/* eslint-disable import/imports-first */
/* eslint-disable no-case-declarations */
import actionTypes from 'redux/actions/actionTypes';

import initialState from '../initialState';

export default function reducer(
  tenderOffersShares = initialState.tenderOffersShares,
  action
) {
  switch (action.type) {
    case actionTypes.CREATE_TENDER_OFFER_SHARES:
      return [...tenderOffersShares, action.tenderOffersShares];

    case actionTypes.GET_TENDER_OFFERS_SHARES:
      return action.data;

    case actionTypes.UPDATE_TENDER_OFFER_SHARES:
      return [
        ...tenderOffersShares.filter(
          (tenderOffersShares) =>
            tenderOffersShares['_id'] !== action.tenderOffersShares['_id']
        ),
        action.tenderOffersShares,
      ];

    case actionTypes.DELETE_TENDER_OFFER_SHARES:
      return [
        ...tenderOffersShares.filter(
          (currentPlan) =>
            currentPlan['_id'] !== action.tenderOffersShares['_id']
        ),
      ];

    default:
      return tenderOffersShares;
  }
}
