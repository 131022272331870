import SignatureBox from 'components/SignatureBox';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import html2pdf from 'html3pdf';
import ReactHtmlParser from 'react-html-parser';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getActualSociety } from 'utils/filters';
import {
  addDocument,
  getDocument,
  getDocumentsByReference,
} from 'redux/actions/documentActions';

import { setMenu } from 'redux/actions/menuActions';
import { getBoards, updateBoard } from 'redux/actions/boardActions';
import store from 'redux/store';
import delegationTemplates from 'constants/delegationTemplates';
import { updateDocumentWithValues } from 'components/EditorWYSIWYG/helpers';
import tags from 'components/EditorWYSIWYG/tags';
import DocumentsGenerator from 'components/Documents/Creators/DocumentsGenerator';
import { setPreview } from 'redux/actions/previewActions';
import { addAlert } from 'redux/actions/alertActions';
import alertBodyTypes from 'components/Alert/alertBodyTypes';
import fileTypes from 'constants/fileTypes';
import documentTypes from 'constants/documentTypes';
import boardTypes from 'constants/boardTypes';
import { formatDateToTime } from 'constants/formats';
import { format } from 'date-fns';
import sendEmail from 'redux/actions/mailActions';
import DelegationMessageBox from 'components/DelegationMessageBox';

const DelegationSignature = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { societyId, boardId, partnerId } = useParams();

  const { actualSociety, user, preview, documents, boards } = useSelector(
    (state) => ({
      actualSociety: state.society?.actualSociety,
      user: state.user,
      preview: state.preview,
      documents: state.documents,
      boards: state.boards,
    })
  );
  const [signature, setSignature] = useState(null);
  const [toggleSignatureBox, setToggleSignatureBox] = useState(true);
  const [delegationTemplate, setDelegationTemplate] = useState();
  const [currentBoard, setCurrentBoard] = useState();

  const [hasFinishedSignature, setHasFinishedSignature] = useState(false);

  const saveSignedDocument = async () => {
    try {
      const partnerName = currentBoard?.delegation?.partner?.name;
      const options = {
        filename: `${t('VoteDelegation')}_${partnerName}.pdf`,
        margin: [10, 10, 10, 10], // top, right, bottom, left
        html2canvas: { scale: 2 },
      };
      const documentUpdated = updateDocumentWithValues(
        delegationTemplate?.editorModel,
        tags({
          society: actualSociety,
          board: currentBoard,
          signatures: { partnerSignature: signature || null },
        })
      );
      const fileBlob = await html2pdf()
        .from(documentUpdated?.html)
        .set(options)
        .output('blob');

      const delegationFile = new File(
        [fileBlob],
        `${t('VoteDelegation')}_${partnerName}.pdf`,
        {
          type: 'application/pdf',
        }
      );
      const newDelegationDocument = {
        name: `${t('VoteDelegation')}_${partnerName}.pdf`,
        file: delegationFile,
        fileType: fileTypes.PDF,
        size: delegationFile.size,
        date: new Date(),
        lastAccess: new Date(),
        category: documentTypes.DOCUMENTS,
        author: user['_id'],
        society: actualSociety['_id'],
        isGenerated: true,
        description: `Generado por la plantilla ${delegationTemplate?.name}`,
      };
      const uploadedDocument = await store.dispatch(
        addDocument(newDelegationDocument)
      );
      if (uploadedDocument) {
        const partnerParticipant = currentBoard.participants.find(
          (participant) => participant.member === partnerId
        );
        const docs = partnerParticipant.delegationDocuments || [];
        await store.dispatch(
          updateBoard(
            boardId,
            {
              participantData: {
                ...partnerParticipant,
                delegationDocuments: [...docs, uploadedDocument['_id']],
                hasDelegated: true,
              },
              delegationDocs: { add: uploadedDocument['_id'] },
            },
            false
          )
        );

        const partnerRepresentative = currentBoard.participants.find(
          (participant) =>
            participant.member ===
            currentBoard?.delegation?.representative?.['_id']
        );
        const docsRepresentative =
          partnerRepresentative.delegationDocuments || [];
        await store.dispatch(
          updateBoard(
            boardId,
            {
              participantData: {
                ...partnerRepresentative,
                delegationDocuments: [
                  ...docsRepresentative,
                  uploadedDocument['_id'],
                ],
              },
            },
            false
          )
        );
        dispatch(getDocumentsByReference({ societyId: actualSociety['_id'] }));

        if (
          partnerRepresentative &&
          currentBoard &&
          currentBoard?.delegation?.partner
        ) {
          const boardTime = currentBoard.date
            ? formatDateToTime(currentBoard.date)
            : '';
          const boardDate = currentBoard.date
            ? format(new Date(currentBoard.date), 'dd/MM/yyyy')
            : '';
          const emailData = {
            to: [
              {
                email: partnerRepresentative?.email,
                name: partnerRepresentative?.name,
              },
            ],
            file: delegationFile,
            subject: t('VoteDelegation'),
            template: 'notify-delegate-es',
            templateData: [
              {
                name: 'partnerName',
                content: currentBoard?.delegation?.partner?.name || '',
              },
              {
                name: 'partnerCif',
                content: currentBoard?.delegation?.partner?.cif || '',
              },
              {
                name: 'delegateName',
                content: partnerRepresentative?.name || '',
              },
              {
                name: 'boardDate',
                content: boardDate,
              },
              {
                name: 'boardTime',
                content: boardTime,
              },
              {
                name: 'boardType',
                content: boardTypes?.[currentBoard?.boardType]?.text || '-',
              },
              {
                name: 'societyName',
                content: actualSociety?.name || '',
              },
              {
                name: 'targetUrl',
                content: `${process.env.REACT_APP_DOMAIN}?redirect=voto-junta/${societyId}/${boardId}/${partnerRepresentative?.member}`,
              },
            ],
            sender: {
              name: `Sttok | ${actualSociety?.name}`,
              email: 'info@sttok.com',
            },
            user: user['_id'],
            society: actualSociety['_id'],
            date: new Date(),
          };
          dispatch(sendEmail(emailData, false));
        }
      }
    } catch (error) {
      dispatch(addAlert(alertBodyTypes.ERROR_GENERATING_DOCUMENT));
    }
  };

  const generateDelegationTemplatePreview = async () => {
    try {
      const documentUpdated = updateDocumentWithValues(
        delegationTemplate?.editorModel,
        tags({
          society: actualSociety,
          board: currentBoard,
          signatures: { partnerSignature: signature || null },
        })
      );
      const dataToDocument = (
        <DocumentsGenerator
          invitationModel={documentUpdated?.html}
          hasConsolidationCalendar={false}
          consolidationCalendar={{}}
        />
      );

      await store.dispatch(
        setPreview({
          name: delegationTemplate.name,
          bdDocument: delegationTemplate,
          document: dataToDocument,
          documentModel: documentUpdated?.html,
          template: delegationTemplate?.['_id'],
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getDelegationTemplate = async () => {
    try {
      const globalDelegationTemplateId =
        delegationTemplates[process.env.NODE_ENV];
      const globalDelegationTemplate = await store.dispatch(
        getDocument(globalDelegationTemplateId)
      );
      return globalDelegationTemplate;
    } catch {
      throw Error('Error getting delegation template');
    }
  };

  useEffect(() => {
    if (societyId) {
      dispatch(getBoards(societyId));
      getDelegationTemplate()
        .then((res) => setDelegationTemplate(res))
        .catch((e) => console.log(e));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [societyId]);

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId) {
      getActualSociety(user, societyId);
    }
  }, [actualSociety, user, societyId]);

  useEffect(() => {
    if (actualSociety) {
      dispatch(getDocumentsByReference({ societyId: actualSociety['_id'] }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety]);

  useEffect(() => {
    if (boards.length && actualSociety) {
      const delegationData = {};
      const actualBoard = boards.find((board) => board['_id'] === boardId);
      const partner = actualSociety?.partners?.find(
        (p) => p['_id'] === partnerId
      );
      const partnerAsParticipant = actualBoard?.participants?.find(
        (p) => p.member === partnerId
      );
      delegationData.partner = {
        ...partner,
        delegationVote: partnerAsParticipant.delegationVote || null,
      };
      if (partnerAsParticipant.representative) {
        const partnerRepresentative = actualSociety?.partners?.find(
          (p) => p['_id'] === partnerAsParticipant.representative
        );
        delegationData.representative = partnerRepresentative || null;
      }
      actualBoard.delegation = delegationData;
      setCurrentBoard(actualBoard);
    }
  }, [boards, boardId, actualSociety]);

  useEffect(
    () =>
      dispatch(
        setMenu({
          type: null,
          societyId: actualSociety?.['_id'] || null,
          societyName: actualSociety?.name,
        })
      ),
    [actualSociety, dispatch]
  );

  useEffect(() => {
    if (actualSociety && delegationTemplate) {
      generateDelegationTemplatePreview();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delegationTemplate, currentBoard]);

  useEffect(() => {
    if (signature) {
      saveSignedDocument();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signature]);

  return !hasFinishedSignature ? (
    <>
      <div className="nk-content-body">
        <div className="nk-content-wrap">
          {!toggleSignatureBox ? (
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <h5 className="title nk-block-title">Delegación voto</h5>
                <div className="nk-block-des">
                  <p>Socio: {currentBoard?.delegation?.partner?.name || ''}</p>
                </div>
              </div>
              <div className="nk-fmg-actions">
                <ul className="nk-block-tools g-3">
                  <li>
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-toggle="dropdown"
                      onClick={() => setToggleSignatureBox(true)}
                    >
                      <em className="icon ni ni-pen" />
                      <span>{t('Sign')}</span>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          ) : (
            <></>
          )}
          {toggleSignatureBox ? (
            <div className="nk-content-body mx-auto align-center mb-5">
              <h5 className="title nk-block-title mb-3">{t('Sign')}:</h5>
              <SignatureBox
                isDelegation
                signature={signature}
                setSignature={setSignature}
                setHasFinishedSignature={setHasFinishedSignature}
              />
            </div>
          ) : (
            <></>
          )}
          <div className="nk-block mb-3">
            {preview?.documentModel ? (
              <div className="card card-bordered w-100" id="preview--box">
                <div className="card-inner card-inner-xl">
                  {ReactHtmlParser(preview?.documentModel)}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  ) : (
    <DelegationMessageBox
      icon="SUCCESS"
      title={t('DelegationSuccessfully')}
      description={t('DelegationSuccessfullyDescription', {
        societyName: actualSociety?.name,
      })}
      link={`/detalle-socio/${societyId}/${partnerId}`}
      linkText={t('SeeMyPartnerDetails')}
    />
  );
};

export default DelegationSignature;
