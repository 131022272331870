/* eslint-disable new-cap */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import jsPDF from 'jspdf';

import store from 'redux/store';
import { addDocument, getDocument } from 'redux/actions/documentActions';
import { updateTenderOfferShares } from 'redux/actions/tenderOffersSharesActions';

import fileTypes from 'constants/fileTypes';
import documentTypes from 'constants/documentTypes';
import signatureTypes from 'constants/signatureTypes';
import signatureAnchorTypes from 'constants/signatureAnchorTypes';
import { JSPDF_MARGINS } from 'constants/defaultConstants';

import tags from 'components/EditorWYSIWYG/tags';
import { updateDocumentWithValues } from 'components/EditorWYSIWYG/helpers';

import TenderOfferSharesDynamic from 'components/TenderOfferSharesDynamic';
import { tenderOfferSharesParticipantStatus } from './tenderOfferSharesTypes';
import { startSignProcess, toBase64 } from './docusignActions';
import cleanHtmlEntities from './editor';

const options = {
  filename: 'invitation.pdf',
  margin: [10, 10, 10, 10], // top, right, bottom, left
  pagebreak: { mode: 'avoid-all' },
  html2canvas: { scale: 2 },
  jsPDF: {
    unit: 'mm',
    format: 'a4',
    orientation: 'portrait',
  },
};

const documentDescription = {
  BUY: 'TenderOfferSharesBuy',
  SELL: 'TenderOfferSharesSell',
  DEFAULT: 'TenderOfferSharesBuySell',
};

export default (data) => async (dispatch) => {
  const {
    tenderOffer,
    tenderOfferData,
    partner,
    actualSociety,
    user,
    translate,
  } = data;

  try {
    await store.dispatch(
      updateTenderOfferShares(tenderOffer._id, {
        participantId: partner._id,
        participantData: {
          id: partner._id,
          type: 'PARTNER',
          status: tenderOfferSharesParticipantStatus.ACCEPTED,
          totalPrice: tenderOfferData.totalPrice,
          totalShares: tenderOfferData.totalShares,
          operationDate: new Date(),
          sharesFrom: tenderOfferData?.sharesFrom,
          sharesTo: tenderOfferData?.sharesTo,
          class: tenderOfferData?.class,
        },
      })
    );
    if (tenderOffer?.template) {
      const currentTemplate = await store.dispatch(
        getDocument(tenderOffer?.template)
      );
      const templateUpdated = updateDocumentWithValues(
        currentTemplate?.editorModel,
        tags({
          partner,
          society: actualSociety,
          tenderOffer,
          tenderOfferShares: tenderOfferData,
          plan: {},
          beneficiary: {},
        })
      );
      const templateModel = templateUpdated.html;

      let tenderHtml = '';
      const hasTenderOfferSharesSummary =
        templateUpdated?.hasTenderOfferSharesSummary;
      if (hasTenderOfferSharesSummary) {
        const templateFinal = (
          <TenderOfferSharesDynamic tenderOffer={tenderOfferData} />
        );
        tenderHtml = renderToStaticMarkup(templateFinal);
      }

      let mainBlob = null;
      const pdf = new jsPDF('p', 'pt', 'a4');
      const docHTML = cleanHtmlEntities(templateModel);
      await new Promise((resolve, reject) => {
        pdf.fromHTML(
          docHTML + tenderHtml,
          JSPDF_MARGINS.left,
          JSPDF_MARGINS.top,
          {
            width: JSPDF_MARGINS.width,
          },
          () => {
            try {
              mainBlob = pdf.output('blob');
              resolve();
            } catch (error) {
              reject(error);
            }
          }
        );
      });
      const fileName = `Contrato_Tender_Offer_${tenderOffer.name}_${actualSociety.name}.pdf`;
      const templateFile = new File([mainBlob], 'invitation.pdf', {
        type: 'application/pdf',
      });

      const newDocument = {
        name: fileName,
        file: templateFile,
        size: templateFile?.size || 0,
        date: new Date(),
        lastAccess: new Date(),
        category: documentTypes.SCRIPTURES,
        author: user['_id'],
        society: actualSociety['_id'],
        isGenerated: true,
        fileType: fileTypes.PDF,
        description: translate(
          tenderOffer?.type
            ? documentDescription[tenderOffer.type]
            : documentDescription.DEFAULT
        ),
      };

      await store.dispatch(addDocument(newDocument));

      const signFileBase64 = await toBase64(templateFile);

      const signers = [
        {
          id: partner?._id,
          name: partner?.name,
          email: partner?.email,
          role:
            tenderOffer?.type === 'BUY'
              ? signatureAnchorTypes.TENDER_BUYER
              : signatureAnchorTypes.TENDER_SELLER,
          type: signatureTypes.TENDER_OFFER_SHARES,
        },
        {
          id: tenderOffer?.sellerId || '',
          name: tenderOffer?.sellerName || '',
          email: tenderOffer?.sellerEmail || '',
          role:
            tenderOffer?.type === 'BUY'
              ? signatureAnchorTypes.TENDER_SELLER
              : signatureAnchorTypes.TENDER_BUYER,
          type: signatureTypes.TENDER_OFFER_SHARES,
        },
      ];
      const signProcessData = {
        signers,
        societyName: actualSociety?.name,
        documentName: fileName,
        planName: tenderOffer?.name,
        language: actualSociety?.language || 'es',
        redirectParams: [actualSociety?._id, partner?._id],
        customParams: tenderOffer?._id,
        signatureType: signatureTypes.TENDER_OFFER_SHARES,
        signatureFile: signFileBase64,
        signatureFileName: fileName,
      };

      await startSignProcess(signProcessData);

      return {
        success: true,
      };
    }
  } catch (error) {
    throw new Error(error);
  }
};
