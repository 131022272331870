import i18n from 'i18n/config';

export default [
  {
    icon: 'ni-user-alt',
    text: i18n.t('MyProfile'),
    url: '/perfil-usuario/datos',
  },
  {
    icon: 'ni-users',
    text: i18n.t('MySocieties'),
    url: '/perfil-usuario/identidades',
  },
  {
    icon: 'ni-building',
    text: i18n.t('CompaniesRepresented'),
    url: '/perfil-usuario/sociedades',
  },
  {
    icon: 'ni-lock-alt',
    text: i18n.t('UsersWithAccess'),
    url: '/perfil-usuario/administradores',
  },
];
