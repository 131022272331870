/* eslint-disable no-underscore-dangle */
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setModal } from 'redux/actions/modalActions';
import { updateSociety } from 'redux/actions/societyActions';
import {
  calculateConsolidationPercent,
  getConsolidationPeriods,
} from 'utils/filters';
import cancelationTypes from 'constants/cancelationTypes';

import '../Modals.scss';

function CancelBeneficiaryModal({ beneficiaryId, beneficiary }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const plans = useSelector((state) => state?.plans);
  const { actualSociety } = useSelector((state) => state?.society);
  const [date, setDate] = useState();
  const [cancelationMode, setCancelationMode] = useState();

  useEffect(() => {
    if (beneficiary?.isCanceled) {
      setDate(format(new Date(beneficiary?.cancelationDate), 'yyyy-MM-dd'));
      setCancelationMode(beneficiary?.cancelationOption);
    }
  }, [beneficiary]);

  function cancel(event) {
    event.preventDefault();
    const actualBeneficiary = actualSociety?.beneficiaries.find(
      (currentBeneficiary) =>
        currentBeneficiary['_id'] === beneficiaryId &&
        currentBeneficiary.plan === beneficiary?.plan
    );
    const updateData = {
      isCanceled: true,
      cancelationDate: date,
      cancelationOption: cancelationMode,
      finalConsolidatedUnits: 0,
    };
    if (cancelationMode === cancelationTypes.PARTIAL) {
      const actualPlan = plans?.find(
        (currentPlan) => currentPlan['_id'] === beneficiary?.plan
      );
      const consolidationPeriods = getConsolidationPeriods(
        actualBeneficiary,
        actualPlan
      );
      const closestCancelationPeriod = consolidationPeriods?.reduce(
        (acc, period) => {
          if (new Date(period?.date).getTime() <= new Date(date).getTime()) {
            if (!acc || new Date(acc?.date) < new Date(period?.date)) {
              return period;
            }
          }
          return acc;
        },
        null
      );
      if (closestCancelationPeriod) {
        updateData['finalConsolidatedUnits'] =
          closestCancelationPeriod?.totalVestedUnits;
      }

      // const percentConsolidated = calculateConsolidationPercent(
      //   date,
      //   actualBeneficiary,
      //   actualPlan
      // );
      // updateData['finalConsolidatedUnits'] = Math.floor(
      //   (percentConsolidated * actualBeneficiary?.sharesCount?.future) / 100
      // );
    } else if (cancelationMode === cancelationTypes.CONSOLIDATED) {
      updateData['finalConsolidatedUnits'] =
        actualBeneficiary?.sharesCount?.future;
    }
    dispatch(
      updateSociety(actualSociety['_id'], {
        beneficiary: { id: actualBeneficiary['_id'], updateData },
      })
    );
    dispatch(setModal(null));
  }

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title">{t('CancelBeneficiary')}</h5>
        <a
          className="close cursor-pointer"
          onClick={() => dispatch(setModal(null))}
        >
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
      <Modal.Body>
        <form className="form-validate is-alter" onSubmit={cancel}>
          <div className="form-group">
            <label className="form-label" htmlFor="pay-amount">
              {t('CancelationDate')}
            </label>
            <div className="form-control-wrap">
              <input
                type="date"
                className="form-control date-picker"
                value={date}
                onChange={(event) => setDate(event.target.value)}
                required
                max="2100-01-01"
              />
            </div>
          </div>

          <div className="form-group">
            <label className="form-label" htmlFor="default-01">
              {t('HowDoYouWantToCancel?')}
            </label>
            <div className="row">
              <div className="col-md-4">
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    className="custom-control-input"
                    name="cancelation-full"
                    id="cancelation-full"
                    value={cancelationTypes.FULL}
                    checked={cancelationMode === cancelationTypes.FULL}
                    onChange={(event) => setCancelationMode(event.target.value)}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="cancelation-full"
                  >
                    {t('DoNotKeepConsolidatedUnit')}
                  </label>
                </div>
              </div>
              <div className="col-md-4">
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    className="custom-control-input"
                    name="cancelation-partial"
                    id="cancelation-partial"
                    value={cancelationTypes.PARTIAL}
                    checked={cancelationMode === cancelationTypes.PARTIAL}
                    onChange={(event) => setCancelationMode(event.target.value)}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="cancelation-partial"
                  >
                    {t('KeepConsolidatedUnitsUnti')}
                  </label>
                </div>
              </div>
              <div className="col-md-4">
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    className="custom-control-input"
                    name="cancelation-consolidated"
                    id="cancelation-consolidated"
                    value={cancelationTypes.CONSOLIDATED}
                    checked={cancelationMode === cancelationTypes.CONSOLIDATED}
                    onChange={(event) => setCancelationMode(event.target.value)}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="cancelation-consolidated"
                  >
                    {t('ConsolidateAllUnits')}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <button
            type="submit"
            className="btn btn-lg btn-primary"
            disabled={!cancelationMode}
          >
            {t('ExecuteCancellation')}
          </button>
        </form>
      </Modal.Body>
    </>
  );
}

export default CancelBeneficiaryModal;
