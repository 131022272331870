import types from 'redux/actions/actionTypes';
import alertBodyTypes from 'components/Alert/alertBodyTypes';

import { decryptResponse } from 'utils/token';
import axios from '../../interceptors/axios';

import { addAlert } from './alertActions';

export function setPartnerBook(partnerBook) {
  return { type: types.SET_PARTNER_BOOK, partnerBook };
}

export const getPartnerBook = (societyId) => async (dispatch) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/book/${societyId}`
    );

    const decodedData = await decryptResponse(data);

    return dispatch(setPartnerBook(decodedData));
  } catch (error) {
    return dispatch(addAlert(alertBodyTypes[500]));
  }
};
