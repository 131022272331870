import i18n from 'i18n/config';

import { SocietyChart } from 'types';
import { formatNumber } from 'utils/formats';

const getSharesInfo = (society: SocietyChart) => {
  const { holdingShares } = society;

  if (holdingShares) {
    return `${formatNumber(holdingShares)} ${i18n.t('Shares')}`;
  }

  return `0 ${i18n.t('Shares')}`;
};

export default getSharesInfo;
