/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-underscore-dangle */
/* eslint-disable comma-dangle */
/* eslint-disable object-curly-newline */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getDocumentsByReference } from 'redux/actions/documentActions';
import documentTypes from 'constants/documentTypes';
import PartnerDocumentsRow from './PartnerDocumentsRow';
import PartnerDocumentsSection from './PartnerDocumentsSection';

const PartnerDocuments = ({ society, partner }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user, isAdmin, documents } = useSelector((state) => ({
    user: state.user,
    isAdmin: state.society?.role?.isAdmin,
    documents: state.documents,
  }));

  const [otherRows, setOtherRows] = useState([]);
  const [certificateValueSharesRows, setCertificateValueSharesRows] = useState(
    []
  );

  const partnerName = `${partner.name || ''}`;

  const getPartnerDocuments = () => {
    const result = documents?.filter((document) =>
      partner?.documents.some(
        (documentId) =>
          documentId === document['_id'] &&
          !document.isForAdmins &&
          document.category !== documentTypes.DELETED
      )
    );
    return result || [];
  };

  const getDocumentRows = () => {
    const partnerDocuments = getPartnerDocuments();

    // Get the certificate of value shares documents and the other documents.
    const certificateValueSharesDocuments = [];
    const otherDocuments = [];

    partnerDocuments?.forEach((document) => {
      // Its a certificate of value shares document.
      if (
        document.category === documentTypes.CERTIFICATES &&
        document.subcategory === 'VALUESHARES'
      ) {
        certificateValueSharesDocuments.push(document);
      }
      // Its a other document.
      else {
        otherDocuments.push(document);
      }
    });

    const certificateValueSharesRows = certificateValueSharesDocuments.map(
      (document, index) => (
        <PartnerDocumentsRow
          document={document}
          index={index + 1}
          key={document?.['_id']}
        />
      )
    );

    const otherRows = otherDocuments.map((document, index) => (
      <PartnerDocumentsRow
        document={document}
        index={index + 1}
        key={document?.['_id']}
      />
    ));

    setCertificateValueSharesRows(certificateValueSharesRows);
    setOtherRows(otherRows);
  };

  useEffect(() => {
    if (documents?.length > 0) {
      getDocumentRows();
    }
  }, [documents]);

  useEffect(() => {
    if (society) {
      dispatch(getDocumentsByReference({ societyId: society['_id'] }));
    }
  }, [society]);

  return (
    <>
      <PartnerDocumentsSection
        data={{
          title: t('Documents'),
          subtitle: t('PartnerDocumentationPar', {
            partnerName,
            societyName: society.name,
          }),
          isAdmin,
          userId: user['_id'],
          societyId: society['_id'],
          partnerId: partner['_id'],
          rows: otherRows,
        }}
      />
      {certificateValueSharesRows?.length > 0 && (
        <PartnerDocumentsSection
          data={{
            title: t('CertificatesValueShares'),
            subtitle: t('CertificatesValueSharesMessage'),
            rows: certificateValueSharesRows,
          }}
        />
      )}
    </>
  );
};

export default PartnerDocuments;
