/* eslint-disable no-nested-ternary */
/* eslint-disable react/display-name */
import React from 'react';

import screens from 'constants/screens';
import MenuDots from 'components/MenuDots';
import CellData from 'components/Tables/CellData';
import userTypes from 'constants/userTypes';

import investorOptions from './investorOptions';

function tableColumns(i18n) {
  return [
    {
      name: i18n.t('Nr'),
      field: 'index',
      hide: screens.SM,
      selector: (row, index) => index + 1,
      cell: (row, index) => index + 1,
      disableSortBy: true,
      disableFilters: true,
      grow: 0.5,
      minWidth: 20,
      export: true,
    },
    {
      // mandatory props
      name: i18n.t('Investor'),
      field: 'name',
      selector: (row) => row[i18n.t('Investor')],
      sortable: true,
      // optional props
      grow: 4,
      compact: true,
      cell: (row) =>
        CellData.User({
          societyId: row?.societyId,
          partner: row?.investor,
          hasLink: row?.hasLink,
          url: '/detalle-inversor',
        }),
      export: true,
    },
    {
      name: i18n.t('DraftConvertibleNote'),
      field: 'draftName',
      selector: (row) => row[i18n.t('DraftConvertibleNote')],
      sortable: true,
      center: true,
      compact: true,
      hide: screens.MD,
      cell: (row) =>
        CellData.PlainText({
          text: row[i18n.t('DraftConvertibleNote')],
        }),
      export: true,
    },
    {
      name: i18n.t('Contribution'),
      field: 'contribution',
      selector: (row) => row[i18n.t('Contribution')],
      sortable: true,
      center: true,
      compact: true,
      cell: (row) =>
        CellData.CurrencyDecimals({
          value: row[i18n.t('Contribution')],
        }),
      export: true,
    },
    {
      name: i18n.t('Discount'),
      field: 'discountPercent',
      selector: (row) => row[i18n.t('Discount')],
      sortable: true,
      center: true,
      hide: screens.MD,
      compact: true,
      cell: (row) =>
        CellData.PercentDecimals({
          value: row[i18n.t('Discount')],
          decimals: 2,
        }),
      export: true,
    },
    // {
    //   name: i18n.t('Interest'),
    //   field: 'interest',
    //   selector: (row) => row[i18n.t('Interest')],
    //   sortable: true,
    //   center: true,
    //   hide: screens.LG,
    //   compact: true,
    //   cell: (row) =>
    //     CellData.CurrencyDecimals({
    //       value: row[i18n.t('Interest')],
    //     }),
    //   export: true,
    // },
    {
      name: i18n.t('Shares'),
      field: 'shares',
      selector: (row) => row[i18n.t('Shares')],
      sortable: true,
      center: true,
      compact: true,
      hide: screens.SM,
      cell: (row) =>
        CellData.NumberNoDecimals({ value: row[i18n.t('Shares')] }),
      export: true,
    },
    {
      name: i18n.t('%ND'),
      field: 'NDPercent',
      selector: (row) => row[i18n.t('%ND')],
      sortable: true,
      center: true,
      hide: screens.SM,
      compact: true,
      cell: (row) =>
        CellData.PercentDecimals({
          value: row[i18n.t('%ND')],
          decimals: row.decimals,
        }),
      export: true,
    },
    {
      name: i18n.t('%FD'),
      field: 'FDPercent',
      selector: (row) => row[i18n.t('%FD')],
      sortable: true,
      center: true,
      hide: screens.SM,
      compact: true,
      cell: (row) =>
        CellData.PercentDecimals({
          value: row[i18n.t('%FD')],
          decimals: row.decimals,
        }),
      export: true,
    },
    {
      name: i18n.t('%PotentialFD'),
      field: 'FDPotential',
      selector: (row) => row[i18n.t('%PotentialFD')],
      sortable: true,
      center: true,
      hide: screens.MD,
      compact: true,
      cell: (row) =>
        CellData.PercentDecimals({
          value: row[i18n.t('%PotentialFD')],
          decimals: row.decimals,
        }),
      export: true,
    },
    {
      name: i18n.t('ContributionDate'),
      field: 'contributionDate',
      selector: (row) => row[i18n.t('ContributionDate')],
      sortable: true,
      center: true,
      hide: screens.MD,
      compact: true,
      cell: (row) =>
        CellData.PlainDate({
          date: row[i18n.t('ContributionDate')],
        }),
      export: true,
    },
    {
      sortable: false,
      cell: (row) => {
        if (!row?.isDemo) {
          return (
            <ul className="nk-tb-actions gx-1">
              <li>
                <div className="dropdown">
                  <MenuDots
                    menuOptions={investorOptions(i18n)}
                    direction="left"
                    id={`${row.societyId}/${row.investor['_id']}`}
                    params={{
                      data: row.investor,
                      societyId: row.societyId,
                      userType: userTypes.INVESTOR,
                      society: row.society,
                    }}
                  />
                </div>
              </li>
            </ul>
          );
        }
      },
      export: false,
      right: true,
      width: '50px',
      allowOverflow: true,
    },
  ];
}

export default tableColumns;
