import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { addDocument, updateDocument } from 'redux/actions/documentActions';
import store from 'redux/store';

import EditorWYSIWYG from 'components/EditorWYSIWYG';

import { Document } from 'types/Document';
import { Society } from 'types';
import { updateSociety } from 'redux/actions/societyActions';
import Swal from 'sweetalert2';
import ReadOnlyEditor from 'components/ReadOnlyEditor';

type CertificateTemplateViewProps = {
  sharesCertificateDocument: Document;
  setShraesCertificateDocument: any;
  actualSociety: Society;
};

const CertificateTemplateView: FC<CertificateTemplateViewProps> = ({
  sharesCertificateDocument,
  setShraesCertificateDocument,
  actualSociety,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const user: any = useSelector((state: any) => state.user);
  const isDemo: boolean = useSelector(
    (state: any) => state.society?.role?.isDemo
  );
  const isAdmin: boolean = useSelector(
    (state: any) => state.society?.role?.isAdmin
  );

  const [htmlContent, setHtmlContent] = useState<string>(
    sharesCertificateDocument?.editorModel || ''
  );
  const [savedContent, setSavedContent] = useState<boolean>(true);

  // Function to handle the editor content change.
  const handleSetHtmlContent = (htmlContent: string) => {
    // Set the html content.
    setHtmlContent(htmlContent);

    // Condition to avoid the save content value to be set to false each time the content is changed.
    if (savedContent) {
      // Set the saved content to false.
      setSavedContent(false);
    }
  };

  const showSuccessAlert = () => {
    Swal.fire({
      icon: 'success',
      title: `<h4 class="nk-modal-title">${t(
        'TemplateUpdatedSuccessfully'
      )}<br></h4>`,
      confirmButtonText: t('OK'),
      allowOutsideClick: false,
      showCancelButton: false,
      confirmButtonColor: '#6576FF',
    });
  };

  // Function to save the editor content.
  const handleSave = async () => {
    try {
      // if society have sharesCertificate document, then update it.
      if (actualSociety?.sharesCertificateTemplate) {
        const updatedTemplate = {
          ...sharesCertificateDocument,
          lastAccess: new Date(),
          editorModel: htmlContent,
        };
        const uploadedDocument = await store.dispatch(
          updateDocument(updatedTemplate, false)
        );

        // Set the shares certificate document.
        setShraesCertificateDocument(uploadedDocument);

        showSuccessAlert();
      }
      // If not, create a new document and update the society.
      else {
        const newTemplate = {
          date: new Date(),
          lastAccess: new Date(),
          category: 'TEMPLATES',
          author: user['_id'],
          isGenerated: false,
          isFavorite: false,
          editorModel: htmlContent,
        };
        // Create the document.
        const uploadedDocument = await store.dispatch(addDocument(newTemplate));

        // Set the shares certificate document.
        setShraesCertificateDocument(uploadedDocument);

        // If the document was created, update the society.
        if (uploadedDocument) {
          await store.dispatch(
            updateSociety(
              actualSociety?.['_id'],
              {
                sharesCertificateTemplate: uploadedDocument['_id'],
              },
              false
            )
          );
          showSuccessAlert();
        }
      }
    } catch (error) {
      Swal.fire({
        icon: 'warning',
        title: `<h4 class="nk-modal-title">${t(
          'ErrorSavingTemplate'
        )}<br></h4>`,
        confirmButtonText: t('OK'),
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonColor: '#6576FF',
      });
    }

    setSavedContent(true);
    console.log('Save');
  };

  useEffect(() => {
    if (sharesCertificateDocument) {
      setHtmlContent(sharesCertificateDocument?.editorModel);
    }
  }, [sharesCertificateDocument]);

  return (
    <>
      <div className="nk-fmg-body-content">
        <div className="nk-block-head nk-block-head-sm">
          <div className="nk-block-between position-relative">
            <div className="nk-block-head-content">
              <h5 className="nk-block-title title">
                {t('CertificateTemplate')}{' '}
                {!savedContent && (
                  <span className="badge rounded-pill text-warning ml-3">
                    {t('UnsavedChanges')}
                  </span>
                )}
              </h5>
              <div className="nk-block-des text-soft">
                <p>{t('TemplateForSharesCertificate')}</p>
              </div>
            </div>
            <div className="nk-block-head-content">
              <ul className="nk-block-tools g-3">
                <li>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSave}
                    disabled={savedContent}
                  >
                    <em className="icon ni ni-save mr-2" />
                    {t('ToSave')}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {isDemo ? (
          <div className="w-100">
            <ReadOnlyEditor editorContent={htmlContent} />
          </div>
        ) : (
          <div className="w-100">
            <EditorWYSIWYG
              values={{
                society: {},
                partner: {},
                signatures: {},
              }}
              setEditorContent={handleSetHtmlContent}
              editorContent={htmlContent}
              setEditorReference={null}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default CertificateTemplateView;
