/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable-next-line react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { setMenu } from 'redux/actions/menuActions';
import { getStockPlans } from 'redux/actions/planActions';
import { getActualSociety } from 'utils/filters';
import { getTenderOffers } from 'redux/actions/tenderOfferActions';

import PlanSummary from 'components/PlanSummary';
import CustomLoading from 'components/CustomLoading';

function SummaryBook() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { societyId } = useParams();

  const user = useSelector((state) => state?.user);
  const actualSociety = useSelector((state) => state?.society?.actualSociety);

  useEffect(() => {
    if (!societyId) return;

    dispatch(getStockPlans(societyId));
    dispatch(getTenderOffers(societyId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [societyId]);

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId) getActualSociety(user, societyId);
  }, [user, societyId, actualSociety]);

  useEffect(() => {
    if (actualSociety) {
      dispatch(
        setMenu({
          type: null,
          societyId: actualSociety?.['_id'] || null,
          societyName: actualSociety?.name,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety]);

  return actualSociety ? (
    <div className="nk-block">
      <PlanSummary />
    </div>
  ) : (
    <CustomLoading />
  );
}

export default SummaryBook;
