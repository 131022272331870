/* eslint-disable no-underscore-dangle */
/* eslint-disable comma-dangle */
/* eslint-disable object-curly-newline */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'i18next';

import { setModal } from 'redux/actions/modalActions';
import { getBeneficiaryData } from 'redux/actions/beneficiaryActions';

import createCertificate from 'utils/createCertificate';

import documentTypes from 'constants/documentTypes';

import DocumentModal from 'pages/Documents/documentModal';

import BeneficiaryDocumentsRow from './BeneficiaryDocumentsRow';

const BeneficiaryDocuments = ({ society, beneficiary, plan }) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state?.user);
  const isAdmin = useSelector((state) => state.society?.role?.isAdmin);
  const isDemo = useSelector((state) => state.society?.role?.isDemo);

  const [rows, setRows] = useState([]);
  const [documents, setDocuments] = useState([]);

  const userId = user['_id'];
  const societyId = society?.societyId;
  const beneficiaryId = beneficiary['_id'];

  const getBeneficiaryDocuments = async () => {
    const beneficiaryData = await getBeneficiaryData({
      beneficiaryId,
      societyId,
      source: 'documents',
    });

    setDocuments(beneficiaryData?.documents);
  };

  const handleOnClickUpload = () =>
    dispatch(
      setModal(
        <DocumentModal
          userId={userId}
          societyId={societyId}
          beneficiaryId={beneficiaryId}
        />
      )
    );

  const handleClickCertificate = () => {
    createCertificate(
      {
        certificateData: {
          societyData: society,
          beneficiaryData: beneficiary,
          planData: plan,
        },
      },
      beneficiary['_id']
    );
  };

  useEffect(() => {
    setRows(
      documents
        ?.filter(
          (currentDoc) =>
            !currentDoc.isForAdmins &&
            currentDoc.category !== documentTypes.DELETED
        )
        .map((filteredDocument, index) => (
          <BeneficiaryDocumentsRow
            document={filteredDocument}
            index={index + 1}
            key={filteredDocument?.['_id']}
          />
        ))
    );
  }, [documents]);

  useEffect(() => {
    getBeneficiaryDocuments();
  }, []); // eslint-disable-line

  return (
    <div className="card-inner">
      <div className="nk-block-head">
        <div className="nk-block-between g-3 d-flex flex-column flex-md-row">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title">{t('IncentivePlanDocumentatio')}</h4>
            <div className="nk-block-des">
              <p>
                {t('DocumentationOfTheIncenti', {
                  societyName: society?.name || '',
                })}
              </p>
            </div>
          </div>

          <div className="nk-block-head-content">
            {isAdmin && (
              <div className="nk-block float-right">
                <button
                  type="button"
                  className="btn btn-primary col-12 my-1"
                  onClick={handleOnClickUpload}
                  disabled={isDemo}
                >
                  <em className="icon ni ni-upload mr-1" />
                  {t('UpDocument')}
                </button>

                {!beneficiary?.isDraft && (
                  <button
                    type="button"
                    className="btn btn-primary col-12 my-1"
                    onClick={handleClickCertificate}
                    disabled={isDemo}
                  >
                    <em className="icon ni ni-reports-alt mr-1" />
                    {t('ParticipationCertificate')}
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      {rows?.length > 0 && (
        <div className="nk-block nk-block-lg">
          <div className="card card-bordered card-preview">
            <table className="nk-tb-list nk-tb-ulist">
              <thead>
                <tr className="nk-tb-item nk-tb-head">
                  <th className="nk-tb-col d-none d-md-table-cell" scope="col">
                    {t('Nr')}
                  </th>
                  <th className="nk-tb-col" scope="col">
                    {t('Name')}
                  </th>
                  <th className="nk-tb-col d-none d-md-table-cell" scope="col">
                    {t('Date')}
                  </th>
                  <th className="nk-tb-col" scope="col">
                    {t('Doc')}
                  </th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default BeneficiaryDocuments;
