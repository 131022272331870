/* eslint-disable no-case-declarations */
import actionTypes from 'redux/actions/actionTypes';
import initialState from '../initialState';

export default function reducer(
  partnerCategories = initialState.partnerCategories,
  action
) {
  switch (action.type) {
    case actionTypes.CREATE_PARTNER_CATEGORY:
      return [...partnerCategories, action.partnerCategory];

    case actionTypes.GET_PARTNER_CATEGORIES: {
      if (action?.data[0]?.createdAt) {
        return action.data.sort(
          (a, b) =>
            new Date(a?.createdAt)?.getTime() -
            new Date(b?.createdAt)?.getTime()
        );
      }
      return action.data;
    }

    case actionTypes.UPDATE_PARTNER_CATEGORY:
      return [
        ...partnerCategories.filter(
          (partnerCategory) =>
            partnerCategory['_id'] !== action.partnerCategory['_id']
        ),
        action.partnerCategory,
      ];

    case actionTypes.DELETE_PARTNER_CATEGORY:
      return partnerCategories.filter(
        (partnerCategory) => partnerCategory['_id'] !== action.partnerCategoryId
      );

    default:
      return partnerCategories;
  }
}
