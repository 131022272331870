import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import hasPlanCustomDilution, {
  getPartnersNamesWithCustomDilution,
} from 'utils/customDilution';

const DilutionBadge: FC = () => {
  const { t } = useTranslation();
  const plans = useSelector((state: any) => state.plans);
  const actualSociety = useSelector(
    (state: any) => state.society?.actualSociety
  );
  const [showBadge, setShowBadge] = useState<boolean>(false);
  const [partnersList, setPartnersList] = useState<string[]>([]);

  useEffect(() => {
    if (plans?.length) {
      setShowBadge(hasPlanCustomDilution(plans));
    }
  }, [plans]);

  useEffect(() => {
    if (showBadge && actualSociety?.partners?.length) {
      setPartnersList(
        getPartnersNamesWithCustomDilution(actualSociety?.partners)
      );
    }
  }, [showBadge, actualSociety?.partners]);

  return (
    showBadge && (
      <>
        <span
          className="badge badge-dim badge-danger"
          data-tooltip-id="dilution-tooltip"
          style={{ marginRight: '36px', marginTop: '3px' }}
        >
          {t('DilutionInfo')}
        </span>

        <Tooltip id="dilution-tooltip" place="top">
          <div className="fw-normal">
            {t('DilutionInfoMessage')}
            <br />

            <ul className="list text-left">
              {partnersList?.map((partnerName: string) => (
                <li key={partnerName}>{partnerName}</li>
              ))}
            </ul>
          </div>
        </Tooltip>
      </>
    )
  );
};

export default DilutionBadge;
