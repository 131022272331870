import Swal from 'sweetalert2';
import store from 'redux/store';
import { updateSociety } from 'redux/actions/societyActions';
import { deleteDocument } from 'redux/actions/documentActions';

const documentDelete = (document, documentId) => {
  Swal.fire({
    icon: 'warning',
    title: '<h4 class="nk-modal-title">¿Estás seguro?</h4>',
    html:
      '<div class="caption-text">Este procedimiento no se puede deshacer.</div>',
    footer: `<div class="bg-lighter"><div class="text-center w-100">
    <p>Consulte al administrador para más detalles</p>
    </div></div>`,
    confirmButtonText: 'Ok, eliminar',
    confirmButtonColor: '#6576ff',
    allowOutsideClick: false,
    showCancelButton: true,
  }).then((result) => {
    if (result.isConfirmed) {
      store.dispatch(deleteDocument(documentId));
      // TODO: send custom message for alert when update
      store.dispatch(
        updateSociety(document.society, {
          docs: { remove: documentId },
        })
      );
    }
  });
};

export default documentDelete;
