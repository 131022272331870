import hasShowDetails from 'utils/showDetails';

const getPriceByPlan = (
  beneficiaryPlan: any,
  simulatorSharePriceUnit: any,
  includeAllUnits: boolean
) => {
  console.log('cclog getPriceByPlan', {
    beneficiaryPlan,
    simulatorSharePriceUnit,
  });

  const totalUnits = includeAllUnits
    ? beneficiaryPlan?.vestedUnits + beneficiaryPlan?.soldUnits
    : beneficiaryPlan?.vestedUnits || 0;

  const totalSalePrice = totalUnits * simulatorSharePriceUnit || 0;

  const totalInitialPrice = totalUnits * beneficiaryPlan?.planData?.sharePrice;

  const totalPrice = totalSalePrice - totalInitialPrice;

  const finalPrice = totalPrice;

  if (hasShowDetails()) {
    console.log('cclog getPriceByPlan', {
      totalUnits,
      totalSalePrice,
      totalInitialPrice,
      totalPrice,
      finalPrice,
    });
  }

  return finalPrice;
};

export default getPriceByPlan;
