import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import DualListBox from 'react-dual-listbox';

import Icon from 'components/Icon/Icon';

import './DualList.scss';

const buttonText = {
  moveLeft: <span className="dual-listbox__button">Remove</span>,
  moveAllLeft: <span className="dual-listbox__button">Remove all</span>,
  moveRight: <span className="dual-listbox__button">Add</span>,
  moveAllRight: <span className="dual-listbox__button">Add all</span>,
  moveDown: <span className="fa fa-chevron-down" />,
  moveUp: <span className="fa fa-chevron-up" />,
  moveTop: <span className="fa fa-double-angle-up" />,
  moveBottom: <span className="fa fa-double-angle-down" />,
};

const buttonIcon = {
  moveLeft: (
    <span className="dual-listbox__button">
      <Icon name="chevron-left" />
    </span>
  ),
  moveAllLeft: (
    <span className="dual-listbox__button">
      <Icon name="chevrons-left" />
    </span>
  ),
  moveRight: (
    <span className="dual-listbox__button">
      <Icon name="chevron-right" />
    </span>
  ),
  moveAllRight: (
    <span className="dual-listbox__button">
      <Icon name="chevrons-right" />
    </span>
  ),
  moveDown: <span className="fa fa-chevron-down" />,
  moveUp: <span className="fa fa-chevron-up" />,
  moveTop: <span className="fa fa-double-angle-up" />,
  moveBottom: <span className="fa fa-double-angle-down" />,
};

const ReactDualList = ({ options, icon, canFilter, preSelected, setValue }) => {
  const { t } = useTranslation();
  const [data, setData] = useState(options);
  const [filterText, setFilterText] = useState('');
  const [selected, setSelected] = useState(preSelected || []);
  const onListChange = (selected) => {
    setSelected(selected);
    setValue(selected);
  };

  const lang = {
    availableFilterHeader: t('AvailableFilterHeader'),
    availableHeader: t('AvailableHeader'),
    filterPlaceholder: t('FilterPlaceholder'),
    moveAllLeft: t('MoveAllLeft'),
    moveAllRight: t('MoveAllRight'),
    moveLeft: t('MoveLeft'),
    moveRight: t('MoveRight'),
    moveBottom: t('MoveBottom'),
    moveDown: t('MoveDown'),
    moveUp: t('MoveUp'),
    moveTop: t('MoveTop'),
    noAvailableOptions: t('NoAvailableOptions'),
    noSelectedOptions: t('NoSelectedOptions'),
    requiredError: t('RequiredError'),
    selectedFilterHeader: t('SelectedFilterHeader'),
    selectedHeader: t('SelectedHeader'),
  };

  // Filtering users by search
  useEffect(() => {
    if (filterText !== '') {
      const filteredObject = options.filter((item) =>
        item.label.toLowerCase().includes(filterText.toLowerCase())
      );
      setData([...filteredObject]);
    } else {
      setData([...options]);
    }
  }, [filterText, options]);

  return (
    <div className="dual-listbox">
      {canFilter && (
        <input
          className="dual-listbox__search"
          placeholder={t('FilterPlaceholder')}
          onChange={(e) => setFilterText(e.target.value)}
        />
      )}
      <DualListBox
        lang={lang}
        options={data}
        selected={selected}
        icons={icon ? buttonIcon : buttonText}
        onChange={onListChange}
        showHeaderLabels
      />
    </div>
  );
};

export default ReactDualList;
