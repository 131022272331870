/* eslint-disable no-case-declarations */
import html2pdf from 'html3pdf';
import { format } from 'date-fns';

import boardStatus from 'constants/boardStatus';
import votePeriods from 'constants/votePeriods';
import signatureTypes from 'constants/signatureTypes';
import signatureAnchorTypes from 'constants/signatureAnchorTypes';

import htmlToPdfFile from './htmlToPdfFile';
import { startSignProcess, toBase64 } from './docusignActions';
import { createBlobMerged } from './downloadMergedPDF';

export const isBoardClosedOrCanceled = (board) => {
  const closedStates = [boardStatus.CLOSED.value, boardStatus.CANCELED.value];

  return closedStates.includes(board?.status);
};

export const isBoardClosed = (board) =>
  board?.status === boardStatus.CLOSED.value;

// returns true if the voting is open based in votePeriod
export const isOpenVoting = (board) => {
  if (!board) return false;
  const { votePeriod } = board;

  const boardDate = board.date ? new Date(board.date) : null;
  const oneHourAfter = new Date(boardDate);
  oneHourAfter.setHours(oneHourAfter.getHours() + 1);

  let isOpen = true;
  switch (votePeriod) {
    case votePeriods[0].value:
      isOpen = !!board.announcementComm;
      break;
    case votePeriods[1].value:
      isOpen = !!board.announcementComm && oneHourAfter > new Date();
      break;

    case votePeriods[2].value:
      isOpen =
        boardDate &&
        (boardDate < new Date() ||
          boardDate.getTime() === new Date().getTime());
      break;
    case votePeriods[3].value:
      isOpen = boardDate < new Date() && oneHourAfter > new Date();
      break;
    case votePeriods[4].value:
      isOpen = !!board?.isActive;
      break;
    default:
      break;
  }
  return isOpen;
};

export const isBoardOutdated = (board) => {
  const today = new Date();

  return today > new Date(board?.date);
};

export const parseEventsToBoard = (events) => {
  const boardEvents = Array.isArray(events)
    ? events.map((event) => ({
        boardId: event.properties.boardId,
        societyId: event.properties.societyId,
        societyName: event.properties.societyName,
        userEmail: event.properties.userEmail,
        userId: event.properties.userId,
        userName: event.properties.userName,
        userIpAddress: event.properties.userIpAddress,
        userDevice: event.properties.userDevice,
        userOs: event.properties.$os,
        userScreen: `${event.properties.$screen_width}x${event.properties.$screen_height}`,
        userBrowser: `${event.properties.$browser} v${event.properties.userBrowserVersion}`,
        operation: event.properties.operation,
        unixTime: event.properties.time,
        date: new Date(event.properties.time * 1000).toISOString(),
        time: new Date(event.properties.time * 1000).toTimeString(),
        region: event.properties.$region,
        city: event.properties.$city,
        country: event.properties.mp_country_code,
        url: event.properties.$current_url,
      }))
    : [];

  return boardEvents;
};

export const getBoardAssistants = (board) => {
  const assistants = board?.participants.filter((p) => p.assists);
  return assistants;
};

export const getAssistantsRepresentation = (assistants) =>
  assistants.reduce((representation, assistant) => {
    const newRepresentation = { ...representation };
    const key =
      assistant?.isRepresented && assistant?.representative
        ? assistant.representative
        : assistant?.member;

    if (!newRepresentation[key]) {
      newRepresentation[key] = [];
    }

    newRepresentation[key].push(assistant?.member);
    return newRepresentation;
  }, {});

export const getSignersInfoFromRepresentation = (
  representation,
  assistants
) => {
  const mainSigners = Object.keys(representation);
  const assistantsWithMembers = assistants
    .filter((assistant) => mainSigners.includes(assistant?.member))
    .map((assistant) => ({
      id: assistant?.member,
      name: assistant?.name,
      email: assistant?.email,
      role: signatureAnchorTypes.BOARD_ASSISTANT,
      type: signatureTypes.BOARD_ASSISTANTS,
      anchors: representation[assistant?.member],
    }));

  return assistantsWithMembers;
};

export const generateBoardAssistantsSignatureMessage = (
  board,
  actualSociety,
  translate
) => {
  const adminSelected = board?.president || board?.secretary || '';
  const adminData = adminSelected
    ? actualSociety?.directors.find(
        (director) => director._id === adminSelected
      )?.socialDenomination
    : `[${translate('Administrator').toUpperCase()}]`;

  const adminName = adminData;
  const societyName = actualSociety?.name;
  const boardName = board?.name;
  const boardDate = format(new Date(board?.date), 'dd/MM/yyyy');

  const message = translate('RequestAssistantsSignatureMessage', {
    adminName,
    societyName,
    boardName,
    boardDate,
  });

  return message;
};

export const requestBoardAssistantsSignature = async (
  actualSociety,
  board,
  certificate,
  translate
) => {
  try {
    const assistants = getBoardAssistants(board);

    const signersRepresentation = getAssistantsRepresentation(assistants);

    const fileName = `Lista de asistentes - ${board?.name}`;

    const certificateFile = await htmlToPdfFile({
      html: certificate,
      fileName,
      certificate,
    });

    const signFileBase64 = await toBase64(certificateFile);

    const signers = getSignersInfoFromRepresentation(
      signersRepresentation,
      assistants
    );

    const signProcessData = {
      signers,
      societyName: actualSociety?.name,
      documentName: fileName,
      planName: board?.name,
      message: generateBoardAssistantsSignatureMessage(
        board,
        actualSociety,
        translate
      ),
      language: actualSociety?.language || 'es',
      redirectParams: [actualSociety?._id],
      customParams: board?._id,
      signatureType: signatureTypes.BOARD_ASSISTANTS,
      signatureFile: signFileBase64,
      signatureFileName: fileName,
    };

    await startSignProcess(signProcessData);
  } catch (error) {
    throw new Error(error);
  }
};

export const requestBoardActSignature = async ({
  actualSociety,
  board,
  htmlContent,
  assistantsHtmlContent,
  translate,
  isCertificate = false,
  includePresidency = true,
  includeAssistants = false,
}) => {
  const signatureType = isCertificate
    ? signatureTypes.BOARD_CERTIFICATE
    : signatureTypes.BOARD_ACT;

  try {
    const fileName = isCertificate
      ? `Certificado de ${board?.name}`
      : `Acta de ${board?.name}`;

    let presidencyData = [];
    let assistantsData = [];
    let assistantsFile = null;

    if (includePresidency) {
      const { president, secretary } = board;

      const presidentData = actualSociety?.directors.find(
        (director) => director._id === president
      );
      const secretaryData = actualSociety?.directors.find(
        (director) => director._id === secretary
      );

      presidencyData = [
        {
          id: presidentData._id,
          name: presidentData.socialDenomination,
          email: presidentData.email,
          role: signatureAnchorTypes.BOARD_PRESIDENT,
          type: signatureType,
        },
        {
          id: secretaryData._id,
          name: secretaryData.socialDenomination,
          email: secretaryData.email,
          role: signatureAnchorTypes.BOARD_SECRETARY,
          type: signatureType,
        },
      ];
    }

    if (includeAssistants) {
      const assistants = getBoardAssistants(board);
      const signersRepresentation = getAssistantsRepresentation(assistants);
      assistantsData = getSignersInfoFromRepresentation(
        signersRepresentation,
        assistants
      );
    }

    const signers = [];

    if (includePresidency) {
      signers.push(...presidencyData);
    }

    if (includeAssistants) {
      signers.push(...assistantsData);
      const assistantsFileName = `Lista de asistentes - ${board?.name}`;
      assistantsFile = await htmlToPdfFile({
        html: assistantsHtmlContent,
        fileName: assistantsFileName,
        certificate: assistantsHtmlContent,
      });
    }

    const options = {
      filename: fileName,
      margin: [10, 10, 10, 10], // top, right, bottom, left
      pagebreak: { mode: 'avoid-all' },
      html2canvas: { scale: 2 },
      jsPDF: {
        unit: 'mm',
        format: 'a4',
        orientation: 'portrait',
      },
    };

    const mainBlob = await html2pdf()
      .from(htmlContent)
      .set(options)
      .output('blob');

    const actFile = new File([mainBlob], fileName, {
      type: 'application/pdf',
    });

    const mergedBlob = includeAssistants
      ? await createBlobMerged({
          documents: [actFile, assistantsFile],
        })
      : mainBlob;

    const signFile = new File([mergedBlob], fileName, {
      type: 'application/pdf',
    });

    const signFileBase64 = await toBase64(signFile);

    const signProcessData = {
      signers,
      societyName: actualSociety?.name,
      documentName: fileName,
      planName: board?.name,
      message: translate('RequestBoardCertificateSignatureMessage', {
        societyName: actualSociety?.name,
        boardName: board?.name,
      }),
      language: actualSociety?.language || 'es',
      redirectParams: [actualSociety?._id, board?._id],
      customParams: board?._id,
      signatureType,
      signatureFile: signFileBase64,
      signatureFileName: fileName,
    };

    await startSignProcess(signProcessData);
  } catch (error) {
    throw new Error(error);
  }
};
