import React from 'react';
import { useTranslation } from 'react-i18next';
import { getConsolidationPeriods } from 'utils/filters';
import BeneficiaryTransactionsRow from 'components/BeneficiaryTransactions/BeneficiaryTransactionsRow';

const CalendarTab = ({ beneficiary, plan }) => {
  const { t } = useTranslation();
  const periods = getConsolidationPeriods(beneficiary, plan);

  const rows = periods.map((period, index) => (
    <BeneficiaryTransactionsRow
      period={period}
      index={index + 1}
      key={period?.date.toString()}
    />
  ));

  return (
    <div className="card-inner p-0">
      <h6>{t('ConsolidationCalendarOf')}</h6>

      {rows.length ? (
        <div className="nk-block nk-block-lg pt-1">
          <div className="card card-bordered card-preview">
            <table
              className="table table-striped table-responsive"
              id="consolidationTable"
            >
              <thead>
                <tr>
                  <th scope="col">{t('Nr')}</th>
                  <th scope="col">{t('Date')}</th>
                  <th scope="col" style={{ textAlign: 'center' }}>
                    {t('UnitsSeen')}
                  </th>
                  <th scope="col" style={{ textAlign: 'center' }}>
                    {t('TotalUnitsVeiled')}
                  </th>
                  <th scope="col">{t('Status')}</th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </table>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default CalendarTab;
