/* eslint-disable no-underscore-dangle */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { EditorState, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import i18n from 'i18n/config';
import html2pdf from 'html3pdf';
import ReactHtmlParser from 'react-html-parser';
import { useTranslation } from 'react-i18next';

import store from 'redux/store';
import { addAlert } from 'redux/actions/alertActions';
import { updateBoard } from 'redux/actions/boardActions';
import { updatePreview } from 'redux/actions/previewActions';
import {
  addDocument,
  getDocumentsByReference,
  updateDocument,
} from 'redux/actions/documentActions';

import {
  isBoardClosed,
  isBoardClosedOrCanceled,
  requestBoardActSignature,
} from 'utils/boards';

import Dialog from 'components/Dialog';
import EditorWYSIWYG from 'components/EditorWYSIWYG';
import alertBodyTypes from 'components/Alert/alertBodyTypes';
import PreviewDropdown from 'components/Dropdowns/PreviewDropdown';
import DocumentsGenerator from 'components/Documents/Creators/DocumentsGenerator';

import fileTypes from 'constants/fileTypes';
import documentTypes from 'constants/documentTypes';

import getBoardAssistants from '../BoardAssistantsTable/getBoardAssistants';

import menuOptions from './menuOptions';

import './Preview.scss';

const BoardActPreview = ({ board }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const printRef = useRef();

  const { user, preview, actualSociety } = useSelector((state) => ({
    user: state.user,
    preview: state.preview,
    actualSociety: state.society?.actualSociety,
  }));

  const isDemo = useSelector((state) => state.society?.role?.isDemo);

  const [currentMenuOptions, setCurrentMenuOptions] = useState(
    menuOptions(i18n)
  );

  const [isDraft] = useState(!isBoardClosedOrCanceled(board));
  const [showEditor, setShowEditor] = useState(false);
  const [editorState, setEditorState] = useState();
  const [htmlContent, setHtmlContent] = useState(preview?.documentModel || '');

  const handleCloseEditor = async () => {
    try {
      const document = (
        <DocumentsGenerator
          invitationModel={htmlContent}
          hasConsolidationCalendar={false}
          consolidationCalendar={{}}
        />
      );
      await store.dispatch(
        updatePreview({ document, documentModel: htmlContent })
      );
      await store.dispatch(
        updateDocument(
          { ...preview.bdDocument, editorModel: htmlContent },
          false
        )
      );
      // delete old announcement document and generate the new one
      if (board.announcementDocument) {
        await store.dispatch(
          updateDocument(
            {
              _id: board.announcementDocument,
              category: documentTypes.DELETED,
            },
            false
          )
        );
      }
      const options = {
        filename: t('AnnouncementBoard'),
        margin: [10, 10, 10, 10], // top, right, bottom, left
        pagebreak: { mode: 'avoid-all' },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: 'mm',
          format: 'a4',
          orientation: 'portrait',
        },
      };
      const fileBlob = await html2pdf()
        .from(htmlContent)
        .set(options)
        .output('blob');

      const announcementFile = new File([fileBlob], t('AnnouncementBoard'), {
        type: 'application/pdf',
      });
      const newAnnouncementDocument = {
        name: t('AnnouncementBoard'),
        file: announcementFile,
        fileType: fileTypes.PDF,
        size: announcementFile.size,
        date: new Date(),
        lastAccess: new Date(),
        category: documentTypes.DOCUMENTS,
        author: user['_id'],
        society: actualSociety['_id'],
        isGenerated: true,
        description: `Generado por la plantilla ${t('AnnouncementTemplate')}_${
          preview?.name
        }`,
      };
      const announcementDocument = await store.dispatch(
        addDocument(newAnnouncementDocument)
      );
      dispatch(
        updateBoard(board['_id'], {
          announcementDocument,
        })
      );
      dispatch(getDocumentsByReference({ societyId: actualSociety['_id'] }));
      dispatch(addAlert(alertBodyTypes.DOCUMENT_GENERATED));
    } catch (error) {
      dispatch(addAlert(alertBodyTypes.ERROR_GENERATING_DOCUMENT));
    } finally {
      setShowEditor(false);
    }
  };

  const printOption = {
    icon: 'ni-printer',
    text: i18n.t('Print'),
    print: true,
    content: printRef.current,
    documentTitle: preview?.name,
    disabled: false,
  };

  const createAssistantsList = async () => {
    try {
      const assistants = await getBoardAssistants(board, actualSociety, t);

      const certificate = {
        header: t('AttendanceCertificateTitle', {
          societyName: actualSociety?.name,
        }),
        subHeader: '',
        tableColumns: [
          'Nº',
          t('Name'),
          `Nº ${t('Share.')}`,
          '% ND',
          t('RepresentativeBoard'),
          t('Attend'),
          t('Signature'),
        ],
        tableData: assistants,
      };

      return certificate;
    } catch (error) {
      dispatch(addAlert(alertBodyTypes.BOARD_ASSISTANTS_SIGNATURE_ERROR));
    }
  };

  const handleRequestSignature = async () => {
    if (!isBoardClosed(board)) {
      dispatch(addAlert(alertBodyTypes.BOARD_NOT_CLOSED));
      return;
    }
    if (!board.president) {
      dispatch(addAlert(alertBodyTypes.BOARD_WITHOUT_PRESIDENT));
      return;
    }
    if (!board.secretary) {
      dispatch(addAlert(alertBodyTypes.BOARD_WITHOUT_SECRETARY));
      return;
    }

    Dialog({
      icon: 'ni-send',
      title: t('AreYouSure'),
      body: t('BoardActSignRequestMessage'),
      buttonText: t('YesSend'),
      radioOptions: {
        PRESIDENT_AND_SECRETARY: t('PresidentAndSecretary'),
        PRESIDENT_AND_SECRETARY_AND_ASSISTANTS: t(
          'PresidentAndSecretaryAndAssistants'
        ),
        ONLY_ASSISTANTS: t('OnlyAssistants'),
      },
      onRadioChecked: async (option) => {
        if (option === 'PRESIDENT_AND_SECRETARY') {
          await requestBoardActSignature({
            actualSociety,
            board,
            htmlContent: preview.documentModel,
            translate: t,
          });
        } else if (option === 'PRESIDENT_AND_SECRETARY_AND_ASSISTANTS') {
          const assistantsList = await createAssistantsList();
          await requestBoardActSignature({
            actualSociety,
            board,
            htmlContent: preview.documentModel,
            assistantsHtmlContent: assistantsList,
            translate: t,
            isCertificate: false,
            includeAssistants: true,
          });
        } else if (option === 'ONLY_ASSISTANTS') {
          const assistantsList = await createAssistantsList();
          await requestBoardActSignature({
            actualSociety,
            board,
            htmlContent: preview.documentModel,
            assistantsHtmlContent: assistantsList,
            translate: t,
            isCertificate: false,
            includeAssistants: true,
            includePresidency: false,
          });
        }
      },
      onError: () => {},
    });
  };

  useEffect(() => {
    if (showEditor && preview?.documentModel) {
      const contentToEditor = htmlToDraft(preview?.documentModel);
      const contentState = ContentState.createFromBlockArray(
        contentToEditor.contentBlocks
      );
      setEditorState(EditorState.createWithContent(contentState));
    } else {
      setEditorState(EditorState.createEmpty());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showEditor]);

  useEffect(() => {
    if (preview) {
      setHtmlContent(preview?.documentModel);
    }
  }, [preview]);

  useEffect(() => {
    const filteredOptions = menuOptions(i18n).map((option) => option);
    setCurrentMenuOptions(filteredOptions);
  }, [board]);

  return (
    <>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between position-relative">
          <div className="nk-block-head-content">
            <h5 className="title nk-block-title">
              {isDraft && `${t('Draft')} - `}
              {t('Act')}:
            </h5>
            <div className="nk-block-des">
              <p>
                {t('UsedTemplate')}:{' '}
                <span className="text-primary">{preview?.name}</span>
              </p>
            </div>
          </div>
          <div className="nk-block-head-content">
            <ul className="nk-block-tools g-3">
              {!showEditor ? (
                <>
                  <li>
                    <button
                      type="button"
                      className="btn btn-outline-light ml-1"
                      onClick={handleRequestSignature}
                      disabled={isDemo}
                    >
                      <em className="icon ni ni-edit-alt" />
                      <span>{t('RequestSignature')}</span>
                    </button>
                  </li>
                  {!isDemo && (
                    <li>
                      <PreviewDropdown
                        menuOptions={[printOption, ...currentMenuOptions]}
                        params={{
                          society: actualSociety,
                          user,
                          preview,
                          setShowEditor,
                          board,
                        }}
                      />
                    </li>
                  )}
                </>
              ) : (
                <li>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleCloseEditor}
                  >
                    <em className="icon ni ni-check-round-cut mr-2" />
                    {t('Save')}
                  </button>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>

      {preview?.documentModel && (
        <div className="card card-bordered w-100" id="preview--box">
          {!showEditor ? (
            <div className="card-inner card-inner-xl" ref={printRef}>
              {ReactHtmlParser(preview?.documentModel)}
            </div>
          ) : (
            <EditorWYSIWYG
              editorContent={htmlContent}
              setEditorContent={setHtmlContent}
            />
          )}
        </div>
      )}
    </>
  );
};

export default BoardActPreview;
