/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getDocumentsByReference } from 'redux/actions/documentActions';
import {
  getTransactionsByPartner,
  getPartnerOperations,
  getPartnerSharesDetails,
} from 'utils/filters';

import PartnerDividends from 'components/PartnerDividends';

import documentTypes from 'constants/documentTypes';
import PartnerTransactionsRow from './PartnerTransactionsRow';
import PartnerTransactionsTable from './PartnerTransactionsTable';

function PartnerTransactions({ society, partner }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { documents } = useSelector((state) => ({
    documents: state.documents,
  }));

  const [rows, setRows] = useState([]);
  const [partnerShares] = useState(getPartnerSharesDetails(partner, society));

  const getDocumentsFromTransaction = (transaction) => {
    if (!documents) return [];

    return documents?.filter(
      (document) =>
        document?.category !== documentTypes.DELETED &&
        transaction?.documents.includes(document?.['_id'])
    );
  };

  const getTagFromTransaction = (transaction, partnerShares) => {
    if (!society.hasShareTags) return '-';

    const partnerShare = partnerShares.find(
      (share) =>
        share?.from === transaction?.shareFrom &&
        share?.to === transaction?.shareTo &&
        share?.nominalValue === transaction?.nominalValue &&
        share !== ''
    );

    return partnerShare?.tag;
  };

  const getInvertedIndex = (index, length) => length - index;

  const sortAndCreateRows = (society, partner) => {
    if (!society || !partner) return [];

    const partnerTransactions = getTransactionsByPartner(
      getPartnerOperations(partner, society),
      partner
    );

    const sortedTransactions = partnerTransactions
      ?.sort((a, b) => new Date(b.date) - new Date(a.date))
      ?.map((transaction, index) => (
        <PartnerTransactionsRow
          index={getInvertedIndex(index, partnerTransactions?.length)}
          society={society}
          transaction={transaction}
          documents={getDocumentsFromTransaction(transaction)}
          tag={getTagFromTransaction(transaction, partnerShares)}
        />
      ));

    return sortedTransactions;
  };

  useEffect(() => {
    if (society || (partner && documents)) {
      const createdRows = sortAndCreateRows(society, partner);
      setRows(createdRows);
    }
  }, [society, partner, documents]);

  useEffect(() => {
    if (society) {
      dispatch(getDocumentsByReference({ societyId: society['_id'] }));
    }
  }, [society]);

  return (
    <div className="card-inner">
      <div className="nk-block-head">
        <div className="nk-block-head-content">
          <h4 className="nk-block-title">{t('PartnerTransactions')}</h4>
          <div className="nk-block-des">
            <p>
              {t('TransactionsInSocietyName', {
                societyName: society.name,
                partnerName: partner.name || '',
              })}
            </p>
          </div>
        </div>
      </div>
      {rows.length ? (
        <div className="nk-block nk-block-lg">
          <PartnerTransactionsTable
            rows={rows}
            hasShareTags={society?.hasShareTags}
          />
        </div>
      ) : (
        <p>
          {t('ItStillDoesNotHaveTransac', {
            partnerName: partner.name || '',
            societyName: society.name,
          })}
        </p>
      )}

      <div className="nk-block-head mt-2">
        <div className="nk-block-head-content">
          <h4 className="nk-block-title">{t('Dividends')}</h4>
          <div className="nk-block-des">
            <p>
              {t('DividendsDetails', {
                societyName: society.name,
              })}
            </p>
          </div>
        </div>
      </div>
      <div className="nk-block nk-block-lg">
        <PartnerDividends
          partner={partner}
          society={society}
          showTitle={false}
        />
      </div>
    </div>
  );
}

export default PartnerTransactions;
