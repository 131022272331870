/* eslint-disable no-console */
import store from 'redux/store';
import { addAlert } from 'redux/actions/alertActions';
import alertBodyTypes from 'components/Alert/alertBodyTypes';

import axios from '../interceptors/axios';
import { decryptResponse } from './token';

async function downloadFile({ documentId }) {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/download/${documentId}`
    );
    const decodedData = await decryptResponse(data);
    window.open(decodedData?.url, '_blank');
  } catch (error) {
    store.dispatch(addAlert(alertBodyTypes['ERROR_OPENING_DOCUMENT']));
  }
}

export default downloadFile;
