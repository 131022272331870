import Swal from 'sweetalert2';

import store from 'redux/store';

import { sendCommEmail } from 'redux/actions/communicationActions';
import LogoSttokBase64 from 'components/Logos/LogoSttokBase64';

const sendReminder = async ({ t, actualSociety, user, beneficiaries }) => {
  Swal.fire({
    html: `<div class="modal-body modal-body-lg text-center">
              <div class="nk-modal">
                <em class="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-send bg-primary-alt"></em>
                <h4 class="nk-modal-title">${t('SendReminder')}</h4>
                <div class="nk-modal-text">
                  <p class="lead">

          ${t('SendReminderPendingInvitations', {
            societyName: actualSociety.name,
          })}
                  </p>
                  <p class="text-soft fs-14px">
                  ${t('SendReminderPendingInvitationsFootMessage')}
                  </p>
                </div>
              </div>
            </div>`,
    confirmButtonText: t('SendNow'),
    confirmButtonColor: '#6576ff',
    showCloseButton: true,
  }).then(async (result) => {
    if (result.isConfirmed) {
      try {
        const recipients = beneficiaries.map((recipient) => ({
          email: recipient.email,
          name: recipient.name,
        }));

        const recipientsForDynamicData = beneficiaries.map((recipient) => {
          const { _id, email, name } = recipient;
          return {
            id: _id,
            email: email ? email.trim() : '',
            name,
          };
        });

        const data = {
          societyName: actualSociety?.name || '-',
        };

        const emailData = {
          to: recipients,
          cc: '',
          bcc: '',
          files: [],
          subject: t('SendReminderPendingInvitationsEmailSubject'),
          bodySummary: t('ApreciatedBeneficiary'),
          template: 'invitation-letter-reminder-es',
          templateBody: `    <div class="main" style="padding: 24px">
          <div style="
              background: #f5f6fa;
              font-family: Roboto;
              font-size: 14px;
              line-height: 22px;
              font-weight: 400;
              color: #8094ae;
              width: 100%;
              text-align: center;
            ">
            <a href="https://www.sttok.com">
              <img src="${LogoSttokBase64}" alt="logo" style="height: 40px; margin-top: 2.75rem"/>
            </a>
            <p style="
                font-size: 13px;
                color: #6576ff;
                padding-top: 6px;
                margin-top: 0;
                padding-bottom: 24px;
              ">
              Gestión de Socios
            </p>
            <div class="email-body" style="
                width: 96%;
                max-width: 620px;
                text-align: left;
                margin: 0 auto;
                padding: 1.75rem 2.75rem;
                background: #ffffff;
              ">
              <h2 style="
            font-family: Nunito, sans-serif;
                font-size: 18px;
                color: #6576ff;
                font-weight: 600;
                margin: 0;
                line-height: 1.4;
                margin: 10px 0;
                padding-bottom: 15px;
                text-align: left;
                ">
                  Recordatorio de Firma de Carta de Invitación de ${data.societyName}
              </h2>
                <p style="text-align: left;">Apreciado beneficiario, recuerda que tienes cartas de invitación pendientes de firma, por favor, no te demores en completarla.</p>
                <p style="text-align: justify">
                    En tu bandeja de entrada puedes buscar por el nombre del plan y localizarás las cartas correspondientes.
                </p>
              </div>
              <p style="padding-bottom: 2.75rem; padding-top: 2.75rem; margin: 0">
                Correo enviado por
                  <a style="color: #6576ff; text-decoration: none" href="https://sttok.com/">sttok.com</a> Puede contactar en <a href="mailto:info@sttok.com" target="_blank">info@sttok.com
                  </a>
              </p>
          </div>
        </div>`,
          templateData: [
            {
              name: 'societyName',
              content: data.societyName,
            },
          ],
          templateDynamicData: recipientsForDynamicData.map(
            ({ name, email }) => ({
              rcpt: email,
              vars: [
                { name: 'name', content: name },
                { name: 'email', content: email },
              ],
            })
          ),
          sender: {
            name: `Sttok | ${actualSociety?.name}`,
            email: 'info@sttok.com',
          },
          user: user['_id'],
          society: actualSociety['_id'],
          date: new Date(),
        };
        await store.dispatch(sendCommEmail(emailData));
      } catch (error) {
        console.log(error);
      }
    }
  });
};

export default sendReminder;
