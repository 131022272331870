import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import CustomDataTable from 'components/Tables/CustomDataTable';

type BeneficiaryPlanTableProps = {
  data: any;
  columns: any;
};

const BeneficiaryPlanTable: FC<BeneficiaryPlanTableProps> = ({
  data,
  columns,
}) => {
  const { t } = useTranslation();

  return (
    <div className="nk-block">
      <div className="card card-bordered card-stretch">
        <div className="card-inner-group">
          <div className="card-inner p-0">
            <CustomDataTable
              data={data}
              columns={columns}
              searchBy={t('Name')}
              pagination
              className="nk-tb-list"
              actions
              showDownload={false}
              // extra params
              selectableRows={undefined}
              expandableRows={undefined}
              rowsPerPage={undefined}
              defaultSortFieldId={undefined}
              toggleNationality={undefined}
              customData={undefined}
              filterText={undefined}
              filterIcon={undefined}
              filterByParam={undefined}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BeneficiaryPlanTable;
