/* eslint-disable no-console */
import axios from '../interceptors/axios';
import { decryptResponse } from './token';

async function fetchMixpanelEvents(field, value) {
  try {
    const query = {
      params: {
        field,
        value,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/mixpanel_events`,
      query
    );

    const decodedData = await decryptResponse(data);

    return decodedData;
  } catch (error) {
    console.error(error);
  }
}

export default fetchMixpanelEvents;
