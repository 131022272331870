/* eslint-disable react/jsx-props-no-spreading */
import Nouislider from 'nouislider-react';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import wNumb from 'wnumb';
import { useParams } from 'react-router-dom';

import { currencyFormatDecimals } from 'constants/formats';
import { useDispatch, useSelector } from 'react-redux';
import { setModal } from 'redux/actions/modalActions';
import BeneficiarySell from 'components/Modals/BeneficiarySell';
import sizes from 'constants/sizes';
import { Beneficiary } from 'types';
import { State } from 'redux/initialState';
import hasShowDetails from 'utils/showDetails';

type BeneficiarySocietyValueSimulatorProps = {
  societyValue: {
    value: number;
  };
  onChange: (value: any) => void;
  valuation: number;
  onClickSimulatorButton?: () => void;
};

const BeneficiarySocietyValueSimulator: FC<
  BeneficiarySocietyValueSimulatorProps
> = ({ societyValue, onChange, valuation, onClickSimulatorButton }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { beneficiaryId } = useParams();

  const currentBeneficiary =
    useSelector((state: State) =>
      state.society?.actualSociety?.beneficiaries?.find(
        (beneficiary: Beneficiary) => beneficiary._id === beneficiaryId
      )
    ) || {};

  const sliderStep = societyValue
    ? +((societyValue.value * 10) / 100).toFixed(2)
    : 0;

  return (
    <div className="card-inner">
      <div className="overline-title-alt text-center pb-3">
        {t('SocietyValueSimulator')}
      </div>

      {societyValue?.value && (
        <div className="form-control-wrap m-5">
          <Nouislider
            start={Math.trunc(societyValue.value)}
            range={{
              min: Math.trunc(societyValue.value),
              max: Math.trunc(societyValue.value) * 10,
            }}
            step={sliderStep}
            format={wNumb({
              decimals: 0,
              thousand: '.',
              suffix: ' €',
            })}
            animate
            tooltips
            connect
            onChange={(value) => onChange(value)}
          />
        </div>
      )}

      <div className="overline-title-alt text-center mt-4">
        {t('SimulatedIncentiveTotalValue')}
      </div>

      <div className="profile-balance-amount mt-2">
        <div className="number text-center">
          <NumberFormat
            value={valuation || 0}
            displayType="text"
            {...currencyFormatDecimals}
          />
        </div>
      </div>

      <div className="mt-4 text-center">
        <button
          type="button"
          className="btn btn-outline-primary"
          onClick={onClickSimulatorButton}
        >
          {t('CalculateIncentiveValue')}
        </button>
      </div>
    </div>
  );
};

export default BeneficiarySocietyValueSimulator;
