/* eslint-disable no-underscore-dangle */
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import { getPartnerNDPercent } from 'utils/filters';
import voteTypes from 'constants/voteTypes';
import { getBoards } from 'redux/actions/boardActions';

import BoardVotesTable from './BoardVotesTable';
import BoardOrdersTable from './BoardOrdersTable';

const BoardParticipants = ({ board }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const votesTableRef = useRef();
  const { actualSociety } = useSelector((state) => ({
    actualSociety: state.society?.actualSociety,
  }));

  const isDemo = useSelector((state) => state.society?.role?.isDemo);

  const [votesSummary, setVotesSummary] = useState({});
  const [selectedOrder, setSelectedOrder] = useState('default_option');

  const [selectOnlyAssistants, setSelectOnlyAssistants] = useState(false);

  const handleSelectOnlyAssistants = () => {
    setSelectOnlyAssistants(!selectOnlyAssistants);
  };

  useEffect(() => {
    if (selectedOrder !== 'default_option') {
      if (votesTableRef.current) {
        const containerPosition = votesTableRef.current.offsetTop;
        window.scrollTo({
          top: containerPosition,
          behavior: 'smooth',
        });
      }
    }
  }, [selectedOrder]);

  useEffect(() => {
    if (board && actualSociety) {
      const participantsTotal = board?.participants?.length;
      const ordersData = board.orders.reduce((acc, order) => {
        acc[order?.['_id']] = {};
        if (order.voteType !== voteTypes.NONE) {
          board.participants.forEach((participant) => {
            const orderVote = participant?.votes?.find(
              (vote) => vote.order === order['_id']
            );
            if (orderVote) {
              const partner = actualSociety?.partners?.find(
                (partner) => partner['_id'] === participant.member
              );
              const index = orderVote?.vote?.toString();
              if (acc[order?.['_id']][index]) {
                acc[order?.['_id']][index].voteCount += 1;
                acc[order?.['_id']][index].ndPercent += getPartnerNDPercent(
                  partner,
                  actualSociety
                );
                acc[order?.['_id']][index].votePercent =
                  (acc[order?.['_id']][index].voteCount * 100) /
                  participantsTotal;
              } else {
                acc[order?.['_id']][index] = {
                  voteCount: 1,
                  votePercent: 100 / participantsTotal,
                  ndPercent: getPartnerNDPercent(partner, actualSociety),
                };
              }
            }
          });
          const sortedData = Object.entries(acc[order?.['_id']]).sort(
            ([, a], [, b]) => a.voteCount - b.voteCount
          );

          acc[order?.['_id']] = Object.fromEntries(sortedData);
        }
        return acc;
      }, {});
      setVotesSummary(ordersData);
    }
  }, [board, actualSociety]);

  return (
    <>
      {board?.orders?.length && votesSummary && (
        <div className="nk-block pt-4">
          <h6 className="title">{t('Subjects')}</h6>
          <div className="card card-bordered">
            <div className="card-inner-group">
              <div className="card-inner">
                <BoardOrdersTable
                  board={board}
                  selectedOrder={selectedOrder}
                  setSelectedOrder={setSelectedOrder}
                  votesSummary={votesSummary}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="nk-block pt-4" ref={votesTableRef}>
        <div className="card card-bordered">
          <div className="card-inner-group">
            {board.orders.length ? (
              <>
                <div className="card-inner">
                  <div className="card-title-group">
                    <div className="sp-plan-head">
                      <h6 className="title">{t('Voting')}</h6>
                    </div>
                    <div className="card-tools d-flex">
                      <select
                        className="form-control "
                        value={selectedOrder}
                        onChange={(event) =>
                          setSelectedOrder(event.target.value)
                        }
                      >
                        <option value="default_option">
                          {t('SelectVote')}
                        </option>
                        {board?.orders?.map((order, index) => (
                          <option value={order?.['_id']} key={order._id}>
                            {index + 1}.&nbsp;
                            {order.subject.length > 50
                              ? `${order.subject.substring(0, 50)}...`
                              : order.subject}
                          </option>
                        ))}
                      </select>
                      <button
                        type="button"
                        className="btn btn-outline-light ml-2"
                        onClick={() =>
                          dispatch(getBoards(actualSociety['_id']))
                        }
                        disabled={isDemo}
                      >
                        <em className="icon ni ni-reload" />
                        <span>{t('CountVotes')}</span>
                      </button>
                      <button
                        type="button"
                        className="btn btn-outline-light ml-2"
                        onClick={() => handleSelectOnlyAssistants()}
                        disabled={isDemo}
                      >
                        {selectOnlyAssistants ? (
                          <>
                            <em className="icon ni ni-user-cross" />{' '}
                            <span>{t('CancelSelection')}</span>
                          </>
                        ) : (
                          <>
                            <em className="icon ni ni-user-check" />
                            <span>{t('SelectAttendees')}</span>
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
                {selectedOrder !== 'default_option' && (
                  <div className="card-inner p-0">
                    <BoardVotesTable
                      board={board}
                      order={selectedOrder}
                      selectOnlyAssistants={selectOnlyAssistants}
                      setSelectOnlyAssistants={setSelectOnlyAssistants}
                    />
                  </div>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default BoardParticipants;
