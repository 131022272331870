/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setMenuType } from 'redux/actions/menuActions';
import { setSociety } from 'redux/actions/societyActions';
import { addAlert } from 'redux/actions/alertActions';

import getRedirectURL from 'utils/getRedirectURL';
import hasShowDetails, { hasInternalView } from 'utils/showDetails';

import menuTypes from 'constants/menuTypes';

import AdministratedList from 'pages/AdministratedList';
import ParticipatedList from 'pages/ParticipatedList';
import BeneficiatedList from 'pages/BeneficiatedList';
import InvestedList from 'pages/InvestedList';

import alertBodyTypes from 'components/Alert/alertBodyTypes';

import '../../assets/styles/Misc.scss';
import '../../assets/styles/Layouts.scss';
import '../../assets/styles/Components.scss';
import '../../assets/styles/Bootstrap.scss';
import '../../assets/styles/Print.scss';
import './Dashboard.scss';

const dashboardTypes = {
  ADMIN: 'ADMIN',
  PARTNER: 'PARTNER',
  BENEFICIARY: 'BENEFICIARY',
  INVESTOR: 'INVESTOR',
};

function Dashboard() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state);

  const [dashboardType, setDashboardType] = useState('');
  const [isCompletedRedirections, setIsCompletedRedirections] = useState(false);

  const [hasAdministrated, setHasAdministrated] = useState(false);
  const [hasParticipated, setHasParticipated] = useState(false);
  const [hasBeneficiated, setHasBeneficiated] = useState(false);
  const [hasInvested, setHasInvested] = useState(false);

  const getGlobalRole = (currentUser) => {
    const { participated, beneficiated, administrated, invested } =
      currentUser.societies || {};

    const isPartner = !!participated.length > 0;
    const isBeneficiary = !!beneficiated.length > 0;
    const isAdministrator = !!administrated.length > 0;
    const isInvestor = !!invested.length > 0;

    setHasParticipated(isPartner);
    setHasBeneficiated(isBeneficiary);
    setHasAdministrated(isAdministrator);
    setHasInvested(isInvestor);

    if (isAdministrator) return dashboardTypes.ADMIN;
    if (isPartner) return dashboardTypes.PARTNER;
    if (isBeneficiary) return dashboardTypes.BENEFICIARY;
    if (isInvestor) return dashboardTypes.INVESTOR;

    return dashboardTypes.PARTNER;
  };

  const checkRedirections = async () => {
    const defaultRoute = '#/';
    const { prefix, redirectUrl } = await getRedirectURL();

    window.location.href = prefix ? `${prefix}${redirectUrl}` : defaultRoute;

    localStorage.removeItem('redirect_url');
    localStorage.removeItem('sign_redirect');
    localStorage.removeItem('redirect_source');

    setIsCompletedRedirections(true);
  };

  useEffect(() => {
    if (!user) return;
    setDashboardType(getGlobalRole(user));
  }, [user]);

  useEffect(() => {
    dispatch(setMenuType(menuTypes.MAIN));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCompletedRedirections]);

  useEffect(() => {
    checkRedirections();
  }, []);

  useEffect(() => {
    dispatch(setSociety(null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isCompletedRedirections ? (
    <div className="nk-content-inner">
      {hasInternalView() && (
        <div className="nk-content-body d-flex flex-row justify-content-between">
          <button
            type="button"
            className="btn btn-warning mt-5"
            onClick={() => dispatch(addAlert(alertBodyTypes[500]))}
          >
            Error 500
          </button>

          <button
            type="button"
            className="btn btn-success mt-5"
            onClick={() =>
              dispatch(addAlert(alertBodyTypes.TENDER_OFFER_SENT_es))
            }
          >
            Success
          </button>
          <button
            type="button"
            className="btn btn-info mt-5"
            onClick={() =>
              dispatch(addAlert(alertBodyTypes.TENDER_OFFER_REJECTED_es))
            }
          >
            Info
          </button>
          <button
            type="button"
            className="btn btn-secondary mt-5"
            onClick={() => dispatch(addAlert(alertBodyTypes.ARE_YOU_SURE))}
          >
            Question
          </button>
          <button
            type="button"
            className="btn btn-danger mt-5"
            onClick={() => dispatch(addAlert(alertBodyTypes[401]))}
          >
            Error
          </button>
        </div>
      )}
      {dashboardType === dashboardTypes.ADMIN && (
        <>
          <AdministratedList items={5} />
          {hasParticipated && <ParticipatedList items={5} />}
          {hasBeneficiated && <BeneficiatedList items={5} />}
          {hasInvested && <InvestedList items={5} />}
        </>
      )}
      {dashboardType === dashboardTypes.PARTNER && (
        <>
          <ParticipatedList items={5} />
          {hasAdministrated && <AdministratedList items={5} />}
          {hasBeneficiated && <BeneficiatedList items={5} />}
          {hasInvested && <InvestedList items={5} />}
        </>
      )}
      {dashboardType === dashboardTypes.BENEFICIARY && (
        <>
          <BeneficiatedList items={5} />
          {hasParticipated && <ParticipatedList items={5} />}
          {hasInvested && <InvestedList items={5} />}
          {hasAdministrated && <AdministratedList items={5} />}
        </>
      )}
      {dashboardType === dashboardTypes.INVESTOR && (
        <>
          <InvestedList items={5} />
          {hasParticipated && <ParticipatedList items={5} />}
          {hasBeneficiated && <BeneficiatedList items={5} />}
          {hasAdministrated && <AdministratedList items={5} />}
        </>
      )}
    </div>
  ) : (
    <></>
  );
}

export default Dashboard;
