/* eslint-disable no-case-declarations */
import types from '../actions/actionTypes';
import initialState from '../initialState';

export default function reducer(user = initialState.user, action) {
  let result;
  let administrated;
  let societies;
  switch (action.type) {
    case types.SET_USER:
      result = action.user;
      break;
    case types.CREATE_SOCIETY:
      administrated = [...user.societies.administrated, action.society];
      societies = { ...user.societies, administrated };
      result = { ...user, societies };
      break;
    case types.DELETE_SOCIETY:
      administrated = user.societies.administrated.filter(
        (societyId) => societyId !== action.society
      );
      societies = { ...user.societies, administrated };
      result = { ...user, societies };
      break;
    default:
      result = null;
      break;
  }
  return result || user;
}
