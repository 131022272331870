/* eslint-disable no-await-in-loop */
/* eslint-disable no-plusplus */
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import { addDocument } from 'redux/actions/documentActions';
import { updateSociety } from 'redux/actions/societyActions';
import { setModal } from 'redux/actions/modalActions';
import store from 'redux/store';

import documentTypes from 'constants/documentTypes';
import fileTypes from 'constants/fileTypes';
import statusTypes from 'components/SocietyAuditors/statusTypes';
import statusTypesOptions from 'components/SocietyAuditors/statusTypesOptions';
import { EMAIL_PATTERN } from 'constants/defaultConstants';
import Swal from 'sweetalert2';
import documentLinkTypes from 'constants/documentLinkTypes';

function AddSocietyAuditor({
  societyId,
  action,
  auditorData,
  auditorId,
  userId,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { actualSociety } = useSelector((state) => ({
    actualSociety: state.society?.actualSociety,
  }));
  const [socialDenomination, setSocialDenomination] = useState('');
  const [nationality, setNationality] = useState('');
  const [initDate, setInitDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [address, setAddress] = useState('');
  const [status, setStatus] = useState('');
  const [email, setEmail] = useState('');
  const [files, setFiles] = useState('');
  const [cif, setCif] = useState('');

  async function uploadDocuments(auditorId) {
    if (files.length) {
      const docs = [];
      const date = initDate || new Date();
      // eslint-disable-next-line no-restricted-syntax
      for (const file of files) {
        const newFile = {
          file,
          size: file.size,
          date,
          fileType: fileTypes[file?.type],
          category: documentTypes.DOCUMENTS,
          name: file.name,
          author: userId,
          society: societyId,
          description: t('FileAssociatedAuditor'),
          linkedTo: auditorId
            ? [
                {
                  linkedId: auditorId,
                  linkedType: documentLinkTypes.AUDITOR,
                },
              ]
            : [],
        };

        const uploadedDocument = await store.dispatch(addDocument(newFile));

        if (uploadedDocument['_id']) {
          docs.push(uploadedDocument['_id']);
        }
      }
      return docs;
    }
  }

  const handleUpdateAuditor = async (event) => {
    event.preventDefault();
    let newAuditorData = [];
    const newAuditor = {
      socialDenomination,
      cif,
      nationality,
      address,
      email,
      initDate,
      endDate,
      auditorStatus: status,
      documents: files,
    };

    // Upload the documents.
    const documents = await uploadDocuments(auditorId);

    if (action === 'ADD') {
      newAuditor.documents = documents;
      newAuditorData = actualSociety?.auditors
        ? [...actualSociety.auditors, newAuditor]
        : [newAuditor];
    } else if (action === 'EDIT') {
      const oldDocuments = newAuditor.documents || [];
      newAuditor.documents = documents
        ? [...oldDocuments, ...documents]
        : oldDocuments;

      newAuditorData = actualSociety?.auditors.map((auditor) => {
        if (auditor['_id'] === auditorId) {
          return { ...newAuditor };
        }
        return auditor;
      });
    }

    const newSocietyData = {
      auditors: newAuditorData,
    };

    dispatch(updateSociety(societyId, newSocietyData, false));
    dispatch(setModal(null));
    return Swal.fire({
      icon: 'success',
      title: `<h4 class="nk-modal-title">${
        action === 'ADD'
          ? t('AuditorAddedSuccessfully')
          : t('AuditorUpdatedSuccessfully')
      }<br></h4>`,
      confirmButtonText: t('OK'),
      allowOutsideClick: false,
      showCancelButton: false,
      confirmButtonColor: '#6576FF',
    });
  };

  const loadInitialValues = () => {
    setSocialDenomination(auditorData.socialDenomination);
    setCif(auditorData.cif);
    setNationality(auditorData.nationality);
    setAddress(auditorData.address);
    setEmail(auditorData.email);
    if (auditorData.initDate)
      setInitDate(format(new Date(auditorData.initDate), 'yyyy-MM-dd'));
    if (auditorData.endDate)
      setEndDate(format(new Date(auditorData.endDate), 'yyyy-MM-dd'));
    if (auditorData.auditorStatus) setStatus(auditorData.auditorStatus);
    if (auditorData.documents) setFiles(auditorData.documents);
  };

  const showFileNames = () => {
    let names = '';
    if (files?.length) {
      for (let i = 0; i < files.length; i++) names += `${files[i].name} `;
    }
    return names;
  };

  useEffect(() => {
    if (action === 'EDIT' && auditorData) {
      loadInitialValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auditorData]);

  useEffect(() => {
    if (!auditorData?.auditorStatus) {
      if (!endDate || endDate === '' || new Date(endDate) - new Date() > 0)
        setStatus(statusTypes.var.ACTIVE);
      else setStatus(statusTypes.var.INACTIVE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDate]);

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title">
          {action === 'ADD' ? `${t('Add')} ` : `${t('Edit')} `}
          {t('Auditor')}
        </h5>
        <a
          className="close cursor-pointer"
          onClick={() => dispatch(setModal(null))}
        >
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>

      <form className="form-validate is-alter" onSubmit={handleUpdateAuditor}>
        <Modal.Body>
          <div className="row gy-4">
            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label"
                  htmlFor="societyAuditor-socialDenom"
                >
                  {t('NameAndSurnameSociety')} *
                </label>
                <input
                  type="text"
                  id="societyAuditor-socialDenom"
                  className="form-control form-control-lg"
                  value={socialDenomination}
                  onChange={(event) =>
                    setSocialDenomination(event.target.value)
                  }
                  placeholder={t('SocialDenomination')}
                  required
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label" htmlFor="societyAuditor-email">
                  {t('Email')} *
                </label>
                <input
                  type="email"
                  className="form-control form-control-lg"
                  id="societyAuditor-email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  placeholder={t('Email')}
                  required
                  pattern={EMAIL_PATTERN}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label" htmlFor="societyAuditor-initDate">
                  {t('AppointmentDate')}
                </label>
                <input
                  type="date"
                  className="form-control form-control-lg date-picker"
                  id="societyAuditor-initDate"
                  value={initDate}
                  onChange={(event) => setInitDate(event.target.value)}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <div className="d-flex">
                  <label
                    className="form-label"
                    htmlFor="societyAuditor-endDate"
                  >
                    {t('EndDate')}
                  </label>
                  <span className="sub-text ml-1 fs-12px">
                    {t('IndefiniteDefault')}
                  </span>
                </div>
                <input
                  type="date"
                  className="form-control form-control-lg date-picker"
                  id="societyAuditor-endDate"
                  value={endDate}
                  onChange={(event) => setEndDate(event.target.value)}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label" htmlFor="societyAuditor-status">
                  {t('Status')}
                </label>
                <div className="form-control-wrap ">
                  <div className="form-control-select">
                    <select
                      className="form-control"
                      id="societyAuditor-status"
                      value={status}
                      onChange={(event) => {
                        setStatus(event.target.value);
                      }}
                      required
                    >
                      {statusTypesOptions?.length > 0 &&
                        statusTypesOptions.map((type) => (
                          <option value={type.value} key={type.value}>
                            {type.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label" htmlFor="societyAuditor-cif">
                  {t('Cif')}
                </label>
                <input
                  type="text"
                  id="societyAuditor-cif"
                  className="form-control form-control-lg"
                  value={cif}
                  onChange={(event) => setCif(event.target.value)}
                  placeholder={t('Cif')}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label"
                  htmlFor="societyAuditor-nationality"
                >
                  {t('Nationality')}
                </label>
                <input
                  type="text"
                  id="societyAuditor-nationality"
                  className="form-control form-control-lg"
                  value={nationality}
                  onChange={(event) => setNationality(event.target.value)}
                  placeholder={t('Nationality')}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label" htmlFor="societyAuditor-address">
                  {t('Address')}
                </label>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  id="societyAuditor-address"
                  value={address}
                  onChange={(event) => setAddress(event.target.value)}
                  placeholder={t('Address')}
                />
              </div>
            </div>

            <div className="col-md-12">
              <div className="form-group">
                <label
                  className="form-label"
                  htmlFor="societyAuditor-documents"
                >
                  {t('AddDocuments')}
                </label>
                <div className="form-control-wrap">
                  <div className="custom-file">
                    <input
                      id="societyAuditor-documents"
                      type="file"
                      accept="application/pdf"
                      className="form-control form-control-lg custom-file-input"
                      onChange={(event) => setFiles(event.target.files)}
                      multiple
                    />
                    <label
                      className="custom-file-label"
                      htmlFor="societyAuditor-documents"
                    >
                      {showFileNames() || t('SelectDocument')}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="row">
            <div className="col-12">
              <button
                type="submit"
                className="btn btn-lg btn-primary"
                disabled={!socialDenomination || !email}
              >
                {t('Save')}
              </button>
              <button
                type="button"
                className="btn btn-lg btn-light ml-3"
                onClick={() => dispatch(setModal(null))}
              >
                {t('ToClose')}
              </button>
            </div>
          </div>
        </Modal.Footer>
      </form>
    </>
  );
}

export default AddSocietyAuditor;
