/* eslint-disable import/no-dynamic-require */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { setModal } from 'redux/actions/modalActions';

import votePeriods from 'constants/votePeriods';
import attendanceTypes from 'constants/attendanceTypes';
import boardTypes from 'constants/boardTypes';
import { formatDateToTime } from 'constants/formats';

import '../Modals.scss';
import '../../../assets/styles/EmailTemplate.scss';
import NewTemplate from './NewTemplate';
import OldTemplate from './OldTemplate';

const AnnouncementTemplateViewer = ({ board }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { actualSociety } = useSelector((state) => ({
    actualSociety: state.society?.actualSociety,
  }));

  const [templateData, setTemplateData] = useState();

  useEffect(() => {
    const data = {
      time: board.date ? formatDateToTime(board.date) : '-',
      boardName: board?.name || '',
      date: board.date ? format(new Date(board.date), 'dd/MM/yyyy') : '-',
      votePeriod:
        votePeriods.find((period) => period.value === board?.votePeriod)
          ?.text || '-',
      societyName: actualSociety?.name || '-',
      announcement: board?.announcement || '',
      place: board?.place || '-',
      onlineAccess: board?.onlineAccess || '-',
      attendanceType: attendanceTypes?.[board?.attendanceType]?.text || '-',
      boardType: boardTypes?.[board?.boardType]?.text || '-',
    };
    setTemplateData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [board]);

  return (
    <>
      <Modal.Header className="border-0 pb-0">
        <h5 className="modal-title">Plantilla Comunicación</h5>
        <a
          className="close cursor-pointer"
          onClick={() => dispatch(setModal(null))}
        >
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
      <Modal.Body>
        <div className="card card-bordered">
          <NewTemplate templateData={templateData} />
        </div>
      </Modal.Body>
    </>
  );
};

export default AnnouncementTemplateViewer;
