/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-danger */
/* eslint-disable no-console */
/* eslint-disable react/jsx-curly-newline */
import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SketchPicker, TwitterPicker } from 'react-color';

import { State } from 'redux/initialState';
import { updateSocietyTheme } from 'redux/actions/societyActions';

import {
  DEFAULT_HEADER_LOGO,
  DEFAULT_HEADER_COLOR,
  DEFAULT_HEADER_TEXT_COLOR,
  DEFAULT_HEADER_TEXT_HOVER_COLOR,
} from 'constants/defaultConstants';

import CustomLoading from 'components/CustomLoading';

import './SocietyConfiguration.scss';
import '../../assets/styles/Styles.scss';
import '../../assets/styles/CustomForms.scss';
import themeColors, {
  githubColors,
  twitterColors,
} from './constants/themeColors';
import SocietyConfigurationColorRow from './components/SocietyConfigurationColorRow';
import checkContrast from './utils/contrast';
import SocietyConfigurationLogoRow from './components/SocietyConfigurationLogoRow';
import SocietyConfigurationHeader from './components/SocietyConfigurationHeader';
import SocietyConfigurationHeaderButtons from './components/SocietyConfigurationHeaderButtons';

type SocietyConfigurationProps = {
  isAdmin: boolean;
};

const SocietyConfiguration: FC<SocietyConfigurationProps> = ({ isAdmin }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const society = useSelector((state: State) => state.society?.actualSociety);

  const [logoFile, setLogoFile] = useState<File>();

  const [logo, setLogo] = useState<string>(
    society?.customization?.theme?.logo || DEFAULT_HEADER_LOGO
  );
  const [headerColor, setHeaderColor] = useState(
    society?.customization?.theme?.headerColor || DEFAULT_HEADER_COLOR
  );
  const [headerTextColor, setHeaderTextColor] = useState(
    society?.customization?.theme?.headerTextColor || DEFAULT_HEADER_TEXT_COLOR
  );
  const [headerTextHoverColor, setHeaderTextHoverColor] = useState(
    society?.customization?.theme?.headerTextHoverColor ||
      DEFAULT_HEADER_TEXT_HOVER_COLOR
  );

  const [isLoading, setIsLoading] = useState(false);

  const isHeaderColorDisabled = (color: string): boolean =>
    color === headerTextColor || color === headerTextHoverColor;

  const isHeaderTextColorDisabled = (color: string): boolean =>
    color === headerColor;

  const isHeaderTextHoverColorDisabled = (color: string): boolean =>
    color === headerColor;

  const handleChangeHeaderColor = (
    e: string | React.ChangeEvent<HTMLInputElement>
  ) => {
    const color = typeof e === 'string' ? e : e.target.value;
    setHeaderColor(color);
  };

  const handleChangeHeaderTextColor = (
    e: string | React.ChangeEvent<HTMLInputElement>
  ) => {
    const color = typeof e === 'string' ? e : e.target.value;
    setHeaderTextColor(color);
  };

  const handleChangeHeaderTextHoverColor = (
    e: string | React.ChangeEvent<HTMLInputElement>
  ) => {
    const color = typeof e === 'string' ? e : e.target.value;
    setHeaderTextHoverColor(color);
  };

  const handleApplyChanges = async () => {
    setIsLoading(true);
    await dispatch(
      updateSocietyTheme(society._id, {
        headerColor,
        headerTextColor,
        headerTextHoverColor,
        logo: logoFile || logo,
      })
    );
    setIsLoading(false);
  };

  const handleResetValues = () => {
    setLogo(DEFAULT_HEADER_LOGO);
    setHeaderColor(DEFAULT_HEADER_COLOR);
    setHeaderTextColor(DEFAULT_HEADER_TEXT_COLOR);
    setHeaderTextHoverColor(DEFAULT_HEADER_TEXT_HOVER_COLOR);
  };

  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target?.files?.length ? e.target.files[0] : undefined;

    if (!file) return;

    setLogoFile(file);
    setLogo(URL.createObjectURL(file));
  };

  return (
    <div className="nk-block custom-forms-wrapper">
      <div className="nk-block-head">
        <SocietyConfigurationHeader />
      </div>

      <div className="nk-block">
        {isAdmin && (
          <div className="nk-block-head">
            <SocietyConfigurationHeaderButtons
              onApplyChanges={handleApplyChanges}
              onResetValues={handleResetValues}
            />
          </div>
        )}

        <div className="card card-bordered  card-preview">
          <div className="nk-data data-list">
            <SocietyConfigurationColorRow
              id="header-demo"
              label={t('Fondo cabecera')}
              colors={twitterColors}
              currentColor={headerColor}
              onColorChange={handleChangeHeaderColor}
              isColorDisabled={isHeaderColorDisabled}
            />

            <SocietyConfigurationColorRow
              id="header-text"
              label={t('Texto cabecera')}
              colors={twitterColors}
              currentColor={headerTextColor}
              onColorChange={handleChangeHeaderTextColor}
              isColorDisabled={isHeaderTextColorDisabled}
            />

            <SocietyConfigurationColorRow
              id="header-text-hover"
              label={t('Texto enlaces')}
              colors={twitterColors}
              currentColor={headerTextHoverColor}
              onColorChange={handleChangeHeaderTextHoverColor}
              isColorDisabled={isHeaderTextHoverColorDisabled}
            />

            <SocietyConfigurationLogoRow
              id="society-logo"
              label={t('Logo cabecera')}
              labelSize="170 x 25px"
              currentLogo={logo}
              backgroundColor={headerColor}
              onLogoChange={handleChangeFile}
            />
          </div>
        </div>
      </div>

      {isLoading && <CustomLoading />}
    </div>
  );
};

export default SocietyConfiguration;
