/* eslint-disable react/display-name */
import screens from 'constants/screens';
import CellData from 'components/Tables/CellData';

function tableColumns(i18n) {
  return [
    {
      name: i18n.t('Society'),
      field: 'societyName',
      selector: (row) => row[i18n.t('Society')],
      sortable: true,
      grow: 2,
      cell: (row) =>
        CellData.Society({
          society: row.society,
          url: '/detalle-beneficiario',
          type: 'light',
          showHolding: false,
          customLink: row.link || '',
          noRedirect: row.noRedirect,
        }),
      export: true,
    },
    {
      name: i18n.t('ColumnLabelPlans'),
      field: 'plansTotal',
      selector: (row) => row[i18n.t('ColumnLabelPlans')],
      sortable: true,
      compact: true,
      center: true,
      grow: 0.75,
      hide: screens.SM,
      cell: (row) =>
        CellData.PlainText({
          text: row[i18n.t('ColumnLabelPlans')],
        }),
      export: true,
    },
    {
      name: i18n.t('Beneficiary'),
      field: 'name',
      selector: (row) => row[i18n.t('Beneficiary')],
      sortable: true,
      compact: true,
      left: true,
      grow: 1,
      hide: screens.MD,
      cell: (row) =>
        CellData.PlainText({
          text: row[i18n.t('Beneficiary')],
        }),
      export: true,
    },
    {
      name: i18n.t('CIF'),
      field: 'cif',
      selector: (row) => row[i18n.t('CIF')],
      sortable: true,
      compact: true,
      left: true,
      grow: 0.75,
      hide: screens.LG,
      cell: (row) =>
        CellData.PlainText({
          text: row[i18n.t('CIF')],
        }),
      export: true,
    },
  ];
}
export default tableColumns;
