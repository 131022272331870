import { combineReducers } from 'redux';
import user from './userReducer';
import partnerBook from './partnerBookReducer';
import menu from './menuReducer';
import sideNav from './sideNavReducer';
import alerts from './alertReducer';
import documents from './documentReducer';
import modal from './modalReducer';
import society from './societyReducer';
import plans from './planReducer';
import drafts from './draftReducer';
import holdingClasses from './holdingClassReducer';
import tenderOffers from './tenderOfferReducer';
import tenderOffersShares from './tenderOfferSharesReducer';
import boards from './boardReducer';
import communication from './communicationReducer';
import preview from './previewReducer';
import notification from './notificationReducer';
import fullWidth from './fullWidthReducer';
import employees from './employeesReducer';
import employeesPlans from './employeesPlanReducer';
import partnerCategories from './partnerCategoryReducer';

const rootReducer = combineReducers({
  user,
  partnerBook,
  menu,
  sideNav,
  alerts,
  documents,
  modal,
  society,
  plans,
  drafts,
  holdingClasses,
  tenderOffers,
  tenderOffersShares,
  boards,
  communication,
  preview,
  notification,
  fullWidth,
  employees,
  employeesPlans,
  partnerCategories,
});

export default rootReducer;
