/* eslint-disable no-console */
/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { setModal } from 'redux/actions/modalActions';
import store from 'redux/store';
import { useTranslation } from 'react-i18next';
import EditModal from './modal';

function SocietyProfileData({ isOpen }) {
  const user = useSelector((state) => state.user);
  const isAdmin = useSelector((state) => state.society?.role?.isAdmin);
  const society = useSelector((state) => state.society?.actualSociety);
  const isDemo = useSelector((state) => state.society?.role?.isDemo);
  const { t } = useTranslation();
  const [mainDirector, setMainDirector] = useState();

  const languageLabels = { es: t('Spanish'), en: t('English') };

  useEffect(() => {
    if (society.mainDirector) {
      const director = society?.directors.find(
        (d) => d?.['_id'] === society.mainDirector
      );
      setMainDirector(director);
    }
  }, [society]);

  useEffect(() => {
    if (isOpen && isAdmin)
      store.dispatch(setModal(<EditModal society={society} />));
  }, [isOpen, society, user, isAdmin]);

  return (
    <div className="nk-block">
      <div className="nk-block-head">
        <div className="nk-block-head-content">
          <h5 className="nk-block-title">{t('SocietyInformation')}</h5>
          <div className="nk-block-des">
            <p>{t('BasicInformationOfSociety')}</p>
          </div>
        </div>
      </div>
      <div
        className="card card-bordered"
        onClick={() => {
          if (isAdmin && !isDemo)
            store.dispatch(setModal(<EditModal society={society} />));
        }}
      >
        <div className="nk-data data-list">
          <div
            className="data-item"
            data-toggle="modal"
            data-target="#profile-edit"
          >
            <div className="data-col">
              <span className="data-label">{t('SocialDenomination')}</span>
              <span className="data-value">{society?.name}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <em className="icon ni ni-forward-ios" />
              </span>
            </div>
          </div>
          <div
            className="data-item"
            data-toggle="modal"
            data-target="#profile-edit"
          >
            <div className="data-col">
              <span className="data-label">CIF</span>
              <span className="data-value">{society?.cif}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <em className="icon ni ni-forward-ios" />
              </span>
            </div>
          </div>
          <div
            className="data-item"
            data-toggle="modal"
            data-target="#profile-edit"
          >
            <div className="data-col">
              <span className="data-label">Web</span>
              <span className="data-value">{society?.web || '-'}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <em className="icon ni ni-forward-ios" />
              </span>
            </div>
          </div>
          <div
            className="data-item"
            data-toggle="modal"
            data-target="#profile-edit"
          >
            <div className="data-col">
              <span className="data-label">{t('Telephone')}</span>
              <span className="data-value">{society?.phone}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <em className="icon ni ni-forward-ios" />
              </span>
            </div>
          </div>
          <div
            className="data-item"
            data-toggle="modal"
            data-target="#profile-edit"
          >
            <div className="data-col">
              <span className="data-label">{t('NotifyThePartners')}</span>
              <span className="data-value">
                {society?.sendNotifications ? t('Yes') : 'No'}
              </span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <em className="icon ni ni-forward-ios" />
              </span>
            </div>
          </div>

          <div
            className="data-item"
            data-toggle="modal"
            data-target="#profile-edit"
          >
            <div className="data-col">
              <span className="data-label">
                {t('EnablePartnerAccessToCaptable')}
              </span>
              <span className="data-value">
                {society?.hasAccessCaptable ? t('Yes') : 'No'}
              </span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <em className="icon ni ni-forward-ios" />
              </span>
            </div>
          </div>

          <div
            className="data-item"
            data-toggle="modal"
            data-target="#profile-edit"
          >
            <div className="data-col">
              <span className="data-label">{t('RegisteredOffice')}</span>
              <span className="data-value">
                {society?.legalAddress.line1
                  ? `${
                      society?.legalAddress?.zip
                        ? society?.legalAddress.zip
                        : ''
                    } ${
                      society?.legalAddress?.line1 &&
                      society?.legalAddress.line1
                    }`
                  : '-'}
                <br />
                {society?.legalAddress &&
                  `${
                    society?.legalAddress?.city
                      ? society?.legalAddress?.city
                      : ''
                  } ${
                    society?.legalAddress?.country
                      ? society?.legalAddress?.country
                      : ''
                  }`}
              </span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <em className="icon ni ni-forward-ios" />
              </span>
            </div>
          </div>
          <div
            className="data-item"
            data-toggle="modal"
            data-target="#profile-edit"
          >
            <div className="data-col">
              <span className="data-label">{t('SelectedAdministrator')}</span>
              <span className="data-value">
                {mainDirector?.socialDenomination || ''}&nbsp;(
                {mainDirector?.email || ''})
              </span>
            </div>

            <div className="data-col data-col-end">
              <span className="data-more">
                <em className="icon ni ni-forward-ios" />
              </span>
            </div>
          </div>
          <div
            className="data-item"
            data-toggle="modal"
            data-target="#profile-edit"
          >
            <div className="data-col">
              <span className="data-label">{t('CommunicationsLanguage')}</span>
              <span className="data-value">
                {languageLabels[society?.societyLanguage]}
              </span>
            </div>

            <div className="data-col data-col-end">
              <span className="data-more">
                <em className="icon ni ni-forward-ios" />
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="nk-block-head">
        <div className="nk-block-head-content">
          <h5 className="nk-block-title">{t('AdditionalInformation')}</h5>
          <div className="nk-block-des">
            <p>{t('UseThisSectionToUpdateSociety')}</p>
          </div>
        </div>
      </div>
      <div
        className="card card-bordered"
        onClick={() => {
          if (!isDemo)
            store.dispatch(setModal(<EditModal society={society} />));
        }}
      >
        <div className="nk-data data-list">
          <div
            className="data-item"
            data-toggle="modal"
            data-target="#profile-edit"
            data-tab-target="#profile-edit"
          >
            <div className="data-col">
              <span className="data-label">Logo</span>
              <div className="user-avatar md bg-primary">
                {society?.additional.logo ? (
                  <img src={society?.additional.logo} alt={society?.name} />
                ) : (
                  <span>{society?.name.slice(0, 2).toUpperCase()}</span>
                )}
              </div>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <em className="icon ni ni-forward-ios" />
              </span>
            </div>
          </div>
          <div className="data-item">
            <div className="data-col">
              <span className="data-label">{t('BriefDescription')}</span>
              <span className="data-value">
                {society?.additional.description}
              </span>
            </div>
            <div className="data-col data-col-end">
              <p className="link link-primary"> {t('Edit')} </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SocietyProfileData;
