import React from 'react';
import { useTranslation } from 'react-i18next';

import { SEARCH_MAX_LENGTH } from 'constants/defaultConstants';

import './SearchBar.scss';

const SearchBar = ({ searchTerm, setSearchTerm, disabled }) => {
  const { t } = useTranslation();

  const handleChangeSearchText = (e) => {
    const text = e.target.value;
    setSearchTerm(text);
  };

  const handleClearSearchText = () => {
    setSearchTerm('');
  };

  return (
    <div className="nk-fmg-search">
      <em className="icon ni ni-search" />
      <input
        id="search-input"
        type="text"
        className="form-control border-transparent form-focus-none"
        placeholder={t('SearchDocuments')}
        value={searchTerm}
        onChange={handleChangeSearchText}
        maxLength={SEARCH_MAX_LENGTH}
        disabled={disabled}
      />

      {searchTerm && (
        <span
          className="btn btn-icon btn-trigger"
          onClick={handleClearSearchText}
        >
          <em className="icon ni ni-cross cursor-pointer text-muted" />
        </span>
      )}
    </div>
  );
};

export default SearchBar;
