/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Link } from 'react-router-dom';
import {
  currencyFormat,
  currencyFormatDecimals,
  numberFormatDecimals,
} from 'constants/formats';
import NumberFormat from 'react-number-format';

import draftTypes from 'constants/draftTypes';
import convertibleNoteStatus from 'constants/convertibleNoteStatus';
import { format } from 'date-fns/esm';
import { draftStatus } from 'components/Badges/BadgeDraftStatus';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import MenuDots from '../MenuDots';
import ncMenuOptions from './ncMenuOptions';
import draftMenuOptions from './draftMenuOptions';

const draftOperations = {
  DRAFT_SELL: 'Venta',
  DRAFT_BUY_SELL_SHARES: 'Compra/Venta',
  DRAFT_CAPITAL_INCREASE: 'Ampliación',
  DRAFT_CONVERTIBLE_NOTE: 'Nota Convertible',
};

const draftRoutes = {
  DRAFT_SELL: 'borrador-venta',
  DRAFT_BUY_SELL_SHARES: 'borrador-compra-venta',
  DRAFT_CAPITAL_INCREASE: 'borrador-ampliacion',
  DRAFT_CONVERTIBLE_NOTE: 'borrador-nota',
};

function DraftsRow({ index, societyId, draft }) {
  const { t } = useTranslation();

  const isDemo = useSelector((state) => state.society?.role?.isDemo);

  const {
    name,
    date,
    percent,
    valuation,
    totalContribution,
    draftType,
    status,
    isConverted,
  } = draft;

  const getDraftStatus = () => {
    if (draftType === draftTypes.DRAFT_CONVERTIBLE_NOTE) {
      return convertibleNoteStatus.name[status];
    }
    if (draftType === draftTypes.DRAFT_CAPITAL_INCREASE && isConverted) {
      return t(draftStatus.EXECUTED.label);
    }
    if (draftType === draftTypes.DRAFT_CAPITAL_INCREASE && !isConverted) {
      return t(draftStatus.DRAFT.label);
    }
    if (draftType === draftTypes.DRAFT_BUY_SELL_SHARES && isConverted) {
      return t(draftStatus.EXECUTED.label);
    }
    if (draftType === draftTypes.DRAFT_BUY_SELL_SHARES && !isConverted) {
      t(draftStatus.DRAFT.label);
    }
  };

  return (
    <tr className="nk-tb-item">
      <td className="nk-tb-col tb-col-xl">
        <span>#{index}</span>
      </td>

      <td className="nk-tb-col">
        <div className="user-card">
          <div className="user-avatar bg-dim-primary d-none d-sm-flex">
            <span>{name.slice(0, 1).toUpperCase()}</span>
          </div>
          <div className="user-info">
            <span className="tb-lead d-block d-md-none text-ellipsis w-100px">
              <Link
                to={`/${draftRoutes[draftType]}/${societyId}/${draft['_id']}`}
              >
                {name || ''}
              </Link>
            </span>
            <span className="tb-lead d-none d-md-block">
              <Link
                to={`/${draftRoutes[draftType]}/${societyId}/${draft['_id']}`}
              >
                {name || ''}
              </Link>
            </span>
          </div>
        </div>
      </td>

      <td className="nk-tb-col">
        <span className="d-block d-md-none">
          {format(new Date(date), 'dd/MM/yy')}
        </span>
        <span className="d-none d-md-block">
          {format(new Date(date), 'dd/MM/yyyy')}
        </span>
      </td>

      <td className="nk-tb-col tb-col-xl text-right">
        <span>
          <NumberFormat
            value={percent || 0}
            displayType="text"
            {...numberFormatDecimals}
          />
          %
        </span>
      </td>

      <td className="nk-tb-col tb-col-xl text-right">
        <span>
          <NumberFormat
            value={valuation || 0}
            displayType="text"
            {...currencyFormatDecimals}
          />
        </span>
      </td>

      <td className="nk-tb-col text-right">
        <span>
          <NumberFormat
            value={totalContribution || 0}
            displayType="text"
            {...currencyFormatDecimals}
          />
        </span>
      </td>

      <td className="nk-tb-col">
        <span className="badge badge-dot text-success">
          {draftOperations[draftType]}
        </span>
      </td>

      <td className="nk-tb-col tb-col-xl">
        <span>{getDraftStatus()}</span>
      </td>

      {!isDemo && (
        <td className="nk-tb-col nk-tb-col-tools">
          <ul className="nk-tb-actions gx-1">
            <li>
              <div className="dropdown">
                {draftType === draftTypes.DRAFT_CONVERTIBLE_NOTE ? (
                  <MenuDots
                    menuOptions={ncMenuOptions}
                    id={draft['_id']}
                    params={draft}
                    direction="left"
                  />
                ) : (
                  <MenuDots
                    menuOptions={draftMenuOptions}
                    id={draft['_id']}
                    params={draft}
                    direction="left"
                  />
                )}
              </div>
            </li>
          </ul>
        </td>
      )}
    </tr>
  );
}

export default DraftsRow;
