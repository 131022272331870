import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';

import { dateFormat } from 'constants/formats';
import {
  calculateConsolidationPercent,
  getBeneficiaryAmount,
} from 'utils/filters';
import {
  formatCurrencyDecimals,
  formatNumber,
  formatNumberDecimals,
} from 'utils/formats';
import invitationStatus from 'constants/invitationStatus';
import BeneficiaryPlansTabRow from './BeneficiaryPlansTabRow';

function BeneficiaryPlansTab({ society, beneficiary, beneficiaryAllPlans }) {
  const { t } = useTranslation();
  const isDemo = useSelector((state) => state.society?.role?.isDemo);

  const societyShares = () =>
    Number(
      society.sharesCount?.actual +
        society.sharesCount?.future +
        society?.sharesCount?.drafts
    );

  const getConsolidatedPercent = (b) => {
    if (b?.isCanceled && new Date(b?.cancelationDate) <= new Date()) {
      return Number((b.finalConsolidatedUnits * 100) / b.sharesCount.future);
    }

    return calculateConsolidationPercent(Date.now(), b, b.planData);
  };

  const getInvitationStatus = (b) => {
    const status = b?.invitationStatus;

    if (status === invitationStatus.PENDING.value || status === '') {
      return invitationStatus.PENDING;
    }

    return invitationStatus[status];
  };

  const rows = beneficiaryAllPlans.map((b, index) => {
    const amount = getBeneficiaryAmount(b, b.planData);
    const sharePrice =
      b?.customConditions?.sharePrice || b.planData?.sharePrice;
    return (
      <BeneficiaryPlansTabRow
        index={index + 1}
        key={b['_id']}
        planName={b.planData?.name}
        grantDate={format(new Date(b.planStartDate), dateFormat)}
        unitPrice={formatCurrencyDecimals(sharePrice)}
        unitTotal={formatNumber(b.sharesCount?.future)}
        FDpercent={((b.sharesCount?.future * 100) / societyShares()).toFixed(2)}
        exercisePrice={formatCurrencyDecimals(amount)}
        consolidatedPercent={formatNumberDecimals(getConsolidatedPercent(b))}
        plan={b.planData}
        society={society}
        beneficiary={b}
        invitationStatus={getInvitationStatus(b)}
      />
    );
  });

  return (
    <div className="card-inner">
      <div className="nk-block-head">
        <div className="nk-block-between g-3">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title">{t('PlansTabTitle')}</h4>
            <div className="nk-block-des">
              <p>
                {t('PlansTabSubtitle', {
                  societyName: society?.name || '',
                  beneficiaryName: beneficiary?.name || '',
                })}
              </p>
            </div>
          </div>
        </div>
      </div>

      {rows.length ? (
        <>
          <div className="nk-block nk-block-lg">
            <div className="card card-bordered card-preview">
              <table className="table table-striped" id="consolidationTable">
                <thead>
                  <tr>
                    <th scope="col" className="d-none d-md-table-cell">
                      {t('Nr')}
                    </th>
                    <th scope="col">{t('Plan')}</th>
                    <th scope="col" className="d-none d-md-table-cell">
                      {t('GrantDate')}
                    </th>
                    <th scope="col" className="d-none d-md-table-cell">
                      {t('StrikePrice')}
                    </th>
                    <th scope="col" className="d-none d-md-table-cell">
                      {t('UnitsNr')}
                    </th>
                    <th scope="col" className="d-none d-md-table-cell">
                      {t('%FD')}
                    </th>
                    <th scope="col" className="d-none d-md-table-cell">
                      {t('ExercisePrice')}
                    </th>
                    <th scope="col" className="d-none d-md-table-cell">
                      {t('%Consolid.')}
                    </th>
                    <th scope="col" className="d-none d-md-table-cell">
                      {t('Invitation')}
                    </th>
                    {!isDemo && (
                      <th scope="col" className="text-center">
                        {t('+Info')}
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>{rows}</tbody>
              </table>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

export default BeneficiaryPlansTab;
