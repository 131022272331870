/* eslint-disable no-restricted-syntax */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-const-assign */
/* eslint-disable no-plusplus */
/* eslint-disable no-await-in-loop */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-alert */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import store from 'redux/store';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';

import { addPartner, constitution } from 'redux/actions/modalsActions';
import { addDocument } from 'redux/actions/documentActions';
import { setModal } from 'redux/actions/modalActions';

import {
  extractDataFromPDF,
  extractDataFromText,
  getDocxContent,
  mapResultToJSON,
} from 'utils/documents';
import { fileToArrayBuffer } from 'utils/files';
import { generateEmail } from 'utils/filters';

import PDFPreview from 'components/PDFViewer';
import DOCXViewer from 'components/DOCXViewer';

import transactionTypes from 'constants/transactionTypes';
import queryTypes from 'constants/queryTypes';
import documentTypes from 'constants/documentTypes';
import operationTypes from 'constants/operationTypes';

import Swal from 'sweetalert2';
import { getSocietyById } from 'redux/actions/societyActions';
import CustomLoading from '../../CustomLoading';
import SocietyTable from './societyTable';
import PartnersTable from './partnersTable';
import ConstitutionTable from './constitutionTable';

const fileTypes = {
  PDF: 'application/pdf',
  DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
};
const societyParams = [
  'Society Name',
  'Society Address',
  'Society Fiscal Identification Number',
  'Society Protocol Number',
  'Society Commercial Registry',
  'Society Social object',
];
const constitutionParams = [
  'Constitution date',
  'Number of new shares',
  'Shares nominal value',
];
const partnerParams = [
  'Name',
  'Shares count',
  'Fiscal Identification Number',
  'Shares from',
  'Shares to',
  'Shares class',
  'Shares premium',
  'Address',
  'Postal code',
  'City',
  'Country',
  'Email',
  'Nationality',
  'Civil status',
  'Birth date',
  'Profession',
];
const representativeParams = [
  'Name',
  'Empowerment Date',
  'Fiscal Identification Number',
  'Address',
  'Email',
  'Nationality',
];

const ConstitutionAI = ({ file }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const actualSociety = useSelector((state) => state.society?.actualSociety);
  const holdingClasses = useSelector((state) => state.holdingClasses);
  const user = useSelector((state) => state.user);

  const [isLoading, setIsLoading] = useState(false);
  const [extractedSocietyData, setExtractedSocietyData] = useState(null);
  const [extractedConstitutionData, setExtractedConstitutionData] =
    useState(null);
  const [extractedPartnerData, setExtractedPartnerData] = useState(null);
  const [partnersData, setPartnersData] = useState(null);
  const [constitutionData, setConstitutionData] = useState(null);
  const [societyData, setSocietyData] = useState(null);

  async function createPartners() {
    const emptyValue = '';
    // TODO Add cif validations
    for (const partner of partnersData) {
      const newPartner = {
        name: partner['Name'] ?? emptyValue,
        email: partner?.['Email']
          ? partner['Email']
          : generateEmail(actualSociety),
        cif: partner['Fiscal Identification Number'] ?? emptyValue,
        category: null,
        society: actualSociety?.['_id'] ?? emptyValue,
        user: user['_id'] ?? emptyValue,
        nationality: partner?.['Nationality'] ?? emptyValue,
        address: {
          line1: partner?.['Address'] ?? emptyValue,
          zip: partner?.['Postal code'] ?? emptyValue,
          city: partner?.['City'] ?? emptyValue,
          country: partner?.['Country'] ?? emptyValue,
        },
        representative: partner?.['Representative']
          ? {
              name: partner?.['Representative']['Name'] ?? emptyValue,
              email: partner?.['Representative']['Email'] ?? emptyValue,
              initDate:
                partner?.['Representative']['Empowerment Date'] ?? emptyValue,
              nif:
                partner?.['Representative']['Fiscal Identification Number'] ??
                emptyValue,
              nationality:
                partner?.['Representative']['Nationality'] ?? emptyValue,
              address: partner?.['Representative']['Address'] ?? emptyValue,
              endDate: emptyValue,
              authority: emptyValue,
              status: emptyValue,
            }
          : {},
        isNaturalPerson: !partner?.['Representative'],
      };
      await store.dispatch(addPartner(newPartner, false));
    }
  }

  async function saveConstitution() {
    await createPartners();
    const society = await getSocietyById(actualSociety['_id']);
    const movements = [];
    const transactions = [];
    partnersData.map((data) => {
      const currentPartner = society?.partners.find(
        (partner) => partner.cif === data['Fiscal Identification Number']
      );
      if (currentPartner) {
        const newMovement = {
          partner: currentPartner,
          shareFrom: data['Shares from'],
          shareTo: data['Shares to'],
          shareClass: holdingClasses?.[0]?.['_id'],
          sharePremium: data['Shares premium'],
        };
        const newTransaction = {
          partner: currentPartner,
          shareFrom: data['Shares from'],
          shareTo: data['Shares to'],
          shareClass: holdingClasses?.[0]?.['_id'],
          sharePremium: data['Shares premium'],
          transactionType: transactionTypes.CONSTITUTION,
        };
        movements.push(newMovement);
        transactions.push(newTransaction);
      }
    });
    let uploadedDocument = null;
    if (file) {
      const newDocument = {
        file,
        date: constitutionData['Constitution date'],
        size: file.size,
        name: operationTypes.CONSTITUTION_FILENAME,
        author: user['_id'],
        society: actualSociety['_id'],
        fileType: fileTypes[file.type],
        category: documentTypes.SCRIPTURES,
        description: t('DocumentLinkedToOperation'),
      };
      uploadedDocument = await store.dispatch(addDocument(newDocument));
    }
    if (transactions.length > 0) {
      await store.dispatch(
        constitution(
          {
            movements,
            transactions,
            date: constitutionData['Constitution date'],
            nominalValue: constitutionData['Shares nominal value'],
            society: actualSociety['_id'],
            user: user['_id'],
            documents: uploadedDocument?.['_id']
              ? [uploadedDocument['_id']]
              : [],
          },
          false
        )
      );
      store.dispatch(setModal(null));
      return Swal.fire({
        icon: 'success',
        title: `<h4 class="nk-modal-title">${t(
          'SuccessfullyIncorporatedMessage'
        )}<br></h4>`,
        confirmButtonText: t('OK'),
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonColor: '#6576FF',
      });
    }
    return Swal.fire({
      icon: 'warning',
      title: `<h4 class="nk-modal-title">${t(
        'ErrorIncorporationMessage'
      )}<br></h4>`,
      confirmButtonText: t('OK'),
      allowOutsideClick: false,
      showCancelButton: false,
      confirmButtonColor: '#6576FF',
    });
  }

  function changeSocietyParam(field, value) {
    const newData = { ...societyData };
    newData[field] = value;
    setSocietyData(newData);
  }
  function changeConstitutionParam(field, value) {
    const newData = { ...constitutionData };
    newData[field] = value;
    setConstitutionData(newData);
  }
  function changePartnerParam(index, field, value, representative = false) {
    const newData = [...partnersData];
    if (representative) {
      newData[index]['Representative'][field] = value;
    } else {
      newData[index][field] = value;
    }
    setPartnersData(newData);
  }

  function parsePartnersData(partnerData) {
    if (holdingClasses && partnerData) {
      const newData = [...partnerData];
      partnerData.forEach((partner, index) => {
        const holdingClass = holdingClasses?.find(
          (holdingClass) =>
            holdingClass?.name?.toUpperCase() ===
            partner['Shares class']?.toUpperCase()
        );

        newData[index]['Shares class'] = holdingClass
          ? holdingClass['_id']
          : holdingClasses?.[0]?.['_id'];

        if (typeof partner['Shares premium'] !== 'number') {
          newData[index]['Shares premium'] = 0;
        }
      });
      setPartnersData(newData);
    }
  }

  function handleDeletePartner(partnerIndex) {
    Swal.fire({
      icon: 'warning',
      title: `<h4 className="nk-block-title page-title">${t(
        'AreYouSureAboutDeletePartner'
      )}  `,
      confirmButtonText: t('Delete'),
      confirmButtonColor: '#6576ff',
      allowOutsideClick: false,
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        const newData = partnersData?.filter(
          (partner, index) => index !== partnerIndex
        );
        setPartnersData(newData);
      }
    });
  }
  const extractSocietyData = async (text, pdfFile) => {
    try {
      const paramsList = societyParams.join(', ');
      let extracted;
      if (pdfFile) {
        extracted = await extractDataFromPDF(
          pdfFile,
          paramsList,
          '',
          queryTypes.SOCIETY
        );
      } else {
        extracted = await extractDataFromText(
          text,
          paramsList,
          '',
          queryTypes.SOCIETY
        );
      }
      setExtractedSocietyData(mapResultToJSON(extracted));
    } catch (error) {
      alert(
        pdfFile ? t('ErrorExtractingPDFData') : t('ErrorExtractingDOCXData')
      );
    }
  };

  const extractConstitutionData = async (text, pdfFile) => {
    try {
      const paramsList = constitutionParams.join(', ');
      let extracted;
      if (pdfFile) {
        extracted = await extractDataFromPDF(
          pdfFile,
          paramsList,
          '',
          queryTypes.CONSTITUTION
        );
      } else {
        extracted = await extractDataFromText(
          text,
          paramsList,
          '',
          queryTypes.CONSTITUTION
        );
      }
      setExtractedConstitutionData(mapResultToJSON(extracted));
    } catch (error) {
      alert(
        pdfFile ? t('ErrorExtractingPDFData') : t('ErrorExtractingDOCXData')
      );
    }
  };

  const extractPartnersData = async (text, pdfFile) => {
    try {
      const paramsList = partnerParams.join(', ');
      let extracted;
      if (pdfFile) {
        extracted = await extractDataFromPDF(
          pdfFile,
          paramsList,
          representativeParams,
          queryTypes.PARTNERS
        );
      } else {
        extracted = await extractDataFromText(
          text,
          paramsList,
          representativeParams,
          queryTypes.PARTNERS
        );
      }
      setExtractedPartnerData(mapResultToJSON(extracted));
    } catch (error) {
      alert(
        pdfFile ? t('ErrorExtractingPDFData') : t('ErrorExtractingDOCXData')
      );
    }
  };

  async function handleDocx(file) {
    try {
      const arrayBuffer = await fileToArrayBuffer(file);
      const textElements = await getDocxContent(arrayBuffer);

      let text = '';
      Array.from(textElements).forEach((element) => {
        text += element.textContent;
      });
      await extractSocietyData(text);
      await extractConstitutionData(text);
      await extractPartnersData(text);
    } catch (error) {
      alert(t('ErrorExtractingDOCXData'));
    } finally {
      setIsLoading(false);
    }
  }

  async function handlePDF(file) {
    try {
      if (file) {
        await extractSocietyData('', file);
        await extractConstitutionData('', file);
        await extractPartnersData('', file);
      }
    } catch (error) {
      alert(t('ErrorExtractingPDFData'));
    } finally {
      setIsLoading(false);
    }
  }

  async function scanFile(file) {
    setIsLoading(true);
    try {
      if (file.type === fileTypes.PDF) {
        await handlePDF(file);
      }
      if (file.type === fileTypes.DOCX) {
        await handleDocx(file);
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleSubmit(file) {
    if (constitutionData?.length || partnersData?.length) {
      Swal.fire({
        icon: 'info',
        title: `<h4 className="nk-block-title page-title">${t(
          'AreYouSureWantScan'
        )}  `,
        html: `<br /><div class="caption-text">${t('AllDataWillLost')}</div>`,
        confirmButtonText: t('Scan'),
        confirmButtonColor: '#6576ff',
        allowOutsideClick: false,
        showCancelButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          scanFile(file);
        }
      });
    } else {
      scanFile(file);
    }
  }

  useEffect(() => {
    if (extractedPartnerData) {
      parsePartnersData([...extractedPartnerData?.partners] || []);
    }
  }, [extractedPartnerData]);

  useEffect(() => {
    if (extractedConstitutionData) {
      setConstitutionData({ ...extractedConstitutionData });
    }
  }, [extractedConstitutionData]);

  useEffect(() => {
    if (extractedSocietyData) {
      setSocietyData({ ...extractedSocietyData });
    }
  }, [extractedSocietyData]);

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title">{t('ConstitutionFromScripture')}</h5>
        <a
          className="close cursor-pointer"
          onClick={() => dispatch(setModal(null))}
        >
          <em className="icon ni ni-cross cursor-pointer" />
        </a>
      </Modal.Header>

      <Modal.Body>
        {isLoading && <CustomLoading />}

        <div
          className="d-flex justify-content-between align-items-baseline"
          style={{ gap: '20px' }}
        >
          <div style={{ width: '50%' }}>
            {!isLoading && partnersData && constitutionData && societyData && (
              <>
                <div>
                  <SocietyTable
                    societyData={societyData}
                    changeSocietyParam={changeSocietyParam}
                  />
                  <ConstitutionTable
                    constitutionData={constitutionData}
                    changeConstitutionParam={changeConstitutionParam}
                  />
                  <PartnersTable
                    partnersData={partnersData}
                    changePartnerParam={changePartnerParam}
                    handleDeletePartner={handleDeletePartner}
                    holdingClasses={holdingClasses}
                  />
                </div>
              </>
            )}
          </div>

          <div style={{ width: '50%', height: '100%' }}>
            <h6>{t('OriginalDocument')}</h6>
            <div className="card card-bordered card-preview mb-4 h-100">
              {file?.type === fileTypes.DOCX ? (
                <DOCXViewer file={file} height={660} />
              ) : (
                <PDFPreview file={file} height={660} />
              )}
            </div>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <button
          type="button"
          className="btn btn-primary"
          onClick={saveConstitution}
          disabled={!constitutionData || !partnersData || !societyData}
        >
          {t('SaveConstitution')}
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => handleSubmit(file)}
        >
          {t('ScanFile')}
        </button>
      </Modal.Footer>
    </>
  );
};

export default ConstitutionAI;
