/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
// eslint-disable-next-line import/no-extraneous-dependencies
import { isEqual as deepEqual } from 'lodash';
import { format } from 'date-fns';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import ReactToPrint from 'react-to-print';

import sizes from 'constants/sizes';
import { dateFormat } from 'constants/formats';
import actionTypes from 'constants/actionTypes';
import tenderOfferStatus from 'constants/tenderOfferStatus';

import { setModal } from 'redux/actions/modalActions';
import { getDocument } from 'redux/actions/documentActions';
import { getStockPlans } from 'redux/actions/planActions';
import { getTenderOffers } from 'redux/actions/tenderOfferActions';

import downloadFile from 'utils/downloadFile';
import {
  formatCurrencyDecimals,
  formatNumber,
  formatNumberDecimals,
} from 'utils/formats';
import {
  getActualSociety,
  getEligiblesData,
  getTenderOfferStatus,
  getTotalUnitsFromEligibles,
} from 'utils/filters';
import {
  tenderEligibleTypes,
  eligiblesSubgroupTypes,
  tenderStatusTypes,
} from 'utils/tenderOfferTypes';
import {
  getChartTenderOfferData,
  tenderOfferChartColors,
  tenderOfferChartLabels,
  tenderOfferChartOptions,
} from 'utils/charts';

import CustomLoading from 'components/CustomLoading';
import AddTenderOffer from 'components/Modals/AddTenderOffer';
import SendTenderOffer from 'components/Modals/SendTenderOffer';
import TenderOfferEligiblesRow from 'components/TenderOfferEligiblesRow';
import WidgetChartDoughnut, {
  legendModes,
} from 'components/WidgetChartDoughnut';
import PrintHeader from 'components/Headers/PrintHeader';

import './TenderOfferDetails.scss';

const TenderOfferDetails = () => {
  const { t } = useTranslation();
  const { societyId, tenderOfferId } = useParams();
  const dispatch = useDispatch();
  const prevEligiblesDataRef = useRef(null);
  const printRef = useRef();

  const isDemo = useSelector((state) => state.society?.role?.isDemo);

  const {
    actualSociety,
    actualTenderOffer,
    holdingClasses,
    partners,
    user,
    plans,
    phantomPlans,
    beneficiaries,
    stockOptionsPlans,
    holdingsAndPlans,
    partnersAndBeneficiaries,
  } = useSelector((state) => ({
    actualSociety: state.society?.actualSociety,
    user: state.user,
    isAdmin: state.society?.role?.isAdmin,
    actualTenderOffer: state.tenderOffers.find(
      (tenderOffer) => tenderOffer._id === tenderOfferId
    ),
    holdingClasses: state.holdingClasses,
    plans: state.plans,
    partners: state.society?.actualSociety?.partners,
    phantomPlans: state.plans?.filter(
      (plan) => plan.planType === 'PHANTOM_SHARE'
    ),
    stockOptionsPlans: state.plans?.filter(
      (plan) => plan.planType === 'STOCK_OPTION'
    ),
    beneficiaries: state.society?.actualSociety?.beneficiaries,
    holdingsAndPlans: state.holdingClasses?.concat(state.plans),
    partnersAndBeneficiaries: state.society?.actualSociety?.partners?.concat(
      state.society?.actualSociety?.beneficiaries
    ),
  }));

  const labels = tenderOfferChartLabels;
  const colors = tenderOfferChartColors;
  const chartOptions = tenderOfferChartOptions;

  const [eligiblesList, setEligiblesList] = useState([]);
  const [totalPriceCurrentOffer, setTotalPriceCurrentOffer] = useState(0);
  const [currentDocument, setCurrentDocument] = useState(null);

  const [chartLegend, setChartLegend] = React.useState([]);
  const [chartData, setChartData] = React.useState(null);

  const [totalPriceAccepted, setTotalPriceAccepted] = useState(0);

  const availableOptions = useMemo(
    () => ({
      PARTNERS: {
        SOME_GROUPS: holdingClasses,
        SOME_MEMBERS: partners,
      },
      BENEFICIARIES_PHANTOM_SHARES: {
        SOME_GROUPS: phantomPlans,
        SOME_MEMBERS: beneficiaries,
      },
      BENEFICIARIES_STOCK_OPTIONS: {
        SOME_GROUPS: stockOptionsPlans,
        SOME_MEMBERS: beneficiaries,
      },
      ALL_MEMBERS: {
        SOME_GROUPS: holdingsAndPlans,
        SOME_MEMBERS: partnersAndBeneficiaries,
      },
    }),
    [
      holdingClasses,
      partners,
      phantomPlans,
      beneficiaries,
      stockOptionsPlans,
      partnersAndBeneficiaries,
      holdingsAndPlans,
    ]
  );

  const isTenderOfferInactive = () => {
    if (actualTenderOffer?.status === tenderStatusTypes.ACTIVE.value)
      return true;

    if (actualTenderOffer?.status === tenderStatusTypes.FINISHED.value)
      return true;

    if (!eligiblesList.length) return true;

    return false;
  };

  const isEditOptionDisabled = () => {
    if (actualTenderOffer?.status === tenderStatusTypes.ACTIVE.value)
      return true;

    if (actualTenderOffer?.status === tenderStatusTypes.FINISHED.value)
      return true;

    return false;
  };

  const getSummarySharesUnits = (list) => {
    if (!list) return 0;

    const totalUnits = list.reduce((acc, item) => acc + (item?.units || 0), 0);
    const totalPrice = totalUnits * actualTenderOffer?.totalPriceByShare;

    return totalPrice;
  };

  const getEligiblesName = () => {
    const selectedGroup =
      availableOptions[actualTenderOffer?.eligiblesType][
        actualTenderOffer?.eligiblesSubgroup
      ];

    let filteredEligibles = [];

    if (actualTenderOffer?.eligiblesSubgroup === eligiblesSubgroupTypes.ALL) {
      filteredEligibles = [
        `${t(actualTenderOffer?.eligiblesType)} (${t(
          actualTenderOffer?.eligiblesSubgroup
        )})`,
      ];
    } else {
      filteredEligibles = selectedGroup
        ? selectedGroup
            ?.filter((option) =>
              actualTenderOffer?.eligibles.includes(option._id)
            )
            ?.map((option) => option.name)
        : [];
    }

    return filteredEligibles;
  };

  const handleOpenModalSendOffer = () => {
    dispatch(
      setModal(
        <SendTenderOffer
          data={actualTenderOffer}
          eligiblesList={eligiblesList}
        />
      )
    );
  };

  const handleOpenModalEditOffer = () => {
    dispatch(
      setModal(
        <AddTenderOffer
          data={actualTenderOffer}
          action={actionTypes.EDIT}
          size={sizes.LG}
        />
      )
    );
  };

  const getCurrentDocument = async (documentId) => {
    const doc = await dispatch(getDocument(documentId));
    setCurrentDocument(doc);
  };

  useEffect(() => {
    if (actualTenderOffer?.document && !currentDocument) {
      getCurrentDocument(actualTenderOffer.document);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualTenderOffer]);

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId) getActualSociety(user, societyId);
  }, [actualSociety, user, societyId]);

  const eligiblesData = useMemo(() => {
    if (!actualTenderOffer || !actualSociety || !plans || !availableOptions)
      return null;

    return getEligiblesData(
      availableOptions,
      actualTenderOffer,
      actualSociety,
      plans
    );
  }, [availableOptions, actualTenderOffer, actualSociety, plans]);

  useEffect(() => {
    if (
      eligiblesData &&
      !deepEqual(eligiblesData, prevEligiblesDataRef.current)
    ) {
      setEligiblesList(eligiblesData);
      prevEligiblesDataRef.current = eligiblesData;
    }
  }, [eligiblesData]);

  useEffect(() => {
    if (societyId) {
      dispatch(getTenderOffers(societyId));
      dispatch(getStockPlans(societyId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [societyId]);

  useEffect(() => {
    if (!actualSociety || !actualTenderOffer || !eligiblesList.length) return;

    const totalOffer = getTotalUnitsFromEligibles(eligiblesList);

    const { legend, chartValues } = getChartTenderOfferData(
      actualSociety,
      actualTenderOffer,
      totalOffer
    );

    setChartLegend(legend);
    setChartData({
      labels,
      datasets: [
        {
          fill: true,
          label: 'Porciento',
          data: chartValues,
          borderWidth: 2,
          borderColor: '#ffffff',
          backgroundColor: colors,
        },
      ],
    });

    const { totalAccepted } = getTenderOfferStatus(
      actualSociety,
      actualTenderOffer
    );
    const { totalPriceByShare } = actualTenderOffer;

    setTotalPriceAccepted(totalAccepted * totalPriceByShare);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety, actualTenderOffer, eligiblesList]);

  useEffect(() => {
    setTotalPriceCurrentOffer(getSummarySharesUnits(eligiblesList));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eligiblesList]);

  return actualTenderOffer ? (
    <>
      <div className="nk-content-body">
        <div className="nk-content-wrap">
          <div className="nk-block-head">
            <div className="nk-block-head-content">
              <div className="nk-block-head-sub">
                <Link
                  className="back-to"
                  to={`/tender-offers/${actualSociety?._id}`}
                >
                  <em className="icon ni ni-arrow-left" />
                  <span>{t('TenderOffersList')}</span>
                </Link>
              </div>
              <div className="nk-block-between-md g-4">
                <div className="nk-block-head-content">
                  <h2 className="nk-block-title fw-normal">
                    {actualTenderOffer?.name}
                  </h2>
                  <div className="nk-block-des">
                    <p>
                      {t('StartDate')}{' '}
                      {actualTenderOffer?.startDate
                        ? format(
                            new Date(actualTenderOffer?.startDate),
                            dateFormat
                          )
                        : '-'}
                      &nbsp;
                      <span
                        className={`badge badge-pill ml-1 ${
                          tenderOfferStatus[actualTenderOffer?.status]?.style
                        }`}
                      >
                        {t(tenderOfferStatus[actualTenderOffer?.status]?.label)}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="nk-block-head-content">
                  <ul className="nk-block-tools gx-3">
                    <li className="">
                      <ReactToPrint
                        trigger={() => (
                          <span className="btn btn-icon btn-primary screen-only">
                            <em className="icon ni ni-printer" />
                          </span>
                        )}
                        content={() => printRef.current}
                        documentTitle={`${t('TenderOffer')} - ${
                          actualTenderOffer?.name
                        } - ${format(new Date(), 'dd-MM-yyyy')}`}
                      />
                    </li>
                    <li>
                      <button
                        type="button"
                        className="btn btn-success screen-only"
                        onClick={() => handleOpenModalEditOffer()}
                        disabled={isEditOptionDisabled() || isDemo}
                      >
                        <em className="icon ni ni-edit mr-2" />
                        {t('EditConditions')}
                      </button>
                    </li>
                    <li>
                      <button
                        type="button"
                        className="btn btn-warning screen-only"
                        onClick={() => handleOpenModalSendOffer()}
                        disabled={isTenderOfferInactive() || isDemo}
                      >
                        <em className="icon ni ni-send mr-2" />
                        {t('SendOffer')}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div ref={printRef} id="margin-print-only">
            <PrintHeader
              title={t('PrintHeaderTenderOffer')}
              subTitle={actualTenderOffer?.name}
            />

            <div className="nk-block">
              <div className="card card-bordered sp-plan">
                <div className="row no-gutters">
                  <div className="col-xl-8">
                    <div className="card card-bordered">
                      <div className="card-inner-group">
                        <div className="card-inner">
                          <div className="sp-plan-head">
                            <h6 className="title">{t('OfferPrice')}</h6>
                          </div>
                          <div className="sp-plan-desc sp-plan-desc-mb">
                            <ul className="row gx-1">
                              <li className="col-sm-4">
                                <p>
                                  <span className="text-soft">
                                    {t('StartDate')}
                                  </span>
                                  {actualTenderOffer?.startDate
                                    ? format(
                                        new Date(actualTenderOffer?.startDate),
                                        dateFormat
                                      )
                                    : '-'}
                                </p>
                              </li>
                              <li className="col-sm-4">
                                <p>
                                  <span className="text-soft">
                                    {t('EndDate')}
                                  </span>
                                  {actualTenderOffer?.endDate
                                    ? format(
                                        new Date(actualTenderOffer?.endDate),
                                        dateFormat
                                      )
                                    : '-'}
                                </p>
                              </li>
                              <li className="col-sm-4">
                                <p>
                                  <span className="text-soft">
                                    {t('CalculationDate')}
                                  </span>
                                  {actualTenderOffer?.calculationDate
                                    ? format(
                                        new Date(
                                          actualTenderOffer?.calculationDate
                                        ),
                                        dateFormat
                                      )
                                    : '-'}
                                </p>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="card-inner">
                          <div className="sp-plan-head">
                            <h6 className="title">{t('Buyer')}</h6>
                          </div>
                          <div className="sp-plan-desc sp-plan-desc-mb">
                            <ul className="row gx-1">
                              <li className="col-sm-4">
                                <p>
                                  <span className="text-soft">{t('Name')}</span>
                                  {actualTenderOffer?.buyerName}
                                </p>
                              </li>
                              <li className="col-sm-4">
                                <p>
                                  <span className="text-soft">
                                    {t('FiscalIdentification')}
                                  </span>
                                  {actualTenderOffer?.buyerNIF}
                                </p>
                              </li>
                              <li className="col-sm-4">
                                <p>
                                  <span className="text-soft">
                                    {t('Email')}
                                  </span>
                                  {actualTenderOffer?.buyerEmail}
                                </p>
                              </li>
                              <li className="col-sm-4">
                                <p>
                                  <span className="text-soft">
                                    {t('RepresentativeBoard')}
                                  </span>
                                  {actualTenderOffer?.buyerRepresentativeName ||
                                    '-'}
                                </p>
                              </li>
                              <li className="col-sm-4">
                                <p>
                                  <span className="text-soft">
                                    {t('FiscalIdentification')}
                                  </span>
                                  {actualTenderOffer?.buyerRepresentativeNIF ||
                                    '-'}
                                </p>
                              </li>
                              <li className="col-sm-4">
                                <p>
                                  <span className="text-soft">
                                    {t('Email')} {t('RepresentativeBoard')}
                                  </span>{' '}
                                  {actualTenderOffer?.buyerRepresentativeEmail ||
                                    '-'}
                                </p>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="card-inner">
                          <div className="sp-plan-head">
                            <h6 className="title">{t('Price')}</h6>
                          </div>
                          <div className="sp-plan-desc sp-plan-desc-mb">
                            <ul className="row gx-1">
                              <li className="col-sm-4">
                                <p>
                                  <span className="text-soft">
                                    {t('PerShare')}
                                  </span>{' '}
                                  {actualTenderOffer?.isPriceByShare
                                    ? t('Yes')
                                    : t('Nope')}
                                </p>
                              </li>
                              <li className="col-sm-4">
                                <p>
                                  <span className="text-soft">
                                    {t('SocietyValuation')}
                                  </span>{' '}
                                  {actualTenderOffer?.isPriceBySociety
                                    ? t('Tes')
                                    : t('Nope')}
                                </p>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="card-inner">
                          <div className="sp-plan-head-group">
                            <div className="sp-plan-head">
                              <h6 className="title">{t('PricePerShare')}</h6>
                            </div>
                            <div className="sp-plan-amount">
                              <span className="amount">
                                {formatCurrencyDecimals(
                                  actualTenderOffer?.totalPriceByShare
                                )}
                              </span>
                            </div>
                          </div>
                          <br />
                          <div className="sp-plan-head-group">
                            <div className="sp-plan-head">
                              <h6 className="title">
                                {t('SocietyValuation')} &nbsp;
                                <span className="badge badge-pill badge-primary">
                                  {actualTenderOffer?.isPriceFD === true
                                    ? 'FD'
                                    : ''}
                                  {actualTenderOffer?.isPriceFD === false
                                    ? 'ND'
                                    : ''}
                                </span>
                              </h6>
                            </div>

                            <div className="sp-plan-amount">
                              <span className="amount">
                                {formatCurrencyDecimals(
                                  actualTenderOffer?.totalPriceBySociety
                                )}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="card-inner">
                          <div className="sp-plan-head-group">
                            <div className="sp-plan-head">
                              <h6 className="title">{t('TotalOfferAmount')}</h6>
                              <span className="ff-italic text-soft">
                                {t('TotalOfferAmountMessage')}
                              </span>
                            </div>
                            <div className="sp-plan-amount">
                              <span className="amount">
                                {formatNumber(totalPriceCurrentOffer) || 0}€
                              </span>
                            </div>
                          </div>
                          <br />
                          <div className="sp-plan-head-group">
                            <div className="sp-plan-head">
                              <h6 className="title">
                                {t('AcceptedOfferAmount')}
                              </h6>
                              <span className="ff-italic text-soft">
                                {t('AmountAcceptedByEligible')}
                              </span>
                            </div>
                            <div className="sp-plan-amount">
                              <span className="amount">
                                {formatNumber(totalPriceAccepted) || 0}€
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="card-inner">
                          <div className="sp-plan-head">
                            <h6 className="title">{t('Elegible')}</h6>
                          </div>
                          <div className="sp-plan-desc sp-plan-desc-mb">
                            <ul className="row gx-1">
                              <li className="col-sm-4">
                                <p>
                                  {/* @Marti actualizar condiciones */}
                                  <span className="text-soft">
                                    {t('Partners')}
                                  </span>
                                  {actualTenderOffer?.eligiblesType ===
                                  tenderEligibleTypes.PARTNERS
                                    ? t('Yes')
                                    : t('Nope')}
                                </p>
                              </li>
                              <li className="col-sm-4">
                                <p>
                                  <span className="text-soft">
                                    {t('Beneficiary')} {t('Phantoms')}
                                  </span>{' '}
                                  {actualTenderOffer?.eligiblesType ===
                                  tenderEligibleTypes.BENEFICIARIES_PHANTOM_SHARES
                                    ? t('Yes')
                                    : t('Nope')}
                                </p>
                              </li>
                              <li className="col-sm-4">
                                <p>
                                  <span className="text-soft">
                                    {t('Beneficiary')} {t('StockOptions')}
                                  </span>{' '}
                                  {actualTenderOffer?.eligiblesType ===
                                  'beneficiaryStockOptions'
                                    ? t('Yes')
                                    : t('Nope')}
                                </p>
                              </li>
                              <li className="col-sm-4">
                                <p>
                                  <span className="text-soft">
                                    {t('ForAll')}
                                  </span>
                                  {actualTenderOffer?.eligiblesSubgroup ===
                                  eligiblesSubgroupTypes.ALL
                                    ? t('Yes')
                                    : t('Nope')}
                                </p>
                              </li>
                              <li className="col-sm-4">
                                <p>
                                  <span className="text-soft">
                                    {t('SomeGroups')}
                                  </span>{' '}
                                  {actualTenderOffer?.eligiblesSubgroup ===
                                  eligiblesSubgroupTypes.SOME_GROUPS
                                    ? t('Yes')
                                    : t('Nope')}
                                </p>
                              </li>
                              <li className="col-sm-4">
                                <p>
                                  <span className="text-soft">
                                    {t('SomePeople')}
                                  </span>{' '}
                                  {actualTenderOffer?.eligiblesSubgroup ===
                                  eligiblesSubgroupTypes.SOME_MEMBERS
                                    ? t('Yes')
                                    : t('Nope')}
                                </p>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="card-inner">
                          <div className="sp-plan-head">
                            <h6 className="title">
                              {t('EligiblePlansClassesMembers')}
                            </h6>
                          </div>
                          <div className="sp-plan-desc sp-plan-desc-mb">
                            <ul className="list list-checked">
                              {getEligiblesName()?.map((eligible) => (
                                <li className="col-sm-8" key={eligible}>
                                  {eligible}
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                        <div className="card-inner">
                          <div className="sp-plan-head">
                            <h6 className="title">{t('SpecialConditions')}</h6>
                          </div>
                          <div className="sp-plan-desc sp-plan-desc-mb">
                            <ul>
                              <li className="col-12 d-inline-flex py-0">
                                <p className="col-4 px-0 d-flex flex-column justify-content-end">
                                  {t('Shares')}
                                </p>
                                <p className="col-4 px-0">
                                  <span className="text-soft">
                                    {t('MaximumNumber')}
                                  </span>{' '}
                                  {formatNumber(actualTenderOffer?.maxUnits) ||
                                    0}
                                </p>
                                <p className="col-4 px-0">
                                  <span className="text-soft">
                                    {t('MaximumPercent')}
                                  </span>{' '}
                                  {formatNumber(
                                    actualTenderOffer?.maxPercentUnits
                                  ) || 0}
                                </p>
                              </li>

                              <li className="col-12 d-inline-flex py-0">
                                <p className="col-4 px-0 d-flex flex-column justify-content-end">
                                  {t('PhantomShares')}
                                </p>
                                <p className="col-4 px-0">
                                  <span className="text-soft">
                                    {t('MaximumNumber')}
                                  </span>{' '}
                                  {formatNumber(
                                    actualTenderOffer?.maxPhantoms
                                  ) || 0}
                                </p>
                                <p className="col-4 px-0">
                                  <span className="text-soft">
                                    {t('MaximumPercent')}
                                  </span>{' '}
                                  {formatNumber(
                                    actualTenderOffer?.maxPercentPhantoms
                                  ) || 0}
                                </p>
                              </li>

                              <li className="col-12 d-inline-flex py-0">
                                <p className="col-4 px-0 d-flex flex-column justify-content-end">
                                  {t('StockOptions')}
                                </p>
                                <p className="col-4 px-0">
                                  <span className="text-soft">
                                    {t('MaximumNumber')}
                                  </span>{' '}
                                  {formatNumber(actualTenderOffer?.maxStocks) ||
                                    0}
                                </p>
                                <p className="col-4 px-0">
                                  <span className="text-soft">
                                    {t('MaximumPercent')}
                                  </span>{' '}
                                  {formatNumber(
                                    actualTenderOffer?.maxPercentStocks
                                  ) || 0}
                                </p>
                              </li>

                              <li className="col-12 d-inline-flex py-0">
                                <p className="col-4 px-0 d-flex flex-column justify-content-end">
                                  {t('AnotherConditions')}
                                </p>
                                <p className="col-4 px-0">
                                  <span className="text-soft">
                                    {t('MaximumAmountPerPartnerBeneficiary')}
                                  </span>{' '}
                                  {formatNumber(actualTenderOffer?.maxAmount) ||
                                    0}
                                </p>
                                <p className="col-4 px-0">
                                  <span className="text-soft">
                                    {t('MaximumPercentageVestedUnitsCanSold')}
                                  </span>{' '}
                                  {formatNumber(
                                    actualTenderOffer?.maxPercentUnvested
                                  ) || 0}
                                </p>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="card-inner">
                          <div className="sp-plan-head-group">
                            <div className="sp-plan-head">
                              <h6 className="title">
                                {t('PaymentConditions')}
                              </h6>
                              <span className="ff-italic text-soft">
                                {actualTenderOffer?.paymentConditions}
                              </span>
                            </div>
                          </div>
                          <ul className="row gx-1 mt-3">
                            <li className="col-sm-4">
                              <p>
                                <span className="text-soft d-block">
                                  % {t('Retention')}
                                </span>
                                {formatNumberDecimals(
                                  actualTenderOffer?.retentionPercent || 0
                                )}
                                %
                              </p>
                            </li>
                          </ul>
                        </div>
                        <div className="card-inner">
                          <div className="sp-plan-head-group">
                            <div className="sp-plan-head">
                              <h6 className="title">{t('Comments')}</h6>
                              <span className="ff-italic text-soft">
                                {actualTenderOffer?.details}
                              </span>
                            </div>
                          </div>
                        </div>
                        {currentDocument && (
                          <div className="card-inner">
                            <div
                              className="sp-plan-link text-primary cursor-pointer"
                              onClick={() =>
                                downloadFile({
                                  documentId: currentDocument._id,
                                })
                              }
                            >
                              <span>
                                <em className="icon ni ni-file mr-1" />
                                <b>{t('OfferContract')}&nbsp;</b>
                                {currentDocument?.name}
                              </span>
                              <em className="icon ni ni-link ml-1" />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-4">
                    <div className="sp-plan-action card-inner p-1 tender-chart justify-content-start">
                      <h6 className="text-center pt-3">
                        {t('OfferEvolution')}
                      </h6>
                      {chartData && (
                        <WidgetChartDoughnut
                          title={t('OfferEvolution')}
                          data={{ data: chartData, options: chartOptions }}
                          legendMode={legendModes.VERTICAL}
                          legend={chartLegend}
                          isEmbedded
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <TenderOfferEligiblesRow
              eligiblesData={eligiblesList}
              tenderOfferData={actualTenderOffer}
            />
          </div>
        </div>
      </div>
    </>
  ) : (
    <CustomLoading />
  );
};

export default TenderOfferDetails;
