/* eslint-disable no-underscore-dangle */
/* eslint-disable no-case-declarations */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useMixpanel } from 'react-mixpanel-browser';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import eventTypes from 'constants/eventTypes';
import transactionTypes from 'constants/transactionTypes';
import { SELECT_OPTION } from 'constants/defaultConstants';

import store from 'redux/store';
import { addAlert } from 'redux/actions/alertActions';
import { setModal } from 'redux/actions/modalActions';
import { sellParticipationsAction } from 'redux/actions/modalsActions';

import {
  formatCurrencyDecimals,
  formatNumber,
  formatNumberDecimalsLong,
} from 'utils/formats';
import trackEvent from 'utils/trackEvent';
import tenderOfferSharesSignature from 'utils/tenderOfferSharesSignature';

import alertBodyTypes from 'components/Alert/alertBodyTypes';

import Swal from 'sweetalert2';
import TenderOfferSellSharesInvoice from './TenderOfferSellSharesInvoice';
import './TenderOfferSellShares.scss';

const optionTypes = {
  FULL: 'FULL',
  CUSTOM: 'CUSTOM',
};

const TenderOfferSellShares = ({ partner }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();

  const user = useSelector((state) => state?.user);
  const actualSociety = useSelector((state) => state.society?.actualSociety);
  const tenderOffersShares = useSelector((state) => state.tenderOffersShares);

  const [buyerPartner, setBuyerPartner] = useState();
  const [holdingClass, setHoldingClass] = useState();
  const [selectedOfferOption, setSelectedOfferOption] = useState(SELECT_OPTION);
  const [tenderOffer, setTenderOffer] = useState();

  const [optionSelected, setOptionSelected] = useState(null);

  const [customShares, setCustomShares] = useState(0);
  const [customTotalAmount, setCustomTotalAmount] = useState(0);
  const [customTotalShares, setCustomTotalShares] = useState(0);

  const [price, setPrice] = useState(0);

  const [sellAll, setSellAll] = useState(false);
  const [maxShares, setMaxShares] = useState(0);
  const [maxAmount, setMaxAmount] = useState(0);
  const [retention, setRetention] = useState(0);

  const [movements, setMovements] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [finalShares, setFinalShares] = useState([]);
  const [sharesCost, setSharesCost] = useState(0);

  const [step, setStep] = useState(1);

  const animatedComponents = makeAnimated();

  const generateMovements = () => {
    try {
      if (buyerPartner && partner?.sharesCount?.actual >= customShares) {
        const finalTransactions = [];
        const finalMovements = [];
        const shares = [];
        let remainingShares = customShares;
        const partnerShares = actualSociety?.shares
          ?.filter(
            (share) => share?.isActive && share?.partner === partner?.['_id']
          )
          .sort((a, b) => b?.from - a?.from);
        // eslint-disable-next-line no-restricted-syntax
        for (const share of partnerShares) {
          if (remainingShares === 0) break;
          const count = share?.to - share?.from + 1;
          if (remainingShares < count) {
            finalMovements.push({
              partner: partner?.['_id'],
              shareFrom: +share.from,
              shareTo: +share.to,
              shareClass: share?.shareClass?.['_id'],
              movementType: 'DECREMENT',
            });
            finalMovements.push({
              partner: partner?.['_id'],
              shareFrom: +share.from + remainingShares,
              shareTo: +share.to,
              shareClass: share?.shareClass?.['_id'],
              sharePremium: share?.sharePremium || 0,
              sharePrice: share?.sharePrice,
              operation: share?.operation || null,
            });
            finalMovements.push({
              partner: buyerPartner?.['_id'],
              shareFrom: +share.from,
              shareTo: +share.from + remainingShares - 1,
              shareClass: share?.shareClass?.['_id'],
              sharePremium: share?.sharePremium || 0,
              sharePrice: price,
              operation: 'THIS',
            });
            finalTransactions.push({
              partner: buyerPartner?.['_id'],
              shareFrom: +share.from,
              shareTo: +share.from + remainingShares - 1,
              shareClass: share?.shareClass?.['_id'],
              sharePremium: share?.sharePremium || 0,
              sharePrice: price,
              transactionType: transactionTypes.BUY,
            });
            finalTransactions.push({
              partner: partner?.['_id'],
              shareFrom: +share.from,
              shareTo: +share.from + remainingShares - 1,
              shareClass: share?.shareClass?.['_id'],
              sharePremium: share?.sharePremium || 0,
              sharePrice: price,
              operation: share?.operation || null,
              transactionType: transactionTypes.SELL,
            });
            const shareCost = share?.sharePrice
              ? share.sharePrice * remainingShares
              : (actualSociety?.nominalValue + (share?.sharePremium || 0)) *
                remainingShares;
            shares.push({
              partner: buyerPartner?.['_id'],
              shareFrom: +share.from,
              shareTo: +share.from + remainingShares - 1,
              className: share?.shareClass?.name,
              shareCost,
              sharePremium: share?.sharePremium || 0,
              sharePrice: price,
            });

            remainingShares -= count;

            break;
          } else {
            finalMovements.push({
              partner: partner?.['_id'],
              shareFrom: +share.from,
              shareTo: +share.to,
              shareClass: share?.shareClass?.['_id'],
              movementType: 'DECREMENT',
            });
            finalMovements.push({
              partner: buyerPartner?.['_id'],
              shareFrom: +share.from,
              shareTo: +share.to,
              shareClass: share?.shareClass?.['_id'],
              sharePremium: share?.sharePremium || 0,
              sharePrice: price,
              operation: 'THIS',
            });
            finalTransactions.push({
              partner: buyerPartner?.['_id'],
              shareFrom: +share.from,
              shareTo: +share.to,
              shareClass: share?.shareClass?.['_id'],
              sharePremium: share?.sharePremium || 0,
              sharePrice: price,
              transactionType: transactionTypes.BUY,
            });
            finalTransactions.push({
              partner: partner?.['_id'],
              shareFrom: +share.from,
              shareTo: +share.to,
              shareClass: share?.shareClass?.['_id'],
              sharePremium: share?.sharePremium || 0,
              sharePrice: price,
              operation: share?.operation || null,
              transactionType: transactionTypes.SELL,
            });
            const shareCost = share?.sharePrice
              ? share.sharePrice * count
              : (actualSociety?.nominalValue + (share?.sharePremium || 0)) *
                count;
            shares.push({
              partner: buyerPartner?.['_id'],
              shareFrom: +share.from,
              shareTo: +share.to,
              className: share?.shareClass?.name,
              shareCost,
              sharePremium: share?.sharePremium || 0,
              sharePrice: price,
            });

            remainingShares -= count;
          }
        }
        const totalSharesCost = shares?.reduce(
          (acc, curr) => acc + curr?.shareCost || 0,
          0
        );
        setSharesCost(totalSharesCost);
        setFinalShares(shares);
        setMovements(finalMovements);
        setTransactions(finalTransactions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSellTenderOffer = async () => {
    try {
      if (movements?.length && transactions?.length) {
        await store.dispatch(
          sellParticipationsAction(
            {
              date: new Date(),
              society: actualSociety['_id'],
              user: user?.['_id'],
              nominalValue: actualSociety?.nominalValue,
              movements,
              transactions,
              documents: [],
              comments: '',
            },
            false // showAlert
          )
        );
        dispatch(setModal(null));

        Swal.fire({
          icon: 'success',
          title: `<h4 class="nk-modal-title">${t('AcceptedOffer')}</h4>`,
          html: `<div class="caption-text">${t('AcceptedOfferMessage')}</div>`,
          footer: `<div class="bg-lighter"><div class="text-center w-100"><p>${t(
            'AcceptedOfferMessageFooter'
          )}</p></div></div>`,
          confirmButtonText: t('OK'),
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonColor: '#6576FF',
        });

        const tenderOfferData = {
          sellerCif: partner?.cif,
          sellerName: partner?.name,
          sellerAddress: partner?.address,
          buyerCif: buyerPartner?.cif,
          buyerName: buyerPartner?.name,
          buyerAddress: buyerPartner?.address,
          sharesCost,
          retention,
          sharesFrom: finalShares[finalShares.length - 1]?.shareFrom,
          sharesTo: finalShares[0]?.shareTo,
          sharePrice: price,
          totalPrice: customTotalAmount,
          totalShares: customTotalShares,
        };

        dispatch(
          tenderOfferSharesSignature({
            user,
            partner,
            tenderOffer,
            tenderOfferData,
            actualSociety,
            translate: t,
          })
        );

        // TODO: Confirm track event name
        trackEvent(mixpanel, eventTypes.SELL_PARTICIPATION, {
          userId: user?.['_id'],
          userName: user?.name,
          userEmail: user?.email,
          societyId: actualSociety?.['_id'],
          societyName: actualSociety?.name,
          operation: eventTypes.SELL_PARTICIPATION,
        });
      } else {
        return Swal.fire({
          icon: 'error',
          title: `<h4 class="nk-modal-title">${t('RejectedOffer')}</h4>`,
          footer: `<div class="bg-lighter"><div class="text-center w-100"><p>${t(
            'RejectedOfferMessageFooter'
          )}</p></div></div>`,
          confirmButtonText: t('OK'),
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonColor: '#6576FF',
        });
      }
    } catch (error) {
      return Swal.fire({
        icon: 'error',
        title: `<h4 class="nk-modal-title">${t('RejectedOffer')}</h4>`,
        footer: `<div class="bg-lighter"><div class="text-center w-100"><p>${t(
          'RejectedOfferMessageFooter'
        )}</p></div></div>`,
        confirmButtonText: t('OK'),
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonColor: '#6576FF',
      });
    }
  };

  const handleCancelTenderOffer = async () => {
    dispatch(setModal(null));
  };

  const isSaveDisabled = () => {
    if (!customTotalAmount) return true;

    return false;
  };

  const handleClickNext = () => {
    if (step === 1) {
      generateMovements();
      setStep(2);
    }
  };

  const handleClickBack = () => {
    if (step === 2) {
      setStep(1);
    }
  };

  const handleTenderOfferChange = (selectedOption) => {
    setSelectedOfferOption(selectedOption);
  };

  const handleChangeCustomShares = (value) => {
    if (+value >= 0 && +value <= maxShares) {
      setCustomShares(+value);
    }
  };

  useEffect(() => {
    switch (optionSelected) {
      case optionTypes.FULL:
        setCustomTotalShares(maxShares);
        setCustomTotalAmount(maxShares * price);
        break;
      case optionTypes.CUSTOM:
        setCustomShares(0);
        setCustomTotalShares(0);
        setCustomTotalAmount(0);
        break;

      default:
        break;
    }
  }, [optionSelected]);

  useEffect(() => {
    setCustomTotalAmount(customShares * price);
    setCustomTotalShares(customShares);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customShares]);

  useEffect(() => {
    const activeTenders = tenderOffersShares?.filter(
      (tender) => tender?.isActive && tender?.type === 'BUY'
    );
    if (activeTenders?.length === 1) {
      setSelectedOfferOption({
        value: activeTenders[0]?.['_id'],
        label: activeTenders[0]?.name,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenderOffersShares]);

  useEffect(() => {
    if (selectedOfferOption === SELECT_OPTION) {
      setPrice(0);
      setSellAll(false);
      setMaxShares(0);
      setMaxAmount(0);
      setRetention(0);
      setBuyerPartner(null);
      setCustomShares(0);
      setCustomTotalShares(0);
      setCustomTotalAmount(0);
      return;
    }
    if (actualSociety && selectedOfferOption !== SELECT_OPTION) {
      const tender = tenderOffersShares?.find(
        (offer) => offer['_id'] === selectedOfferOption?.value
      );
      if (tender) {
        setTenderOffer(tender);
        setPrice(tender?.price);

        if (tender?.sellAll) {
          setSellAll(true);
          setMaxShares(partner?.sharesCount?.actual);
          setMaxAmount(partner?.sharesCount?.actual * tender?.price);
        } else {
          setSellAll(false);
          setMaxShares(tender?.maxShares);
          setMaxAmount(tender?.maxAmount);
        }
        setRetention(tender?.retentionPercent);
        const stockPartner = actualSociety?.partners?.find(
          (partner) => partner['_id'] === tender?.sellerId
        );
        if (stockPartner) {
          const stockPartnerClass = actualSociety?.shares?.find(
            (share) => share?.partner === stockPartner?.['_id']
          )?.shareClass;
          setHoldingClass(stockPartnerClass);
          setBuyerPartner(stockPartner);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenderOffersShares, actualSociety, selectedOfferOption]);

  return (
    <Modal.Body>
      <div className="text-right">
        <a
          className="text-soft fs-22px  cursor-pointer"
          onClick={() => dispatch(setModal(null))}
        >
          <em className="icon ni ni-cross" />
        </a>
      </div>
      <div className="modal-body modal-body-md pt-0">
        <div className="sp-package text-center" id="sell-tender-offer">
          {step === 1 && (
            <>
              <div className="sp-package-head">
                <h4 className="title">{t('QuantityToSellIndication')}</h4>
              </div>

              <div className="form-group mt-3">
                <label className="form-label" htmlFor="pay-amount">
                  {t('SelectOffer')}
                </label>
                <div className="form-control-wrap">
                  <div className="">
                    <Select
                      closeMenuOnSelect
                      className="react-select"
                      value={selectedOfferOption}
                      options={tenderOffersShares
                        ?.filter(
                          (tender) => tender?.isActive && tender?.type === 'BUY'
                        )
                        .map((tender) => ({
                          value: tender?.['_id'],
                          label: tender?.name,
                        }))}
                      components={animatedComponents}
                      onChange={handleTenderOfferChange}
                      placeholder={SELECT_OPTION}
                    />
                  </div>
                </div>
              </div>

              <ul className="sp-package-list">
                <li className="sp-package-item">
                  <input
                    className="sp-package-choose"
                    type="radio"
                    name="sell-shares-100-percent"
                    id="sell-shares-100-percent"
                    value={optionTypes.FULL}
                    checked={optionSelected === optionTypes.FULL}
                    disabled={selectedOfferOption === SELECT_OPTION}
                    onChange={(e) => setOptionSelected(e.target.value)}
                  />
                  <label
                    className="sp-package-desc"
                    htmlFor="sell-shares-100-percent"
                  >
                    <span className="sp-package-info">
                      <span className="sp-package-title title">
                        {t('SellingMax')}&nbsp;
                        {optionSelected === optionTypes.FULL && (
                          <span className="badge badge-primary badge-pill">
                            {t('Selected')}
                          </span>
                        )}
                      </span>
                      <span className="sp-package-detail d-block">
                        <strong>{t('Shares')}:&nbsp;</strong>
                        {formatNumber(maxShares)}
                      </span>
                      <span className="sp-package-detail d-block">
                        <strong>{t('PriceShare')}:&nbsp;</strong>
                        {formatNumberDecimalsLong(price)}€
                      </span>
                    </span>
                    <span className="sp-package-price">
                      <span className="sp-package-amount yearly">
                        <span className="amount">
                          {formatCurrencyDecimals(maxAmount)}
                        </span>

                        <span className="text-soft">
                          {t('Profit')}:&nbsp;
                          <span className="badge badge-light badge-pill">
                            {formatNumber(0) || 0}{' '}
                            {/* // TODO: Calculate difference */}
                            &nbsp;€
                          </span>
                        </span>
                      </span>
                    </span>
                  </label>
                </li>
                <li className="sp-package-item">
                  <input
                    className="sp-package-choose"
                    type="radio"
                    name="sell-shares-custom"
                    id="sell-shares-custom"
                    value={optionTypes.CUSTOM}
                    checked={optionSelected === optionTypes.CUSTOM}
                    disabled={selectedOfferOption === SELECT_OPTION}
                    onChange={(e) => setOptionSelected(e.target.value)}
                  />
                  <label
                    className="sp-package-desc"
                    htmlFor="sell-shares-custom"
                  >
                    <span className="sp-package-info">
                      <span className="sp-package-title title">
                        {t('DifferentNumber')}
                        {optionSelected === optionTypes.CUSTOM && (
                          <span className="badge badge-primary badge-pill">
                            {t('Selected')}
                          </span>
                        )}
                      </span>

                      {optionSelected === optionTypes.CUSTOM && (
                        <div className="form-control-wrap my-2">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text"
                                id="inputGroup-sizing-default"
                              >
                                {t('Shares')}
                              </span>
                            </div>
                            <input
                              type="number"
                              className="form-control text-right"
                              value={customShares}
                              onChange={(e) =>
                                handleChangeCustomShares(e.target.value)
                              }
                              disabled={optionSelected !== optionTypes.CUSTOM}
                            />
                          </div>
                        </div>
                      )}
                      <span className="sp-package-detail d-block">
                        <strong>{t('Shares')}:&nbsp;</strong>
                        {formatNumber(maxShares)}
                      </span>
                      <span className="sp-package-detail d-block">
                        <strong>{t('PriceShare')}:&nbsp;</strong>
                        {formatNumberDecimalsLong(price)}€
                      </span>
                    </span>
                    <span className="sp-package-price d-flex justify-content-center align-items-center">
                      <span className="sp-package-amount yearly">
                        <span className="amount">
                          {optionSelected === optionTypes.CUSTOM
                            ? formatNumber(customTotalAmount)
                            : formatNumber(0)}
                          €
                        </span>

                        <span className="text-soft">
                          {t('Profit')}:&nbsp;
                          <span className="badge badge-light badge-pill">
                            {formatNumber(0) || 0}
                            &nbsp;€
                          </span>
                        </span>
                      </span>
                    </span>
                  </label>
                </li>
              </ul>
            </>
          )}

          {step === 2 && (
            <>
              <div className="sp-package-head">
                <h4 className="title mb-4">{t('TransactionSummary')}</h4>
              </div>

              <TenderOfferSellSharesInvoice
                data={{
                  partner,
                  actualSociety,
                  buyerName: buyerPartner?.name,
                  sharesClass: holdingClass?.name || '',
                  sharesCost,
                  sharesPrice: price,
                  retention,
                  totalShares: customTotalShares,
                  totalAmount: customTotalAmount,
                }}
              />
            </>
          )}

          <div className="sp-package-action">
            {step === 1 && (
              <>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleClickNext}
                  disabled={isSaveDisabled()}
                >
                  {t('Next')}
                </button>
                <button
                  type="button"
                  className="btn btn-dim btn-danger"
                  onClick={handleCancelTenderOffer}
                >
                  {t('Cancel')}
                </button>
              </>
            )}

            {step === 2 && (
              <>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleClickBack}
                >
                  {t('Back')}
                </button>
                <button
                  type="button"
                  className="btn btn-primary btn-success text-secondary"
                  onClick={handleSellTenderOffer}
                  disabled={isSaveDisabled()}
                >
                  {t('ConfirmTransaction')}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </Modal.Body>
  );
};

export default TenderOfferSellShares;
