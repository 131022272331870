import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { setModal } from 'redux/actions/modalActions';

import signatureSend from 'assets/images/signature-send.svg';
import signaturePending from 'assets/images/signature-pending.svg';
import signatureCompleted from 'assets/images/signature-completed.svg';
import SignatureModal from 'pages/Documents/signatureModal';

const SignaturesHeader = ({ setSignatureDocumentCategory }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isDemo = useSelector((state) => state.society?.role?.isDemo);

  return (
    <div className="nk-files nk-files-view-grid mb-4">
      <div className="nk-files-list">
        <div
          className="nk-file-item nk-file cursor-pointer"
          onClick={() => {
            if (!isDemo) {
              dispatch(setModal(<SignatureModal />));
            }
          }}
        >
          <div className="nk-file-info">
            <div href="#" className="nk-file-link">
              <div className="nk-file-title">
                <div className="nk-file-icon">
                  <span className="nk-file-icon-type">
                    <img src={signatureSend} alt="Sign new document" />
                  </span>
                </div>
                <div className="nk-file-name">
                  <div className="nk-file-name-text">
                    <span className="title">{t('SignNewDocument')}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="nk-file-item nk-file cursor-pointer"
          onClick={() => setSignatureDocumentCategory('SIGNED')}
        >
          <div className="nk-file-info">
            <div className="nk-file-link">
              <div className="nk-file-title">
                <div className="nk-file-icon">
                  <span className="nk-file-icon-type">
                    <img src={signatureCompleted} alt="Documents signed" />
                  </span>
                </div>
                <div className="nk-file-name">
                  <div className="nk-file-name-text">
                    <span className="title">{t('DocumentsSigned')}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="nk-file-item nk-file cursor-pointer"
          onClick={() => setSignatureDocumentCategory('PENDING')}
        >
          <div className="nk-file-info">
            <div className="nk-file-link">
              <div className="nk-file-title">
                <div className="nk-file-icon">
                  <span className="nk-file-icon-type">
                    <img src={signaturePending} alt="Documents pending" />
                  </span>
                </div>
                <div className="nk-file-name">
                  <div className="nk-file-name-text">
                    <span className="title">{t('DocumentsPending')}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignaturesHeader;
