import React from 'react';
import { format } from 'date-fns';
import NumberFormat from 'react-number-format';
import vestingOptions from 'constants/vestingOptions';
import { useTranslation } from 'react-i18next';
import { numberFormat, currencyFormatDecimals } from 'constants/formats';
import cliffOptions from 'constants/cliffOptions';
import { getBeneficiaryAmount } from 'utils/filters';

const planTypes = {
  STOCK_OPTION: 'Stock Options',
  PHANTOM_SHARE: 'Phantom Shares',
};

const BeneficiaryPlanDetails = ({ beneficiary, plan, society }) => {
  const { t } = useTranslation();

  const conditions = beneficiary?.customConditions;

  const originalAmount = getBeneficiaryAmount(beneficiary, plan);
  const sharePrice = conditions?.sharePrice || plan?.sharePrice;
  const cliff =
    conditions && 'cliff' in conditions ? conditions?.cliff : plan?.cliff;
  const vestingPeriod =
    conditions && 'vestingPeriod' in conditions
      ? conditions?.vestingPeriod
      : plan?.vestingPeriod;

  return (
    <ul className="list">
      <li>
        <b>{t('BeneficiaryName')}: </b>
        {beneficiary?.name}
      </li>
      <li>
        <b>{t('NifNiPassport')}: </b>
        {beneficiary?.cif}
      </li>
      <li>
        <b>{t('Business')}: </b>
        {society?.name}
      </li>
      <li>
        <b>{t('PlanName')}: </b>
        {plan?.name}
      </li>
      <li>
        <b>{t('TypeOfBenefit')}: </b>
        {planTypes[beneficiary?.planType]}
      </li>
      <li>
        <b>{t('NumberOfUnitsGranted')}: </b>
        <NumberFormat
          displayType="text"
          value={beneficiary?.sharesCount?.future}
          renderText={(value) => value}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...numberFormat}
        />
      </li>
      <li>
        <b>{t('DateOfConcessionOfTheUnit')}: </b>
        {beneficiary?.planStartDate
          ? format(new Date(beneficiary?.planStartDate), 'dd/MM/yyyy')
          : ''}
      </li>
      <li>
        <b>{t('TotalExercisePrice')}: </b>
        <NumberFormat
          displayType="text"
          value={originalAmount}
          renderText={(value) => value}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...currencyFormatDecimals}
        />
      </li>
      <li>
        <b>{t('ThePricePerUnitIs')}: </b>
        <NumberFormat
          displayType="text"
          value={sharePrice}
          renderText={(value) => value}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...currencyFormatDecimals}
        />
      </li>
      <li>
        <b>{t('Cliff')}: </b>
        {cliffOptions?.find((c) => c.value === cliff)?.text}
      </li>
      <li>
        <b>{t('VestingConsolidationPeriod')}: </b>
        {vestingOptions?.find((v) => v.value === vestingPeriod)?.text}
      </li>
      <li>
        <b>{t('FinishDate')}: </b>
        {beneficiary?.consolidationDate
          ? format(new Date(beneficiary?.consolidationDate), 'dd/MM/yyyy')
          : '-'}
      </li>
      {beneficiary?.isCanceled && (
        <li>
          <b>{t('CancelationDate')}: </b>
          {beneficiary?.cancelationDate
            ? format(new Date(beneficiary?.cancelationDate), 'dd/MM/yyyy')
            : '-'}
        </li>
      )}
      {beneficiary?.invitationDate && (
        <li>
          <b>{t('InvitationDate')}: </b>
          {format(new Date(beneficiary?.invitationDate), 'dd/MM/yyyy')}
        </li>
      )}
    </ul>
  );
};

export default BeneficiaryPlanDetails;
