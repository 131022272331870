/* eslint-disable new-cap */
/* eslint-disable no-underscore-dangle */
import store from 'redux/store';
// eslint-disable-next-line import/no-unresolved, import/no-extraneous-dependencies
import html2pdf from 'html3pdf';
import jsPDF from 'jspdf';
import { format } from 'date-fns';
import { enUS, es } from 'date-fns/locale';
import i18n from 'i18n/config';

import { setModal } from 'redux/actions/modalActions';
import { addAlert } from 'redux/actions/alertActions';
import { JSPDF_MARGINS } from 'constants/defaultConstants';
import alertBodyTypes from 'components/Alert/alertBodyTypes';

import cleanHtmlEntities from './editor';
import addWatermark from './addWatermark';

const showDetails = JSON.parse(localStorage.getItem('showDetails'));
// TODO Set to showDetails after embed watermark its correctly done
const embedWatermark = false;

const LOCALES = {
  es,
  en: enUS,
};

export default async function previewDownload(params) {
  try {
    if (embedWatermark) {
      const pdf = new jsPDF('p', 'pt', 'a4');
      const docHTML = cleanHtmlEntities(params.preview.documentModel);
      const now = format(Date.now(), 'PPPPpppp', {
        locale: LOCALES[i18n.language],
      });
      const userName = params.user.name || '';
      const userEmail = params.user.email || '';
      const societyName = params.society.name || '';
      const watermarkData = i18n.t('WatermarkData', {
        date: now,
        userName,
        userEmail,
        societyName,
      });

      await new Promise((resolve, reject) => {
        pdf.fromHTML(
          docHTML,
          JSPDF_MARGINS.left,
          JSPDF_MARGINS.top,
          {
            width: JSPDF_MARGINS.width,
          },
          () => {
            try {
              const pdfWithWatermark = addWatermark(pdf, watermarkData);
              pdfWithWatermark.save('preview');
              resolve();
            } catch (error) {
              reject(error);
            }
          }
        );
      });
    } else {
      const options = {
        filename: params.preview.name,
        margin: [10, 10, 10, 10], // top, right, bottom, left
        pagebreak: { mode: 'avoid-all' },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: 'mm',
          format: 'a4',
          orientation: 'portrait',
        },
      };

      html2pdf().from(params.preview.documentModel).set(options).save();
    }
  } catch (error) {
    console.error(error);
    store.dispatch(addAlert(alertBodyTypes.ERROR_GENERATING_DOCUMENT));
  } finally {
    store.dispatch(setModal(null));
  }
}
