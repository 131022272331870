/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-plusplus */
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import makeAnimated from 'react-select/animated';
import Select from 'react-select';
import Swal from 'sweetalert2';

import fileTypes from 'constants/fileTypes';
import documentTypes from 'constants/documentTypes';
import documentLinkTypes from 'constants/documentLinkTypes';
import { EMAIL_PATTERN, SELECCIONAR_SOCIO } from 'constants/defaultConstants';

import store from 'redux/store';
import { addDocument } from 'redux/actions/documentActions';
import { updateSociety } from 'redux/actions/societyActions';
import { setModal } from 'redux/actions/modalActions';

import statusTypes from 'components/SocietyDirectors/statusTypes';
import directorTypesOptions from 'components/SocietyDirectors/directorTypesOptions';
import statusTypesOptions from 'components/SocietyDirectors/statusTypesOptions';

import { getFullAddress } from 'utils/filters';

function AddSocietyDirector({
  societyId,
  action,
  directorData,
  directorId,
  userId,
}) {
  const { partners } = useSelector((state) => ({
    partners: state.society.actualSociety?.partners,
  }));
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [socialDenomination, setSocialDenomination] = useState('');
  const [directorType, setDirectorType] = useState('');
  const [nationality, setNationality] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [initDate, setInitDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [address, setAddress] = useState('');
  const [status, setStatus] = useState('');
  const [email, setEmail] = useState('');
  const [files, setFiles] = useState('');
  const [cif, setCif] = useState('');
  const [isPartnerAlready, setIsPartnerAlready] = useState(false);
  const animatedComponents = makeAnimated();
  const [partnerOptions, setPartnerOptions] = useState([]);

  const [currDirectorTypesOptions, setCurrDirectorTypesOptions] = useState(
    directorTypesOptions(i18n)
  );
  const [currStatusTypesOptions, setCurrStatusTypesOptions] = useState(
    statusTypesOptions(i18n)
  );
  const [currStatusTypes, setCurrStatusTypes] = useState(statusTypes(i18n));

  const handlePartnerOptionChange = (selectedOption) => {
    setAddress(getFullAddress(selectedOption.value?.address));
    setSocialDenomination(selectedOption.value?.name);
    setNationality(selectedOption.value?.nationality);
    setEmail(selectedOption.value?.email);
    setCif(selectedOption.value?.cif);
  };

  async function uploadDocuments(directorId) {
    if (files.length) {
      const docs = [];
      const date = initDate || new Date();
      for (const file of files) {
        const newFile = {
          file,
          size: file.size,
          date,
          fileType: fileTypes[file?.type],
          category: documentTypes.ASSIGNMENTS,
          name: file.name,
          author: userId,
          society: societyId,
          description: t('FileAssociatedDirector'),
          linkedTo: directorId
            ? [{ linkedId: directorId, linkedType: documentLinkTypes.DIRECTOR }]
            : [],
        };

        const uploadedDocument = await store.dispatch(addDocument(newFile));

        if (uploadedDocument['_id']) {
          docs.push(uploadedDocument['_id']);
        }
      }
      return docs;
    }
  }

  const handleChangeIsAlreadyPartner = () => {
    setIsPartnerAlready(!isPartnerAlready);
  };

  const handleUpdateDirector = async (event) => {
    event.preventDefault();

    const newDirector = {
      socialDenomination,
      cif,
      nationality,
      birthDate,
      address,
      email,
      directorType,
      initDate,
      endDate,
      directorStatus: status,
    };

    let newSocietyData;
    // Upload the documents.
    const documents = await uploadDocuments(directorId);

    if (action === 'ADD') {
      newDirector.documents = documents;
      newSocietyData = {
        directors: {
          add: {
            newDirector,
          },
        },
      };
    } else if (action === 'EDIT') {
      const oldDocuments = directorData.documents || [];
      newDirector.documents = documents
        ? [...oldDocuments, ...documents]
        : oldDocuments;
      newDirector['_id'] = directorId;
      newSocietyData = {
        directors: {
          edit: [
            {
              directorId,
              newDirector,
            },
          ],
        },
      };
    }

    // Update the society with the new director.
    dispatch(updateSociety(societyId, newSocietyData, false));
    dispatch(setModal(null));

    return Swal.fire({
      icon: 'success',
      title: `<h4 class="nk-modal-title">${
        action === 'ADD'
          ? t('MemberAddedSuccessfully')
          : t('MemberUpdatedSuccessfully')
      }<br></h4>`,
      confirmButtonText: t('OK'),
      allowOutsideClick: false,
      showCancelButton: false,
      confirmButtonColor: '#6576FF',
    });
  };

  const loadInitialValues = () => {
    setSocialDenomination(directorData.socialDenomination);
    setCif(directorData.cif);
    setNationality(directorData.nationality);
    if (directorData.birthDate)
      setBirthDate(format(new Date(directorData.birthDate), 'yyyy-MM-dd'));
    setAddress(directorData.address);
    setEmail(directorData.email);
    setDirectorType(directorData.directorType);
    if (directorData.initDate)
      setInitDate(format(new Date(directorData.initDate), 'yyyy-MM-dd'));
    if (directorData.endDate)
      setEndDate(format(new Date(directorData.endDate), 'yyyy-MM-dd'));
    if (directorData.directorStatus) setStatus(directorData.directorStatus);
  };

  const showFileNames = () => {
    let names = '';
    if (files?.length) {
      for (let i = 0; i < files.length; i++) names += `${files[i].name} `;
    }
    return names;
  };

  useEffect(() => {
    if (action === 'EDIT' && directorData) {
      loadInitialValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [directorData]);

  useEffect(() => {
    if (!directorData?.directorStatus) {
      if (!endDate || endDate === '' || new Date(endDate) - new Date() > 0)
        setStatus(currStatusTypes.var.ACTIVE);
      else setStatus(currStatusTypes.var.INACTIVE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDate]);

  useEffect(() => {
    if (partners) {
      const options = partners
        .filter((partner) => partner?.sharesCount?.actual > 0)
        .map((partner) => ({
          value: partner,
          label: `${partner?.cif} | ${partner?.name}`,
        }));
      setPartnerOptions(options);
    }
  }, [partners]);

  useEffect(() => {
    setCurrDirectorTypesOptions(directorTypesOptions(i18n));
    setCurrStatusTypesOptions(statusTypesOptions(i18n));
    setCurrStatusTypes(statusTypes(i18n));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title">
          {action === 'ADD' ? `${t('Add')} ` : `${t('Edit')} `}
          {t('Admin')}
        </h5>
        <a
          className="close cursor-pointer"
          onClick={() => dispatch(setModal(null))}
        >
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>

      <form className="form-validate is-alter" onSubmit={handleUpdateDirector}>
        <Modal.Body>
          <div className="row gy-4">
            {action === 'ADD' && (
              <div className="col-md-12">
                <div className="form-group">
                  <label className="form-label" htmlFor="full-name">
                    {t('IsItCurrentlyAPartner?')}
                  </label>

                  <div className="custom-control custom-control-sm custom-radio custom-control-pro ml-5">
                    <input
                      type="radio"
                      className="custom-control-input"
                      name="already-partner"
                      id="already-partner"
                      checked={isPartnerAlready}
                      onChange={handleChangeIsAlreadyPartner}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="already-partner"
                    >
                      {t('Yes')}
                    </label>
                  </div>

                  <div className="custom-control custom-control-sm custom-radio custom-control-pro ml-5">
                    <input
                      type="radio"
                      className="custom-control-input"
                      name="no-already-partner"
                      id="no-already-partner"
                      checked={!isPartnerAlready}
                      onChange={handleChangeIsAlreadyPartner}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="no-already-partner"
                    >
                      {t('Nope')}
                    </label>
                  </div>
                </div>
              </div>
            )}
            <div className="col-md-6">
              <div className="form-group">
                {isPartnerAlready ? (
                  <div className="form-control-wrap">
                    <label
                      className="form-label"
                      htmlFor="societyDirectors-socialDenom"
                    >
                      {t('Partner')} *
                    </label>
                    <Select
                      closeMenuOnSelect
                      className="react-select"
                      options={partnerOptions}
                      components={animatedComponents}
                      onChange={handlePartnerOptionChange}
                      placeholder={SELECCIONAR_SOCIO}
                    />
                  </div>
                ) : (
                  <div>
                    <label
                      className="form-label"
                      htmlFor="societyDirectors-socialDenom"
                    >
                      {t('NameAndSurnameSociety')}
                    </label>
                    <input
                      type="text"
                      id="societyDirectors-socialDenom"
                      className="form-control form-control-lg"
                      value={socialDenomination}
                      onChange={(event) =>
                        setSocialDenomination(event.target.value)
                      }
                      placeholder={t('SocialDenomination')}
                      required
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label" htmlFor="societyDirectors-email">
                  {t('Email')} *
                </label>
                <input
                  type="email"
                  className="form-control form-control-lg"
                  id="societyDirectors-email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  placeholder={t('Email')}
                  pattern={EMAIL_PATTERN}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label"
                  htmlFor="societyDirectors-initDate"
                >
                  {t('RegistrationDate')}
                </label>
                <input
                  type="date"
                  className="form-control form-control-lg date-picker"
                  id="societyDirectors-initDate"
                  value={initDate}
                  onChange={(event) => setInitDate(event.target.value)}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <div className="d-flex">
                  <label
                    className="form-label"
                    htmlFor="societyDirectors-endDate"
                  >
                    {t('EndDate')}
                  </label>
                  <span className="sub-text ml-1 fs-12px">
                    {t('IndefiniteDefault')}
                  </span>
                </div>
                <input
                  type="date"
                  className="form-control form-control-lg date-picker"
                  id="societyDirectors-endDate"
                  value={endDate}
                  onChange={(event) => setEndDate(event.target.value)}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label"
                  htmlFor="societyDirectors-directorType"
                >
                  {t('TypeOfCharge')} *
                </label>
                <div className="form-control-wrap ">
                  <div className="form-control-select">
                    <select
                      className="form-control"
                      id="societyDirectors-directorType"
                      value={directorType}
                      onChange={(event) => {
                        setDirectorType(event.target.value);
                      }}
                      required
                    >
                      <option value="" className="text-light">
                        {t('TypeOfCharge')}
                      </option>
                      {currDirectorTypesOptions?.length > 0 &&
                        currDirectorTypesOptions
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .map((type) => (
                            <option value={type.value} key={type.value}>
                              {type.name}
                            </option>
                          ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label" htmlFor="societyDirectors-status">
                  {t('Status')}
                </label>
                <div className="form-control-wrap ">
                  <div className="form-control-select">
                    <select
                      className="form-control"
                      id="societyDirectors-status"
                      value={status}
                      onChange={(event) => {
                        setStatus(event.target.value);
                      }}
                      required
                    >
                      {currStatusTypesOptions?.length > 0 &&
                        currStatusTypesOptions.map((type) => (
                          <option value={type.value} key={type.value}>
                            {type.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label" htmlFor="societyDirectors-cif">
                  {t('Cif')}
                </label>
                <input
                  type="text"
                  id="societyDirectors-cif"
                  className="form-control form-control-lg"
                  value={cif}
                  onChange={(event) => setCif(event.target.value)}
                  placeholder={t('Cif')}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label"
                  htmlFor="societyDirectors-nationality"
                >
                  {t('Nationality')}
                </label>
                <input
                  type="text"
                  id="societyDirectors-nationality"
                  className="form-control form-control-lg"
                  value={nationality}
                  onChange={(event) => setNationality(event.target.value)}
                  placeholder={t('Nationality')}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label"
                  htmlFor="societyDirectors-address"
                >
                  {t('Address')}
                </label>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  id="societyDirectors-address"
                  value={address}
                  onChange={(event) => setAddress(event.target.value)}
                  placeholder={t('Address')}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label
                  className="form-label"
                  htmlFor="societyDirectors-birthDate"
                >
                  {`${t('DateOfBirth')} / ${t('ConstitutionDate')}`}
                </label>
                <input
                  type="date"
                  className="form-control form-control-lg date-picker"
                  id="societyDirectors-birthDate"
                  value={birthDate}
                  onChange={(event) => setBirthDate(event.target.value)}
                />
              </div>
            </div>

            <div className="col-md-12">
              <div className="form-group">
                <label
                  className="form-label"
                  htmlFor="societyDirectors-documents"
                >
                  {t('AddDocuments')}
                </label>
                <div className="form-control-wrap">
                  <div className="custom-file">
                    <input
                      id="societyDirectors-documents"
                      type="file"
                      accept="application/pdf"
                      className="form-control form-control-lg custom-file-input"
                      onChange={(event) => setFiles(event.target.files)}
                      multiple
                    />
                    <label
                      className="custom-file-label"
                      htmlFor="societyDirectors-documents"
                    >
                      {showFileNames() || t('SelectDocument')}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="row">
            <div className="col-12">
              <button
                type="submit"
                className="btn btn-lg btn-primary"
                disabled={!socialDenomination || !directorType}
              >
                {t('Save')}
              </button>
              <button
                type="button"
                className="btn btn-lg btn-light ml-3"
                onClick={() => dispatch(setModal(null))}
              >
                {t('ToClose')}
              </button>
            </div>
          </div>
        </Modal.Footer>
      </form>
    </>
  );
}

export default AddSocietyDirector;
