import React, { FC, useEffect, useState } from 'react';
import store from 'redux/store';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { getDocument } from 'redux/actions/documentActions';

import UploadDocument from 'components/UploadDocument';

import Swal from 'sweetalert2';

import {
  Encumbrance,
  ScriptureData,
  Range,
  Share as OriginalShare,
} from 'types';

import { numberFormat } from 'constants/formats';

import removeRangeFromRanges from './utils/removeRangeFromRanges';
import addRangeToRanges from './utils/addRangeToRanges';
import EncumbrancesTable from './EncumbrancesTable';

type Share = Omit<OriginalShare, 'shareClass'> & {
  shareClass: string;
};

type Props = {
  share: Share;
  encumbrances: Encumbrance[];
  setEncumbrances: (value: Encumbrance[]) => void;
  setEncumbrancesUpdated: (value: boolean) => void;
};

const ShareEncumbranceView: FC<Props> = ({
  share,
  encumbrances,
  setEncumbrances,
  setEncumbrancesUpdated,
}) => {
  const { t } = useTranslation();

  const [affectedShares, setAffectedShares] = useState<number>(
    share?.to - share?.from
  );
  const [encumbranceName, setEncumbranceName] = useState<string>('');
  const [encumbranceDate, setEncumbranceDate] = useState<string>('');
  const [encumbranceFrom, setEncumbranceFrom] = useState<number>(share?.from);
  const [encumbranceTo, setEncumbranceTo] = useState<number>(share?.to);
  const [encumbranceScriptureData, setEncumbranceScriptureData] =
    useState<ScriptureData>({ notaryName: '', protocolNr: '', notaryCity: '' });
  const [encumbranceFile, setEncumbranceFile] = useState<File | undefined>();

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const [editingIndex, setEditingIndex] = useState<number>(-1);

  const [inputLabel, setInputLabel] = useState<string>(
    t('DragDocumentToUpload')
  );

  const [isValidRange, setIsValidRange] = useState<boolean>(true);

  const [validRanges, setValidRanges] = useState<Range[]>([
    { from: share?.from, to: share?.to },
  ]);

  const validRange = (from: number, to: number) => {
    const isValid = validRanges?.some(
      (range) => range?.from <= from && range?.to >= to && from < to
    );
    return isValid;
  };

  const setValidRangeOnInput = (ranges: Range[]) => {
    const range = ranges.find((range) => range?.to - range?.from > 0);
    if (range) {
      setEncumbranceFrom(range?.from);
      setEncumbranceTo(range?.to);
      setAffectedShares(range?.to - range?.from);
    }
    setIsValidRange(true);
  };

  const setInitialValidRanges = (encumbrances: Encumbrance[]) => {
    let ranges: Range[] = [{ from: share?.from, to: share?.to }];
    encumbrances.forEach((encumbrance) => {
      ranges = removeRangeFromRanges(
        encumbrance?.from,
        encumbrance?.to,
        ranges
      );
    });
    setValidRanges(ranges);
    setValidRangeOnInput(ranges);
  };

  const cleanForm = () => {
    setEncumbranceName('');
    setEncumbranceDate('');

    setEncumbranceScriptureData({
      notaryName: '',
      protocolNr: '',
      notaryCity: '',
    });
    setEncumbranceFile(undefined);
    setInputLabel(t('DragDocumentToUpload'));
  };

  const addEncumbrance = () => {
    setEncumbrances([
      ...encumbrances,
      {
        name: encumbranceName,
        from: encumbranceFrom,
        to: encumbranceTo,
        date: encumbranceDate,
        scriptureData: encumbranceScriptureData,
        scriptureDocument: '',
        file: encumbranceFile,
      },
    ]);
  };

  const editEncumbrance = async () => {
    const encumbrance = encumbrances[editingIndex];
    const newEncumbrances = [
      ...encumbrances?.slice(0, editingIndex),
      {
        ...encumbrance,
        name: encumbranceName,
        date: encumbranceDate,
        from: encumbranceFrom,
        to: encumbranceTo,
        scriptureData: encumbranceScriptureData,
        file: encumbranceFile,
      },
      ...encumbrances?.slice(editingIndex + 1),
    ];
    setEncumbrances(newEncumbrances);
    setIsEditing(false);
  };

  const handleAddEncumbrance = () => {
    if (isEditing) {
      editEncumbrance();
    } else {
      addEncumbrance();
    }

    const ranges = removeRangeFromRanges(
      encumbranceFrom,
      encumbranceTo,
      validRanges
    );
    setValidRanges(ranges);
    setValidRangeOnInput(ranges);

    setEncumbrancesUpdated(true);
    cleanForm();
  };

  const getScriptureDocument = async (documnetId: string) => {
    const document = await store.dispatch(getDocument(documnetId));
    return document;
  };

  const getValidRanges = (encumbrance: Encumbrance) => {
    let ranges: Range[] = [];
    if (validRanges?.length > 0) {
      ranges = addRangeToRanges(
        encumbrance?.from,
        encumbrance?.to,
        validRanges
      );
    } else {
      ranges = [{ from: encumbrance?.from, to: encumbrance?.to }];
    }
    return ranges;
  };

  const handleEditEncumbrance = async (index: number) => {
    const encumbrance = encumbrances[index];
    setEncumbranceName(encumbrance?.name);
    setEncumbranceDate(encumbrance?.date);
    setEncumbranceFrom(encumbrance?.from);
    setEncumbranceTo(encumbrance?.to);
    setAffectedShares(encumbrance?.to - encumbrance?.from);
    setEncumbranceScriptureData(encumbrance?.scriptureData);
    if (encumbrance.file) {
      setEncumbranceFile(encumbrance.file);
    }
    setIsEditing(true);
    setEditingIndex(index);

    const ranges = getValidRanges(encumbrance);
    setValidRanges(ranges);

    setIsValidRange(true);

    if (encumbrance?.scriptureDocument) {
      const document = await getScriptureDocument(
        encumbrance.scriptureDocument
      );
      setInputLabel(document?.name || t('DragDocumentToUpload'));
    } else {
      setInputLabel(t('DragDocumentToUpload'));
    }
  };

  const removeEncumbrance = (index: number) => {
    const encumbrance = encumbrances[index];

    const ranges = getValidRanges(encumbrance);
    setValidRanges(ranges);
    setValidRangeOnInput(ranges);

    const filteredEncumbrances = encumbrances.filter((_, i) => i !== index);
    setEncumbrances(filteredEncumbrances);

    setEncumbrancesUpdated(true);
  };

  const handleRemoveEncumbrance = (index: number) => {
    const encumbrance = encumbrances[index];
    Swal.fire({
      icon: 'warning',
      title: `<h4 class="nk-modal-title">${t(
        'AreYouSureAboutDelEncumbrance'
      )}</h4>`,
      html: `<h5 class="text-primary">${
        encumbrance?.name
      }</h5><br /><div class="caption-text">${t(
        'ThisProcedureCantUndo'
      )}</div>`,
      confirmButtonText: t('OkDelete'),
      confirmButtonColor: '#6576ff',
      allowOutsideClick: false,
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        removeEncumbrance(index);
      }
    });
  };

  const handleCancelEdit = () => {
    const encumbrance = encumbrances[editingIndex];
    const ranges = removeRangeFromRanges(
      encumbrance?.from,
      encumbrance?.to,
      validRanges
    );
    setValidRanges(ranges);
    setValidRangeOnInput(ranges);
    setIsEditing(false);
    cleanForm();
  };

  const handleClickRange = (range: Range) => {
    setEncumbranceFrom(range?.from);
    setEncumbranceTo(range?.to);
    setAffectedShares(range?.to - range?.from);
    setIsValidRange(true);
  };

  const handleChangeEncumbranceName = (event: any) => {
    setEncumbranceName(event.target.value);
  };

  const handleChangeEncumbranceDate = (event: any) => {
    setEncumbranceDate(event.target.value);
  };

  const handleChangeEncumbranceFrom = (event: any) => {
    const { value } = event.target;

    setEncumbranceFrom(+value);

    const isValid = validRange(+value, encumbranceTo);
    setIsValidRange(isValid);

    if (encumbranceTo) {
      setAffectedShares(encumbranceTo - value);
    }
  };

  const handleChangeEncumbranceTo = (event: any) => {
    const { value } = event.target;

    setEncumbranceTo(+value);

    const isValid = validRange(encumbranceFrom, +value);
    setIsValidRange(isValid);

    if (encumbranceFrom) {
      setAffectedShares(value - encumbranceFrom);
    }
  };

  const handleChangeNotaryName = (event: any) => {
    setEncumbranceScriptureData({
      ...encumbranceScriptureData,
      notaryName: event.target.value,
    });
  };

  const handleChangeProtocolNr = (event: any) => {
    setEncumbranceScriptureData({
      ...encumbranceScriptureData,
      protocolNr: event.target.value,
    });
  };

  const handleChangeNotaryCity = (event: any) => {
    setEncumbranceScriptureData({
      ...encumbranceScriptureData,
      notaryCity: event.target.value,
    });
  };

  useEffect(() => {
    if (share?.encumbrances?.length > 0) {
      setInitialValidRanges(share?.encumbrances);
    }
  }, [share]);

  return (
    <>
      <div className="row">
        <div className="col-6">
          <div className="form-group">
            <label className="form-label" htmlFor="encumbrance-name">
              {t('EncumbranceName')}
              {!encumbranceName && <span className="text-danger"> *</span>}
            </label>
            <div className="form-control-wrap">
              <input
                type="text"
                id="encumbrance-name"
                placeholder={t('Name')}
                className="form-control form-control-md"
                value={encumbranceName}
                onChange={handleChangeEncumbranceName}
              />
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <label className="form-label" htmlFor="encumbrance-date">
              {t('EncumbranceDate')}
              {!encumbranceDate && <span className="text-danger"> *</span>}
            </label>
            <div className="form-control-wrap">
              <input
                type="date"
                id="encumbrance-date"
                className="form-control form-control-md"
                value={encumbranceDate}
                onChange={handleChangeEncumbranceDate}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <div className="form-group">
            <label className="form-label" htmlFor="encumbrance-from">
              {t('From')}
            </label>
            <div className="form-control-wrap">
              <input
                type="number"
                id="encumbrance-from"
                min={share?.from}
                className="form-control form-control-md"
                value={encumbranceFrom}
                onChange={handleChangeEncumbranceFrom}
              />
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <label className="form-label" htmlFor="encumbrance-to">
              {t('To')}
            </label>
            <div className="form-control-wrap">
              <input
                type="number"
                max={share?.to}
                id="encumbrance-to"
                className="form-control form-control-md"
                value={encumbranceTo}
                onChange={handleChangeEncumbranceTo}
              />
            </div>
          </div>
        </div>
      </div>
      {!isValidRange ? (
        <span className="sub-text text-danger">
          <i>{t('InvalidRange')}</i>
        </span>
      ) : (
        <></>
      )}
      <div className="row">
        <div className="col-12 d-flex">
          <div className="form-label mb-0">{t('Available')}:</div> &nbsp;
          {validRanges?.length > 0 ? (
            validRanges
              .filter((range) => range?.to > range?.from)
              .map((range) => (
                <>
                  <b
                    className="badge btn-primary ml-1 cursor-pointer align-items-center"
                    onClick={() => handleClickRange(range)}
                  >
                    <NumberFormat
                      displayType="text"
                      value={range?.from}
                      thousandSeparator={numberFormat.thousandSeparator}
                      decimalSeparator={numberFormat.decimalSeparator}
                    />
                    &nbsp;-&nbsp;
                    <NumberFormat
                      displayType="text"
                      value={range?.to}
                      thousandSeparator={numberFormat.thousandSeparator}
                      decimalSeparator={numberFormat.decimalSeparator}
                    />
                    &nbsp;
                  </b>
                </>
              ))
          ) : (
            <span className="sub-text text-danger">
              <i>{t('NoRangesAvailable')}</i>
            </span>
          )}
        </div>
      </div>
      {validRanges?.length > 0 && (
        <span className="sub-text">
          <i>{t('AvailableSharesInfo')}</i>
        </span>
      )}
      <div className="row">
        <div className="col-6">
          <div className="form-group">
            <label className="form-label" htmlFor="encumbrance-affected-shares">
              {t('AffectedShares')}
            </label>
            <div className="form-control-wrap">
              <input
                type="text"
                id="encumbrance-affected-shares"
                className="form-control form-control-md"
                value={affectedShares}
                readOnly
              />
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <label className="form-label" htmlFor="encumbrance-class">
              {t('EncumbranceClass')}
            </label>
            <div className="form-control-wrap">
              <input
                type="text"
                id="encumbrance-calss"
                className="form-control form-control-md"
                value={share?.shareClass || ''}
                readOnly
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-4">
          <div className="form-group">
            <label className="form-label" htmlFor="notary-name">
              {t('NotaryName')}
            </label>
            <div className="form-control-wrap">
              <input
                type="text"
                id="notary-name"
                className="form-control form-control-md"
                placeholder="Mr. John Smith"
                value={encumbranceScriptureData?.notaryName}
                onChange={handleChangeNotaryName}
              />
            </div>
          </div>
        </div>
        <div className="col-4">
          <div className="form-group">
            <label className="form-label" htmlFor="notary-protocol">
              {t('NotaryProtocolNumber')}
            </label>
            <div className="form-control-wrap">
              <input
                type="text"
                id="notary-protocol"
                className="form-control form-control-md"
                placeholder="P-8234290A"
                value={encumbranceScriptureData?.protocolNr}
                onChange={handleChangeProtocolNr}
              />
            </div>
          </div>
        </div>
        <div className="col-4">
          <div className="form-group">
            <label className="form-label" htmlFor="notary-city">
              {t('NotaryCity')}
            </label>
            <div className="form-control-wrap">
              <input
                type="text"
                id="notary-city"
                className="form-control form-control-md"
                placeholder="Barcelona"
                value={encumbranceScriptureData?.notaryCity}
                onChange={handleChangeNotaryCity}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="pb-0">
        <UploadDocument
          label={t('Scripture')}
          inputLabel={inputLabel}
          file={encumbranceFile}
          setFile={setEncumbranceFile}
        />
      </div>
      <div className="form-group d-flex justify-content-between">
        <div>
          {' '}
          <button
            type="button"
            className="btn btn-outline-primary btn-dim border-radius-0"
            disabled={
              !encumbranceName ||
              !encumbranceDate ||
              !isValidRange ||
              validRanges?.length === 0
            }
            onClick={handleAddEncumbrance}
          >
            {isEditing ? t('EditEncumbrance') : t('AddEncumbrance')}
          </button>
        </div>
        {isEditing && (
          <div className="ml-2">
            <button
              type="button"
              className="btn btn-outline-danger btn-dim border-radius-0 "
              onClick={handleCancelEdit}
            >
              {t('Cancel')}
            </button>
          </div>
        )}
      </div>

      {encumbrances?.length > 0 && (
        <EncumbrancesTable
          encumbrances={encumbrances}
          isEditing={isEditing}
          handleEditEncumbrance={handleEditEncumbrance}
          handleRemoveEncumbrance={handleRemoveEncumbrance}
        />
      )}
    </>
  );
};

export default ShareEncumbranceView;
