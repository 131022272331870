/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { formatCurrency, formatNumber } from 'utils/formats';

import '../Modals.scss';

function BeneficiariesReview({ beneficiaries, onClose }) {
  const { t } = useTranslation();

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <Modal.Header className="border-0 pb-0 d-flex justify-content-between">
        <h5 className="modal-title">
          {`${beneficiaries?.length || 0} ${t('beneficiaries')}`}
        </h5>

        <a className="close cursor-pointer" onClick={handleClose}>
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
      <Modal.Body>
        <div className="nk-block">
          <div className="card">
            <table className="table table-tranx">
              <thead>
                <tr className="tb-tnx-head">
                  <th className="nk-tb-col p-3">
                    <span>Nº</span>
                  </th>
                  <th className="nk-tb-col p-3">
                    <span>{t('Name')}</span>
                  </th>
                  <th className="nk-tb-col p-3">
                    <span>{t('E-Mail')}</span>
                  </th>
                  <th className="nk-tb-col text-right p-3">
                    <span>{t('Salary')}</span>
                  </th>
                  <th className="nk-tb-col text-right p-3">
                    <span>{t('Units')}</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {beneficiaries?.map((beneficiary, index) => (
                  <tr className="tb-tnx-item" key={beneficiary?.['_id']}>
                    <td className="tb-tnx-id">
                      <span>{index + 1}</span>
                    </td>
                    <td className="tb-tnx-info">
                      <span className="tb-tnx-info">
                        {beneficiary?.['NOMBRE Y APELLIDOS'] || '-'}
                      </span>
                    </td>
                    <td className="tb-tnx-info">
                      <span className="tb-tnx-info">
                        {beneficiary?.['EMAIL'] || '-'}
                      </span>
                    </td>
                    <td className="tb-tnx-info text-right">
                      <span className="tb-tnx-info text-right">
                        {formatCurrency(beneficiary?.['SUELDO'])}
                      </span>
                    </td>
                    <td className="tb-tnx-info text-right">
                      <span className="tb-tnx-info text-right">
                        {formatNumber(beneficiary?.['UNIDADES'])}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Modal.Body>
    </>
  );
}

export default BeneficiariesReview;
