/* eslint-disable prefer-promise-reject-errors */
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Editor } from '@tinymce/tinymce-react';

import { promiseUploadImage } from 'redux/actions/imageActions';

import tags from './tags';
import './EditorWYSIWYG.scss';

const EditorWYSIWYG = ({
  menubar = true,
  values,
  editorContent,
  setEditorContent,
  setEditorReference,
}) => {
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const editorRef = useRef(null);

  const [editorLanguage, setEditorLanguage] = useState(
    i18n.language === 'es' ? 'es' : 'en_US'
  );

  const handleUploadImage = (blobInfo) => {
    const uploadFile = blobInfo.blob();
    return promiseUploadImage(uploadFile);
  };

  function createMergetagsToolbarToggleButton(
    editor,
    buttonName,
    labelText,
    buttonIcon
  ) {
    const text =
      editorLanguage === 'es' ? 'Insertar merge tag' : 'Insert merge tag';
    editor.ui.registry.addButton(buttonName, {
      text: labelText,
      icon: buttonIcon,
      onAction: () => {
        const mergetagsToolbar = editor.editorContainer.querySelector(
          `[aria-label="${text}"]`
        );
        if (mergetagsToolbar) {
          mergetagsToolbar.click();
        }
      },
    });
  }

  useEffect(() => {
    setEditorLanguage(i18n.language === 'es' ? 'es' : 'en_US');
  }, [i18n.language]);

  useEffect(() => {
    if (editorContent) {
      editorRef?.current?.setContent(editorContent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Editor
      apiKey={process.env.REACT_APP_TINY_EDITOR_KEY}
      value={editorContent}
      onInit={async (_evt, editor) => {
        editorRef.current = editor;
        if (setEditorReference) {
          setEditorReference(editor);
        }
      }}
      onEditorChange={(content) => {
        setEditorContent(content);
      }}
      init={{
        width: 940,
        height: 842,
        menubar,
        selector: 'textarea',

        plugins: [
          'advcode',
          'advtable',
          'anchor',
          'autolink',
          'autosave',
          'casechange',
          'charmap',
          'checklist',
          'code',
          'codesample',
          'directionality',
          'editimage',
          'export',
          'formatpainter',
          'fullscreen',
          'help',
          'image',
          'importcss',
          'inlinecss',
          'insertdatetime',
          'link',
          'lists',
          'media',
          'mergetags',
          'nonbreaking',
          'pagebreak',
          'pageembed',
          'permanentpen',
          'powerpaste',
          'preview',
          'save',
          'searchreplace',
          'table',
          'tableofcontents',
          'template',
          'visualblocks',
          'visualchars',
          'wordcount',
        ],
        toolbar:
          'variables mergetags undo redo print formatpainter fontfamily fontsize | bold italic underline forecolor | link image |' +
          'alignleft aligncenter ' +
          'alignright alignjustify | bullist numlist outdent indent',
        toolbar_mode: 'sliding',
        images_upload_handler: handleUploadImage,

        setup: (editor) => {
          if (values) {
            createMergetagsToolbarToggleButton(
              editor,
              'variables',
              t('AddProperty'),
              'addtag'
            );
          }
        },
        font_family_formats:
          'Arial=arial,helvetica,sans-serif; Book Antiqua=book antiqua,palatino; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Symbol=symbol; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva;',
        fontsize_formats: '8pt 10pt 12pt 14pt 18pt 24pt 36pt',
        content_style:
          'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
        mergetags_prefix: '{{',
        mergetags_suffix: '}}',
        mergetags_list: tags({ ...values }),
        language: editorLanguage,
      }}
    />
  );
};

export default EditorWYSIWYG;
