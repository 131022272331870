import alertBodyTypes from 'components/Alert/alertBodyTypes';
import { addAlert } from 'redux/actions/alertActions';
import store from 'redux/store';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import SignaturePad from 'signature_pad';

import getUserMetadata from 'utils/userMetadata';
import './SignatureBox.scss';

const SignatureBox = ({
  signature,
  setSignature,
  isDelegation = false,
  setHasFinishedSignature,
}) => {
  const { t } = useTranslation();
  const [signaturePad, setSignaturePad] = useState(null);
  const [userMetadata, setUserMetadata] = useState(null);

  const getMetadata = async () => {
    const data = await getUserMetadata();
    setUserMetadata(data);
  };

  useEffect(() => {
    setSignaturePad(
      new SignaturePad(document.getElementById('signature-pad'), {
        backgroundColor: 'rgb(255, 255, 255)',
        penColor: 'rgb(0, 0, 0)',
      })
    );
    getMetadata();
  }, []);

  return (
    <>
      <div
        className="signature-wrapper"
        style={{
          maxWidth: '500px',
          width: '100%',
          margin: '0 auto',
          position: 'relative',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <canvas
            id="signature-pad"
            className="signature-pad"
            width="500"
            height="200"
          />
          <div className="signature-buttons-area">
            <button
              type="button"
              className="btn btn-primary"
              disabled={!!signature}
              onClick={() => {
                const data = signaturePad?.toDataURL('image/png');
                if (isDelegation) {
                  setHasFinishedSignature(true);
                } else {
                  store.dispatch(addAlert(alertBodyTypes.SIGNATURE_SAVED));
                }
                setSignature(data);
              }}
            >
              {t('Sign')}
            </button>

            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                signaturePad?.clear();
                setSignature(null);
              }}
            >
              {t('Clear')}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignatureBox;
