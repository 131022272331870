import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import store from 'redux/store';
import { setModal } from 'redux/actions/modalActions';
import {
  getBeneficiaryData,
  updateBeneficiary,
} from 'redux/actions/beneficiaryActions';

import { getActualSociety } from 'utils/filters';

import { Beneficiary } from 'types';
import Swal from 'sweetalert2';

import PersonalDataView from './PersonalDataView';
import AddressDataView from './AddressDataView';
import OtherDataView from './OtherDataView';

type Props = {
  beneficiary: Beneficiary;
  societyId: String;
  setBeneficiarySocietyData: any;
};

const EditBeneficiaryModal: FC<Props> = ({
  beneficiary,
  societyId,
  setBeneficiarySocietyData,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const user: any = useSelector((state: any) => state.user);

  const hasTaxAddress = !!(
    beneficiary?.taxAddress?.line1 ||
    beneficiary?.taxAddress?.city ||
    beneficiary?.taxAddress?.zip ||
    beneficiary?.taxAddress?.country
  );

  const [updatedBeneficiary, setUpdatedBeneficiary] = useState<any>({
    name: beneficiary?.name,
    cif: beneficiary?.cif,
    email: beneficiary?.email,
    emails: beneficiary?.emails || [],
    nationality: beneficiary?.nationality,
    birthdate: beneficiary?.birthdate,
    jobTitle: beneficiary?.jobTitle,
    employeeNumber: beneficiary?.employeeNumber,
    phone: beneficiary?.phone,
    image: beneficiary?.image,
    address: beneficiary?.address,
    bankAccountNumber: beneficiary?.bankAccountNumber,
    taxAddress: hasTaxAddress
      ? beneficiary?.taxAddress
      : {
          line1: '',
          city: '',
          zip: '',
          country: '',
        },
  });
  const [page, setPage] = useState<number>(0);

  const isValidEmail = (email: string) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const validEmails = () => {
    const emails = [updatedBeneficiary.email, ...updatedBeneficiary.emails];
    return emails.every((email) => email !== '' && isValidEmail(email));
  };

  const handleUpdateBeneficiary = async () => {
    const newEmails = updatedBeneficiary.emails;

    await store.dispatch(
      updateBeneficiary({
        id: beneficiary['_id'],
        data: {
          ...updatedBeneficiary,
          newEmails,
          userId: user['_id'],
          societyId,
          cascade: true,
        },
        showAlert: false,
      })
    );

    if (setBeneficiarySocietyData) {
      getBeneficiaryData(
        {
          beneficiaryId: beneficiary['_id'],
          societyId,
          userId: user?.['_id'],
          source: 'society',
        },
        setBeneficiarySocietyData
      );
    }
    getActualSociety(user, societyId);
    Swal.fire({
      icon: 'success',
      title: `<h4 class="nk-modal-title">${t(
        'BeneficiaryUpdatedSuccesfully'
      )}<br></h4>`,
      confirmButtonText: t('OK'),
      allowOutsideClick: false,
      showCancelButton: false,
      confirmButtonColor: '#6576FF',
    });

    dispatch(setModal(null));
  };

  return (
    <>
      <Modal.Header>
        <div className="row">
          <h5 className="title col-4">
            {t('UpdateData')}&nbsp;
            <span className="text-muted ff-alt fs-14px text-right">
              ({t('Beneficiary')})
            </span>
          </h5>
        </div>
        <a
          className="close cursor-pointer"
          onClick={() => dispatch(setModal(null))}
        >
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
      <Modal.Body style={{ minHeight: '720px', overflowY: 'auto' }}>
        <ul className="nk-nav nav nav-tabs mt-xl">
          <li className="nav-item" onClick={() => setPage(0)}>
            <p className={`nav-link ${page === 0 && 'active'}`}>
              {t('PersonalInformation')}
            </p>
          </li>
          <li className="nav-item" onClick={() => setPage(1)}>
            <p className={`nav-link ${page === 1 && 'active'}`}>
              {t('Address')}
            </p>
          </li>
          <li className="nav-item" onClick={() => setPage(2)}>
            <p className={`nav-link ${page === 4 && 'active'}`}>
              {t('OtherInformation')}
            </p>
          </li>
        </ul>
        <div className="modal-body modal-body-lg">
          <div className="nk-modal">
            {page === 0 && (
              <PersonalDataView
                beneficiary={updatedBeneficiary}
                setUpdatedBeneficiary={setUpdatedBeneficiary}
              />
            )}
            {page === 1 && (
              <AddressDataView
                beneficiary={updatedBeneficiary}
                setUpdatedBeneficiary={setUpdatedBeneficiary}
              />
            )}
            {page === 2 && (
              <OtherDataView
                beneficiary={updatedBeneficiary}
                setUpdatedBeneficiary={setUpdatedBeneficiary}
              />
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-lg btn-primary "
          onClick={handleUpdateBeneficiary}
          disabled={!validEmails()}
        >
          {t('SaveChanges')}
        </button>
      </Modal.Footer>
    </>
  );
};

export default EditBeneficiaryModal;
