import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setModal } from 'redux/actions/modalActions';
import { updateSociety } from 'redux/actions/societyActions';
import store from 'redux/store';

function MyFoldersModal({ societyId }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const actualSociety = useSelector((state) => state.society?.actualSociety);
  const [folderName, setFolderName] = useState('');

  async function saveFolder(event) {
    event.preventDefault();
    const newFolder = {
      label: folderName,
    };
    const newFolderData = actualSociety?.myFolders
      ? [...actualSociety.myFolders, newFolder]
      : [newFolder];
    const newSocietyData = {
      myFolders: newFolderData,
    };

    dispatch(updateSociety(societyId, newSocietyData));

    dispatch(setModal(null));
  }

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title">{t('CreateFolder')}</h5>
        <a className="close" onClick={() => store.dispatch(setModal(null))}>
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
      <Modal.Body>
        <form className="form-validate is-alter" onSubmit={saveFolder}>
          <div className="form-group">
            <label className="form-label" htmlFor="full-name">
              {t('Name')}
            </label>
            <div className="form-control-wrap">
              <input
                type="text"
                className="form-control"
                value={folderName}
                onChange={(event) => setFolderName(event.target.value)}
              />
            </div>
          </div>

          <button
            type="submit"
            className="btn btn-lg btn-primary"
            disabled={!folderName}
          >
            {t('Save')}
          </button>
        </form>
      </Modal.Body>
    </>
  );
}

export default connect()(MyFoldersModal);
