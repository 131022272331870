/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/style-prop-object */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { setMenu } from 'redux/actions/menuActions';
import { getStockPlans } from 'redux/actions/planActions';

import {
  getActualSociety,
  getPartnerFDPercent,
  getPartnerNDPercent,
  getPartnerPotentialFD,
  getPartnerPremium,
  getUsedSharesFromPlan,
} from 'utils/filters';

import { Tooltip } from 'react-tooltip';
import './PartnerBook.scss';
import getUnitsAndPercents from './getUnitsAndPercents';

import DrawCell from './DrawCell';
import DrawInversionHeaders from './InversionViewComponents/DrawInversionHeaders';
import DrawInversionColumns from './InversionViewComponents/DrawInversionColumns';
import DrawEmptyColumns from './DrawEmptyColumns';
import DrawInversionTotals from './InversionViewComponents/DrawInversionTotals';

import getColumnClass from './utils/getColumnClass';

function ClassesTable({
  view,
  updatedPartners,
  draftView,
  text = false,
  inversionView = false,
}) {
  const { t } = useTranslation();

  const aditionalRowHeaders = [
    {
      text: 'Warrants/Stock Option',
      style1: 'text-left w-200px fw-bold bg-light ',
      style2: 'text-left w-200px fw-bold',
    },
    {
      text: ` • ${t('Allocated')}`,
      style1: 'text-left w-200px bg-light',
      style2: 'text-left w-200px',
    },
    {
      text: ` • ${t('Unallocated')}`,
      style1: 'text-left w-200px bg-light',
      style2: 'text-left w-200px',
    },
    {
      text: 'ESOP/Phantom Share',
      style1: 'text-left w-200px fw-bold bg-light ',
      style2: 'text-left w-200px fw-bold',
    },
    {
      text: ` • ${t('Allocated')}`,
      style1: 'text-left w-200px bg-light',
      style2: 'text-left w-200px',
    },
    {
      text: ` • ${t('Unallocated')}`,
      style1: 'text-left w-200px bg-light',
      style2: 'text-left w-200px',
    },
    {
      text: t('ConvertibleNotes'),
      style1: 'text-left w-200px fw-bold bg-light ',
      style2: 'text-left w-200px fw-bold',
    },
    {
      text: ` • ${t('InForce')}`,
      style1: 'text-left w-200px bg-light',
      style2: 'text-left w-200px',
    },
    {
      text: ` • ${'Draft'}`,
      style1: 'text-left w-200px bg-light',
      style2: 'text-left w-200px',
    },
  ];

  const totalsRowHeaders = [
    {
      text: 'TOTAL',
      style1: 'text-left font-weight-bold bg-light',
      style2: 'text-left font-weight-bold',
    },
  ];

  const totalSharesColumnHeader = {
    text: t('TotalShares'),
    style1: 'text-left w-200px bg-light',
    style2: 'text-left w-200px',
    columns: 1,
  };

  const aditionalColumnsHeaders = [
    {
      text: '%ND',
      style1: 'text-left w-200px bg-light',
      style2: 'text-left w-200px',
      columns: 2,
    },
    {
      text: '%FD',
      style1: 'text-left w-200px bg-light',
      style2: 'text-left w-200px',
      columns: 2,
    },
    {
      text: '% Potential FD',
      style1: 'text-left w-200px bg-light',
      style2: 'text-left w-200px',
      columns: 2,
    },
  ];

  const inversionColumnsHeaders = 3;

  const dispatch = useDispatch();
  const { societyId } = useParams();

  const user = useSelector((state) => state?.user);
  const actualSociety = useSelector((state) => state?.society?.actualSociety);
  const plans = useSelector((state) => state?.plans);
  const holdingClasses = useSelector((state) => state?.holdingClasses);
  const partners = useSelector((state) =>
    state?.society?.actualSociety?.partners.filter(
      (partner) => partner?.sharesCount?.actual > 0
    )
  );
  const allPartners = useSelector(
    (state) => state?.society?.actualSociety?.partners
  );
  const shares = useSelector((state) => state?.society?.actualSociety?.shares);
  const tenderOffers = useSelector((state) => state?.tenderOffers);
  const investors = useSelector(
    (state) => state?.society?.actualSociety?.investors
  );

  const [plansTotalShares, setPlansTotalShares] = useState({
    assignedShares: 0,
    sharesTotal: 0,
  });

  const [plansTypeShares, setPlansTypeShares] = useState({
    stockAssignedShares: 0,
    stockUnassignedShares: 0,
    stockTotalShares: 0,
    phantomTotalShares: 0,
    phantomUnassignedShares: 0,
    phantomAssignedShares: 0,
  });

  const [tableRows, setTableRows] = useState([]);
  const [currentTableRows, setCurrentTableRows] = useState([]);
  const [partnersShares, setPartnersShares] = useState([]);
  const [classesShares, setClassesShares] = useState([]);
  const [totalCN, setTotalCN] = useState(0);
  const [newCN, setNewCN] = useState(0);
  const [newShares, setNewShares] = useState(0);
  const [currentPartners, setCurrentPartners] = useState(partners);
  const societyShares =
    actualSociety.sharesCount?.actual +
    (actualSociety.sharesCount?.future || 0) +
    (actualSociety.sharesCount?.drafts || 0);

  const societySharesActual = actualSociety.sharesCount?.actual;

  const generateAlternativeId = () =>
    uuidv4().replace(/-/g, '').substring(0, 24);

  const {
    stockAssignedShares,
    stockUnassignedShares,
    phantomAssignedShares,
    phantomUnassignedShares,
    inForceCNShares,
    currentCNShares,
    currentCNPercent,
    totalShares,
    totalNDShares,
    totalNDPercent,
    totalFDShares,
    totalFDPercent,
    totalPFDShares,
    totalPFDPercent,
  } = getUnitsAndPercents({
    plansTypeShares,
    societyShares,
    newShares,
    newCN,
    totalCN,
    societySharesActual,
    plansTotalShares,
    draftView,
  });

  const aditionalRows = [
    {
      text: 'Warrants/Stock Option',
      column1Value: '', // 1 Total Shares
      column2Value: '', // 2 %ND - units
      column3Value: '', // 3 %ND - %
      column4Value: '', // 4 %FD - units
      column5Value: '', // 5 %FD - %
      column6Value: '', // 6 %P.FD - units
      column7Value: '', // 7 %P.FD - %
    },
    {
      text: ` • ${t('Allocated')}`,
      column1Value: '',
      column2Value: '',
      column3Value: '',
      column4Value: stockAssignedShares,
      column5Value: ((stockAssignedShares * 100) / totalFDShares).toFixed(2),
      column6Value: stockAssignedShares,
      column7Value: ((stockAssignedShares * 100) / totalPFDShares).toFixed(2),
    },
    {
      text: ` • ${t('Unallocated')}`,
      column1Value: '',
      column2Value: '',
      column3Value: '',
      column4Value: '',
      column5Value: '',
      column6Value: stockUnassignedShares,
      column7Value: ((stockUnassignedShares * 100) / totalPFDShares).toFixed(2),
    },
    {
      text: 'ESOP/Phantom Share',
      column1Value: '',
      column2Value: '',
      column3Value: '',
      column4Value: '',
      column5Value: '',
      column6Value: '',
      column7Value: '',
    },
    {
      text: ` • ${t('Allocated')}`,
      column1Value: '',
      column2Value: '',
      column3Value: '',
      column4Value: phantomAssignedShares,
      column5Value: ((phantomAssignedShares * 100) / totalFDShares).toFixed(2),
      column6Value: phantomAssignedShares,
      column7Value: ((phantomAssignedShares * 100) / totalPFDShares).toFixed(2),
    },
    {
      text: ` • ${t('Unallocated')}`,
      column1Value: '',
      column2Value: '',
      column3Value: '',
      column4Value: '',
      column5Value: '',
      column6Value: phantomUnassignedShares,
      column7Value: ((phantomUnassignedShares * 100) / totalPFDShares).toFixed(
        2
      ),
    },
    {
      text: t('ConvertibleNotes'),
      column1Value: '',
      column2Value: '',
      column3Value: '',
    },
    {
      text: ` • ${t('InForce')}`,
      column1Value: '',
      column2Value: '',
      column3Value: '',
      column4Value: inForceCNShares,
      column5Value: ((inForceCNShares * 100) / totalFDShares).toFixed(2),
      column6Value: inForceCNShares,
      column7Value: ((inForceCNShares * 100) / totalPFDShares).toFixed(2),
    },
    {
      text: ` • ${t('Draft')}`,
      column1Value: '',
      column2Value: '',
      column3Value: '',
      column4Value: currentCNShares,
      column5Value: draftView
        ? ((currentCNShares * 100) / totalFDShares).toFixed(2)
        : currentCNPercent.toFixed(2),
      column6Value: currentCNShares,
      column7Value: draftView
        ? ((currentCNShares * 100) / totalPFDShares).toFixed(2)
        : currentCNPercent.toFixed(2),
    },
  ];

  const totalsRows = [
    {
      text: 'TOTAL',
      column1Value: totalShares,
      column2Value: totalNDShares,
      column3Value: totalNDPercent,
      column4Value: totalFDShares,
      column5Value: totalFDPercent,
      column6Value: totalPFDShares,
      column7Value: totalPFDPercent,
    },
  ];

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId) getActualSociety(user, societyId);
  }, [actualSociety, user, societyId]);

  useEffect(
    () =>
      dispatch(
        setMenu({
          type: null,
          societyId: actualSociety?.['_id'] || null,
          societyName: actualSociety?.name,
        })
      ),
    [actualSociety, dispatch]
  );

  useEffect(() => {
    if (shares) {
      const rows = shares.reduce((acc, cur) => {
        if (cur.isActive) {
          const key = `${cur.partner}-${cur.shareClass?.['_id']}`;
          if (key in acc) {
            acc[key] += cur.to - cur.from + 1;
          } else {
            acc[key] = cur.to - cur.from + 1;
          }
        }
        return acc;
      }, {});

      setTableRows(rows);
      setCurrentTableRows(rows);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shares]);

  useEffect(() => {
    if (currentTableRows) {
      const { totalSharesPerPartner, totalSharesPerClass } = Object.entries(
        currentTableRows
      ).reduce(
        (acc, [key, value]) => {
          const [partnerId, shareClassId] = key.split('-');

          if (!acc.totalSharesPerPartner[partnerId]) {
            acc.totalSharesPerPartner[partnerId] = value;
          } else {
            acc.totalSharesPerPartner[partnerId] += value;
          }
          if (!acc.totalSharesPerClass[shareClassId]) {
            acc.totalSharesPerClass[shareClassId] = value;
          } else {
            acc.totalSharesPerClass[shareClassId] += value;
          }

          return acc;
        },
        { totalSharesPerPartner: {}, totalSharesPerClass: {} }
      );

      setPartnersShares(totalSharesPerPartner);
      setClassesShares(totalSharesPerClass);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTableRows]);

  useEffect(() => {
    if (plans.length && actualSociety) {
      const result = plans
        .filter((plan) => plan.society === societyId && !plan.isDraft)
        .reduce(
          (acc, plan) => {
            const assigned =
              getUsedSharesFromPlan(plan, actualSociety, tenderOffers) || 0;
            const total = plan?.sharesTotal || 0;
            const unassigned = total - assigned || 0;

            if (
              plan.planType === 'STOCK_OPTION' ||
              plan.planType === 'WARRANTS'
            ) {
              acc.stockTotalShares += total;
              acc.stockAssignedShares += assigned;
              acc.stockUnassignedShares += unassigned;
            }

            if (plan.planType === 'PHANTOM_SHARE') {
              acc.phantomTotalShares += total;
              acc.phantomAssignedShares += assigned;
              acc.phantomUnassignedShares += unassigned;
            }
            return acc;
          },
          {
            stockAssignedShares: 0,
            stockUnassignedShares: 0,
            stockTotalShares: 0,
            phantomTotalShares: 0,
            phantomUnassignedShares: 0,
            phantomAssignedShares: 0,
          }
        );
      setPlansTypeShares(result);
      setPlansTotalShares(
        plans
          .filter((plan) => !plan.isDraft && plan.society === societyId)
          .reduce(
            (acc, plan) => ({
              assignedShares:
                acc.assignedShares +
                getUsedSharesFromPlan(plan, actualSociety, tenderOffers),
              sharesTotal: acc.sharesTotal + plan?.sharesTotal || 0,
            }),
            { assignedShares: 0, sharesTotal: 0 }
          )
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plans, actualSociety, tenderOffers]);

  useEffect(() => {
    if (societyId && !plans?.length) {
      dispatch(getStockPlans(societyId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [societyId]);

  useEffect(() => {
    if (investors.length) {
      const sum = investors.reduce(
        (acc, investor) => acc + investor?.sharesCount?.drafts || 0,
        0
      );
      setTotalCN(sum);
    } else {
      setTotalCN(0);
    }
  }, [investors]);

  useEffect(() => {
    if (updatedPartners?.length && tableRows) {
      const newRows = { ...tableRows };
      let newPartners = [...partners];
      let newSharesTotal = 0;
      const addedPartners = {};
      updatedPartners.forEach((partner) => {
        newSharesTotal += partner.shares.new;

        const currentPartnerId =
          partner.societyPartnerId || generateAlternativeId();

        if (partner?.holdingClass) {
          const key = `${currentPartnerId}-${partner.holdingClass}`;
          newRows[key] = newRows[key]
            ? newRows[key] + partner.shares.new
            : partner.shares.new;
        }
        if (!partner?.societyPartnerId) {
          if (!addedPartners[currentPartnerId]) {
            addedPartners[currentPartnerId] = true;
            newPartners.push({
              _id: currentPartnerId,
              name: partner.name,
              sharesCount: { actual: partner.shares.new },
              ndPercent: partner.NDTotal,
              fdPercent: partner.FDTotal,
              pfdPercent: partner.FDPotential,
            });
          }
        } else {
          const tempArray = newPartners.filter(
            (otherPartner) => otherPartner?.['_id'] !== partner.societyPartnerId
          );
          const tempPartner = allPartners.find(
            (otherPartner) => otherPartner?.['_id'] === partner.societyPartnerId
          );
          tempArray.push({
            ...tempPartner,
            sharesCount: {
              actual: partner.shares?.total,
            },
            ndPercent: partner.NDTotal,
            fdPercent: partner.FDTotal,
            pfdPercent: partner.FDPotential, // TODO: check why this is affecting to % Potential FD column
          });
          newPartners = [...tempArray];
        }
      });
      setNewShares(newSharesTotal);

      // const partnersWithShares = newPartners?.filter(
      //   (partner) => partner?.sharesCount?.actual > 0
      // );
      setCurrentPartners(newPartners);
      setCurrentTableRows(newRows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedPartners, tableRows]);

  return (
    <div
      className={
        (view === 2 || view === 3) && text ? 'nk-content-body pt-0' : 'd-none'
      }
    >
      <div className="nk-content-wrap">
        <div className="nk-block">
          <div className="card card-bordered">
            <div className="table-responsive">
              <table
                id="partnerClassTableMainColumn"
                className="table table-striped text-right cloned pos-abs bg-white border-right partner-book-table"
              >
                <tr className="text-center table-header">
                  <th className="w-200px bg-light"> {t('Partners')} &nbsp;</th>
                </tr>

                {currentPartners
                  ?.filter((partner) => partner?.sharesCount?.actual > 0)
                  ?.map((partner, index) => (
                    <tr key={partner?.['_id']}>
                      <td
                        key={partner?.['_id']}
                        id={`${partner?.['_id']}s`}
                        className={
                          !(index % 2 === 0)
                            ? 'text-left w-200px d-block text-ellipsis bg-light '
                            : 'text-left w-200px d-block text-ellipsis'
                        }
                        style={
                          index === currentPartners.length - 1
                            ? { borderBottom: '1px solid #999' }
                            : {}
                        }
                      >
                        {partner.name}
                        <Tooltip
                          anchorId={`${partner?.['_id']}s`}
                          place="bottom"
                        >
                          {partner?.name}
                        </Tooltip>
                      </td>
                    </tr>
                  ))}

                {aditionalRowHeaders?.map((header, index) => (
                  <tr key={`${header.text}-${index}`}>
                    <td
                      key={header.text}
                      className={
                        !((currentPartners.length + index) % 2 === 0)
                          ? header.style1
                          : header.style2
                      }
                      style={
                        index === aditionalRowHeaders.length - 1
                          ? { borderBottom: '1px solid #999' }
                          : {}
                      }
                    >
                      {header.text}
                    </td>
                  </tr>
                ))}

                {totalsRowHeaders?.map((header, index) => (
                  <tr key={header.text}>
                    <td
                      key={header.text}
                      className={
                        !((currentPartners.length + index + 9) % 2 === 0)
                          ? header.style1
                          : header.style2
                      }
                    >
                      {header.text}
                    </td>
                  </tr>
                ))}
              </table>

              <table
                className="table table-striped text-right border-right partner-book-table"
                id={
                  text
                    ? 'partnerClassTableText'
                    : inversionView
                    ? 'partnerClassTableInversion'
                    : 'partnerClassTable'
                }
                style={{
                  width: '100%',
                  // width: `${(holdingClasses.length + 2) * 210 + 210}px`,
                }}
              >
                <tr className="text-center table-header">
                  <th className="w-200px"> {t('Partners')} &nbsp;</th>

                  {holdingClasses?.map(
                    (holdingClass, index) =>
                      holdingClass.name !== '' && (
                        <th
                          id={holdingClass?.['_id']}
                          key={`${holdingClass?.['_id']}-${index}`}
                          className={`${
                            index % 2 === 0 ? 'bg-light-vert' : ''
                          } holding-class-header`}
                        >
                          {holdingClass.name}
                          <Tooltip anchorId={holdingClass?.['_id']} place="top">
                            {holdingClass?.name}
                          </Tooltip>
                        </th>
                      )
                  )}

                  <th
                    key={`${totalSharesColumnHeader.text}`}
                    className={getColumnClass(0, holdingClasses.length, true)}
                    colSpan={totalSharesColumnHeader.columns}
                  >
                    {totalSharesColumnHeader.text}
                  </th>

                  {inversionView && (
                    <DrawInversionHeaders
                      previousColumnsLength={holdingClasses?.length + 1 || 0}
                    />
                  )}

                  {aditionalColumnsHeaders?.map((column, index) => (
                    <th
                      key={`${column.text}-${index}`}
                      className={
                        inversionView
                          ? getColumnClass(
                              index,
                              inversionColumnsHeaders +
                                holdingClasses.length +
                                1,
                              true
                            )
                          : getColumnClass(
                              index,
                              holdingClasses.length + 1,
                              true
                            )
                      }
                      colSpan={column.columns}
                    >
                      {column.text}
                    </th>
                  ))}
                </tr>

                {currentPartners
                  ?.filter((partner) => partner?.sharesCount?.actual > 0)
                  ?.map((partner, index) => (
                    <tr
                      key={`${partner?.['_id']}-${index}`}
                      className={
                        index % 2 === 0
                          ? 'text-center'
                          : 'bg-light-vert text-center'
                      }
                    >
                      <td
                        className="bg-light-vert d-block text-ellipsis"
                        style={
                          index === currentPartners.length - 1
                            ? { borderBottom: '1px solid #999' }
                            : {}
                        }
                      >
                        {partner?.['_id']}-${index} {partner.name}
                      </td>

                      {holdingClasses?.map((holdingClass, index1) => (
                        <DrawCell
                          columnIndex={index1}
                          value={
                            currentTableRows[
                              `${partner?.['_id']}-${holdingClass?.['_id']}`
                            ]
                          }
                          text={text}
                          previousColumnsLength={2}
                          rowIndex={index}
                          rowsCount={currentPartners.length - 1}
                          keyText={`classes-${partner?.['_id']}-${holdingClass?.['_id']}`}
                        />
                      ))}

                      {/* Total Shares */}
                      <DrawCell
                        columnIndex={0}
                        value={partnersShares[partner?.['_id']]}
                        text={text}
                        previousColumnsLength={holdingClasses?.length || 0}
                        rowIndex={index}
                        rowsCount={currentPartners.length - 1}
                        keyText={`totalshares-${partner?.['_id']}`}
                      />

                      {/* Inversion */}
                      {inversionView && (
                        <DrawInversionColumns
                          rowIndex={index}
                          rowsCount={currentPartners.length - 1}
                          text={text}
                          previousColumnsLength={holdingClasses?.length + 1}
                          accumulatedNominalValue={parseFloat(
                            (
                              partnersShares[partner?.['_id']] *
                              actualSociety?.nominalValue
                            ).toFixed(2)
                          )}
                          totalPremiumValue={parseFloat(
                            getPartnerPremium(
                              partner?.shares || [],
                              actualSociety
                            ).toFixed(2)
                          )}
                        />
                      )}

                      {/* ND */}
                      <DrawCell
                        columnIndex={0}
                        value={partnersShares[partner?.['_id']]}
                        text={text}
                        previousColumnsLength={
                          inversionView
                            ? holdingClasses?.length + 4
                            : holdingClasses?.length + 1
                        }
                        rowIndex={index}
                        rowsCount={currentPartners.length - 1}
                        keyText={`ndshares-${partner?.['_id']}`}
                      />
                      <DrawCell
                        columnIndex={0}
                        value={parseFloat(
                          partner?.ndPercent?.toFixed(2) ||
                            getPartnerNDPercent(partner, actualSociety).toFixed(
                              2
                            )
                        )}
                        text={text}
                        previousColumnsLength={
                          inversionView
                            ? holdingClasses?.length + 5
                            : holdingClasses?.length + 2
                        }
                        rowIndex={index}
                        rowsCount={currentPartners.length - 1}
                        suffix="%"
                        decimal
                        keyText={`ndpercent-${partner?.['_id']}`}
                      />

                      {/* FD */}
                      <DrawCell
                        columnIndex={0}
                        value={partnersShares[partner?.['_id']]}
                        text={text}
                        previousColumnsLength={
                          inversionView
                            ? holdingClasses?.length + 4
                            : holdingClasses?.length + 1
                        }
                        rowIndex={index}
                        rowsCount={currentPartners.length - 1}
                        keyText={`fdshares-${partner?.['_id']}`}
                      />
                      <DrawCell
                        columnIndex={0}
                        value={parseFloat(
                          partner?.fdPercent?.toFixed(2) ||
                            getPartnerFDPercent(partner, actualSociety).toFixed(
                              2
                            )
                        )}
                        text={text}
                        previousColumnsLength={
                          inversionView
                            ? holdingClasses?.length + 5
                            : holdingClasses?.length + 2
                        }
                        rowIndex={index}
                        rowsCount={currentPartners.length - 1}
                        suffix="%"
                        decimal
                        keyText={`fdpercent-${partner?.['_id']}`}
                      />

                      {/* Potential FD */}
                      <DrawCell
                        columnIndex={0}
                        value={partnersShares[partner?.['_id']]}
                        text={text}
                        previousColumnsLength={
                          inversionView
                            ? holdingClasses?.length + 4
                            : holdingClasses?.length + 1
                        }
                        rowIndex={index}
                        rowsCount={currentPartners.length - 1}
                        keyText={`pfdshares-${partner?.['_id']}`}
                      />
                      <DrawCell
                        columnIndex={0}
                        value={parseFloat(
                          partner?.pfdPercent?.toFixed(2) ||
                            getPartnerPotentialFD(
                              partner,
                              actualSociety,
                              plans
                            ).toFixed(2)
                        )}
                        text={text}
                        previousColumnsLength={
                          inversionView
                            ? holdingClasses?.length + 5
                            : holdingClasses?.length + 2
                        }
                        rowIndex={index}
                        rowsCount={currentPartners.length - 1}
                        suffix="%"
                        decimal
                        keyText={`pfdpercent-${partner?.['_id']}`}
                      />
                    </tr>
                  ))}

                {aditionalRows?.map((row, index) => (
                  <tr
                    key={`${row.text}-${index}`}
                    className={
                      (currentPartners.length + index) % 2 === 0
                        ? 'text-center'
                        : 'bg-light-vert text-center'
                    }
                  >
                    <DrawEmptyColumns
                      columnsCount={holdingClasses.length + 2}
                      rowIndex={index}
                      rowsCount={aditionalRows.length - 1}
                      previousColumnsLength={1}
                    />

                    {inversionView && (
                      <DrawEmptyColumns
                        columnsCount={3}
                        rowIndex={index}
                        rowsCount={aditionalRows.length - 1}
                        previousColumnsLength={holdingClasses?.length + 1}
                      />
                    )}

                    {[
                      row.column2Value,
                      row.column3Value,
                      row.column4Value,
                      row.column5Value,
                      row.column6Value,
                      row.column7Value,
                    ].map((value, valueIndex) => (
                      <DrawCell
                        columnIndex={valueIndex}
                        rowIndex={index}
                        rowsCount={aditionalRows.length - 1}
                        value={parseFloat(value)}
                        text={text}
                        previousColumnsLength={
                          inversionView
                            ? holdingClasses?.length + 4
                            : holdingClasses?.length + 1
                        }
                        suffix={[1, 3, 5].includes(valueIndex) ? '%' : ''}
                        decimal
                        keyText="columnvalues1"
                      />
                    ))}
                  </tr>
                ))}

                {totalsRows?.map((row, index) => (
                  <tr
                    key={`${row.text}-${index}`}
                    className={
                      (currentPartners.length + index + 9) % 2 === 0
                        ? 'text-center font-weight-bold '
                        : 'text-center font-weight-bold bg-light-vert'
                    }
                  >
                    <td>{row.text}</td>

                    {holdingClasses?.map((holdingClass, index1) => (
                      <DrawCell
                        columnIndex={index1}
                        rowIndex={index}
                        value={classesShares[holdingClass?.['_id']]}
                        text={text}
                        keyText={`classesshares-${holdingClass?.['_id']}`}
                      />
                    ))}

                    <DrawCell
                      columnIndex={0}
                      rowIndex={index}
                      value={row.column1Value}
                      text={text}
                      keyText="column1value"
                    />

                    {inversionView && (
                      <DrawInversionTotals
                        partners={currentPartners}
                        partnersShares={partnersShares}
                        actualSociety={actualSociety}
                        text={text}
                      />
                    )}

                    {[
                      row.column2Value,
                      row.column3Value,
                      row.column4Value,
                      row.column5Value,
                      row.column6Value,
                      row.column7Value,
                    ].map((value, valueIndex) => (
                      <DrawCell
                        columnIndex={valueIndex}
                        rowIndex={index}
                        value={value}
                        text={text}
                        suffix={[1, 3, 5].includes(valueIndex) ? '%' : ''}
                        keyText="columnvalues2"
                      />
                    ))}
                  </tr>
                ))}
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClassesTable;
