import React from 'react';
import { useSelector } from 'react-redux';

import LogoSttokPrint from 'components/Logos/LogoSttokPrint';
// import Print.scss
import '../../../assets/styles/Print.scss';
import './PrintHeader.scss';

const PrintHeader = ({ title, subTitle = '' }) => {
  const { actualSociety } = useSelector((state) => state?.society) || {};

  return (
    <div className="print-header print-only">
      <LogoSttokPrint />
      <h3 className="text-soft print-only">{actualSociety?.name}</h3>
      <div className="print-header--title print-only">
        <h5 className={`text-soft ${subTitle ? 'text-bold' : ''}`}>{title}</h5>
        {subTitle && <h6 className="text-soft mt-1">{subTitle}</h6>}
      </div>
    </div>
  );
};

export default PrintHeader;
