/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-case-declarations */

import store from 'redux/store';
import { updateDocument } from 'redux/actions/documentActions';
import documentTypes from 'constants/documentTypes';
import documentLinkTypes from 'constants/documentLinkTypes';
import { updateSociety } from 'redux/actions/societyActions';
import { updateOperation } from 'redux/actions/modalsActions';
import Swal from 'sweetalert2';
import { updateUser } from 'redux/actions/userActions';

export default async function deleteDocument(document, society, user, i18n) {
  let directors = [];
  let representatives = society?.representatives || [];
  let auditors = society?.auditors || [];
  const linkedElements = document?.linkedTo || [];

  // Lopp for each element linked to the document.
  for (const element of linkedElements) {
    switch (element.linkedType) {
      case documentLinkTypes.PARTNER:
        // Find the partner linked to the current document.
        const partner = society?.partners?.filter(
          (partner) => partner['_id'] === element.linkedId
        )[0];

        // Get the documents without the current document.
        const partnerDocuments = partner?.documents?.filter(
          (currentDocument) => currentDocument !== document['_id']
        );

        const newPartner = {
          ...partner,
          documents: partnerDocuments,
        };
        if (partner) {
          await store.dispatch(
            updateUser(
              partner?.user,
              {
                partner: partner?.['_id'],
                society: society?.['_id'],
                partnerData: newPartner,
              },
              user?.['_id'],
              false
            )
          );
        }
        break;
        case documentLinkTypes.BENEFICIARY:
        // Find the beneficiary linked to the current document.
        const beneficiary = society?.beneficiaries?.filter(
          (beneficiary) => beneficiary['_id'] === element.linkedId
        )[0];

        // Get the documents without the current document.
        const beneficiaryDocuments = beneficiary?.documents?.filter(
          (currentDocument) => currentDocument !== document['_id']
        );

        const newBeneficiary = {
          ...beneficiary,
          documents: beneficiaryDocuments,
        };

        if (beneficiary) {
          await store.dispatch(
            updateUser(
              beneficiary?.user,
              {
                beneficiary: beneficiary?.['_id'],
                society: society?.['_id'],
                beneficiaryData: newBeneficiary,
              },
              user?.['_id'],
              false
            )
          );
        }
        break;
      case documentLinkTypes.INVESTOR:
        // Find the investor linked to the current document.
        const investor = society?.investors?.filter(
          (investor) => investor['_id'] === element.linkedId
        )[0];

        // Get the documents without the current document.
        const investorDocuments = investor?.documents?.filter(
          (currentDocument) => currentDocument !== document['_id']
        );

        const newInvestor = {
          ...investor,
          documents: investorDocuments,
        };

        if (investor) {
          await store.dispatch(
            updateUser(
              investor?.user,
              {
                investor: investor?.['_id'],
                society: society?.['_id'],
                investorData: newInvestor,
              },
              user?.['_id'],
              false
            )
          );
        }
        break;
      case documentLinkTypes.OPERATION:
        // Find the operation linked to the current document.
        const operation = society?.operations?.filter(
          (operation) => operation['_id'] === element.linkedId
        )[0];

        // Get the documents without the current document.
        const operationDocuments = operation?.documents?.filter(
          (currentDocument) => currentDocument !== document['_id']
        );

        const newOperation = {
          documents: operationDocuments,
        };

        if (operation) {
          await store.dispatch(updateOperation(operation['_id'], newOperation));
        }
        break;
      case documentLinkTypes.DIRECTOR:
        // Find the director linked to the current document.
        const director = society?.directors.filter(
          (director) => director['_id'] === element.linkedId
        )[0];

        // Get the documents without the current document.
        const directorDocuments = director?.documents?.filter(
          (currentDocument) => currentDocument !== document['_id']
        );

        const newDirector = { ...director, documents: directorDocuments };

        if (director) {
          directors = {
            edit: [
              ...(directors?.edit || []),
              { directorId: newDirector['_id'], newDirector },
            ],
          };
        }

        break;
      case documentLinkTypes.REPRESENTATIVE:
        // Find the representative linked to the current document.
        const representative = representatives.filter(
          (representative) => representative['_id'] === element.linkedId
        )[0];

        // Get the documents without the current document.
        const representativeDocuments = representative?.documents?.filter(
          (currentDocument) => currentDocument !== document['_id']
        );

        const newRepresentative = {
          ...representative,
          documents: representativeDocuments,
        };

        representatives = representatives.filter(
          (representative) => representative['_id'] !== element.linkedId
        );

        if (representative) {
          representatives = [...representatives, newRepresentative];
        }
        break;
      case documentLinkTypes.AUDITOR:
        // Find the auditor linked to the current document.
        const auditor = auditors.filter(
          (auditor) => auditor['_id'] === element.linkedId
        )[0];

        // Get the documents without the current document.
        const auditorDocuments = auditor?.documents?.filter(
          (currentDocument) => currentDocument !== document['_id']
        );

        const newAuditor = {
          ...auditor,
          documents: auditorDocuments,
        };

        auditors = auditors?.filter(
          (auditor) => auditor['_id'] !== element.linkedId
        );

        if (auditor) {
          auditors = [...auditors, newAuditor];
        }
        break;
      default:
        break;
    }
  }
  const newSocietyData = {
    directors,
    representatives,
    auditors,
  };

  // Update society.
  if (linkedElements.length) {
    await store.dispatch(updateSociety(society['_id'], newSocietyData, false));
  }
  // Update document.
  await store.dispatch(
    updateDocument(
      {
        ...document,
        category: documentTypes.DELETED,
        linkedTo: [],
      },
      false
    )
  );
  return Swal.fire({
    icon: 'success',
    title: `<h4 class="nk-modal-title">${i18n.t(
      'DocumentDeletedSuccessfully'
    )}<br></h4>`,
    confirmButtonText: i18n.t('OK'),
    allowOutsideClick: false,
    showCancelButton: false,
    confirmButtonColor: '#6576FF',
  });
}
