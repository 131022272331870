import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslation } from 'react-i18next';

import menuTypes from 'constants/menuTypes';

import { setMenuType } from 'redux/actions/menuActions';

import CustomLoading from 'components/CustomLoading';
import UserMyData from '../../components/UserMyData';
import UserWithAccess from '../../components/UserWithAccess';

const tabs = {
  datos: 0,
  administradores: 1,
};

function UserProfile({ user, actions }) {
  const { t } = useTranslation();
  const { activeTab } = useParams();
  const [page, setPage] = useState(tabs[activeTab]);

  const isDemo = useSelector((state) => state.society?.role?.isDemo);

  const pages = [
    <UserMyData user={user} key="UserMyData" />,
    <UserWithAccess user={user} key="UserWithAccess" />,
  ];

  useEffect(() => {
    setPage(tabs[activeTab]);
  }, [activeTab]);

  useEffect(() => {
    actions.setMenuType(menuTypes.MAIN);
  }, [actions]);

  return !user ? (
    <CustomLoading />
  ) : (
    <div className="nk-content-body">
      <div className="nk-content-wrap">
        <div className="nk-block-head">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">{t('MyProfile')}</h3>
            <div className="nk-block-des">
              <p>{t('FromHereYouCanManageYourData')}</p>
            </div>
          </div>
        </div>
        <div className="nk-block">
          <div className="card card-bordered">
            <ul className="nav nav-tabs nav-tabs-mb-icon nav-tabs-card">
              <li className="nav-item current-page">
                <Link
                  className={`nav-link ${page === 0 ? 'active' : ''}`}
                  to="/perfil-usuario/datos"
                  onClick={() => setPage(0)}
                >
                  <em className="icon ni ni-user-fill-c" />
                  <span>{t('MyData')}</span>
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  className={`nav-link ${page === 1 ? 'active' : ''} ${
                    isDemo ? 'disabled' : ''
                  }`}
                  to={isDemo ? '#' : '/perfil-usuario/administradores'}
                  onClick={
                    isDemo ? (e) => e.preventDefault() : () => setPage(1)
                  }
                >
                  <em className="icon ni ni-lock-alt-fill" />
                  <span>{t('UsersWithAccess')}</span>
                </Link>
              </li>
            </ul>
            {pages[page]}
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps({ user, address }) {
  return { user, address };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ setMenuType }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
