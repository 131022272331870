import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { Share as OriginalShare } from 'types';

type Share = Omit<OriginalShare, 'shareClass'> & {
  shareClass: string;
  nominalValue: number;
};

type Props = {
  share: Share;
  tag: string;
  setTag: (value: string) => void;
  setTagUpdated: (value: boolean) => void;
};

const ShareTagView: FC<Props> = ({
  share: {
    from: shareFrom,
    to: shareTo,
    nominalValue: shareNominalValue,
    sharePremium,
    shareClass,
  },
  tag,
  setTag,
  setTagUpdated,
}) => {
  const { t } = useTranslation();

  const handleChangeTag = (event: any) => {
    setTag(event.target.value);
    setTagUpdated(true);
  };
  return (
    <>
      <div className="row">
        <div className="col-6">
          <div className="form-group">
            <label className="form-label" htmlFor="share-from">
              {t('From')}
            </label>
            <div className="form-control-wrap">
              <input
                type="text"
                id="share-from"
                className="form-control form-control-md"
                value={shareFrom}
                readOnly
              />
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <label className="form-label" htmlFor="share-to">
              {t('To')}
            </label>
            <div className="form-control-wrap">
              <input
                type="text"
                id="share-to"
                className="form-control form-control-md"
                value={shareTo}
                readOnly
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-6">
          <div className="form-group">
            <label className="form-label" htmlFor="share-nominal-value">
              {t('NominalValue')}
            </label>
            <div className="form-control-wrap">
              <input
                type="text"
                id="share-nominal-value"
                className="form-control form-control-md"
                value={shareNominalValue}
                readOnly
              />
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <label className="form-label" htmlFor="share-premium">
              {t('SharePremium')}
            </label>
            <div className="form-control-wrap">
              <input
                type="text"
                id="share-premium"
                className="form-control form-control-md"
                value={sharePremium}
                readOnly
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-6">
          <div className="form-group">
            <label className="form-label" htmlFor="share-class">
              {t('ShareClass')}
            </label>
            <div className="form-control-wrap">
              <input
                type="text"
                id="share-class"
                className="form-control form-control-md"
                value={shareClass}
                readOnly
              />
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <label className="form-label" htmlFor="share-tag">
              {t('ShareTag')}
            </label>
            <div className="form-control-wrap">
              <input
                type="text"
                id="share-tag"
                className="form-control form-control-md"
                value={tag}
                onChange={handleChangeTag}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShareTagView;
