/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { format } from 'date-fns';
// eslint-disable-next-line import/no-extraneous-dependencies
import parse from 'html-react-parser';

import { updateCommEmail } from 'redux/actions/communicationActions';

import { getActualSociety } from 'utils/filters';
import downloadDocument from 'utils/downloadDocument';

import {
  getDateTime,
  getRecipientsText,
  getMailStatus,
  updateRecipietns,
} from 'components/Communications/utils';
import UsersArray from 'components/UsersArray';
import Certification from 'components/Communications/Certification';
import CustomLoading from 'components/CustomLoading';
import CommunicationPreview from 'components/Communications/components/CommunicationPreview';

const InboxDetails = ({ communication }) => {
  const { t } = useTranslation();
  const printRef = useRef();
  const dispatch = useDispatch();
  const { location } = useHistory();
  const { societyId } = useParams();

  const user = useSelector((state) => state.user);
  const actualSociety = useSelector((state) => state.society?.actualSociety);
  const isDemo = useSelector((state) => state.society?.role?.isDemo);

  const [parsedDoc, setParsedDoc] = useState();
  const [isFavorite, setIsFavorite] = useState(false);
  const [openCertification, setOpenCertification] = useState(false);
  const [hasAttachments, setHasAttachments] = useState(false);
  const [recipients, setRecipients] = useState(communication?.recipients);
  const [isLoading, setIsLoading] = useState(false);

  const getRecipientsStatus = async () => {
    try {
      setIsLoading(true);
      const recipientsInfo = await updateRecipietns(communication);
      setIsLoading(false);

      setRecipients(recipientsInfo);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const getRecipientsList = (recipients) =>
    recipients?.map((recipient) => (
      <li key={recipient?._id}>{recipient?.name || recipient?.email}</li>
    ));

  const handleChangeFavorites = async (favoriteStatus) => {
    setIsFavorite(favoriteStatus);
    try {
      await dispatch(
        updateCommEmail(communication?._id, actualSociety?._id, {
          isFavorite: favoriteStatus,
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateCommunication = async () => {
    try {
      await dispatch(
        updateCommEmail(communication?._id, actualSociety?._id, {
          isUnread: false,
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId) getActualSociety(user, societyId);
  }, [actualSociety, user, societyId]);

  useEffect(() => {
    if (communication?.isUnread && actualSociety && !openCertification) {
      handleUpdateCommunication();
    }
    if (communication?.attachments?.length > 0) {
      setHasAttachments(true);
    }
    if (communication?.isFavorite) {
      setIsFavorite(true);
    }
    if (communication?.body) {
      setParsedDoc(parse(communication?.body));
    }
  }, [actualSociety, communication]);

  useEffect(() => {
    // Check the recipients info
    if (!isDemo) {
      getRecipientsStatus();
    }

    if (location?.pathname?.includes('/certificacion')) {
      setOpenCertification(true);
    }
  }, []);

  return communication ? (
    <>
      <div className="nk-ibx-reply nk-reply" data-simplebar="init">
        <div className="simplebar-wrapper" style={{ margin: '0px' }}>
          <div className="simplebar-height-auto-observer-wrapper">
            <div className="simplebar-height-auto-observer" />
          </div>
          <div className="simplebar-mask">
            <div
              className="simplebar-offset"
              style={{ right: '0px', bottom: '0px' }}
              ref={printRef}
            >
              <div
                className="simplebar-content-wrapper"
                role="region"
                aria-label="scrollable content"
                style={{
                  height: '100%',
                  overflow: 'hidden scroll',
                }}
              >
                <div className="simplebar-content" style={{ padding: '0px' }}>
                  {openCertification ? (
                    <>
                      <Certification
                        communication={communication}
                        recipientsInfo={recipients}
                      />
                    </>
                  ) : (
                    <>
                      <div className="nk-ibx-reply-head">
                        <div>
                          <h4 className="title">{communication?.subject}</h4>
                        </div>

                        <ul className="d-flex g-1">
                          <li className="">
                            <Link
                              to={`/comunicaciones/${actualSociety?._id}/${communication?._id}/certificacion`}
                            >
                              <span
                                className="btn btn-icon btn-trigger btn-tooltip"
                                onClick={() => {
                                  setOpenCertification(true);
                                }}
                              >
                                <em className="icon ni ni-shield-check" />
                              </span>
                            </Link>
                          </li>
                          <li className="d-none d-sm-block">
                            <ReactToPrint
                              trigger={() => (
                                <span className="btn btn-icon btn-trigger btn-tooltip">
                                  <em className="icon ni ni-printer" />
                                </span>
                              )}
                              content={() => printRef.current}
                              documentTitle={`${actualSociety?.name} - ${
                                communication?.subject
                              } ${format(new Date(), 'dd-MM-yyyy')}`}
                            />
                          </li>
                          {!isDemo && (
                            <li className="mr-n1">
                              <div className="asterisk">
                                <span
                                  className="btn btn-trigger btn-icon btn-tooltip"
                                  onClick={() =>
                                    handleChangeFavorites(!isFavorite)
                                  }
                                >
                                  {isFavorite ? (
                                    <em className="asterisk-off icon ni ni-star-fill  text-primary" />
                                  ) : (
                                    <em className="asterisk-off icon ni ni-star" />
                                  )}
                                </span>
                              </div>
                            </li>
                          )}
                        </ul>
                      </div>

                      <div className="nk-ibx-reply-group">
                        <div className="nk-ibx-reply-item nk-reply-item">
                          <div className="nk-reply-header nk-ibx-reply-header is-shown">
                            <div className="nk-reply-desc">
                              <div className="ml-1">
                                <UsersArray
                                  users={communication?.recipients}
                                  show={1}
                                  size="w-40px"
                                  bgColor="bg-primary"
                                />
                              </div>

                              <div className="nk-reply-info">
                                <div className="nk-reply-author lead-text">
                                  {communication?.sender?.email}
                                  <span className="date d-sm-none">
                                    {getDateTime(communication?.date)}
                                  </span>
                                </div>
                                <div className="dropdown nk-reply-msg-info">
                                  <a
                                    id="tooltip-recipients"
                                    className="dropdown-toggle sub-text dropdown-indicator"
                                    data-toggle="dropdown"
                                  >
                                    {t('EmailTo')}{' '}
                                    {getRecipientsText(
                                      communication?.recipients,
                                      communication?.presetGroup
                                    )}
                                  </a>
                                  <Tooltip
                                    anchorId="tooltip-recipients"
                                    place="bottom"
                                  >
                                    <div>
                                      <ul>
                                        {getRecipientsList(
                                          communication?.recipients
                                        )}
                                      </ul>
                                    </div>
                                  </Tooltip>
                                  <div className="dropdown-menu dropdown-menu-md">
                                    <ul className="nk-reply-msg-meta">
                                      <li>
                                        <span className="label">from:</span>{' '}
                                        <span className="info">
                                          <a href="#">info@softnio.com</a>
                                        </span>
                                      </li>
                                      <li>
                                        <span className="label">to:</span>{' '}
                                        <span className="info">
                                          <a href="#">team@softnio.com</a>
                                        </span>
                                      </li>
                                      <li>
                                        <span className="label">bcc:</span>{' '}
                                        <span className="info">
                                          <a href="#">team@softnio.com</a>
                                        </span>
                                      </li>
                                      <li>
                                        <span className="label">
                                          mailed-by:
                                        </span>{' '}
                                        <span className="info">
                                          <a href="#">softnio.com</a>
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="nk-reply-msg-excerpt">
                                  {parsedDoc || ''}
                                </div>
                              </div>
                            </div>
                            <ul className="nk-reply-tools g-1">
                              {communication?.attachments?.length > 0 && (
                                <li className="attach-msg">
                                  <em className="icon ni ni-clip-h" />
                                </li>
                              )}
                              <li className="date-msg">
                                <span className="date">
                                  {getDateTime(communication?.date)}
                                </span>
                              </li>
                              <li className="replyto-msg">
                                <a
                                  href="#"
                                  className="btn btn-trigger btn-icon btn-tooltip is-disable"
                                  title=""
                                  data-original-title="Reply"
                                >
                                  <em className="icon ni ni-curve-up-left" />
                                </a>
                              </li>
                              <li className="more-actions">
                                <div className="dropdown">
                                  <a
                                    href="#"
                                    className="dropdown-toggle btn btn-trigger btn-icon is-disable"
                                    data-toggle="dropdown"
                                  >
                                    <em className="icon ni ni-more-v" />
                                  </a>
                                  <div className="dropdown-menu dropdown-menu-right">
                                    <ul className="link-list-opt no-bdr">
                                      <li>
                                        <a className="dropdown-item" href="#">
                                          <em className="icon ni ni-reply-fill" />
                                          <span>Reply to</span>
                                        </a>
                                      </li>
                                      <li>
                                        <a className="dropdown-item" href="#">
                                          <em className="icon ni ni-forward-arrow-fill" />
                                          <span>Forward</span>
                                        </a>
                                      </li>
                                      <li>
                                        <a className="dropdown-item" href="#">
                                          <em className="icon ni ni-trash-fill" />
                                          <span>Delete this</span>
                                        </a>
                                      </li>
                                      <li>
                                        <a className="dropdown-item" href="#">
                                          <em className="icon ni ni-report-fill" />
                                          <span>Report Spam</span>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>

                          <div className="nk-reply-body nk-ibx-reply-body">
                            <CommunicationPreview data={{ communication }} />

                            <div className="attach-files">
                              <ul className="attach-list flex-column justify-content-center">
                                {communication?.attachments?.map(
                                  (attachment) => (
                                    <li
                                      className="attach-item d-flex w-100 mb-1"
                                      key={attachment._id}
                                    >
                                      <em className="icon ni ni-file-doc" />
                                      <span>{attachment?.name}</span>
                                    </li>
                                  )
                                )}
                              </ul>

                              <div className="attach-foot">
                                <span className="attach-info">
                                  {communication?.attachments?.length}
                                  &nbsp;
                                  {t('FilesAttached')}
                                </span>

                                <span
                                  className={`attach-download link ${
                                    !hasAttachments ? 'is-disable' : ''
                                  }`}
                                  onClick={() => {
                                    downloadDocument({
                                      multiple: true,
                                      documents: communication?.attachments,
                                    });
                                  }}
                                >
                                  <em
                                    className={`icon ni ni-download text-primary cursor-pointer ${
                                      !hasAttachments ? 'is-disable' : ''
                                    }`}
                                  />
                                  <span
                                    className={`${
                                      !hasAttachments ? 'is-disable' : ''
                                    }`}
                                  >
                                    {t('DownloadAll')}
                                  </span>
                                </span>
                              </div>
                            </div>

                            <hr className="divider" />

                            <div className="table-recipients">
                              <div className="d-flex flex-row justify-content-between">
                                <h6 className="m-0">{t('DeliveryStatus')}</h6>
                                <Link
                                  to={`/comunicaciones/${actualSociety?._id}/${communication?._id}/certificacion`}
                                >
                                  <span
                                    className="attach-download link"
                                    onClick={() => {
                                      setOpenCertification(true);
                                    }}
                                  >
                                    <em className="icon ni ni-external text-primary cursor-pointer" />
                                    <span className="cursor-pointer mr-2">
                                      {t('ViewCertificate')}
                                    </span>
                                  </span>
                                </Link>
                              </div>
                              <table className="table table-striped mt-3">
                                <thead>
                                  <tr>
                                    <th>{t('Email')}</th>
                                    <th>{t('Recipient')}</th>
                                    <th className="text-left">{t('Status')}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {recipients.map((recipient) => (
                                    <tr key={recipient?._id}>
                                      <td>{recipient?.email}</td>
                                      <td>{recipient?.name}</td>
                                      <td className="text-left">
                                        {getMailStatus(recipient, t)}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            className="simplebar-placeholder"
            style={{ width: 'auto', height: '1029px' }}
          />
        </div>
        <div
          className="simplebar-track simplebar-horizontal"
          style={{ visibility: 'hidden' }}
        >
          <div
            className="simplebar-scrollbar"
            style={{ width: '0px', display: 'none' }}
          />
        </div>
        <div
          className="simplebar-track simplebar-vertical"
          style={{ visibility: 'visible' }}
        >
          <div
            className="simplebar-scrollbar"
            style={{
              height: '90px',
              transform: 'translate3d(0px, 0px, 0px)',
              display: 'block',
            }}
          />
        </div>
      </div>
      {isLoading && <CustomLoading />}
    </>
  ) : (
    <></>
  );
};

export default InboxDetails;
