import i18n from 'i18n/config';

// TODO: Update existing boards with the deprecated values to the existing ones
export default [
  {
    value: 'FROM_SENDING_TO_CLOSING',
    text: i18n.t('FROM_SENDING_TO_CLOSING'),
  },
  // {
  //   value: 'FROM_SENDING_TO_ONE_HOUR_AFTER_BOARD_DATE',
  //   text: i18n.t('FROM_SENDING_TO_ONE_HOUR_AFTER_BOARD_DATE'),
  // },
  {
    value: 'FROM_BOARD_DATE_TO_CLOSING',
    text: i18n.t('FROM_BOARD_DATE_TO_CLOSING'),
  },
  // {
  //   value: 'FROM_BOARD_DATE_TO_ONE_HOUR_AFTER_BOARD_DATE',
  //   text: i18n.t('FROM_BOARD_DATE_TO_ONE_HOUR_AFTER_BOARD_DATE'),
  // },
  {
    value: 'FROM_OPENING_VOTES_TO_CLOSING',
    text: i18n.t('FROM_OPENING_VOTES_TO_CLOSING'),
  },
];
