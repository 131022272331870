import React from 'react';
import { twitterLightColors } from '../constants/themeColors';

type Props = {
  id: string;
  label: string;
  colors: string[];
  onColorChange: any;
  currentColor: string;
  isColorDisabled: (color: string) => boolean;
};

const formatColorForInput = (color: string): string =>
  color.replace('#', '').toUpperCase();

const SocietyConfigurationColorRow = (props: Props) => {
  const { id, label, colors, isColorDisabled, onColorChange, currentColor } =
    props;

  const isValidHexColor = (color: string) => /^#[0-9A-F]{6}$/i.test(color);
  const hasValidCharacters = (color: string) => /^[0-9A-F]{6}$/i.test(color);

  const parseColorToInput = (color: string) => color.replace('#', '');

  const getLabelStyle = (color: string, currentColor: string) => ({
    background: color,
    boxShadow: currentColor === color ? `${color} 0px 0px 4px` : 'none',
    border: color === '#FFFFFF' ? '1px solid #ABB8C3' : 'none',
  });

  const getInputLabelColor = (color: string) => {
    if (!isValidHexColor(color) || !colors.includes(color)) {
      return '#000000';
    }

    const isLightColor = twitterLightColors.includes(color);
    return isLightColor ? '#000000' : '#FFFFFF';
  };

  const getInputBackgroundColor = (color: string) => {
    if (!isValidHexColor(color)) {
      return '#F0F0F0';
    }

    return color;
  };

  const handleChangeColorInput = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const color = event.target.value;
    const parsedColor = `#${color}`;
    onColorChange(parsedColor);
  };

  return (
    <div className="data-item">
      <div className="data-col">
        <span className="data-label">{label}</span>
      </div>

      <div className="data-col data-col-end w-auto">
        <div className="form-control-wrap input-color-hex w-120px">
          <div className="input-group input-group-sm">
            <div className="input-group-prepend">
              <span
                className="input-group-text"
                style={{
                  backgroundColor: getInputBackgroundColor(currentColor),
                  color: getInputLabelColor(currentColor),
                }}
              >
                #
              </span>
            </div>
            <input
              type="text"
              className="form-control text-center"
              value={formatColorForInput(currentColor)}
              onChange={handleChangeColorInput}
              aria-label="#"
              aria-describedby="currentColor-hex-value"
              maxLength={6}
            />
          </div>
        </div>

        <ul className="custom-control-group g-1 vertical-align-middle">
          {colors.map((color, index) => (
            <li>
              <div className="custom-control color-control">
                <input
                  type="radio"
                  className={`custom-control-input `}
                  id={`${id}-${index}-${color}`}
                  name={`${id}-color-input`}
                  value={parseColorToInput(color)}
                  disabled={isColorDisabled(color)}
                  onChange={handleChangeColorInput}
                />
                <label
                  className={`custom-control-label color-selector-item ${
                    isColorDisabled(color) ? 'cursor-not-allowed' : ''
                  }`}
                  data-bg={color}
                  htmlFor={`${id}-${index}-${color}`}
                  style={getLabelStyle(color, currentColor)}
                />
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SocietyConfigurationColorRow;
