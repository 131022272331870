import React from 'react';
import store from 'redux/store';
import AddBeneficiary from 'components/Modals/AddBeneficiary';
import { setModal } from 'redux/actions/modalActions';
import { getBeneficiaryFromBeneficiaryId } from './filters';

export default function editBeneficiary(data, beneficiaryId) {
  const { society } = store.getState();
  const currentBeneficiary = getBeneficiaryFromBeneficiaryId(
    society?.actualSociety,
    beneficiaryId
  );
  store.dispatch(
    setModal(
      <AddBeneficiary action="edit" currentBeneficiary={currentBeneficiary} />
    )
  );
}
