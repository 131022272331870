import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  DEFAULT_HEADER_COLOR,
  DEFAULT_HEADER_LOGO,
  DEFAULT_HEADER_TEXT_COLOR,
  DEFAULT_HEADER_TEXT_HOVER_COLOR,
} from 'constants/defaultConstants';

import useUserValidation from 'hooks/useUserValidation';
import toggleSideNav from 'redux/actions/sideNavActions';
import isSuperAdmin from 'utils/isSuperAdmin';

import ToggleWidthButton from 'components/Buttons/ToggleWidthButton';
import UserMenuDots from 'components/MenuDots/UserMenuDots';
import WizardButton from 'components/Buttons/WizardButton';

import './Header.scss';
import '../../assets/styles/Layouts.scss';
import '../../assets/styles/Styles.scss';
import '../../assets/styles/Bootstrap.scss';

import menuOptions from './menuOptions';

function Header() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();

  const user = useSelector((state) => state.user);
  const theme = useSelector(
    (state) => state.society?.actualSociety?.customization?.theme
  );
  const showSideNav = useSelector((state) => state.sideNav);

  const userValidations = useUserValidation(user);

  const [hasSocietyButtonEnabled, setHasSocietyButtonEnabled] = useState(false);

  const [headerLogo, setHeaderLogo] = useState(
    theme?.logo || DEFAULT_HEADER_LOGO
  );
  const [headerColor, setHeaderColor] = useState(
    theme?.headerColor || DEFAULT_HEADER_COLOR
  );
  const [headerTextColor, setHeaderTextColor] = useState(
    theme?.headerTextColor || DEFAULT_HEADER_TEXT_COLOR
  );
  const [headerTextHoverColor, setHeaderTextHoverColor] = useState(
    theme?.headerTextHoverColor || DEFAULT_HEADER_TEXT_HOVER_COLOR
  );

  useEffect(() => {
    if (theme) {
      setHeaderLogo(theme?.logo || DEFAULT_HEADER_LOGO);
      setHeaderColor(theme?.headerColor || DEFAULT_HEADER_COLOR);
      setHeaderTextColor(theme?.headerTextColor || DEFAULT_HEADER_TEXT_COLOR);
      setHeaderTextHoverColor(
        theme?.headerTextHoverColor || DEFAULT_HEADER_TEXT_HOVER_COLOR
      );
    } else {
      setHeaderLogo(DEFAULT_HEADER_LOGO);
      setHeaderColor(DEFAULT_HEADER_COLOR);
      setHeaderTextColor(DEFAULT_HEADER_TEXT_COLOR);
      setHeaderTextHoverColor(DEFAULT_HEADER_TEXT_HOVER_COLOR);
    }
  }, [theme]);

  useEffect(() => {
    if (location.pathname === '/') {
      setHasSocietyButtonEnabled(true);
    } else {
      setHasSocietyButtonEnabled(false);
    }
  }, [location]);

  return (
    <div
      className="nk-header nk-header-fixed is-light"
      style={{ backgroundColor: headerColor }}
    >
      <div className="container-lg wide-xl">
        <div className="nk-header-wrap">
          <div className="nk-header-brand">
            <Link to="/" className="logo-link">
              <img
                className="logo-dark logo-img"
                src={headerLogo}
                srcSet={headerLogo}
                alt="logo-dark"
              />
            </Link>
          </div>
          <div className="nk-header-menu">
            <ul className="nk-menu nk-menu-main">
              <li className="nk-menu-item">
                <Link
                  to="/"
                  className="nk-menu-link"
                  style={{ color: headerTextColor }}
                >
                  <span className="nk-menu-text">{t('Dashboard')}</span>
                </Link>
              </li>

              {userValidations.hasAdministrated && (
                <li className="nk-menu-item has-sub">
                  <a
                    className="nk-menu-link nk-menu-toggle cursor-pointer"
                    style={{ color: headerTextColor }}
                  >
                    <span className="nk-menu-text">{t('Administrated')}</span>
                  </a>
                  <ul
                    className="nk-menu-sub"
                    style={{ backgroundColor: headerColor }}
                  >
                    <li className="nk-menu-item">
                      <Link
                        to="/cuadro-administradas"
                        className="nk-menu-link nk-menu-link-secondary"
                        style={{ color: headerTextColor }}
                      >
                        <span className="nk-menu-text">
                          {t('AdministratedTable')}
                        </span>
                      </Link>
                    </li>
                    <li className="nk-menu-item">
                      <Link
                        to="/lista-administradas"
                        className="nk-menu-link nk-menu-link-secondary"
                        style={{ color: headerTextColor }}
                      >
                        <span className="nk-menu-text">
                          {t('AdministratedList')}
                        </span>
                      </Link>
                    </li>
                    <li className="nk-menu-item">
                      <Link
                        to="/diagrama-administradas"
                        className="nk-menu-link nk-menu-link-secondary"
                        style={{ color: headerTextColor }}
                      >
                        <span className="nk-menu-text">
                          {t('AdministratedChart')}
                        </span>
                      </Link>
                    </li>
                    <li className="nk-menu-item">
                      <Link
                        to="/kanban-administradas"
                        className="nk-menu-link nk-menu-link-secondary is-disable"
                      >
                        <span className="nk-menu-text">
                          {t('AdministratedKanban')}
                        </span>
                      </Link>
                    </li>
                  </ul>
                </li>
              )}

              {userValidations.hasParticipated && (
                <li className="nk-menu-item has-sub">
                  <a
                    className="nk-menu-link nk-menu-toggle cursor-pointer"
                    style={{ color: headerTextColor }}
                  >
                    <span className="nk-menu-text">{t('Participated')}</span>
                  </a>
                  <ul
                    className="nk-menu-sub"
                    style={{ backgroundColor: headerColor }}
                  >
                    <li className="nk-menu-item">
                      <Link
                        to="/cuadro-participadas"
                        className="nk-menu-link nk-menu-link-secondary"
                        style={{ color: headerTextColor }}
                      >
                        <span className="nk-menu-text">
                          {t('ParticipatedTable')}
                        </span>
                      </Link>
                    </li>
                    <li className="nk-menu-item">
                      <Link
                        to="/lista-participadas"
                        className="nk-menu-link nk-menu-link-secondary"
                        style={{ color: headerTextColor }}
                      >
                        <span className="nk-menu-text">
                          {t('ParticipatedList')}
                        </span>
                      </Link>
                    </li>
                    <li className="nk-menu-item">
                      <Link
                        to="/diagrama-participadas"
                        className="nk-menu-link nk-menu-link-secondary"
                        style={{ color: headerTextColor }}
                      >
                        <span className="nk-menu-text">
                          {t('ParticipatedChart')}
                        </span>
                      </Link>
                    </li>
                    <li className="nk-menu-item">
                      <Link
                        to="/kanban-participadas"
                        className="nk-menu-link nk-menu-link-secondary is-disable"
                      >
                        <span className="nk-menu-text">
                          {t('ParticipatedKanban')}
                        </span>
                      </Link>
                    </li>
                  </ul>
                </li>
              )}

              {userValidations.hasBeneficiated && (
                <li className="nk-menu-item has-sub">
                  <a
                    className="nk-menu-link nk-menu-toggle cursor-pointer"
                    style={{ color: headerTextColor }}
                  >
                    <span className="nk-menu-text">{t('Plans')}</span>
                  </a>
                  <ul
                    className="nk-menu-sub"
                    style={{ backgroundColor: headerColor }}
                  >
                    <li className="nk-menu-item">
                      <Link
                        to="/lista-beneficiadas"
                        className="nk-menu-link nk-menu-link-secondary"
                        style={{ color: headerTextColor }}
                      >
                        <span className="nk-menu-text">{t('PlansList')}</span>
                      </Link>
                    </li>
                  </ul>
                </li>
              )}
            </ul>
          </div>
          <div className="nk-header-tools">
            <ul className="nk-quick-nav">
              {hasSocietyButtonEnabled && (
                <Link
                  to="/nueva-sociedad"
                  className="btn btn-outline-primary d-none d-md-inline-flex"
                >
                  {t('AddSociety')}
                </Link>
              )}
              {isSuperAdmin(user) && hasSocietyButtonEnabled && (
                <li className="d-none d-md-inline">
                  <WizardButton />
                </li>
              )}
              <li className="d-none d-md-inline pl-0">
                <ToggleWidthButton />
              </li>
              <li className="dropdown user-dropdown ml-2">
                <UserMenuDots menuOptions={menuOptions} />
              </li>
              <li className="d-lg-none">
                {showSideNav}
                <a
                  className="toggle nk-quick-nav-icon mr-n1"
                  data-target="sideNav"
                  aria-label="temp"
                  onClick={() => dispatch(toggleSideNav())}
                >
                  <em className="icon ni ni-menu" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
