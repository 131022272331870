/* eslint-disable react/display-name */
import React from 'react';

import screens from 'constants/screens';
import MenuDots from 'components/MenuDots';
import CellData from 'components/Tables/CellData';

import societyOptions from './societyOptions';

function tableColumns(i18n) {
  return [
    {
      // mandatory props
      name: i18n.t('Society'),
      field: 'name',
      selector: (row) => row[i18n.t('Society')],
      sortable: true,
      // optional props
      grow: 4,
      cell: (row) =>
        CellData.Society({
          society: row.society,
          url: '/socios',
          type: 'light',
          showHolding: true,
          userRoles: row?.userRoles || {},
        }),
      export: true,
    },
    {
      name: i18n.t('NºPart'),
      field: 'actualSharesCount',
      selector: (row) => row[i18n.t('NºPart')],
      sortable: true,
      compact: true,
      center: true,
      hide: screens.SM,
      cell: (row) =>
        CellData.NumberNoDecimals({ value: row[i18n.t('NºPart')] }),
      export: true,
    },
    {
      name: i18n.t('Partners'),
      field: 'partners',
      selector: (row) => row[i18n.t('Partners')],
      sortable: true,
      grow: 1.5,
      compact: true,
      center: true,
      hide: screens.SM,
      cell: (row) =>
        CellData.NumberNoDecimals({ value: row[i18n.t('Partners')] }),

      export: true,
    },
    {
      name: i18n.t('PercentND'),
      field: 'percent',
      selector: (row) => row[i18n.t('PercentND')],
      sortable: true,
      grow: 2.5,
      compact: true,
      center: true,
      hide: screens.LG,
      cell: (row) =>
        CellData.SocietyPartnerPercent({
          percent: row[i18n.t('PercentND')],
          decimals: true,
          decimalScale: row.decimalScale,
        }),
      export: true,
    },
    {
      name: i18n.t('PercentFD'),
      field: 'percentFD',
      selector: (row) => row[i18n.t('PercentFD')],
      sortable: true,
      grow: 1,
      center: true,
      compact: true,
      hide: screens.MD,
      cell: (row) =>
        CellData.SocietyPartnerPercent({
          percent: row[i18n.t('PercentFD')],
          progressBar: false,
          decimals: true,
          decimalScale: row.decimalScale,
        }),
      export: true,
    },
    {
      name: i18n.t('FdValue'),
      field: 'valueFD',
      selector: (row) => row[i18n.t('FdValue')],
      sortable: true,
      center: true,
      compact: true,
      hide: screens.MD,
      cell: (row) =>
        CellData.CurrencyDecimals({
          value: row[i18n.t('FdValue')],
        }),

      export: true,
    },
    {
      name: i18n.t('PartnerSince'),
      field: 'partnerSince',
      selector: (row) => row[i18n.t('PartnerSince')],
      sortable: true,
      compact: true,
      center: true,
      grow: 1,
      hide: screens.LG,
      cell: (row) =>
        CellData.PlainDate({
          date: row[i18n.t('PartnerSince')],
          monthYear: true,
        }),
      export: true,
    },
    {
      sortable: false,
      cell: (row) => (
        <ul className="nk-tb-actions gx-1 w-min-50px">
          <li>
            <div className="dropdown">
              <MenuDots
                menuOptions={societyOptions(i18n)}
                id={`${row.society['_id']}`}
                direction="left"
              />
            </div>
          </li>
        </ul>
      ),
      export: false,
      right: true,
      width: '50px',
      allowOverflow: true,
    },
  ];
}
export default tableColumns;
