/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable arrow-body-style */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import TableLoader from 'components/Tables/TableLoader';
import { getDecimalScale } from 'utils/filters';

import { getUserSocieties } from 'redux/actions/societyActions';
import { setMenuType } from 'redux/actions/menuActions';
import menuTypes from 'constants/menuTypes';

import CustomDataTable from 'components/Tables/CustomDataTable';
import transformData from 'components/Tables/helpers';
import tableColumns from './tableColumns';

import './ParticipatedList.scss';

function ParticipatedList({ items }) {
  const { t, i18n } = useTranslation();
  const role = useSelector((state) => state.society?.role);
  const user = useSelector((state) => state.user);

  const [participatedData, setParticipatedData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [currentTableColumns, setCurrentTableColumns] = useState(
    tableColumns(i18n)
  );

  const getSocietyDecimalScale = (societyValue) => {
    return societyValue ? getDecimalScale(societyValue) : 2;
  };

  useEffect(() => {
    setMenuType(menuTypes.MAIN);
  }, []);

  useEffect(() => {
    if (user && user.societies.participated?.length) {
      getUserSocieties(
        user['_id'],
        'partner',
        setParticipatedData,
        'table'
      ).then((result) => {
        if (!result?.length) {
          setIsLoadingData(false);
        }
      });
    } else {
      setIsLoadingData(false);
    }
  }, [user]);

  useEffect(() => {
    const newData = participatedData.map((data) => ({
      society: data?.society,
      name: data?.society?.name,
      actualSharesCount: data?.societyShares?.actual,
      partners: data?.partnersCount,
      decimalScale: getSocietyDecimalScale(data?.societyValue),
      percent: data?.userSharesPercent,
      percentFD: data?.userFdPercent,
      valueFD: (data?.societyValue * data?.userFdPercent) / 100,
      partnerSince: data?.partnerSince,
      isAdmin: data?.isUserAdmin,
      userRoles: data?.userRole,
      isSocietyHolding: data?.society?.isSocietyHolding,
    }));
    if (newData.length > 0) {
      setTableData(
        transformData({
          data: newData,
          columns: tableColumns(i18n),
        })
      );
    } else {
      setTableData([]);
    }
  }, [participatedData, i18n.language, role]);

  useEffect(() => {
    setCurrentTableColumns(tableColumns(i18n));
  }, [i18n.language]);

  return (
    <div className="nk-content-body">
      <div className="nk-content-wrap">
        <div className="nk-block-head nk-block-head-sm">
          <div className="nk-block-between">
            <div className="nk-block-head-content">
              <h3 className="nk-block-title page-title">
                {t('ParticipatedTitle')}
              </h3>
              <div className="nk-block-des text-soft">
                <p>
                  {participatedData?.length === 0
                    ? t('ParticipatedNoSocieties')
                    : t('ParticipatedHaveSocieties', {
                        count: participatedData?.length,
                      })}
                </p>
              </div>
            </div>
            <div className="nk-block-head-content">
              <div className="toggle-wrap nk-block-tools-toggle">
                <a
                  className="btn btn-icon btn-trigger toggle-expand mr-n1"
                  data-target="pageMenu"
                  aria-label="link"
                >
                  <em className="icon ni ni-menu-alt-r" />
                </a>
                <div className="toggle-expand-content" data-content="pageMenu">
                  <ul className="nk-block-tools g-3">
                    <li>
                      <Link to="/diagrama-participadas">
                        <button type="button" className="btn btn-outline-light">
                          <em className="icon ni ni-network" />
                          <span>{t('Chart')}</span>
                        </button>
                      </Link>
                    </li>
                    <li>
                      <Link to="/cuadro-participadas">
                        <button type="button" className="btn btn-outline-light">
                          <em className="icon ni ni-menu-squared" />
                          <span>{t('View')}</span>
                        </button>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        {participatedData?.length > 0 ? (
          <CustomDataTable
            data={tableData}
            columns={currentTableColumns}
            searchBy={t('Society')}
            pagination
            className="nk-tb-list"
            // expandableRows
            rowsPerPage={items}
            actions
            showDense
          />
        ) : (
          isLoadingData && <TableLoader rows={items} />
        )}
      </div>
    </div>
  );
}

export default ParticipatedList;
