/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useMixpanel } from 'react-mixpanel-browser';
import store from 'redux/store';
import { format } from 'date-fns';

import { addDocument } from 'redux/actions/documentActions';
import { updateDraft } from 'redux/actions/draftActions';
import { setModal } from 'redux/actions/modalActions';
import {
  sellParticipationsAction,
  addPartner,
} from 'redux/actions/modalsActions';

import operationTypes from 'constants/operationTypes';
import { getPartnerShares } from 'utils/filters';
import eventTypes from 'constants/eventTypes';
import fileTypes from 'constants/fileTypes';
import documentTypes from 'constants/documentTypes';

import trackEvent from 'utils/trackEvent';

import UploadDocument from 'components/UploadDocument';
import CommentsCharCounter from 'components/CommentsCharCounter';
import CustomLoading from 'components/CustomLoading';

import Swal from 'sweetalert2';

import '../Modals.scss';

function DraftToBuySellShares({
  draftId,
  draftData,
  operationData,
  setTouched,
  newPartners,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();

  const user = useSelector((state) => state.user);
  const actualSociety = useSelector((state) => state.society?.actualSociety);

  const [file, setFile] = useState();
  const [date, setDate] = useState(format(operationData?.date, 'yyyy-MM-dd'));
  const [lastOperationDate, setLastOperationDate] = useState();
  const [sharesValidation, setSharesValidation] = useState(false);
  const [sharesErrorMessages, setSharesErrorMessages] = useState([]);
  const [operationDate, setOperationDate] = useState(
    format(operationData?.date, 'yyyy-MM-dd')
  );
  const [operationLimit, setOperationLimit] = useState();
  const [comments, setComments] = useState(operationData?.comments || '');
  const [notaryName, setNotaryName] = useState(
    operationData?.notarialRegistration?.notaryName || ''
  );
  const [notaryCity, setNotaryCity] = useState(
    operationData?.notarialRegistration?.notaryCity || ''
  );
  const [protocolNr, setProtocolNr] = useState(
    operationData?.notarialRegistration?.protocolNr || ''
  );
  const [errorMessage, setErrorMessage] = useState('');
  const finalOperation = useRef(operationData);
  const [isLoading, setIsLoading] = useState(false);

  const handleDate = (selectedDate) => {
    const objDate = new Date(selectedDate);
    if (objDate.getFullYear() < 1970) {
      if (operationDate) setOperationDate();
    } else if (objDate > new Date(lastOperationDate)) {
      setErrorMessage('');
      setDate(objDate);
      setOperationDate(format(objDate, 'yyyy-MM-dd'));
    } else {
      const nextDate = new Date(lastOperationDate);
      nextDate.setMinutes(nextDate.getMinutes() + 30);
      setDate(nextDate);
      setOperationDate(format(nextDate, 'yyyy-MM-dd'));
    }
  };

  // Function to check if any cif of newPartners exists
  function notValidPartner() {
    const { partners } = actualSociety;
    for (let i = 0; i < newPartners?.length; i++) {
      for (let j = 0; j < partners?.length; j++) {
        if (newPartners[i]?.cif === partners[j]?.cif) {
          return newPartners[i];
        }
      }
    }
    return null;
  }

  // Function to add the new partners if are valid
  async function createPartners() {
    for (const newPartner of newPartners) {
      const society = await store.dispatch(addPartner(newPartner, false));
      const partners = society?.partners ?? [];
      const addedPartner = partners.find(
        (partner) => partner.cif === newPartner.cif
      );

      const newMovements = finalOperation.current?.movements.map((movement) => {
        if (movement.partner === newPartner['_id']) {
          return {
            ...movement,
            partner: addedPartner['_id'],
          };
        }
        return movement;
      });
      const newTransactions = finalOperation.current?.transactions.map(
        (transaction) => {
          if (transaction.partner === newPartner['_id']) {
            return {
              ...transaction,
              partner: addedPartner['_id'],
            };
          }
          return transaction;
        }
      );
      const newOperation = {
        ...finalOperation.current,
        movements: newMovements,
        transactions: newTransactions,
      };
      finalOperation.current = newOperation;
    }
  }

  async function saveOperation() {
    const partner = notValidPartner();
    if (partner) {
      Swal.fire({
        icon: 'info',
        title: `<h4 class="nk-modal-title">${t('NotValidCif')}</h4>`,
        html: `<h5 class="text-primary">${partner?.name}</h5><br />`,
        confirmButtonText: t('OK'),
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonColor: '#6576FF',
      });
    } else {
      setIsLoading(true);
      await createPartners();
      try {
        const operation = {
          date,
          society: actualSociety['_id'],
          user: user['_id'],
          nominalValue: actualSociety.nominalValue,
          movements: finalOperation.current.movements,
          transactions: finalOperation.current.transactions,
          comments: finalOperation.current.comments,
          notarialRegistration: {
            notaryName,
            notaryCity,
            protocolNr,
          },
        };

        let uploadedDocument = null;

        if (file) {
          const newDocument = {
            file,
            date,
            size: file.size,
            name: operationTypes.SELL_PARTICIPATION_FILENAME,
            author: user['_id'],
            society: actualSociety['_id'],
            fileType: fileTypes[file.type],
            category: documentTypes.SCRIPTURES,
            description: t('DocumentLinkedToOperation'),
          };
          uploadedDocument = await store.dispatch(addDocument(newDocument));
        }

        const society = await store.dispatch(
          sellParticipationsAction(
            {
              ...operation,
              documents: uploadedDocument?.['_id']
                ? [uploadedDocument['_id']]
                : [],
            },
            false
          )
        );

        // const operationId = society?.operations
        //   ?.filter((op) => op?.operationType === operationTypes.CAPITAL_INCREASE)
        //   .reduce(
        //     (acc, curr) =>
        //       new Date(acc.date) < new Date(curr.date) ? curr : acc,
        //     { date: new Date(-8640000000000000) }
        //   )?._id;

        // const newDraft = {
        //   ...draftData,
        //   draftPartners: JSON.stringify([]),
        //   isConverted: true,
        //   convertedDate: new Date(),
        //   convertedOperation: operationId || null,
        // };
        // dispatch(updateDraft(draftId, newDraft, false));
        // setTouched(false);

        trackEvent(mixpanel, eventTypes.SELL_PARTICIPATION, {
          userId: user?._id,
          userName: user?.name,
          userEmail: user?.email,
          societyId: actualSociety?._id,
          societyName: actualSociety?.name,
          operation: eventTypes.SELL_PARTICIPATION,
        });
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
        Swal.fire({
          icon: 'success',
          title: `<h4 class="nk-modal-title">${t(
            'OperationSavedSuccessfully'
          )}<br></h4>`,
          confirmButtonText: t('OK'),
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonColor: '#6576FF',
        });
        window.location.href = `#/socios-libro/${actualSociety['_id']}`;
        dispatch(setModal(null));
      }
    }
  }

  const allSharesAreAvailable = () => {
    const transactionsSell = operationData.transactions.filter(
      (transaction) => transaction.transactionType === 'SELL'
    );

    const partnersWhoSell = transactionsSell.map(
      (transaction) => transaction.partner
    );

    const partnersCurrentShares = {};
    const currentErrorMessages = [];

    partnersWhoSell.forEach((partner) => {
      const partnerShares = getPartnerShares(partner, actualSociety);
      partnersCurrentShares[partner] = partnerShares;
    });

    transactionsSell.reduce((acc, transaction) => {
      const transactionPartner = transaction.partner;
      const transactionClass = transaction.shareClass;
      const transactionFrom = transaction.shareFrom;
      const transactionTo = transaction.shareTo;

      let hasPartnerTheseShares = false;

      const sharesFromCurrentPartner =
        partnersCurrentShares[transactionPartner];

      if (sharesFromCurrentPartner) {
        sharesFromCurrentPartner.forEach((partnerShare) => {
          if (
            partnerShare.shareClass._id === transactionClass &&
            partnerShare.from <= transactionFrom &&
            partnerShare.to >= transactionTo
          ) {
            hasPartnerTheseShares = true;
          }
        });
      }

      if (!hasPartnerTheseShares) {
        const partnerName = actualSociety.partners.find(
          (partner) => partner._id === transactionPartner
        ).name;
        const shareClassName = sharesFromCurrentPartner.find(
          ({ shareClass }) => shareClass._id === transactionClass
        )?.shareClass?.name;

        currentErrorMessages.push(
          `${t('from')} ${transactionFrom} ${t('to')} ${transactionTo} |
          ${t('class')} ${shareClassName} | ${t('partner')} ${partnerName}`
        );
      }

      const currentPartnerKey = `${transactionPartner}-${transactionClass}-${transactionFrom}-${transactionTo}`;

      acc[currentPartnerKey] = hasPartnerTheseShares;

      return acc;
    }, {});

    setSharesErrorMessages(currentErrorMessages);
    return currentErrorMessages.length === 0;
  };

  const validateConvertionIsPossible = () => {
    setSharesValidation(allSharesAreAvailable());
  };

  useEffect(() => {
    if (actualSociety) {
      const excludedOps = [
        operationTypes.ADD_PARTNERS,
        operationTypes.ADD_PARTNER,
        operationTypes.ADD_BENEFICIARY,
        operationTypes.STOCK_OPTION,
        operationTypes.PHANTOM_SHARE,
      ];
      const filteredOperations = actualSociety.operations.filter(
        (operation) => !excludedOps.includes(operation.operationType)
      );
      const lastOperation = filteredOperations.reduce((acc, op) =>
        new Date(acc.date) > new Date(op.date) ? acc : op
      );
      // Get last operation date from UTC to local timezone with new Date()
      const lastDate = format(
        new Date(lastOperation.date),
        "yyyy-MM-dd'T'HH:mm"
      );
      const dateLimit = format(new Date(lastOperation.date), 'yyyy-MM-dd');
      setOperationLimit(dateLimit);
      setLastOperationDate(lastDate || '1990-01-01T00:00');
    }
  }, [actualSociety]);

  useEffect(() => {
    validateConvertionIsPossible();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title">{t('SaleOfShares')}</h5>
        <a
          className="close cursor-pointer"
          onClick={() => dispatch(setModal(null))}
        >
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
      <Modal.Body>
        {isLoading && <CustomLoading />}
        <form className="form-validate is-alter" onSubmit={saveOperation}>
          <div className="form-group">
            <label className="form-label" htmlFor="pay-amount">
              {t('OperationDate')}
            </label>
            <div className="form-control-wrap">
              <input
                type="date"
                className="form-control"
                value={operationDate}
                onChange={(event) => handleDate(event.target.value)}
                required
                max="2100-01-01"
                min={operationLimit}
              />
            </div>
            {errorMessage && (
              <span className="sub-text mt-1 text-danger">{errorMessage}</span>
            )}
          </div>

          <div className="mb-4">
            {sharesValidation ? (
              <></>
            ) : (
              <>
                <ul className="list list-cross">
                  <li>Las unidades que se venden ya no están disponibles</li>
                </ul>
                <ul className="list ml-4">
                  {sharesErrorMessages?.map((message) => (
                    <li key={message}>{message}</li>
                  ))}
                </ul>
              </>
            )}
          </div>

          <UploadDocument
            file={file}
            setFile={setFile}
            label="AddWritingOptional"
          />

          <div className="form-group">
            <label className="form-label" htmlFor="operation-comments">
              {t('CommentsLabel')}
            </label>
            <div className="form-control-wrap">
              <div className="form-editor-custom">
                <textarea
                  id="operation-comments"
                  maxLength="500"
                  className="form-control form-control-lg no-resize"
                  value={comments}
                  onChange={(e) => setComments(e.target.value)}
                />
              </div>
            </div>
            <div className="form-note d-flex justify-content-between">
              <>{t('CommentsVisibleOnlyAdmins')}</>
              <CommentsCharCounter comments={comments} />
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-4">
              <div className="form-group">
                <label className="form-label">{t('NotaryName')}</label>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    className="form-control form-control-md"
                    placeholder={t('Mr. John Smith')}
                    value={notaryName}
                    onChange={(e) => setNotaryName(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label className="form-label">
                  {t('NotaryProtocolNumber')}
                </label>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    className="form-control form-control-md"
                    placeholder={t('P-8234290A')}
                    value={protocolNr}
                    onChange={(e) => setProtocolNr(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label className="form-label">{t('NotaryCity')}</label>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    className="form-control form-control-md"
                    placeholder={t('Barcelona')}
                    value={notaryCity}
                    onChange={(e) => setNotaryCity(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>

          <button
            type="submit"
            className="btn btn-lg btn-primary"
            disabled={!sharesValidation}
          >
            {t('Save')}
          </button>
        </form>
      </Modal.Body>
    </>
  );
}

export default DraftToBuySellShares;
