/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import EditorWYSIWYG from 'components/EditorWYSIWYG';
import i18n from 'i18n/config';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PreviewDropdown from 'components/Dropdowns/PreviewDropdown';
import { updateDocument } from 'redux/actions/documentActions';
import store from 'redux/store';
import { updatePreview } from 'redux/actions/previewActions';
import DocumentsGenerator from 'components/Documents/Creators/DocumentsGenerator';
import ReadOnlyEditor from 'components/ReadOnlyEditor';
import menuOptions from './menuOptions';

import './Preview.scss';

const Preview = ({ hasEdit = false }) => {
  const { t } = useTranslation();

  const { user, preview, actualSociety } = useSelector((state) => ({
    user: state.user,
    preview: state.preview,
    actualSociety: state.society?.actualSociety,
  }));
  const isDemo = useSelector((state) => state.society?.role?.isDemo);
  const [currentMenuOptions, setCurrentMenuOptions] = useState(
    menuOptions(i18n)
  );

  const [showEditor, setShowEditor] = useState(hasEdit);
  const [download, setDownload] = useState(false);
  const [editorReference, setEditorReference] = useState(null);
  const [htmlContent, setHtmlContent] = useState(preview?.documentModel || '');

  const handleCloseEditor = async () => {
    const document = (
      <DocumentsGenerator
        invitationModel={htmlContent}
        hasConsolidationCalendar={false}
        consolidationCalendar={{}}
      />
    );
    await store.dispatch(
      updatePreview({ document, documentModel: htmlContent })
    );
    await store.dispatch(
      updateDocument({ ...preview.bdDocument, editorModel: htmlContent })
    );
    window.location.href = `#/documentos/nuevo/${actualSociety?._id}?preview=true`;
    setShowEditor(false);
  };

  useEffect(() => {
    if (editorReference && download) {
      editorReference.plugins.export.download('clientpdf', {});
      setDownload(false);
    }
  }, [editorReference, download]);

  useEffect(() => {
    setShowEditor(hasEdit);
  }, [hasEdit]);

  useEffect(() => {
    setCurrentMenuOptions(menuOptions(i18n));
  }, [i18n.language]);

  return (
    <>
      <div className="nk-fmg-body-content">
        <div className="nk-block-head nk-block-head-sm">
          <div className="nk-block-between position-relative">
            <div className="nk-block-head-content">
              <h3 className="nk-block-title page-title">
                {t('Document')}: {preview?.name || ''}
              </h3>
              <div className="nk-block-des text-soft">
                <p>
                  {t('UsedTemplate')}:{' '}
                  <span className="text-primary">
                    {preview?.template?.name}
                  </span>
                </p>
              </div>
            </div>
            {!isDemo && (
              <div className="nk-block-head-content">
                <ul className="nk-block-tools g-3">
                  {!showEditor ? (
                    <li>
                      <PreviewDropdown
                        menuOptions={currentMenuOptions}
                        params={{
                          society: actualSociety,
                          user,
                          preview,
                          setDownload,
                        }}
                      />
                    </li>
                  ) : (
                    <li>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleCloseEditor}
                      >
                        <em className="icon ni ni-check-round-cut mr-2" />
                        {t('Save')}
                      </button>
                    </li>
                  )}
                </ul>
              </div>
            )}
          </div>
        </div>
        <div>
          {preview?.documentModel && (
            <div>
              {!showEditor ? (
                <ReadOnlyEditor editorContent={htmlContent} />
              ) : (
                <EditorWYSIWYG
                  editorContent={htmlContent}
                  setEditorContent={setHtmlContent}
                />
              )}
            </div>
          )}
          <div style={{ display: 'none' }}>
            <EditorWYSIWYG
              editorContent={htmlContent}
              setEditorContent={setHtmlContent}
              setEditorReference={setEditorReference}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Preview;
