import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import store from 'redux/store';
import { setModal } from 'redux/actions/modalActions';
import { updateUser } from 'redux/actions/userActions';
import { updateBoard, getBoards } from 'redux/actions/boardActions';

import CustomLoading from 'components/CustomLoading';

import attendanceTypes from 'constants/attendanceTypes';
import boardTypes from 'constants/boardTypes';

import { getActualSociety } from 'utils/filters';

import Swal from 'sweetalert2';
import SendAnnouncementModal from './SendAnnouncementModal';

type SharesCount = {
  actual: number;
};

type ParticipantsType = {
  _id: string;
  member: string;
  name: string;
  email: string;
  userType: string;
  assists: boolean;
  isAbsent: boolean;
  isRepresented: boolean;
  hasDelegated: boolean;
  representative: string;
  votes: any[];
};

type PartnersType = {
  _id: string;
  name: string;
  email: string;
  sharesCount: SharesCount;
  user: string;
};

type BoardType = {
  _id: string;
  name: string;
  date: Date;
  participants: ParticipantsType[];
  attendanceType: keyof typeof attendanceTypes;
  announcement: string;
  place: string;
  onlineAccess: string;
  boardType: keyof typeof boardTypes;
  announcementDocument: string;
  votePeriod: string;
};

type SocietyType = {
  _id: string;
  name: string;
  partners: PartnersType[];
};

type Props = {
  currentBoard: BoardType;
  actualSociety: SocietyType;
  participant: any;
  announcementTemplateDoc: any;
};

const EditParticipantModal: FC<Props> = ({
  currentBoard,
  actualSociety,
  participant,
  announcementTemplateDoc,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const user: any = useSelector((state: any) => state.user);

  const [name, setName] = useState<string>();
  const [email, setEmail] = useState<string>();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSaveParticipant = async (
    event: React.FormEvent<HTMLFormElement>
  ): Promise<any> => {
    event.preventDefault();
    try {
      // Find the correspoding partner
      const partner = actualSociety?.partners.find(
        (partner) => partner?._id === participant?.member
      );

      // If the partner exist update it
      if (partner) {
        setIsLoading(true);

        // Get participants without current panrticipant
        const newParticipants = currentBoard?.participants.filter(
          (currentParticipant) =>
            currentParticipant?.['_id'] !== participant?.['_id']
        );

        // Update participant with new name and email
        const newParticipant = { ...participant, name, email };

        // Update the corresponding partner
        await store.dispatch(
          updateUser(
            partner?.user,
            {
              partner: partner?.['_id'],
              society: actualSociety?.['_id'],
              partnerData: { ...partner, name, email },
            },
            user['_id'],
            false
          )
        );

        // Update the board with new participants array
        const newBoard = await store.dispatch(
          updateBoard(
            currentBoard['_id'],
            {
              participants: [newParticipant, ...newParticipants],
            },
            false
          )
        );
        await getActualSociety(user, actualSociety['_id']);

        setIsLoading(false);

        Swal.fire({
          icon: 'success',
          title: `<h4 class="nk-modal-title">${t(
            'ParticipantUpdatedSuccessfully'
          )}<br></h4>`,
          confirmButtonText: t('OK'),
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonColor: '#6576FF',
        });
        store.dispatch(setModal(null));
        dispatch(
          setModal(
            <SendAnnouncementModal
              currentBoard={newBoard}
              actualSociety={actualSociety}
              announcementTemplateDoc={announcementTemplateDoc}
            />
          )
        );
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleClose = (): any => {
    store.dispatch(setModal(null));
    dispatch(
      setModal(
        <SendAnnouncementModal
          currentBoard={currentBoard}
          actualSociety={actualSociety}
          announcementTemplateDoc={announcementTemplateDoc}
        />
      )
    );
  };

  useEffect(() => {
    if (participant) {
      setName(participant?.name);
      setEmail(participant?.email);
    }
  }, [participant]);

  return (
    <>
      <Modal.Header>
        <h5>{t('UpdateParticipant')}</h5>
        <a className="close" onClick={handleClose}>
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>

      <Modal.Body>
        <form
          className="form-validate is-alter"
          onSubmit={handleSaveParticipant}
        >
          <div className="form-group">
            <label className="form-label" htmlFor="name">
              {t('Name')}
            </label>
            <div className="form-control-wrap">
              <input
                type="text"
                className="form-control"
                value={name}
                onChange={(event) => setName(event.target.value)}
              />
            </div>
          </div>
          <div className="form-group">
            <label className="form-label" htmlFor="email">
              {t('Email')}
            </label>
            <div className="form-control-wrap">
              <input
                type="text"
                className="form-control"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
            </div>
          </div>

          <button
            type="submit"
            className="btn btn-lg btn-primary"
            disabled={!name?.length || !email?.length}
          >
            {t('Save')}
          </button>
        </form>
      </Modal.Body>
      {isLoading && <CustomLoading />}
    </>
  );
};

export default EditParticipantModal;
