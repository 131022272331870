/* eslint-disable no-await-in-loop */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import voteValues from 'constants/voteValues';
import { isBoardClosed, isOpenVoting } from 'utils/boards';
import { getChartVotingResultsData } from 'utils/charts';
import voteResultValues from 'constants/voteResultValues';
import OrderRow from './OrderRow';

const BoardOrdersTable = ({ board, setSelectedOrder, votesSummary }) => {
  const { t } = useTranslation();

  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (board?.orders?.length && votesSummary) {
      const data = board.orders.map((order) => {
        const votingStats = votesSummary?.[order['_id']] || {};
        const yesPercent =
          votingStats?.[voteValues.YES.value]?.votePercent || 0;
        const noPercent = votingStats?.[voteValues.NO.value]?.votePercent || 0;
        const abstainPercent =
          votingStats?.[voteValues.ABS.value]?.votePercent || 0;
        const totalPercent = yesPercent + noPercent + abstainPercent;
        const isOpen = !isBoardClosed(board) && isOpenVoting(board);
        const chartData = getChartVotingResultsData([
          Number(yesPercent.toFixed(2)),
          Number(noPercent.toFixed(2)),
          Number(abstainPercent.toFixed(2)),
        ]);
        let votingResult;
        if (isOpen) {
          votingResult = voteResultValues.OPEN;
        } else if (yesPercent > noPercent) votingResult = voteResultValues.YES;
        else if (yesPercent < noPercent) votingResult = voteResultValues.NO;
        else votingResult = voteResultValues.ABS;
        return (
          <OrderRow
            key={order['_id']}
            order={order}
            votingResult={votingResult}
            chartData={chartData}
            percents={{
              yes: yesPercent,
              no: noPercent,
              abs: abstainPercent,
              total: totalPercent,
            }}
            setSelectedOrder={setSelectedOrder}
          />
        );
      });
      setRows(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [board, votesSummary]);

  return (
    <table
      className="nk-tb-list is-loose traffic-channel-table"
      id="orders-table"
    >
      <thead>
        <tr className="nk-tb-item nk-tb-head">
          <th className="nk-tb-col">
            <span>{t('Subject')}</span>
          </th>
          <th className="nk-tb-col tb-col-xl text-center">
            <span>{t('Yes')}</span>
          </th>
          <th className="nk-tb-col tb-col-xl text-center">
            <span>{t('Nope')}</span>
          </th>
          <th className="nk-tb-col tb-col-xl text-center">
            <span>{t('Abstention')}</span>
          </th>
          <th className="nk-tb-col tb-col-md text-center">
            <span>{t('Participation')}</span>
          </th>
          <th className="nk-tb-col text-center">
            <span>{t('Result')}</span>
          </th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  );
};

export default BoardOrdersTable;
