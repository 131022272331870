/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import { setModal } from 'redux/actions/modalActions';
import AddClassEmbedded from 'components/AddClassEmbedded';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { ANADIR_CLASE } from 'constants/defaultConstants';
import 'components/Modals/Modals.scss';
import { currencyFormatDecimals } from 'constants/formats';

function TransferInvestorsModal({ investors, draftId }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { holdingClasses } = useSelector((state) => state);
  const { actualSociety } = useSelector((state) => state.society);

  const [classesOptions, setClassesOptions] = useState([]);
  const [selectedClassOption, setSelectedClassOption] = useState({
    value: '',
    label: t('Select'),
  });
  const [createClassInsite, setCreateClassInsite] = useState(false);
  const animatedComponents = makeAnimated();

  const handleSubmit = () => {
    if (actualSociety && draftId) {
      window.location.href = `#/borrador-ampliacion/${actualSociety?.['_id']}?initial=${draftId}&class=${selectedClassOption.value}`;
      dispatch(setModal(null));
    }
  };

  useEffect(() => {
    if (holdingClasses?.length) {
      const options = holdingClasses?.map((currentClass) => ({
        value: currentClass?.['_id'],
        label: currentClass?.name,
      }));
      options.push({
        value: 'disabled',
        label: '─────────────────',
        isDisabled: true,
      });
      options.push({
        value: ANADIR_CLASE,
        label: ANADIR_CLASE,
      });
      setClassesOptions(options);
      setSelectedClassOption({
        value: '',
        label: t('Select'),
      });
      setCreateClassInsite(false);
    }
  }, [holdingClasses]);

  useEffect(() => {
    if (selectedClassOption?.value === ANADIR_CLASE) {
      setCreateClassInsite(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClassOption]);

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title">{t('TransferDataToCapitalIncrease')}</h5>
        <a
          className="close cursor-pointer"
          onClick={() => dispatch(setModal(null))}
        >
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
      <Modal.Body>
        <>
          <h6 className="text-base m-2">
            {t('TheNextDataWillBeTransferred')}:
          </h6>

          <table className="table table-bordered table-striped">
            <thead>
              <tr>
                <th>{t('Name')}</th>
                <th>{t('Contribution')}</th>
              </tr>
            </thead>
            <tbody>
              {investors.map((investor, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <tr key={`${investor.name}-${index}`}>
                  <td>{investor?.name}</td>
                  <td>
                    <NumberFormat
                      value={
                        investor?.realContribution || investor?.contribution
                      }
                      displayType="text"
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...currencyFormatDecimals}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {createClassInsite ? (
            <AddClassEmbedded
              setSelectedClassOption={setSelectedClassOption}
              setCreateClassInsite={setCreateClassInsite}
            />
          ) : (
            <div className="form-group">
              <label className="form-label" htmlFor="default-01">
                {t('ClassOfParticipations')}
              </label>
              <div className="form-control-wrap mt-1">
                <div className="form-icon form-icon-left">
                  <em className="icon ni ni-list" />
                </div>
                <Select
                  closeMenuOnSelect
                  className="react-select"
                  value={selectedClassOption}
                  options={classesOptions}
                  components={animatedComponents}
                  onChange={(selectedOption) =>
                    setSelectedClassOption(selectedOption)
                  }
                />
              </div>
            </div>
          )}
          <div className="text-center">
            <button
              type="button"
              className="btn btn-lg btn-primary"
              onClick={handleSubmit}
              disabled={
                !selectedClassOption?.value ||
                selectedClassOption?.value === ANADIR_CLASE
              }
            >
              {t('Accept')}
            </button>
          </div>
        </>
      </Modal.Body>
    </>
  );
}
export default TransferInvestorsModal;
