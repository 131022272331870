/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';

import { currencyFormatDecimals } from 'constants/formats';
import userTypes from 'constants/userTypes';
import {
  getDecimalScale,
  getPartnerAmount,
  getSocietyValue,
} from 'utils/filters';

import { useSelector } from 'react-redux';
import { formatNumber, formatNumberDecimalsLong } from 'utils/formats';
import MenuDots from '../MenuDots';
import menuOptions from './menuOptions';

import './PartnerCard.scss';

const PartnerCard = ({ partner, society, hasLink }) => {
  const { t } = useTranslation();
  const { user } = useSelector((state) => ({
    user: state?.user,
  }));
  const isDemo = useSelector((state) => state.society?.role?.isDemo);
  const [partnerTotalShares, setPartnerTotalShares] = useState();
  const [percent, setPercent] = useState();

  useEffect(() => {
    if (partner) {
      const partnerShares = partner.sharesCount?.actual;
      setPartnerTotalShares(partnerShares);
      const societyValue = getSocietyValue(society);
      const decimalScale = societyValue
        ? getDecimalScale(societyValue.value)
        : 2;
      setPercent(
        ((partnerShares * 100) / society.sharesCount?.actual).toFixed(
          decimalScale
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partner, partnerTotalShares]);

  menuOptions[0].url = `/detalle-socio/${society?.['_id']}`;
  menuOptions[1].url = `/detalle-socio/${society?.['_id']}`;

  return (
    <div className="col-sm-6 col-xl-4">
      <div className="card card-bordered">
        <div className="card-inner">
          <div className="team">
            <MenuDots
              menuOptions={menuOptions}
              id={partner['_id']}
              params={{
                data: partner,
                societyId: society['_id'],
                userType: userTypes.PARTNER,
                society,
                userId: user?.['_id'],
                isDemo,
              }}
            />
            <div className="user-card user-card-s2">
              <div className="user-avatar md bg-primary">
                {partner.image ? (
                  <img src={partner.image} alt={partner.name || ''} />
                ) : (
                  <span>{partner?.name?.slice(0, 2)?.toUpperCase()}</span>
                )}
              </div>
              <div className="user-info">
                <h6>{partner.name}</h6>
                <span className="sub-text">{t('PARTNER')}</span>
              </div>
            </div>

            <div className="team-details">
              <p>{partner.description}</p>
            </div>

            <div className="d-flex card-statistics justify-content-sm-between justify-content-center flex-wrap flex-sm-row flex-column">
              <div className="d-flex flex-column">
                <span>{formatNumber(partnerTotalShares) || 0}</span>
                <span>{t('NrPart.')}</span>
              </div>
              <div className="d-flex flex-column">
                <span>
                  {`${percent > 0 ? formatNumberDecimalsLong(+percent) : 0}%`}
                </span>
                <span>%</span>
              </div>
              <div className="d-flex flex-column">
                <span>
                  <NumberFormat
                    value={getPartnerAmount(partner.shares, society) || 0}
                    displayType="text"
                    renderText={(value) => <div>{value}</div>}
                    {...currencyFormatDecimals}
                  />
                </span>
                <span>{t('Investment')}</span>
              </div>
            </div>
            <div className="team-view">
              {hasLink ? (
                <Link
                  to={`/detalle-socio/${society?.['_id']}/${partner?.['_id']}`}
                  className="btn btn-round btn-outline-light w-150px"
                >
                  <span>{t('ViewProfile')}</span>
                </Link>
              ) : (
                <span className="btn btn-round btn-outline-light w-150px text-center disabled">
                  <em className="icon ni ni-lock text-secondary text-black-50 mx-auto" />
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerCard;
