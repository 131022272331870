/* eslint-disable react/react-in-jsx-scope */

import documentTypes from 'constants/documentTypes';
import { updateSociety } from 'redux/actions/societyActions';
import store from 'redux/store';
import Swal from 'sweetalert2';
import { setModal } from 'redux/actions/modalActions';
import ChangeBookNameModal from './ChangeBookNameModal';
import DownloadBookModal from './DownloadBookModal';

function menuOptions(i18n) {
  return [
    {
      icon: 'ni-edit-alt',
      text: i18n.t('Rename'),
      action: ({ currentBook, actualSociety }) => {
        store.dispatch(
          setModal(
            <ChangeBookNameModal
              actualSociety={actualSociety}
              currentBook={currentBook}
            />
          )
        );
      },
      dontShowCurrent: ({ isDemo }) => isDemo === true,
    },
    {
      icon: 'ni-file-pdf',
      text: i18n.t('Discharge'),
      action: async ({ documents, currentBook }) => {
        const docs = documents
          ?.filter(
            (document) =>
              document?.category === documentTypes.MINUTEBOOK &&
              document?.subcategory === currentBook['_id']
          )
          .sort((a, b) => new Date(a.date) - new Date(b.date));

        store.dispatch(
          setModal(
            <DownloadBookModal documents={docs} currentBook={currentBook} />
          )
        );
      },
    },
    {
      icon: 'ni-trash-alt',
      text: i18n.t('Delete'),
      action: ({ currentBook, actualSociety }) => {
        Swal.fire({
          icon: 'warning',
          title: `<h4 className="nk-block-title page-title">${i18n.t(
            'AreYouSureAboutDelFolder'
          )}  `,
          html: `<h3 class="text-primary fw-normal">${
            currentBook.label
          } </h3><br /><div class="caption-text">${i18n.t(
            'ThisProcedureCantUndo'
          )}</div>`,
          confirmButtonText: i18n.t('OkDelete'),
          confirmButtonColor: '#6576ff',
          allowOutsideClick: false,
          showCancelButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            const newFoldersData = actualSociety?.minutebookFolders.filter(
              (folder) => folder['_id'] !== currentBook['_id']
            );
            store.dispatch(
              updateSociety(actualSociety['_id'], {
                minutebookFolders: newFoldersData,
              })
            );
          }
        });
      },
      dontShowCurrent: ({ isDemo }) => isDemo === true,
    },
  ];
}
export default menuOptions;
