import React from 'react';
import { useDispatch } from 'react-redux';
import { setModal } from 'redux/actions/modalActions';

import sizes from 'constants/sizes';

import AddSocietyWizard from 'components/Modals/AddSocietyWizard';

const WizardButton = ({ variant = 'primary' }) => {
  const dispatch = useDispatch();

  const handleOpenWizard = () => {
    dispatch(setModal(<AddSocietyWizard size={sizes.XXL} />));
  };

  return (
    <button
      type="button"
      className={`btn btn-white btn-outline-${variant} px-1`}
      onClick={handleOpenWizard}
    >
      <em className="icon ni ni-plus" />
    </button>
  );
};

export default WizardButton;
