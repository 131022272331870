import {
  AlignmentType,
  convertInchesToTwip,
  Document,
  Footer,
  HeadingLevel,
  ImageRun,
  Paragraph,
  TabStopPosition,
  LevelFormat,
  TextRun,
  TabStopType,
  Header,
  Table,
  TableRow,
  TableCell,
  WidthType,
  VerticalAlign,
  PageNumber,
} from 'docx';
import BlankLogo from './BlankLogo';

const breakLine = new Paragraph({
  text: '',
});

function ActaJuntaAprobacionCuentas(society = {}) {
  const { name, assistants } = society;
  const { cif } = society;
  const { logo } = society?.additional;
  const { line1, zip, city } = society?.legalAddress;

  const imageBase64Data = logo
    ? logo?.replace(/^data:image\/png;base64,/, '')
    : BlankLogo?.replace(/^data:image\/png;base64,/, '');

  const totalND = assistants.reduce(
    (acc, cur) => acc + Number(cur.percentND),
    0
  );

  const assistantRows = assistants.map(
    (assistant) =>
      new TableRow({
        children: [
          new TableCell({
            width: {
              size: 3500,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `${assistant.fullname}`,
                  }),
                ],
                style: 'tableContent',
              }),
            ],
            verticalAlign: VerticalAlign.CENTER,
          }),
          new TableCell({
            width: {
              size: 3500,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: assistant.isRepresentated
                      ? 'Representado por: [Nombre y Apellidos]'
                      : '',
                  }),
                ],
                style: 'tableContent',
              }),
            ],
            verticalAlign: VerticalAlign.CENTER,
          }),
          new TableCell({
            width: {
              size: 2010,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                text: `${assistant.percentND}%`,
                alignment: AlignmentType.CENTER,
                style: 'tableContent',
              }),
            ],
            verticalAlign: VerticalAlign.CENTER,
          }),
        ],
      })
  );

  const doc = new Document({
    creator: name,
    title: 'Acta de la Junta General Aprobación de Cuentas',
    description: 'Acta de la Junta General Aprobación de Cuentas',

    numbering: {
      config: [
        {
          reference: 'ref1',
          levels: [
            {
              level: 0,
              format: LevelFormat.DECIMAL,
              text: '%1.',
              start: 1,
            },
          ],
        },
      ],
    },
    styles: {
      default: {
        heading1: {
          run: {
            font: 'Arial',
            size: 28,
            bold: true,
            color: '000000',
          },
          paragraph: {
            alignment: AlignmentType.CENTER,
            spacing: { line: 340 },
          },
        },
        heading2: {
          run: {
            font: 'Arial',
            size: 28,
            bold: true,
            color: '000000',
          },
          paragraph: {
            alignment: AlignmentType.CENTER,
            spacing: { line: 340 },
          },
        },
        heading3: {
          run: {
            font: 'Arial',
            size: 26,
            bold: true,
          },
          paragraph: {
            spacing: { line: 276 },
          },
        },
        heading4: {
          run: {
            font: 'Arial',
            size: 22,
            bold: true,
          },
          paragraph: {
            alignment: AlignmentType.JUSTIFIED,
          },
        },
      },
      paragraphStyles: [
        {
          id: 'normalPara',
          name: 'Normal Para',
          basedOn: 'Normal',
          next: 'Normal',
          quickFormat: true,
          run: {
            font: 'Arial',
            size: 22,
          },
          paragraph: {
            alignment: AlignmentType.JUSTIFIED,
            spacing: { line: 276, before: 20 * 72 * 0.1, after: 40 * 72 * 0.1 },
            rightTabStop: TabStopPosition.MAX,
            leftTabStop: 453.543307087,
          },
        },
        {
          id: 'secondaryPara',
          name: 'Secondary Para',
          basedOn: 'Normal',
          next: 'Normal',
          quickFormat: true,
          run: {
            font: 'Arial',
            size: 18,
            italics: true,
          },
          paragraph: {
            alignment: AlignmentType.JUSTIFIED,
            spacing: { line: 276, before: 20 * 72 * 0.1, after: 40 * 72 * 0.1 },
            indent: {
              left: 720,
            },
            rightTabStop: TabStopPosition.MAX,
            leftTabStop: 453.543307087,
          },
        },
        {
          id: 'secondaryHeading',
          name: 'Secondary Heading',
          basedOn: 'Normal',
          next: 'Normal',
          quickFormat: true,
          run: {
            font: 'Arial',
            size: 18,
            bold: true,
            italics: true,
          },
          paragraph: {
            alignment: AlignmentType.CENTER,
            spacing: { line: 276, before: 20 * 72 * 0.1, after: 40 * 72 * 0.1 },
            indent: {
              left: 720,
            },
            rightTabStop: TabStopPosition.MAX,
            leftTabStop: 453.543307087,
          },
        },
        {
          id: 'tableContent',
          name: 'Table Content',
          basedOn: 'Normal',
          next: 'Normal',
          quickFormat: true,
          run: {
            font: 'Arial',
            size: 20,
          },
          paragraph: {
            spacing: { line: 276, before: 20 * 72 * 0.1, after: 20 * 72 * 0.1 },
            rightTabStop: TabStopPosition.MAX,
            leftTabStop: 453.543307087,
          },
        },
        {
          id: 'highLight',
          name: 'HighLight',
          basedOn: 'Normal',
          next: 'Normal',
          quickFormat: true,
          run: {
            font: 'Arial',
            size: 22,
            bold: false,
            fill: 'FFD966',
            color: 'FFD966',
          },
          paragraph: {
            alignment: AlignmentType.JUSTIFIED,
            spacing: { line: 276, before: 20 * 72 * 0.1, after: 40 * 72 * 0.1 },
            rightTabStop: TabStopPosition.MAX,
            leftTabStop: 453.543307087,
          },
        },
        {
          id: 'normalPara2',
          name: 'Normal Para2',
          basedOn: 'Normal',
          next: 'Normal',
          quickFormat: true,
          run: {
            font: 'Arial',
            size: 22,
          },
          paragraph: {
            alignment: AlignmentType.JUSTIFIED,
            spacing: {
              line: 276,
              before: 20 * 72 * 0.1,
              after: 20 * 72 * 0.05,
            },
          },
        },
        {
          id: 'aside',
          name: 'Aside',
          basedOn: 'Normal',
          next: 'Normal',
          run: {
            color: '999999',
            italics: true,
          },
          paragraph: {
            spacing: { line: 276 },
            indent: { left: convertInchesToTwip(0.5) },
          },
        },
        {
          id: 'wellSpaced',
          name: 'Well Spaced',
          basedOn: 'Normal',
          paragraph: {
            spacing: {
              line: 276,
              before: 20 * 72 * 0.1,
              after: 20 * 72 * 0.05,
            },
          },
        },
        {
          id: 'numberedAgreement',
          name: 'Numbered Agreement',
          basedOn: 'Normal',
          next: 'Normal',
          quickFormat: true,
          run: {
            font: 'Arial',
            size: 20,
          },
          paragraph: {
            spacing: {
              line: 276,
              before: 20 * 72 * 0.1,
              after: 20 * 72 * 0.05,
            },
            rightTabStop: TabStopPosition.MAX,
            leftTabStop: 453.543307087,
            numbering: {
              reference: 'ref1',
              instance: 0,
              level: 0,
            },
          },
        },
        {
          id: 'numberedPara',
          name: 'Numbered Para',
          basedOn: 'Normal',
          next: 'Normal',
          quickFormat: true,
          run: {
            font: 'Arial',
            size: 22,
          },
          paragraph: {
            spacing: {
              line: 276,
              before: 20 * 72 * 0.1,
              after: 20 * 72 * 0.05,
            },
            rightTabStop: TabStopPosition.MAX,
            leftTabStop: 453.543307087,
            numbering: {
              reference: 'ref1',
              instance: 0,
              level: 0,
            },
          },
        },
      ],
    },
    sections: [
      {
        properties: {
          page: {
            margin: {
              top: 400,
              right: 1400,
              bottom: 700,
              left: 1400,
            },
          },
        },
        headers: {
          default: new Header({
            children: [
              new Paragraph({
                children: [
                  new ImageRun({
                    data: Buffer.from(imageBase64Data, 'base64'),
                    transformation: {
                      width: 100,
                      height: 100,
                    },
                  }),
                ],
                alignment: AlignmentType.CENTER,
              }),
            ],
          }),
        },
        footers: {
          default: new Footer({
            children: [
              new Paragraph({
                style: 'normalPara',
                alignment: AlignmentType.RIGHT,
                children: [
                  new TextRun({
                    children: [PageNumber.CURRENT],
                  }),
                ],
              }),
            ],
          }),
        },
        children: [
          breakLine,

          new Paragraph({
            text: 'CERTIFICACIÓN DE ACTA DE JUNTA GENERAL',
            heading: HeadingLevel.HEADING_1,
            alignment: AlignmentType.CENTER,
          }),

          new Paragraph({
            text: `DE LA SOCIEDAD ${name || '[DENOMINACIÓN SOCIAL]'}`,
            heading: HeadingLevel.HEADING_1,
            alignment: AlignmentType.CENTER,
          }),

          breakLine,

          new Paragraph({
            children: [
              new TextRun({
                text: '[Nombre y Apellidos]',
                highlight: 'yellow',
              }),
              new TextRun({
                text: `, en su condición de Secretario del Órgano de Administración de la entidad ${
                  name || '[DENOMINACIÓN SOCIAL]'
                } (en adelante,`,
              }),
              new TextRun({ text: '“la Sociedad”', bold: true }),
              new TextRun({
                text: `) con C.I.F. ${cif || '[CIF]'} y domicilio en ${
                  line1 || '[dirección]'
                }, ${zip || '[código postal]'} - ${
                  city || '[ciudad]'
                }, con el visto bueno del Presidente del Órgano de Administración,`,
              }),
              new TextRun({
                text: '[Nombre y Apellidos]',
                highlight: 'yellow',
              }),
            ],
            style: 'normalPara',
          }),

          breakLine,

          new Paragraph({
            text: 'CERTIFICA',
            heading: HeadingLevel.HEADING_2,
            alignment: AlignmentType.CENTER,
          }),

          new Paragraph({
            children: [
              new TextRun({
                text: 'Que, entre las Actas de la Sociedad, figura la que, transcrita en su parte necesaria, dice así:',
              }),
            ],
            style: 'normalPara',
          }),

          new Paragraph({
            children: [
              new TextRun({
                text: 'Que en fecha ',
              }),
              new TextRun({
                text: '[día]',
                highlight: 'yellow',
              }),
              new TextRun({
                text: ' de ',
              }),
              new TextRun({
                text: '[mes]',
                highlight: 'yellow',
              }),
              new TextRun({
                text: ' de ',
              }),
              new TextRun({
                text: '[año]',
                highlight: 'yellow',
              }),
              new TextRun({
                text: `y en el domicilio social de la entidad sito en ${
                  line1 || '[dirección]'
                }, ${zip || '[código postal]'} - ${
                  city || '[ciudad]'
                }, se celebró su Junta General de Socios. Todos los socios fueron convocados a la Junta General de Socios mediante correo electrónico, con acuse de recibo, enviado en fecha `,
              }),
              new TextRun({
                text: '[día]',
                highlight: 'yellow',
              }),
              new TextRun({
                text: ' de ',
              }),
              new TextRun({
                text: '[mes]',
                highlight: 'yellow',
              }),
              new TextRun({
                text: ' de ',
              }),
              new TextRun({
                text: '[año]',
                highlight: 'yellow',
              }),
              new TextRun({
                text: ', tal y como establecen los Estatutos Legales en su artículo ',
              }),
              new TextRun({
                text: '[número]',
                highlight: 'yellow',
              }),
              new TextRun({
                text: 'º, cuyo texto literal era el siguiente:',
              }),
            ],
            style: 'numberedPara',
          }),

          breakLine,

          new Paragraph({
            children: [
              new TextRun({
                text: 'CONVOCATORIA JUNTA GENERAL DE SOCIOS DE LA SOCIEDAD [DENOMINACIÓN SOCIAL]',
              }),
            ],
            style: 'secondaryHeading',
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: `Por acuerdo del Órgano de Administración de la Sociedad ${
                  name || '[NOMBRE SOCIEDAD]'
                } (en adelante, “la Sociedad”), se convoca a los socios de la misma a la Junta General de Socios que se celebrará el próximo día `,
              }),
              new TextRun({
                text: '[día]',
                highlight: 'yellow',
              }),
              new TextRun({
                text: ' de ',
              }),
              new TextRun({
                text: '[mes]',
                highlight: 'yellow',
              }),
              new TextRun({
                text: ' de ',
              }),
              new TextRun({
                text: '[año] ',
                highlight: 'yellow',
              }),
              new TextRun({
                text: ' a las ',
              }),
              new TextRun({
                text: '[hora:minutos] ',
                highlight: 'yellow',
              }),
              new TextRun({
                text: `horas y que tendrá lugar en el domicilio social de la Sociedad, sito en ${
                  line1 || '[dirección]'
                }, ${zip || '[código postal]'} - ${
                  city || '[ciudad]'
                }. La asistencia a la Junta General de Socios podrá realizarse acudiendo presencialmente al domicilio social de la Sociedad anteriormente referenciado `,
              }),
              new TextRun({
                text: '[OPCIONAL]: o bien por medios telemáticos a través de sistemas de videoconferencia, de conformidad con lo previsto en los Estatutos de la Sociedad]',
                highlight: 'yellow',
              }),
              new TextRun({
                text: '. En la Junta General de Socios será objeto de debate y votación el siguiente',
              }),
            ],
            style: 'secondaryPara',
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: 'ORDEN DEL DÍA',
              }),
            ],
            style: 'secondaryHeading',
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: '1. ',
              }),
              new TextRun({
                text: '[Primer tema a tratar en la Junta]',
                highlight: 'yellow',
              }),
            ],
            style: 'secondaryPara',
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: '2. ',
              }),
              new TextRun({
                text: '[Segundo tema a tratar en la Junta]',
                highlight: 'yellow',
              }),
            ],
            style: 'secondaryPara',
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: '3. ',
              }),
              new TextRun({
                text: '[Tercer tema a tratar en la Junta]',
                highlight: 'yellow',
              }),
            ],
            style: 'secondaryPara',
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: '4. Ruegos y preguntas.',
              }),
            ],
            style: 'secondaryPara',
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: '5. Confección, lectura y aprobación, si procede, del Acta de la reunión.',
              }),
            ],
            style: 'secondaryPara',
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: 'Todos los documentos y cuentas que han de ser sometidos a la aprobación de la Junta General de Socios se encuentran a disposición de los Señores Socios en el domicilio social, donde podrán ser examinados y obtener copia de los mismos de forma gratuita. Asimismo, se recuerda a los socios que también pueden solicitar el envío de la documentación por correo electrónico o por correo certificado de manera inmediata y gratuita.',
              }),
            ],
            style: 'secondaryPara',
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: '[OPCIONAL]: Finalmente, se recuerda a los Socios que, en el correo electrónico por el cual se hace llegar esta convocatoria figuran los medios y canales a través de los cuales los socios podrán conectarse y hacer el correcto seguimiento de la celebración de la Junta General de Socios, en el día y hora señalados].',
                highlight: 'yellow',
              }),
            ],
            style: 'secondaryPara',
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: `En ${city || '[Ciudad]'}, a `,
              }),
              new TextRun({
                text: '[día] ',
                highlight: 'yellow',
              }),
              new TextRun({
                text: 'de ',
              }),
              new TextRun({
                text: '[mes] ',
                highlight: 'yellow',
              }),
              new TextRun({
                text: 'de ',
              }),
              new TextRun({
                text: '[año]',
                highlight: 'yellow',
              }),
              new TextRun({
                text: '.',
              }),
            ],
            style: 'secondaryPara',
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: '[Nombre y Apellidos]',
                highlight: 'yellow',
              }),
              new TextRun({
                text: '\t[Nombre y Apellidos]',
                highlight: 'yellow',
              }),
            ],
            tabStops: [
              {
                type: TabStopType.RIGHT,
                position: TabStopPosition.MAX,
              },
            ],
            style: 'secondaryPara',
          }),
          new Paragraph({
            children: [new TextRun('Presidente'), new TextRun('\tSecretario')],
            tabStops: [
              {
                type: TabStopType.RIGHT,
                position: TabStopPosition.MAX,
              },
            ],
            style: 'secondaryPara',
          }),

          new Paragraph({
            children: [
              new TextRun({
                text: 'Que se aceptó por unanimidad el “Orden del Día” que figuraba en la convocatoria transcrita.',
              }),
            ],
            style: 'numberedPara',
          }),

          breakLine,

          new Paragraph({
            children: [
              new TextRun({
                text: 'Que en el acta figura el nombre y la firma de los asistentes, por sí o debidamente representados, quienes al finalizar la reunión aprobaron el acta por unanimidad, la cual fue firmada por el Presidente y el Secretario de la Junta General de Socios, siendo los socios presentes y/o representados:',
              }),
            ],
            style: 'numberedPara',
          }),

          breakLine,

          new Table({
            columnWidths: [3000, 4000, 2010],
            rows: [
              new TableRow({
                children: [
                  new TableCell({
                    width: {
                      size: 3000,
                      type: WidthType.DXA,
                    },
                    children: [
                      new Paragraph({
                        text: 'NOMBRE DEL SOCIO',
                        heading: HeadingLevel.HEADING_4,
                        alignment: AlignmentType.CENTER,
                      }),
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                  }),
                  new TableCell({
                    width: {
                      size: 4000,
                      type: WidthType.DXA,
                    },
                    children: [
                      new Paragraph({
                        text: 'REPRESENTANTE',
                        heading: HeadingLevel.HEADING_4,
                        alignment: AlignmentType.CENTER,
                      }),
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                  }),
                  new TableCell({
                    width: {
                      size: 2010,
                      type: WidthType.DXA,
                    },
                    children: [
                      new Paragraph({
                        text: '% PARTICIPACIÓN',
                        heading: HeadingLevel.HEADING_4,
                        alignment: AlignmentType.CENTER,
                      }),
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                  }),
                ],
              }),

              ...assistantRows,

              new TableRow({
                children: [
                  new TableCell({
                    width: {
                      size: 3000,
                      type: WidthType.DXA,
                    },
                    children: [
                      new Paragraph({
                        text: 'TOTAL CAPITAL PRESENTE',
                        heading: HeadingLevel.HEADING_4,
                        alignment: AlignmentType.CENTER,
                      }),
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                  }),
                  new TableCell({
                    width: {
                      size: 4000,
                      type: WidthType.DXA,
                    },
                    children: [
                      new Paragraph({
                        text: '',
                        heading: HeadingLevel.HEADING_4,
                        alignment: AlignmentType.CENTER,
                      }),
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                  }),
                  new TableCell({
                    width: {
                      size: 2010,
                      type: WidthType.DXA,
                    },
                    children: [
                      new Paragraph({
                        text: `${totalND}%`,
                        heading: HeadingLevel.HEADING_4,
                        alignment: AlignmentType.CENTER,
                      }),
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                  }),
                ],
              }),
            ],
          }),

          breakLine,

          new Paragraph({
            children: [
              new TextRun({
                text: 'Que fueron adoptados los siguientes acuerdos:',
              }),
            ],
            style: 'numberedPara',
          }),

          breakLine,

          new Paragraph({
            children: [
              new TextRun({
                text: '1. ',
              }),
              new TextRun({
                text: '[Primer tema a tratar en la Junta].',
                highlight: 'yellow',
              }),
            ],
            style: 'secondaryPara',
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: '    [Opción 1: Aprobación por unanimidad]: Sometido a votación se aprueba por unanimidad.',
              }),
            ],
            style: 'secondaryPara',
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: '    [Opción 2: Existencia de votos en contra]: Sometido a votación se aprueba con los votos favorables correspondientes al ',
              }),
              new TextRun({
                text: '[porcentaje]',
                highlight: 'yellow',
              }),
              new TextRun({
                text: '% de las participaciones en que se divide el capital social. Voto en contra de ',
              }),
              new TextRun({
                text: '[Nombre y Apellidos]',
                highlight: 'yellow',
              }),
              new TextRun({
                text: '(',
              }),
              new TextRun({
                text: '[porcentaje]',
                highlight: 'yellow',
              }),
              new TextRun({
                text: '% del capital social).',
              }),
            ],
            style: 'secondaryPara',
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: '2. ',
              }),
              new TextRun({
                text: '[Segundo tema a tratar en la Junta].',
                highlight: 'yellow',
              }),
            ],
            style: 'secondaryPara',
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: '    [Opción 1: Aprobación por unanimidad]: Sometido a votación se aprueba por unanimidad.',
              }),
            ],
            style: 'secondaryPara',
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: '    [Opción 2: Existencia de votos en contra]: Sometido a votación se aprueba con los votos favorables correspondientes al ',
              }),
              new TextRun({
                text: '[porcentaje]',
                highlight: 'yellow',
              }),
              new TextRun({
                text: '% de las participaciones en que se divide el capital social. Voto en contra de ',
              }),
              new TextRun({
                text: '[Nombre y Apellidos]',
                highlight: 'yellow',
              }),
              new TextRun({
                text: '(',
              }),
              new TextRun({
                text: '[porcentaje]',
                highlight: 'yellow',
              }),
              new TextRun({
                text: '% del capital social).',
              }),
            ],
            style: 'secondaryPara',
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: '3. ',
              }),
              new TextRun({
                text: '[Tercer tema a tratar en la Junta].',
                highlight: 'yellow',
              }),
            ],
            style: 'secondaryPara',
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: '    [Opción 1: Aprobación por unanimidad]: Sometido a votación se aprueba por unanimidad.',
              }),
            ],
            style: 'secondaryPara',
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: '    [Opción 2: Existencia de votos en contra]: Sometido a votación se aprueba con los votos favorables correspondientes al ',
              }),
              new TextRun({
                text: '[porcentaje]',
                highlight: 'yellow',
              }),
              new TextRun({
                text: '% de las participaciones en que se divide el capital social. Voto en contra de ',
              }),
              new TextRun({
                text: '[Nombre y Apellidos]',
                highlight: 'yellow',
              }),
              new TextRun({
                text: '(',
              }),
              new TextRun({
                text: '[porcentaje]',
                highlight: 'yellow',
              }),
              new TextRun({
                text: '% del capital social).',
              }),
            ],
            style: 'secondaryPara',
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: '4. Y no habiendo más asuntos que tratar, se levanta la sesión, extendiéndose la presente acta que, leída y aprobada por unanimidad de todos los asistentes, es firmada por el Secretario con el visto bueno del Presidente.',
              }),
            ],
            style: 'secondaryPara',
          }),

          new Paragraph({
            children: [
              new TextRun({
                text: 'Que al finalizar la reunión, la Junta General de Socios aprobó el Acta, que fue debidamente firmada por el Secretario de la Junta con el Visto Bueno del Presidente.',
              }),
            ],
            style: 'numberedPara',
          }),

          breakLine,

          new Paragraph({
            children: [
              new TextRun({
                text: 'Igualmente hace constar que el Acta de la reunión de la Junta General de Socios de la Sociedad a que la presente certificación hace referencia, fue confeccionada al término de la reunión, siendo aprobada de forma unánime y firmada por todos los asistentes.',
              }),
            ],
            style: 'numberedPara',
          }),

          breakLine,

          new Paragraph({
            children: [
              new TextRun({
                text: `Y para que así conste, expido la presente certificación en ${
                  city || '[Ciudad]'
                }, a `,
              }),
              new TextRun({
                text: '[día] ',
                highlight: 'yellow',
              }),
              new TextRun({
                text: 'de ',
              }),
              new TextRun({
                text: '[mes] ',
                highlight: 'yellow',
              }),
              new TextRun({
                text: 'de ',
              }),
              new TextRun({
                text: '[año]',
                highlight: 'yellow',
              }),
              new TextRun({
                text: '.',
              }),
            ],
            style: 'normalPara',
          }),

          breakLine,

          new Paragraph({
            children: [
              new TextRun({
                text: `Fdo.: `,
              }),
            ],
            style: 'normalPara',
          }),

          breakLine,
          breakLine,
          breakLine,

          new Paragraph({
            children: [
              new TextRun('EL SECRETARIO'),
              new TextRun('\tVº Bº DEL PRESIDENTE'),
            ],
            tabStops: [
              {
                type: TabStopType.RIGHT,
                position: TabStopPosition.MAX,
              },
            ],
            style: 'normalPara',
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: '[Nombre y Apellidos]',
                highlight: 'yellow',
              }),
              new TextRun({
                text: '\t[Nombre y Apellidos]',
                highlight: 'yellow',
              }),
            ],
            tabStops: [
              {
                type: TabStopType.RIGHT,
                position: TabStopPosition.MAX,
              },
            ],
            style: 'normalPara2',
          }),
        ],
      },
    ],
  });

  return doc;
}

export default ActaJuntaAprobacionCuentas;
