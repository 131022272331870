import React from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

import { dateFormat } from 'constants/formats';

import MenuDots from 'components/MenuDots';
import userTypes from 'constants/userTypes';
import { useSelector } from 'react-redux';
import menuOptions from './menuOptions';

function SocietyAdministratorRow({
  administrator,
  society,
  user,
  isAdmin,
  index,
}) {
  const { t } = useTranslation();

  const isDemo = useSelector((state) => state.society?.role?.isDemo);

  const creationDate = format(
    administrator?.creationDate && new Date(administrator?.creationDate),
    dateFormat
  );
  const lastAccess = format(
    administrator?.lastAccess && new Date(administrator?.lastAccess),
    dateFormat
  );

  const filterOptions = menuOptions.map((option, index) => {
    if (index === 0) {
      return user?.['_id'] === administrator?.['_id']
        ? option
        : { ...option, disabled: true };
    }

    return !society?.administrators.includes(user?.['_id']) ||
      administrator?.['_id'] === society?.mainAdmin
      ? { ...option, disabled: true }
      : option;
  });

  return (
    <tr className="nk-tb-item">
      <td className="nk-tb-col tb-col-xl">
        <span>{index + 1}</span>
      </td>

      <td className="nk-tb-col">
        <span>{administrator.name || ''}</span>
      </td>

      <td className="nk-tb-col tb-col-xl">
        <span>{creationDate}</span>
      </td>

      <td className="nk-tb-col tb-col-xl">
        <span>{lastAccess}</span>
      </td>

      <td className="nk-tb-col tb-col-xl">
        <span>{administrator.email}</span>
      </td>
      <td className="nk-tb-col">
        <span className="badge badge-dot badge-danger">{t('Full')}</span>
      </td>

      {isAdmin && !isDemo && (
        <td className="nk-tb-col  nk-tb-col-tools">
          <MenuDots
            menuOptions={filterOptions}
            id={administrator['_id']}
            params={{
              data: administrator,
              userType: userTypes.USER,
              societyId: society?.['_id'],
            }}
          />
        </td>
      )}
    </tr>
  );
}

export default SocietyAdministratorRow;
