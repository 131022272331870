import { updateTenderOffer } from 'redux/actions/tenderOfferActions';
import store from 'redux/store';
import { TenderOfferParticipant } from 'types';

type Participant = TenderOfferParticipant & {
  tenderOfferId: string;
};

const updateTenderParticipants = async (participants: Participant[]) => {
  try {
    await Promise.all(
      participants.map((participant: Participant) =>
        store.dispatch(
          updateTenderOffer(participant.tenderOfferId, {
            participantId: participant.id,
            participantData: { removedFromPlan: true },
            updateBeneficiary: false,
          })
        )
      )
    );
  } catch (error) {
    console.error(error);
  }
};

export default updateTenderParticipants;
