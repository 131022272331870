/* eslint-disable prefer-template */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable-next-line react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { setMenu } from 'redux/actions/menuActions';
import { setModal } from 'redux/actions/modalActions';
import { getStockPlans } from 'redux/actions/planActions';
import { useTranslation } from 'react-i18next';

import menuTypes from 'constants/menuTypes';
import invitationStatus from 'constants/invitationStatus';
import beneficiaryStatus from 'constants/beneficiaryStatus';
import operationTypesRealNames from 'constants/operationTypesRealNames';

import CustomLoading from 'components/CustomLoading';
import transformData from 'components/Tables/helpers';
import BeneficiaryRow from 'components/BeneficiaryRow';
import CustomDataTable from 'components/Tables/CustomDataTable';
import UploadBeneficiary from 'components/Modals/UploadBeneficiary';
import OperationMenuDots from 'components/MenuDots/OperationMenuDots';

import {
  calculateConsolidationPercent,
  getActualSociety,
  getBeneficiaryAmount,
  getBeneficiaryFDPercent,
  getDecimalScale,
  getSocietyValue,
} from 'utils/filters';

import menuOptions from './menuOptions';
import tableColumns from './tableColumns';
import beneficiaryOptions from './beneficiaryOptions';

function BeneficiaryDrafts() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { societyId, planId } = useParams();
  const user = useSelector((state) => state.user);
  const isAdmin = useSelector((state) => state.society?.role?.isAdmin);
  const actualSociety = useSelector((state) => state.society?.actualSociety);
  const plans = useSelector((state) => state.plans);
  const isDemo = useSelector((state) => state.society?.role?.isDemo);

  const [societyShares, setSocietyShares] = useState();
  const [rows, setRows] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [currentTableColumns, setCurrentTableColumns] = useState(
    tableColumns(i18n)
  );
  const [currentMenuOptions, setCurrentMenuOptions] = useState(
    menuOptions(i18n)
  );
  const [currentBeneficiaryOptions, setCurrentBeneficiaryOptions] = useState(
    beneficiaryOptions(i18n)
  );

  const getPlanName = (planId) => {
    const plan = plans?.find((plan) => plan['_id'] === planId);
    return plan ? plan.name : '-';
  };

  const getIncentive = (planId) => {
    const plan = plans?.find((plan) => plan['_id'] === planId);
    return plan ? operationTypesRealNames[plan.planType] : '-';
  };

  const getUnits = (beneficiary) => {
    const units = beneficiary?.isCanceled
      ? beneficiary?.finalConsolidatedUnits
      : beneficiary?.sharesCount?.future;
    return units;
  };

  const getConsolidatedPercent = (beneficiary, planId) => {
    let percent;
    const plan = plans?.find((plan) => plan['_id'] === planId);

    if (beneficiary?.isCanceled)
      percent = Number(
        (
          (beneficiary.finalConsolidatedUnits * 100) /
          beneficiary.sharesCount.future
        ).toFixed(2)
      );
    else percent = calculateConsolidationPercent(Date.now(), beneficiary, plan);

    return percent;
  };

  const getConsolidationDate = (beneficiary, planId) => {
    const plan = plans?.find((plan) => plan['_id'] === planId);
    const endDate = new Date(beneficiary?.planStartDate);
    endDate.setMonth(endDate.getMonth() + Number(plan?.vestingPeriod));
    return endDate;
  };

  const getCertificateData = (beneficiary, planId) => {
    const plan = plans?.find((plan) => plan['_id'] === planId);

    const beneficiaryData = {
      ...beneficiary,
      sharesCount: beneficiary.sharesCount,
      amount: getBeneficiaryAmount(beneficiary, plan),
      cif: beneficiary.cif,
    };
    const planData = {
      sharePrice: plan?.sharePrice,
      startDate: plan?.startDate,
      planId,
    };
    const societyData = {
      name: actualSociety?.name,
      cif: actualSociety?.cif,
      administrator:
        actualSociety?.mainAdmin || actualSociety?.administrators?.[0],
      societyId,
    };

    return { societyData, beneficiaryData, planData };
  };

  const getStatus = (beneficiary, planId) => {
    const plan = plans?.find((plan) => plan['_id'] === planId);
    let percent;
    if (!beneficiary?.isCanceled) {
      percent = calculateConsolidationPercent(Date.now(), beneficiary, plan);
    }
    let currentStatus;
    if (beneficiary?.isCanceled) {
      if (beneficiary?.cancelationOption === 'CONSOLIDATED') {
        currentStatus = beneficiaryStatus.var.CONSOLIDATED;
      } else {
        currentStatus = beneficiaryStatus.var.CANCELED;
      }
    } else if (percent >= 0 && percent < 100)
      currentStatus = beneficiaryStatus.var.ACTIVE;
    else currentStatus = beneficiaryStatus.var.CONSOLIDATED;
    return currentStatus;
  };

  const filterOptions = (beneficiary) => {
    const options = currentBeneficiaryOptions.map((option) => ({ ...option }));
    options[0].url = `/detalle-beneficiario/${societyId}/${beneficiary?.plan}`;
    if (!isAdmin) {
      for (let i = 0; i < options.length; i += 1) {
        options[i].disabled = true;
      }
    }
    return options;
  };

  useEffect(() => {
    dispatch(getStockPlans(societyId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [societyId]);

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId) {
      getActualSociety(user, societyId);
    }
  }, [actualSociety, user, societyId]);

  useEffect(() => {
    if (actualSociety && plans?.length) {
      const totalShares =
        actualSociety.sharesCount?.actual +
        actualSociety.sharesCount?.future +
        actualSociety?.sharesCount?.drafts;

      setSocietyShares(totalShares);
      const societyValue = getSocietyValue(actualSociety);
      const decimalScale = societyValue
        ? getDecimalScale(societyValue.value)
        : 2;

      const filteredBeneficiaries = actualSociety?.beneficiaries?.filter(
        (beneficiary) =>
          !isAdmin
            ? user['_id'] === beneficiary?.user
            : beneficiary?.isDraft === true
      );

      const filteredBeneficiariesByPlan = filteredBeneficiaries?.filter(
        (beneficiary) => beneficiary?.plan === planId
      );

      const beneficiariesTable = planId
        ? filteredBeneficiariesByPlan
        : filteredBeneficiaries;

      setRows(
        beneficiariesTable?.map((beneficiary, index) => {
          const plan = plans?.find((plan) => plan['_id'] === beneficiary?.plan);
          return (
            <BeneficiaryRow
              beneficiary={beneficiary}
              sharesCount={beneficiary?.sharesCount}
              society={actualSociety}
              societyId={actualSociety['_id']}
              societyShares={totalShares}
              decimals={decimalScale}
              amount={getBeneficiaryAmount(beneficiary, plan)}
              index={index + 1}
              key={beneficiary['_id']}
              planId={beneficiary?.plan}
              userId={user['_id']}
              documentURL={beneficiary?.documentURL}
              invitationStatus={beneficiary?.invitationStatus}
            />
          );
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    actualSociety,
    societyShares,
    user,
    societyId,
    plans,
    planId,
    i18n.language,
  ]);

  useEffect(() => {
    if (actualSociety) {
      dispatch(
        setMenu({
          type: menuTypes.EXTENDED,
          societyId: actualSociety?.['_id'],
          societyName: actualSociety?.name,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety]);

  useEffect(() => {
    const newData = rows.map(({ props }) => ({
      beneficiary: props.beneficiary,
      name: props.beneficiary?.name,
      status: getStatus(props.beneficiary, props.beneficiary?.plan),
      planId: props.beneficiary?.plan,
      societyId: actualSociety['_id'],
      units: getUnits(props.beneficiary),
      decimals: props.decimals,
      amount: props.amount,
      FDpercent: getBeneficiaryFDPercent(props.beneficiary, props.society),
      jobTitle: props.beneficiary?.jobTitle || '-',
      incentive: getIncentive(props.beneficiary?.plan),
      planName: getPlanName(props.beneficiary?.plan),
      consolidatedPercent: getConsolidatedPercent(
        props.beneficiary,
        props.beneficiary?.plan
      ),
      consolidationDate: getConsolidationDate(
        props.beneficiary,
        props.beneficiary?.plan
      ),
      certificateData: getCertificateData(
        props.beneficiary,
        props.beneficiary?.plan
      ),
      documentURL: props.beneficiary?.documentURL,
      beneficiaryOptions: filterOptions(props.beneficiary),
      invitationStatus: invitationStatus[props.beneficiary?.invitationStatus],
      isDraft: props.beneficiary?.isDraft,
      isDemo,
    }));

    if (newData.length > 0) {
      setTableData(
        transformData({
          data: newData,
          columns: tableColumns(i18n),
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  useEffect(() => {
    setCurrentTableColumns(tableColumns(i18n));
    setCurrentBeneficiaryOptions(beneficiaryOptions(i18n));
    setCurrentMenuOptions(menuOptions(i18n));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return actualSociety ? (
    <>
      <div className="nk-content-body">
        <div className="nk-content-wrap">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">{actualSociety.name}</h3>
          </div>
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title"> </h3>
          </div>
          <div className="nk-block-head">
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <h3 className="nk-block-title page-title">
                  {t('BeneficiariesDrafts')}
                  {planId && (
                    <>
                      &nbsp;/&nbsp;
                      <strong className="text-primary small">
                        {getPlanName(planId) || ''}
                      </strong>
                    </>
                  )}
                </h3>
                <div className="nk-block-des text-soft">
                  <p>
                    {`${planId ? getPlanName(planId) : actualSociety?.name} ${t(
                      'HaveBeneficiaries',
                      {
                        count: rows.length,
                      }
                    )}`}
                  </p>
                </div>
              </div>

              <div className="nk-block-head-content">
                <div className="toggle-wrap nk-block-tools-toggle">
                  <a
                    className="btn btn-icon btn-trigger toggle-expand mr-n1"
                    data-target="pageMenu"
                    aria-label="link"
                  >
                    <em className="icon ni ni-menu-alt-r" />
                  </a>
                  <div
                    className="toggle-expand-content"
                    data-content="pageMenu"
                  >
                    <ul className="nk-block-tools g-3">
                      {isAdmin && (
                        <li>
                          <button
                            type="button"
                            className="btn btn-white btn-outline-light"
                            onClick={() =>
                              dispatch(
                                setModal(
                                  <UploadBeneficiary
                                    user={user}
                                    societyId={actualSociety['_id']}
                                    isDraft
                                  />
                                )
                              )
                            }
                            disabled={isDemo}
                          >
                            <em className="icon ni ni-upload-cloud" />
                            <span>{t('UploadListing')}</span>
                          </button>
                        </li>
                      )}
                      {/* {isAdmin && (
                        <ReactHtmlTableToExcel
                          id="custom-table-xls-button"
                          className="btn btn-white btn-outline-light mr-1"
                          table="draft-table"
                          filename={`${t('BeneficiariesDraftsFilename')} ${
                            actualSociety?.name
                          }`}
                          sheet={t('BeneficiariesDraftsFilename')}
                          buttonText={`${t('DownloadXLS')}`}
                        />
                      )} */}
                      {isAdmin && (
                        <li>
                          <OperationMenuDots
                            menuOptions={currentMenuOptions}
                            handleModal={(Modal) =>
                              dispatch(
                                setModal(
                                  <Modal
                                    user={user}
                                    societyId={actualSociety['_id']}
                                  />
                                )
                              )
                            }
                            disabled={isDemo}
                          />
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {tableData.length > 0 && tableData.length === rows.length ? (
            <CustomDataTable
              data={tableData}
              columns={currentTableColumns}
              searchBy={t('Beneficiary')}
              pagination
              className="nk-tb-list"
              // expandableRows
              actions
              showDense
              showDownload
              fileName={`${t('BeneficiariesDraftsFilename')} ${
                actualSociety.name
              }`}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  ) : (
    <CustomLoading />
  );
}

export default BeneficiaryDrafts;
