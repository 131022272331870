import React from 'react';
import store from 'redux/store';
import ChangeNcStatus from 'components/Modals/ChangeNcStatus';
import { setModal } from 'redux/actions/modalActions';

export default function updateNcStatus(draft) {

  store.dispatch(
    setModal(
      <ChangeNcStatus draft={draft} />
    )
  );
}