/* eslint-disable no-nested-ternary */
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Select, { ControlProps, CSSObjectWithLabel } from 'react-select';
import NumberFormat from 'react-number-format';
import makeAnimated from 'react-select/animated';
import Record from 'typescript';

import { cleanValue, numberFormat } from 'constants/formats';

import partnersSources from './constants/partnersSources';
import getPartnersFromSocieties from './utils/getPartnersFromSocieties';
import AddNewClass from './AddNewClass';

import Step1b from '../../../assets/images/wizard-1b.svg';
import Step1c from '../../../assets/images/wizard-1c.svg';
import Step1d from '../../../assets/images/wizard-1d.svg';

interface StepProps {
  data: Record<string, any>;
  setData: (data: any) => void;
  setCompletedSteps: (data: any) => void;
  setActiveStep: (data: any) => void;
}

const Step2: FC<StepProps> = ({
  data,
  setData,
  setCompletedSteps,
  setActiveStep,
}) => {
  const { t } = useTranslation();
  const user = useSelector((state: any) => state.user);

  const [partnerSource, setPartnerSource] = useState<string>('');
  const [partners, setPartners] = useState<Record<string, any>[]>([]);

  const [holdingClasses, setHoldingClasses] = useState<Record<string, any>[]>([
    {
      id: 0,
      name: t('Ordinary'),
      numerationOption: null,
    },
  ]);
  const [editSelectedPartners, setEditSelectedPartners] =
    useState<boolean>(false);
  const [createClassInsite, setCreateClassInsite] = useState<boolean>(false);
  const [manualPartners, setManualPartners] = useState<Record<string, any>[]>([
    {
      id: 0,
      name: '',
      cif: '',
      email: '',
      shares: '',
      shareFrom: '',
      shareTo: '',
      holdingClass: {
        id: 0,
        name: t('Ordinary'),
        numerationOption: null,
      },
    },
  ]);
  const animatedComponents = makeAnimated();

  const [date, setDate] = useState<string>('');
  const [nominalValue, setNominalValue] = useState<number>(0);

  const [lastKeyPressed, setLastKeyPressed] = useState(null);

  const isValidData = (
    data: Record<string, any>,
    partners: Record<string, any>[]
  ) => {
    if (!data?.date || !+data?.nominalValue) return false;
    return partners?.some(
      (partner) => partner.cif && partner.name && +partner.shares
    );
  };

  const validateData = (): boolean => {
    if (partnerSource === partnersSources.MANUAL) {
      return isValidData(data, manualPartners);
    }
    if (partnerSource === partnersSources.SOCIETIES) {
      if (editSelectedPartners) {
        return isValidData(data, manualPartners);
      }
      return true;
    }
    return false;
  };

  const calculateNumerations = (
    partners: Record<string, any>[]
  ): Record<string, any>[] => {
    const initial = holdingClasses?.reduce(
      (acc, currentClass) => {
        acc.from[currentClass.id] = 1;
        acc.refs[currentClass.id] =
          currentClass.numerationOption || currentClass.id;
        return acc;
      },
      {
        from: {},
        refs: {},
      }
    );
    const finalRefs = Object.entries(initial.refs).reduce(
      (acc: any, [id, ref]: any) => {
        acc[id] = acc[initial.refs[ref]] || initial.refs[ref];
        return acc;
      },
      {}
    );
    const actualFrom = initial.from;

    const result = partners?.map((partner) => {
      let shareFrom: any = '';
      let shareTo: any = '';
      if (partner.shares && partner.holdingClass) {
        const classId = partner.holdingClass.id;
        shareFrom = actualFrom[finalRefs[classId]];
        shareTo = actualFrom[finalRefs[classId]] + +partner.shares - 1;
        actualFrom[finalRefs[classId]] += +partner.shares;
      }
      return { ...partner, shareFrom, shareTo };
    });
    return result;
  };
  const handleClassOptionChange = (
    selectedOption: any,
    index: number
  ): void => {
    const newManualPartners = [...manualPartners];
    const currentClass = holdingClasses?.find(
      (holdingClass) => holdingClass?.id === selectedOption.value
    );
    newManualPartners[index]['holdingClass'] = currentClass;
    const updatedManualPartners = calculateNumerations(newManualPartners);
    setManualPartners(updatedManualPartners);
    setData((prevData: any) => ({
      ...prevData,
      partners: updatedManualPartners,
    }));
  };

  const handleKeyDown = (event: any) => {
    // Set the last key pressed
    setLastKeyPressed(event.keyCode);
  };

  const handleBlur = () => {
    if (lastKeyPressed === 9) {
      // add a new element to manualPartners
      setManualPartners((prevManualPartners) => [
        ...prevManualPartners,
        {
          id: prevManualPartners.length,
          name: '',
          cif: '',
          email: '',
          shares: '',
          holdingClass: {
            id: 0,
            name: t('Ordinary'),
            numerationOption: null,
          },
        },
      ]);
      setLastKeyPressed(null);
      // Handle the tab-induced blur event here
    }
  };

  const handleAddPartner = (): void => {
    setManualPartners((prevManualPartners) => [
      ...prevManualPartners,
      {
        id: prevManualPartners.length,
        name: '',
        cif: '',
        email: '',
        category: null,
        shares: '',
        shareFrom: '',
        shareTo: '',
        holdingClass: {
          id: 0,
          name: t('Ordinary'),
          numerationOption: null,
        },
      },
    ]);
  };

  const handleRemovePartner = (index: number): void => {
    const newManualPartners = [...manualPartners];
    newManualPartners.splice(index, 1);
    const updatedManualPartners = calculateNumerations(newManualPartners);
    setManualPartners(updatedManualPartners);
    setData((prevData: any) => ({
      ...prevData,
      partners: updatedManualPartners,
    }));
  };

  const handleChangeSource = (e: any) => {
    setEditSelectedPartners(false);
    setPartnerSource(e.target.value);
    setData((prevData: any) => ({
      ...prevData,
      partnersSource: e.target.value,
    }));
  };

  const handleChangeValue = (e: any, setValue: any): void => {
    const newValue =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setValue(newValue);
    setData((prevData: any) => ({
      ...prevData,
      [e.target.name]: newValue,
    }));
  };

  const handleSelectPartner = (e: any, partner: Record<string, any>) => {
    const existingPartners = data.partners || [];

    if (e.target.checked) {
      const partnerExists = existingPartners.some(
        (p: Record<string, any>) => p.id === partner.id
      );

      if (partnerExists) return;
      setData((prevData: any) => ({
        ...prevData,
        partners: [...existingPartners, partner],
      }));
    } else {
      const newPartners = existingPartners.filter(
        (p: Record<string, any>) => p.id !== partner.id
      );
      setData((prevData: any) => ({
        ...prevData,
        partners: newPartners,
      }));
    }
  };

  const handleChangeManualPartner = (field: string, e: any, index: number) => {
    const newManualPartners = [...manualPartners];
    newManualPartners[index][field] =
      field === 'shares' ? cleanValue(e.target.value) : e.target.value;
    const updatedManualPartners = calculateNumerations(newManualPartners);
    setManualPartners(updatedManualPartners);
    setData((prevData: any) => ({
      ...prevData,
      partners: updatedManualPartners,
    }));
  };

  useEffect(() => {
    if (holdingClasses?.length) {
      setData((prevData: any) => ({
        ...prevData,
        holdingClasses,
      }));
    }
  }, [holdingClasses]);

  useEffect(() => {
    const fetchPartners = async () => {
      if (partnerSource === partnersSources.SOCIETIES) {
        const partnersFromSocieties: Record<string, any>[] =
          await getPartnersFromSocieties(user);
        setPartners(partnersFromSocieties);
      }
    };
    fetchPartners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnerSource]);

  useEffect(() => {
    if (partnerSource && data.partners?.length) {
      setCompletedSteps((prevData: any) => ({
        ...prevData,
        1: true,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnerSource, data.partners]);

  useEffect(() => {
    setPartnerSource(data.partnersSource || '');
    setPartners(data.partners || []);
    setManualPartners(
      data.partners || [
        {
          id: 0,
          name: '',
          cif: '',
          email: '',
          shares: '',
          shareFrom: '',
          shareTo: '',
          holdingClass: {
            id: 0,
            name: t('Ordinary'),
            numerationOption: null,
          },
        },
      ]
    );
    setDate(data?.date || '');
    setNominalValue(data?.nominalValue || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="nk-stepper-step">
      <ul className="row g-3">
        {/* From other societies option */}
        <li className="col-sm-4">
          <div className="custom-control custom-control-sm custom-radio pro-control custom-control-full border rounded py-3 w-100">
            <input
              type="radio"
              className="custom-control-input"
              name="cp1-project-template"
              id="cp1-template-societies"
              value={partnersSources.SOCIETIES}
              checked={
                partnerSource === partnersSources.SOCIETIES ||
                editSelectedPartners
              }
              onChange={handleChangeSource}
            />
            <label
              className="custom-control-label ml-2"
              htmlFor="cp1-template-societies"
            >
              <span
                className="d-flex flex-column text-center pl-0 pr-3"
                style={{ marginLeft: '-20px' }}
              >
                <span className="icon-wrap text-danger">
                  <img
                    src={Step1c}
                    alt="wizard-step-2"
                    width={44}
                    height={36}
                  />
                </span>
                <span className="lead-text mb-1 mt-3">De otras sociedades</span>
                <span className="sub-text">Administradas por ti</span>
              </span>
            </label>
          </div>
        </li>

        {/* Upload file option */}
        <li className="col-sm-4">
          <div className="custom-control custom-control-sm custom-radio pro-control custom-control-full border rounded py-3 w-100">
            <input
              type="radio"
              className="custom-control-input"
              name="cp1-project-template"
              id="cp1-template-upload"
              value={partnersSources.FILE}
              checked={partnerSource === partnersSources.FILE}
              onChange={handleChangeSource}
            />
            <label
              className="custom-control-label ml-2"
              htmlFor="cp1-template-upload"
            >
              <span
                className="d-flex flex-column text-center pl-0 pr-3"
                style={{ marginLeft: '-10px' }}
              >
                <span className="icon-wrap text-info">
                  <img
                    src={Step1d}
                    alt="wizard-step-2"
                    width={44}
                    height={36}
                  />
                </span>
                <span className="lead-text mb-1 mt-3">Desde un archivo</span>
                <span className="sub-text">.docx / .xlsx / .pdf</span>
              </span>
            </label>
          </div>
        </li>

        {/* Manually */}
        <li className="col-sm-4">
          <div className="custom-control custom-control-sm custom-radio pro-control custom-control-full border rounded py-3 w-100">
            <input
              type="radio"
              className="custom-control-input"
              name="cp1-project-template"
              id="cp1-template-manually"
              value={partnersSources.MANUAL}
              checked={partnerSource === partnersSources.MANUAL}
              onChange={handleChangeSource}
            />
            <label
              className="custom-control-label ml-2"
              htmlFor="cp1-template-manually"
            >
              <span
                className="d-flex flex-column text-center pl-0 pr-3"
                style={{ marginLeft: '5px' }}
              >
                <span className="icon-wrap text-info">
                  <img
                    src={Step1b}
                    alt="wizard-step-3"
                    width={44}
                    height={36}
                  />
                </span>
                <span className="lead-text mb-1 mt-3">Manualmente</span>
                <span className="sub-text">CIF obligatorio</span>
              </span>
            </label>
          </div>
        </li>
      </ul>

      {/* Partners list */}
      {partnerSource === partnersSources.SOCIETIES && !editSelectedPartners && (
        <div className="card card-bordered mt-4 wizard--partners-table-wrapper">
          <table className="table table-tranx" id="selected-partners-table">
            <thead>
              <tr className="tb-tnx-head">
                <th />
                <th className="text-left">
                  <span>{t('Name')}</span>
                </th>

                <th className="text-left">
                  <span>{t('CIF')}</span>
                </th>
                <th className="text-left">
                  <span>{t('Society')}</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {partners?.map((partner) => (
                <tr className="tb-tnx-item" key={partner?.id}>
                  <td className="d-flex align-items-center pt-2">
                    <div className="custom-control custom-control-sm custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id={`checkbox-partner-${partner.id}`}
                        name={`checkbox-partner-${partner.id}`}
                        onChange={(e) => handleSelectPartner(e, partner)}
                        checked={data?.partners?.some(
                          (p: Record<string, any>) => p.id === partner.id
                        )}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={`checkbox-partner-${partner.id}`}
                      />
                    </div>
                  </td>
                  <td className="tb-tnx-info py-1">
                    <div className="tb-tnx-info">
                      <div className="user-card">
                        <div className="user-avatar bg-dim-primary sm">
                          {partner?.image ? (
                            <img
                              src={partner?.image}
                              alt={partner?.name || ''}
                              className="rounded-circle"
                            />
                          ) : (
                            <span>
                              {partner?.name.slice(0, 2).toUpperCase()}
                            </span>
                          )}
                        </div>
                        <div className="user-info link-text">
                          <span className="lead-text">
                            {partner?.name || ''}
                          </span>
                          <span className="d-block text-ellipsis fs-10px link-text">
                            {partner?.email}
                          </span>
                        </div>
                      </div>
                    </div>
                  </td>

                  <td
                    className="tb-tnx-info py-1"
                    style={{ height: '40px', lineHeight: '38px' }}
                  >
                    <div className="tb-tnx-info">{partner?.cif || '-'}</div>
                  </td>

                  <td
                    className="tb-tnx-info py-1"
                    style={{ height: '40px', lineHeight: '38px' }}
                  >
                    <div className="tb-tnx-info">{partner?.society || '-'}</div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {(partnerSource === partnersSources.MANUAL || editSelectedPartners) && (
        <div className="card card-bordered mt-4 wizard--partners-table-wrapper">
          {createClassInsite ? (
            <AddNewClass
              setCreateClassInsite={setCreateClassInsite}
              holdingClasses={holdingClasses}
              setHoldingClasses={setHoldingClasses}
            />
          ) : (
            <table
              className="table table-borderless"
              id="manual-partners-table"
            >
              <thead>
                <tr className="tb-tnx-head">
                  <th className="border-bottom-0" />
                  <th className="text-left border-bottom-0">
                    <span>{t('Name')}</span>
                  </th>

                  <th className="text-left border-bottom-0">
                    <span>{t('CIF')}</span>
                  </th>
                  <th className="text-left border-bottom-0">
                    <span>Email</span>
                  </th>
                  <th className="text-left border-bottom-0">
                    <span>{t('Shares')}</span>
                  </th>
                  <th className="text-left border-bottom-0">
                    <span>{t('Class')}</span>
                  </th>
                  <th className="text-left border-bottom-0">
                    <span>{t('Numeration')}</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {manualPartners?.map((partner, index) => (
                  <tr className="tb-tnx-item border-bottom-0" key={partner?.id}>
                    <td className="tb-tnx-info p-1">
                      {manualPartners?.length > 1 &&
                      index < manualPartners?.length - 1 ? (
                        <button
                          className="btn btn-sm btn-dim btn-outline-primary"
                          type="button"
                          onClick={() => handleRemovePartner(index)}
                        >
                          <em className="icon ni ni-minus" />
                        </button>
                      ) : (
                        <button
                          className="btn btn-sm btn-outline-primary btn-dim"
                          type="button"
                          onClick={() => handleAddPartner()}
                        >
                          <em className="icon ni ni-plus" />
                        </button>
                      )}
                    </td>
                    <td className="tb-tnx-info p-1">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        id="name"
                        name="name"
                        value={manualPartners[index].name}
                        onChange={(e) =>
                          handleChangeManualPartner('name', e, index)
                        }
                      />
                    </td>

                    <td className="tb-tnx-info p-1">
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        id="cif"
                        name="cif"
                        value={manualPartners[index].cif}
                        onChange={(e) =>
                          handleChangeManualPartner('cif', e, index)
                        }
                      />
                    </td>

                    <td className="tb-tnx-info p-1">
                      <input
                        type="email"
                        className="form-control form-control-sm"
                        id="email"
                        name="email"
                        value={manualPartners[index].email}
                        onChange={(e) =>
                          handleChangeManualPartner('email', e, index)
                        }
                      />
                    </td>

                    <td className="tb-tnx-info p-1">
                      <NumberFormat
                        id="outlined-normal"
                        className="form-control form-control-sm"
                        value={manualPartners[index].shares}
                        onChange={(e: any) =>
                          handleChangeManualPartner('shares', e, index)
                        }
                        onKeyDown={handleKeyDown}
                        onBlur={handleBlur}
                        isAllowed={(inputObj: any) => {
                          const { floatValue, formattedValue } = inputObj;
                          if (formattedValue === '') return true;
                          if (Number.isInteger(floatValue) && floatValue >= 1)
                            return true;
                          return false;
                        }}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...numberFormat}
                      />
                    </td>

                    <td className="tb-tnx-info p-1">
                      <Select
                        closeMenuOnSelect
                        className="react-select"
                        styles={{
                          control: (baseStyles: any) => ({
                            ...baseStyles,
                            width: '150px',
                            minHeight: 'calc(1.75rem + 2px)',
                            height: 'calc(1.75rem + 2px)',
                            fontSize: '0.75rem',
                            alignContent: 'center',
                          }),
                          option: (styles: any, { isFocused }) => ({
                            ...styles,
                            backgroundColor: isFocused ? 'gray' : 'white',
                            color: isFocused ? 'white' : 'black',
                            fontSize: '0.75rem',
                            alignContent: 'center',
                          }),
                        }}
                        value={{
                          value: manualPartners[index]?.holdingClass?.id,
                          label: manualPartners[index]?.holdingClass?.name,
                        }}
                        options={holdingClasses?.map((holdingClass) => ({
                          value: holdingClass?.id,
                          label: `${holdingClass.name}`,
                        }))}
                        components={animatedComponents}
                        onChange={(selectedOption) =>
                          handleClassOptionChange(selectedOption, index)
                        }
                      />
                    </td>

                    <td className="tb-tnx-info p-1 text-center">
                      {manualPartners[index].shares ? (
                        <>
                          <span className="badge badge-primary">
                            {`${manualPartners[index].shareFrom}-${manualPartners[index].shareTo}`}
                          </span>
                          <span className="badge badge-success">
                            {/*  percent of total shares */}
                            {Math.round(
                              (Number(manualPartners[index].shares) * 100) /
                                manualPartners.reduce(
                                  (acc, partner) =>
                                    acc + Number(partner.shares) || 0,
                                  0
                                )
                            )}
                            %
                          </span>
                        </>
                      ) : (
                        <span>-</span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr className="tb-tnx-item">
                  <td className="tb-tnx-info p-1" colSpan={3}>
                    <span className="text-muted">
                      {t('TotalShares')}:{' '}
                      {manualPartners.reduce(
                        (acc, partner) => acc + Number(partner.shares),
                        0
                      )}
                    </span>
                  </td>
                </tr>
              </tfoot>
            </table>
          )}
        </div>
      )}
      <br />

      <div className="row g-3">
        <div className="col-4">
          <div className="form-group">
            <label className="form-label" htmlFor="constitution-date">
              {t('ConstitutionDate')}
            </label>
            <div className="form-control-wrap">
              <input
                type="date"
                className="form-control date-picker"
                id="constitution-date"
                name="date"
                value={date}
                onChange={(e) => handleChangeValue(e, setDate)}
                required
              />
            </div>
          </div>
        </div>

        <div className="col-4">
          <div className="form-group">
            <label className="form-label" htmlFor="constitution-nominal-value">
              {t('NominalValue')}
            </label>
            <div className="form-control-wrap">
              <input
                type="number"
                className="form-control"
                id="constitution-nominal-value"
                name="nominalValue"
                value={nominalValue}
                onChange={(e) => handleChangeValue(e, setNominalValue)}
                required
              />
            </div>
          </div>
        </div>

        {(partnerSource === partnersSources.MANUAL || editSelectedPartners) && (
          <div className="col-4 d-flex flex-column align-items-center justify-content-end">
            <button
              type="button"
              id="add-class-btn"
              className="btn btn-sm btn-dim btn-light justify-content-center"
              onClick={() => setCreateClassInsite((prev) => !prev)}
            >
              <em className="icon ni ni-plus" />
              <span>{t('AddClass')}</span>
            </button>
          </div>
        )}
      </div>
      <div className="wizard--pagination card-inner">
        <ul className="nk-stepper-pagination gx-4 stepper-pagination">
          <li className="step-prev">
            <button
              type="button"
              className="btn btn-dim btn-primary"
              onClick={() => {
                if (partnerSource === partnersSources.MANUAL) {
                  setData((prevData: any) => ({
                    ...prevData,
                    partners: manualPartners,
                  }));
                  setActiveStep((activeStep: any) => activeStep - 1);
                }
                if (partnerSource === partnersSources.SOCIETIES) {
                  if (editSelectedPartners) {
                    setEditSelectedPartners(false);
                  } else {
                    setActiveStep((activeStep: any) => activeStep - 1);
                  }
                }
                if (partnerSource === partnersSources.FILE) {
                  setActiveStep((activeStep: any) => activeStep - 1);
                }
              }}
            >
              {t('Back')}
            </button>
          </li>

          <li className="step-next">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                if (partnerSource === partnersSources.MANUAL) {
                  setData((prevData: any) => ({
                    ...prevData,
                    partners: manualPartners.filter(
                      (partner) =>
                        partner.cif && partner.name && +partner.shares
                    ),
                  }));
                  setActiveStep((activeStep: any) => activeStep + 1);
                }
                if (partnerSource === partnersSources.SOCIETIES) {
                  if (editSelectedPartners) {
                    setData((prevData: any) => ({
                      ...prevData,
                      partners: manualPartners.filter(
                        (partner) =>
                          partner.cif && partner.name && +partner.shares
                      ),
                    }));
                    setActiveStep((activeStep: any) => activeStep + 1);
                  } else {
                    setManualPartners(data?.partners);
                    setEditSelectedPartners(true);
                  }
                }
              }}
              disabled={!validateData()}
            >
              {t('Next')}
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Step2;
