/* eslint-disable no-case-declarations */
import actionTypes from 'redux/actions/actionTypes';
import initialState from '../initialState';

export default function reducer(
  employeesPlans = initialState.employeesPlans,
  action
) {
  switch (action.type) {
    case actionTypes.CREATE_EMPLOYEES_PLAN:
      return [...employeesPlans, action.employeesPlans];

    case actionTypes.GET_EMPLOYEES_PLANS:
      return action.data;

    case actionTypes.UPDATE_EMPLOYEES_PLAN:
      return [
        ...employeesPlans.filter(
          (employeesPlan) =>
            employeesPlan['_id'] !== action.employeesPlan['_id']
        ),
        action.employeesPlan,
      ];

    case actionTypes.DELETE_EMPLOYEES_PLAN:
      return [
        ...employeesPlans.filter(
          (employeesPlan) =>
            employeesPlan['_id'] !== action.employeesPlan['_id']
        ),
      ];

    default:
      return employeesPlans;
  }
}
