/* eslint-disable import/prefer-default-export */
import React from 'react';
import store from 'redux/store';

import { setModal } from 'redux/actions/modalActions';

import EditModal from 'components/UserMyData/EditModal';

export function editUser({ data, societyId, userType }) {
  store.dispatch(setModal(<EditModal user={data} societyId={societyId} userType={userType} />));
}
