/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import BarChart from 'components/Charts/BarChart';

import './WidgetChartBar.scss';

const WidgetChartBar = ({ title, subtitle, height, kpis, data }) => (
  <div className="col-12 col-xl-5 mb-4">
    <div className="card card-bordered h-100">
      <div className="card-inner">
        <div className="card-title-group pb-3 g-2">
          <div className="card-title card-title-sm">
            <h6 className="title">{title}</h6>
            <p>{subtitle}</p>
          </div>
        </div>
        <div className="analytic-ov">
          <div className="analytic-data-group analytic-ov-group mt-1">
            {kpis.map((item) => (
              <div className="analytic-data" key={uuidv4()}>
                <div className="title">{item?.title}</div>
                <div className="amount">{item?.value}</div>

                {item?.link ? (
                  <Link to={item?.link} className="fs-12px">
                    <em className={`icon mr-1 ni ${item?.icon}`} />
                    Ver
                  </Link>
                ) : (
                  <div style={{ height: '25px' }} />
                )}
              </div>
            ))}
          </div>
          <div className="mt-3">
            <BarChart data={data.data} options={data.options} height={height} />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default WidgetChartBar;
