import alertBodyTypes from 'components/Alert/alertBodyTypes';

import axios from '../../interceptors/axios';

import { addAlert } from './alertActions';

export default function sendContactEmail(newEmailData) {
  const { file, ...emailData } = newEmailData;
  const formData = new FormData();

  return async (dispatch) => {
    try {
      if (file) formData.append('file', file);
      formData.append('data', JSON.stringify(emailData));

      await axios.post(`${process.env.REACT_APP_API_URL}/contact`, formData, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      });

      dispatch(addAlert(alertBodyTypes['CONTACT_EMAIL_SENT']));
    } catch (error) {
      dispatch(addAlert(alertBodyTypes['ERROR_CONTACT_EMAIL']));
    }
  };
}
