/* eslint-disable react-hooks/rules-of-hooks */
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setModal } from 'redux/actions/modalActions';
import SaveTemplates from 'components/Modals/SaveTemplates';
import EditorWYSIWYG from 'components/EditorWYSIWYG';
import {
  getDocumentsByReference,
  updateDocument,
} from 'redux/actions/documentActions';
import './TemplateCreator.scss';

const TemplateCreator = ({ document }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { user, actualSociety } = useSelector((state) => ({
    user: state.user,
    actualSociety: state.society?.actualSociety,
  }));

  const isDemo = useSelector((state) => state.society?.role?.isDemo);

  const [htmlContent, setHtmlContent] = React.useState(
    document?.editorModel || null
  );

  const plan = useSelector((state) => state?.plans[0] || {});
  const partner = useSelector(
    (state) => state?.society?.actualSociety?.partners[0] || {}
  );
  const society = useSelector((state) => state?.society?.actualSociety || {});
  const beneficiary = useSelector(
    (state) => state?.society?.actualSociety?.beneficiaries[0] || {}
  );
  const board = useSelector((state) => state?.boards?.[0] || {});
  const signatures = {};
  const tenderOfferShares = {};

  const handleSave = () => {
    const newTemplate = {
      date: new Date(),
      lastAccess: new Date(),
      category: 'TEMPLATES',
      author: user['_id'],
      society: actualSociety['_id'],
      isGenerated: false,
      isFavorite: false,
      editorModel: htmlContent,
    };

    dispatch(setModal(<SaveTemplates data={newTemplate} />));
  };

  const handleUpdate = () => {
    const updatedTemplate = {
      ...document,
      lastAccess: new Date(),
      editorModel: htmlContent,
    };

    dispatch(updateDocument(updatedTemplate));
    dispatch(getDocumentsByReference({ societyId: actualSociety['_id'] }));
  };

  return (
    <>
      <div className="nk-fmg-body-content">
        <div className="nk-block-head nk-block-head-sm">
          <div className="nk-block-between position-relative">
            <div className="nk-block-head-content">
              <h3 className="nk-block-title page-title">
                {document?.editorModel ? t('EditTemplate') : t('NewTemplate')}
              </h3>
              {document?.editorModel && (
                <div className="nk-block-des text-soft">
                  <p>{document.name}</p>
                </div>
              )}
            </div>

            <div className="nk-block-head-content">
              <ul className="nk-block-tools g-3">
                {document?.editorModel ? (
                  <li>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={handleUpdate}
                      disabled={isDemo}
                    >
                      <em className="icon ni ni-save mr-2" />
                      {t('ToUpdate')}
                    </button>
                  </li>
                ) : (
                  <li>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={handleSave}
                      disabled={isDemo}
                    >
                      <em className="icon ni ni-save mr-2" />
                      {t('ToSave')}
                    </button>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>

        <div className="w-100">
          <EditorWYSIWYG
            values={{
              plan,
              partner,
              society,
              beneficiary,
              board,
              signatures,
              tenderOfferShares,
              operation: {},
            }}
            setEditorContent={setHtmlContent}
            editorContent={htmlContent}
          />
        </div>
      </div>
    </>
  );
};

export default TemplateCreator;
