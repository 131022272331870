/* eslint-disable no-await-in-loop */
import React from 'react';
import store from 'redux/store';
import Record from 'typescript';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { createHoldingClass } from 'redux/actions/holdingClassActions';
import { setModal } from 'redux/actions/modalActions';
import { setSocietyForUser } from 'redux/actions/societyActions';

import { generateEmail } from 'utils/filters';
import { addPartners, constitution } from 'redux/actions/modalsActions';
import IconDashboard from '../../../assets/images/wizard-icon-dashboard.svg';
import IconCaptable from '../../../assets/images/wizard-icon-captable.svg';
import IconBeneficiaries from '../../../assets/images/wizard-icon-beneficiaries.svg';
import IconMeetings from '../../../assets/images/wizard-icon-meetings.svg';

import CompletedSuccessfully from '../../../assets/images/wizard-successful.svg';
import creationSteps from './constants/creationSteps';
import createSociety from './utils/createSociety';
import filterUniqueElementsByProperty from './utils/filterUniqueElementsByProperty';

const StepFinal = ({ data }: Record<string, any>) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.user);

  const [processCompleted, setProcessCompleted] = React.useState(false);
  const [processPercentage, setProcessPercentage] = React.useState(0);
  const [processMessage, setProcessMessage] = React.useState('');

  const [createdSocietyId, setCreatedSocietyId] = React.useState('');
  const [initializedSociety, setInitializedSociety] = React.useState(false);

  const [isSocietyCreated, setIsSocietyCreated] = React.useState(false);
  const [isPartnerCreated, setIsPartnerCreated] = React.useState(false);
  const [isConstitutionCreated, setIsConstitutionCreated] =
    React.useState(false);

  const createSocietyWizard = async () => {
    const societyData = {
      name: data?.socialDenomination,
      cif: data?.cif,
      legalAddress: {
        line1: data?.line1,
        zip: data?.zip,
        city: data?.city,
        country: data?.country,
      },
      language: data?.commsLanguage,
      sendNotifications: data?.sendNotifications || false,
      hasAccessCaptable: data?.hasAccessCaptable || false,
      isSocietyHolding: data?.isSocietyHolding || false,
    };
    const society = await createSociety(societyData, user?.['_id'], false);
    return society;
  };

  const createHoldingClasses = async (societyId: string) => {
    try {
      const createdClasses: Record<string, any>[] = [];
      const primaryClasses: Record<string, any>[] = [];
      const secundaryClasses: Record<string, any>[] = [];
      if (societyId && data?.holdingClasses?.length) {
        data?.holdingClasses
          ?.sort((a: any, b: any) => a.id - b.id)
          .forEach((holdingClass: Record<string, any>) => {
            if (holdingClass?.numerationOption) {
              secundaryClasses.push(holdingClass);
            } else {
              primaryClasses.push(holdingClass);
            }
          });
        // eslint-disable-next-line no-restricted-syntax
        for (const holdingClass of primaryClasses) {
          const newHoldingClass = {
            name: holdingClass?.name || '',
            votes: 1,
            numerationOption: null,
            lockupPeriod: new Date(),
            conversionRate: 0,
            hasConversionRights: false,
            hasLiquidationRights: false,
            hasAntidilutionRights: false,
            society: societyId,
          };
          // @ts-ignore
          const result = await store.dispatch(
            createHoldingClass(newHoldingClass, false)
          );
          createdClasses.push({
            ...result,
            originalId: holdingClass?.id,
          });
        }
        // eslint-disable-next-line no-restricted-syntax
        for (const holdingClass of secundaryClasses) {
          const numerationOptionClass = createdClasses.find(
            (c: any) => c.originalId === holdingClass.numerationOption
          );
          const newHoldingClass = {
            name: holdingClass?.name || '',
            votes: 1,
            numerationOption: numerationOptionClass?.['_id'] || null,
            lockupPeriod: new Date(),
            conversionRate: 0,
            hasConversionRights: false,
            hasLiquidationRights: false,
            hasAntidilutionRights: false,
            society: societyId,
          };
          const result = await store.dispatch(
            createHoldingClass(newHoldingClass, false)
          );
          createdClasses.push({
            ...result,
            originalId: holdingClass?.id,
          });
        }
        return createdClasses;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const createPartners = async (society: Record<string, any>) => {
    try {
      if (data?.partners?.length) {
        const partnersToAdd = filterUniqueElementsByProperty(
          data.partners,
          'cif'
        );
        const formattedPartners = partnersToAdd?.map((partner, index) => {
          const {
            shares,
            id,
            shareFrom,
            shareTo,
            holdingClass,
            ...partnerData
          } = partner;
          return {
            ...partnerData,
            email:
              partner?.email ||
              generateEmail(society, society?.currentPartner + index + 1),
            user: user['_id'],
            userEmail: user.email,
            date: data?.date,
            society: society['_id'],
          };
        });
        const uploadedPartners = await store.dispatch(
          addPartners(formattedPartners)
        );
        return uploadedPartners;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const createConstitution = async (
    societyId: string,
    partners: Record<string, any>[] | undefined,
    holdingClasses: Record<string, any>[] | undefined
  ) => {
    try {
      if (
        societyId &&
        data?.partners?.length &&
        partners?.length &&
        holdingClasses?.length
      ) {
        const movements: Record<string, any>[] = [];
        const transactions: Record<string, any>[] = [];
        data?.partners?.forEach((partner: Record<string, any>) => {
          const actualPartner = partners?.find((p) => p?.cif === partner?.cif);
          const actualHoldingClass = holdingClasses?.find(
            (c) => c.originalId === partner?.holdingClass?.id
          );
          if (
            actualPartner &&
            actualHoldingClass &&
            partner?.shareFrom &&
            partner?.shareTo
          ) {
            movements.push({
              partner: actualPartner?.['_id'],
              shareFrom: +partner?.shareFrom,
              shareTo: +partner?.shareTo,
              shareClass: actualHoldingClass?.['_id'],
              sharePremium: 0,
              operation: 'THIS',
            });
            transactions.push({
              partner: actualPartner?.['_id'],
              shareFrom: +partner?.shareFrom,
              shareTo: +partner?.shareTo,
              shareClass: actualHoldingClass?.['_id'],
              sharePremium: 0,
              operation: 'THIS',
            });
          }
        });
        await store.dispatch(
          constitution(
            {
              movements,
              transactions,
              date: data?.date,
              nominalValue: +data?.nominalValue,
              society: societyId,
              user: user['_id'],
              documents: [],
            },
            false
          )
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const startProcess = async () => {
    try {
      setProcessMessage(creationSteps.CREATE_SOCIETY.label);
      const society = await createSocietyWizard();
      const holdingClasses = await createHoldingClasses(society?.['_id']);
      setIsSocietyCreated(true);
      setCreatedSocietyId(society?.['_id'] || null);
      setProcessPercentage(33);

      setProcessMessage(creationSteps.CREATE_PARTNERS.label);
      const partners = await createPartners(society);
      setIsPartnerCreated(true);
      setProcessPercentage(66);

      setProcessMessage(creationSteps.CREATE_CONSTITUTION.label);
      await createConstitution(society['_id'], partners, holdingClasses);
      setIsConstitutionCreated(true);
      setProcessPercentage(99);
      await dispatch(setSocietyForUser(society['_id']));

      setProcessPercentage(100);
      setProcessCompleted(true);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    if (!initializedSociety) {
      setInitializedSociety(true);
      startProcess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="nk-stepper-step text-center flex-column justify-content-around">
      {processCompleted ? (
        <>
          <div>
            <h5 className="title mb-2">{t('SuccessfullyCreated')}</h5>
            <p className="text-soft">
              {t('SuccessfullyCreatedDescription')}.<br />
              {t('SuccessfullyCreatedNextSteps')}
            </p>
          </div>
          <img
            src={CompletedSuccessfully}
            alt="Wizard completed successfully"
            height={200}
            className="mt-4"
          />

          {createdSocietyId ? (
            <ul className="row mt-4">
              <li className="col-4">
                <div
                  className="wizard--final-button preview-icon-box card card-bordered"
                  onClick={() => {
                    window.location.href = `#/sociedad/${createdSocietyId}`;
                    store.dispatch(setModal(null));
                  }}
                >
                  <div className="preview-icon-wrap">
                    <img
                      src={IconDashboard}
                      alt="Go to dashboard"
                      height={120}
                    />
                  </div>
                  <span className="preview-icon-name">
                    Acceder al Dashboard
                  </span>
                </div>
              </li>
              <li className="col-4">
                <div
                  className="wizard--final-button preview-icon-box card card-bordered"
                  onClick={() => {
                    window.location.href = `#/socios-libro/${createdSocietyId}`;
                    store.dispatch(setModal(null));
                  }}
                >
                  <div className="preview-icon-wrap">
                    <img
                      src={IconCaptable}
                      alt="Open the cap table"
                      height={120}
                    />
                  </div>
                  <span className="preview-icon-name">
                    Ver el Libro de Socios
                  </span>
                </div>
              </li>
              <li className="col-4">
                <div
                  className="wizard--final-button preview-icon-box card card-bordered"
                  onClick={() => {
                    window.location.href = `#/beneficiarios/${createdSocietyId}`;
                    store.dispatch(setModal(null));
                  }}
                >
                  <div className="preview-icon-wrap">
                    <img
                      src={IconBeneficiaries}
                      alt="Add beneficiaries"
                      height={120}
                    />
                  </div>
                  <span className="preview-icon-name">
                    Añadir Beneficiarios
                  </span>
                </div>
              </li>
            </ul>
          ) : (
            <></>
          )}
        </>
      ) : (
        <div className=" w-100">
          <div
            className="d-flex flex-column justify-content-center"
            style={{
              height: '354px',
            }}
          >
            <h2>{processPercentage}%</h2>
            <div className="progress progress-lg bg-light rounded">
              <div
                className={`progress-bar progress-bar-striped progress-bar-animated ${
                  processPercentage === 100 ? 'bg-success' : 'bg-primary'
                }`}
                data-progress={processPercentage || 0}
                style={{ width: `${processPercentage}%` }}
              />
            </div>
            {processMessage && <p className="mt-2">{processMessage}...</p>}
          </div>

          <ul className="row mt-4">
            {new Array(4).fill(null).map(() => (
              <li className="col-3">
                <div
                  className="card card-bordered placeholder-glow"
                  aria-hidden="true"
                >
                  <div className="card-body">
                    <h5 className="card-title placeholder-glow">
                      <span
                        className="placeholder col-6"
                        style={{ height: '40px' }}
                      />
                    </h5>
                    <p className="card-text placeholder-glow">
                      <span className="placeholder col-7" />
                      <span className="placeholder col-4" />
                      <span className="placeholder col-4" />
                      <span className="placeholder col-6" />
                      <span className="placeholder col-8" />
                    </p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default StepFinal;
