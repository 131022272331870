/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import store from 'redux/store';
import { Tooltip } from 'react-tooltip';
import html2pdf from 'html3pdf';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useMixpanel } from 'react-mixpanel-browser';
import { format } from 'date-fns';
import makeAnimated from 'react-select/animated';
import Select from 'react-select';

import { setModal } from 'redux/actions/modalActions';
import {
  addDocument,
  getDocument,
  updateDocument,
} from 'redux/actions/documentActions';
import { addAlert } from 'redux/actions/alertActions';
import { boardAction } from 'redux/actions/modalsActions';
import { updateBoard } from 'redux/actions/boardActions';

import { updateDocumentWithValues } from 'components/EditorWYSIWYG/helpers';
import tags from 'components/EditorWYSIWYG/tags';
import alertBodyTypes from 'components/Alert/alertBodyTypes';
import CustomLoading from 'components/CustomLoading';

import fileTypes from 'constants/fileTypes';
import eventTypes from 'constants/eventTypes';
import documentTypes from 'constants/documentTypes';
import operationTypes from 'constants/operationTypes';
import attendanceTypes from 'constants/attendanceTypes';
import voteValidationTypes from 'constants/voteValidationTypes';
import boardTypes from 'constants/boardTypes';
import voteTypes from 'constants/voteTypes';
import votePeriods from 'constants/votePeriods';
import boardStatus from 'constants/boardStatus';
import actionTypes from 'constants/actionTypes';
import userTypes from 'constants/userTypes';
import announcementTemplates from 'constants/announcementTemplates';
import { TYPE_FILES_ALLOWED } from 'constants/defaultConstants';
import actTemplates from 'constants/actTemplates';
import actCertificateTemplates from 'constants/actCertificateTemplates';
import { dateFormat } from 'constants/formats';

import trackEvent from 'utils/trackEvent';
import { getFullAddress } from 'utils/filters';
import { getDocumentIcon } from 'utils/documentIcons';

import Swal from 'sweetalert2';
import UploadAnnouncementModal from './uploadAnnouncementModal';
import '../Modals.scss';

const AddBoard = ({ action = actionTypes.ADD, board }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();

  const user = useSelector((state) => state?.user);
  const actualSociety = useSelector((state) => state.society?.actualSociety);
  const societyDocuments = useSelector((state) => state.documents);

  const animatedComponents = makeAnimated();

  const globalAnnouncementTemplateId =
    announcementTemplates[process.env.NODE_ENV];

  const globalActTemplateId = actTemplates[process.env.NODE_ENV];

  const globalActCertificateTemplateId =
    actCertificateTemplates[process.env.NODE_ENV];

  const defaultOrders = () => {
    const firstOrder = {
      subject: 'Ruegos y preguntas',
      description: '',
      voteType: voteTypes.NONE.value,
      voteValidation: voteValidationTypes.NO_CALCULATE.value,
    };
    const secondOrder = {
      subject:
        'Confección, lectura y aprobación, si procede, del Acta de la reunión',
      description: '',
      voteType: voteTypes.NONE.value,
      voteValidation: voteValidationTypes.NO_CALCULATE.value,
    };
    return [firstOrder, secondOrder];
  };

  const [name, setName] = useState(
    `${t('ShareholdersBoard')} ${format(new Date(), 'dd/MM/yyyy')}`
  );
  const [date, setDate] = useState('');

  const [attendanceType, setAttendanceType] = useState(
    attendanceTypes.HYBRID.value
  );
  const [selectedAttendanceOption, setSelectedAttendanceOption] = useState({
    value: attendanceTypes.HYBRID.value,
    label: attendanceTypes.HYBRID.text,
  });

  const [place, setPlace] = useState('');
  const [onlineAccess, setOnlineAccess] = useState('');

  const [votePeriod, setVotePeriod] = useState(votePeriods[0].value);
  const [selectedVotePeriodOption, setSelectedVotePeriodOption] = useState({
    value: votePeriods[0].value,
    label: votePeriods[0].text,
  });

  const [boardType, setBoardType] = useState(boardTypes.ORDINARY.value);
  const [selectedBoardTypeOption, setSelectedBoardTypeOption] = useState({
    value: boardTypes.ORDINARY.value,
    label: boardTypes.ORDINARY.text,
  });

  const [announcement, setAnnouncement] = useState('');

  const [president, setPresident] = useState();
  const [selectedPresidentOption, setSelectedPresidentOption] = useState();

  const [secretary, setSecretary] = useState('');
  const [selectedSecretaryOption, setSelectedSecretaryOption] = useState();

  const [digitalVoting, setDigitalVoting] = useState(true);
  const [orders, setOrders] = useState(defaultOrders());
  const [files, setFiles] = useState([]);

  const [displayOrderForm, setDisplayOrderForm] = useState(false);
  const [subject, setSubject] = useState('');
  const [description, setDescription] = useState('');
  const [voteType, setVoteType] = useState(voteTypes.YES_NO_ABS.value);

  const [voteValidation, setVoteValidation] = useState(
    voteValidationTypes.SIMPLE_MAJORITY.value
  );
  const [selectedVoteValidationOption, setSelectedVoteValidationOption] =
    useState({
      value: voteValidationTypes.SIMPLE_MAJORITY.value,
      label: voteValidationTypes.SIMPLE_MAJORITY.text,
    });

  const [orderIndex, setOrderIndex] = useState();

  const [announcementFile, setAnnouncementFile] = useState('');
  const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [directorOptions, setDirectorOptions] = useState([]);

  const [partnertOptions, setPartnerOptions] = useState([]);
  const [selectedPartners, setSelectedPartners] = useState([]);
  const [selectedPartnersOptions, setSelectedPartersOptions] = useState([]);

  const [selectedDelegation, setSelectedDelegation] = useState(0);
  const [selectedDelegationOption, setSelectedDelegationOption] = useState({
    value: 0,
    label: t('AnyPartner'),
  });

  // Options for delegation type selector
  const delegationOptions = [
    { value: 0, label: t('AnyPartner') },
    { value: 1, label: t('OnlyCertainPartners') },
  ];

  // Options for attendance type selector
  const attendanceOptions = Object.values(attendanceTypes).map(
    (attendanceType) => ({
      value: attendanceType.value,
      label: attendanceType.text,
    })
  );

  // Options for vote period selector
  const votePeriodOptions = votePeriods.map((votePeriod) => ({
    value: votePeriod.value,
    label: votePeriod.text,
  }));

  // Options for board type selector
  const boardTypeOptions = Object.values(boardTypes).map((boardType) => ({
    value: boardType.value,
    label: boardType.text,
  }));

  // Options for vote validation type selector
  const voteValidationOptions = Object.values(voteValidationTypes).map(
    (voteValidationType) => ({
      value: voteValidationType.value,
      label: voteValidationType.text,
    })
  );

  const handleDelegationChange = (selectedOption) => {
    setSelectedDelegationOption(selectedOption);
    setSelectedDelegation(selectedOption?.value);
  };
  const handlePartnerChange = (selectedOptions) => {
    setSelectedPartners(selectedOptions.map((option) => option.value));
    setSelectedPartersOptions(selectedOptions);
  };

  const handleAttendanceChange = (selectedOption) => {
    setAttendanceType(selectedOption.value);
    setSelectedAttendanceOption(selectedOption);
  };

  const handleVotePeriodChange = (selectedOption) => {
    setVotePeriod(selectedOption.value);
    setSelectedVotePeriodOption(selectedOption);
  };

  const handleBoardTypeChange = (selectedOption) => {
    setBoardType(selectedOption.value);
    setSelectedBoardTypeOption(selectedOption);
  };

  const handlePresidentChange = (selectedOption) => {
    setPresident(selectedOption.value);
    setSelectedPresidentOption(selectedOption);
  };

  const handleSecretaryChange = (selectedOption) => {
    setSecretary(selectedOption.value);
    setSelectedSecretaryOption(selectedOption);
  };

  const handleVoteValidationChange = (selectedOption) => {
    setVoteValidation(selectedOption.value);
    setSelectedVoteValidationOption(selectedOption);
  };

  const handleDisplayOptionChange = () => {
    if (displayOrderForm) {
      setOrderIndex();
      setSubject('');
      setDescription('');
      setVoteType(voteTypes.YES_NO_ABS.value);
      setVoteValidation(voteValidationTypes.SIMPLE_MAJORITY.value);
      setSelectedVoteValidationOption({
        value: voteValidationTypes.SIMPLE_MAJORITY.value,
        label: voteValidationTypes.SIMPLE_MAJORITY.text,
      });
    }
    setDisplayOrderForm(!displayOrderForm);
  };

  function updateTextWithNewDate(date) {
    // Regular expression to match dates in the format MM/DD/YYYY
    const dateRegex = /\b(\d{1,2}\/\d{1,2}\/\d{4})\b/g;

    // Replace the date expression with a new date.
    const updatedText = name.replace(dateRegex, date);

    setName(updatedText);
  }

  function getFileDate(file) {
    const fileDate = file?.lastModifiedDate;
    if (fileDate) {
      return format(new Date(fileDate), `${dateFormat}`);
    }
    return '';
  }
  function saveOrder(event) {
    event.preventDefault();
    const newOrder = {
      subject,
      description,
      voteType,
      voteValidation,
    };
    if (orderIndex >= 0) {
      const newOrders = [...orders];
      newOrders[orderIndex] = newOrder;
      setOrders(newOrders);
      setOrderIndex();
    } else if (orders.length >= 2) {
      const firstHalf = orders.slice(0, orders.length - 2);
      const secondHalf = orders.slice(orders.length - 2);
      setOrders([...firstHalf, newOrder, ...secondHalf]);
    } else {
      setOrders([...orders, newOrder]);
    }
    setDisplayOrderForm(false);
    setSubject('');
    setDescription('');
    setVoteType(voteTypes.YES_NO_ABS.value);
    setVoteValidation(voteValidationTypes.SIMPLE_MAJORITY.value);
    setSelectedVoteValidationOption({
      value: voteValidationTypes.SIMPLE_MAJORITY.value,
      label: voteValidationTypes.SIMPLE_MAJORITY.text,
    });
  }

  function handleEditOrder(index) {
    if (orders?.[index]) {
      setDisplayOrderForm(true);
      setSubject(orders[index]?.subject);
      setDescription(orders[index]?.description);
      setVoteType(orders[index].voteType || voteTypes.YES_NO_ABS.value);

      const currentVoteValidation =
        orders[index].voteValidation ||
        voteValidationTypes.SIMPLE_MAJORITY.value;

      setVoteValidation(currentVoteValidation);
      setSelectedVoteValidationOption({
        value: currentVoteValidation,
        label: voteValidationTypes[currentVoteValidation].text,
      });
      setOrderIndex(index);
    }
  }

  function handleDeleteDocument(documentIndex) {
    const indexedFile = files?.[documentIndex];
    if (indexedFile) {
      Swal.fire({
        icon: 'warning',
        title: `<h4 class="nk-modal-title">${t(
          'AreYouSureAboutDelDocument'
        )}</h4>`,
        html: `<h5 class="text-primary">${indexedFile?.name}</h5><br />`,
        confirmButtonText: t('OkDelete'),
        confirmButtonColor: '#6576ff',
        allowOutsideClick: false,
        showCancelButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const filteredFiles = files.filter(
            (file, index) => documentIndex !== index
          );
          setFiles(filteredFiles);
        }
      });
    }
  }

  function handleDeleteOrder(orderIndex) {
    if (orders?.[orderIndex]) {
      Swal.fire({
        icon: 'warning',
        title: `<h4 class="nk-modal-title">${t(
          'AreYouSureAboutDelSubject'
        )}</h4>`,
        html: `<h5 class="text-primary">${orders?.[orderIndex]?.subject}</h5><br />`,
        confirmButtonText: t('OkDelete'),
        confirmButtonColor: '#6576ff',
        allowOutsideClick: false,
        showCancelButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const filteredOrders = orders.filter(
            (order, index) => orderIndex !== index
          );
          setOrders(filteredOrders);
        }
      });
    }
  }

  const handleUploadAnnouncementDocument = async (announcementFile) => {
    if (board?.announcementDocument) {
      await store.dispatch(
        updateDocument(
          {
            _id: board.announcementDocument,
            category: documentTypes.DELETED,
          },
          false
        )
      );
    }
    const newAnnouncementDocument = {
      file: announcementFile,
      date: new Date(),
      size: announcementFile?.size,
      name: announcementFile?.name,
      author: user?.['_id'],
      society: actualSociety?.['_id'],
      fileType: fileTypes[announcementFile?.type],
      category: documentTypes.DOCUMENTS,
      subcategory: documentTypes.BOARDS,
      description: t('DocumentLinkedManually'),
    };
    const uploadedDoc = await store.dispatch(
      addDocument(newAnnouncementDocument)
    );
    if (uploadedDoc) {
      dispatch(
        updateBoard(
          board?.['_id'],
          {
            announcementDocument: uploadedDoc['_id'],
          },
          false
        )
      );
    }
    dispatch(addAlert(alertBodyTypes.BOARD_UPDATED));
    dispatch(setModal(null));
  };

  async function saveBoard(event) {
    event.preventDefault();
    try {
      setIsLoading(true);
      const uploadedDocuments = [];
      if (files.length) {
        for (const file of files) {
          const newDocument = {
            file,
            date: new Date(),
            size: file.size,
            name: `${operationTypes.ADD_BOARD_FILENAME}-${file.name}`,
            author: user['_id'],
            society: actualSociety['_id'],
            fileType: fileTypes[file.type],
            category: documentTypes.DOCUMENTS,
            description: t('DocumentLinkedToOperation'),
          };
          const documentData = await store.dispatch(addDocument(newDocument));
          if (documentData) {
            uploadedDocuments.push(documentData);
          }
        }
      }

      const globalAnnouncementTemplate = await store.dispatch(
        getDocument(globalAnnouncementTemplateId)
      );
      const globalActTemplate = await store.dispatch(
        getDocument(globalActTemplateId)
      );
      const globalActCertificateTemplate = await store.dispatch(
        getDocument(globalActCertificateTemplateId)
      );

      const newTemplate = {
        name: `${t('AnnouncementTemplate')}_${name}`,
        category: 'TEMPLATES',
        date: new Date(),
        lastAccess: new Date(),
        author: user['_id'],
        society: actualSociety['_id'],
        isGenerated: false,
        isFavorite: false,
        editorModel: globalAnnouncementTemplate.editorModel,
        fileType: fileTypes.TEMPLATE,
      };
      const announcementTemplate = await store.dispatch(
        addDocument(newTemplate)
      );
      const newParticipants = actualSociety?.partners
        // ?.filter((partner) => partner?.sharesCount?.actual > 0) // TODO remove the comment
        .map((partner) => ({
          member: partner['_id'],
          name: partner?.name,
          email: partner?.email,
          userType: userTypes.PARTNER,
          assists: false,
          isAbsent: false,
          isRepresented: false,
          hasDelegated: false,
          representative: null,
          delegationVote: null,
          delegationDocuments: [],
          votes: [],
        }));
      const newActTemplate = {
        name: `${t('ActTemplate')}_${name}`,
        category: 'TEMPLATES',
        date: new Date(),
        lastAccess: new Date(),
        author: user['_id'],
        society: actualSociety['_id'],
        isGenerated: false,
        isFavorite: false,
        editorModel: globalActTemplate.editorModel,
        fileType: fileTypes.TEMPLATE,
      };
      const actTemplate = await store.dispatch(addDocument(newActTemplate));

      const newActCertificateTemplate = {
        name: `${t('ActCertificateTemplate')}_${name}`,
        category: 'TEMPLATES',
        date: new Date(),
        lastAccess: new Date(),
        author: user['_id'],
        society: actualSociety['_id'],
        isGenerated: false,
        isFavorite: false,
        editorModel: globalActCertificateTemplate.editorModel,
        fileType: fileTypes.TEMPLATE,
      };
      const actCertificateTemplate = await store.dispatch(
        addDocument(newActCertificateTemplate)
      );

      const newBoard = {
        name,
        status: boardStatus.PENDING.value,
        date: date ? new Date(date) : new Date(),
        attendanceType,
        place,
        announcement,
        onlineAccess,
        votePeriod,
        boardType,
        digitalVoting,
        orders,
        participants: newParticipants,
        president,
        secretary,
        delegationDocuments: [],
        delegationPartners: selectedDelegation === 1 ? selectedPartners : [],
        attachedDocuments: uploadedDocuments,
        announcementTemplate: announcementTemplate
          ? announcementTemplate['_id']
          : null,
        actTemplate: actTemplate ? actTemplate['_id'] : null,
        actCertificateTemplate: actCertificateTemplate
          ? actCertificateTemplate['_id']
          : null,
        society: actualSociety?.['_id'],
      };
      // generate announcement document
      const announcementDoc = updateDocumentWithValues(
        globalAnnouncementTemplate.editorModel,
        tags({
          society: actualSociety,
          board: newBoard,
        })
      );
      const options = {
        filename: `${t('AnnouncementBoard')}.pdf`,
        margin: [10, 10, 10, 10], // top, right, bottom, left
        pagebreak: { mode: 'avoid-all' },
        html2canvas: { scale: 2 },
        jsPDF: {
          unit: 'mm',
          format: 'a4',
          orientation: 'portrait',
        },
      };
      const fileBlob = await html2pdf()
        .from(announcementDoc?.html)
        .set(options)
        .output('blob');

      const announcementFile = new File(
        [fileBlob],
        `${t('AnnouncementBoard')}.pdf`,
        {
          type: 'application/pdf',
        }
      );
      const newAnnouncementDocument = {
        name: `${t('AnnouncementBoard')}.pdf`,
        file: announcementFile,
        fileType: fileTypes.PDF,
        size: announcementFile.size,
        date: new Date(),
        lastAccess: new Date(),
        category: documentTypes.DOCUMENTS,
        author: user['_id'],
        society: actualSociety['_id'],
        isGenerated: true,
        description: `Generado por la plantilla ${t(
          'AnnouncementTemplate'
        )}_${name}`,
      };
      const uploadedDoc = await store.dispatch(
        addDocument(newAnnouncementDocument)
      );
      if (uploadedDoc) {
        newBoard.announcementDocument = uploadedDoc['_id'];
      }

      const board = await store.dispatch(boardAction(newBoard, false));
      if (board) {
        Swal.fire({
          icon: 'success',
          title: `<h4 class="nk-modal-title">${t(
            'BoardCreatedSuccessfully'
          )}<br></h4>`,
          confirmButtonText: t('OK'),
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonColor: '#6576FF',
        });
        window.location.href = `#/detalle-junta/${actualSociety['_id']}/${board['_id']}`;
      }
      const currentEvent = eventTypes.ADD_BOARD;
      trackEvent(mixpanel, currentEvent, {
        userId: user?.['_id'],
        userName: user?.name,
        userEmail: user?.email,
        societyId: actualSociety?.['_id'],
        societyName: actualSociety?.name,
        operation: currentEvent,
      });
      setIsLoading(false);
      dispatch(setModal(null));
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  }

  async function editBoard(event) {
    event.preventDefault();
    try {
      setIsLoading(true);
      const updatedData = {
        name,
        date: date ? new Date(date) : new Date(),
        attendanceType,
        place,
        onlineAccess,
        votePeriod,
        boardType,
        digitalVoting,
        president,
        secretary,
        orders,
        delegationPartners: selectedDelegation === 1 ? selectedPartners : [],
        society: actualSociety?.['_id'],
      };
      // Update announcement
      if (
        societyDocuments?.length &&
        board?.announcementDocument &&
        board?.announcementTemplate
      ) {
        const announcementDocument = societyDocuments.find(
          (doc) => doc['_id'] === board?.announcementDocument
        );
        if (announcementDocument?.subcategory === documentTypes.BOARDS) {
          dispatch(updateBoard(board?.['_id'], updatedData, false));
          setIsUploadModalVisible(true);
          const currentEvent = eventTypes.EDIT_BOARD;
          trackEvent(mixpanel, currentEvent, {
            userId: user?.['_id'],
            userName: user?.name,
            userEmail: user?.email,
            societyId: actualSociety?.['_id'],
            societyName: actualSociety?.name,
            operation: currentEvent,
          });
        } else {
          const announcementTemplate = societyDocuments.find(
            (doc) => doc['_id'] === board?.announcementTemplate
          );

          if (announcementTemplate) {
            const newName = `${t('AnnouncementTemplate')}_${name}`;
            // Update announcementTemplate template name
            await store.dispatch(
              updateDocument(
                {
                  _id: board.announcementTemplate,
                  name: newName,
                },
                false
              )
            );
            // Delete actual announcementDocument
            await store.dispatch(
              updateDocument(
                {
                  _id: board.announcementDocument,
                  category: documentTypes.DELETED,
                },
                false
              )
            );
            // Generate new announcement document
            const newAnnouncementDoc = updateDocumentWithValues(
              announcementTemplate.editorModel,
              tags({
                society: actualSociety,
                board: updatedData,
              })
            );
            const options = {
              filename: `${t('AnnouncementBoard')}.pdf`,
              margin: [10, 10, 10, 10], // top, right, bottom, left
              pagebreak: { mode: 'avoid-all' },
              html2canvas: { scale: 2 },
              jsPDF: {
                unit: 'mm',
                format: 'a4',
                orientation: 'portrait',
              },
            };
            const fileBlob = await html2pdf()
              .from(newAnnouncementDoc?.html)
              .set(options)
              .output('blob');

            const announcementFile = new File(
              [fileBlob],
              `${t('AnnouncementBoard')}.pdf`,
              {
                type: 'application/pdf',
              }
            );
            const newAnnouncementDocument = {
              name: `${t('AnnouncementBoard')}.pdf`,
              file: announcementFile,
              fileType: fileTypes.PDF,
              size: announcementFile.size,
              date: new Date(),
              lastAccess: new Date(),
              category: documentTypes.DOCUMENTS,
              author: user['_id'],
              society: actualSociety['_id'],
              isGenerated: true,
              description: `Generado por la plantilla ${t(
                'AnnouncementTemplate'
              )}_${name}`,
            };
            const uploadedDoc = await store.dispatch(
              addDocument(newAnnouncementDocument)
            );
            if (uploadedDoc) {
              updatedData.announcementDocument = uploadedDoc['_id'];
            }
          }
          dispatch(updateBoard(board?.['_id'], updatedData, false));

          const currentEvent = eventTypes.EDIT_BOARD;

          trackEvent(mixpanel, currentEvent, {
            userId: user?.['_id'],
            userName: user?.name,
            userEmail: user?.email,
            societyId: actualSociety?.['_id'],
            societyName: actualSociety?.name,
            operation: currentEvent,
          });
          Swal.fire({
            icon: 'success',
            title: `<h4 class="nk-modal-title">${t(
              'BoardUpdatedSuccessfully'
            )}<br></h4>`,
            confirmButtonText: t('OK'),
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonColor: '#6576FF',
          });
          setIsLoading(false);
          dispatch(setModal(null));
        }
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  }

  useEffect(() => {
    switch (attendanceType) {
      case attendanceTypes.FACE_TO_FACE.value:
        setOnlineAccess('');
        break;
      case attendanceTypes.ONLINE.value:
      case attendanceTypes.WRITTEN_NO_SESSION.value:
        setPlace('');
        break;
      default:
        setPlace(getFullAddress(actualSociety?.legalAddress));
        setOnlineAccess(onlineAccess || '');
        break;
    }
  }, [attendanceType]);

  useEffect(() => {
    if (actualSociety) {
      setAnnouncement(
        `Apreciado socio, te comunicamos que ${actualSociety?.name} celebrará su junta.`
      );
      const societyAddress = getFullAddress(actualSociety?.legalAddress);
      setPlace(societyAddress);

      setDirectorOptions(
        actualSociety?.directors.map((director) => ({
          value: director?.['_id'],
          label: `${director?.socialDenomination} | ${director?.email}`,
        }))
      );

      const options = actualSociety?.partners
        // ?.filter((partner) => partner?.sharesCount?.actual >= 0) // TODO remove the comment
        ?.map((partner) => ({
          value: partner?.['_id'],
          label: `${partner?.name} | ${partner?.email}`,
        }));
      setPartnerOptions(options);
    }
  }, [actualSociety]);

  useEffect(() => {
    if (action === actionTypes.EDIT && board) {
      const {
        name,
        date,
        attendanceType,
        place,
        onlineAccess,
        votePeriod,
        boardType,
        digitalVoting,
        president,
        secretary,
        orders,
      } = board;
      setName(name);
      setDate(date ? format(new Date(date), "yyyy-MM-dd'T'HH:mm") : '');

      setAttendanceType(attendanceType);
      setSelectedAttendanceOption({
        value: attendanceTypes[attendanceType]?.value,
        label: attendanceTypes[attendanceType]?.text,
      });

      setPlace(place || '');
      setOnlineAccess(onlineAccess || '');

      setVotePeriod(votePeriod);
      const filteredVotePeriod = votePeriods.find(
        (period) => period.value === votePeriod
      );
      setSelectedVotePeriodOption({
        value: filteredVotePeriod?.value,
        label: filteredVotePeriod?.text,
      });

      setBoardType(boardType);
      setSelectedBoardTypeOption({
        value: boardTypes[boardType]?.value,
        label: boardTypes[boardType]?.text,
      });

      setDigitalVoting(digitalVoting);

      setPresident(president);
      const filteredPresident = actualSociety?.directors.find(
        (director) => director['_id'] === president
      );
      if (filteredPresident) {
        setSelectedPresidentOption({
          value: filteredPresident?.['_id'],
          label: `${filteredPresident?.socialDenomination} | ${filteredPresident?.email}`,
        });
      }

      setSecretary(secretary);
      const filteredSecretary = actualSociety?.directors.find(
        (director) => director['_id'] === secretary
      );
      if (filteredSecretary) {
        setSelectedSecretaryOption({
          value: filteredSecretary?.['_id'],
          label: `${filteredSecretary?.socialDenomination} | ${filteredSecretary?.email}`,
        });
      }
      setOrders(orders);

      if (board?.delegationPartners?.length) {
        // Set selected delegation options for delegation partners
        setSelectedDelegation(1);
        setSelectedDelegationOption({
          value: 1,
          label: t('OnlyCertainPartners'),
        });

        // Find the partners that are in the board delegation
        const filteredPartners = actualSociety?.partners.filter((partner) =>
          board?.delegationPartners.includes(partner['_id'])
        );

        // Set the selected partners
        setSelectedPartners(board?.delegationPartners);

        // Create and set the selected partners options
        setSelectedPartersOptions(
          filteredPartners.map((partner) => ({
            value: partner['_id'],
            label: `${partner?.name} | ${partner?.email}`,
          }))
        );
      } else {
        // Set selected delegation options for not delegation partners
        setSelectedDelegation(0);
        setSelectedDelegationOption({
          value: 0,
          label: t('AnyPartner'),
        });
      }
    }
  }, [action, board]);

  useEffect(() => {
    if (announcementFile) {
      handleUploadAnnouncementDocument(announcementFile);
    }
  }, [announcementFile]);

  useEffect(() => {
    // Set a new name if the name have a date and the board date change.
    if (action === actionTypes.ADD && date) {
      const newDate = new Date(date);
      updateTextWithNewDate(format(newDate, 'dd/MM/yyyy'));
    }
  }, [date]);

  return isUploadModalVisible ? (
    <UploadAnnouncementModal
      setAnnouncementFile={setAnnouncementFile}
      setIsUploadModalVisible={setIsUploadModalVisible}
    />
  ) : (
    <>
      <Modal.Header>
        {action === actionTypes.ADD ? (
          <h4 className="modal-title">
            {t('BoardPreparation')}
            <small className="d-block fs-14px text-soft">
              {t('LaterCanModify')}
            </small>
          </h4>
        ) : (
          <h4 className="modal-title">{t('EditBoard')}</h4>
        )}

        <a
          className="close cursor-pointer"
          onClick={() => dispatch(setModal(null))}
        >
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
      <Modal.Body>
        <>
          <div className="nk-block">
            <form
              className="form-contact g-4"
              onSubmit={action === actionTypes.ADD ? saveBoard : editBoard}
            >
              <div className="row">
                <div className="nk-block-head-content col-12">
                  <div className="form-group">
                    <label className="form-label">{t('BoardName')}</label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        placeholder={t('BoardExampleName')}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row g-2">
                <div className="nk-block-head-content col-12">
                  <h5 className="nk-block-title">{t('BasicInfo')}</h5>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label
                      className="form-label"
                      htmlFor="cp1-project-deadline"
                    >
                      {t('DateAndTime')}
                    </label>
                    <div className="form-control-wrap">
                      <input
                        type="datetime-local"
                        className="form-control date-picker"
                        id="cp1-project-deadline"
                        name="cp1-project-deadline"
                        value={date}
                        placeholder="dd/mm/yyyy"
                        onChange={(event) => setDate(event.target.value)}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label">
                      {t('FormOfAssistance')}
                    </label>
                    <div>
                      <Select
                        closeMenuOnSelect
                        value={selectedAttendanceOption}
                        className="react-select"
                        options={attendanceOptions}
                        components={animatedComponents}
                        onChange={handleAttendanceChange}
                      />
                    </div>
                  </div>
                </div>
                {attendanceType === attendanceTypes.FACE_TO_FACE.value ||
                attendanceType === attendanceTypes.HYBRID.value ? (
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="form-label">
                        {t('PlaceOfCelebration')}
                      </label>
                      <div className="form-control-wrap">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Si es presencial indicar dirección. "
                          value={place}
                          onChange={(e) => setPlace(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {attendanceType === attendanceTypes.ONLINE.value ||
                attendanceType === attendanceTypes.HYBRID.value ? (
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label className="form-label">
                        {t('OnlineAccessOptional')}
                      </label>
                      <div className="form-control-wrap">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Link de acceso o explicar cómo. "
                          value={onlineAccess}
                          onChange={(e) => setOnlineAccess(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label">{t('WhenCanYouVote')}</label>
                    <div>
                      <Select
                        closeMenuOnSelect
                        value={selectedVotePeriodOption}
                        className="react-select"
                        options={votePeriodOptions}
                        components={animatedComponents}
                        onChange={handleVotePeriodChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label">{t('BoardType')}</label>
                    <div>
                      <Select
                        closeMenuOnSelect
                        value={selectedBoardTypeOption}
                        className="react-select"
                        options={boardTypeOptions}
                        components={animatedComponents}
                        onChange={handleBoardTypeChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label">{t('President')}</label>
                    <div>
                      <Select
                        closeMenuOnSelect
                        value={selectedPresidentOption}
                        className="react-select"
                        options={directorOptions}
                        components={animatedComponents}
                        onChange={handlePresidentChange}
                        placeholder={t('SelectPresident')}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label">{t('Secretary')}</label>
                    <div>
                      <Select
                        closeMenuOnSelect
                        value={selectedSecretaryOption}
                        className="react-select"
                        options={directorOptions}
                        components={animatedComponents}
                        onChange={handleSecretaryChange}
                        placeholder={t('SelectSecretary')}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label className="form-label">
                      <span>{t('AssistanceDelegation')}</span>
                      <em
                        className="icon ni ni-info text-gray ml-2"
                        id="assistanceDelegation"
                      />
                      <Tooltip anchorId="assistanceDelegation" place="top">
                        {t('ToWhomAssistanceCanBeDelegated')}
                      </Tooltip>
                    </label>
                    <div className="form-control-wrap">
                      <Select
                        closeMenuOnSelect
                        value={selectedDelegationOption}
                        className="react-select"
                        options={delegationOptions}
                        components={animatedComponents}
                        defaultValue={{ value: 0, label: t('AnyPartner') }}
                        onChange={handleDelegationChange}
                      />
                    </div>
                  </div>
                </div>

                {selectedDelegation === 1 && (
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label className="form-label">
                        {t('SelectPartners')}
                      </label>
                      <div className="form-control-wrap">
                        <Select
                          closeMenuOnSelect={false}
                          value={selectedPartnersOptions}
                          className="react-select"
                          options={partnertOptions}
                          components={animatedComponents}
                          onChange={handlePartnerChange}
                          placeholder={t('Partners')}
                          isMulti
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {action === actionTypes.ADD ? (
                <div className="g-2">
                  <div className="nk-block-head-content col-12">
                    <div className="row">
                      <div className="col-md-6 d-flex align-items-center">
                        <h5 className="nk-block-title mb-0">
                          {t('Documentation')}
                        </h5>
                        <em
                          className="icon ni ni-info text-gray ml-2"
                          id="documentation-title"
                        />
                        <Tooltip anchorId="documentation-title" place="top">
                          {t('TheseDocumentsBeAvailablePartners')}
                        </Tooltip>
                      </div>
                      <div className="col-md-6 text-right">
                        <div className="form-group">
                          <label
                            htmlFor="custom-file"
                            className="btn btn-sm btn-dim btn-primary mb-0"
                          >
                            <em className="icon ni ni-plus" />
                            <span>{t('AddDocumentation')}</span>
                          </label>

                          <input
                            type="file"
                            id="custom-file"
                            name="custom-file"
                            style={{ display: 'none' }}
                            accept={TYPE_FILES_ALLOWED}
                            onChange={(event) =>
                              setFiles([...files, ...event.target.files])
                            }
                            multiple
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {files?.length > 0 ? (
                    <div className="card-borderer col-12 pt-0">
                      <div className="nk-files nk-files-view-list">
                        <div className="nk-files-head">
                          <div className="nk-file-item">
                            <div className="nk-file-info">
                              <div className="tb-head ml-4">{t('Name')}</div>
                            </div>

                            <div className="nk-file-meta d-none d-xl-table-cell text-center">
                              <div className="tb-head">{t('DocumentDate')}</div>
                            </div>
                          </div>
                        </div>
                        <div className="nk-files-list">
                          {files?.map((file, index) => (
                            <div className="nk-file-item nk-file">
                              <div className="nk-file-info">
                                <div className="nk-file-title w-100">
                                  <div className="nk-file-icon">
                                    <span className="nk-file-icon-type">
                                      <img
                                        src={getDocumentIcon(file, true)}
                                        alt="icon-file"
                                      />
                                    </span>
                                  </div>

                                  <div className="nk-file-name">
                                    <div
                                      className="nk-file-name-text d-flex flex-column"
                                      style={{ maxWidth: '300px' }}
                                    >
                                      <span
                                        className="title text-ellipsis"
                                        title={file?.name}
                                      >
                                        {file?.name}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="nk-file-meta d-none d-xl-table-cell">
                                <div className="tb-lead text-center">
                                  {getFileDate(file)}
                                </div>
                              </div>
                              <div className="text-center">
                                <button
                                  type="button"
                                  className="text-soft btn btn-icon"
                                  onClick={() => handleDeleteDocument(index)}
                                >
                                  <em className="icon ni ni-trash" />
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <></>
              )}

              <div className="row g-2">
                <div className="col-12">
                  {digitalVoting && (
                    <>
                      <ul>
                        <li className="order-md-last d-flex justify-content-between align-items-end mb-2">
                          <h5 className="nk-block-title mb-0">
                            {t('SubjectsToVote')}
                          </h5>
                          <button
                            type="button"
                            className="btn btn-sm btn-dim btn-primary"
                            onClick={handleDisplayOptionChange}
                          >
                            <em className="icon ni ni-plus" />
                            <span>{t('AddSubject')}</span>
                          </button>
                        </li>
                      </ul>

                      {displayOrderForm ? (
                        <>
                          <div className="card card-bordered">
                            <div className="card-inner">
                              <div className="form-group">
                                <label
                                  className="form-label"
                                  htmlFor="full-name"
                                >
                                  {t('Subject')}
                                </label>
                                <div className="form-control-wrap">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="full-name"
                                    value={subject}
                                    onChange={(event) =>
                                      setSubject(event.target.value)
                                    }
                                    required
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <label
                                  className="form-label"
                                  htmlFor="default-textarea"
                                >
                                  {t('Description')}
                                </label>
                                <div className="form-control-wrap">
                                  <textarea
                                    className="form-control"
                                    id="default-textarea"
                                    placeholder={t('SubjectText')}
                                    value={description}
                                    onChange={(event) =>
                                      setDescription(event.target.value)
                                    }
                                    style={{ minHeight: '50px' }}
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <label
                                  className="form-label"
                                  htmlFor="full-name"
                                >
                                  {t('AnswerVoteType')}
                                </label>
                                <div className="row">
                                  <div className="col-md-3">
                                    <div className="custom-control custom-control-sm custom-radio custom-control-pro">
                                      <input
                                        type="radio"
                                        className="custom-control-input"
                                        name="btnRadio"
                                        id="btnRadio1"
                                        value={voteTypes.YES_NO_ABS.value}
                                        checked={
                                          voteType ===
                                          voteTypes.YES_NO_ABS.value
                                        }
                                        onChange={(event) =>
                                          setVoteType(event.target.value)
                                        }
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor="btnRadio1"
                                      >
                                        {t('YesNoAbs')}
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="custom-control custom-control-sm custom-radio custom-control-pro">
                                      <input
                                        type="radio"
                                        className="custom-control-input"
                                        name="btnRadio"
                                        id="btnRadio4"
                                        value={voteTypes.NONE.value}
                                        checked={
                                          voteType === voteTypes.NONE.value
                                        }
                                        onChange={(event) =>
                                          setVoteType(event.target.value)
                                        }
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor="btnRadio4"
                                      >
                                        {t('None')}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <ul>
                                <div className="form-group">
                                  <label
                                    className="form-label"
                                    htmlFor="default-06"
                                  >
                                    {t('TheVoteIsApprovedWith')}
                                  </label>

                                  <div className="form-group row">
                                    <div className="form-group col-9 pr-0">
                                      <div>
                                        <Select
                                          closeMenuOnSelect
                                          value={selectedVoteValidationOption}
                                          className="react-select"
                                          options={voteValidationOptions}
                                          components={animatedComponents}
                                          onChange={handleVoteValidationChange}
                                        />
                                      </div>
                                    </div>
                                    <div className=" col-3 text-right">
                                      <button
                                        type="button"
                                        className="btn btn-primary"
                                        disabled={!subject || !voteType}
                                        onClick={saveOrder}
                                      >
                                        {t('Save')}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </ul>
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}

                      {!displayOrderForm && orders.length ? (
                        <div className="card-borderer">
                          <div className="nk-files nk-files-view-list">
                            <div className="nk-files-list">
                              {orders?.map((order, index) => (
                                <div className="nk-file-item nk-file">
                                  <div className="nk-file-info">
                                    <div className="nk-file-title w-100">
                                      <div className="nk-file-name">
                                        <div
                                          className="nk-file-name-text d-flex flex-column"
                                          style={{ maxWidth: '300px' }}
                                        >
                                          <span>
                                            {index + 1}.&nbsp;
                                            {order.subject || ''}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-auto">
                                    <div className="d-flex">
                                      <div>
                                        <button
                                          type="button"
                                          className="text-soft btn btn-icon"
                                          onClick={() => handleEditOrder(index)}
                                        >
                                          <em className="icon ni ni-edit" />
                                        </button>
                                      </div>
                                      <div>
                                        <button
                                          type="button"
                                          className="text-soft btn btn-icon"
                                          onClick={() =>
                                            handleDeleteOrder(index)
                                          }
                                        >
                                          <em className="icon ni ni-trash" />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-12 d-flex justify-content-end">
                  <button
                    className="btn btn-primary mt-1"
                    type="submit"
                    disabled={
                      attendanceType === 'default_option' ||
                      displayOrderForm ||
                      (selectedDelegation === 1 && !selectedPartners.length)
                    }
                  >
                    {action === actionTypes.ADD ? t('Save') : t('UpdateBoard')}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </>
      </Modal.Body>
      {isLoading && <CustomLoading />}
    </>
  );
};

export default AddBoard;
