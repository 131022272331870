import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setModal } from 'redux/actions/modalActions';
import { formatCurrencyDecimals, formatNumber } from 'utils/formats';

import BeneficiarySellInvoiceView from 'components/BeneficiarySellInvoiceView';

const BeneficiarySell = ({
  society,
  beneficiary,
  beneficiaryAllPlans,
  setSimulatorValue,
  societyValue,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const participant = beneficiary;

  const [isConfirmed, setIsConfirmed] = useState(false);

  const handleAcceptOffer = () => {};

  const closeModal = () => {
    dispatch(setModal(null));
    handleAcceptOffer();
  };

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title">{t('Simulador Valor Incentivo')}</h5>
        <a
          className="close cursor-pointer"
          onClick={() => dispatch(setModal(null))}
        >
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
      <Modal.Body>
        <BeneficiarySellInvoiceView
          participant={participant}
          society={society}
          societyValue={societyValue}
          beneficiary={beneficiary}
          beneficiaryAllPlans={beneficiaryAllPlans}
        />
      </Modal.Body>
    </>
  );
};

export default BeneficiarySell;
