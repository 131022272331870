/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-props-no-spreading */
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { formatNumber } from 'utils/formats';
import voteTypes from 'constants/voteTypes';
import voteValues from 'constants/voteValues';
import { updateBoard } from 'redux/actions/boardActions';
import userTypes from 'constants/userTypes';

function AssistantVoteRow({
  assistant,
  board,
  order,
  hasSelectedAll,
  customSelected,
  setCustomSelected,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => ({
    user: state.user,
  }));

  const isDemo = useSelector((state) => state.society?.role?.isDemo);

  const [selectedVote, setSelectedVote] = useState('default_option');
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    setSelectedVote('default_option');
  }, [order]);

  useEffect(() => {
    if (selectedVote !== 'default_option') {
      const participant = board?.participants?.find(
        (p) => p.member === assistant.member
      );
      if (participant) {
        const currentVote = participant?.votes?.find(
          (v) => v?.order === assistant?.orderData?.['_id']
        );
        if (
          (!currentVote && selectedVote !== 'no_vote') ||
          (currentVote && currentVote?.vote !== selectedVote)
        ) {
          const newVotes = participant?.votes?.filter(
            (v) => v?.order !== assistant?.orderData?.['_id']
          );
          if (selectedVote !== 'no_vote') {
            participant.assists = true;
            participant.isAbsent = false;
            newVotes.push({
              order: assistant?.orderData?.['_id'],
              vote: selectedVote,
              voterData: {
                voterId: user?.['_id'],
                voterType: userTypes.USER,
                voterName: user?.name,
              },
            });
            // List of participants represented by current voter which will be updated as assistants
            const participantsToUpdate = board?.participants?.filter(
              (p) => p?.representative === participant?.member
            );
            // eslint-disable-next-line no-restricted-syntax
            for (const currentParticipant of participantsToUpdate) {
              if (!currentParticipant?.assists) {
                // eslint-disable-next-line no-await-in-loop
                dispatch(
                  updateBoard(
                    board['_id'],
                    {
                      participantData: {
                        ...currentParticipant,
                        assists: true,
                        isAbsent: false,
                      },
                    },
                    false
                  )
                );
              }
            }
          }
          dispatch(
            updateBoard(board['_id'], {
              participantData: {
                ...participant,
                votes: newVotes,
              },
            })
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVote]);

  const filterVote = () => {
    if (!assistant?.hasVoted) {
      return { elem: 'badge-warning', text: t('NoVote') };
    }
    if (assistant?.orderData?.voteType === voteTypes.YES_NO_ABS.value) {
      if (assistant?.voteData?.vote === voteValues.YES.value) {
        return { elem: 'badge-success', text: t('Yes') };
      }
      if (assistant?.voteData?.vote === voteValues.NO.value) {
        return { elem: 'badge-danger', text: t('Nope') };
      }
      return { elem: 'badge-gray', text: t('Abstention') };
    }
    return { elem: 'badge-light', text: assistant?.voteData?.vote };
  };

  const voteBadge = filterVote();

  const handleSelectCustom = (id, checked) => {
    const currentSelected = {
      ...customSelected,
      [id]: checked,
    };
    setCustomSelected(currentSelected);
    setIsSelected(checked);
  };

  useEffect(() => {
    if (customSelected?.[assistant?._id]) {
      setIsSelected(true);
    } else {
      setIsSelected(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customSelected]);

  return (
    <tr className="nk-tb-item">
      <td className="nk-tb-col nk-tb-channel w-25 py-1">
        <div className=" d-flex flex-row justify-content-start align-items-center">
          <div className="nk-activity-media user-avatar bg-success mr-2">
            <img src={assistant?.image || ''} alt="" />
          </div>
          <span className="tb-lead">{assistant.name}</span>
        </div>
      </td>
      <td className="nk-tb-col nk-tb-sessions text-right w-15  py-1">
        <span className="tb-sub tb-amount">
          {formatNumber(assistant.sharesCount)}
        </span>
      </td>
      <td className="nk-tb-col nk-tb-change text-right w-15  py-1">
        <span className="tb-sub">{+assistant.percentage?.toFixed(3)}%</span>
      </td>
      <td className="nk-tb-col nk-tb-prev-sessions w-20  py-1">
        <span className="tb-sub tb-amount">
          {assistant?.representative?.name || '-'}
        </span>
      </td>
      <td className="nk-tb-col nk-tb-change py-1">
        <span className={`badge ${voteBadge.elem} align-center`}>
          {voteBadge.text}
        </span>
      </td>
      <td className="nk-tb-col nk-tb-change w-15 py-1">
        {assistant?.orderData?.voteType === voteTypes.YES_NO_ABS.value && (
          <div className="form-control-select">
            <select
              className="form-control form-control-sm ml-1"
              value={selectedVote}
              onChange={(event) => setSelectedVote(event.target.value)}
              disabled={isSelected || isDemo}
              required
            >
              <option value="default_option">{t('Vote')}</option>
              {Object.values(voteValues).map((voteValue) => (
                <option value={voteValue.value} key={JSON.stringify(voteValue)}>
                  {voteValue.text}
                </option>
              ))}
              <option value="no_vote">{t('NoVote')}</option>
            </select>
          </div>
        )}
      </td>
      <td className="nk-tb-col nk-tb-change py-1">
        <div className="custom-control custom-control-xs custom-checkbox mt-1">
          <input
            type="checkbox"
            id={`${assistant?.orderData?._id}-${assistant?._id}`}
            className="custom-control-input"
            value={isSelected}
            checked={isSelected}
            onChange={(e) =>
              handleSelectCustom(assistant?._id, e.target.checked)
            }
          />
          <label
            className="custom-control-label"
            htmlFor={`${assistant?.orderData?._id}-${assistant?._id}`}
          />
        </div>
      </td>
    </tr>
  );
}
export default AssistantVoteRow;
