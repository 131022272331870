import beneficiaryStatus from 'constants/beneficiaryStatus';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  data: {
    beneficiaryData: Record<string, any>;
    status: string;
  };
  actions: {
    handleGoBack: () => void;
  };
};

type Status = 'ACTIVE' | 'CANCELED' | 'CONSOLIDATED';

const statusBadges = {
  ACTIVE: { label: beneficiaryStatus.name.ACTIVE, style: 'text-warning' },
  CANCELED: { label: beneficiaryStatus.name.CANCELED, style: 'text-danger' },
  CONSOLIDATED: {
    label: beneficiaryStatus.name.CONSOLIDATED,
    style: 'text-success',
  },
};

const BeneficiaryBadge = ({ status }: { status: Status }) => {
  const statusStyle: any = statusBadges[status].style;
  const statusLabel = statusBadges[status].label;

  return (
    <span className={`badge rounded-pill ${statusStyle}`}>{statusLabel}</span>
  );
};

const BeneficiaryDetailsHeader: FC<Props> = ({ data, actions }) => {
  const { t } = useTranslation();

  const { beneficiaryData, status } = data;
  const { handleGoBack } = actions;

  return (
    <div className="nk-block-head">
      <div className="nk-block-between g-3">
        <div className="nk-block-head-content">
          <h3 className="nk-block-title page-title">
            {beneficiaryData?.isDraft ? `${t('BeneficiariesDraft')} ` : ''}
            {t('Beneficiary')} /{' '}
            <strong className="text-primary small mr-2">
              {beneficiaryData.name || ''}
            </strong>
            {beneficiaryData?.isDraft && (
              <span
                className={`badge rounded-pill bg-${beneficiaryStatus.style.DRAFT} text-white`}
              >
                {beneficiaryStatus?.name.DRAFT}
              </span>
            )}
            {!beneficiaryData?.isDraft &&
              status === beneficiaryStatus.name.CANCELED && (
                <BeneficiaryBadge status="CANCELED" />
              )}
            {!beneficiaryData?.isDraft &&
              status === beneficiaryStatus.name.CONSOLIDATED && (
                <BeneficiaryBadge status="CONSOLIDATED" />
              )}
            {!beneficiaryData?.isDraft &&
              status === beneficiaryStatus.name.ACTIVE && (
                <BeneficiaryBadge status="ACTIVE" />
              )}
          </h3>
        </div>
        <div className="nk-block-head-content">
          <span
            className="btn btn-outline-light bg-white d-none d-sm-inline-flex"
            onClick={handleGoBack}
          >
            <em className="icon ni ni-arrow-left" />
            <span>{t('Back')}</span>
          </span>
          <span
            className="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"
            onClick={handleGoBack}
          >
            <em className="icon ni ni-arrow-left" />
          </span>
        </div>
      </div>
    </div>
  );
};

export default BeneficiaryDetailsHeader;
