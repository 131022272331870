import store from 'redux/store';
import Swal from 'sweetalert2';
import i18n from 'i18n/config';
import { deleteStockPlan } from 'redux/actions/planActions';
import { getActualSociety } from './filters';

export default function removePlan(params, planId) {
  const { planName, user, societyId } = params;

  Swal.fire({
    icon: 'warning',
    title:
      `<h4 class="nk-modal-title">${i18n.t('AreYouSureAboutDelPlan')}</h4>`,
    html: `<h5 class="text-primary">${planName}</h5><br /><div class="caption-text">${i18n.t('ThisProcedureCantUndo')}</div>`,
    confirmButtonText: i18n.t('OkDelete'),
    confirmButtonColor: '#6576ff',
    allowOutsideClick: false,
    showCancelButton: true,
  }).then(async (result) => {
    if (result.isConfirmed) {
      await store.dispatch(deleteStockPlan(planId));
      getActualSociety(user, societyId);
    }
  });
}
