/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-props-no-spreading */
import UserDropdown from 'components/Modals/UserDropdown';
import directorTypes from 'components/SocietyDirectors/directorTypes';
import statusTypes from 'components/SocietyDirectors/statusTypes';
import { SELECCIONAR_SOCIO } from 'constants/defaultConstants';
import React, { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { setModal } from 'redux/actions/modalActions';
import { updateSociety as updateSocietyAction } from 'redux/actions/societyActions';
import { getDirectorStatus } from 'utils/filters';
import adjustImageSize from 'utils/images';
import { useTranslation } from 'react-i18next';
import './Modal.scss';

const pageTypes = {
  sociedad: 0,
  direccion: 1,
};

function EditModal({ society, dispatch }) {
  const { t, i18n } = useTranslation();
  const [page, setPage] = useState(false);
  const [socialDenomination, setSocialDenomination] = useState(society.name);
  const [CIF, setCIF] = useState(society.cif);
  const [web, setWeb] = useState(society.web);
  const [phone, setPhone] = useState(society.phone);
  const [logo, setLogo] = useState(society.additional.logo);
  const [mainDirector, setMainDirector] = useState();
  const [directors, setDirectors] = useState();
  const [description, setDescription] = useState(
    society.additional?.description
  );
  const [sendNotifications, setSendNotifications] = useState(
    society?.sendNotifications
  );
  const [hasAccessCaptable, setHasAccessCaptable] = useState(
    society?.hasAccessCaptable || false
  );
  const [isSocietyHolding, setIsSocietyHolding] = useState(
    society?.isSocietyHolding || false
  );
  const [isSocietyDemo, setIsSocietyDemo] = useState(
    society?.isSocietyDemo || false
  );
  const [updatedLogo, setUpdatedLogo] = useState(false);

  const [line1, setLine1] = useState(society.legalAddress?.line1 || '');
  const [zip, setZip] = useState(society.legalAddress?.zip || '');
  const [city, setCity] = useState(society.legalAddress?.city || '');
  const [country, setCountry] = useState(society.legalAddress?.country || '');

  const [currStatusTypes, setCurrStatusTypes] = useState(statusTypes(i18n));
  const [currDirectorTypes, setCurrDirectorTypes] = useState(
    directorTypes(i18n)
  );

  const animatedComponents = makeAnimated();

  const languageOption = [
    {
      value: 'es',
      label: t('Spanish'),
    },
    {
      value: 'en',
      label: t('English'),
    },
  ];
  const languageLabels = {
    es: t('Spanish'),
    en: t('English'),
  };

  const selectedLanguage = useRef(society?.societyLanguage);
  const [selectedLanguageOption, setSelectedLanguageOption] = useState({
    value: society?.societyLanguage,
    label: languageLabels[society?.societyLanguage],
  });

  const handleLanguageOptionChange = (selectedOption) => {
    setSelectedLanguageOption(selectedOption);
    selectedLanguage.current = selectedOption.value;
  };

  async function fileSelected(event) {
    if (event.target.files.length > 0) {
      const reader = new FileReader();
      reader.onloadend = (e) => {
        setLogo(e.target.result);
        setUpdatedLogo(true);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  function updateSociety(event) {
    event.preventDefault();
    const newSocietyData = {
      name: socialDenomination,
      cif: CIF,
      web,
      phone,
      additional: {
        ...(logo && { logo }),
        ...(description && { description }),
        description,
      },
      mainDirector:
        mainDirector && mainDirector !== SELECCIONAR_SOCIO
          ? JSON.parse(mainDirector)?.id
          : null,
      legalAddress: {
        line1,
        zip,
        city,
        country,
      },
      sendNotifications,
      hasAccessCaptable,
      isSocietyHolding,
      isSocietyDemo,
      societyLanguage: selectedLanguage.current,
    };
    dispatch(updateSocietyAction(society['_id'], newSocietyData));
    dispatch(setModal(null));
  }

  useEffect(() => {
    const resizedImage = adjustImageSize(
      'society-canvas',
      'society-image',
      200
    );
    setLogo(resizedImage);
    setUpdatedLogo(false);
  }, [updatedLogo]);

  useEffect(() => {
    if (society.mainDirector) {
      const director = society?.directors.find(
        (d) => d?.['_id'] === society.mainDirector
      );
      setMainDirector(
        JSON.stringify({ id: director['_id'], cif: director?.cif })
      );
    }
    const availableDirectors = society?.directors.filter(
      (d) =>
        (d.directorType === currDirectorTypes.var.UNIQUE_ADMIN ||
          d.directorType === currDirectorTypes.var.DELEGATE_COUNSELOR ||
          d.directorType === currDirectorTypes.var.COUNCIL_SECRETARY) &&
        getDirectorStatus(d) === currStatusTypes.var.ACTIVE
    );
    setDirectors(availableDirectors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [society]);

  useEffect(() => {
    setCurrStatusTypes(statusTypes(i18n));
    setCurrDirectorTypes(directorTypes(i18n));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return (
    <>
      <Modal.Header>
        <h5 className="title">{t('UpdateSociety')}</h5>
        <a
          className="close cursor-pointer"
          onClick={() => dispatch(setModal(null))}
        >
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
      <Modal.Body>
        <ul className="nk-nav nav nav-tabs">
          <li className="nav-item">
            <p
              className={`nav-link ${!page ? 'active' : ''}`}
              onClick={() => setPage(pageTypes.sociedad)}
            >
              {t('Society')}
            </p>
          </li>
          <li className="nav-item">
            <p
              className={`nav-link ${
                page === pageTypes.direccion ? 'active' : ''
              }`}
              onClick={() => setPage(pageTypes.direccion)}
            >
              {t('Address')}
            </p>
          </li>
        </ul>
        <div className="tab-content">
          <form onSubmit={updateSociety}>
            {!page && (
              <div className={`tab-pane ${!page ? 'active' : ''}`}>
                <div className="row gy-4">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="full-name">
                        {t('SocialDenomination')}
                      </label>
                      <input
                        id="full-name"
                        type="text"
                        className="form-control form-control-lg"
                        value={socialDenomination}
                        onChange={(event) =>
                          setSocialDenomination(event.target.value)
                        }
                        placeholder="Denominación Social"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="society-cif">
                        CIF
                      </label>
                      <input
                        id="society-cif"
                        type="text"
                        className="form-control form-control-lg"
                        value={CIF}
                        onChange={(event) => setCIF(event.target.value)}
                        placeholder="Enter display name"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="society-website">
                        Web
                      </label>
                      <input
                        id="society-website"
                        type="text"
                        className="form-control form-control-lg date-picker"
                        value={web}
                        onChange={(event) => setWeb(event.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="phone-number">
                        {t('Telephone')}
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="phone-number"
                        value={phone}
                        onChange={(event) => setPhone(event.target.value)}
                        placeholder={t('PhoneNumber')}
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label
                        className="form-label"
                        htmlFor="partners-notification"
                      >
                        {t('NotifyThePartners')}
                      </label>
                      <div className="form-control-wrap ">
                        <div className="form-control-select">
                          <select
                            id="partners-notification"
                            name="partners-notification"
                            className="form-control"
                            value={sendNotifications}
                            onChange={(event) =>
                              setSendNotifications(event.target.value)
                            }
                          >
                            <option value={false}>No</option>
                            <option value>{t('Yes')}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label
                        className="form-label"
                        htmlFor="partners-access-captable"
                      >
                        {t('EnablePartnerAccessToCaptable')}
                      </label>
                      <div className="form-control-wrap ">
                        <div className="form-control-select">
                          <select
                            className="form-control"
                            id="partners-access-captable"
                            name="partners-access-captable"
                            value={hasAccessCaptable}
                            onChange={(event) =>
                              setHasAccessCaptable(event.target.value)
                            }
                          >
                            <option value={false}>No</option>
                            <option value>{t('Yes')}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="society-holding">
                        {t('IsHoldingSociety')}
                      </label>
                      <div className="form-control-wrap ">
                        <div className="form-control-select">
                          <select
                            className="form-control"
                            id="society-holding"
                            name="society-holding"
                            value={isSocietyHolding}
                            onChange={(event) =>
                              setIsSocietyHolding(event.target.value)
                            }
                          >
                            <option value={false}>No</option>
                            <option value>{t('Yes')}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="society-demo">
                        {t('IsDemoSociety')}
                      </label>
                      <div className="form-control-wrap ">
                        <div className="form-control-select">
                          <select
                            className="form-control"
                            id="society-demo"
                            name="society-demo"
                            value={isSocietyDemo}
                            onChange={(event) =>
                              setIsSocietyDemo(event.target.value)
                            }
                          >
                            <option value={false}>No</option>
                            <option value>{t('Yes')}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="main-admin">
                        {t('CommunicationsLanguage')}
                      </label>
                      <div className="form-control-wrap">
                        <Select
                          closeMenuOnSelect
                          className="react-select"
                          value={selectedLanguageOption}
                          options={languageOption}
                          components={animatedComponents}
                          onChange={handleLanguageOptionChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="main-admin">
                        {t('SelectedAdministrator')}
                      </label>
                      <UserDropdown
                        users={directors}
                        action={setMainDirector}
                        user={mainDirector}
                        directorsData
                      />
                    </div>
                  </div>

                  <div className="col-md-4 col-8">
                    <div className="form-group">
                      <label className="form-label" htmlFor="society-logo">
                        Logo
                      </label>
                      <input
                        id="society-logo"
                        type="file"
                        className="form-control-file form-control-lg cursor-pointer"
                        accept="image/*"
                        onChange={fileSelected}
                      />
                    </div>
                  </div>
                  <div className="col-md-2 col-4">
                    <canvas
                      className="bg-primary"
                      id="society-canvas"
                      width="200"
                      height="200"
                    >
                      {logo ? (
                        <img id="society-image" src={logo} alt={society.name} />
                      ) : (
                        <span>{society.name.slice(0, 2).toUpperCase()}</span>
                      )}
                    </canvas>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group">
                      <label
                        className="form-label"
                        htmlFor="society-description"
                      >
                        {t('BriefDescription')}
                      </label>
                      <textarea
                        id="society-description"
                        rows="2"
                        maxLength="150"
                        className="form-control form-control-lg date-picker"
                        value={description}
                        onChange={(event) => setDescription(event.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {page === pageTypes.direccion && (
              <div className={`tab-pane ${page === 1 ? 'active' : ''}`}>
                <div className="row gy-4">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="address-l1">
                        {t('Address')}
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="address-l1"
                        value={line1}
                        onChange={(event) => setLine1(event.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="address-zip">
                        {t('PostalCode')}
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="address-zip"
                        value={zip}
                        onChange={(event) => setZip(event.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="address-city">
                        {t('City')}
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="address-city"
                        value={city}
                        onChange={(event) => setCity(event.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="address-country">
                        {t('Country')}
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="address-country"
                        value={country}
                        onChange={(event) => setCountry(event.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="row mt-4">
              <div className="col-12">
                <button type="submit" className="btn btn-lg btn-primary">
                  {t('UpdateInformation')}
                </button>
                <button
                  type="button"
                  className="btn btn-lg btn-light ml-3"
                  onClick={() => dispatch(setModal(null))}
                >
                  {t('Close')}
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal.Body>
    </>
  );
}

function mapStateToProps({ societies }) {
  return { societies };
}

export default connect(mapStateToProps)(EditModal);
