/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useMixpanel } from 'react-mixpanel-browser';

import { setMenu } from 'redux/actions/menuActions';
import { setModal } from 'redux/actions/modalActions';
import { getHoldingClasses } from 'redux/actions/holdingClassActions';
import { getStockPlans } from 'redux/actions/planActions';
import validateOutdatedSocietyValue from 'redux/actions/validationActions';
import { getPartnerCategories } from 'redux/actions/partnerCategoryActions';

import menuTypes from 'constants/menuTypes';
import operationTypes from 'constants/operationTypes';

import {
  getActualSociety,
  getDecimalScale,
  getPartnerFDPercent,
  getPartnerNDPercent,
  getPartnerNominalValue,
  getPartnerPotentialFD,
  getPartnerPremium,
  getSocietyValue,
  getFullAddress,
} from 'utils/filters';
import activePartner from 'utils/activePartner';

import OperationMenuDots from 'components/MenuDots/OperationMenuDots';
import UploadPartner from 'components/Modals/UploadPartner';

import CustomDataTable from 'components/Tables/CustomDataTable';
import transformData from 'components/Tables/helpers';
import CustomLoading from 'components/CustomLoading';

import menuOptions from './menuOptions';
import tableColumns from './tableColumns';

function PartnerList() {
  const { t, i18n } = useTranslation();
  const mixpanel = useMixpanel();
  const dispatch = useDispatch();
  const { societyId, userId } = useParams();

  const user = useSelector((state) => state.user);
  const plans = useSelector((state) => state?.plans);
  const isAdmin = useSelector((state) => state.society?.role?.isAdmin);
  const actualSociety = useSelector((state) => state.society?.actualSociety);
  const hasAccessCaptable = useSelector(
    (state) => state.society?.actualSociety?.hasAccessCaptable
  );
  const isDemo = useSelector((state) => state.society?.role?.isDemo);

  const [showNationality, setShowNationality] = useState(false);

  const [filteredMenuOptions, setFilteredMenuOptions] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [customData, setCustomData] = useState([]);
  const [currentTableColumns, setCurrentTableColumns] = useState(
    tableColumns(i18n, showNationality)
  );
  const [currentMenuOptions, setCurrentMenuOptions] = useState(
    menuOptions(i18n)
  );

  const [activePartnersTotal, setActivePartnersTotal] = useState(0);

  useEffect(() => {
    if (societyId) {
      dispatch(getStockPlans(societyId));
      dispatch(getHoldingClasses(societyId));
      dispatch(getPartnerCategories(societyId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [societyId]);

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId) {
      getActualSociety(user, societyId);
    }
  }, [actualSociety, user, societyId]);

  useEffect(() => {
    if (actualSociety?.['_id'] === societyId) {
      const searchParams = new URLSearchParams(location.href.split('?')[1]);
      const categoryId = searchParams.get('categoria');
      const partners = categoryId
        ? actualSociety.partners?.filter(
            (partner) => partner?.category === categoryId
          )
        : actualSociety.partners;
      const societyValue = getSocietyValue(actualSociety);
      const decimalScale = societyValue
        ? getDecimalScale(societyValue.value)
        : 2;
      const newData = partners
        .filter((partner) => partner?.sharesCount?.actual >= 0)
        .filter((partner) =>
          // TODO*: create a function with different cases instead of inline validations
          userId || (!userId && !hasAccessCaptable && !isAdmin)
            ? user['_id'] === partner?.user ||
              partner?.otherUsers?.includes(user['_id']) ||
              user?.usersWithAccess.some(
                (currentUser) => partner?.user === currentUser?.['_id']
              )
            : true
        )
        .map((partner, index) => ({
          index: index + 1,
          name: partner.name,
          email: partner.email,
          cif: partner?.cif || '-',
          shares: partner.sharesCount.actual,
          decimals: decimalScale,
          NDPercent: getPartnerNDPercent(partner, actualSociety),
          FDPercent: getPartnerFDPercent(partner, actualSociety),
          FDPotential: getPartnerPotentialFD(partner, actualSociety, plans),
          partner,
          society: actualSociety,
          societyId: actualSociety['_id'],
          partnerNominalValue: parseFloat(
            getPartnerNominalValue(partner?.shares, actualSociety).toFixed(2)
          ),
          partnerPremium: parseFloat(
            getPartnerPremium(partner?.shares, actualSociety).toFixed(2)
          ),
          isActive: activePartner(partner),
          hasLink:
            partner?.user === user['_id'] ||
            partner?.otherUsers?.includes(user['_id']) ||
            isAdmin,
          userId: user?.['_id'],
          nationality: partner?.nationality || '-',
          isDemo,
        }));

      const newCustomData = actualSociety?.partners.map((partner) => ({
        NOMBRE: partner.name,
        CIF: partner?.cif,
        EMAIL: partner?.email,
        NACIONALIDAD: partner?.nationality || '-',
        PARTICIPACIONES: partner.sharesCount.actual,
        DIRECCION: getFullAddress(partner?.address),
        '%ND': parseFloat(
          getPartnerNDPercent(partner, actualSociety).toFixed(2)
        ),
        '%FD': parseFloat(
          getPartnerFDPercent(partner, actualSociety).toFixed(2)
        ),
        '% Potential FD': parseFloat(
          getPartnerPotentialFD(partner, actualSociety, plans).toFixed(2)
        ),
        Nominal: parseFloat(
          getPartnerNominalValue(partner?.shares, actualSociety).toFixed(2)
        ),
        'Total Prima': parseFloat(
          getPartnerPremium(partner?.shares, actualSociety).toFixed(2)
        ),
      }));

      setCustomData(newCustomData);

      if (newData.length > 0) {
        setTableData(
          transformData({
            data: newData,
            columns: tableColumns(i18n, showNationality),
          })
        );
      } else {
        setTableData([]);
      }

      if (!actualSociety.partners.length) {
        const [addPartnerOption, constitutionOption] = currentMenuOptions;
        setFilteredMenuOptions([addPartnerOption, constitutionOption]);
      } else if (!actualSociety.shares.length) {
        const isSocietyConstituted = actualSociety.operations.some(
          (operation) => operation.operationType === operationTypes.CONSTITUTION
        );

        const [addPartnerOption, constitutionOption, capitalIncreaseOption] =
          currentMenuOptions;

        setFilteredMenuOptions([
          addPartnerOption,
          isSocietyConstituted ? capitalIncreaseOption : constitutionOption,
        ]);
      } else {
        const options = currentMenuOptions.filter(
          (option, index) => index !== 1
        );
        setFilteredMenuOptions(options);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety, plans, userId, i18n.language, currentMenuOptions]);

  useEffect(() => {
    if (actualSociety) {
      dispatch(
        setMenu({
          type: menuTypes.EXTENDED,
          societyId: actualSociety?.['_id'],
          societyName: actualSociety?.name,
        })
      );
      if (isAdmin) {
        dispatch(validateOutdatedSocietyValue(actualSociety));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety, dispatch]);

  useEffect(() => {
    if (actualSociety?.partners) {
      const activePartners = actualSociety.partners.filter((partner) =>
        activePartner(partner)
      ).length;
      setActivePartnersTotal(activePartners);
    }
  }, [actualSociety]);

  useEffect(() => {
    setCurrentTableColumns(tableColumns(i18n, showNationality));
    setCurrentMenuOptions(menuOptions(i18n));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language, showNationality]);

  return actualSociety ? (
    <>
      <div className="nk-content-body">
        <div className="nk-content-wrap">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">{actualSociety.name}</h3>
          </div>
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title"> </h3>
          </div>
          <div className="nk-block-head">
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <h3 className="nk-block-title page-title">{t('Partners')}</h3>
                <div className="nk-block-des text-soft">
                  <p>
                    {`${actualSociety?.name} ${t('HavePartners', {
                      count: activePartnersTotal,
                    })}`}
                  </p>
                </div>
              </div>

              <div className="nk-block-head-content">
                <div className="toggle-wrap nk-block-tools-toggle">
                  <div
                    className="toggle-expand-content"
                    data-content="pageMenu"
                  >
                    <ul className="nk-block-tools g-3">
                      {isAdmin && (
                        <>
                          <li>
                            <button
                              type="button"
                              className="btn btn-white btn-outline-light"
                              onClick={() =>
                                dispatch(
                                  setModal(
                                    <UploadPartner
                                      user={user}
                                      societyId={actualSociety['_id']}
                                    />
                                  )
                                )
                              }
                              disabled={isDemo}
                            >
                              <em className="icon ni ni-upload-cloud" />
                              <span>{t('UploadListing')}</span>
                            </button>
                          </li>
                          <li>
                            <OperationMenuDots
                              menuOptions={filteredMenuOptions}
                              handleModal={(Modal) =>
                                dispatch(
                                  setModal(
                                    <Modal
                                      user={user}
                                      societyId={actualSociety['_id']}
                                    />
                                  )
                                )
                              }
                              params={{
                                society: actualSociety,
                                user,
                                mixpanel,
                              }}
                              disabled={isDemo}
                            />
                          </li>
                        </>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {tableData?.length > 0 ? (
            <>
              <CustomDataTable
                data={tableData}
                columns={currentTableColumns}
                searchBy={[t('Partner'), t('Email'), t('CIF')]}
                searchByPlaceholder={[t('name'), t('email'), t('CIF')]}
                defaultSortFieldId={6}
                defaultSortAsc={false}
                pagination
                className="nk-tb-list"
                expandableRows={false}
                actions
                showDense
                showDownload
                showNationality
                toggleNationality={() => setShowNationality(!showNationality)}
                customData={customData}
                filterByParam="isActive"
                filterText={{
                  true: t('SeeOld'),
                  false: t('HideOld'),
                }}
                filterIcon={{
                  true: 'ni ni-eye',
                  false: 'ni ni-eye-off',
                }}
                fileName={`${t('PartnersList')} ${actualSociety.name}`}
              />
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  ) : (
    <CustomLoading />
  );
}

export default PartnerList;
