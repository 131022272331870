/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import ReactDOM from 'react-dom';
import LogRocket from 'logrocket';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { MixpanelProvider } from 'react-mixpanel-browser';

import * as Sentry from '@sentry/react';
import setupLogRocketReact from 'logrocket-react';

import store from 'redux/store';

import App from './App';

import './i18n/config';

if (process.env.NODE_ENV !== 'development') {
  LogRocket.init(process.env.REACT_APP_LOG_ROCKET_ID, {
    shouldDebugLog: false,
  });
  setupLogRocketReact(LogRocket);
}

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: [
        /^https:\/\/sttok-api\.es\/api/,
        /^https:\/\/sociolegal\.es\/api/,
      ],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  enabled: process.env.NODE_ENV !== 'development',
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

ReactDOM.render(
  <BrowserRouter>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      redirectUri={`${window.location.origin}`}
    >
      <Provider store={store}>
        <MixpanelProvider>
          <App />
        </MixpanelProvider>
      </Provider>
    </Auth0Provider>
  </BrowserRouter>,
  document.getElementById('root')
);
