import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setModal } from 'redux/actions/modalActions';
import { updateSociety } from 'redux/actions/societyActions';
import store from 'redux/store';

function CouncilMinuteModal({ societyId }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { actualSociety } = useSelector((state) => ({
    actualSociety: state.society?.actualSociety,
  }));
  const [councilMinuteName, setCouncilMinuteName] = useState('');

  async function saveCouncilMinute(event) {
    event.preventDefault();
    const newCouncilMinute = {
      label: councilMinuteName,
    };
    const newCouncilMinuteData = actualSociety?.councilMinutes
      ? [...actualSociety.councilMinutes, newCouncilMinute]
      : [newCouncilMinute];
    const newSocietyData = {
      councilMinutes: newCouncilMinuteData,
    };

    dispatch(updateSociety(societyId, newSocietyData));

    dispatch(setModal(null));
  }

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title">{t('CreateCouncilMinute')}</h5>
        <a className="close" onClick={() => store.dispatch(setModal(null))}>
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
      <Modal.Body>
        <form className="form-validate is-alter" onSubmit={saveCouncilMinute}>
          <div className="form-group">
            <label className="form-label" htmlFor="full-name">
              {t('Name')}
            </label>
            <div className="form-control-wrap">
              <input
                type="text"
                className="form-control"
                value={councilMinuteName}
                onChange={(event) => setCouncilMinuteName(event.target.value)}
              />
            </div>
          </div>

          <button
            type="submit"
            className="btn btn-lg btn-primary"
            disabled={!councilMinuteName}
          >
            {t('Save')}
          </button>
        </form>
      </Modal.Body>
    </>
  );
}

export default connect()(CouncilMinuteModal);
