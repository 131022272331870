import React from 'react';
import store from 'redux/store';
import { setModal } from 'redux/actions/modalActions';

import CancelBeneficiaryModal from 'components/Modals/CancelBeneficiary';

export default function cancelBeneficiary(params, beneficiaryId) {
  const { data } = params;
  store.dispatch(
    setModal(
      <CancelBeneficiaryModal
        beneficiaryId={beneficiaryId}
        beneficiary={data}
      />
    )
  );
}
