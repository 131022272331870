/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import store from 'redux/store';
import { setModal } from 'redux/actions/modalActions';
import presetRecipients from 'constants/presetRecipients';
import {
  MAX_FILESIZE_ALLOWED,
  PDF_TYPE_FILE_ALLOWED,
} from 'constants/defaultConstants';
import fileTypes from 'constants/fileTypes';
import documentTypes from 'constants/documentTypes';
import subcategoryTypes from 'constants/subcategoryTypes';
import { addDocument } from 'redux/actions/documentActions';
import signatureTypes from 'constants/signatureTypes';
import { startSignProcess, toBase64 } from 'utils/docusignActions';
import { addAlert } from 'redux/actions/alertActions';
import alertBodyTypes from 'components/Alert/alertBodyTypes';

const ALL = 'ALL';

function SignatureModal() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { actualSociety, user } = useSelector((state) => ({
    user: state.user,
    actualSociety: state.society?.actualSociety,
  }));

  const [file, setFile] = useState(null);
  const [selectedRole, setSelectedRole] = useState('');
  const [signer, setSigner] = useState('');
  const [message, setMessage] = useState('');

  const [signersList, setSignersList] = useState([]);

  const labelSections = {
    [presetRecipients.PARTNERS]: t('SelectPartner'),
    [presetRecipients.BENEFICIARIES]: t('SelectBeneficiary'),
    [presetRecipients.DIRECTORS]: t('SelectDirectorship'),
    [presetRecipients.INVESTOR]: t('SelectInvestor'),
  };

  const roleToApi = {
    [presetRecipients.PARTNERS]: 'PARTNER',
    [presetRecipients.BENEFICIARIES]: 'BENEFICIARY',
    [presetRecipients.DIRECTORS]: 'DIRECTOR',
    [presetRecipients.INVESTOR]: 'INVESTOR',
  };

  const signaturesByRole = {
    [presetRecipients.PARTNERS]: signatureTypes.FREE_SIGNATURE_PARTNER,
    [presetRecipients.BENEFICIARIES]: signatureTypes.FREE_SIGNATURE_BENEF,
    [presetRecipients.DIRECTORS]: signatureTypes.FREE_SIGNATURE_DIRECTOR,
    [presetRecipients.INVESTOR]: signatureTypes.FREE_SIGNATURE_INVESTOR,
  };

  const signaturesByRoleAll = {
    [presetRecipients.PARTNERS]: signatureTypes.FREE_SIGNATURE_PARTNER_ALL,
    [presetRecipients.BENEFICIARIES]: signatureTypes.FREE_SIGNATURE_BENEF_ALL,
    [presetRecipients.DIRECTORS]: signatureTypes.FREE_SIGNATURE_DIRECTOR_ALL,
    [presetRecipients.INVESTOR]: signatureTypes.FREE_SIGNATURE_INVESTOR_ALL,
  };

  const getSignersData = (signerId) => {
    let signers = [];

    if (signerId === ALL) {
      signers = signersList.slice(1).map((signer) => ({
        id: signer.id,
        email: signer.email,
        name: signer.name,
        role: roleToApi[selectedRole],
        type: signaturesByRoleAll[selectedRole],
      }));
    } else {
      const signerData = signersList.find((signer) => signer.id === signerId);
      signers = [
        {
          id: signerId,
          email: signerData?.email,
          name: signerData?.name,
          role: roleToApi[selectedRole],
          type: signaturesByRole[selectedRole],
        },
      ];
    }

    return signers;
  };

  const getRedirectParams = (selectedRole, signerId) => {
    if (signerId === ALL) return [actualSociety['_id']];

    const signerData = signersList.find((signer) => signer.id === signerId);

    switch (selectedRole) {
      case presetRecipients.PARTNERS:
        return [actualSociety['_id'], signerId];
      case presetRecipients.BENEFICIARIES:
        return [actualSociety['_id'], signerData.plan, signerId];
      case presetRecipients.DIRECTORS:
      case presetRecipients.INVESTOR:
      default:
        return ['', '', ''];
    }
  };

  const sendSignature = async (e) => {
    e.preventDefault();

    try {
      const { name, size, type } = file;

      const newSignature = {
        name,
        file: null,
        size,
        date: new Date(),
        fileType: fileTypes[type],
        lastAccess: new Date(),
        isGenerated: false,
        author: user?._id,
        society: actualSociety?._id,
        category: documentTypes.SIGNATURES,
        subcategory: subcategoryTypes.SIGNATURES.PENDING,
        description: t('SentByUsername', {
          name: user?.name,
        }),
        url: '',
      };

      const uploadedDocument = await store.dispatch(addDocument(newSignature));

      const signers = getSignersData(signer);

      const redirectParams = getRedirectParams(selectedRole, signer);

      const signFileBase64 = await toBase64(file);

      const signProcessData = {
        signers,
        societyName: actualSociety?.name,
        documentName: name,
        planName: '',
        language: actualSociety?.societyLanguage || 'es',
        redirectParams,
        customParams: `${roleToApi[selectedRole]}/${uploadedDocument?._id}`,
        signatureType: signatureTypes.FREE_SIGNATURE,
        message,
        signatureFile: signFileBase64,
        signatureFileName: name,
      };

      await startSignProcess(signProcessData);

      dispatch(setModal(null));
      dispatch(addAlert(alertBodyTypes.SIGNATURE_SENT));
    } catch (error) {
      console.log(error);
    } finally {
      // dispatch(setModal(null));
    }
  };

  const getSignersList = (role) => {
    let list = [];

    switch (role) {
      case presetRecipients.PARTNERS:
        list = actualSociety?.partners?.map((partner) => ({
          id: partner?.['_id'],
          text: `${partner?.name} | ${partner?.email}`,
          name: partner?.name,
          email: partner?.email,
        }));
        break;

      case presetRecipients.BENEFICIARIES:
        list = actualSociety?.beneficiaries?.map((beneficiary) => ({
          id: beneficiary?.['_id'],
          text: `${beneficiary?.name} | ${beneficiary?.email}`,
          name: beneficiary?.name,
          email: beneficiary?.email,
          plan: beneficiary?.plan,
        }));
        break;

      case presetRecipients.DIRECTORS:
        list = actualSociety?.directors?.map((director) => ({
          id: director?.['_id'],
          text: `${director?.socialDenomination} | ${director?.email}`,
          name: director?.socialDenomination,
          email: director?.email,
        }));
        break;

      default:
        break;
    }

    const totalSigners = list?.length || 0;

    const all = {
      id: ALL,
      text: `${t('All')} (${totalSigners})`,
    };

    setSignersList([all, ...list]);

    if (list?.length) {
      setSigner(all.id);
    } else {
      setSigner('');
    }
  };

  const handleSetRole = (e) => {
    setSelectedRole(e.target.value);
  };

  useEffect(() => {
    if (!selectedRole) return;

    getSignersList(selectedRole);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRole]);

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title">{t('SignNewDocument')}</h5>
        <a
          className="close cursor-pointer"
          onClick={() => store.dispatch(setModal(null))}
        >
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
      <Modal.Body>
        <form className="form-validate is-alter" onSubmit={sendSignature}>
          <div className="form-group">
            <label className="form-label" htmlFor="default-06">
              {t('AddDocument')}
            </label>
            <div className="form-control-wrap">
              <div className="custom-file">
                <input
                  id="customFile"
                  type="file"
                  accept={PDF_TYPE_FILE_ALLOWED}
                  className="custom-file-input"
                  onChange={(event) => setFile(event.target.files[0])}
                />
                <label className="custom-file-label" htmlFor="customFile">
                  {file?.name || t('SelectDocument')}
                </label>
              </div>
            </div>

            {/* TODO: translate */}
            <div className="sub-text mt-1">
              Tamaño máximo permitido = <code>{MAX_FILESIZE_ALLOWED.text}</code>
              {' | '}
              Tamaño del archivo actual ={' '}
              <code>
                {(file && (file.size / 1024 / 1024).toFixed(2)) || 0}mb
              </code>
            </div>

            {file && file.size > MAX_FILESIZE_ALLOWED.value && (
              <div className="alert alert-icon alert-danger mt-2" role="alert">
                <em className="icon ni ni-alert-circle" />
                <strong>Archivo no válido</strong>. Excede el tamaño máximo
                permitido.
              </div>
            )}
          </div>

          <div className="form-group">
            <label className="form-label" htmlFor="full-name">
              {t('SelectSignerRole')}
            </label>

            <ul className="custom-control-group d-flex flex-row justify-content-between">
              <li className="card card-bordered p-2 mt-0">
                <div className="custom-control custom-control-sm custom-radio custom-control-pro">
                  <input
                    type="radio"
                    className="custom-control-input"
                    name="btnRadio"
                    id="rolPartner"
                    value={presetRecipients.PARTNERS}
                    onChange={handleSetRole}
                  />
                  <label className="custom-control-label" htmlFor="rolPartner">
                    {t(presetRecipients.PARTNERS)}
                  </label>
                </div>
              </li>

              <li className="card card-bordered p-2 mt-0">
                <div className="custom-control custom-control-sm custom-radio custom-control-pro">
                  <input
                    type="radio"
                    className="custom-control-input"
                    name="btnRadio"
                    id="rolBeneficiary"
                    value={presetRecipients.BENEFICIARIES}
                    onChange={handleSetRole}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="rolBeneficiary"
                  >
                    {t(presetRecipients.BENEFICIARIES)}
                  </label>
                </div>
              </li>

              <li className="card card-bordered p-2 mt-0">
                <div className="custom-control custom-control-sm custom-radio custom-control-pro">
                  <input
                    type="radio"
                    className="custom-control-input"
                    name="btnRadio"
                    id="rolInvestor"
                    value={presetRecipients.INVESTORS}
                    onChange={handleSetRole}
                    disabled
                  />
                  <label className="custom-control-label" htmlFor="rolInvestor">
                    {t(presetRecipients.INVESTORS)}
                  </label>
                </div>
              </li>

              <li className="card card-bordered p-2 mt-0">
                <div className="custom-control custom-control-sm custom-radio custom-control-pro">
                  <input
                    type="radio"
                    className="custom-control-input"
                    name="btnRadio"
                    id="rolDirector"
                    value={presetRecipients.DIRECTORS}
                    onChange={handleSetRole}
                    disabled
                  />
                  <label className="custom-control-label" htmlFor="rolDirector">
                    {t(presetRecipients.DIRECTORS)}
                  </label>
                </div>
              </li>
            </ul>
          </div>

          <div className="form-group">
            <label className="form-label" htmlFor="pay-amount">
              {labelSections[selectedRole] || t('Select')}
            </label>
            <div className="form-control-wrap">
              <div className="form-control-select">
                <select
                  className="form-control"
                  value={signer}
                  onChange={(event) => setSigner(event.target.value)}
                >
                  {signersList.map((option) => (
                    <option
                      value={option.id}
                      key={`class-${option.id}`}
                      label={option.text}
                    >
                      {option.text}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="form-group">
            <label className="form-label" htmlFor="pay-amount">
              {t('Message')}
            </label>
            <div className="form-control-wrap">
              <div className="form-editor-custom">
                <textarea
                  className="form-control form-control-lg no-resize"
                  placeholder="Escribir aquí"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>
            </div>
          </div>

          <button
            type="submit"
            className="btn btn-lg btn-primary"
            disabled={!file || !selectedRole || !signer || !message}
          >
            {t('Send')}
          </button>
        </form>
      </Modal.Body>
    </>
  );
}

export default SignatureModal;

/*
https://app.sttok.com/?signed=%3fredirect%3dsocios%2f64158b7c4e0cfc51020805bb%2f64158be34e0cfc51020805d5
*/
