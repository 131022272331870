import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { State } from 'redux/initialState';

import OperationMenuDots from 'components/MenuDots/OperationMenuDots';
import { setModal } from 'redux/actions/modalActions';

type BeneficiaryPlanHeaderProps = {
  rows: any;
  menuOptions: any;
};

const BeneficiaryPlanHeader: FC<BeneficiaryPlanHeaderProps> = ({
  rows,
  menuOptions,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const user = useSelector((state: State) => state?.user);
  const isAdmin = useSelector((state: State) => state.society?.role?.isAdmin);
  const actualSociety = useSelector(
    (state: State) => state.society.actualSociety
  );
  const isDemo = useSelector((state: State) => state.society?.role?.isDemo);

  const title = `${actualSociety.name} ${t('HavePlans', {
    count: rows?.length,
  })}`;

  return (
    <>
      <div className="nk-block-head-content">
        <h3 className="nk-block-title page-title">{actualSociety.name}</h3>
        <p />
      </div>

      <div className="nk-block-head">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">
              {t('StockPhantomPlans')}
            </h3>
            <div className="nk-block-des text-soft">
              <p>{title}</p>
            </div>
          </div>

          <div className="nk-block-head-content">
            <div className="toggle-wrap nk-block-tools-toggle">
              <a
                className="btn btn-icon btn-trigger toggle-expand mr-n1"
                data-target="pageMenu"
                aria-label="link"
              >
                <em className="icon ni ni-menu-alt-r" />
              </a>
              <div className="toggle-expand-content" data-content="pageMenu">
                <ul className="nk-block-tools g-3">
                  <li>
                    <Link
                      to={`/resumen-planes/${actualSociety['_id']}`}
                      className="btn btn-outline-primary"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <em className="icon ni ni-external" />
                      <span>{t('Vista Resumen')}</span>
                    </Link>
                  </li>

                  {isAdmin ? (
                    <li>
                      <OperationMenuDots
                        menuOptions={menuOptions}
                        handleModal={(Modal: any) =>
                          dispatch(
                            setModal(
                              <Modal
                                user={user}
                                societyId={actualSociety['_id']}
                              />
                            )
                          )
                        }
                        disabled={isDemo}
                      />
                    </li>
                  ) : (
                    <> </>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BeneficiaryPlanHeader;
