/* eslint-disable react/react-in-jsx-scope */
import EditBeneficiaryModal from 'components/Modals/EditBeneficiary';
import { setModal } from 'redux/actions/modalActions';
import store from 'redux/store';
import editBeneficiary from 'utils/editBeneficiary';

function menuOptions(i18n) {
  return [
    {
      icon: 'ni-eye',
      text: i18n.t('Watch'),
      url: '',
    },
    {
      icon: 'ni-edit',
      text: i18n.t('EditPlan'),
      action: editBeneficiary,
    },
    {
      icon: 'ni-edit',
      text: i18n.t('EditBeneficiary'),
      action: (beneficiary) => {
        store.dispatch(
          setModal(
            <EditBeneficiaryModal
              beneficiary={beneficiary.data}
              societyId={beneficiary.societyId}
            />
          )
        );
      },
    },
  ];
}
export default menuOptions;
