/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';

import boardStatus from 'constants/boardStatus';
import attendanceTypes from 'constants/attendanceTypes';
import { formatDateToTime } from 'constants/formats';

const BoardCardPartner = ({ data }) => {
  const {
    _id,
    name,
    status,
    date,
    place,
    attendanceType,
    societyId,
    society,
    user,
  } = data;

  const getPartnerId = () => {
    const societyPartners = society?.partners?.map((partner) => partner._id);
    const partner = societyPartners
      ? user?.partners.find((partner) => societyPartners?.includes(partner))
      : null;

    return partner;
  };

  return (
    <div className="nk-block">
      <div className="card card-bordered sp-plan">
        <div className="card card-bordered sp-plan">
          <div className="row no-gutters">
            <div className="col-md-8">
              <div className="sp-plan-info card-inner">
                <div className="row gx-0 gy-3">
                  <div className="col-12">
                    <div className="sp-plan-name">
                      <h6 className="title">
                        <Link
                          to={`/voto-junta/${societyId}/${_id}/${getPartnerId()}`}
                        >
                          {name}&nbsp;
                          <span
                            className={`badge badge-pill ${boardStatus?.[status]?.style}`}
                          >
                            {boardStatus?.[status]?.text || ''}
                          </span>
                        </Link>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="sp-plan-desc card-inner pt-0">
                <ul className="row gx-1">
                  <li className="col-3">
                    <p>
                      <span className="text-soft">Fecha</span>{' '}
                      {format(new Date(date), 'dd/MM/yyyy')}
                    </p>
                  </li>

                  <li className="col-3">
                    <p>
                      <span className="text-soft">Hora inicio</span>{' '}
                      {formatDateToTime(date)}
                    </p>
                  </li>

                  <li className="col-3">
                    <p>
                      <span className="text-soft">Lugar celebración</span>{' '}
                      {place || '-'}
                    </p>
                  </li>

                  <li className="col-3">
                    <p>
                      <span className="text-soft">Forma asistencia</span>{' '}
                      {attendanceTypes?.[attendanceType]?.text || '-'}
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4">
              <div className="sp-plan-action card-inner">
                <div className="sp-plan-btn">
                  <Link
                    to={`/voto-junta/${societyId}/${_id}/${getPartnerId()}`}
                    className="btn btn-dim btn-white btn-outline-primary"
                  >
                    <span>Ver detalles</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoardCardPartner;
