import React, { FC } from 'react';

import { getPartnerPremium } from 'utils/filters';

import DrawCell from '../DrawCell';

type Props = {
  partners: any[];
  partnersShares: any[];
  actualSociety: any;
  text: boolean;
};

const DrawInversionTotals: FC<Props> = ({
  partners,
  partnersShares,
  actualSociety,
  text,
}) => {
  const getTotalPremium = (partners: any[], actualSociety: any) => {
    let totalPremium = 0;
    partners.forEach((partner: any) => {
      const partnerPremium = parseFloat(
        getPartnerPremium(partner?.shares || [], actualSociety).toFixed(2)
      );
      totalPremium += partnerPremium;
    });
    return totalPremium;
  };

  const getTotalAccumulatedNominalValue = (
    partnersShares: any[],
    actualSociety: any
  ) => {
    let totalAccumulatedNominalValue = 0;
    Object.values(partnersShares).forEach((value: any) => {
      totalAccumulatedNominalValue += value * actualSociety?.nominalValue || 0;
    });
    return totalAccumulatedNominalValue;
  };

  const totalPremiumValue = getTotalPremium(partners, actualSociety);
  const totalAccumulatedNominalValue = getTotalAccumulatedNominalValue(
    partnersShares,
    actualSociety
  );
  const totalsSum = totalPremiumValue + totalAccumulatedNominalValue;

  return (
    <>
      <DrawCell
        columnIndex={0}
        value={
          totalAccumulatedNominalValue > 0
            ? parseFloat(totalAccumulatedNominalValue?.toFixed(2))
            : ''
        }
        text={text}
        decimal
      />
      <DrawCell
        columnIndex={0}
        value={
          totalPremiumValue > 0 ? parseFloat(totalPremiumValue?.toFixed(2)) : ''
        }
        text={text}
        decimal
      />
      <DrawCell
        columnIndex={0}
        value={totalsSum > 0 ? parseFloat(totalsSum?.toFixed(2)) : ''}
        text={text}
        decimal
      />
    </>
  );
};

export default DrawInversionTotals;
