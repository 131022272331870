/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import i18n from 'i18n/config';

import store from 'redux/store';
import { setPreview } from 'redux/actions/previewActions';

import fileTypes from 'constants/fileTypes';
import { dateFormat, dateFormatInverted } from 'constants/formats';
import documentTypes from 'constants/documentTypes';

import downloadFile from 'utils/downloadFile';
import { getPartnersFromTransactions } from 'utils/filters';
import { getDocumentIcon } from 'utils/documentIcons';

import UsersArray from 'components/UsersArray';
import TableLoader from 'components/Tables/TableLoader';
import DocumentsGenerator from 'components/Documents/Creators/DocumentsGenerator';
import CustomLoading from 'components/CustomLoading';

import Swal from 'sweetalert2';
import {
  addDocument,
  getDocumentsByReference,
} from 'redux/actions/documentActions';
import { updateSociety } from 'redux/actions/societyActions';
import reportStatusTypes from 'constants/reportStatusTypes';
import signedStatusTypes from 'constants/signedStatusTypes';
import MenuDots from '../../MenuDots';
import menuOptions from './menuOptions';
import NoDocumentsAlert from './NoDocumentsAlert';
import NoDocumentsFoundAlert from './NoDocumentsFoundAlert';

import './List.scss';

const hasTransactionsColumn = [documentTypes.SCRIPTURES];
const hasParticipantsColumn = [
  documentTypes.SCRIPTURES,
  documentTypes.ASSIGNMENTS,
  documentTypes.AUTHORITY,
];
const hasReportStatusColumn = [documentTypes.ANNUALREPORTS];
const hasSignedStatusColumn = [documentTypes.SIGNATURES];

const List = ({
  isLoadingData,
  section,
  documents,
  setDocumentSelected,
  setOpenTemplateCreator,
  currentBook,
  setCurrentBook,
  currentCouncilMinute,
  setCurrentCouncilMinute,
  currentFolder,
  setCurrentFolder,
  searchTerm,
  signatureDocumentCategory,
}) => {
  const { t } = useTranslation();

  const user = useSelector((state) => state.user);
  const isDemo = useSelector((state) => state.society?.role?.isDemo);
  const actualSociety = useSelector((state) => state.society?.actualSociety);
  const [currentMenuOptions, setCurrentMenuOptions] = useState(
    menuOptions(i18n)
  );
  const [documentsByOperations, setDocumentsByOperations] = useState({});
  const [partnersByOperations, setPartnersByOperations] = useState({});
  const [participants, setParticipants] = useState({});
  const [mergedParticipants, setMergedParticipants] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const infoSwal = (text, type) =>
    Swal.fire({
      icon: type,
      title: `<h4 class="nk-modal-title">${t(text)}<br></h4>`,
      confirmButtonText: t('OK'),
      allowOutsideClick: false,
      showCancelButton: false,
      confirmButtonColor: '#6576FF',
    });

  const uploadFile = async (file) => {
    const societyId = actualSociety['_id'];
    const newDocument = {
      file,
      date: format(new Date(), dateFormatInverted),
      size: file?.size,
      name: file?.name,
      author: user?.['_id'],
      society: societyId,
      fileType: fileTypes[file?.type],
      category:
        section.tag === documentTypes.ALL
          ? documentTypes.DOCUMENTS
          : section.tag,
      subcategory: currentBook || currentFolder || currentCouncilMinute,
      description: t('DocumentLinkedManually'),
      isForAdmins: false,
    };
    const uploadedDocument = await store.dispatch(addDocument(newDocument));
    const data = {
      docs: {
        add: uploadedDocument,
      },
    };
    store.dispatch(updateSociety(societyId, data, false));
  };

  const uploadFiles = async (files) => {
    const societyId = actualSociety['_id'];
    setIsLoading(true);
    try {
      await Promise.all(files.map((file) => uploadFile(file)));
    } catch (error) {
      console.log(error);
    }
    store.dispatch(getDocumentsByReference({ societyId }));
    setIsLoading(false);
    infoSwal('DocumentsUploadedSuccessfully', 'success');
  };

  const handleChooseDocument = async (event) => {
    event.preventDefault();
    const files = [...event.target.files];
    const pdfFiles = files.filter((file) => file.type === 'application/pdf');
    Swal.fire({
      icon: 'info',
      title: `<h4 class="nk-modal-title">${i18n.t(
        'WantUploadThisDocuments'
      )} </h4>`,
      html: `<h5 class="fw-normal">${i18n.t(
        'OnlyPDFDocumentsWillBeUploaded'
      )}</div>`,
      confirmButtonText: i18n.t('Upload'),
      confirmButtonColor: '#6576ff',
      allowOutsideClick: false,
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await uploadFiles(pdfFiles);
      }
    });
  };

  const handleDrop = async (event) => {
    event.preventDefault();
    const files = [...event.dataTransfer.files];
    const pdfFiles = files.filter((file) => file.type === 'application/pdf');
    if (section.tag === documentTypes.MINUTEBOOK && !currentBook) {
      infoSwal('MustBeInsideBook', 'info');
    } else if (section.tag === documentTypes.MYFOLDERS && !currentFolder) {
      infoSwal('MustBeInsideFolder', 'info');
    } else if (
      section.tag === documentTypes.COUNCILMINUTES &&
      !currentCouncilMinute
    ) {
      infoSwal('MustBeInsideCouncilMinute', 'info');
    } else if (
      section.tag === documentTypes.TEMPLATES ||
      section.tag === documentTypes.DELETED
    ) {
      infoSwal('CannotUploadDocumentsInThisSection', 'info');
    } else {
      Swal.fire({
        icon: 'info',
        title: `<h4 class="nk-modal-title">${i18n.t(
          'WantUploadThisDocuments'
        )} </h4>`,
        html: `<h5 class="fw-normal">${i18n.t(
          'OnlyPDFDocumentsWillBeUploaded'
        )}</div>`,
        confirmButtonText: i18n.t('Upload'),
        confirmButtonColor: '#6576ff',
        allowOutsideClick: false,
        showCancelButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          await uploadFiles(pdfFiles);
        }
      });
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const getDocumentDate = (date) => {
    if (!date) return '';
    if (date) {
      return format(new Date(date), `${dateFormat}`);
    }
  };

  const getDateColumnLabel = (sectionName) => {
    if (sectionName === documentTypes.SIGNATURES) {
      return t('SentDate');
    }
    return t('DocumentDate');
  };

  const getColumnNameStyle = (sectionName) => {
    switch (sectionName) {
      case documentTypes.SCRIPTURES:
        return { maxWidth: '400px' };

      case documentTypes.LETTERS:
        return { maxWidth: '550px' };

      default:
        return { maxWidth: '500px' };
    }
  };

  const handleParticipants = (
    sourceArray,
    documentId,
    participantsArray,
    linkPrefix,
    linkField,
    beneficiary
  ) => {
    const resultArray = { ...participantsArray };

    sourceArray.forEach((element) => {
      if (element.documents.includes(documentId)) {
        const participant = {
          ...element,
          name: element?.socialDenomination || element?.name,
          linkPrefix,
          linkField: beneficiary
            ? `${element?.plan}/${element?._id}`
            : linkField || element?._id,
        };

        resultArray[documentId] = resultArray[documentId]
          ? [...resultArray[documentId], participant]
          : [participant];
      }
    });

    return resultArray;
  };

  const handleOpenDocument = (document) => {
    downloadFile({ documentId: document?._id });
    if (typeof setDocumentSelected === 'function') {
      setDocumentSelected(null);
    }
    if (typeof setOpenTemplateCreator === 'function') {
      setOpenTemplateCreator(false);
    }
  };

  const handleOpenEditableDocument = async (document) => {
    const datatDocument = (
      <DocumentsGenerator
        invitationModel={document?.editorModel}
        hasConsolidationCalendar={false}
        consolidationCalendar={{}}
      />
    );
    await store.dispatch(
      setPreview({
        name: document.name,
        bdDocument: document,
        document: datatDocument,
        documentModel: document?.editorModel,
        template: document?.['_id'],
      })
    );
    window.location.href = `#/documentos/documentos/${actualSociety?._id}?preview=true`;
  };

  const handleOpenTemplate = async (document) => {
    if (typeof setDocumentSelected === 'function') {
      setDocumentSelected(document);
    }
    if (typeof setOpenTemplateCreator === 'function') {
      setOpenTemplateCreator(true);
    }
  };

  const handleOpenTransaction = (document) => {
    if (!document || !document?.operationType) return;

    window.location.href = `#/detalle-transacciones/${actualSociety._id}/${document._id}`;
  };

  const handleExitFolder = () => {
    if (setCurrentBook) {
      setCurrentBook(null);
    }
    if (setCurrentFolder) {
      setCurrentFolder(null);
    }
    if (setCurrentCouncilMinute) {
      setCurrentCouncilMinute(null);
    }
  };

  useEffect(() => {
    let options = menuOptions(i18n);
    if (!currentBook) {
      options = menuOptions(i18n).filter(
        (current) => current.text !== i18n.t('ChangeBook')
      );
    }
    if (!currentFolder) {
      options = options.filter(
        (current) => current.text !== i18n.t('ChangeFolder')
      );
    }
    if (!currentCouncilMinute) {
      options = options.filter(
        (current) => current.text !== i18n.t('ChangeCouncilMinute')
      );
    }
    setCurrentMenuOptions(options);
  }, [i18n.language, currentBook, currentFolder, currentCouncilMinute]);

  useEffect(() => {
    if (!documents) return;
    if (
      section.tag !== documentTypes.SCRIPTURES &&
      section.tag !== documentTypes.ASSIGNMENTS &&
      section.tag !== documentTypes.AUTHORITY
    )
      return;

    const currentDocumentsIds = documents.map((document) => document._id);
    const societyOperations = actualSociety?.operations || [];

    const documentsLinked = {};

    societyOperations.forEach((operation) => {
      const operationDocumentsIds = operation.documents.map(
        (document) => document
      );
      currentDocumentsIds.forEach((documentId) => {
        if (operationDocumentsIds.includes(documentId)) {
          documentsLinked[documentId] = operation;
        }
      });
    });

    setDocumentsByOperations(documentsLinked);
  }, [actualSociety?.operations, documents, section]);

  useEffect(() => {
    if (!documents) return;
    if (
      section.tag !== documentTypes.SCRIPTURES &&
      section.tag !== documentTypes.ASSIGNMENTS &&
      section.tag !== documentTypes.AUTHORITY
    )
      return;

    const currentDocumentsIds = documents.map((document) => document._id);
    const societyDirectors = actualSociety?.directors || [];
    const societyRepresentatives = actualSociety?.representatives || [];
    const societyAuditors = actualSociety?.auditors || [];
    const societyBeneficiaries = actualSociety?.beneficiaries || [];
    const societyPartners = actualSociety?.partners || [];
    const societyInvestors = actualSociety?.investors || [];
    let participantsArray = {};

    currentDocumentsIds.forEach((documentId) => {
      participantsArray = handleParticipants(
        societyDirectors,
        documentId,
        participantsArray,
        `detalle-miembro/${actualSociety._id}`,
        false
      );
      participantsArray = handleParticipants(
        societyRepresentatives,
        documentId,
        participantsArray,
        `detalle-apoderado/${actualSociety._id}`,
        false
      );
      participantsArray = handleParticipants(
        societyAuditors,
        documentId,
        participantsArray,
        'perfil-sociedad/auditors',
        actualSociety._id
      );
      participantsArray = handleParticipants(
        societyBeneficiaries,
        documentId,
        participantsArray,
        `detalle-beneficiario/${actualSociety._id}`,
        false,
        true
      );
      participantsArray = handleParticipants(
        societyPartners,
        documentId,
        participantsArray,
        `detalle-socio/${actualSociety._id}`,
        false
      );
      participantsArray = handleParticipants(
        societyInvestors,
        documentId,
        participantsArray,
        `detalle-inversor/${actualSociety._id}`,
        false
      );
    });

    setParticipants(participantsArray);
  }, [actualSociety, documents, section]);

  useEffect(() => {
    const mergedObject = {};

    Object.entries(participants).forEach(([key, value]) => {
      if (mergedObject[key]) {
        mergedObject[key] = mergedObject[key].concat(value);
      } else {
        mergedObject[key] = value;
      }
    });

    Object.entries(partnersByOperations).forEach(([key, value]) => {
      if (mergedObject[key]) {
        mergedObject[key] = mergedObject[key].concat(value);
      } else {
        mergedObject[key] = value;
      }
    });
    setMergedParticipants(mergedObject);
  }, [participants, partnersByOperations]);

  useEffect(() => {
    if (!documentsByOperations) return;

    const partnersLinked = {};

    Object.entries(documentsByOperations).forEach(([documentId, operation]) => {
      const partners = getPartnersFromTransactions(
        operation.transactions,
        actualSociety
      );
      const uniquePartners = partners
        .filter(
          (partner, index, self) =>
            index === self.findIndex((t) => t._id === partner._id)
        )
        .map((partner) => ({
          ...partner,
          linkField: partner._id,
          linkPrefix: `detalle-socio/${actualSociety._id}`,
        }));

      partnersLinked[documentId] = uniquePartners;
    });

    setPartnersByOperations(partnersLinked);
  }, [actualSociety, documentsByOperations]);

  return !isLoading ? (
    <div className="nk-fmg-body-content">
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-between position-relative">
          <div className="nk-block-head-content">
            {!currentBook &&
            !currentFolder &&
            !currentCouncilMinute &&
            !signatureDocumentCategory ? (
              <h3 className="nk-block-title page-title">{t(section.title)}</h3>
            ) : (
              <h3 className="nk-block-title page-title">
                <span className="cursor-pointer" onClick={handleExitFolder}>
                  {t(section.title)}
                </span>
                &nbsp;/&nbsp;
                <span className="text-primary fw-normal">
                  {currentBook?.label ||
                    currentFolder?.label ||
                    currentCouncilMinute?.label ||
                    t(signedStatusTypes.text[signatureDocumentCategory])}
                </span>
              </h3>
            )}
          </div>
        </div>
      </div>
      {section.tag !== documentTypes.TEMPLATES &&
        section.tag !== documentTypes.DELETED &&
        !isDemo && (
          <div onDrop={handleDrop} onDragOver={handleDragOver}>
            <div className="col-md-12" style={{ paddingBottom: '20px' }}>
              <div className="card card-bordered sp-plan">
                <div className="sp-plan-action card-inner">
                  <em className="icon ni ni-upload-cloud mr-0 mr-md-2" />
                  <span>{t('DragDocumentsToUpload')}</span>
                  <span>{t('Or')}</span>
                  <input
                    type="file"
                    id="fileInput"
                    style={{ display: 'none' }}
                    onChange={handleChooseDocument}
                    accept=".pdf"
                    multiple
                  />
                  <label htmlFor="fileInput" className="btn btn-light">
                    {t('Choose')}
                  </label>
                </div>
              </div>
            </div>
          </div>
        )}
      <div className="nk-fmg-listing nk-block">
        <div className="nk-files nk-files-view-list">
          {documents?.length > 0 ? (
            <>
              <div className="nk-files-head">
                <div className="nk-file-item">
                  <div className="nk-file-info">
                    <div className="tb-head ml-4">{t('Name')}</div>
                  </div>

                  {hasReportStatusColumn.includes(section.tag) ||
                    (hasSignedStatusColumn.includes(section.tag) && (
                      <div className="nk-file-info d-none d-lg-table-cell">
                        <div className="tb-head">{t('Status')}</div>
                      </div>
                    ))}

                  {hasTransactionsColumn.includes(section.tag) && (
                    <div className="nk-file-info d-none d-lg-table-cell">
                      <div className="tb-head">{t('Transaction')}</div>
                    </div>
                  )}

                  {hasParticipantsColumn.includes(section.tag) && (
                    <div className="nk-file-info d-none d-lg-table-cell">
                      <div className="tb-head">{t('Participants')}</div>
                    </div>
                  )}

                  <div className="nk-file-meta d-none d-xl-table-cell text-center">
                    <div className="tb-head">
                      {t(getDateColumnLabel(section.tag))}
                    </div>
                  </div>

                  <div className="nk-file-members d-none d-xl-table-cell">
                    <div className="tb-head">{t('Author')}</div>
                  </div>
                </div>
              </div>

              <div className="nk-files-list">
                {documents?.map((document) => (
                  <div className="nk-file-item nk-file" key={document?._id}>
                    <div className="nk-file-info">
                      <div className="nk-file-title w-100">
                        <div className="nk-file-icon">
                          <span className="nk-file-icon-type">
                            <img
                              src={getDocumentIcon(document)}
                              alt="icon-file"
                            />
                          </span>
                        </div>

                        <div className="nk-file-name">
                          <div
                            className="nk-file-name-text d-flex flex-column"
                            style={getColumnNameStyle(section.tag)}
                          >
                            <span
                              className="title cursor-pointer text-ellipsis"
                              style={
                                section.tag === documentTypes.DELETED
                                  ? { textDecoration: 'line-through' }
                                  : {}
                              }
                              onClick={async () => {
                                if (
                                  document.subcategory ===
                                  documentTypes.DOCUMENTS
                                ) {
                                  await handleOpenEditableDocument(document);
                                } else if (
                                  document.category === documentTypes.TEMPLATES
                                ) {
                                  await handleOpenTemplate(document);
                                } else {
                                  handleOpenDocument(document);
                                }
                              }}
                              title={document?.name}
                            >
                              {document?.name}
                            </span>
                            <span className="fw-light text-muted text-ellipsis fs-12px">
                              {document?.description}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    {hasReportStatusColumn.includes(section.tag) && (
                      <>
                        <div className="nk-file-info d-none d-lg-table-cell">
                          {document?.reportStatus ? (
                            <span
                              className={`badge badge-dot badge-${
                                reportStatusTypes.badge[document?.reportStatus]
                              }`}
                            >
                              {t(
                                reportStatusTypes.text[document?.reportStatus]
                              )}
                            </span>
                          ) : (
                            <span>-</span>
                          )}
                        </div>
                      </>
                    )}

                    {hasSignedStatusColumn.includes(section.tag) && (
                      <>
                        <div className="nk-file-info d-none d-lg-table-cell text-left">
                          {document?.subcategory === 'SIGNED' ? (
                            <span
                              className={`badge badge-dot badge-${signedStatusTypes.badge.SIGNED}`}
                            >
                              {t(signedStatusTypes.text.SIGNED)}
                            </span>
                          ) : (
                            <span
                              className={`badge badge-dot badge-${signedStatusTypes.badge.PENDING}`}
                            >
                              {t(signedStatusTypes.text.PENDING)}
                            </span>
                          )}
                        </div>
                      </>
                    )}

                    {hasTransactionsColumn.includes(section.tag) && (
                      <div className="nk-file-info d-none d-lg-table-cell">
                        <div
                          className={`tb-lead ${
                            documentsByOperations[document?._id]?.operationType
                              ? 'cursor-pointer'
                              : ''
                          }`}
                          onClick={() =>
                            handleOpenTransaction(
                              documentsByOperations[document?._id]
                            )
                          }
                        >
                          {t(
                            documentsByOperations[document?._id]
                              ?.operationType || '-'
                          )}
                        </div>
                      </div>
                    )}

                    {hasParticipantsColumn.includes(section.tag) && (
                      <div className="nk-file-info d-none d-lg-table-cell">
                        <div className="tb-lead ">
                          {mergedParticipants[document?._id]?.length ? (
                            <UsersArray
                              users={mergedParticipants[document?._id]}
                              customUsers
                              hasTooltip
                              hasLink
                              linkPrefix
                            />
                          ) : (
                            '-'
                          )}
                        </div>
                      </div>
                    )}

                    <div className="nk-file-meta d-none d-xl-table-cell">
                      <div className="tb-lead text-center">
                        {getDocumentDate(document?.date)}
                      </div>
                    </div>

                    <div className="nk-file-members d-none d-xl-table-cell">
                      <div className="tb-lead">{document?.author?.name}</div>
                    </div>

                    {document?.category !== documentTypes.DELETED && (
                      <div className="nk-file-actions">
                        <div className="tb-lead">
                          <MenuDots
                            menuOptions={currentMenuOptions}
                            id={document._id}
                            params={{
                              documentId: document?._id,
                              documentName: document?.name,
                              document,
                              actualSociety,
                              user,
                              isDemo,
                              setDocumentSelected,
                              setOpenTemplateCreator,
                              documentsByOperations,
                            }}
                            direction="left"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </>
          ) : (
            <>
              {isLoadingData && <TableLoader rows={10} notHeader />}
              {!isLoadingData && !searchTerm && (
                <NoDocumentsAlert section={section} />
              )}
              {!isLoadingData && searchTerm && <NoDocumentsFoundAlert />}
            </>
          )}
        </div>
      </div>
    </div>
  ) : (
    <CustomLoading />
  );
};

export default List;
