import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ReactToPrint from 'react-to-print';

import { setModal } from 'redux/actions/modalActions';
import '../../assets/styles/Icons.scss';
import '../../assets/styles/Bootstrap.scss';
import './PreviewDropdown.scss';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    className="dropdown-toggle mr-lg-n1"
  >
    {children}
  </div>
));
CustomToggle.displayName = 'CustomToggle';

function PreviewDropdown({
  menuOptions,
  direction,
  id = null,
  label = '',
  params,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const menu = menuOptions.map((menuOption) => {
    const idUrl = id ? `/${id}` : '';

    if (menuOption.print)
      return (
        <ReactToPrint
          trigger={() => (
            <a
              key={menuOption.text}
              className={menuOption.disabled ? 'disabled' : 'cursor-pointer'}
            >
              <em className="icon ni ni-printer" />
              <span>{menuOption.text}</span>
            </a>
          )}
          content={() => menuOption.content}
          documentTitle={menuOption.documentTitle}
        />
      );

    if (menuOption.action)
      return (
        <a
          key={menuOption.text}
          onClick={() => menuOption.action(params, id)}
          className={menuOption.disabled ? 'disabled' : 'cursor-pointer'}
        >
          <em className={`icon ni ${menuOption.icon}`} />
          <span>{menuOption.text}</span>
        </a>
      );

    if (menuOption.url)
      return (
        <Link
          to={`${menuOption.url}${idUrl}${
            menuOption.urlSuffix ? `/${menuOption.urlSuffix}` : ''
          }`}
          key={menuOption.text}
          className={menuOption.disabled ? 'disabled' : 'cursor-pointer'}
        >
          <em className={`icon ni ${menuOption.icon}`} />
          <span>{menuOption.text}</span>
        </Link>
      );

    if (menuOption.modal)
      return (
        <a
          key={menuOption.text}
          onClick={() => dispatch(setModal(menuOption.modal))}
          className={menuOption.disabled ? 'disabled' : 'cursor-pointer'}
        >
          <em className={`icon ni ${menuOption.icon}`} />
          <span>{menuOption.text}</span>
        </a>
      );

    // if (menuOption.download && (downloadURL || menuOption.downloadURL))
    //   return (
    //     <a
    //       key={menuOption.text}
    //       href={downloadURL || menuOption.downloadURL}
    //       target="_blank"
    //       rel="noreferrer"
    //       className={menuOption.disabled ? 'disabled' : 'cursor-pointer'}
    //     >
    //       <em className={`icon ni ${menuOption.icon}`} />
    //       <span>{menuOption.text}</span>
    //     </a>
    //   );

    return null;
  });

  return (
    <Dropdown drop={direction}>
      <Dropdown.Toggle as={CustomToggle}>
        <button type="button" className="btn btn-primary">
          {label || t('Options')}
          <span className="icon ni ni-caret-down ml-2" />
        </button>
      </Dropdown.Toggle>
      <Dropdown.Menu
        size="sm"
        title=""
        className="link-list-opt no-bdr dropdown-menu dropdown-menu-s1"
      >
        {menu}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default PreviewDropdown;
