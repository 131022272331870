import React from 'react';
import { format } from 'date-fns';
import { dateFormat } from 'constants/formats';
import downloadFile from 'utils/downloadFile';

function BeneficiaryDocumentsRow({ document, index }) {
  return (
    <tr className="nk-tb-item">
      <td className="nk-tb-col">{index}</td>
      <td className="nk-tb-col">{document?.name}</td>
      <td className="nk-tb-col">
        {format(new Date(document?.date), dateFormat)}
      </td>
      <td className="nk-tb-col py-0">
        <span
          className="btn btn-icon btn-trigger"
          onClick={() => downloadFile({ documentId: document?.['_id'] })}
        >
          <em className="icon ni ni-file-download" />
        </span>
      </td>
    </tr>
  );
}

export default BeneficiaryDocumentsRow;
