/* eslint-disable no-underscore-dangle */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { setModal } from 'redux/actions/modalActions';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import MenuDots from 'components/MenuDots';
import AddSocietyDirector from 'components/Modals/AddSocietyDirector';
import { dateFormat } from 'constants/formats';
import downloadFile from 'utils/downloadFile';
import { BLANK_PROFILE_PICTURE } from 'constants/defaultConstants';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import { updateSociety } from 'redux/actions/societyActions';

function SocietyDirectorsRow({
  userId,
  directorId,
  societyId,
  socialDenomination,
  cif,
  nationality,
  birthDate,
  address,
  email,
  image,
  directorType,
  initDate,
  endDate,
  directorStatus,
  documents,
  currStatusTypes,
  currDirectorTypes,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isDemo = useSelector((state) => state.society?.role?.isDemo);

  const status = () => {
    if (!directorStatus) {
      if (!endDate || new Date(endDate) - new Date() > 0)
        return currStatusTypes.var.ACTIVE;
      return currStatusTypes.var.INACTIVE;
    }
    return directorStatus;
  };

  const handleEditDirector = () => {
    let directorData = {
      socialDenomination,
      cif,
      nationality,
      birthDate,
      address,
      email,
      directorType,
      initDate,
      endDate,
      documents,
    };
    if (directorStatus)
      directorData = {
        ...directorData,
        directorStatus,
      };
    dispatch(
      setModal(
        <AddSocietyDirector
          societyId={societyId}
          action="EDIT"
          directorData={directorData}
          directorId={directorId}
          userId={userId}
        />
      )
    );
  };

  const handleDeleteDirector = () => {
    Swal.fire({
      icon: 'warning',
      title: `<h4 className="nk-block-title page-title">${t(
        'AreYouSureAboutDelMember'
      )}  `,
      html: `<h3 class="text-primary fw-normal">${socialDenomination} </h3><br /><div class="caption-text">${t(
        'ThisProcedureCantUndo'
      )}</div>`,
      confirmButtonText: t('OkDelete'),
      confirmButtonColor: '#6576ff',
      allowOutsideClick: false,
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const newSocietyData = {
          directors: {
            delete: {
              directorId,
            },
          },
        };
        await dispatch(updateSociety(societyId, newSocietyData, false));
        Swal.fire({
          icon: 'success',
          title: `<h4 class="nk-modal-title">${t(
            'MemberSuccessfullyRemoved'
          )}<br></h4>`,
          confirmButtonText: t('OK'),
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonColor: '#6576FF',
        });
      }
    });
  };

  const showLoadedDocuments = () => {
    if (documents?.length === 1) {
      return (
        <span
          className="btn btn-icon btn-trigger"
          onClick={() => {
            downloadFile({ documentId: documents[0]?._id });
          }}
        >
          <em className="icon ni ni-file-download" />
        </span>
      );
    }
    if (documents?.length > 1) {
      const options = documents.map((doc) => ({
        icon: 'ni-file',
        text: doc.name,
        download: true,
        documentId: doc._id,
      }));
      return (
        <MenuDots
          menuOptions={options}
          direction="left"
          customIcon="ni-files"
        />
      );
    }
    return <span>-</span>;
  };

  const SettingsToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="dropdown-toggle btn-trigger mr-lg-n1"
    >
      {children}
      <em className="icon ni ni-more-h fs-22px" />
    </div>
  ));
  SettingsToggle.displayName = 'SettingsToggle';

  return (
    <tr className="nk-tb-item" key={directorId}>
      <td className="nk-tb-col">
        <div className="user-card">
          {image ? (
            <div className="user-avatar bg-dim-primary d-flex">
              <img
                src={image}
                alt={socialDenomination || ''}
                className="rounded-circle"
              />
            </div>
          ) : (
            <div className="user-avatar bg-dim-primary d-flex">
              <img
                src={BLANK_PROFILE_PICTURE}
                alt={socialDenomination || ''}
                className="rounded-circle"
              />
            </div>
          )}
          <div className="user-info">
            {isDemo ? (
              <span className="text-primary font-weight-bold cursor-pointer">
                {socialDenomination || ''}
              </span>
            ) : (
              <Link
                className="text-primary font-weight-bold cursor-pointer"
                to={`/detalle-miembro/${societyId}/${directorId}`}
              >
                {socialDenomination || ''}
              </Link>
            )}
          </div>
        </div>
      </td>

      <td className="nk-tb-col tb-col-xl">
        <span>{nationality || ''}</span>
      </td>

      <td className="nk-tb-col tb-col-xl">
        <span>
          {initDate && initDate !== ''
            ? format(new Date(initDate), dateFormat)
            : ''}
        </span>
      </td>

      <td className="nk-tb-col tb-col-xl">
        <span>
          {endDate && endDate !== ''
            ? format(new Date(endDate), dateFormat)
            : 'Indefinida'}
        </span>
      </td>

      <td className="nk-tb-col tb-col-xl">
        <span>{email || ''}</span>
      </td>

      <td className="nk-tb-col">
        <span>{directorType ? currDirectorTypes.name[directorType] : ''}</span>
      </td>

      <td className="nk-tb-col">
        {status && (
          <span
            className={`badge badge-dot badge-${
              currStatusTypes.badge[status()]
            }`}
          >
            {currStatusTypes.name[status()]}
          </span>
        )}
      </td>

      <td className="nk-tb-col tb-col-xl text-center">
        {documents.length > 0 ? (
          showLoadedDocuments()
        ) : (
          <span className="btn btn-icon disabled">
            <em className="icon ni ni-file-download" />
          </span>
        )}
      </td>

      {!isDemo && (
        <td className="nk-tb-col">
          <Dropdown className="pr-0" drop="left">
            <Dropdown.Toggle as={SettingsToggle} />
            <Dropdown.Menu
              size="sm"
              title=""
              className="link-list-opt no-bdr dropdown-menu"
            >
              <a className="d-flex cursor-pointer" onClick={handleEditDirector}>
                <em className="icon ni ni-edit fs-20px" />
                <span className="ml-1">{t('Edit')}</span>
              </a>
              <a
                className="d-flex cursor-pointer"
                onClick={handleDeleteDirector}
              >
                <em className="icon ni ni-trash fs-20px" />
                <span className="ml-1">{t('Delete')}</span>
              </a>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      )}
    </tr>
  );
}

export default SocietyDirectorsRow;
