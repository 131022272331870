/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import RepresentativeTable from './representativeTable';

const labels = {
  Name: 'Name',
  'Shares count': 'Shares',
  'Fiscal Identification Number': 'CIF',
  'Shares from': 'SharesFrom',
  'Shares to': 'SharesTo',
  'Shares class': 'Class',
  'Shares premium': 'Premium',
  Address: 'Address',
  City: 'City',
  Country: 'Country',
  Email: 'Email',
  Nationality: 'Nationality',
  'Civil status': 'CivilStatus',
  'Birth date': 'DateOfBirth',
  Profession: 'JobTitle',
};
const inputTypes = {
  Name: 'text',
  'Fiscal Identification Number': 'text',
  'Shares count': 'number',
  'Shares from': 'number',
  'Shares to': 'number',
  'Shares class': 'text',
  'Shares premium': 'number',
  Address: 'text',
  City: 'text',
  Country: 'text',
  Email: 'text',
  Nationality: 'text',
  'Civil status': 'text',
  'Birth date': 'date',
  Profession: 'text',
};

const PartnersTable = ({
  partnersData,
  handleDeletePartner,
  changePartnerParam,
  holdingClasses,
}) => {
  const { t } = useTranslation();

  const [partnerExpandViews, setPartnerExpandViews] = useState([]);

  const classOptions = holdingClasses.map((holdingClass) => (
    <option value={holdingClass['_id']} key={`class-${holdingClass['_id']}`}>
      {holdingClass.name}
    </option>
  ));

  function changePartnerExpandView(index, value) {
    const partnerViews = [...partnerExpandViews];
    partnerViews[index] = value;
    setPartnerExpandViews(partnerViews);
  }
  useEffect(() => {
    if (partnersData) {
      const length = partnersData?.length ?? 0;
      const partnerViews = Array.from({ length }, () => false);
      setPartnerExpandViews(partnerViews);
    }
  }, []);

  return (
    <>
      <div key="partners-card" className="card card-bordered card-preview mb-2">
        <table className="table table-tranx" id="partners-table">
          <thead>
            <tr className="tb-tnx-head">
              <th className="text-left" colSpan={4}>
                <span>{t('Partners')}</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {partnersData.map((partner, index) =>
              partnerExpandViews[index] ? (
                <>
                  {index !== 0 && (
                    <tr style={{ borderBottom: '2px solid #ccc' }} />
                  )}
                  {Object.entries(partner).map(([key, value], fieldIndex) =>
                    key === 'Representative' ? (
                      <tr>
                        <td colSpan="1">
                          <span>
                            <b>{t(labels[key]) || key}</b>
                          </span>
                        </td>
                        <td colSpan="3">
                          <RepresentativeTable
                            representativeData={partner[key]}
                            partnerIndex={index}
                            changePartnerParam={changePartnerParam}
                          />
                        </td>
                      </tr>
                    ) : (
                      <tr key={`partner-${key}-input`}>
                        <td colSpan="1">
                          <span>
                            <b>{t(labels[key]) || key}</b>
                          </span>
                        </td>
                        {key === 'Shares class' ? (
                          <td colSpan="2">
                            <select
                              className="w-100 form-control date-picker"
                              value={value}
                              onChange={(event) =>
                                changePartnerParam(
                                  index,
                                  key,
                                  event.target.value
                                )
                              }
                            >
                              {classOptions}
                            </select>
                          </td>
                        ) : (
                          <td colSpan="2">
                            <input
                              style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                              id={`partner-${key}-input`}
                              type={inputTypes[key]}
                              value={value}
                              className="w-100 form-control date-picker"
                              readOnly={false}
                              onChange={(event) =>
                                changePartnerParam(
                                  index,
                                  key,
                                  event.target.value
                                )
                              }
                            />
                          </td>
                        )}

                        {fieldIndex === 0 ? (
                          <td className="text-right">
                            <button
                              type="button"
                              className="badge btn-primary"
                              onClick={() =>
                                changePartnerExpandView(index, false)
                              }
                            >
                              <em className="icon ni ni-chevron-up" />
                            </button>
                          </td>
                        ) : (
                          <td className="" />
                        )}
                      </tr>
                    )
                  )}
                </>
              ) : (
                <>
                  {index !== 0 && (
                    <tr style={{ borderBottom: '2px solid #ccc' }} />
                  )}
                  <tr className="tb-tnx-item" key={`partner-${index}-name-row`}>
                    <td className="tb-tnx-info">
                      <span>
                        <b>{partner['Name']}</b>
                      </span>
                    </td>

                    <td className="tb-tnx-info">
                      <span>
                        <b>{partner['Shares count']}</b>
                      </span>
                    </td>
                    <td className="tb-tnx-inf">
                      <span>
                        <b>{`${partner['Shares from']} - ${partner['Shares to']}`}</b>
                      </span>
                    </td>
                    <td className="tb-tnx-info text-right">
                      <button
                        type="button"
                        className="badge btn-primary"
                        onClick={() => changePartnerExpandView(index, true)}
                      >
                        <em className="icon ni ni-chevron-down" />
                      </button>
                    </td>
                  </tr>
                </>
              )
            )}
          </tbody>
        </table>

        {/* <button
            type="button"
            className="btn btn-dim btn-outline-danger"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onClick={(event) => handleDeletePartner(index, event.target.value)}
          >
            {t('Delete')}
          </button> */}
      </div>
    </>
  );
};

export default PartnersTable;
