import React from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';

import boardStatus from 'constants/boardStatus';
import attendanceTypes from 'constants/attendanceTypes';
import votePeriods from 'constants/votePeriods';

import { deleteBoard, updateBoard } from 'redux/actions/boardActions';
import boardTypes from 'constants/boardTypes';
import { formatDateToTime } from 'constants/formats';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import store from 'redux/store';
import { getActualSociety } from 'utils/filters';

const BoardCard = ({ data }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { actualSociety } = useSelector((state) => state.society);
  const { user } = useSelector((state) => state.user);
  const isDemo = useSelector((state) => state.society?.role?.isDemo);
  const {
    _id,
    name,
    details,
    isActive,
    status,
    date,
    place,
    attendanceType,
    votePeriod,
    boardType,
  } = data;

  const handleChangeActiveValue = async () => {
    dispatch(
      updateBoard(_id, {
        isActive: !isActive,
      })
    );
  };

  const handleDeleteBoard = async () => {
    if (data) {
      Swal.fire({
        icon: 'warning',
        title: `<h4 class="nk-modal-title">${t(
          'AreYouSureAboutDelBoard'
        )}</h4>`,
        html: `<h5 class="text-primary">${name}</h5><br /><div class="caption-text">${t(
          'ThisProcedureCantUndo'
        )}</div>`,
        confirmButtonText: t('OkDelete'),
        confirmButtonColor: '#6576ff',
        allowOutsideClick: false,
        showCancelButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await store.dispatch(deleteBoard(_id));
            await getActualSociety(user, actualSociety['_id']);
          } catch (error) {
            console.log(error);
          }
        }
      });
    }
  };

  return (
    <div className="nk-block">
      <div className="card card-bordered sp-plan">
        <div className="card card-bordered sp-plan">
          <div className="row no-gutters">
            <div className="col-md-8">
              <div className="sp-plan-info card-inner">
                <div className="row gx-0 gy-3">
                  <div className="col-xl-9 col-sm-8">
                    <div className="sp-plan-name">
                      <h6 className="title">
                        <Link
                          to={`/detalle-junta/${actualSociety?.['_id']}/${_id}`}
                        >
                          {name}&nbsp;
                          <span
                            className={`badge badge-pill ${boardStatus?.[status]?.style}`}
                          >
                            {boardStatus?.[status]?.text || ''}
                          </span>
                        </Link>
                      </h6>
                      <p>
                        {t('Details')}:{' '}
                        <span className="text-base">{details}</span>
                      </p>
                    </div>
                  </div>
                  <div className="col-xl-3 col-sm-4">
                    <div className="sp-plan-opt">
                      <div className="custom-control custom-switch">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id={`board-status-switch-${_id}`}
                          checked={isActive}
                          onChange={handleChangeActiveValue}
                          disabled={isDemo}
                        />

                        <label
                          className="custom-control-label text-soft"
                          htmlFor={`board-status-switch-${_id}`}
                        >
                          {t('ActiveVoting')}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="sp-plan-desc card-inner">
                <ul className="row gx-1">
                  <li className="col-6 col-lg-4">
                    <p>
                      <span className="text-soft">{t('Date')}</span>{' '}
                      {format(new Date(date), 'dd/MM/yyyy')}
                    </p>
                  </li>
                  <li className="col-6 col-lg-4">
                    <p>
                      <span className="text-soft">{t('StartTime')}</span>{' '}
                      {formatDateToTime(date)}
                    </p>
                  </li>

                  {attendanceType === attendanceTypes.FACE_TO_FACE.value ||
                  attendanceType === attendanceTypes.HYBRID.value ? (
                    <li className="col-6 col-lg-4">
                      <p>
                        <span className="text-soft">
                          {t('PlaceOfCelebration')}
                        </span>{' '}
                        {place || '-'}
                      </p>
                    </li>
                  ) : (
                    <></>
                  )}

                  <li className="col-6 col-lg-4">
                    <p>
                      <span className="text-soft">{t('AssistanceForm')}</span>{' '}
                      {attendanceTypes?.[attendanceType]?.text || '-'}
                    </p>
                  </li>
                  {/* <li className="col-6 col-lg-3">
                    <p>
                      <span className="text-soft">Quorum mínimo</span>{' '}
                      {minQuorum}%
                    </p>
                  </li> */}
                  <li className="col-6 col-lg-4">
                    <p>
                      <span className="text-soft">{t('VotingPeriod')}</span>{' '}
                      {votePeriods.find((period) => period.value === votePeriod)
                        ?.text || '-'}
                    </p>
                  </li>
                  <li className="col-6 col-lg-4">
                    <p>
                      <span className="text-soft">{t('BoardType')}</span>{' '}
                      {boardTypes?.[boardType]?.text || '-'}
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4">
              <div className="sp-plan-action card-inner">
                <div className="sp-plan-btn">
                  <Link
                    to={`/detalle-junta/${actualSociety?.['_id']}/${_id}`}
                    className="btn btn-dim btn-white btn-outline-primary"
                  >
                    <span>{t('ViewDetails')}</span>
                  </Link>
                </div>

                <button
                  type="button"
                  className="btn btn-icon btn-trigger mr-2 mt-2"
                  style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                  }}
                  onClick={() => handleDeleteBoard()}
                  disabled={isDemo}
                >
                  <em className="icon ni ni-cross text-soft" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoardCard;
