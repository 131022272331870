/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable comma-dangle */
/* eslint-disable object-curly-newline */
import React, { useState, useEffect } from 'react';
import store from 'redux/store';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { setModal } from 'redux/actions/modalActions';
import { getUsers } from 'redux/actions/userActions';

import EditBeneficiaryModal from 'components/Modals/EditBeneficiary';

function BeneficiaryPersonal({
  society,
  beneficiary,
  incorporationDate,
  setBeneficiaryData,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isAdmin = society?.role?.isAdmin;
  const isDemo = society?.role?.isDemo;

  const [address, setAddress] = useState();
  const [emails, setEmails] = useState([]);
  const [beneficiarySocietyData, setBeneficiarySocietyData] = useState(null);

  const getOtherUsers = async (users) => {
    try {
      const otherUsersList = await dispatch(
        getUsers({
          usersList: users,
        })
      );
      setEmails(
        otherUsersList?.length ? otherUsersList.map((curr) => curr.email) : ['']
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const handleOpenEdit = () => {
    if (isAdmin && !isDemo) {
      store.dispatch(
        setModal(
          <EditBeneficiaryModal
            beneficiary={beneficiary}
            societyId={society.societyId}
            setBeneficiarySocietyData={setBeneficiarySocietyData}
          />
        )
      );
    }
  };

  useEffect(() => {
    const { line1, zip, city, country } = beneficiary.address || {};

    setAddress(
      `${line1 || ''} ${zip || ''} ${city || ''} ${
        country ? `(${country})` : ''
      }`
    );
  }, [beneficiary.address]);

  useEffect(() => {
    if (beneficiary?.otherUsers?.length) {
      getOtherUsers(beneficiary.otherUsers);
    }
  }, [beneficiary.otherUsers]);

  useEffect(() => {
    if (beneficiarySocietyData) {
      setBeneficiaryData(beneficiarySocietyData?.beneficiary);
    }
  }, [beneficiarySocietyData]);

  return (
    <div className="card-inner">
      <div className="nk-block-head" onClick={handleOpenEdit}>
        {isAdmin && !isDemo && (
          <div className="nk-block-head-content">
            <div className="nk-block float-right">
              <button
                type="button"
                className="btn btn-icon btn-trigger btn-tooltip"
                onClick={handleOpenEdit}
              >
                <em className="icon ni ni-edit mr-1" />
              </button>
            </div>
          </div>
        )}
        <h5 className="title">{t('PersonalInformation')}</h5>
        <p>{t('BasicPersonalInformation')}</p>
        <div className="nk-block-head nk-block-head-line" />

        <div className="profile-ud wider d-flex flex-column flex-md-row">
          <span className="profile-ud-label w-200px">{t('FullName')}</span>
          <span className="profile-ud-value  text-left">
            {beneficiary.name || ''}
          </span>
        </div>

        <div className="profile-ud wider d-flex flex-column flex-md-row">
          <span className="profile-ud-label w-200px">{t('Nif')}</span>
          <span className="profile-ud-value  text-left">
            {beneficiary.cif || '-'}
          </span>
        </div>

        <div className="profile-ud wider d-flex flex-column flex-md-row">
          <span className="profile-ud-label w-200px">{t('Telephone')}</span>
          <span className="profile-ud-value  text-left">
            {beneficiary?.phone || '-'}
          </span>
        </div>

        <div className="profile-ud wider d-flex flex-column flex-md-row">
          <span className="profile-ud-label w-200px">{t('JobTitle')}</span>
          <span className="profile-ud-value  text-left">
            {beneficiary?.jobTitle || '-'}
          </span>
        </div>

        <div className="profile-ud wider d-flex flex-column flex-md-row">
          <span className="profile-ud-label w-200px">{t('Address')}</span>
          <span className="profile-ud-value  text-left">{address}</span>
        </div>

        <div className="profile-ud wider d-flex flex-column flex-md-row">
          <span className="profile-ud-label w-200px">
            {emails.length === 0 && t('Email')}
            {emails.length > 0 && t('Emails')}
          </span>
          <span className="profile-ud-value  text-left">
            <div className="d-block">
              {beneficiary.email}
              <span className="badge badge-outline-primary badge-pill text-primary ucap mx-1">
                {t('primary')}
              </span>
            </div>
            {emails?.map((email) => (
              <div className="d-block mt-1">
                <span className="profile-ud-value text-left">{email}</span>
              </div>
            ))}
          </span>
        </div>

        <div className="profile-ud wider d-flex flex-column flex-md-row">
          <span className="profile-ud-label w-200px">
            {t('IncoporationDate')}
          </span>
          <span className="profile-ud-value  text-left">
            {incorporationDate}
          </span>
        </div>

        <div className="profile-ud wider d-flex flex-column flex-md-row">
          <span className="profile-ud-label w-200px">{t('Nationality')}</span>
          <span className="profile-ud-value  text-left">
            {beneficiary?.nationality || '-'}
          </span>
        </div>
      </div>
    </div>
  );
}

export default BeneficiaryPersonal;
