import React from 'react';
import { Tooltip } from 'react-tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { setModal } from 'redux/actions/modalActions';

import { formatNumber } from 'utils/formats';
import { getVestedUnits } from 'utils/filters';

import BeneficiaryInvitation from 'components/Modals/BeneficiaryInvitation';

function BeneficiaryCalendarTabRow({
  index,
  planName,
  plan,
  society,
  beneficiary,
  unitsGranted,
}) {
  const dispatch = useDispatch();
  const isDemo = useSelector((state) => state.society?.role?.isDemo);

  const handleClickPlanName = () => {
    dispatch(
      setModal(
        <BeneficiaryInvitation
          beneficiary={beneficiary}
          society={society}
          plan={plan}
        />
      )
    );
  };

  const handleClickCalendar = () => {
    dispatch(
      setModal(
        <BeneficiaryInvitation
          beneficiary={beneficiary}
          society={society}
          plan={plan}
          tab="CALENDAR"
        />
      )
    );
  };

  return (
    <>
      <tr>
        <td className="fw-bold d-none d-md-table-cell">{index}</td>

        <td
          id={plan?.['_id']}
          className="text-left w-200px d-block text-ellipsis text-primary cursor-pointer"
          onClick={handleClickPlanName}
        >
          {planName}
          <Tooltip anchorId={plan?.['_id']} place="bottom">
            {planName}
          </Tooltip>
        </td>

        <td className="text-right d-none d-md-table-cell">
          {formatNumber(unitsGranted)}
        </td>

        <td className="text-right d-none d-md-table-cell">
          {formatNumber(getVestedUnits(beneficiary, plan))}
        </td>

        {!isDemo && (
          <td className="text-center p-0">
            <span
              id={`beneficiary-plan-link-${index}`}
              className="btn btn-icon btn-trigger"
              onClick={handleClickCalendar}
            >
              <em className="icon ni ni-external text-primary" />
            </span>
          </td>
        )}
      </tr>
    </>
  );
}

export default BeneficiaryCalendarTabRow;
