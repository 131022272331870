import { editUser } from 'utils/modals';
import removePartner from 'utils/removePartner';

function partnerOptions(i18n) {
  return [
    {
      icon: 'ni-eye',
      text: i18n.t('Watch'),
      url: '/detalle-socio',
    },
    {
      icon: 'ni-pen',
      text: i18n.t('Edit'),
      action: editUser,
    },
    {
      icon: 'ni-repeat',
      text: i18n.t('Transactions'),
      url: '/detalle-socio',
      urlSuffix: '2',
    },
    {
      icon: 'ni-file',
      text: i18n.t('Documents'),
      url: '/detalle-socio',
      urlSuffix: '3',
    },
    {
      icon: 'ni-trash',
      text: i18n.t('Delete'),
      action: async ({ data, society, userId }) => {
        await removePartner(data, society, userId, i18n);
      },
      dontShowCurrent: ({ data }) => data?.operations?.length > 1,
    },
  ];
}
export default partnerOptions;
