/* eslint-disable no-underscore-dangle */
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Sidenav = () => {
  const { t } = useTranslation();
  const actualSociety = useSelector((state) => state.society?.actualSociety);

  return (
    <div
      className="nk-ibx-aside toggle-screen-lg"
      data-content="inbox-aside"
      data-toggle-overlay="true"
      data-toggle-screen="lg"
    >
      <div className="nk-ibx-nav" data-simplebar="init">
        <div className="simplebar-wrapper" style={{ margin: '0px' }}>
          <div className="simplebar-height-auto-observer-wrapper">
            <div className="simplebar-height-auto-observer" />
          </div>
          <div className="simplebar-mask">
            <div
              className="simplebar-offset"
              style={{ right: '0px', bottom: '0px' }}
            >
              <div
                className="simplebar-content-wrapper"
                role="region"
                aria-label="scrollable content"
                style={{ height: '100%', overflow: 'hidden scroll' }}
              >
                <div className="simplebar-content" style={{ padding: '0px' }}>
                  <ul className="nk-ibx-menu">
                    <li className="active">
                      <Link
                        className="nk-ibx-menu-item"
                        to={`/comunicaciones/${actualSociety?._id}`}
                      >
                        <em className="icon ni ni-send" />
                        <span className="nk-ibx-menu-text">{t('Sent')}</span>
                      </Link>
                    </li>
                    <li className="is-disable">
                      <a className="nk-ibx-menu-item is-disable" href="#">
                        <em className="icon ni ni-inbox" />
                        <span className="nk-ibx-menu-text">{t('Inbox')}</span>
                        <span className="badge badge-pill badge-primary">
                          0
                        </span>
                      </a>
                    </li>
                    <li className="is-disable">
                      <a className="nk-ibx-menu-item is-disable" href="#">
                        <em className="icon ni ni-edit" />
                        <span className="nk-ibx-menu-text">
                          {t('EmailDrafts')}
                        </span>
                        <span className="badge badge-pill badge-light">0</span>
                      </a>
                    </li>

                    <li className="is-disable">
                      <a className="nk-ibx-menu-item is-disable" href="#">
                        <em className="icon ni ni-trash" />
                        <span className="nk-ibx-menu-text">{t('Trash')}</span>
                        <span className="badge badge-pill badge-danger badge-dim">
                          0
                        </span>
                      </a>
                    </li>
                    <li className="is-disable">
                      <a className="nk-ibx-menu-item is-disable" href="#">
                        <em className="icon ni ni-emails" />
                        <span className="nk-ibx-menu-text">{t('All')} </span>
                      </a>
                    </li>
                  </ul>
                  <div className="nk-ibx-nav-head">
                    <h6 className="title">{t('Certificates')}</h6>
                    <a className="link is-disable" href="#">
                      <em className="icon ni ni-plus-c" />
                    </a>
                  </div>
                  <ul className="nk-ibx-label">
                    <li>
                      <a href="#" className="is-disable">
                        <em
                          className="nk-ibx-label-dot icon ni ni-shield-check text-soft"
                          style={{
                            fontSize: '1.5rem',
                          }}
                        />
                        <span
                          className="nk-ibx-label-text ml-1"
                          style={{
                            lineHeight: '1.5rem',
                          }}
                        >
                          {t('All')}
                        </span>
                      </a>
                    </li>
                  </ul>

                  {/* <div className="nk-ibx-nav-head">
                  <h6 className="title">Socios y beneficiarios</h6>
                  <a className="link is-disable" href="#">
                    <em className="icon ni ni-plus-c" />
                  </a>
                </div>
                <ul className="nk-ibx-contact">
                  <li>
                    <a href="#">
                      <div className="user-card">
                        <div className="user-avatar">
                          <img src="./images/avatar/a-sm.jpg" alt="" />
                        </div>
                        <div className="user-info">
                          <span className="lead-text">Marti Manent</span>
                          <span className="sub-text">Socio</span>
                        </div>
                      </div>
                    </a>
                    <div className="dropdown">
                      <a
                        href="#"
                        className="dropdown-toggle"
                        data-toggle="dropdown"
                      >
                        <em className="icon ni ni-more-v" />
                      </a>
                      <div className="dropdown-menu dropdown-menu-xs dropdown-menu-right">
                        <ul className="link-list-opt no-bdr">
                          <li>
                            <a href="html/ficha-socio.html">
                              <span>Ver ficha</span>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              data-toggle="modal"
                              data-target="#compose-mail"
                            >
                              {' '}
                              &gt;<span>Comunicación</span>
                            </a>
                          </li>
                          <li>
                            <a href="html/apps-chats.html">
                              <span>Chatear</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                  <li>
                    <a href="#">
                      <div className="user-card">
                        <div className="user-avatar">
                          <img src="./images/avatar/b-sm.jpg" alt="" />
                        </div>
                        <div className="user-info">
                          <span className="lead-text">Antonio Pérez</span>
                          <span className="sub-text">Socio</span>
                        </div>
                      </div>
                    </a>
                    <div className="dropdown">
                      <a
                        href="#"
                        className="dropdown-toggle"
                        data-toggle="dropdown"
                      >
                        <em className="icon ni ni-more-v" />
                      </a>
                      <div className="dropdown-menu dropdown-menu-xs dropdown-menu-right">
                        <ul className="link-list-opt no-bdr">
                          <li>
                            <a href="html/ficha-socio.html">
                              <span>Ver ficha</span>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              data-toggle="modal"
                              data-target="#compose-mail"
                            >
                              {' '}
                              &gt;<span>Comunicación</span>
                            </a>
                          </li>
                          <li>
                            <a href="html/apps-chats.html">
                              <span>Chatear</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                  <li>
                    <a href="#">
                      <div className="user-card">
                        <div className="user-avatar">
                          <img src="./images/avatar/c-sm.jpg" alt="" />
                        </div>
                        <div className="user-info">
                          <span className="lead-text">
                            María Pérez Gonázalez
                          </span>
                          <span className="sub-text">Beneficiaria</span>
                        </div>
                      </div>
                    </a>
                    <div className="dropdown">
                      <a
                        href="#"
                        className="dropdown-toggle"
                        data-toggle="dropdown"
                      >
                        <em className="icon ni ni-more-v" />
                      </a>
                      <div className="dropdown-menu dropdown-menu-xs dropdown-menu-right">
                        <ul className="link-list-opt no-bdr">
                          <li>
                            <a href="html/ficha-socio.html">
                              <span>Ver ficha</span>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              data-toggle="modal"
                              data-target="#compose-mail"
                            >
                              {' '}
                              &gt;<span>Comunicación</span>
                            </a>
                          </li>
                          <li>
                            <a href="html/apps-chats.html">
                              <span>Chatear</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                  <li>
                    <a href="#">
                      <div className="user-card">
                        <div className="user-avatar">
                          <img src="./images/avatar/d-sm.jpg" alt="" />
                        </div>
                        <div className="user-info">
                          <span className="lead-text">Eula Flowers</span>
                          <span className="sub-text">
                            Inversor Nota Convertible
                          </span>
                        </div>
                      </div>
                    </a>
                    <div className="dropdown">
                      <a
                        href="#"
                        className="dropdown-toggle"
                        data-toggle="dropdown"
                      >
                        <em className="icon ni ni-more-v" />
                      </a>
                      <div className="dropdown-menu dropdown-menu-xs dropdown-menu-right">
                        <ul className="link-list-opt no-bdr">
                          <li>
                            <a href="html/ficha-socio.html">
                              <span>Ver ficha</span>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#"
                              data-toggle="modal"
                              data-target="#compose-mail"
                            >
                              {' '}
                              &gt;<span>Comunicación</span>
                            </a>
                          </li>
                          <li>
                            <a href="html/apps-chats.html">
                              <span>Chatear</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul> */}
                </div>
              </div>
            </div>
          </div>
          <div
            className="simplebar-placeholder"
            style={{ width: 'auto', height: '718px' }}
          />
        </div>
        <div
          className="simplebar-track simplebar-horizontal"
          style={{ visibility: 'hidden' }}
        >
          <div
            className="simplebar-scrollbar"
            style={{ width: '0px', display: 'none' }}
          />
        </div>
        <div
          className="simplebar-track simplebar-vertical"
          style={{ visibility: 'visible' }}
        >
          <div
            className="simplebar-scrollbar"
            style={{
              height: '186px',
              transform: 'translate3d(0px, 0px, 0px)',
              display: 'block',
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Sidenav;
