/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable react/style-prop-object */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getActualSociety } from 'utils/filters';
import { getDocumentsByReference } from 'redux/actions/documentActions';

import { setMenu } from 'redux/actions/menuActions';
import { setModal } from 'redux/actions/modalActions';

import SendEmail from 'components/Modals/SendEmail';
import sizes from 'constants/sizes';
import Inbox from 'components/Communications/Inbox';
import Sidenav from 'components/Communications/Sidenav';
import InboxDetails from 'components/Communications/Inbox/InboxDetails';

function Communications() {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { societyId, communicationId } = useParams();

  const user = useSelector((state) => state.user);
  const isAdmin = useSelector((state) => state.society?.role?.isAdmin);
  const actualSociety = useSelector((state) => state.society?.actualSociety);
  const isDemo = useSelector((state) => state.society?.role?.isDemo);

  const [currentCommunication, setCurrentCommunication] = useState();

  function goBack() {
    history.goBack();
  }

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId) getActualSociety(user, societyId);
  }, [actualSociety, user, societyId]);

  useEffect(() => {
    if (actualSociety) {
      dispatch(
        setMenu({
          type: null,
          societyId: actualSociety['_id'],
          societyName: actualSociety?.name,
        })
      );
    }
  }, [actualSociety, dispatch]);

  useEffect(() => {
    if (societyId) {
      dispatch(getDocumentsByReference({ societyId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [societyId]);

  useEffect(() => {
    if (actualSociety?.communications?.length > 0) {
      setCurrentCommunication(
        actualSociety?.communications?.find(
          (communication) => communication['_id'] === communicationId
        )
      );
    }
  }, [actualSociety, communicationId]);

  return (
    <div className="nk-content-body">
      <div className="nk-content-wrap">
        <div className="nk-block-head pb-0">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">{actualSociety?.name}</h3>
            <p />
          </div>
          <div className="nk-block-between g-3">
            <div className="nk-block-head-content">
              <h3 className="nk-block-title page-title">
                {t('Communications')}
              </h3>
            </div>
            <div className="nk-block-head-content">
              <ul className="nk-block-tools g-3">
                <li>
                  <span
                    className="btn btn-white btn-outline-light"
                    aria-label="link"
                    onClick={() => goBack()}
                  >
                    <em className="icon ni ni-arrow-left" />
                    <span>{t('Back')}</span>
                  </span>
                </li>

                {isAdmin && (
                  <li className="preview-item">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() =>
                        dispatch(setModal(<SendEmail size={sizes.XL} />))
                      }
                      disabled={isDemo}
                    >
                      {t('NewCommunication')}
                    </button>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>

        <div className="nk-content-body pt-4">
          <div className="nk-content-wrap">
            <div className="nk-ibx">
              <Sidenav />
              <div className="nk-ibx-body bg-white">
                {/* <Topbar /> */}

                {!communicationId && (
                  <Inbox
                    communications={actualSociety?.communications?.sort(
                      (a, b) =>
                        new Date(b.date).getTime() - new Date(a.date).getTime()
                    )}
                  />
                )}

                {currentCommunication && (
                  <InboxDetails communication={currentCommunication} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Communications;
