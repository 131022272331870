/* eslint-disable react/react-in-jsx-scope */

import store from 'redux/store';
import Swal from 'sweetalert2';
import { setModal } from 'redux/actions/modalActions';
import AddPartnerDraftModal from 'components/Modals/AddPartnerDraft';
import draftTypes from 'constants/draftTypes';

function menuOptions(i18n) {
  return [
    {
      icon: 'ni-edit',
      text: i18n.t('Edit'),
      action: ({ addPartnerToDraft,updatePartnerToDraft, valuation,premium, action,partnerData,currentDraft,discountPercent,dueDate, draftType, setTouched }) => {
        store.dispatch(
          setModal(
            <AddPartnerDraftModal
              addPartnerToDraft={addPartnerToDraft}
              updatePartnerToDraft={updatePartnerToDraft}
              valuation={valuation}
              premium={premium}
              action={action}
              partnerData={partnerData}
              currentDraft={currentDraft}
              noteConditions={{
                discountPercent,
                dueDate,
              }}
              draftType={draftType}
              setTouched={setTouched}
              hasSharesProportional={draftType === draftTypes.DRAFT_CAPITAL_INCREASE}
            />
          )
        )
      },
    },
    {
      icon: 'ni-trash',
      text: i18n.t('Delete'),
      action: ({ partnerData, removePartnerFromDraft}) => {
        Swal.fire({
          icon: 'warning',
          title: `<h4 className="nk-block-title page-title">${i18n.t(
            'SureYouWantToDeleteInvestor'
          )}  `,
        html: `<h3 class="text-primary fw-normal">${partnerData?.name} </h3><br /><div class="caption-text"></div>`,
          confirmButtonText: i18n.t('OkDelete'),
          confirmButtonColor: '#6576ff',
          allowOutsideClick: false,
          showCancelButton: true,
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-light',
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            removePartnerFromDraft(partnerData.generatedKey);
                Swal.fire({
                  title: `${i18n.t('InvestorRemovedSuccessfully')}`,
                  icon: 'success',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                });
          }
        });
      },
    },
  ];
}
export default menuOptions;
