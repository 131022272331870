/* eslint-disable no-useless-escape */
import documentTypes from 'constants/documentTypes';
import fileTypes from 'constants/fileTypes';
import addDataToModel from 'utils/addDataToModel';
import draftToHtml from 'draftjs-to-html';
import oldTags from './oldTags';

export const generateDocument = async (
  editorRef,
  fileName = 'Documento sin nombre.pdf'
) => {
  if (editorRef) {
    try {
      const doc = await editorRef.plugins.export.convert('clientpdf', {});

      const documentFile = new File([doc], fileName, {
        type: 'application/pdf',
      });

      const newDocument = {
        name: fileName,
        file: documentFile,
        size: documentFile.size,
        date: new Date(),
        fileType: fileTypes.PDF,
        category: documentTypes.DOCUMENTS,
        lastAccess: new Date(),
        isGenerated: true,
        description: `Generado por plantilla`,
      };

      return { data: newDocument };
    } catch (error) {
      return { error };
    }
  }
};

export const downloadDocument = async (editorContent) => {
  editorContent.plugins.export.download('clientpdf', {});
};

export const updateDocumentWithValues = (editorContent, values) => {
  const valuesAndUrls = {};
  values.forEach((tagList) => {
    tagList.menu.forEach((tag) => {
      valuesAndUrls[tag.value] = tag.url || '';
    });
  });

  let docWithValues = '';
  const docInitial = editorContent.toString();

  Object.entries(valuesAndUrls).forEach(([key, value]) => {
    const regex = new RegExp(`\{\{${key}\}\}`, 'g');

    if (value) {
      docWithValues = !docWithValues
        ? docInitial.replace(regex, value)
        : docWithValues.replace(regex, value);
    }
  });

  const hasSocietySign = docInitial.indexOf('{{FIRMA_SOCIEDAD}}') !== -1;
  const hasBeneficiarySign =
    docInitial.indexOf('{{FIRMA_BENEFICIARIO}}') !== -1;
  const hasConsolidationCalendar =
    docInitial.indexOf('{{PLAN_CONSOLIDACION_PERSONALIZADA}}') !== -1;
  const hasConsolidationCalendarNoStatus =
    docInitial.indexOf('{{PLAN_CONSOLIDACION_PERSONALIZADA_SIN_ESTADO}}') !==
    -1;
  const hasConsolidationCalendarNoStatusENES =
    docInitial.indexOf(
      '{{PLAN_CONSOLIDACION_PERSONALIZADA_SIN_ESTADO_EN_ES}}'
    ) !== -1;
  const hasTenderOfferSummary =
    docInitial.indexOf('{{CUADRO_RESUMEN_TENDER_OFFER}}') !== -1;
  const hasTenderOfferSharesSummary =
    docInitial.indexOf('{{TENDER_ANEXO_RESUMEN}}') !== -1;

  return {
    html: docWithValues,
    hasSocietySign,
    hasBeneficiarySign,
    hasConsolidationCalendar,
    hasConsolidationCalendarNoStatus,
    hasConsolidationCalendarNoStatusENES,
    hasTenderOfferSummary,
    hasTenderOfferSharesSummary,
  };
};

export const formatOldTemplates = (templates) => {
  const templateData = oldTags({
    plan: {},
    society: {},
    partner: {},
    beneficiary: {},
  });

  const templateUpdated = addDataToModel({
    model: templates,
    data: templateData,
  });

  const templateHtml = draftToHtml(templateUpdated.draft);

  return {
    ...templateUpdated,
    html: templateHtml,
  };
};
