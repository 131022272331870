import React, { useState } from 'react';
import store from 'redux/store';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import sendContactEmail from 'redux/actions/contactActions';

import '../../assets/styles/Bootstrap.scss';
import './Contact.scss';

function Contact({ user }) {
  const { t } = useTranslation();

  const [type, setType] = useState();
  const [category, setCategory] = useState();
  const [priority, setPriority] = useState();
  const [details, setDetails] = useState();
  const [message, setMessage] = useState();
  const [file, setFile] = useState();

  const contactTypes = {
    GENERAL: t('GeneralQuestion'),
    INCIDENCE: t('HaveIncidence'),
  };

  const hiddenFileInput = React.useRef(null);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleReset = () => {
    setType('');
    setCategory('');
    setPriority('');
    setDetails('');
    setMessage('');
    setFile({});
  };

  function sendEmail(event) {
    event.preventDefault();

    const email = {
      type,
      category,
      priority,
      details,
      message,
      file,
      user: `${user.firstName} ${user.lastName || ''}`.trim(),
      email: user.email,
    };

    store.dispatch(sendContactEmail(email));
    handleReset();
    event.target.reset();
  }

  return (
    <div className="nk-content-body">
      <div className="nk-content-wrap">
        <div className="nk-block-head nk-block-head-lg">
          <div className="nk-block-head-sub">
            <a className="text-soft">
              <span>{t('ContactSttok')}</span>
            </a>
          </div>
          <div className="nk-block-head-content">
            <h2 className="nk-block-title fw-normal">{t('HowCanWeHelp')}</h2>
            <div className="nk-block-des">
              <p>{t('UseFollowingForm')}</p>
            </div>
          </div>
        </div>
        <div className="nk-block mb-3">
          <div className="card card-bordered">
            <div className="card-inner">
              <form className="form-contact is-alter" onSubmit={sendEmail}>
                <div className="row g-4">
                  <div className="col-md-6">
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        className="custom-control-input"
                        name="type"
                        id="type-general"
                        value={contactTypes.GENERAL}
                        onChange={(event) => setType(event.target.value)}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="type-general"
                      >
                        {t('GeneralQuestion')}
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        className="custom-control-input"
                        name="type"
                        id="type-problem"
                        value={contactTypes.INCIDENCE}
                        onChange={(event) => setType(event.target.value)}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="type-problem"
                      >
                        {t('HaveIncidence')}
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        <span>{t('Category')}</span>
                      </label>
                      <div className="form-control-wrap">
                        <select
                          className="form-control form-control-lg w-100"
                          data-search="off"
                          data-ui="lg"
                          onChange={(event) => setCategory(event.target.value)}
                          defaultValue=""
                        >
                          <option hidden value="" className="text-success">
                            {t('SelectCategory')}
                          </option>
                          <option value="General">{t('General')}</option>
                          <option value="Facturación">{t('Billing')}</option>
                          <option value="Técnica">{t('Technical')}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        <span>{t('Priority')}</span>
                      </label>
                      <div className="form-control-wrap">
                        <select
                          className="form-control form-control-lg w-100"
                          data-search="off"
                          data-ui="lg"
                          onChange={(event) => setPriority(event.target.value)}
                          defaultValue=""
                        >
                          <option hidden value="">
                            {t('SelectPriority')}
                          </option>
                          <option value="Normal">{t('Normal')}</option>
                          <option value="Importante">{t('Important')}</option>
                          <option value="Urgente">{t('Urgent')}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label className="form-label">
                        {t('DetailYourIssue')}
                      </label>
                      <div className="form-control-wrap">
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          placeholder={`${t('DetailYourIssue')}...`}
                          value={details}
                          onChange={(event) => setDetails(event.target.value)}
                          defaultValue=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label className="form-label">
                        <span>{t('FacilitatesMoreInfo')}</span>
                        <em className="icon ni ni-info text-gray" />
                      </label>
                      <p className="text-soft">
                        {t('FacilitatesMoreInfoDetails')}
                      </p>
                      <div className="form-control-wrap">
                        <div className="form-editor-custom">
                          <textarea
                            className="form-control form-control-lg no-resize"
                            placeholder={t('WriteYourMessage')}
                            value={message}
                            onChange={(event) => setMessage(event.target.value)}
                            defaultValue=""
                          />
                          <div className="form-editor-action p-2">
                            <em className="icon ni ni-clip fs-18 ml-1" />
                            <input
                              id="customFile"
                              type="file"
                              accept="application/pdf"
                              ref={hiddenFileInput}
                              style={{ display: 'none' }}
                              onChange={handleChange}
                              defaultValue=""
                            />
                            <span
                              className="link collapsed cursor-pointer"
                              onClick={handleClick}
                            >
                              {file?.name || t('AttachFile')}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="choose-file">
                      <div className="form-group collapse" id="filedown">
                        <div className="support-upload-box">
                          <div className="upload-zone">
                            <div className="dz-message" data-dz-message>
                              <em className="icon ni ni-clip" />
                              <span className="dz-message-text">
                                {t('AttachFile')}
                              </span>
                              <span className="dz-message-or">
                                {' '}
                                {t('AdministratedTitle')} {t('Or')}
                              </span>
                              <button className="btn btn-primary" type="button">
                                {t('Select')}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <button
                      type="submit"
                      className={
                        type && category && priority && details && message
                          ? 'btn btn-primary text-white'
                          : 'btn btn-primary text-white is-disable'
                      }
                    >
                      {t('Send')}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps({ user }) {
  return { user };
}

export default connect(mapStateToProps)(Contact);
