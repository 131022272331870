import alertBodyTypes from 'components/Alert/alertBodyTypes';

import axios from '../../interceptors/axios';

import { addAlert } from './alertActions';

export function sendEmail(newEmailData, showAlert = true) {
  const { file, ...emailData } = newEmailData;
  const formData = new FormData();

  return async (dispatch) => {
    try {
      if (file) {
        formData.append('file', file);
      }
      formData.append('data', JSON.stringify(emailData));

      await axios.post(`${process.env.REACT_APP_API_URL}/mail`, formData, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      });
      if (showAlert) dispatch(addAlert(alertBodyTypes['SEND_EMAIL_SENT']));
    } catch (error) {
      dispatch(addAlert(alertBodyTypes['ERROR_SEND_EMAIL']));
    }
  };
}

export default sendEmail;
