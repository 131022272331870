import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

type SummaryRow = {
  sharesTotal: number;
  assignedShares: number;
};

type BeneficiaryPlanProgressCardProps = {
  data: SummaryRow;
};

const BeneficiaryPlanProgressCard: FC<BeneficiaryPlanProgressCardProps> = ({
  data,
}) => {
  const { t } = useTranslation();
  const { sharesTotal, assignedShares } = data;

  return (
    <div className="card card-bordered card-stretch col-12 col-lg-4 ml-0 ml-lg-2 mt-0">
      <div className="card-inner">
        <h6 className="overline-title-alt mb-2">
          <em className="icon ni ni-meter mr-2  fs-20px" />
          <span>
            {((assignedShares * 100) / sharesTotal).toFixed(2) || 0}
            {t('UnitsPercentage')}
          </span>
        </h6>
        <div className="progress progress-md bg-light">
          <div
            className="progress-bar"
            data-progress={(assignedShares * 100) / sharesTotal || 0}
            style={{
              width: `${(assignedShares * 100) / sharesTotal || 0}%`,
            }}
          />
        </div>
        <div className="nk-fmg-status-info">
          <div className="float-md-left">0</div>
          <div className="float-md-right">100%</div>
        </div>
      </div>
    </div>
  );
};

export default BeneficiaryPlanProgressCard;
