/* eslint-disable no-underscore-dangle */
import alertBodyTypes from 'components/Alert/alertBodyTypes';
import { dateFormat } from 'constants/formats';
import presetRecipients from 'constants/presetRecipients';
import createSocietyEmail from 'constants/societyEmail';
import { format } from 'date-fns';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { addAlert } from 'redux/actions/alertActions';
import { sendCommEmail } from 'redux/actions/communicationActions';
import { setModal } from 'redux/actions/modalActions';
import { updateTenderOffer } from 'redux/actions/tenderOfferActions';
import {
  tenderOfferParticipantStatus,
  tenderStatusTypes,
} from 'utils/tenderOfferTypes';

const SendTenderOffer = ({ data, eligiblesList }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { user, actualSociety } = useSelector((state) => ({
    user: state.user,
    actualSociety: state.society?.actualSociety,
  }));

  const { _id, name, startDate, endDate } = data;

  const handleSendNotification = async () => {
    try {
      const participants = eligiblesList.map((item) => ({
        id: item.id,
        type: item.type,
        status: tenderOfferParticipantStatus.NOTIFIED,
        totalAccepted: 0,
      }));

      await dispatch(
        updateTenderOffer(_id, {
          status: tenderStatusTypes.ACTIVE.value,
          notificationDate: Date.now(),
          participants,
        })
      );

      const recipients = eligiblesList.map((recipient) => {
        const { email, name } = recipient;
        return {
          email: email.trim(),
          name,
        };
      });
      const recipientsForDynamicData = eligiblesList.map((recipient) => {
        const { id, email, name } = recipient;
        return {
          id,
          email: email.trim(),
          name,
        };
      });
      const subject = t('TenderOfferMailSubject', {
        societyName: actualSociety?.name,
      });
      const body = t('TenderOfferMailBody', {
        societyName: actualSociety?.name,
        startDate: format(new Date(startDate), dateFormat),
        endDate: format(new Date(endDate), dateFormat),
      });
      const presetGroup = presetRecipients.CUSTOM;

      const emailData = {
        to: recipients,
        subject,
        template: `send-tender-offer-${i18n.language}`,
        templateData: [
          {
            name: 'startDate',
            content: format(new Date(startDate), dateFormat) || '-',
          },
          {
            name: 'endDate',
            content: format(new Date(endDate), dateFormat) || '-',
          },
          {
            name: 'societyName',
            content: actualSociety?.name || '-',
          },
        ],
        templateDynamicData: recipientsForDynamicData.map(
          ({ id, name, email }) => ({
            rcpt: email,
            vars: [
              { name: 'name', content: name },
              {
                name: 'targetUrl',
                content: `${process.env.REACT_APP_DOMAIN}/#/participacion-tender-offer/${actualSociety._id}/${_id}/beneficiario/${id}`,
              },
            ],
          })
        ),
        templateBody: body,
        presetGroup,
        sender: {
          name: actualSociety?.name,
          email: createSocietyEmail(actualSociety?.name),
        },
        user: user['_id'],
        society: actualSociety['_id'],
        date: new Date(),
        status: 'SENT',
      };

      dispatch(sendCommEmail(emailData));

      dispatch(addAlert(alertBodyTypes[`TENDER_OFFER_SENT_${i18n.language}`]));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('[ERROR]', error);
    } finally {
      dispatch(setModal(null));
    }
  };

  return (
    <>
      <Modal.Body>
        <div className="text-right">
          <a
            className="text-soft fs-22px  cursor-pointer"
            onClick={() => dispatch(setModal(null))}
          >
            <em className="icon ni ni-cross" />
          </a>
        </div>
        <div className="modal-body modal-body-lg pt-0 text-center">
          <div className="nk-modal">
            <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-send bg-warning" />
            <h4 className="nk-modal-title">{t('SendOffer')}</h4>
            <div className="nk-modal-text">
              <p className="lead">
                {t('SendOfferModalMessagePart1', { offerName: name })}{' '}
                {t('SendOfferModalMessagePart2')}
              </p>
              <p className="text-soft">
                {t('SendOfferModalMessagePart3')}&nbsp;
                <b>{format(new Date(startDate), dateFormat)}</b> y&nbsp;
                <b>{format(new Date(endDate), dateFormat)}</b>.
              </p>
            </div>
            <div className="nk-modal-action mt-5">
              <button
                type="button"
                className="btn btn-light"
                onClick={handleSendNotification}
              >
                {t('SendNow')}
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </>
  );
};

export default SendTenderOffer;
