import React from 'react';

import { useTranslation } from 'react-i18next';

const labels = {
  'Constitution date': 'Date',
  'Number of new shares': 'Shares',
  'Shares nominal value': 'NominalValue',
};
const inputTypes = {
  'Constitution date': 'date',
  'Number of new shares': 'number',
  'Shares nominal value': 'number',
};

const ConstitutionTable = ({ constitutionData, changeConstitutionParam }) => {
  const { t } = useTranslation();

  return (
    <div
      key="constitution-card"
      className="card card-bordered card-preview mb-2"
    >
      <table className="table table-tranx" id="constitution--table">
        <thead>
          <tr className="tb-tnx-head">
            <th className="text-left" colSpan={3}>
              <span>{t('Capital')}</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(constitutionData).map(([key, value]) => (
            <tr className="tb-tnx-item" key={`constitution-${key}-card`}>
              <td className="tb-tnx-info w-150px">
                <div className="tb-tnx-info w-150px">
                  <span className="title">
                    <b>{t(labels[key]) || key}</b>
                  </span>
                </div>
              </td>
              <td className="tb-tnx-info">
                <div className="tb-tnx-info w-100">
                  <input
                    id={`constitution-${key}-input`}
                    type={inputTypes[key]}
                    value={value}
                    className="form-control date-picker"
                    max="2100-01-01"
                    min="1000-01-01"
                    onChange={(event) =>
                      changeConstitutionParam(key, event.target.value)
                    }
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ConstitutionTable;
