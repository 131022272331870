/* eslint-disable no-await-in-loop */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useMixpanel } from 'react-mixpanel-browser';
import { v4 as uuidv4 } from 'uuid';
import store from 'redux/store';
import { cloneDeep } from 'lodash';
import { format } from 'date-fns';
import NumberFormat from 'react-number-format';

import { addDocument } from 'redux/actions/documentActions';
import { updateDraft } from 'redux/actions/draftActions';
import { setModal } from 'redux/actions/modalActions';
import { addPartner, capitalIncreaseAction } from 'redux/actions/modalsActions';

import { numberFormat, currencyFormatDecimals } from 'constants/formats';
import operationTypes from 'constants/operationTypes';
import { generateEmail } from 'utils/filters';
import transactionTypes from 'constants/transactionTypes';
import eventTypes from 'constants/eventTypes';
import fileTypes from 'constants/fileTypes';
import documentTypes from 'constants/documentTypes';

import trackEvent from 'utils/trackEvent';

import '../Modals.scss';

function DraftToCapitalIncreaseModal({
  updatedPartners,
  setPartners,
  draft,
  draftId,
  setTouched,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();

  const { user, actualSociety } = useSelector((state) => ({
    user: state.user,
    actualSociety: state.society?.actualSociety,
  }));

  const [step, setStep] = useState(0);
  const [file, setFile] = useState();

  const [newShares, setNewShares] = useState([]);
  const [partnersToAdd, setPartnersToAdd] = useState([]);

  const [date, setDate] = useState('');
  const [lastOperationDate, setLastOperationDate] = useState();

  const [operationDate, setOperationDate] = useState();
  const [operationLimit, setOperationLimit] = useState();

  const [errorMessage, setErrorMessage] = useState('');

  const handleDate = (selectedDate) => {
    const objDate = new Date(selectedDate);
    if (objDate.getFullYear() < 1970) {
      if (operationDate) setOperationDate();
    } else if (objDate > new Date(lastOperationDate)) {
      setErrorMessage('');
      setDate(objDate);
      setOperationDate(format(objDate, 'yyyy-MM-dd'));
    } else {
      const nextDate = new Date(lastOperationDate);
      nextDate.setMinutes(nextDate.getMinutes() + 30);
      setDate(nextDate);
      setOperationDate(format(nextDate, 'yyyy-MM-dd'));
    }
  };

  const saveIncrease = async () => {
    try {
      let uploadedDocument = null;
      let currentSociety = cloneDeep(actualSociety);
      if (file) {
        const newDocument = {
          file,
          date,
          size: file.size,
          name: operationTypes.CAPITAL_INCREASE_FILENAME,
          author: user['_id'],
          society: actualSociety['_id'],
          fileType: fileTypes[file.type],
          category: documentTypes.SCRIPTURES,
          description: t('DocumentLinkedToOperation'),
        };
        uploadedDocument = await store.dispatch(addDocument(newDocument));
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const partner of partnersToAdd) {
        const { cif, name, email } = partner;
        const newEmail = email || generateEmail(currentSociety);
        currentSociety = await store.dispatch(
          addPartner(
            {
              name,
              email: newEmail,
              date,
              category: null,
              society: actualSociety?.['_id'],
              societyName: actualSociety?.name,
              userEmail: user.email,
              cif,
              user: user['_id'],
              isNaturalPerson: true,
            },
            false
          )
        );
      }
      // Create movements and transactions for Capital Increase op
      const movements = updatedPartners
        .filter((partner) => partner?.contribution > 0)
        .map((partner) => {
          const actualPartner = currentSociety?.partners.find(
            (p) => p.cif === partner.cif
          );
          return {
            partner: actualPartner?.['_id'] || '',
            shareFrom: partner?.shareFrom,
            shareTo: partner?.shareTo,
            shareClass: partner?.holdingClass,
            sharePremium: partner?.premiumByShare || 0,
            operation: 'THIS',
          };
        });
      const transactions = movements.map((movement) => ({
        ...movement,
        transactionType: transactionTypes.CAPITAL_INCREASE,
      }));
      const society = await store.dispatch(
        capitalIncreaseAction({
          movements,
          transactions,
          date,
          nominalValue: actualSociety.nominalValue,
          society: actualSociety['_id'],
          user: user['_id'],
          documents: uploadedDocument?.['_id'] ? [uploadedDocument['_id']] : [],
        })
      );
      setTouched(false);
      // Update draft data
      setPartners([]);
      if (draftId) {
        const operationId = society?.operations
          ?.filter(
            (op) => op?.operationType === operationTypes.CAPITAL_INCREASE
          )
          .reduce(
            (acc, curr) =>
              new Date(acc.date) < new Date(curr.date) ? curr : acc,
            { date: new Date(-8640000000000000) }
          )?._id;
        const newDraft = {
          ...draft,
          isConverted: true,
          convertedDate: new Date(),
          convertedOperation: operationId || null,
          draftPartners: JSON.stringify(
            updatedPartners
              ?.filter((partner) => partner.contribution > 0)
              .map((partner) => ({
                name: partner.name,
                cif: partner?.cif,
                email: partner?.email,
                society: partner.society,
                contribution: partner.contribution,
                realContribution: partner?.realContribution,
                contributionNotRound: partner?.contributionNotRound,
                discountPercent: partner?.discountPercent || 0,
                discountByValuation: partner?.discountByValuation,
                holdingClass: partner?.holdingClass,
                societyPartnerId: partner.societyPartnerId,
                isPartnerAlready: partner.isPartnerAlready,
                proportionality: partner.proportionality,
                proportionalityPercent: partner?.proportionalityPercent || 0,
                shares: partner?.shares,
                shareFrom: partner?.shareFrom,
                shareTo: partner?.shareTo,
                nominal: partner?.nominal,
                premium: partner?.premium,
                premiumByShare: partner?.premiumByShare,
                NDTotal: partner?.NDTotal,
                NDPercent: partner?.NDPercent,
                FDPotential: partner?.FDPotential,
                FDTotal: partner?.FDTotal,
              }))
          ),
        };
        dispatch(updateDraft(draftId, newDraft, false));

        trackEvent(mixpanel, eventTypes.CAPITAL_INCREASE, {
          userId: user?._id,
          userName: user?.name,
          userEmail: user?.email,
          societyId: actualSociety?._id,
          societyName: actualSociety?.name,
          operation: eventTypes.CAPITAL_INCREASE,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      window.location.href = `#/socios-libro/${actualSociety['_id']}`;
      dispatch(setModal(null));
    }
  };

  const nextStep = (event) => {
    event.preventDefault();

    if (!step) {
      setStep(1);
    } else saveIncrease();
  };

  useEffect(() => {
    if (actualSociety) {
      const excludedOps = [
        operationTypes.ADD_PARTNERS,
        operationTypes.ADD_PARTNER,
        operationTypes.ADD_BENEFICIARY,
        operationTypes.STOCK_OPTION,
        operationTypes.PHANTOM_SHARE,
      ];
      const filteredOperations = actualSociety.operations.filter(
        (operation) => !excludedOps.includes(operation.operationType)
      );
      const lastOperation = filteredOperations.reduce((acc, op) =>
        new Date(acc.date) > new Date(op.date) ? acc : op
      );
      // Get last operation date from UTC to local timezone with new Date()
      const lastDate = format(
        new Date(lastOperation.date),
        "yyyy-MM-dd'T'HH:mm"
      );
      const dateLimit = format(new Date(lastOperation.date), 'yyyy-MM-dd');
      setOperationLimit(dateLimit);
      setLastOperationDate(lastDate || '1990-01-01T00:00');
    }
  }, [actualSociety]);

  useEffect(() => {
    if (updatedPartners?.length && actualSociety?.partners?.length) {
      const shares = updatedPartners
        .filter((partner) => partner?.contribution > 0)
        .map((partner) => ({
          key: uuidv4(),
          cif: partner?.cif,
          name: partner?.name,
          contribution: partner?.contribution,
          shareFrom: partner?.shareFrom,
          shareTo: partner?.shareTo,
          shareClass: partner?.holdingClass,
          shareClassName: partner?.className || '',
          sharePremium: 0,
        }));
      const newPartners = updatedPartners
        .filter(
          (partner) =>
            !partner?.societyPartnerId &&
            !actualSociety.partners.some((p) => p.cif === partner?.cif)
        )
        .map((partner) => ({
          name: partner?.name,
          email: partner?.email || '',
          cif: partner?.cif,
        }));
      setNewShares(shares);
      setPartnersToAdd(newPartners);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety, updatedPartners]);

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title">{t('CapitalExpansion')}</h5>
        <a
          className="close cursor-pointer"
          onClick={() => dispatch(setModal(null))}
        >
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
      <Modal.Body>
        <form className="form-validate is-alter" onSubmit={nextStep}>
          {!step ? (
            <>
              <div className="form-group">
                <label className="form-label" htmlFor="pay-amount">
                  {t('ExpansionDate')}
                </label>
                <div className="form-control-wrap">
                  <input
                    type="date"
                    className="form-control"
                    value={operationDate}
                    onChange={(event) => handleDate(event.target.value)}
                    required
                    max="2100-01-01"
                    min={operationLimit}
                  />
                </div>
                {errorMessage && (
                  <span className="sub-text mt-1 text-danger">
                    {errorMessage}
                  </span>
                )}
              </div>

              <div className="form-group">
                <label className="form-label" htmlFor="default-06">
                  {t('AddWritingOptional')}
                </label>
                <div className="form-control-wrap">
                  <div className="custom-file">
                    <input
                      id="customFile"
                      type="file"
                      accept="application/pdf"
                      className="custom-file-input"
                      onChange={(event) => setFile(event.target.files[0])}
                    />
                    <label className="custom-file-label" htmlFor="customFile">
                      {file?.name || t('SelectDocument')}
                    </label>
                  </div>
                </div>
              </div>

              <button type="submit" className="btn btn-lg btn-primary">
                {t('Following')}
              </button>
            </>
          ) : (
            <>
              <div className="nk-block nk-block-lg">
                <div className="card card-bordered card-preview mb-4">
                  <table
                    className="table table-striped nk-tb-list nk-tb-ulist"
                    id="capital-increase-table"
                  >
                    <thead>
                      <tr className="nk-tb-head">
                        <th className="nk-tb-col tb-col-xl text-left">
                          <span>Nº</span>
                        </th>
                        <th className="nk-tb-col text-left w-150px">
                          <span>{t('Name')}</span>
                        </th>
                        <th className="nk-tb-col tb-col-xl text-left">
                          <span>{t('Contribution')}</span>
                        </th>
                        <th className="nk-tb-col text-left">
                          <span>{t('From')}</span>
                        </th>
                        <th className="nk-tb-col text-left">
                          <span>{t('To')}</span>
                        </th>
                        <th className="nk-tb-col tb-col-xl text-left">
                          <span>{t('Class')}</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {newShares.length > 0 &&
                        newShares.map((data, index) => (
                          <tr className="nk-tb-item" key={data.key}>
                            <td className="nk-tb-col tb-col-xl">
                              <span className="text-primary">{index + 1}</span>
                            </td>
                            <td className="nk-tb-col w-150px">
                              <span className="title">{data.name}</span>
                            </td>
                            <td className="nk-tb-col tb-col-xl text-nowrap">
                              <span className="amount">
                                <NumberFormat
                                  displayType="text"
                                  value={data.contribution}
                                  {...currencyFormatDecimals}
                                />
                              </span>
                            </td>
                            <td className="nk-tb-col text-nowrap">
                              <span className="amount">
                                <NumberFormat
                                  displayType="text"
                                  value={data.shareFrom}
                                  {...numberFormat}
                                />
                              </span>
                            </td>
                            <td className="nk-tb-col text-nowrap">
                              <span className="amount">
                                <NumberFormat
                                  displayType="text"
                                  value={data.shareTo}
                                  {...numberFormat}
                                />
                              </span>
                            </td>
                            <td className="nk-tb-col tb-col-xl text-nowrap">
                              <span className="title">
                                {data.shareClassName}
                              </span>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <button
                type="submit"
                className="btn btn-lg btn-primary"
                disabled={!newShares.length}
              >
                {!step ? t('Following') : t('Save')}
              </button>
            </>
          )}
        </form>
      </Modal.Body>
    </>
  );
}

export default DraftToCapitalIncreaseModal;
