const internalFF = 'showDetails';
const newPDF = 'newPDF';
const beneficiariesTest = 'btest';

const hasShowDetails = () => JSON.parse(localStorage.getItem(internalFF));

export const hasNewPDF = () => JSON.parse(localStorage.getItem(newPDF));

export const hasBeneficiariesTest = () =>
  JSON.parse(localStorage.getItem(beneficiariesTest));

export const hasInternalView = () =>
  JSON.parse(localStorage.getItem('show-dev'));

export default hasShowDetails;
