import React from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import store from 'redux/store';
import { removeAlert } from '../../redux/actions/alertActions';

import './Alert.scss';
import alertOptions from './alertOptions';

function LegalAlert({ title, html, footer, type, props }) {
  const myAlert = withReactContent(Swal);

  myAlert.fire({
    showCloseButton: true,
    icon: alertOptions[type]?.icon,
    title,
    html,
    footer,
    confirmButtonText: 'Cerrar',
    confirmButtonColor: '#6576ff',
    allowOutsideClick: false,
    ...props,
    willClose: () => store.dispatch(removeAlert()),
  });

  return <></>;
}

export default LegalAlert;
