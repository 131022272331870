/* eslint-disable no-case-declarations */
import actionTypes from 'redux/actions/actionTypes';
import initialState from '../initialState';

export default function reducer(
  holdingClasses = initialState.holdingClasses,
  action
) {
  switch (action.type) {
    case actionTypes.CREATE_HOLDING_CLASS:
      return [...holdingClasses, action.holdingClass];

    case actionTypes.GET_HOLDING_CLASSES: {
      if (action?.data[0]?.createdAt) {
        return action.data.sort(
          (a, b) =>
            new Date(a?.createdAt)?.getTime() -
            new Date(b?.createdAt)?.getTime()
        );
      }
      return action.data;
    }

    case actionTypes.UPDATE_HOLDING_CLASS:
      return [
        ...holdingClasses.filter(
          (holdingClass) => holdingClass['_id'] !== action.holdingClass['_id']
        ),
        action.holdingClass,
      ];

    default:
      return holdingClasses;
  }
}
