/* eslint-disable no-nested-ternary */
/* eslint-disable react/display-name */
import React from 'react';
import i18n from 'i18n/config';

import screens from 'constants/screens';
import userTypes from 'constants/userTypes';
import MenuDots from 'components/MenuDots';
import CellData from 'components/Tables/CellData';
import screenSizes from 'constants/screenSizes';

function tableColumns(i18n) {
  return [
    // {
    //   name: i18n.t('EmployeesPlan'),
    //   field: 'employeesPlan',
    //   selector: (row) => row[i18n.t('EmployeesPlan')],
    //   sortable: true,
    //   grow: 3,
    //   cell: (row) =>
    //     CellData.EmployeesPlan({
    //       employeesPlan: row[i18n.t('EmployeesPlan')],
    //     }),
    //   export: true,
    // },
    {
      name: i18n.t('EmployeesPlan'),
      field: 'employeesPlan',
      selector: (row) => row[i18n.t('EmployeesPlan')],
      sortable: true,
      grow: 3,
      cell: (row) =>
        CellData.Plan({
          name: row[i18n.t('EmployeesPlan')]?.name,
          isDraft: row.draft,
          url: `/empleados-planes/${row.societyId}/${row.id}`,
          initials: row.initials,
        }),
      export: true,
    },
    {
      name: i18n.t('Plan'),
      field: 'name',
      selector: (row) => row[i18n.t('Plan')],
      sortable: true,
      compact: true,
      grow: 1.5,
      cell: (row) =>
        CellData.PlainText({
          text: row[i18n.t('Plan')],
        }),
      export: true,
    },

    {
      name: i18n.t('Status'),
      field: 'status',
      selector: (row) => row[i18n.t('Status')],
      sortable: true,
      compact: true,
      cell: (row) =>
        CellData.BadgeText({
          text: row[i18n.t('Status')]?.text,
          // remove "badge-" from the color
          style: row[i18n.t('Status')]?.color?.slice(6),
          outline: false,
        }),
      export: true,
    },
    {
      name: i18n.t('Units'),
      field: 'units',
      selector: (row) => row[i18n.t('Units')],
      sortable: true,
      center: true,
      compact: true,
      cell: (row) =>
        CellData.NumberNoDecimals({
          value: row[i18n.t('Units')],
        }),
      export: true,
    },

    {
      name: i18n.t('PercentFD'),
      field: 'percentFD',
      selector: (row) => row[i18n.t('PercentFD')],
      sortable: true,
      center: true,
      compact: true,
      cell: (row) =>
        CellData.PercentDecimals({
          value: row[i18n.t('PercentFD')],
        }),
      export: true,
    },

    {
      name: i18n.t('Beneficiaries'),
      field: 'beneficiaries',
      selector: (row) => row[i18n.t('Beneficiaries')],
      sortable: true,
      center: true,
      compact: true,
      cell: (row) =>
        CellData.NumberNoDecimals({
          value: row[i18n.t('Beneficiaries')],
        }),
      export: true,
    },

    // {
    //   name: i18n.t('DateOfConcession'),
    //   field: 'planStartDate',
    //   selector: (row) => row[i18n.t('DateOfConcession')],
    //   sortable: true,
    //   center: true,
    //   compact: true,
    //   cell: (row) =>
    //     CellData.PlainDate({
    //       date: row[i18n.t('DateOfConcession')],
    //     }),
    //   export: true,
    // },
    // {
    //   name: i18n.t('Units'),
    //   field: 'units',
    //   selector: (row) => row[i18n.t('Units')],
    //   sortable: true,
    //   center: true,
    //   compact: true,
    //   cell: (row) =>
    //     CellData.NumberNoDecimals({
    //       value: row[i18n.t('Units')],
    //     }),
    //   export: true,
    // },
    // {
    //   name: i18n.t('ExercisePrice'),
    //   field: 'amount',
    //   selector: (row) => row[i18n.t('ExercisePrice')],
    //   sortable: true,
    //   center: true,
    //   compact: true,
    //   cell: (row) =>
    //     CellData.CurrencyDecimals({
    //       value: row[i18n.t('ExercisePrice')],
    //     }),
    //   export: true,
    // },
    // {
    //   name: i18n.t('%FD'),
    //   field: 'FDpercent',
    //   selector: (row) => row[i18n.t('%FD')],
    //   sortable: true,
    //   center: true,
    //   compact: true,
    //   cell: (row) =>
    //     CellData.PercentDecimals({
    //       value: row[i18n.t('%FD')],
    //       decimals: row.decimals,
    //     }),
    //   export: true,
    //   hide: screenSizes.LG,
    // },
    // {
    //   name: i18n.t('JobTitle'),
    //   field: 'jobTitle',
    //   selector: (row) => row[i18n.t('JobTitle')],
    //   sortable: true,
    //   center: true,
    //   compact: true,
    //   cell: (row) =>
    //     CellData.PlainText({
    //       text: row[i18n.t('JobTitle')],
    //     }),
    //   export: true,
    //   hide: screenSizes.LG,
    // },
    // {
    //   name: i18n.t('Incentive'),
    //   field: 'incentive',
    //   selector: (row) => row[i18n.t('Incentive')],
    //   sortable: true,
    //   compact: true,
    //   center: true,
    //   cell: (row) =>
    //     CellData.PlainText({
    //       text: row[i18n.t('Incentive')],
    //     }),
    //   export: true,
    //   hide: screenSizes.LG,
    // },
    // {
    //   name: i18n.t('Plan'),
    //   field: 'planName',
    //   selector: (row) => row[i18n.t('Plan')],
    //   sortable: true,
    //   center: true,
    //   compact: true,
    //   cell: (row) =>
    //     CellData.PlainText({
    //       text: row[i18n.t('Plan')],
    //       className: 'ml-2',
    //     }),
    //   export: true,
    // },
    // {
    //   name: i18n.t('ConsolidatedPercent'),
    //   field: 'consolidatedPercent',
    //   selector: (row) => row[i18n.t('ConsolidatedPercent')],
    //   sortable: true,
    //   compact: true,
    //   cell: (row) =>
    //     CellData.ConsolidationPercent({
    //       consolidatedPercent: row[i18n.t('ConsolidatedPercent')],
    //     }),
    //   export: true,
    // },
    // {
    //   name: i18n.t('Invitation'),
    //   field: 'invitationStatus',
    //   selector: (row) => row[i18n.t('Invitation')],
    //   sortable: true,
    //   compact: true,
    //   cell: (row) =>
    //     CellData.BadgeText({
    //       text: row[i18n.t('Invitation')]?.text,
    //       style: row[i18n.t('Invitation')]?.color || 'dark',
    //     }),
    //   export: true,
    // },
    // {
    //   name: i18n.t('ConsolidationDate'),
    //   field: 'consolidationDate',
    //   selector: (row) => row[i18n.t('ConsolidationDate')],
    //   sortable: true,
    //   center: true,
    //   compact: true,
    //   cell: (row) =>
    //     CellData.PlainDate({
    //       date: row[i18n.t('ConsolidationDate')],
    //     }),
    //   export: true,
    //   hide: screens.LG,
    // },
    // {
    //   name: i18n.t('Status'),
    //   field: 'statusName',
    //   selector: (row) => row[i18n.t('Status')],
    //   sortable: true,
    //   center: true,
    //   compact: true,
    //   omit: true,
    //   cell: (row) =>
    //     CellData.PlainText({
    //       text: row[i18n.t('Status')],
    //     }),
    //   export: true,
    //   hide: screens.LG,
    // },
    // {
    //   name: i18n.t('UnitsSeen'),
    //   field: 'vestedUnits',
    //   selector: (row) => row[i18n.t('UnitsSeen')],
    //   sortable: true,
    //   center: true,
    //   compact: true,
    //   omit: true,
    //   cell: (row) =>
    //     CellData.NumberNoDecimals({
    //       value: row[i18n.t('UnitsSeen')],
    //     }),
    //   export: true,
    //   hide: screens.LG,
    // },
    // {
    //   name: i18n.t('ToVest'),
    //   field: 'toVestUnits',
    //   selector: (row) => row[i18n.t('ToVest')],
    //   sortable: true,
    //   center: true,
    //   compact: true,
    //   omit: true,
    //   cell: (row) =>
    //     CellData.NumberNoDecimals({
    //       value: row[i18n.t('ToVest')],
    //     }),
    //   export: true,
    //   hide: screens.LG,
    // },
    // {
    //   name: i18n.t('MonthsToVest'),
    //   field: 'months',
    //   selector: (row) => row[i18n.t('MonthsToVest')],
    //   sortable: true,
    //   center: true,
    //   compact: true,
    //   omit: true,
    //   cell: (row) =>
    //     CellData.NumberNoDecimals({
    //       value: row[i18n.t('MonthsToVest')],
    //     }),
    //   export: true,
    //   hide: screens.LG,
    // },

    // {
    //   name: i18n.t('LaboralCategory'),
    //   field: 'laboralCategory',
    //   selector: (row) => row[i18n.t('LaboralCategory')],
    //   sortable: true,
    //   center: true,
    //   compact: true,
    //   omit: true,
    //   cell: (row) =>
    //     CellData.PlainText({
    //       text: row[i18n.t('LaboralCategory')],
    //     }),
    //   export: true,
    //   hide: screens.LG,
    // },
    // {
    //   name: i18n.t('StrikePrice'),
    //   field: 'strikePrice',
    //   selector: (row) => row[i18n.t('StrikePrice')],
    //   sortable: true,
    //   center: true,
    //   compact: true,
    //   omit: true,
    //   cell: (row) =>
    //     CellData.PlainText({
    //       text: row[i18n.t('StrikePrice')],
    //     }),
    //   export: true,
    //   hide: screens.LG,
    // },
    {
      sortable: false,
      cell: (row) => (
        <ul className="nk-tb-actions gx-1">
          <li>
            <div className="dropdown">
              {/* <MenuDots
                menuOptions={row.beneficiaryOptions}
                id={row.beneficiary['_id']}
                params={{
                  data: row.beneficiary,
                  societyId: row.societyId,
                  userType: userTypes.BENEFICIARY,
                  certificateData: row.certificateData,
                }}
                downloadURL={row.documentURL}
                direction="left"
              /> */}
            </div>
          </li>
        </ul>
      ),
      export: false,
      right: true,
      width: '50px',
      allowOverflow: true,
    },
  ];
}

export default tableColumns;
