/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import ContentLoader from 'react-content-loader';

const cards = 6;
const cardHeight = 345;

const CardLoader = (props) => {
  const totalCards = props?.cards || cards;
  const totalHeight = (totalCards / 3) * cardHeight;

  return (
    <ContentLoader
      width="100%"
      height={totalHeight}
      backgroundColor="#eaeced"
      foregroundColor="#ffffff"
      style={{ width: '100%' }}
      {...props}
    >
      {/* 1st */}
      <rect x="0" y="0" rx="3" ry="3" width="31%" height="2" />
      <rect x="0" y="315" rx="3" ry="3" width="31%" height="2" />
      <rect x="0" y="2" rx="3" ry="3" width="2" height="315" />
      <rect x="30.9%" y="2" rx="3" ry="3" width="2" height="315" />

      <circle cx="5.5%" cy="55" r="4%" />
      <rect x="10.5%" y="35" rx="0" ry="0" width="17.5%" height="15" />
      <rect x="10.5%" y="60" rx="0" ry="0" width="10%" height="15" />

      <rect x="2.5%" y="105" rx="0" ry="0" width="18%" height="15" />
      <rect x="2.5%" y="175" rx="0" ry="0" width="18%" height="15" />
      <rect x="23.5%" y="175" rx="0" ry="0" width="4.5%" height="15" />
      <rect x="2.5%" y="205" rx="0" ry="0" width="25.5%" height="10" />
      <rect x="2.5%" y="230" rx="0" ry="0" width="10%" height="15" />

      <circle cx="4%" cy="275" r="2%" />
      <circle cx="8%" cy="275" r="2%" />
      <rect x="22.5%" y="250" rx="0" ry="0" width="5.5%" height="15" />
      {/* 2nd */}
      <rect x="34%" y="0" rx="3" ry="3" width="31%" height="2" />
      <rect x="34%" y="315" rx="3" ry="3" width="31%" height="2" />
      <rect x="34%" y="2" rx="3" ry="3" width="2" height="315" />
      <rect x="65.5%" y="2" rx="3" ry="3" width="2" height="315" />

      <circle cx="40.5%" cy="55" r="4%" />
      <rect x="45.5%" y="35" rx="0" ry="0" width="17.5%" height="15" />
      <rect x="45.5%" y="60" rx="0" ry="0" width="10%" height="15" />

      <rect x="37.5%" y="105" rx="0" ry="0" width="18%" height="15" />
      <rect x="37.5%" y="175" rx="0" ry="0" width="18%" height="15" />
      <rect x="58.5%" y="175" rx="0" ry="0" width="4.5%" height="15" />
      <rect x="37.5%" y="205" rx="0" ry="0" width="25.5%" height="10" />
      <rect x="37.5%" y="230" rx="0" ry="0" width="10%" height="15" />

      <circle cx="39%" cy="275" r="2%" />
      <circle cx="43%" cy="275" r="2%" />
      <rect x="57.5%" y="250" rx="0" ry="0" width="5.5%" height="15" />
      {/* 3rd */}
      <rect x="68.8%" y="0" rx="3" ry="3" width="31.5%" height="2" />
      <rect x="69%" y="315" rx="3" ry="3" width="31%" height="2" />
      <rect x="69%" y="2" rx="3" ry="3" width="2" height="315" />
      <rect x="99.9%" y="2" rx="3" ry="3" width="2" height="315" />

      <circle cx="75.5%" cy="55" r="4%" />
      <rect x="80.5%" y="35" rx="0" ry="0" width="17.5%" height="15" />
      <rect x="80.5%" y="60" rx="0" ry="0" width="10%" height="15" />

      <rect x="72.5%" y="105" rx="0" ry="0" width="18%" height="15" />
      <rect x="72.5%" y="175" rx="0" ry="0" width="18%" height="15" />
      <rect x="93.5%" y="175" rx="0" ry="0" width="4.5%" height="15" />
      <rect x="72.5%" y="205" rx="0" ry="0" width="25.5%" height="10" />
      <rect x="72.5%" y="230" rx="0" ry="0" width="10%" height="15" />

      <circle cx="74%" cy="275" r="2%" />
      <circle cx="78%" cy="275" r="2%" />
      <rect x="92.5%" y="250" rx="0" ry="0" width="5.5%" height="15" />

      {/* 4th */}
      <rect x="0" y="345" rx="3" ry="3" width="31%" height="2" />
      <rect x="0" y="660" rx="3" ry="3" width="31%" height="2" />
      <rect x="0" y="347" rx="3" ry="3" width="2" height="315" />
      <rect x="30.9%" y="347" rx="3" ry="3" width="2" height="315" />

      <circle cx="5.5%" cy="400" r="4%" />
      <rect x="10.5%" y="380" rx="0" ry="0" width="17.5%" height="15" />
      <rect x="10.5%" y="405" rx="0" ry="0" width="10%" height="15" />

      <rect x="2.5%" y="450" rx="0" ry="0" width="18%" height="15" />
      <rect x="2.5%" y="520" rx="0" ry="0" width="18%" height="15" />
      <rect x="23.5%" y="520" rx="0" ry="0" width="4.5%" height="15" />
      <rect x="2.5%" y="550" rx="0" ry="0" width="25.5%" height="10" />
      <rect x="2.5%" y="575" rx="0" ry="0" width="10%" height="15" />

      <circle cx="4%" cy="620" r="2%" />
      <circle cx="8%" cy="620" r="2%" />
      <rect x="22.5%" y="595" rx="0" ry="0" width="5.5%" height="15" />
      {/* 5th */}
      <rect x="34%" y="345" rx="3" ry="3" width="31%" height="2" />
      <rect x="34%" y="660" rx="3" ry="3" width="31%" height="2" />
      <rect x="34%" y="347" rx="3" ry="3" width="2" height="315" />
      <rect x="65.5%" y="347" rx="3" ry="3" width="2" height="315" />

      <circle cx="40.5%" cy="400" r="4%" />
      <rect x="45.5%" y="380" rx="0" ry="0" width="17.5%" height="15" />
      <rect x="45.5%" y="405" rx="0" ry="0" width="10%" height="15" />

      <rect x="37.5%" y="450" rx="0" ry="0" width="18%" height="15" />
      <rect x="37.5%" y="520" rx="0" ry="0" width="18%" height="15" />
      <rect x="58.5%" y="520" rx="0" ry="0" width="4.5%" height="15" />
      <rect x="37.5%" y="550" rx="0" ry="0" width="25.5%" height="10" />
      <rect x="37.5%" y="575" rx="0" ry="0" width="10%" height="15" />

      <circle cx="39%" cy="620" r="2%" />
      <circle cx="43%" cy="620" r="2%" />
      <rect x="57.5%" y="595" rx="0" ry="0" width="5.5%" height="15" />
      {/* 3rd */}
      <rect x="68.8%" y="345" rx="3" ry="3" width="31.5%" height="2" />
      <rect x="69%" y="660" rx="3" ry="3" width="31%" height="2" />
      <rect x="69%" y="347" rx="3" ry="3" width="2" height="315" />
      <rect x="99.9%" y="347" rx="3" ry="3" width="2" height="315" />

      <circle cx="75.5%" cy="400" r="4%" />
      <rect x="80.5%" y="380" rx="0" ry="0" width="17.5%" height="15" />
      <rect x="80.5%" y="405" rx="0" ry="0" width="10%" height="15" />

      <rect x="72.5%" y="450" rx="0" ry="0" width="18%" height="15" />
      <rect x="72.5%" y="520" rx="0" ry="0" width="18%" height="15" />
      <rect x="93.5%" y="520" rx="0" ry="0" width="4.5%" height="15" />
      <rect x="72.5%" y="550" rx="0" ry="0" width="25.5%" height="10" />
      <rect x="72.5%" y="575" rx="0" ry="0" width="10%" height="15" />

      <circle cx="74%" cy="620" r="2%" />
      <circle cx="78%" cy="620" r="2%" />
      <rect x="92.5%" y="595" rx="0" ry="0" width="5.5%" height="15" />
    </ContentLoader>
  );
};

export default CardLoader;
