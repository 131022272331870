/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import percentRound from 'percent-round';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import { setModal } from 'redux/actions/modalActions';
import { getBoards, updateBoard } from 'redux/actions/boardActions';

import {
  getPartnerNDPercent,
  getDecimalScale,
  getSocietyValue,
} from 'utils/filters';

import { BLANK_PROFILE_PICTURE } from 'constants/defaultConstants';
import userTypes from 'constants/userTypes';

import Dialog from 'components/Dialog';

function AssistentsModal({ society, boardId }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { boards } = useSelector((state) => ({
    boards: state.boards,
  }));

  const [assistsAll, setAssistsAll] = useState(null);
  const [assistants, setAssistants] = useState([]);
  const [hasAssistants, setHasAssistants] = useState(false);
  const [totalAssistants, setTotalAssistants] = useState(0);
  const [totalPercentND, setTotalPercentND] = useState(0);

  const [partners, setPartners] = useState([]);

  const [searchQuery, setSearchQuery] = useState('');
  const [allPartners, setAllPartners] = useState([]);
  const [filteredPartners, setFilteredPartners] = useState(partners);
  const handleChangeAssistsAll = (event) => {
    const assists = event.target.value === 'true';
    setAssistsAll(assists);
  };

  const animatedComponents = makeAnimated();

  const filterPartners = (query) => {
    const filtered = partners.filter((partner) =>
      partner.name.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredPartners(filtered);
  };

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    filterPartners(query);
  };

  const handleChangeRepresentative = (index, value) => {
    if (value !== '') {
      const newFilteredPartners = [...filteredPartners];
      const representativePartner = partners.find(
        (partner) => partner.member === value
      );
      if (representativePartner) {
        if (representativePartner?.isRepresented) {
          newFilteredPartners[index].representative = '';
          newFilteredPartners[index].assists = false;
          newFilteredPartners[index].isAbsent = true;
        } else if (representativePartner?.assists) {
          newFilteredPartners[index].assists = true;
          newFilteredPartners[index].isAbsent = false;
          newFilteredPartners[index].representative = value;
        } else {
          newFilteredPartners[index].representative = value;
          newFilteredPartners[index].assists = false;
          newFilteredPartners[index].isAbsent = true;
        }
      }

      const newPartners = partners.map((partner) => {
        if (partner.member === newFilteredPartners[index].member) {
          if (representativePartner) {
            if (representativePartner?.isRepresented) {
              return {
                ...partner,
                representative: '',
                assists: false,
                isAbsent: true,
              };
            }
            if (representativePartner?.assists) {
              return {
                ...partner,
                representative: value,
                assists: true,
                isAbsent: false,
              };
            }
            return {
              ...partner,
              representative: value,
              assists: false,
              isAbsent: true,
            };
          }
        }
        return partner;
      });
      setPartners(newPartners);
      setFilteredPartners(newFilteredPartners);
    }
  };
  const handleChangeRepresentation = (index, value) => {
    const newFilteredPartners = [...filteredPartners];
    newFilteredPartners[index].isRepresented = value;
    newFilteredPartners[index].representative = '';

    const newPartners = partners.map((partner) => {
      if (partner.member === newFilteredPartners[index].member) {
        return { ...partner, isRepresented: value, representative: '' };
      }
      return partner;
    });
    setPartners(newPartners);
    setFilteredPartners(newFilteredPartners);
    if (value) {
      handleChangeRepresentative(index, partners[0].member);
    }
  };

  const handlePartnerOptionChange = (index) => (selectedOption) => {
    handleChangeRepresentative(index, selectedOption.value);
  };
  const handleChangeAssists = (index, value) => {
    const newFilteredPartners = [...filteredPartners];

    newFilteredPartners[index].assists = value;
    newFilteredPartners[index].isAbsent = !value;
    let updatedPartnerIndex = -1;
    const newPartners = partners.map((partner, index1) => {
      if (partner.member === newFilteredPartners[index].member) {
        updatedPartnerIndex = index1;
        return { ...partner, assists: value, isAbsent: !value };
      }
      return partner;
    });

    for (let i = 0; i < newFilteredPartners.length; i += 1) {
      const partner = newFilteredPartners[i];
      if (
        partner?.representative === newFilteredPartners[index].member ||
        (newFilteredPartners[index].isRepresented &&
          partner?.member === newFilteredPartners[index].representative)
      ) {
        newFilteredPartners[i].assists = value;
        newFilteredPartners[i].isAbsent = !value;
      }
    }

    for (let i = 0; i < newPartners.length; i += 1) {
      const partner = newPartners[i];
      if (
        partner?.representative === newPartners[updatedPartnerIndex].member ||
        (newPartners[updatedPartnerIndex].isRepresented &&
          partner?.member === newPartners[updatedPartnerIndex].representative)
      ) {
        newPartners[i].assists = value;
        newPartners[i].isAbsent = !value;
      }
    }
    setPartners(newPartners);
    setFilteredPartners(newFilteredPartners);
  };

  // validation functions
  const isDelegate = (memberId, participants) =>
    !!participants?.find((p) => p.representative === memberId);
  const isParticipant = (memberId, participants) =>
    !!participants?.find((p) => p.member === memberId);
  const isAssistant = (participant, participants) => {
    if (participant?.representative) {
      const representative = participants?.find(
        (p) => p.member === participant?.representative
      );
      const representedPartners = participants?.filter(
        (p) => p.representative === participant?.representative
      );
      if (representative) {
        return [representative, ...representedPartners].some(
          (partner) => partner?.votes?.length > 0
        );
      }
    }
    return false;
  };

  const getParticipantsForSave = (addAssistantsAbsent) =>
    partners.map((partner) => ({
      member: partner.member,
      name: partner.name || '',
      email: partner.email || '',
      userType: userTypes.PARTNER,
      assists: partner?.assists,
      isAbsent: !partner?.assists && addAssistantsAbsent,
      isRepresented: partner?.isRepresented && partner?.representative !== '',
      representative: partner?.isRepresented ? partner?.representative : null,
      hasDelegated: partner?.hasDelegated,
      delegationVote: partner?.delegationVote,
      delegationDocuments: partner?.delegationDocuments,
      votes: partner?.votes || [],
    }));

  const saveAssistants = async () => {
    Dialog({
      icon: 'ni-question',
      title: t('SetNoParticipantsAbsent'),
      buttonText: t('Yes,IWant'),
      cancelButtonText: t('Nope,Thanks'),
      onConfirm: () => {
        const participants = getParticipantsForSave(true);
        dispatch(updateBoard(boardId, { participants }));
        dispatch(setModal(null));
      },
      onCancel: () => {
        const participants = getParticipantsForSave(false);
        dispatch(updateBoard(boardId, { participants }));
        dispatch(setModal(null));
      },
    });
  };

  useEffect(() => {
    if (assistants?.length) {
      const societyPartners = [];
      const societyValue = getSocietyValue(society);
      const scale = societyValue ? getDecimalScale(societyValue.value) : 2;
      const sharePercents = society.partners.map((partner) =>
        getPartnerNDPercent(partner, society)
      );

      const roundPercents = percentRound(sharePercents, scale);
      society.partners.forEach((partner, index) => {
        const partnerPercent = roundPercents[index];
        if (partner?.sharesCount?.actual >= 0) {
          const currentAssistant = assistants?.find(
            (assistant) =>
              assistant.userType === userTypes.PARTNER &&
              assistant.member === partner['_id']
          );
          societyPartners.push({
            image: partner?.image || BLANK_PROFILE_PICTURE,
            member: partner['_id'],
            name: partner.name || '',
            cif: partner.cif,
            email: partner?.email,
            percentage: partnerPercent || 0,
            assists: !!currentAssistant?.assists,
            isAbsent: !!currentAssistant?.isAbsent,
            hasDelegated: !!currentAssistant?.hasDelegated,
            isRepresented: !!currentAssistant?.isRepresented,
            representative: currentAssistant?.representative || null,
            delegationVote: currentAssistant?.delegationVote || null,
            delegationDocuments: currentAssistant?.delegationDocuments || [],
            votes: currentAssistant?.votes || [],
            hasShares: partner?.sharesCount?.actual >= 0,
          });
        }
      });

      const partnersWithShares = societyPartners.filter(
        (partner) => partner.hasShares
      );
      setAllPartners(societyPartners);

      setPartners(partnersWithShares);
      setFilteredPartners(partnersWithShares);
    }
  }, [assistants]);

  useEffect(() => {
    if (assistsAll) {
      const newPartners = [];
      partners.map((partner) =>
        newPartners.push({
          ...partner,
          assists: true,
          isAbsent: false,
        })
      );
      setPartners(newPartners);
      setFilteredPartners(newPartners);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assistsAll]);

  useEffect(() => {
    let currentHasAssistants = false;
    let currentTotalAssistants = 0;
    let currentPercentND = 0;

    partners.forEach((partner) => {
      if (partner.assists) {
        currentHasAssistants = true;
        currentTotalAssistants += 1;
        currentPercentND += partner.percentage;
      }
    });
    setHasAssistants(currentHasAssistants);
    setTotalPercentND(currentPercentND);
    setTotalAssistants(currentTotalAssistants);
  }, [partners]);

  const isRepresentativePartner = (id, list) => {
    const partner = list.find((p) => p.member === id);
    return !!partner;
  };

  const getCurrentValues = async () => {
    dispatch(getBoards(society['_id']));
  };
  useEffect(() => {
    if (society) {
      getCurrentValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [society]);

  useEffect(() => {
    if (boards.length) {
      const actualBoard = boards.find((board) => board['_id'] === boardId);
      setAssistants(actualBoard?.participants || []);
    }
  }, [boards, boardId]);

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title">{t('ControlOfMeetingAttendees')}</h5>
        <span className="badge-primary badge-sm rounded px-2 py-1 ml-2 fw-bold">
          {+totalPercentND.toFixed(3) || 0}% capital
        </span>
        <a
          className="close cursor-pointer"
          onClick={() => dispatch(setModal(null))}
        >
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
      <Modal.Body>
        <div className="form-group">
          <div className="d-flex mt-1">
            <label className="form-label" htmlFor="fw-first-name">
              {t('HaveAllThePartnersAttended')}
            </label>
            <div className="custom-control custom-radio ml-3 mr-3">
              <input
                type="radio"
                id="yes-constituted"
                name="society-status"
                className="custom-control-input"
                value
                checked={assistsAll === true}
                onChange={handleChangeAssistsAll}
              />
              <label className="custom-control-label" htmlFor="yes-constituted">
                {t('Yes')}
              </label>
            </div>

            <div className="custom-control custom-radio">
              <input
                type="radio"
                id="no-constituted"
                name="society-status"
                className="custom-control-input"
                value={false}
                checked={assistsAll === false}
                onChange={handleChangeAssistsAll}
              />
              <label className="custom-control-label" htmlFor="no-constituted">
                No
              </label>
            </div>
            <div className="custom-control custom-radio mt-1">
              <div className="form-icon form-icon-left">
                <em className="icon ni ni-search" />
              </div>
              <input
                type="text"
                className="form-control form-control-lg"
                value={searchQuery}
                onChange={handleSearch}
                placeholder={t('SearchPartner')}
              />
            </div>
          </div>

          <div className="form-group">
            <label className="form-label" htmlFor="fw-first-name">
              {t('SelectTheMembersWhoHaveAttended')}:
            </label>
            <div className="form-control-wrap ">
              <div className="card card-bordered card-full">
                {filteredPartners.length > 0 &&
                  filteredPartners
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((partner, index) => (
                      <>
                        <ul
                          key={partner?.cif || index}
                          className={
                            index + 1 > filteredPartners.length - 1
                              ? 'nk-activity'
                              : 'nk-activity border-bottom'
                          }
                        >
                          <li className="nk-activity-item px-0 py-1">
                            <div className="col-6 d-flex align-center">
                              <div className="custom-control custom-control-xs custom-checkbox">
                                <input
                                  type="checkbox"
                                  id={`assistent-${index}`}
                                  className="custom-control-input"
                                  value={filteredPartners[index].assists}
                                  checked={filteredPartners[index].assists}
                                  disabled={
                                    filteredPartners[index]?.votes?.length ||
                                    isAssistant(
                                      filteredPartners[index],
                                      filteredPartners
                                    )
                                  }
                                  onChange={(event) =>
                                    handleChangeAssists(
                                      index,
                                      event.target.checked
                                    )
                                  }
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor={`assistent-${index}`}
                                />
                              </div>
                              <div className="nk-activity-media user-avatar user-avatar-sm bg-success">
                                <img src={partner.image} alt="" />
                              </div>
                              <div className="nk-activity-data">
                                <div className="label">
                                  <b>{partner?.name}</b> | {partner?.cif} |{' '}
                                  {partner?.percentage}%
                                </div>
                              </div>
                            </div>

                            <div className="custom-control custom-control-xs custom-checkbox col-2">
                              <input
                                type="checkbox"
                                id={`representated-${index}`}
                                className="custom-control-input"
                                value={filteredPartners[index].isRepresented}
                                checked={filteredPartners[index].isRepresented}
                                disabled={
                                  filteredPartners[index].hasDelegated ||
                                  filteredPartners[index]?.votes?.length ||
                                  isDelegate(
                                    filteredPartners[index].member,
                                    partners
                                  )
                                }
                                onChange={(event) =>
                                  handleChangeRepresentation(
                                    index,
                                    event.target.checked
                                  )
                                }
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={`representated-${index}`}
                              >
                                {t('Represented')}
                              </label>
                            </div>

                            {filteredPartners[index]?.isRepresented ? (
                              <div className="col-4">
                                <div className="form-group">
                                  <div className="form-control-wrap">
                                    <div className="form-icon form-icon-left">
                                      <em className="icon ni ni-user" />
                                    </div>
                                    {!filteredPartners[index]?.representative ||
                                    isParticipant(
                                      filteredPartners[index]?.representative,
                                      filteredPartners
                                    ) ? (
                                      <Select
                                        closeMenuOnSelect
                                        className="react-select"
                                        isDisabled={
                                          filteredPartners[index]
                                            .hasDelegated ||
                                          filteredPartners[index]?.votes
                                            ?.length ||
                                          isDelegate(
                                            filteredPartners[index].member,
                                            filteredPartners
                                          )
                                        }
                                        value={{
                                          value:
                                            filteredPartners[index]
                                              .representative ??
                                            partners[0]?.member,
                                          label: filteredPartners[index]
                                            .representative
                                            ? `${
                                                partners.find(
                                                  (partner) =>
                                                    partner.member ===
                                                    filteredPartners[index]
                                                      .representative
                                                )?.cif
                                              } | ${
                                                partners.find(
                                                  (partner) =>
                                                    partner.member ===
                                                    filteredPartners[index]
                                                      .representative
                                                )?.name
                                              }`
                                            : `${partners[0]?.cif} | ${partners[0]?.name}`,
                                        }}
                                        options={partners
                                          .filter(
                                            (partner) =>
                                              partner.member !==
                                                filteredPartners[index]
                                                  ?.member &&
                                              !partner?.isRepresented
                                          )
                                          .map((partner) => ({
                                            value: partner?.member,
                                            label: `${partner?.cif} | ${partner?.name}`,
                                          }))}
                                        components={animatedComponents}
                                        onChange={handlePartnerOptionChange(
                                          index
                                        )}
                                      />
                                    ) : (
                                      <Select
                                        className="react-select"
                                        isDisabled
                                        value={{
                                          value:
                                            filteredPartners[index]
                                              .representative,
                                          label: `${
                                            allPartners.find(
                                              (partner) =>
                                                partner.member ===
                                                filteredPartners[index]
                                                  .representative
                                            )?.cif
                                          } | ${
                                            allPartners.find(
                                              (partner) =>
                                                partner.member ===
                                                filteredPartners[index]
                                                  .representative
                                            )?.name
                                          }`,
                                        }}
                                        components={animatedComponents}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}
                          </li>
                        </ul>
                      </>
                    ))}
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex flex-row justify-content-end align-items-center h-50px">
          <button
            type="button"
            className="btn btn-lg btn-outline-primary btn-dim"
            onClick={saveAssistants}
          >
            {t('Confirm')} {totalAssistants || ''}{' '}
            {totalAssistants === 1 ? t('Assistant') : t('Assistants')}
          </button>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <span className="sub-text">{t('OnlyAttendeesAreReflected')}</span>
      </Modal.Footer>
    </>
  );
}

export default AssistentsModal;
