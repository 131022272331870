/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { useMixpanel } from 'react-mixpanel-browser';
import { v4 as uuidv4 } from 'uuid';
import { format } from 'date-fns';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import store from 'redux/store';
import html2pdf from 'html3pdf';

// eslint-disable-next-line import/no-unresolved, import/no-extraneous-dependencies
import jsPDF from 'jspdf';
import alertBodyTypes from 'components/Alert/alertBodyTypes';
import { addAlert } from 'redux/actions/alertActions';
import { addDocument } from 'redux/actions/documentActions';
import { addBeneficiary } from 'redux/actions/modalsActions';
import { updateBeneficiary } from 'redux/actions/beneficiaryActions';
import { setModal } from 'redux/actions/modalActions';
import vestingOptions from 'constants/vestingOptions';
import cliffOptions from 'constants/cliffOptions';
import consolidationOptions from 'constants/consolidationOptions';
import cleanHtmlEntities from 'utils/editor';
import {
  getActualSociety,
  getUsedSharesFromPlan,
  isBeneficiaryAlready,
  getConsolidationPeriods,
} from 'utils/filters';
import NumberFormat from 'react-number-format';
import {
  numberFormat,
  currencyFormatDecimals,
  cleanValue,
} from 'constants/formats';
import operationTypes from 'constants/operationTypes';
import invitationStatus from 'constants/invitationStatus';

import trackEvent from 'utils/trackEvent';
import { createBlobMerged } from 'utils/downloadMergedPDF';
import { generateCalendarPlanTable } from 'utils/htmlTemplates';
import addHtmlAlign from 'utils/addHtmlAlign';
import { getSingleDocument } from 'utils/downloadDocument';
import { hasNewPDF } from 'utils/showDetails';
import { transformHtmlTables } from 'utils/transformHtmlTables';
import { generatePdfBlob } from 'utils/generatePdfBlob';
import { clearTableLines } from 'utils/clearTableLines';

import eventTypes from 'constants/eventTypes';
import { EMAIL_PATTERN, JSPDF_MARGINS } from 'constants/defaultConstants';
import signatureTypes from 'constants/signatureTypes';
import { startSignProcess, toBase64 } from 'utils/docusignActions';
import { updateDocumentWithValues } from 'components/EditorWYSIWYG/helpers';
import tags from 'components/EditorWYSIWYG/tags';
import documentTypes from 'constants/documentTypes';
import fileTypes from 'constants/fileTypes';
import '../Modals.scss';
import './AddBeneficiary.scss';

function AddBeneficiaryModal({
  action = 'add',
  currentBeneficiary,
  isDraft = false,
}) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();
  const user = useSelector((state) => state.user);
  const stockPlans = useSelector((state) => state.plans);
  const tenderOffers = useSelector((state) => state.tenderOffers);
  const actualSociety = useSelector((state) => state.society?.actualSociety);

  const [name, setName] = useState('');
  const [cif, setCif] = useState('');
  const [email, setEmail] = useState('');
  const [addressLine, setAddressLine] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [plan, setPlan] = useState('');
  const [date, setDate] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [invitationDate, setInvitationDate] = useState(
    format(new Date(), 'yyyy-MM-dd')
  );
  const [shares, setShares] = useState();
  const [file, setFile] = useState();
  const [currentPlan, setCurrentPlan] = useState('');
  const [currentPlanShares, setCurrentPlanShares] = useState(0);
  const [amount, setAmount] = useState(0);

  const [currentPlanLaboralCategories, setCurrentPlanLaboralCategories] =
    useState([]);
  const [laboralCategory, setLaboralCategory] = useState('');
  const [salary, setSalary] = useState('');

  const [invitationCode, setInvitationCode] = useState('');
  const [invitationStatusType, setInvitationStatusType] = useState();

  // Custom conditions
  const [hasCustomConditions, setHasCustomConditions] = useState(false);
  const [sharePrice, setSharePrice] = useState();
  const [isFixedPrice, setIsFixedPrice] = useState(false);
  const [cliff, setCliff] = useState(0);
  const [vestingPeriod, setVestingPeriod] = useState();
  const [consolidation, setConsolidation] = useState();
  const [customConsolidation, setCustomConsolidation] = useState(false);
  const [consolidationPeriods, setConsolidationPeriods] = useState([]);
  const [attachmentDocument, setAttachmentDocument] = useState(null);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleConsolidationPeriodsChange = (index, value) => {
    const newConsolidationPeriods = [...consolidationPeriods];
    newConsolidationPeriods[index] = +value;
    setConsolidationPeriods(newConsolidationPeriods);
  };

  const parseVestingMonths = (months) =>
    vestingOptions.find((option) => option.value === Number(months))?.text;

  const parseCliffMonths = (months) =>
    cliffOptions.find((option) => option.value === Number(months))?.text;

  const getAttachedDocument = async (documentId) => {
    if (documentId) {
      const attachmentBlob = await getSingleDocument(documentId);
      setAttachmentDocument(attachmentBlob);
    }
  };

  const savePartner = async (event) => {
    event.preventDefault();
    try {
      const parsedCategory =
        currentPlan?.hasLaboralCategories && laboralCategory
          ? JSON.parse(laboralCategory)
          : { name: '', percent: 0 };

      const newBeneficiary = {
        email,
        society: actualSociety?.['_id'],
        societyLogo: actualSociety?.additional?.['logo'],
        userId: user['_id'],
        userEmail: user.email,
        societyName: actualSociety?.name,
        address: {
          line1: addressLine,
          zip: '',
          city: '',
          state: '',
          country: '',
        },
        cif,
        plan,
        planName: currentPlan?.name,
        planType: currentPlan?.planType,
        planStartDate: new Date(date),
        amount,
        sharesCount: { future: shares },
        name,
        jobTitle,
        laboralCategory: {
          name: parsedCategory.name,
          percent: parsedCategory.percent,
        },
        salary,
        hasInvitation: currentPlan?.hasInvitation,
        invitationDate: currentPlan?.hasInvitation
          ? new Date(invitationDate)
          : null,
        invitationCode,
        invitationStatus: invitationStatusType,
        isDraft,
      };
      if (hasCustomConditions) {
        newBeneficiary.customConditions = {
          sharePrice,
          isFixedPrice,
          cliff,
          vestingPeriod,
          consolidation,
          customConsolidation,
          consolidationPeriods,
        };
      }
      if (action === 'add' || action === 'duplicate') {
        let validBeneficiary = true;
        const isCifAlready = isBeneficiaryAlready(cif, plan, actualSociety);

        if (
          !isDraft &&
          currentPlan?.hasInvitation &&
          currentPlan?.invitationModel &&
          !currentPlan?.invitationModel?.html
        ) {
          return Swal.fire({
            icon: 'warning',
            title: `<h4 class="nk-modal-title">${t(
              'EmptyInvitationLetter'
            )}</h4><br /><p class="caption-text">${t(
              'EmptyInvitationLetterMessage'
            )}</p>`,
            confirmButtonText: t('Accept'),
            confirmButtonColor: '#6576ff',
            allowOutsideClick: false,
          });
        }

        if (isCifAlready) {
          await Swal.fire({
            icon: 'warning',
            title: `<h4 class="nk-modal-title">${t('BeneficiaryAlreadyExists', {
              cif,
              planName: currentPlan?.name,
            })}</h4>`,
            confirmButtonText: t('Accept'),
            cancelButtonText: t('Cancel'),
            confirmButtonColor: '#6576ff',
            allowOutsideClick: false,
            showCancelButton: true,
          }).then((result) => {
            if (!result.isConfirmed) {
              validBeneficiary = false;
            }
          });
        }
        if (validBeneficiary) {
          let uploadedDocument = '';

          if (file) {
            const fileName = `${operationTypes.ADD_BENEFICIARY_FILENAME}_${file.name}`;
            const newDocument = {
              file,
              date,
              name: fileName,
              author: user['_id'],
              society: actualSociety['_id'],
              size: file?.size || 0,
              lastAccess: new Date(),
              category: documentTypes.LETTERS,
              isGenerated: true,
              fileType: fileTypes[file?.type],
              description: t('DocumentLinkedToBeneficiary'),
            };
            uploadedDocument = await store.dispatch(addDocument(newDocument));
          }

          const documents = [];
          if (uploadedDocument?.['_id']) {
            documents.push(uploadedDocument?.['_id']);
          }

          let fileName = '';
          let invitationFile = '';
          let invitationDocument = '';
          let hasSocietySign = false;

          if (
            currentPlan?.hasInvitation &&
            currentPlan?.invitationModel &&
            !isDraft
          ) {
            const invitationUpdated = updateDocumentWithValues(
              currentPlan?.invitationModel?.html,
              tags({
                plan: currentPlan,
                society: actualSociety,
                beneficiary: newBeneficiary,
                partner: null,
                board: null,
              })
            );

            fileName = `Carta_de_Invitación_${newBeneficiary.name}_${currentPlan.name}.pdf`;
            const invitationModel = invitationUpdated.html;
            const {
              hasConsolidationCalendar,
              hasConsolidationCalendarNoStatus,
              hasConsolidationCalendarNoStatusENES,
            } = invitationUpdated;
            hasSocietySign = invitationUpdated.hasSocietySign;

            const invitationHTML = cleanHtmlEntities(invitationModel);
            const alignedText = addHtmlAlign(invitationHTML);
            let mainBlob = null;

            if (hasNewPDF()) {
              const transformedHtml = transformHtmlTables(invitationModel);
              const clearTablesHtml = clearTableLines(transformedHtml);
              mainBlob = await generatePdfBlob(clearTablesHtml);
            } else {
              // eslint-disable-next-line new-cap
              const pdf = new jsPDF('p', 'pt', 'a4');

              const margins = JSPDF_MARGINS;

              await new Promise((resolve, reject) => {
                pdf.fromHTML(
                  alignedText,
                  JSPDF_MARGINS.left,
                  JSPDF_MARGINS.top,
                  {
                    width: JSPDF_MARGINS.width,
                  },
                  () => {
                    try {
                      mainBlob = pdf.output('blob');
                      resolve();
                    } catch (error) {
                      reject(error);
                    }
                  },
                  margins
                );
              });
            }

            const mainFile = new File([mainBlob], fileName, {
              type: 'application/pdf',
            });

            const tableFiles = [];
            if (
              hasConsolidationCalendar ||
              hasConsolidationCalendarNoStatus ||
              hasConsolidationCalendarNoStatusENES
            ) {
              const consolidationTables = generateCalendarPlanTable(
                getConsolidationPeriods(newBeneficiary, currentPlan),
                {
                  hasConsolidationCalendar,
                  hasConsolidationCalendarNoStatus,
                  hasConsolidationCalendarNoStatusENES,
                }
              );

              const options = {
                filename: 'attachment',
                margin: [10, 10, 10, 10], // top, right, bottom, left
                pagebreak: { mode: 'avoid-all' },
                html2canvas: { scale: 2 },
                jsPDF: {
                  unit: 'mm',
                  format: 'a4',
                  orientation: 'portrait',
                },
              };
              // Create a file for each table
              for (const table of consolidationTables) {
                const tableBlob = await html2pdf()
                  .from(table)
                  .set(options)
                  .output('blob');

                const tableFile = new File([tableBlob], fileName, {
                  type: 'application/pdf',
                });
                tableFiles.push(tableFile);
              }
            }

            let attachmentFile;
            if (attachmentDocument) {
              attachmentFile = new File([attachmentDocument], fileName, {
                type: 'application/pdf',
              });
            }

            const mergedBlob =
              tableFiles?.length > 0 || attachmentFile
                ? await createBlobMerged({
                    documents: [mainFile, ...tableFiles, attachmentFile],
                  })
                : mainBlob;

            invitationFile = new File([mergedBlob], fileName, {
              type: 'application/pdf',
            });

            const newDocument = {
              name: fileName,
              file: invitationFile,
              size: invitationFile?.size || 0,
              date: new Date(),
              fileType: fileTypes.PDF,
              lastAccess: new Date(),
              isGenerated: true,
              author: user['_id'],
              society: actualSociety['_id'],
              category: documentTypes.LETTERS,
              description: t('DocumentGenerated'),
            };

            invitationDocument = await store.dispatch(addDocument(newDocument));
            if (invitationDocument?.['_id']) {
              documents.push(invitationDocument?.['_id']);
            }
          }

          const uploadedBeneficiary = await store.dispatch(
            addBeneficiary({
              ...newBeneficiary,
              documents,
            })
          );

          if (
            currentPlan?.hasInvitation &&
            currentPlan?.invitationModel &&
            !isDraft
          ) {
            const signFileBase64 = await toBase64(invitationFile);

            const signers = [
              {
                id: uploadedBeneficiary['_id'],
                email: newBeneficiary?.email,
                name: newBeneficiary?.name,
                role: 'BENEFICIARY',
                type: signatureTypes.INVITATION_LETTER,
              },
            ];

            if (hasSocietySign) {
              signers.push({
                id: '',
                email: currentPlan?.invitationExtraSigner?.email || '',
                name: currentPlan?.invitationExtraSigner?.name || '',
                role: 'SOCIETY',
                type: signatureTypes.FREE_SIGNATURE,
              });
            }

            const signProcessData = {
              signers,
              societyName: actualSociety?.name,
              documentName: fileName,
              planName: currentPlan?.name,
              language: actualSociety?.societyLanguage || 'es',
              redirectParams: [
                actualSociety['_id'],
                currentPlan['_id'],
                uploadedBeneficiary['_id'],
              ],
              signatureType: signatureTypes.INVITATION_LETTER,
              signatureFile: signFileBase64,
              signatureFileName: fileName,
            };
            await startSignProcess(signProcessData);
          }

          trackEvent(mixpanel, eventTypes.ADD_BENEFICIARY, {
            userId: user?._id,
            userName: user?.name,
            userEmail: user?.email,
            societyId: actualSociety?._id,
            societyName: actualSociety?.name,
            operation: eventTypes.ADD_BENEFICIARY,
          });

          dispatch(setModal(null));

          dispatch(addAlert(alertBodyTypes.SOCIETY_UPDATED));
        }
      } else if (action === 'edit') {
        if (cif !== currentBeneficiary?.cif) {
          const isCifAlready = isBeneficiaryAlready(cif, plan, actualSociety);
          if (isCifAlready) {
            dispatch(addAlert(alertBodyTypes.DUPLICATED_CIF));
            return;
          }
        }
        const parsedCategory =
          currentPlan?.hasLaboralCategories && laboralCategory
            ? JSON.parse(laboralCategory)
            : { name: '', percent: 0 };
        const updatedBeneficiary = {
          planStartDate: new Date(date),
          amount,
          sharesCount: { future: shares },
          laboralCategory: {
            name: parsedCategory.name,
            percent: parsedCategory.percent,
          },
          salary,
        };
        if (hasCustomConditions) {
          updatedBeneficiary.customConditions = {
            sharePrice,
            isFixedPrice,
            cliff,
            vestingPeriod,
            consolidation,
            customConsolidation,
            consolidationPeriods,
          };
        }
        await store.dispatch(
          updateBeneficiary({
            id: currentBeneficiary['_id'],
            data: {
              ...updatedBeneficiary,
              userId: user['_id'],
              societyId: actualSociety['_id'],
              cascade: false,
            },
            showAlert: false,
          })
        );
        getActualSociety(user, actualSociety['_id']);
        dispatch(setModal(null));
      } else {
        dispatch(setModal(null));

        dispatch(addAlert(alertBodyTypes.SOCIETY_UPDATED));
      }
    } catch (error) {
      console.log('Error adding beneficiary', error, error?.message);
      dispatch(addAlert(alertBodyTypes.ERROR_ADDING_BENEFICIARY));
    }
  };

  function setFormattedShares(value) {
    const numberValue = Number(value);
    if (numberValue > 0 && currentPlanShares >= numberValue) {
      setShares(numberValue);
    }
    if (value === '') {
      setShares();
    }
  }

  useEffect(() => {
    const selectedPlan = stockPlans.find((p) => p['_id'] === plan);
    if (selectedPlan) {
      if (selectedPlan?.hasInvitation) {
        const generatedCode = uuidv4();
        setInvitationCode(generatedCode);
        setInvitationStatusType(invitationStatus.PENDING.value);
      } else {
        setInvitationStatusType('');
      }
    }
    setCurrentPlan(selectedPlan);
    getAttachedDocument(selectedPlan?.invitationAttachedDocument);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plan]);

  useEffect(() => {
    if (action !== 'watch') {
      if (customConsolidation) {
        if (consolidation !== 0.033) {
          const totalPeriods = Math.ceil(+vestingPeriod / +consolidation);
          const newConsolidationPeriods = Array.from(
            { length: totalPeriods },
            () => 0
          );
          setConsolidationPeriods(newConsolidationPeriods);
        } else {
          setCustomConsolidation(false);
          setConsolidationPeriods([]);
        }
      } else {
        setConsolidationPeriods([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vestingPeriod, consolidation, customConsolidation]);

  useEffect(() => {
    if (currentPlan) {
      const usedShares = getUsedSharesFromPlan(
        currentPlan,
        actualSociety,
        tenderOffers,
        isDraft
      );
      const categories = currentPlan.laboralCategories;
      let availableShares = currentPlan.sharesTotal - usedShares;
      if (action === 'edit' && currentBeneficiary)
        availableShares += currentBeneficiary?.sharesCount?.future;

      setCurrentPlanShares(Math.floor(availableShares)); // round to integer down
      setCurrentPlanLaboralCategories(categories);
      if (action !== 'edit') {
        setSalary('');
        setShares(0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety, currentPlan]);

  useEffect(() => {
    if (laboralCategory && laboralCategory === 'default_option')
      setLaboralCategory({});
    else if (currentPlan && salary && Object.entries(laboralCategory).length) {
      const category = JSON.parse(laboralCategory);
      const cleanSalary = cleanValue(salary);
      const years = currentPlan?.annualUnits
        ? currentPlan?.vestingPeriod / 12
        : 1;
      if (hasCustomConditions && !isFixedPrice && sharePrice) {
        const units = Math.floor(
          (category.percent * cleanSalary) / 100 / sharePrice
        );
        setShares(Math.floor(units * years));
      } else if (!currentPlan?.isFixedPrice) {
        const units = Math.floor(
          (category.percent * cleanSalary) / 100 / currentPlan?.sharePrice
        );
        setShares(Math.floor(units * years));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salary, laboralCategory, currentPlan, isFixedPrice, sharePrice]);

  useEffect(() => {
    let computedAmount = 0;
    if (hasCustomConditions && sharePrice) {
      computedAmount = isFixedPrice ? sharePrice : shares * sharePrice;
    } else {
      computedAmount = currentPlan?.isFixedPrice
        ? currentPlan?.sharePrice
        : shares * currentPlan?.sharePrice;
    }
    setAmount(computedAmount);
  }, [sharePrice, currentPlan, shares, isFixedPrice, hasCustomConditions]);

  useEffect(() => {
    if (action === 'duplicate') {
      setPlan(currentBeneficiary?.plan);
      setDate(
        format(new Date(currentBeneficiary?.planStartDate), 'yyyy-MM-dd')
      );
      setShares(currentBeneficiary?.sharesCount?.future);
    }
    if (action === 'edit') {
      setName(currentBeneficiary?.name);
      setCif(currentBeneficiary?.cif);
      setEmail(currentBeneficiary?.email);
      setJobTitle(currentBeneficiary?.jobTitle || '');
      setPlan(currentBeneficiary?.plan);
      if (currentBeneficiary.invitationDate) {
        setInvitationDate(
          format(new Date(currentBeneficiary?.invitationDate), 'yyyy-MM-dd')
        );
      }
      if (currentBeneficiary?.customConditions) {
        setHasCustomConditions(true);
        setIsFixedPrice(currentBeneficiary?.customConditions?.isFixedPrice);
        setSharePrice(currentBeneficiary?.customConditions?.sharePrice);
        setCliff(currentBeneficiary?.customConditions?.cliff);
        setVestingPeriod(currentBeneficiary?.customConditions?.vestingPeriod);
        setCustomConsolidation(
          currentBeneficiary?.customConditions?.customConsolidation
        );
        setConsolidation(currentBeneficiary?.customConditions?.consolidation);
        setConsolidationPeriods(
          currentBeneficiary?.customConditions?.consolidationPeriods || []
        );
      }
      setDate(
        format(new Date(currentBeneficiary?.planStartDate), 'yyyy-MM-dd')
      );
      setShares(currentBeneficiary?.sharesCount?.future);
      setLaboralCategory(
        JSON.stringify(currentBeneficiary?.laboralCategory) || ''
      );
      setSalary(cleanValue(currentBeneficiary?.salary) || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title">
          {action === 'edit' ? t('EditBeneficiary') : t('AddBeneficiary')}
        </h5>
        <a
          className="close cursor-pointer"
          onClick={() => dispatch(setModal(null))}
        >
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
      <Modal.Body>
        <form className="form-validate is-alter" onSubmit={savePartner}>
          <div className="row mb-4">
            <div className="col">
              <div className="form-group">
                <label className="form-label" htmlFor="beneficiary-name">
                  {t('NameAndSurnameOfTheBenefi')}
                </label>
                <div className="form-control-wrap">
                  <div className="form-text-hint">
                    <span className="overline-title">
                      {t('NameAndSurname')}
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    id="beneficiary-name"
                    name="beneficiary-name"
                    value={name}
                    placeholder={t('NameAndSurname')}
                    onChange={(event) => setName(event.target.value)}
                    required
                    disabled={action === 'edit'}
                  />
                </div>
              </div>

              <div className="form-group">
                <label className="form-label" htmlFor="beneficiary-identity">
                  {t('NifPassportNie')}
                </label>
                <div className="form-control-wrap">
                  <div className="form-text-hint">
                    <span className="overline-title">
                      {t('NifPassportNie')}
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    id="beneficiary-identity"
                    name="beneficiary-identity"
                    value={cif}
                    onChange={(event) => setCif(event.target.value)}
                    placeholder={t('NifNoPassportOrDen')}
                    required
                    disabled={action === 'edit'}
                  />
                </div>
              </div>

              <div className="form-group">
                <label className="form-label" htmlFor="beneficiary-email">
                  {t('BeneficiaryEmail')}
                </label>
                <div className="form-control-wrap">
                  <div className="form-text-hint">
                    <span className="overline-title">{t('Email')}</span>
                  </div>
                  <input
                    type="email"
                    className="form-control"
                    id="beneficiary-email"
                    name="beneficiary-email"
                    placeholder={t('EmailOfTheNewBeneficiary')}
                    value={email}
                    onChange={handleEmailChange}
                    pattern={EMAIL_PATTERN}
                    required
                    disabled={action === 'edit'}
                  />
                </div>
              </div>

              <div className="form-group">
                <label className="form-label" htmlFor="beneficiary-address">
                  {t('Address')}
                </label>
                <div className="form-control-wrap">
                  <div className="form-text-hint">
                    <span className="overline-title">{t('Address')}</span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    id="beneficiary-address"
                    name="beneficiary-address"
                    value={addressLine}
                    placeholder={`${t('Address')} (${t('Optional')})`}
                    onChange={(event) => setAddressLine(event.target.value)}
                    disabled={action === 'edit'}
                  />
                </div>
              </div>

              <div className="form-group">
                <label className="form-label" htmlFor="beneficiary-job">
                  {t('JobTitle')}
                </label>
                <div className="form-control-wrap">
                  <div className="form-text-hint">
                    <span className="overline-title">{t('JobTitle')}</span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    id="beneficiary-job"
                    name="beneficiary-job"
                    placeholder={t('JobTitleOptional')}
                    value={jobTitle}
                    onChange={(event) => setJobTitle(event.target.value)}
                    disabled={action === 'edit'}
                  />
                </div>
              </div>

              <div className="form-group">
                <label className="form-label" htmlFor="beneficiary-plan">
                  {t('StockOptionsPlan')}
                  <span className="sub-text">
                    {t('SelectTheStockOptionsPlanInclude')}
                  </span>
                </label>
                <div className="form-control-wrap ">
                  <div className="form-control-select">
                    <select
                      className="form-control"
                      id="beneficiary-plan"
                      name="beneficiary-plan"
                      value={plan}
                      onChange={(event) => setPlan(event.target.value)}
                      disabled={action === 'edit'}
                    >
                      <option value="default_option">{t('Select')}</option>
                      {!isDraft &&
                        stockPlans?.length &&
                        stockPlans
                          ?.filter(
                            (plan) =>
                              !plan?.isDraft &&
                              plan.society === actualSociety['_id']
                          )
                          .map((plan) => (
                            <option value={plan['_id']} key={plan['_id']}>
                              {plan.name}
                            </option>
                          ))}
                      {isDraft &&
                        stockPlans?.length &&
                        stockPlans
                          ?.filter(
                            (plan) =>
                              plan?.isDraft &&
                              plan.society === actualSociety['_id']
                          )
                          .map((plan) => (
                            <option value={plan['_id']} key={plan['_id']}>
                              {plan.name}
                            </option>
                          ))}
                    </select>
                  </div>
                  <div className="row col-md-12">
                    <div className="col-md-8 px-0">
                      <label className="form-label" htmlFor="beneficiary-plan">
                        {t('Conditions')}:
                      </label>
                      {currentPlan && (
                        <span className="ml-1">
                          {t('Vesting')}:{' '}
                          <span className="text-primary">
                            {parseVestingMonths(currentPlan?.vestingPeriod)}
                          </span>{' '}
                          | {t('Cliff')}:{' '}
                          <span className="text-primary">
                            {parseCliffMonths(currentPlan?.cliff)}
                          </span>
                        </span>
                      )}
                    </div>
                    <div className="col-md-4 px-0">
                      <div className="custom-control custom-control-xs custom-checkbox mt-1">
                        <input
                          type="checkbox"
                          id="checkbox-conditions"
                          className="custom-control-input"
                          value={hasCustomConditions}
                          checked={hasCustomConditions}
                          disabled={action === 'watch'}
                          onChange={(event) =>
                            setHasCustomConditions(event.target.checked)
                          }
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="checkbox-conditions"
                        >
                          {t('EditConditionsForBenef')}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {hasCustomConditions ? (
                <div className="card card-bordered mb-2">
                  <div className="card-inner">
                    <div className="form-group">
                      {isFixedPrice ? (
                        <label className="form-label" htmlFor="pay-amount">
                          {t('FixedPriceExercisePrice')}
                        </label>
                      ) : (
                        <label className="form-label" htmlFor="pay-amount">
                          {t('PricePerUnitExercisePrice')}
                        </label>
                      )}
                      <div className="form-control-wrap">
                        <div className="form-text-hint">
                          <span className="overline-title">Eur</span>
                        </div>
                        <input
                          type="number"
                          className="form-control"
                          value={sharePrice}
                          onChange={(event) =>
                            setSharePrice(+event.target.value)
                          }
                          disabled={action === 'watch'}
                          onWheel={(event) => event.target.blur()}
                          min="0"
                          step="0.000000001"
                          placeholder={
                            isFixedPrice
                              ? t('FixedPriceToPay')
                              : t('PriceToPayForEachUnit')
                          }
                        />
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-12">
                          <div className="custom-control-sm custom-switch float-right d-flex align-content-center">
                            <span
                              style={{
                                marginRight: '45px',
                                fontSize: '12px',
                                lineHeight: '1.125rem',
                                paddingTop: '0',
                              }}
                            >
                              {t('FixedPrice')}
                            </span>
                            <input
                              type="checkbox"
                              id="price-switch"
                              className="custom-control-input float-right"
                              checked={!isFixedPrice}
                              disabled={action === 'watch'}
                              onChange={(event) =>
                                setIsFixedPrice(!event.target.checked)
                              }
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="price-switch"
                            >
                              {t('PriceByUnit')}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="form-label" htmlFor="default-06">
                        {t('Cliff')}
                      </label>
                      <span className="sub-text mb-3">
                        {t('MinimumPermanenceTimeThat')}:
                      </span>
                      <div className="form-control-wrap">
                        <div className="form-control-select">
                          <select
                            className="form-control"
                            value={cliff}
                            disabled={action === 'watch'}
                            onChange={(event) => setCliff(+event.target.value)}
                          >
                            {cliffOptions.map((cliff) => (
                              <option
                                value={cliff.value}
                                key={JSON.stringify(cliff)}
                              >
                                {cliff.text}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="form-label" htmlFor="default-01">
                        {t('VestingConsolidationPeriod')}
                      </label>
                      <span className="sub-text mb-3">
                        {t('TheBeneficiariesWillBeEnt')}:
                      </span>
                      <div className="form-control-wrap ">
                        <div className="form-control-select">
                          <select
                            className="form-control"
                            value={vestingPeriod}
                            disabled={action === 'watch'}
                            onChange={(event) =>
                              setVestingPeriod(+event.target.value)
                            }
                          >
                            <option value="default-option">
                              {t('Select')}
                            </option>
                            {vestingOptions.map((vesting) => (
                              <option
                                value={+vesting.value}
                                key={JSON.stringify(vesting)}
                              >
                                {vesting.text}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="form-label" htmlFor="default-01">
                        {t('ConsolidationSpeed')}
                      </label>
                      <span className="sub-text mb-3">
                        {t('PeriodOfTimeItTakesToCons')}
                      </span>
                      <div className="form-control-wrap ">
                        <div className="form-control-select">
                          <select
                            className="form-control"
                            value={+consolidation}
                            disabled={action === 'watch'}
                            onChange={(event) =>
                              setConsolidation(+event.target.value)
                            }
                          >
                            <option value="default-option">
                              {t('Select')}
                            </option>
                            {consolidationOptions.map((option) => (
                              <option
                                value={+option.value}
                                key={JSON.stringify(option)}
                                disabled={option?.value > vestingPeriod}
                              >
                                {option.text}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="custom-control custom-control-xs custom-checkbox mt-2">
                        <input
                          type="checkbox"
                          id="checkbox-consolidation-period"
                          className="custom-control-input"
                          value={customConsolidation}
                          selected={customConsolidation}
                          disabled={
                            !vestingPeriod ||
                            vestingPeriod === 'default-option' ||
                            !consolidation ||
                            consolidation === 0.033 ||
                            +vestingPeriod < +consolidation ||
                            action === 'watch'
                          }
                          onChange={(event) =>
                            setCustomConsolidation(event.target.checked)
                          }
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="checkbox-consolidation-period"
                        >
                          {t('CustomizeTheDifferentCons')}
                        </label>
                      </div>
                    </div>

                    {customConsolidation && (
                      <div className="card card-bordered card-preview mb-3">
                        <div className="card-inner">
                          {consolidationPeriods.map((period, index) => (
                            <div className="form-group">
                              <label
                                className="form-label"
                                htmlFor="default-01"
                              >
                                {t('Section')} {index + 1}
                              </label>
                              <div className="form-control-wrap">
                                <input
                                  type="number"
                                  className="form-control"
                                  value={period}
                                  disabled={action === 'watch'}
                                  onChange={(event) =>
                                    handleConsolidationPeriodsChange(
                                      index,
                                      event.target.value
                                    )
                                  }
                                  onWheel={(event) => event.target.blur()}
                                  min="0"
                                  step="0.000000001"
                                  placeholder="%"
                                  required
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <></>
              )}

              {currentPlan?.hasLaboralCategories && (
                <div className="row">
                  <div className="form-group col-6">
                    <label className="form-label" htmlFor="laboral-category">
                      {t('LaboralCategory')}
                    </label>
                    <div className="form-control-wrap ">
                      <div className="form-control-select">
                        <select
                          className="form-control"
                          id="laboral-category"
                          name="laboral-category"
                          value={laboralCategory}
                          onChange={(event) =>
                            setLaboralCategory(event.target.value)
                          }
                        >
                          <option value="default_option">{t('Select')}</option>
                          {currentPlanLaboralCategories?.length > 0 &&
                            currentPlanLaboralCategories.map((category) => (
                              <option
                                value={JSON.stringify({
                                  name: category.name,
                                  percent: category.percent,
                                })}
                                key={category['_id']}
                              >
                                {category.name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-6">
                    <label className="form-label" htmlFor="user-salary">
                      {t('AnnualSalary')}
                    </label>
                    <div className="form-control-wrap ">
                      <div className="form-text-hint">
                        <span className="overline-title">EUR</span>
                      </div>
                      <NumberFormat
                        id="outlined-normal"
                        className="form-control form-control-outlined"
                        value={salary}
                        onChange={(e) => setSalary(e.target.value)}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...numberFormat}
                      />
                    </div>
                  </div>
                </div>
              )}

              <div className="form-group">
                <label className="form-label" htmlFor="beneficiary-plan-date">
                  {t('DateOfConcessionOfTheBene')}
                </label>
                <div className="form-control-wrap">
                  <input
                    id="beneficiary-plan-date"
                    name="beneficiary-plan-date"
                    type="date"
                    className="form-control date-picker"
                    value={date}
                    onChange={(event) => setDate(event.target.value)}
                    required
                    max="2100-01-01T00:00"
                  />
                </div>
              </div>

              <div className="form-group">
                <label className="form-label" htmlFor="beneficiary-plan-shares">
                  {t('UnitsGrantedToTheBenefici')}
                </label>
                <div className="form-control-wrap">
                  <div className="form-text-hint">
                    {plan && (
                      <span className="overline-title">
                        /{currentPlanShares}
                      </span>
                    )}
                  </div>
                  <input
                    type="number"
                    className="form-control"
                    id="beneficiary-plan-shares"
                    name="beneficiary-plan-shares"
                    value={shares}
                    disabled={
                      !currentPlanShares ||
                      (salary && Object.entries(laboralCategory).length)
                    }
                    placeholder={t('TotalShares')}
                    onChange={(event) => setFormattedShares(event.target.value)}
                    onWheel={(event) => event.target.blur()}
                    min="0"
                  />
                </div>
                <label className="form-label" htmlFor="beneficiary-plan-shares">
                  {currentPlan?.isFixedPrice
                    ? `(*) ${t('FixedPrice')}:`
                    : `(*) ${t('UnitPrice')}:`}
                  &nbsp;
                </label>
                <span className="text-primary">
                  <NumberFormat
                    value={
                      hasCustomConditions && sharePrice
                        ? sharePrice
                        : currentPlan?.sharePrice || ''
                    }
                    displayType="text"
                    {...currencyFormatDecimals}
                  />
                </span>
                <label className="form-label" htmlFor="beneficiary-plan-shares">
                  &nbsp;| {t('TotalToPay')}:&nbsp;
                </label>
                <span className="text-primary">
                  <NumberFormat
                    value={amount}
                    displayType="text"
                    {...currencyFormatDecimals}
                  />
                </span>
              </div>

              {currentPlan?.hasInvitation && (
                <div className="form-group">
                  <label className="form-label" htmlFor="beneficiary-inv-date">
                    {t('InvitationDate')}
                  </label>
                  <div className="form-control-wrap">
                    <input
                      id="beneficiary-inv-date"
                      name="beneficiary-inv-date"
                      type="date"
                      className="form-control date-picker"
                      value={invitationDate}
                      onChange={(event) =>
                        setInvitationDate(event.target.value)
                      }
                      max="2100-01-01T00:00"
                    />
                  </div>
                </div>
              )}

              {action !== 'edit' && (
                <div className="form-group">
                  <label className="form-label" htmlFor="beneficiary-plan-file">
                    {t('AddDocument')}
                  </label>
                  <div className="form-control-wrap">
                    <div className="custom-file">
                      <input
                        id="beneficiary-plan-file"
                        name="beneficiary-plan-file"
                        type="file"
                        accept="application/pdf"
                        className="custom-file-input"
                        onChange={(event) => setFile(event.target.files[0])}
                      />
                      <label className="custom-file-label" htmlFor="customFile">
                        {file?.name || t('SelectDocument')}
                      </label>
                    </div>
                  </div>
                </div>
              )}

              <span className="sub-text text-dark">
                {t('ThisPriceIsSubjectToThePlanAndLetter')}
              </span>
            </div>
          </div>

          <button
            type="submit"
            className="btn btn-lg btn-primary"
            disabled={!name || !cif || !email || !plan || !date || !shares}
          >
            {action === 'edit' ? t('Save') : t('AddBeneficiary')}
          </button>
        </form>
      </Modal.Body>
      <Modal.Footer className="bg-light">
        <span className="sub-text">{t('TheBeneficiaryWillReceive')}</span>
      </Modal.Footer>
    </>
  );
}

export default AddBeneficiaryModal;
