import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setModal } from 'redux/actions/modalActions';
import { updateSociety } from 'redux/actions/societyActions';
import store from 'redux/store';

function MinuteBookModal({ societyId }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { actualSociety } = useSelector((state) => ({
    actualSociety: state.society?.actualSociety,
  }));
  const [bookName, setBookName] = useState('');

  async function saveBook(event) {
    event.preventDefault();
    const newMinuteBook = {
      label: bookName,
    };
    const newBookData = actualSociety?.minutebookFolders
      ? [...actualSociety.minutebookFolders, newMinuteBook]
      : [newMinuteBook];
    const newSocietyData = {
      minutebookFolders: newBookData,
    };

    dispatch(updateSociety(societyId, newSocietyData));

    dispatch(setModal(null));
  }

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title">{t('CreateMinuteBook')}</h5>
        <a className="close" onClick={() => store.dispatch(setModal(null))}>
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
      <Modal.Body>
        <form className="form-validate is-alter" onSubmit={saveBook}>
          <div className="form-group">
            <label className="form-label" htmlFor="full-name">
              {t('Name')}
            </label>
            <div className="form-control-wrap">
              <input
                type="text"
                className="form-control"
                value={bookName}
                onChange={(event) => setBookName(event.target.value)}
              />
            </div>
          </div>

          <button
            type="submit"
            className="btn btn-lg btn-primary"
            disabled={!bookName}
          >
            {t('Save')}
          </button>
        </form>
      </Modal.Body>
    </>
  );
}

export default connect()(MinuteBookModal);
