import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getDocumentsByReference } from 'redux/actions/documentActions';
import { getInvestors } from 'utils/filters';

import InvestorTransactionsRow from './InvestorTransactionsRow';

function InvestorTransactions({ society, investor }) {
  const { t } = useTranslation();
  const { drafts } = useSelector((state) => ({
    drafts: state.drafts,
  }));

  const [investors] = useState(getInvestors(investor, society));
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (drafts?.length) {
      const dataRows = investors.map((currentInvestor, index) => {
        const draftName =
          drafts?.find((draft) => draft['_id'] === currentInvestor.draft)
            ?.name || '-';
        const dataInvestor = { ...currentInvestor, draftName };
        return (
          <InvestorTransactionsRow
            investor={dataInvestor}
            index={index + 1}
            key={currentInvestor?.['_id']}
            society={society}
          />
        );
      });
      setRows(dataRows);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drafts, investors]);

  return (
    <div className="card-inner card-inner-lg">
      <div className="nk-block-head">
        <div className="nk-block-head-content">
          <h4 className="nk-block-title">{t('Investments')}</h4>
          <div className="nk-block-des">
            <p>
              {t('InvestmentInSocietyName', {
                societyName: society.name,
                partnerName: investor.name || '',
              })}
            </p>
          </div>
        </div>
      </div>
      {rows.length ? (
        <div className="nk-block nk-block-lg">
          <div className="card card-bordered card-preview">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">{t('Nr')}</th>
                  <th scope="col">{t('Contribution')}</th>
                  <th scope="col">{t('ContributionDate')}</th>
                  <th scope="col" className="d-none d-xl-table-cell">
                    {t('NrPart.')}
                  </th>
                  <th scope="col">{t('DraftConvertibleNote')}</th>             
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </table>
          </div>
        </div>
      ) : (
        <p>
          {t('ItStillDoesNotHaveInvestments', {
            partnerName: investor.name || '',
            societyName: society.name,
          })}
        </p>
      )}
    </div>
  );
}

export default InvestorTransactions;
