import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';

import store from 'redux/store';
import { setModal } from 'redux/actions/modalActions';
import { updateBoard, getBoards } from 'redux/actions/boardActions';
import { sendCommEmail } from 'redux/actions/communicationActions';

import { getPartnerActualShares } from 'utils/filters';

import { formatDateToTime } from 'constants/formats';
import attendanceTypes from 'constants/attendanceTypes';
import boardTypes from 'constants/boardTypes';
import boardStatus from 'constants/boardStatus';

import CustomLoading from 'components/CustomLoading';
import LogoSttokBase64 from 'components/Logos/LogoSttokBase64';

import hasShowDetails from 'utils/showDetails';
import { Participant, Board, Society } from 'types';

type SendReminderModalProps = {
  currentBoard: Board;
  actualSociety: Society;
};

const SendReminderModal: FC<SendReminderModalProps> = ({
  currentBoard,
  actualSociety,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const user: any = useSelector((state: any) => state.user);

  const [date, setDate] = useState<string>();
  const [time, setTime] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [allParticipants, setAllParticipants] = useState<boolean>(false);

  const isParticipantAllowedForReminder = (participant: Participant) => {
    const participantShares = getPartnerActualShares(
      participant.member,
      actualSociety
    );

    if (participantShares === 0) return false;

    // If all participants are selected and have shares, return true
    if (allParticipants) return true;

    return (
      (participant?.isRepresented && !participant.hasDelegated) ||
      (!participant?.assists &&
        !participant?.isAbsent &&
        !participant?.hasDelegated)
    );
  };

  const handleSendReminder = async (): Promise<void> => {
    try {
      setIsLoading(true);
      const activePartner =
        currentBoard?.participants.filter((participant) =>
          isParticipantAllowedForReminder(participant)
        ) || [];

      const recipients = activePartner.map((partner) => ({
        email: partner.email,
        name: partner.name,
      }));

      const recipientsForDynamicData = activePartner.map((recipient) => {
        const { member, email, name } = recipient;
        return {
          id: member,
          email: email ? email.trim() : '',
          name,
        };
      });
      const data = {
        societyName: actualSociety?.name || '-',
        boardName: currentBoard?.name || '',
        date,
        time,
        place: currentBoard?.place || '-',
        onlineAccess: currentBoard?.onlineAccess || '-',
        boardType: boardTypes?.[currentBoard?.boardType]?.text || '-',
      };

      const emailData = {
        to: recipients,
        cc: '',
        bcc: '',
        files: [],
        subject: t('BoardReminder'),
        bodySummary: t('ApreciatedPartner'),
        template: 'board-reminder-es',
        templateBody: `<div class="main" style="padding: 24px">
                <div style="
                    background: #f5f6fa;
                    font-family: Roboto;
                    font-size: 14px;
                    line-height: 22px;
                    font-weight: 400;
                    color: #8094ae;
                    width: 100%;
                    text-align: center;
                  ">
                  <a href="https://www.sttok.com">
                    <img src="${LogoSttokBase64}" alt="logo" style="height: 40px; margin-top: 2.75rem"/>
                  </a>
                  <p style="
                      font-size: 13px;
                      color: #6576ff;
                      padding-top: 6px;
                      margin-top: 0;
                      padding-bottom: 24px;
                    ">
                    Gestión de Socios
                  </p>
                  <div class="email-body" style="
                      width: 96%;
                      max-width: 620px;
                      text-align: left;
                      margin: 0 auto;
                      padding: 1.75rem 2.75rem;
                      background: #ffffff;
                    ">
                    <h2 style="
                      font-family: Nunito, sans-serif;
                      font-size: 18px;
                      color: #6576ff;
                      font-weight: 600;
                      margin: 0;
                      line-height: 1.4;
                      margin: 10px 0;
                      padding-bottom: 15px;
                      text-align: left;
                      ">
                        Convocatoria Junta de ${data.societyName}
                    </h2>
                    <p style="text-align: left;">Apreciado socio, te recordamos que ${data.societyName} celebrará su junta en el día y hora indicados a continuación.</p>
                    <p style="text-align: left;">Es muy importante tu asistencia. Si no puedes asistir, delega tu voto fácilmente mediante el botón "Delegar mi voto", que aparece en este correo. Para acceder utiliza este correo</p>
                    <h2 style="
                      font-size: 18px;
                      text-align: left;
                      margin-bottom: 0.5rem;
                      font-family: Nunito, sans-serif;
                      font-weight: 700;
                      line-height: 1.1;
                      color: #364a63;
                    ">
                        ${data.boardName}
                    </h2>
                    <table style="width:100%;">
                        <tr>
                            <td style="text-align: left; padding-bottom: 10px;">
                              <span>Fecha: ${data.date}</span>
                            </td>
                            <td style="text-align: left; padding-bottom: 10px;">
                              <span>Hora: ${data.time}</span>
                            </td>
                        </tr>
                        <tr>
                            <td style="text-align: left;">
                              <span>Tipo Junta: ${data.boardType}</span>
                            </td>
                            <td style="text-align: left;">
                              <span>Videollamada: ${data.onlineAccess}</span>
                            </td>
                        </tr>
                      </table>
                      <p style="text-align: left; margin-top: 10px;">Lugar de celebración: ${data.place}</p>
                      <h2 style="
                      font-size: 18px;
                      text-align: left;
                      margin-bottom: 0.5rem;
                      font-family: Nunito, sans-serif;
                      font-weight: 700;
                      line-height: 1.1;
                      color: #364a63;
                      ">
                        Delegación de voto
                    </h2>
                    <p style="text-align: left;">Si no puedes asistir a la Junta, puedes delegar tu voto aquí:</p>
                    <span style="
                        background: rgb(101, 118, 255);
                        border-radius: 4px;
                        border: none;
                        color: white;
                        display: inline-block;
                        font-size: 13px;
                        font-weight: 600;
                        line-height: 38px;
                        text-align: center;
                        text-decoration: none;
                        text-transform: uppercase;
                        margin: 0 auto 30px;
                        padding: 0 30px;
                        width: fit-content;
                      ">
                        DELEGAR MI VOTO
                    </span>
                    <br/>
                    <h2 style="
                      font-size: 18px;
                      text-align: left;
                      margin-bottom: 0.5rem;
                      font-family: Nunito, sans-serif;
                      font-weight: 700;
                      line-height: 1.1;
                      color: #364a63;
                      ">
                        Acceso a la Junta y votación
                    </h2>
                    <p style="text-align: left;">Puedes acceder a los detalles y documentación de la Junta:</p>
                    <span style="
                        background: rgb(101, 118, 255);
                        border-radius: 4px;
                        border: none;
                        color: white;
                        display: inline-block;
                        font-size: 13px;
                        font-weight: 600;
                        line-height: 38px;
                        text-align: center;
                        text-decoration: none;
                        text-transform: uppercase;
                        margin: 0 auto 30px;
                        padding: 0 30px;
                        width: fit-content;
                      ">
                        ACCEDER A LA JUNTA
                      </span>
                      <br/>
                      <p style="text-align: justify">
                          El proceso está gestionado a través de la plataforma Sttok desde la cual podrás acceder a la convocatoria, poder revisar la documentación y votar.
                      </p>
                    </div>
                    <p style="padding-bottom: 2.75rem; padding-top: 2.75rem; margin: 0">
                      Correo enviado por
                        <a style="color: #6576ff; text-decoration: none" href="https://sttok.com/">sttok.com</a> Puede contactar en <a href="mailto:info@sttok.com" target="_blank">info@sttok.com
                        </a>
                    </p>
                </div>
              </div>`,
        templateData: [
          {
            name: 'societyName',
            content: data.societyName,
          },
          {
            name: 'boardName',
            content: data.boardName,
          },
          {
            name: 'boardDate',
            content: data.date,
          },
          {
            name: 'boardTime',
            content: data.time,
          },
          {
            name: 'onlineAccess',
            content: data.onlineAccess,
          },
          {
            name: 'boardPlace',
            content: data.place,
          },
          {
            name: 'boardType',
            content: data.boardType,
          },
        ],
        templateDynamicData: recipientsForDynamicData.map(
          ({ id, name, email }) => ({
            rcpt: email,
            vars: [
              { name: 'name', content: name },
              { name: 'partnerEmail', content: email },
              {
                name: 'delegationUrl',
                content: `${process.env.REACT_APP_DOMAIN}?redirect=delegacion/${actualSociety['_id']}/${currentBoard?.['_id']}/${id}`,
              },
              {
                name: 'boardUrl',
                content: `${process.env.REACT_APP_DOMAIN}?redirect=voto-junta/${actualSociety['_id']}/${currentBoard?.['_id']}/${id}`,
              },
            ],
          })
        ),
        sender: {
          name: `Sttok | ${actualSociety?.name}`,
          email: 'info@sttok.com',
        },
        user: user['_id'],
        society: actualSociety['_id'],
        date: new Date(),
        iCal: {
          societyEmail: user.email,
          societyName: data.societyName,
          boardName: data.boardName,
          boardDate: currentBoard.date,
          boardLink: data.onlineAccess,
        },
      };

      const communicationId = await store.dispatch(sendCommEmail(emailData));
      dispatch(
        updateBoard(
          currentBoard['_id'],
          {
            announcementComm: communicationId,
            status: boardStatus.ACTIVE.value,
          },
          false
        )
      );
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (currentBoard) {
      setTime(currentBoard?.date ? formatDateToTime(currentBoard?.date) : '');
      setDate(
        currentBoard?.date
          ? format(new Date(currentBoard?.date), 'dd/MM/yyyy')
          : ''
      );
    }
  }, [currentBoard]);

  return (
    <>
      <Modal.Body>
        <div className="modal-body modal-body-lg text-center">
          <div className="nk-modal">
            <button
              type="button"
              className="close"
              onClick={() => store.dispatch(setModal(null))}
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-send bg-primary-alt" />
            <h4 className="nk-modal-title">{t('SendReminder')}</h4>
            <div className="nk-modal-text">
              <p
                className="lead"
                dangerouslySetInnerHTML={{
                  __html: t('SendReminderMessage', {
                    societyName: actualSociety.name,
                    date,
                    time,
                    attendanceType:
                      attendanceTypes[currentBoard.attendanceType].text,
                  }),
                }}
              />
            </div>
            <div className="text-left">
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  className="custom-control-input"
                  name="all-participants"
                  id="all-participants"
                  checked={allParticipants}
                  onChange={() => setAllParticipants(!allParticipants)}
                />
                <label
                  className="custom-control-label"
                  htmlFor="all-participants"
                >
                  {t('ReminderAllCheckboxMessage')}
                </label>
              </div>
            </div>
            <div className="text-left mt-1">
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  className="custom-control-input"
                  name="not-all-participants"
                  id="not-all-participants"
                  checked={!allParticipants}
                  onChange={() => setAllParticipants(!allParticipants)}
                />
                <label
                  className="custom-control-label"
                  htmlFor="not-all-participants"
                >
                  {t('ReminderNotAllCheckboxMessage')}
                </label>
              </div>
            </div>
            <button
              type="button"
              className="btn btn-primary mt-2"
              onClick={() => handleSendReminder()}
            >
              {t('SendNow')}
            </button>
          </div>
        </div>
      </Modal.Body>
      {isLoading && <CustomLoading />}
    </>
  );
};

export default SendReminderModal;
