/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import store from 'redux/store';
import { setModal } from 'redux/actions/modalActions';
import { addDocument } from 'redux/actions/documentActions';
import { updateShare } from 'redux/actions/sharesActions';

import fileTypes from 'constants/fileTypes';
import documentTypes from 'constants/documentTypes';

import ShareTagView from './ShareTagView';
import ShareEncumbranceView from './ShareEncumbranceView';

const UpdateShares = ({ share, society }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);

  const [page, setPage] = useState(0);

  const [pages, setPages] = useState([]);

  const [tag, setTag] = useState(share?.tag || '');

  const [encumbrances, setEncumbrances] = useState(share?.encumbrances || []);

  const [encumbrancesUpdated, setEncumbrancesUpdated] = useState(false);
  const [tagUpdated, setTagUpdated] = useState(false);

  const isUpdateButtonDisabled = () =>
    (!encumbrancesUpdated && page === 0) || (!tagUpdated && page === 1);

  const addEncumbrancesDocumnets = async (encumbrances) => {
    const result = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const encumbrance of encumbrances) {
      const currentEncumbrance = {
        name: encumbrance.name,
        date: encumbrance.date,
        from: encumbrance.from,
        to: encumbrance.to,
        scriptureData: encumbrance.scriptureData,
        scriptureDocument: encumbrance.scriptureDocument,
      };
      if (encumbrance?.file) {
        let uploadedDocument = null;
        const encumbranceFile = encumbrance.file;
        const newDocument = {
          file: encumbranceFile,
          date: new Date(),
          size: encumbranceFile.size,
          name: encumbranceFile.name,
          author: user['_id'],
          society: society['_id'],
          fileType: fileTypes[encumbranceFile.type],
          category: documentTypes.SCRIPTURES,
          description: t('DocumentLinkedToEncumbrance'),
        };
        // eslint-disable-next-line no-await-in-loop
        uploadedDocument = await store.dispatch(addDocument(newDocument));

        if (uploadedDocument) {
          result.push({
            ...currentEncumbrance,
            scriptureDocument: uploadedDocument['_id'],
          });
        }
      } else {
        result.push({
          ...currentEncumbrance,
        });
      }
    }
    return result;
  };

  const handleButtonClick = async () => {
    try {
      const newShare = {
        ...share,
      };
      if (tagUpdated) {
        newShare.tag = tag;
      }
      if (encumbrancesUpdated) {
        const newEncumbrances = await addEncumbrancesDocumnets(encumbrances);
        newShare.encumbrances = newEncumbrances;
      }

      await store.dispatch(updateShare(newShare, society));

      setEncumbrancesUpdated(false);
      setTagUpdated(false);
      dispatch(setModal(null));
    } catch (error) {
      throw new Error(error);
    }
  };

  useEffect(() => {
    const viewPages = [
      <ShareEncumbranceView
        share={share}
        encumbrances={encumbrances}
        setEncumbrances={setEncumbrances}
        setEncumbrancesUpdated={setEncumbrancesUpdated}
      />,
      <ShareTagView
        share={share}
        tag={tag}
        setTag={setTag}
        setTagUpdated={setTagUpdated}
      />,
    ];

    setPages(viewPages);
  }, [encumbrances, share, tag]);

  return (
    <>
      <Modal.Header>
        <h5 className="mb-0">{t('UpdateShare')}</h5>
        <a
          className="close cursor-pointer"
          onClick={() => dispatch(setModal(null))}
        >
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>

      <Modal.Body style={{ minHeight: '500px', overflowY: 'auto' }}>
        <div className="nk-modal gy-4">
          <ul className="nav nav-tabs nav-tabs-mb-icon nav-tabs-card">
            <li className="nav-item">
              <p
                id="share-encumbrance-tab"
                className={`nav-link ${page === 0 ? 'active' : ''}`}
                onClick={() => setPage(0)}
              >
                <em />
                <span>{t('ShareEncumbrance')}</span>
              </p>
            </li>
            <li className="nav-item">
              <p
                id="share-tag-tab"
                className={`nav-link ${page === 1 ? 'active' : ''}`}
                onClick={() => setPage(1)}
              >
                <em />
                <span>{t('ShareTag')}</span>
              </p>
            </li>
          </ul>
          {pages[page]}
        </div>
      </Modal.Body>

      <Modal.Footer className="bg-light">
        <div className="nk-modal-action float-left">
          <span className="sub-text">
            {t('ModalVisibleForSuperAdminsOnly')}
          </span>
        </div>
        <div className="nk-modal-action">
          <button
            type="button"
            className="btn btn-lg btn-block btn-primary"
            onClick={handleButtonClick}
            disabled={isUpdateButtonDisabled()}
          >
            {t('Update')}
          </button>
        </div>
      </Modal.Footer>
    </>
  );
};

export default UpdateShares;
