/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { Tooltip } from 'react-tooltip';

import { getBeneficiaryFDPercent, getDecimalScale } from 'utils/filters';

import { cleanValue, currencyFormatDecimals } from 'constants/formats';

import BeneficiarySocietyValueSimulator from 'components/BeneficiarySocietyValueSimulator';
import getSimulatorValues from 'components/BeneficiarySocietyValueSimulator/utils/getSimulatorValues';
import BeneficiarySell from 'components/Modals/BeneficiarySell';
import sizes from 'constants/sizes';
import { useDispatch } from 'react-redux';
import { setModal } from 'redux/actions/modalActions';

function BeneficiaryAside({
  beneficiary,
  society,
  setSimulatorValue,
  beneficiaryAllPlans,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { societyValue } = beneficiary;
  const [decimalScale] = useState(
    societyValue ? getDecimalScale(societyValue.value) : 2
  );
  const [FDpercent] = useState(
    getBeneficiaryFDPercent(beneficiary, society).toFixed(decimalScale)
  );
  const [incentiveValue] = useState(
    societyValue ? (+FDpercent * societyValue.value) / 100 : 0
  );

  const [valuation, setValuation] = useState();
  const [summary, setSummary] = useState({});
  const [currentVestedValue, setCurrentVestedValue] = useState(0.0);
  const [currentVestedDate, setCurrentVestedDate] = useState('???');

  const calculateValues = () => {
    const {
      currentVestedValue: calculatedVestedValue,
      currentVestedDate: calculatedVestedDate,
      incentiveValue: calculatedIncentiveValue,
      exercisePrice: calculatedExercisePrice,
      FDpercent: calculatedFDpercent,
      summary: calculatedSummary,
    } = getSimulatorValues({ society, societyValue, beneficiaryAllPlans });

    setCurrentVestedValue(calculatedVestedValue);
    setCurrentVestedDate(calculatedVestedDate);
    setSummary(calculatedSummary);

    return {
      calculatedIncentiveValue,
      calculatedExercisePrice,
      calculatedFDpercent,
    };
  };

  const handleChangeSlider = (value) => {
    const { FDpercent: currentFD, exercisePrice } = getSimulatorValues({
      society,
      societyValue,
      beneficiaryAllPlans,
    });

    const newValue = cleanValue(value[0]);
    const valuation = (currentFD * newValue) / 100;
    const higherValue = Math.max(valuation, exercisePrice);

    setSimulatorValue(newValue);
    setValuation(higherValue);
  };

  const handleOpenSellView = () => {
    dispatch(
      setModal(
        // @ts-ignore
        <BeneficiarySell
          size={sizes.XL}
          society={society}
          beneficiary={beneficiary}
          beneficiaryAllPlans={beneficiaryAllPlans}
          setSimulatorValue={setSimulatorValue}
          societyValue={societyValue}
        />
      )
    );
  };

  useEffect(() => {
    if (beneficiaryAllPlans.length) {
      calculateValues();
      const { incentiveValue } = getSimulatorValues({
        society,
        societyValue,
        beneficiaryAllPlans,
      });
      setValuation(incentiveValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="card-inner">
        <div className="user-card user-card-s2">
          <div className="user-avatar lg bg-primary">
            {beneficiary.image ? (
              <img src={beneficiary.image} alt={beneficiary.name || ''} />
            ) : (
              <span>{beneficiary?.name?.slice(0, 2)?.toUpperCase()}</span>
            )}
          </div>
          <div className="user-info">
            <div className="badge badge-outline-primary badge-pill text-primary ucap">
              {t('Beneficiary')}
            </div>
            <h5>{beneficiary.name || ''}</h5>
            <span className="sub-text">{beneficiary.email}</span>
          </div>
        </div>
      </div>

      <div className="card-inner">
        <div className="overline-title-alt mb-2 text-center">
          {incentiveValue ? t('TotalIncentiveValue') : t('ExercisePrice')}
        </div>
        <div className="profile-balance">
          <div className="profile-balance-group">
            <div className="profile-balance-sub mx-auto">
              <div className="profile-balance-amount">
                <div
                  className="number w-100 d-flex justify-content-center align-items-start"
                  style={{ textAlign: 'center' }}
                >
                  <NumberFormat
                    value={
                      summary?.incentiveValue ||
                      beneficiary.amount -
                        (beneficiary?.soldUnits || 0) *
                          beneficiary?.planData?.sharePrice ||
                      0
                    }
                    displayType="text"
                    {...currencyFormatDecimals}
                  />

                  <em
                    className="icon ni ni-info notification-icon ml-1 text-primary"
                    id="icon-incentive-value-message"
                  />
                </div>
              </div>
              <div className="profile-balance-subtitle text-center">
                {incentiveValue
                  ? t('TotalValueGrantedUnits')
                  : t('ExercisePrice')}
              </div>
              {incentiveValue && (
                <Tooltip
                  anchorId="icon-incentive-value-message"
                  place="bottom"
                  style={{ maxWidth: '300px' }}
                >
                  {t('IncentiveValueDetails', {
                    amount: summary?.exercisePrice || '',
                  })}
                </Tooltip>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="card-inner">
        <div className="overline-title-alt mb-2 text-center">
          {t('ActualVestedValue')}
        </div>
        <div className="profile-balance">
          <div className="profile-balance-group">
            <div className="profile-balance-sub mx-auto">
              <div className="profile-balance-amount">
                <div
                  className="number text-teal"
                  style={{ textAlign: 'center' }}
                >
                  <NumberFormat
                    value={currentVestedValue || 0}
                    displayType="text"
                    {...currencyFormatDecimals}
                  />
                </div>
              </div>
              <div className="profile-balance-subtitle text-center">
                {t('VestedValue')} - {currentVestedDate}
              </div>
            </div>
          </div>
        </div>
      </div>

      {incentiveValue ? (
        <BeneficiarySocietyValueSimulator
          societyValue={societyValue}
          valuation={valuation}
          onChange={handleChangeSlider}
          onClickSimulatorButton={handleOpenSellView}
        />
      ) : (
        <></>
      )}
    </>
  );
}

export default BeneficiaryAside;
