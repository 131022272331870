/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import sizes from 'constants/sizes';
import store from 'redux/store';
import { getBoard } from 'redux/actions/boardActions';
import { setModal } from 'redux/actions/modalActions';
import { addAlert } from 'redux/actions/alertActions';

import AssistentsModal from 'pages/Templates/assistentsModal';

import CustomLoading from 'components/CustomLoading';
import alertBodyTypes from 'components/Alert/alertBodyTypes';
import BoardAssistantsMetrics from 'components/BoardAssistantsMetrics';
import BoardAssistantsTable from 'components/BoardAssistantsTable';

import getSummaryByCapital from './utils/getSummaryByCapital';

const BoardAttendance = ({ board }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { actualSociety } = useSelector((state) => ({
    actualSociety: state.society?.actualSociety,
  }));

  const isDemo = useSelector((state) => state.society?.role?.isDemo);

  const [isUpdatingAssistance, setIsUpdatingAssistance] = useState(false);

  const [attendanceSummaryByCapital, setAttendanceSummaryByCapital] = useState({
    assistants: 0,
    represented: 0,
    absents: 0,
    pending: 0,
    quorum: 0,
  });

  const updateAssistance = () => {
    const capitals = getSummaryByCapital(board, actualSociety);

    setAttendanceSummaryByCapital({
      ...capitals,
      quorum: capitals.assistants + capitals.represented,
    });

    setIsUpdatingAssistance(false);
  };

  const handleUpdateAssistance = async () => {
    setIsUpdatingAssistance(true);
    try {
      await store.dispatch(getBoard(board?._id));
    } catch (error) {
      dispatch(addAlert(alertBodyTypes.BOARD_UPDATE_ATTENDANCE_ERROR));
    }
  };

  useEffect(() => {
    if (board && actualSociety) {
      updateAssistance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [board, actualSociety]);

  return (
    <>
      <div className="nk-block pt-4">
        <div className="d-flex justify-content-end mb-2">
          <button
            type="button"
            className="btn btn-outline-light mr-2"
            onClick={handleUpdateAssistance}
            disabled={isDemo}
          >
            <em className="icon ni ni-reload" />
            <span> {t('UpdateAttendance')}</span>
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() =>
              dispatch(
                setModal(
                  <AssistentsModal
                    society={actualSociety}
                    boardId={board?.['_id']}
                    assistants={board?.participants}
                    size={sizes.XL}
                  />
                )
              )
            }
            disabled={isDemo}
          >
            {t('RegisterAttendees')}
          </button>
        </div>

        {attendanceSummaryByCapital && (
          <BoardAssistantsMetrics metrics={attendanceSummaryByCapital} />
        )}
      </div>
      <div id="assistantsList" className="mt-4">
        <BoardAssistantsTable
          board={board}
          attendanceSummaryByCapital={attendanceSummaryByCapital}
        />
      </div>

      {isUpdatingAssistance && <CustomLoading />}
    </>
  );
};

export default BoardAttendance;
