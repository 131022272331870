import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

type InputErrorProps = {
  errorMessage: string;
};

const InputError: FC<InputErrorProps> = ({ errorMessage }) => {
  const { t } = useTranslation();

  const user: any = useSelector((state: any) => state.user);
  const isAdmin: boolean = useSelector(
    (state: any) => state.society?.role?.isAdmin
  );

  return <span className="fs-12px text-danger">{t(errorMessage)}</span>;
};

export default InputError;
