import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { isMobileScreen } from 'utils/getScreenSize';

import DemoImage from '../../../assets/images/try-demo-logo.png';
import { defaultDarkColor } from '../constants/demoConstants';

import '../Demo.scss';

type DemoLeftSectionProps = {
  data: {
    societyDarkColor: string;
    currentLogo: string;
  };
};

const currentYear = new Date().getFullYear();

const DemoLeftSection: FC<DemoLeftSectionProps> = ({ data }) => {
  const { t } = useTranslation();

  const { societyDarkColor, currentLogo } = data;

  return (
    <div
      className={`nk-split-content nk-left-section is-dark p-5 d-flex justify-between flex-column text-center ${
        isMobileScreen() ? 'w-100' : 'w-50'
      }`}
      style={{ backgroundColor: societyDarkColor ?? defaultDarkColor }}
    >
      <div className="nk-left-section-logo">
        <img
          className="logo-img"
          src={currentLogo}
          srcSet={currentLogo}
          alt="logo-dark"
        />
      </div>
      <div className="text-block">
        <img
          className={`nk-survey-gfx nk-left-section-image mb-5 `}
          src={DemoImage}
          alt="Demo logo"
        />
        <h3 className="text-white">Versión Demo Sttok</h3>
        <p className="d-none d-md-block text-soft">
          Prueba todas las funcionalidades y descubre la forma más eficiente y
          profesional de gestionar Libros de Socios, Planes de Incentivos,
          Juntas de Accionistas, etc.
        </p>
      </div>
      <p className="d-none d-md-block">
        © {currentYear} Sttok | {t('AllRightsReserved')}.
      </p>
    </div>
  );
};

export default DemoLeftSection;
