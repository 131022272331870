/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import { Link } from 'react-router-dom';
import { format, isToday } from 'date-fns';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import parse from 'html-react-parser';

import { dateTimeFormat, timeFormat } from 'constants/formats';
import UsersArray from 'components/UsersArray';
import MenuDots from 'components/MenuDots';

import { deleteCommEmail } from 'redux/actions/communicationActions';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import menuOptions from './menuOptions';
import { getDateTime, getRecipientsText } from '../utils';

const Inbox = ({ communications }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isDemo = useSelector((state) => state.society?.role?.isDemo);

  return (
    <div className="nk-ibx-list" data-simplebar="init">
      <div className="simplebar-wrapper" style={{ margin: '0px' }}>
        <div className="simplebar-height-auto-observer-wrapper">
          <div className="simplebar-height-auto-observer" />
        </div>
        <div className="simplebar-mask">
          <div
            className="simplebar-offset"
            style={{ right: '0px', bottom: '0px', height: '100% !important' }}
          >
            <div
              className="simplebar-content-wrapper"
              role="region"
              aria-label="scrollable content"
              style={{
                height: '100%',
                overflow: 'hidden scroll',
              }}
            >
              <div className="simplebar-content" style={{ padding: '0px' }}>
                {communications?.map((communication) => (
                  <div
                    className={`nk-ibx-item ${
                      communication?.isUnread ? 'is-unread' : ''
                    }`}
                    key={communication['_id']}
                  >
                    <Link
                      to={`/comunicaciones/${communication?.society}/${communication['_id']}`}
                      className="nk-ibx-link"
                    />
                    <div className="nk-ibx-item-elem nk-ibx-item-check">
                      <div className="custom-control custom-control-sm custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input nk-dt-item-check"
                          id="conversionItem01"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="conversionItem01"
                        />
                      </div>
                    </div>
                    <div className="nk-ibx-item-elem nk-ibx-item-star">
                      <Link
                        to={`/comunicaciones/${communication?.society}/${communication?._id}/certificacion`}
                        className="btn btn-icon btn-trigger"
                      >
                        <em className=" icon ni ni-shield-check-fill text-soft" />
                      </Link>
                    </div>
                    <div className="nk-ibx-item-elem nk-ibx-item-user">
                      <div className="user-card">
                        <UsersArray
                          users={communication?.recipients}
                          show={1}
                          size="w-40px"
                          bgColor="bg-primary"
                        />
                        <div className="user-name ml-1">
                          <div className="lead-text">
                            {getRecipientsText(
                              communication?.recipients,
                              communication?.presetGroup
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="nk-ibx-item-elem nk-ibx-item-fluid">
                      <div className="nk-ibx-context-group">
                        <div className="nk-ibx-context">
                          <span className="nk-ibx-context-text">
                            <span className="heading mr-1">
                              {communication?.subject}
                            </span>
                            {communication?.bodySummary || ''}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="nk-ibx-item-elem nk-ibx-item-attach" />
                    <div className="nk-ibx-item-elem nk-ibx-item-time">
                      <div className="sub-text">
                        {getDateTime(communication?.date)}
                      </div>
                    </div>
                    <div className="nk-ibx-item-elem nk-ibx-item-tools">
                      <div className="ibx-actions">
                        {!isDemo && (
                          <>
                            <ul className="ibx-actions-hidden gx-1">
                              {/* <li>
      <span
        className="btn btn-sm btn-icon btn-trigger"
        data-toggle="tooltip"
        data-placement="top"
        data-original-title="Archive"
        onClick={() => {
          console.log('[ARCHIVE]');
        }}
      >
        <em className="icon ni ni-archived" />
      </span>
    </li> */}
                              <li>
                                <span
                                  className="btn btn-sm btn-icon btn-trigger"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  data-original-title="Delete"
                                  onClick={() => {
                                    Swal.fire({
                                      icon: 'warning',
                                      title:
                                        '<h4 class="nk-modal-title">¿Está seguro de que desea eliminar esta comunicación?</h4>',
                                      confirmButtonText: 'Ok, eliminar',
                                      confirmButtonColor: '#6576ff',
                                      allowOutsideClick: false,
                                      showCancelButton: true,
                                    }).then((result) => {
                                      if (result.isConfirmed) {
                                        dispatch(
                                          deleteCommEmail(communication?._id)
                                        );
                                      }
                                    });
                                  }}
                                >
                                  <em className="icon ni ni-trash" />
                                </span>
                              </li>
                            </ul>
                            <ul className="ibx-actions-visible gx-2">
                              <li>
                                <div className="dropdown">
                                  <MenuDots
                                    menuOptions={menuOptions}
                                    id={communication['_id']}
                                    params={{
                                      data: communication,
                                      societyId: communication?.society,
                                    }}
                                    direction="left"
                                  />
                                </div>
                              </li>
                            </ul>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div
          className="simplebar-placeholder"
          style={{ width: '910px', height: '1167px' }}
        />
      </div>
      <div
        className="simplebar-track simplebar-horizontal"
        style={{ visibility: 'hidden' }}
      >
        <div
          className="simplebar-scrollbar"
          style={{ width: '0px', display: 'none' }}
        />
      </div>
      <div
        className="simplebar-track simplebar-vertical"
        style={{ visibility: 'visible' }}
      >
        <div
          className="simplebar-scrollbar"
          style={{
            height: '80px',
            transform: 'translate3d(0px, 35px, 0px)',
            display: 'block',
          }}
        />
      </div>
    </div>
  );
};

export default Inbox;
