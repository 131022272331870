/* eslint-disable no-nested-ternary */
/* eslint-disable react/display-name */
import React from 'react';
import i18n from 'i18n/config';

import screens from 'constants/screens';
import userTypes from 'constants/userTypes';
import MenuDots from 'components/MenuDots';
import CellData from 'components/Tables/CellData';
import screenSizes from 'constants/screenSizes';

function tableColumns(i18n) {
  return [
    // {
    //   name: i18n.t('EmployeesPlan'),
    //   field: 'employeesPlan',
    //   selector: (row) => row[i18n.t('EmployeesPlan')],
    //   sortable: true,
    //   grow: 3,
    //   cell: (row) =>
    //     CellData.EmployeesPlan({
    //       employeesPlan: row[i18n.t('EmployeesPlan')],
    //     }),
    //   export: true,
    // },
    {
      name: i18n.t('Employee'),
      field: 'employee',
      selector: (row) => row[i18n.t('Employee')],
      sortable: true,
      grow: 2,
      cell: (row) =>
        CellData.Employee({
          employee: row[i18n.t('Employee')],
        }),
      export: true,
    },
    {
      name: i18n.t('JobTitle'),
      field: 'jobTitle',
      selector: (row) => row[i18n.t('JobTitle')],
      sortable: true,
      compact: true,
      grow: 1.5,
      cell: (row) =>
        CellData.PlainText({
          text: row[i18n.t('JobTitle')],
        }),
      export: true,
    },
    {
      name: i18n.t('Incentive'),
      field: 'hasIncentive',
      selector: (row) => row[i18n.t('Incentive')],
      sortable: true,
      compact: true,
      cell: (row) =>
        CellData.BadgeText({
          text: row[i18n.t('Incentive')]?.text,
          style: row[i18n.t('Incentive')]?.color,
          outline: false,
        }),
      export: true,
      grow: 0.5,
    },
    {
      name: i18n.t('TaxId'),
      field: 'cif',
      selector: (row) => row[i18n.t('TaxId')],
      sortable: true,
      center: true,
      compact: true,
      cell: (row) =>
        CellData.PlainText({
          text: row[i18n.t('TaxId')],
        }),
      export: true,
      grow: 0.5,
    },
    {
      name: i18n.t('Salary'),
      field: 'salary',
      selector: (row) => row[i18n.t('Salary')],
      sortable: true,
      center: true,
      compact: true,
      cell: (row) =>
        CellData.NumberNoDecimals({
          value: row[i18n.t('Salary')],
        }),
      export: true,
      grow: 1,
    },
    {
      name: i18n.t('Currency'),
      field: 'currency',
      selector: (row) => row[i18n.t('Currency')],
      sortable: true,
      center: true,
      compact: true,
      cell: (row) =>
        CellData.PlainText({
          text: row[i18n.t('Currency')],
        }),
      export: true,
      grow: 0.5,
    },
    {
      name: i18n.t('Status'),
      field: 'isEmployed',
      selector: (row) => row[i18n.t('Status')],
      sortable: true,
      compact: true,
      cell: (row) =>
        CellData.BadgeText({
          text: row[i18n.t('Status')]?.text,
          style: row[i18n.t('Status')]?.color,
          outline: false,
        }),
      export: true,
      grow: 0.5,
    },
    {
      name: i18n.t('StartDate'),
      field: 'startDate',
      sortable: true,
      center: true,
      compact: true,
      selector: (row) => row[i18n.t('StartDate')],
      cell: (row) =>
        CellData.PlainDate({
          date: row[i18n.t('StartDate')],
        }),
      export: true,
      grow: 0.5,
    },
    // {
    //   sortable: false,
    //   cell: (row) => (
    //     <ul className="nk-tb-actions gx-1">
    //       <li>
    //         <div className="dropdown">
    //           {/* <MenuDots
    //             menuOptions={row.beneficiaryOptions}
    //             id={row.beneficiary['_id']}
    //             params={{
    //               data: row.beneficiary,
    //               societyId: row.societyId,
    //               userType: userTypes.BENEFICIARY,
    //               certificateData: row.certificateData,
    //             }}
    //             downloadURL={row.documentURL}
    //             direction="left"
    //           /> */}
    //         </div>
    //       </li>
    //     </ul>
    //   ),
    //   export: false,
    //   right: true,
    //   width: '50px',
    //   allowOverflow: true,
    // },
  ];
}

export default tableColumns;
