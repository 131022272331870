/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import InvestorSociety from 'components/InvestorSociety';
import InvestorPersonal from 'components/InvestorPersonal';
import InvestorTransactions from 'components/InvestorTransactions';
import InvestorDocuments from 'components/InvestorDocuments';
import CustomLoading from 'components/CustomLoading';

import { getStockPlans } from 'redux/actions/planActions';
import { getDrafts } from 'redux/actions/draftActions';
import { getActualSociety, getSocietyValue } from 'utils/filters';

import { setMenu } from 'redux/actions/menuActions';

import menuTypes from 'constants/menuTypes';
import './InvestorDetail.scss';

const InvestorDetail = ({ initialPage = 0 }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { societyId, investorId, pageId } = useParams();
  const { user, actualSociety, drafts } = useSelector((state) => ({
    user: state.user,
    actualSociety: state.society?.actualSociety,
    drafts: state.drafts,
  }));
  const [actualInvestor, setActualInvestor] = useState();
  const [actualDraft, setActualDraft] = useState();
  const [page, setPage] = useState(+pageId || initialPage);
  const [pages, setPages] = useState([]);

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId) getActualSociety(user, societyId);
  }, [actualSociety, user, societyId]);

  useEffect(() => {
    if (user && actualSociety) {
      const societyValue = getSocietyValue(actualSociety);
      const investor = actualSociety?.investors?.find(
        (i) => i['_id'] === investorId
      );
      if (investor) {
        const investorData = {
          ...investor,
          societyValue,
        };
        setActualInvestor(investorData);
        setPages([
          <InvestorSociety
            key="InvestorSociety"
            society={actualSociety}
            investor={investorData}
          />,
          <InvestorPersonal
            key="InvestorPersonal"
            society={actualSociety}
            investor={investorData}
          />,
          <InvestorTransactions
            key="InvestorTransactions"
            society={actualSociety}
            investor={investorData}
          />,
          <InvestorDocuments
            key="InvestorDocuments"
            society={actualSociety}
            investor={investorData}
          />,
        ]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investorId, societyId, user, actualSociety]);

  useEffect(() => {
    if (actualSociety) {
      dispatch(
        setMenu({
          type: menuTypes.EXTENDED,
          societyName: actualSociety?.name,
          societyId: actualSociety?.['_id'],
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety]);

  useEffect(() => {
    if (societyId) dispatch(getDrafts(societyId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [societyId]);

  useEffect(() => {
    if (societyId) {
      dispatch(getStockPlans(societyId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [societyId]);

  useEffect(() => {
    if (actualInvestor && drafts.length) {
      setActualDraft(drafts.find((d) => d['_id'] === actualInvestor.draft));
    }
  }, [drafts, actualInvestor]);

  return actualInvestor ? (
    <div className="nk-content-body">
      <div className="nk-block-head-content">
        <h3 className="nk-block-title page-title">{actualSociety.name}</h3>
      </div>
      <div className="nk-content-wrap">
        <div className="nk-block-head">
          <div className="nk-block-between g-3">
            <div className="nk-block-head-content">
              <h3 className="nk-block-title page-title">
                {t('Investor')} /{' '}
                <strong className="text-primary small">
                  {actualInvestor.name || ''}
                </strong>
              </h3>
            </div>
            <div className="nk-block-head-content">
              <span
                className="btn btn-outline-light bg-white d-none d-sm-inline-flex"
                onClick={() => history.goBack()}
              >
                <em className="icon ni ni-arrow-left" />
                <span>{t('Back')}</span>
              </span>
              <span
                className="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"
                onClick={() => history.goBack()}
              >
                <em className="icon ni ni-arrow-left" />
              </span>
            </div>
          </div>
        </div>
        <div className="nk-block">
          <div className="card card-bordered">
            <div className="card-aside-wrap">
              <div className="card-content">
                <ul className="nav nav-tabs nav-tabs-mb-icon nav-tabs-card">
                  <li className="nav-item">
                    <p
                      className={`nav-link ${page === 0 ? 'active' : ''}`}
                      onClick={() => setPage(0)}
                    >
                      <em className="icon ni ni-user-circle" />
                      <span>{t('Investor')}</span>
                    </p>
                  </li>
                  <li className="nav-item">
                    <p
                      className={`nav-link ${page === 1 ? 'active' : ''}`}
                      onClick={() => setPage(1)}
                    >
                      <em className="icon ni ni-list" />
                      <span>{t('Data')}</span>
                    </p>
                  </li>
                  <li className="nav-item">
                    <p
                      className={`nav-link ${page === 2 ? 'active' : ''}`}
                      onClick={() => setPage(2)}
                    >
                      <em className="icon ni ni-repeat" />
                      <span>{t('Investments')}</span>
                    </p>
                  </li>
                  <li className="nav-item">
                    <p
                      className={`nav-link ${page === 3 ? 'active' : ''}`}
                      onClick={() => setPage(3)}
                    >
                      <em className="icon ni ni-file-text" />
                      <span>{t('Documents')}</span>
                    </p>
                  </li>
                </ul>
                {pages[page]}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <CustomLoading />
  );
};

export default InvestorDetail;
