/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-case-declarations */
/* eslint-disable react/react-in-jsx-scope */

import store from 'redux/store';
import Swal from 'sweetalert2';
import downloadDocument from 'utils/downloadDocument';
import downloadFile from 'utils/downloadFile';
import DocumentsGenerator from 'components/Documents/Creators/DocumentsGenerator';
import documentTypes from 'constants/documentTypes';
import { setPreview } from 'redux/actions/previewActions';
import DocumentModal from 'pages/Documents/documentModal';
import { setModal } from 'redux/actions/modalActions';
import html2pdf from 'html3pdf';
import duplicateDocument from 'utils/duplicateDocument';
import deleteDocument from 'utils/deleteDocument';
import UpdateDocumentModal from './UpdateDocumentModal';
import MoveDocumentModal from './MoveDocumentModal';

function menuOptions(i18n) {
  return [
    {
      icon: 'ni-info',
      text: i18n.t('View'),
      action: async ({
        documentId,
        document,
        setDocumentSelected,
        setOpenTemplateCreator,
        actualSociety,
      }) => {
        if (document.subcategory === documentTypes.DOCUMENTS) {
          const datatDocument = (
            <DocumentsGenerator
              invitationModel={document?.editorModel}
              hasConsolidationCalendar={false}
              consolidationCalendar={{}}
            />
          );
          await store.dispatch(
            setPreview({
              name: document.name,
              bdDocument: document,
              document: datatDocument,
              documentModel: document?.editorModel,
              template: document?.['_id'],
            })
          );
          window.location.href = `#/documentos/documentos/${actualSociety['_id']}?preview=true`;
        } else if (document.category === documentTypes.TEMPLATES) {
          if (typeof setDocumentSelected === 'function') {
            setDocumentSelected(document);
          }
          if (typeof setOpenTemplateCreator === 'function') {
            setOpenTemplateCreator(true);
          }
        } else {
          downloadFile({ documentId });
        }
      },
    },
    {
      icon: 'ni-file-pdf',
      text: i18n.t('Discharge'),
      action: async ({ document }) => {
        if (document.subcategory === documentTypes.DOCUMENTS) {
          const options = {
            filename: document?.name,
            margin: [20, 20, 20, 20], // top, right, bottom, left
            pagebreak: { mode: 'avoid-all' },
            html2canvas: { scale: 2 },
            jsPDF: {
              unit: 'mm',
              format: 'a4',
              orientation: 'portrait',
            },
          };
          html2pdf().from(document?.editorModel).set(options).save();
        } else {
          downloadDocument({
            documentId: document['_id'],
            documentName: document.name,
          });
        }
      },
      dontShowCurrent: ({ document }) =>
        document.category === documentTypes.TEMPLATES &&
        document.subcategory !== documentTypes.DOCUMENTS,
    },
    {
      icon: 'ni-edit-alt',
      text: i18n.t('Rename'),
      action: ({ actualSociety, document, documentsByOperations }) => {
        store.dispatch(
          setModal(
            <UpdateDocumentModal
              renameUpdate
              societyId={actualSociety['_id']}
              document={document}
              operationDocument={
                documentsByOperations
                  ? // eslint-disable-next-line no-prototype-builtins
                    documentsByOperations.hasOwnProperty(document['_id'])
                  : false
              }
            />
          )
        );
      },
      dontShowCurrent: ({ isDemo }) => isDemo === true,
    },
    {
      icon: 'ni-calendar',
      text: i18n.t('ChangeDate'),
      action: ({ actualSociety, document }) => {
        store.dispatch(
          setModal(
            <UpdateDocumentModal
              societyId={actualSociety['_id']}
              document={document}
            />
          )
        );
      },

      dontShowCurrent: ({ document, documentsByOperations, isDemo }) =>
        (documentsByOperations
          ? // eslint-disable-next-line no-prototype-builtins
            documentsByOperations.hasOwnProperty(document['_id'])
          : false) || isDemo === true,
    },
    {
      icon: 'ni-copy',
      text: i18n.t('Duplicate'),
      action: ({ document }) => {
        if (document.subcategory === documentTypes.DOCUMENTS) {
          duplicateDocument(document, false);
        } else {
          duplicateDocument(document, true);
        }
      },
      dontShowCurrent: ({ document, isDemo }) =>
        (document.category === documentTypes.TEMPLATES &&
          document.subcategory !== documentTypes.DOCUMENTS) ||
        isDemo === true,
    },
    {
      icon: 'ni-curve-up-right',
      text: i18n.t('Move'),
      action: ({ document, actualSociety }) => {
        store.dispatch(
          setModal(
            <MoveDocumentModal
              societyId={actualSociety['_id']}
              document={document}
            />
          )
        );
      },
      dontShowCurrent: ({ document, isDemo }) =>
        (document.category === documentTypes.TEMPLATES &&
          document.subcategory !== documentTypes.DOCUMENTS) ||
        isDemo === true,
    },
    {
      icon: 'ni-curve-up-right',
      text: i18n.t('ChangeBook'),
      action: ({ document, actualSociety }) => {
        if (document.category === documentTypes.MINUTEBOOK) {
          store.dispatch(
            setModal(
              <MoveDocumentModal
                societyId={actualSociety['_id']}
                document={document}
                changeBook
              />
            )
          );
        }
      },
      dontShowCurrent: ({ isDemo }) => isDemo === true,
    },
    {
      icon: 'ni-curve-up-right',
      text: i18n.t('ChangeFolder'),
      action: ({ document, actualSociety }) => {
        if (document.category === documentTypes.MYFOLDERS) {
          store.dispatch(
            setModal(
              <MoveDocumentModal
                societyId={actualSociety['_id']}
                document={document}
                changeFolder
              />
            )
          );
        }
      },
      dontShowCurrent: ({ isDemo }) => isDemo === true,
    },
    {
      icon: 'ni-curve-up-right',
      text: i18n.t('ChangeCouncilMinute'),
      action: ({ document, actualSociety }) => {
        if (document.category === documentTypes.COUNCILMINUTES) {
          store.dispatch(
            setModal(
              <MoveDocumentModal
                societyId={actualSociety['_id']}
                document={document}
                changeCouncilMinute
              />
            )
          );
        }
      },
      dontShowCurrent: ({ isDemo }) => isDemo === true,
    },
    {
      icon: 'ni ni-link',
      text: i18n.t('LinkTo'),
      action: ({ user, actualSociety, document }) => {
        store.dispatch(
          setModal(
            <DocumentModal
              userId={user['_id']}
              societyId={actualSociety['_id']}
              showLinkOptions
              document={document}
              updateLink
            />
          )
        );
      },
      dontShowCurrent: ({ document, isDemo }) =>
        (document.category === documentTypes.TEMPLATES &&
          document.subcategory !== documentTypes.DOCUMENTS) ||
        isDemo === true,
    },
    {
      icon: 'ni-swap-alt',
      text: i18n.t('ChangeStatus'),
      action: ({ actualSociety, document }) => {
        store.dispatch(
          setModal(
            <UpdateDocumentModal
              societyId={actualSociety['_id']}
              document={document}
              statusUpdate
              currentStatus={document.reportStatus}
            />
          )
        );
      },
      dontShowCurrent: ({ document, isDemo }) =>
        document.category !== documentTypes.ANNUALREPORTS || isDemo === true,
    },
    {
      icon: 'ni-trash',
      text: i18n.t('Delete'),
      action: async ({ document, actualSociety, user }) => {
        Swal.fire({
          icon: 'warning',
          title: `<h4 class="nk-modal-title">${i18n.t(
            'AreYouSureAboutDelDocument'
          )}</h4>`,
          confirmButtonText: i18n.t('Delete'),
          confirmButtonColor: '#6576ff',
          allowOutsideClick: false,
          showCancelButton: true,
        }).then(async (result) => {
          if (result.isConfirmed) {
            await deleteDocument(document, actualSociety, user, i18n);
          }
        });
      },
      dontShowCurrent: ({ isDemo }) => isDemo === true,
    },
  ];
}

export default menuOptions;
