import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Encumbrance } from 'types';

type Props = {
  encumbrances: Encumbrance[];
  isEditing: boolean;
  handleEditEncumbrance: (index: number) => void;
  handleRemoveEncumbrance: (index: number) => void;
};

const ShareEncumbranceView: FC<Props> = ({
  encumbrances,
  isEditing,
  handleEditEncumbrance,
  handleRemoveEncumbrance,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="table-responsive">
        <table className="table my-2">
          <thead className="table-light">
            <tr>
              <th className="text-left">{t('Name')}</th>
              <th className="text-center">{t('AffectedShares')}</th>
              <th className="text-center">{t('From')}</th>
              <th className="text-center">{t('To')}</th>
              <th className="text-center" />
              <th className="text-center" />
            </tr>
          </thead>
          <tbody>
            {encumbrances?.map((encumbrance, index) => (
              <tr>
                <td
                  className="text-left"
                  key={`encumbrance-name-${encumbrance?.from}`}
                >
                  {encumbrance?.name}
                </td>
                <td
                  className="text-center"
                  key={`encumbrance-affected-shares-${encumbrance?.from}`}
                >
                  {encumbrance?.to - encumbrance?.from}
                </td>
                <td
                  className="text-center"
                  key={`encumbrance-from-${encumbrance?.from}`}
                >
                  {encumbrance?.from}
                </td>
                <td
                  className="text-center"
                  key={`encumbrance-to-${encumbrance?.from}`}
                >
                  {encumbrance?.to}
                </td>
                <td
                  className="text-center"
                  key={`encumbrance-edit-${encumbrance?.from}`}
                >
                  <button
                    type="button"
                    className="text-soft btn btn-icon pt-0 pb-0"
                    onClick={() => handleEditEncumbrance(index)}
                    disabled={isEditing}
                  >
                    <em className="icon ni ni-edit" />
                  </button>
                </td>
                <td
                  className="text-center"
                  key={`encumbrance-remove-${encumbrance?.from}`}
                >
                  <button
                    type="button"
                    className="text-soft btn btn-icon pt-0 pb-0"
                    onClick={() => handleRemoveEncumbrance(index)}
                    disabled={isEditing}
                  >
                    <em className="icon ni ni-trash" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ShareEncumbranceView;
