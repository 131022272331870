import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';

import Slider from 'components/Slider';

import '../../assets/styles/Pages.scss';
import '../../assets/styles/Styles.scss';

function Login() {
  const { t } = useTranslation();
  const { loginWithRedirect } = useAuth0();

  const currentYear = new Date().getFullYear();

  return (
    <div className="nk-split nk-split-page nk-split-md">
      <div className="nk-split-content nk-block-area nk-block-area-column nk-auth-container bg-white">
        <div className="nk-block nk-block-middle nk-auth-body">
          <div className="brand-logo pb-5">
            <Link to="/" className="logo-link">
              <img
                className="logo-light logo-img logo-img-lg"
                src="/images/logo-dark.png"
                srcSet="/images/logo-dark.png"
                alt="logo"
              />
              <img
                className="logo-dark logo-img logo-img-lg"
                src="/images/logo-dark.png"
                srcSet="/images/logo-dark.png"
                alt="logo-dark"
              />
            </Link>
          </div>
          <div className="nk-block-head">
            <div className="nk-block-head-content">
              <h5 className="nk-block-title">{t('IdentifyYourself')}</h5>
              <div className="nk-block-des">
                <p>{t('AccessSttok')}.</p>
              </div>
            </div>
          </div>
          <button
            className="btn btn-lg btn-primary btn-block"
            type="button"
            aria-label="Login"
            onClick={loginWithRedirect}
          >
            {t('Login')}
          </button>
        </div>
        <div className="nk-block nk-auth-footer">
          <div className="nk-block-between">
            <ul className="nav nav-sm">
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://www.sttok.com/aviso-legal/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('LegalWarning')}
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://www.sttok.com/privacidad/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('Privacy')}
                </a>
              </li>
            </ul>
          </div>
          <div className="mt-3">
            <p>
              © {currentYear} Sttok | {t('AllRightsReserved')}.
            </p>
          </div>
        </div>
      </div>
      <Slider />
    </div>
  );
}

export default Login;
