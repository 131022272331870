/* eslint-disable react/react-in-jsx-scope */
import store from 'redux/store';
import Swal from 'sweetalert2';
import i18n from 'i18n/config';

import { format } from 'date-fns';
import { deleteDraft } from 'redux/actions/draftActions';
import { dateFormat } from 'constants/formats';
import { setModal } from 'redux/actions/modalActions';

import updateNcStatus from 'utils/updateNcStatus';
import RenameDraft from 'components/Modals/RenameDraft';

export default [
  {
    icon: 'ni-edit-alt',
    text: i18n.t('Rename'),
    action: (draft) => {
      store.dispatch(setModal(<RenameDraft draft={draft} />));
    },
  },
  {
    icon: 'ni-edit',
    text: i18n.t('EditState'),
    action: updateNcStatus,
  },
  {
    icon: 'ni-trash',
    text: i18n.t('Delete'),
    action: (draft, draftId) => {
      const formattedDate = format(new Date(draft?.date), dateFormat);
      Swal.fire({
        icon: 'warning',
        title:
          '<h4 class="nk-modal-title">¿Está seguro de que desea eliminar este borrador?</h4>',
        html: `<h5 class="text-primary">${formattedDate} | ${draft?.name}</h5><br /><div class="caption-text">Este procedimiento no se puede deshacer.</div>`,
        confirmButtonText: 'Ok, eliminar',
        confirmButtonColor: '#6576ff',
        allowOutsideClick: false,
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          store.dispatch(deleteDraft(draftId));
        }
      });
    },
  },
];
