import types from 'redux/actions/actionTypes';
import { identifyUser } from 'utils/logRocket';

import { BLANK_PROFILE_PICTURE } from 'constants/defaultConstants';
import alertBodyTypes from 'components/Alert/alertBodyTypes';

import jwtDecode from 'jwt-decode';
import { decryptResponse } from 'utils/token';
import errors from 'pages/TryDemo/utils/errors';
import axios from '../../interceptors/axios';
import { addAlert } from './alertActions';

function setUser(user) {
  return { type: types.SET_USER, user };
}

export const getUser = (id) => async (dispatch) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/users/${id}`
    );

    const decodedUserData = jwtDecode(data);
    localStorage.setItem('token', decodedUserData['_id']);
    identifyUser(decodedUserData);

    dispatch(setUser(decodedUserData));
  } catch (error) {
    dispatch(addAlert(alertBodyTypes[500]));
  }
};

export const getUsers = (usersList) => async (dispatch) => {
  const query = { params: { ...usersList } };
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/users`,
      query
    );

    const decodedData = await decryptResponse(data);
    return decodedData;
  } catch (error) {
    dispatch(addAlert(alertBodyTypes[500]));
  }
};

export const updateUser =
  (userId, updateUserData, loggedUser, showAlert = true) =>
  async (dispatch) => {
    const body = {
      _id: userId,
      ...updateUserData,
      loggedUser: loggedUser || userId,
    };
    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/users`,
        body
      );

      const decodedUserData = jwtDecode(data);

      dispatch(setUser(decodedUserData));

      if (showAlert) {
        dispatch(addAlert(alertBodyTypes.USER_UPDATED));
      }
    } catch (error) {
      dispatch(addAlert(alertBodyTypes.ERROR_UPDATING_USER));
    }
  };

export const findUser = (user) => async (dispatch) => {
  const email = user?.email;
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/users`,
      { email }
    );

    const decodedUserData = jwtDecode(data);
    localStorage.setItem('token', decodedUserData['_id']);
    identifyUser(decodedUserData);

    const currentImage = decodedUserData?.image;
    if (currentImage === BLANK_PROFILE_PICTURE && user?.picture) {
      decodedUserData.image = user?.picture;
      dispatch(
        updateUser(
          decodedUserData['_id'],
          { image: user?.picture },
          decodedUserData['_id'],
          false
        )
      );
    }

    dispatch(setUser(decodedUserData));
  } catch (error) {
    dispatch(addAlert(alertBodyTypes[500]));
  }
};

export const addUserDemo = (user) => async (dispatch) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL_V2}/users`,
      { ...user, source: 'demo', isDemo: true }
    );

    const decodedUserData = jwtDecode(data);
    localStorage.setItem('token', decodedUserData['_id']);
    identifyUser(decodedUserData);

    return decodedUserData;
  } catch (error) {
    if (error.response.status === 409) {
      const responseError = error.response.data.error;
      const currentError = errors[responseError] || errors.GENERIC_ERROR;
      throw new Error(currentError);
    }

    dispatch(addAlert(alertBodyTypes[500]));
    throw new Error(errors.GENERIC_ERROR);
  }
};

export const fetchIdentities = (email, society = '') =>
  axios.post(`${process.env.REACT_APP_API_URL}/identities`, { email, society });
