/* eslint-disable no-underscore-dangle */
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { addMonths, format } from 'date-fns';

import transformData from 'components/Tables/helpers';
import CustomDataTable from 'components/Tables/CustomDataTable';
import TableLoader from 'components/Tables/TableLoader';

import { setMenuType } from 'redux/actions/menuActions';
import { getUserSocieties } from 'redux/actions/societyActions';

import { formatNumber } from 'utils/formats';
import { getUserInvestorsFromSociety } from 'utils/filters';

import menuTypes from 'constants/menuTypes';

import tableColumns from './tableColumns';

import './InvestedList.scss';

function InvestedList({ items = 10 }) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user);

  const [isLoadingData, setIsLoadingData] = useState(true);
  const [societies, setSocieties] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [currentTableColumns, setCurrentTableColumns] = useState(
    tableColumns(i18n)
  );
  useEffect(() => dispatch(setMenuType(menuTypes.MAIN)), [dispatch]);

  useEffect(() => {
    if (user && user?.societies?.invested?.length) {
      getUserSocieties(user['_id'], 'investor', setSocieties).then(() =>
        setIsLoadingData(false)
      );
    } else {
      setIsLoadingData(false);
    }
  }, [user]);

  useEffect(() => {
    if (i18n.language) {
      setCurrentTableColumns(tableColumns(i18n));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const memoizedData = useMemo(() => {
    if (user && societies.length) {
      const userInvestors = societies.reduce((acc, society) => {
        const currentInvestors = getUserInvestorsFromSociety(
          society,
          user['_id']
        );
        return [...acc, ...currentInvestors];
      }, []);

      return userInvestors.map((investor, index) => ({
        id: investor.draft?.['_id'] + index,
        draftId: investor.draft?.['_id'],
        draftName: investor?.draft?.name,
        societyName: investor.societyName,
        contributionDate: investor.contributionDate
          ? format(new Date(investor.contributionDate), 'dd/MM/yyyy')
          : '',
        contribution: investor?.contribution,
        discountPercent: investor?.discountPercent,
        sharesTotal: investor?.sharesCount?.drafts
          ? formatNumber(investor?.sharesCount?.drafts || 0)
          : 0,
        societyId: investor?.draft?.society,
        investorId: investor?.['_id'],
      }));
    }
    return [];
  }, [user, societies]);

  useEffect(() => {
    if (memoizedData.length) {
      setTableData(
        transformData({
          data: memoizedData,
          columns: tableColumns(i18n),
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memoizedData, i18n.language]);

  return (
    <div className="nk-content-body">
      <div className="nk-content-wrap">
        <div className="nk-block-head nk-block-head-sm">
          <div className="nk-block-between">
            <div className="nk-block-head-content">
              <h3 className="nk-block-title page-title">
                {t('ConvertibleLoans')}
              </h3>
              <div className="nk-block-des text-soft">
                <p>
                  {tableData?.length === 0
                    ? t('NoLoans')
                    : t('YouHaveLoans', { count: tableData?.length })}
                </p>
              </div>
            </div>
          </div>
        </div>

        {tableData?.length > 0 ? (
          <CustomDataTable
            data={tableData}
            columns={currentTableColumns}
            searchBy={t('DraftConvertibleNote')}
            pagination
            className="nk-tb-list"
            rowsPerPage={items}
            actions
            showDownload={false}
          />
        ) : (
          isLoadingData && <TableLoader rows={items} />
        )}
      </div>
    </div>
  );
}

export default InvestedList;
