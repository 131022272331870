import domtoimage from 'dom-to-image';

export const snapshotCreator = async (element: any) => {
  try {
    if (!element) {
      throw new Error('Element not found');
    }

    const scale = window.devicePixelRatio;

    const blob = await domtoimage.toBlob(element, {
      height: element.offsetHeight * scale,
      width: element.offsetWidth * scale,
      style: {
        transform: `scale(${scale})`,
        transformOrigin: 'top left',
        width: `${element.offsetWidth}px`,
        height: `${element.offsetHeight}px`,
      },
    });

    return blob;
  } catch (err) {
    console.error(err);
  }
};

export const copyImageToClipBoardSafari = (element: HTMLElement) => {
  navigator.clipboard
    .write([
      new ClipboardItem({
        'image/png': new Promise((resolve, reject) => {
          try {
            const blob = snapshotCreator(element);

            resolve(
              new Blob([blob as unknown as BlobPart], { type: 'image/png' })
            );
          } catch (err) {
            reject(err);
          }
        }),
      }),
    ])
    .then(() => console.log('Copied to clipboard'))
    .catch((err) => console.error('Error:', err));
};

export const copyImageToClipBoardOtherBrowsers = (element: HTMLElement) => {
  navigator?.permissions
    ?.query({ name: 'clipboard-write' as PermissionName })
    .then(async (result) => {
      if (result.state === 'granted') {
        const type = 'image/png';
        const blob = await snapshotCreator(element);
        const data = [new ClipboardItem({ [type]: blob as Blob })];

        navigator.clipboard
          .write(data)
          .then(() => console.log('Copied to clipboard'))
          .catch((err) => console.error('Error:', err));
      }
    });
};
