export const getEmployeeSalaryAmount = (employee) => {
  if (!employee.terminationDate) {
    const lastContract = employee.contracts[employee.contracts.length - 1];
    return lastContract.salaryAmount ? lastContract.salaryAmount / 100 : 'N/A';
  }

  return 'N/A';
};

export const getEmployeeTeam = (employee) => {
  if (!employee.terminationDate && employee.teams?.length) {
    const teams = employee.teams?.map((team) => team.name);
    return teams.join(', ');
  }

  return 'N/A';
};

export const getEmployeeIncentive = (employee, cifList) => {
  const { cif } = employee;
  return cifList?.includes(cif?.toUpperCase());
};

export const getTotalEmployeesWithIncentive = (employees, cifList) => {
  const total = employees?.filter((employee) =>
    cifList?.includes(employee.cif)
  );
  return total?.length || 0;
};

export const getLastImportationDate = (employeesList) => {
  const lastImportationDate = employeesList?.reduce((prev, current) =>
    prev.importationDate > current.importationDate ? prev : current
  );

  return lastImportationDate?.importationDate;
};

export const getEmployeeFirstStartDate = (employee) => {
  const firstContract = employee?.contracts?.reduce((prev, current) =>
    prev.startDate < current.startDate ? prev : current
  );

  return firstContract?.startDate;
};

export const getEmployeeLastStartDate = (employee) => {
  const lastContract = employee?.contracts?.reduce((prev, current) =>
    prev.endDate > current.endDate ? prev : current
  );

  return lastContract?.endDate;
};

export const getEmployeeJobTitle = (employee) => {
  const lastContract = employee.contracts[employee.contracts.length - 1];
  return lastContract.jobTitle || lastContract.role;
};

export const getEmployeeTerminationDate = (employee) =>
  employee.terminationDate;
