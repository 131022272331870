import React from 'react';
import { useTranslation } from 'react-i18next';

import alertIcon from '../../../assets/images/icon-sections/alert-danger.svg';

const NoDocumentsFoundAlert = () => {
  const { t } = useTranslation();

  return (
    <div className="nk-files-list">
      <div className="card card-bordered w-100">
        <div className="card-inner">
          <div className="nk-help">
            <div className="nk-help-img">
              <img src={alertIcon} alt="section" />
            </div>
            <div className="nk-help-text">
              <h5>{t('NoDocumentsFound')}</h5>
              <p className="text-soft">{t('NoDocumentsFoundMessage') || ''}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoDocumentsFoundAlert;
