/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMixpanel } from 'react-mixpanel-browser';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { Modal } from 'react-bootstrap';
import { format } from 'date-fns';
import Select from 'react-select';
import store from 'redux/store';
import makeAnimated from 'react-select/animated';

import { setModal } from 'redux/actions/modalActions';
import { addAlert } from 'redux/actions/alertActions';
import { addDocument } from 'redux/actions/documentActions';
import { getHoldingClasses } from 'redux/actions/holdingClassActions';
import { sellParticipationsAction } from 'redux/actions/modalsActions';

import cleanText from 'utils/cleanText';
import trackEvent from 'utils/trackEvent';
import { getActualPartner } from 'utils/filters';

import {
  ANADIR_SOCIO,
  SELECCIONAR_SOCIO,
  SELECT_OPTION,
} from 'constants/defaultConstants';
import fileTypes from 'constants/fileTypes';
import eventTypes from 'constants/eventTypes';
import documentTypes from 'constants/documentTypes';
import operationTypes from 'constants/operationTypes';
import transactionTypes from 'constants/transactionTypes';
import { currencyFormat, numberFormat, cleanValue } from 'constants/formats';

import UploadDocument from 'components/UploadDocument';
import alertBodyTypes from 'components/Alert/alertBodyTypes';
import CommentsCharCounter from 'components/CommentsCharCounter';

import AddPartnerEmbeddedModal from '../AddPartnerEmbedded';

import '../Modals.scss';

const DECREMENT = 'DECREMENT';

const prices = {
  FIXED: 'FIXED',
  BY_SHARE: 'BY_SHARE',
  BY_SOCIETY: 'BY_SOCIETY',
};

const priceLabels = {
  [prices.FIXED]: 'TotalPriceForSale',
  [prices.BY_SHARE]: 'PriceForParticipation',
  [prices.BY_SOCIETY]: 'SocietyValuation',
};

function SellParticipationsModal({
  isDraft = false,
  setDraftData,
  setDraftPartners,
}) {
  const { t } = useTranslation();
  const mixpanel = useMixpanel();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const actualSociety = useSelector((state) => state.society.actualSociety);
  const holdingClasses = useSelector((state) => state.holdingClasses);

  const [date, setDate] = useState('');
  const [lastOperationDate, setLastOperationDate] = useState();
  const [operationDate, setOperationDate] = useState();
  const [operationLimit, setOperationLimit] = useState();
  const [startedSell, setStartedSell] = useState(false); // Esta propiedad se setea true cuando se agrega una primera venta
  const [movements, setMovements] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [historyRanges, setHistoryRanges] = useState({});
  const [sharesCount, setSharesCount] = useState({});
  const [historySells, setHistorySells] = useState([]);
  const [sellPartner, setSellPartner] = useState(SELECCIONAR_SOCIO);
  const [buyPartner, setBuyPartner] = useState(SELECCIONAR_SOCIO);
  const [participationsToSell, setParticipationsToSell] = useState();
  const [participationPrice, setParticipationPrice] = useState();
  const [priceType, setPriceType] = useState(prices.FIXED);
  const [file, setFile] = useState('');
  const [comments, setComments] = useState('');
  const [notaryName, setNotaryName] = useState('');
  const [notaryCity, setNotaryCity] = useState('');
  const [protocolNr, setProtocolNr] = useState('');

  const [userShareRanges, setUserShareRanges] = useState([]);
  const [sharesRange, setSharesRange] = useState([{ from: '', to: '' }]);
  const [sharesTotal, setSharesTotal] = useState(0);
  const [isValidRange, setIsValidRange] = useState(false);
  const [holdingClass, setHoldingClass] = useState(SELECT_OPTION);
  const [ordinaryClass] = useState(holdingClasses?.[0]?.['_id']);
  const [createPartnerInsite, setCreatePartnerInsite] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [rangeErrorMessage, setRangeErrorMessage] = useState('');
  const [sellPartnerOptions, setSellPartnerOptions] = useState([]);
  const [buyPartnerOptions, setBuyPartnerOptions] = useState([]);

  const [newPartners, setNewPartners] = useState([]);
  const [newPartner, setNewPartner] = useState(null);

  const [selectedSellPartnerOption, setSelectedSellPartnerOption] = useState({
    value: SELECCIONAR_SOCIO,
    label: t('SelectPartner'),
  });

  const [selectedBuyPartnerOption, setSelectedBuyPartnerOption] = useState({
    value: SELECCIONAR_SOCIO,
    label: t('SelectPartner'),
  });

  const [selectedClassOption, setSelectedClassOption] = useState({
    value: SELECT_OPTION,
    label: t('Select'),
  });

  const priceTypeOptions = Object.entries(prices).map(([, price]) => ({
    value: price,
    label: t(priceLabels[price]),
  }));

  const animatedComponents = makeAnimated();

  const getNewPartner = (partnerId) => {
    const result = newPartners.find((partner) => partner['_id'] === partnerId);
    return result;
  };

  const handleClassOptionChange = (selectedOption) => {
    setHoldingClass(selectedOption.value);
    setSelectedClassOption(selectedOption);
  };

  const handleSellPartnerOptionChange = (selectedOption) => {
    setSellPartner(selectedOption.value);
    setSelectedSellPartnerOption(selectedOption);
  };

  const handleBuyPartnerOptionChange = (selectedOption) => {
    setBuyPartner(selectedOption.value);
    setSelectedBuyPartnerOption(selectedOption);
  };

  const handlePriceTypeOptionChange = (selectedOption) => {
    setPriceType(selectedOption.value);
  };

  const handleDate = (selectedDate) => {
    const objDate = new Date(selectedDate);
    if (objDate.getFullYear() < 1970) {
      if (operationDate) setOperationDate();
    } else if (objDate > new Date(lastOperationDate)) {
      setErrorMessage('');
      setDate(objDate);
      setOperationDate(format(objDate, 'yyyy-MM-dd'));
    } else {
      const nextDate = new Date(lastOperationDate);
      nextDate.setMinutes(nextDate.getMinutes() + 30);
      setDate(nextDate);
      setOperationDate(format(nextDate, 'yyyy-MM-dd'));
    }
  };

  const handleSellAll = () => {
    const newSharesRange = userShareRanges.map((range) => ({
      from: range.shareFrom,
      to: range.shareTo,
    }));
    setSharesRange(newSharesRange);
    setIsValidRange(true);
  };

  const handleAddRange = () => {
    const newSharesRange = [...sharesRange];
    newSharesRange.push({
      from: '',
      to: '',
    });
    setSharesRange(newSharesRange);
  };

  const handleRemoveRange = (index) => {
    const newSharesRange = [...sharesRange];
    newSharesRange.splice(index, 1);
    setSharesRange(newSharesRange);
  };

  const calculatePricePerShare = (priceType, currentPrice, sharesToSell) => {
    switch (priceType) {
      case prices.FIXED:
        return currentPrice / sharesToSell;
      case prices.BY_SHARE:
        return currentPrice;
      case prices.BY_SOCIETY:
        return currentPrice / actualSociety?.sharesCount?.actual;
      default:
        return 0;
    }
  };

  // devuelve true si no hay solapamiento entre los rangos
  const checkOverlappingRanges = (ranges) => {
    const compareFunction = (a, b) => +a.from - +b.from;
    const orderedRanges = ranges.sort(compareFunction);
    for (let index = 0; index < orderedRanges.length - 1; index += 1) {
      if (+orderedRanges[index].to >= +orderedRanges[index + 1].from)
        return false;
    }
    return true;
  };

  // devuelve true si el rango es subintervalo de algun intervalo en userShareRanges
  const checkExistsRange = (range) =>
    userShareRanges.some(
      (userRange) =>
        +range.from >= +userRange.shareFrom && +range.to <= +userRange.shareTo
    );

  const handleSharesRange = (index, field, value) => {
    const newSharesRange = [...sharesRange];
    if (field === 'FROM') {
      newSharesRange[index].from = value;
    } else {
      newSharesRange[index].to = value;
    }
    // validar rango de venta
    if (newSharesRange[index].from !== '' && newSharesRange[index].to !== '') {
      if (+newSharesRange[index].to < +newSharesRange[index].from) {
        setIsValidRange(false);
        setRangeErrorMessage('Numeración inválida');
      } else if (!checkExistsRange(newSharesRange[index])) {
        setIsValidRange(false);
        setRangeErrorMessage(
          'Numeración inválida. El vendedor no posee la numeración indicada'
        );
      } else if (!checkOverlappingRanges([...newSharesRange])) {
        setIsValidRange(false);
        setRangeErrorMessage(
          'Numeración inválida. Las numeraciones introducidas no pueden solaparse'
        );
      } else {
        setIsValidRange(true);
        setRangeErrorMessage('');
      }
    } else if (index === newSharesRange.length - 1) {
      setIsValidRange(true);
      setRangeErrorMessage('');
    } else {
      setIsValidRange(false);
      setRangeErrorMessage('');
    }
    setSharesRange(newSharesRange);
  };
  function saveSell() {
    if (!sellPartner || !buyPartner)
      dispatch(addAlert(alertBodyTypes['SELECT_USER']));
    else if (sellPartner === buyPartner)
      dispatch(addAlert(alertBodyTypes['SAME_BUY_AND_SELL_USER']));
    else {
      const newMovements = [];
      const newTransactions = [];
      const newHistorySells = [...historySells];
      let actualUserShareRanges = [...userShareRanges];
      const newHistoryRanges = historyRanges;
      for (const actualRange of sharesRange) {
        if (actualRange.from === '' || actualRange.to === '') break;
        // encuentra el rango de participaciones del que se vende
        const currentUserRange = actualUserShareRanges.find(
          (range) =>
            +actualRange.from >= range.shareFrom &&
            +actualRange.to <= range.shareTo
        );
        // elimina las participaciones vendidas de userShareRanges
        actualUserShareRanges = actualUserShareRanges.filter(
          (range) =>
            range.shareFrom !== currentUserRange.shareFrom &&
            range.shareTo !== currentUserRange.shareTo
        );
        // agrega el movement de las participaciones vendidas como un DECREMENT
        newMovements.push({
          partner: sellPartner,
          shareFrom: currentUserRange.shareFrom,
          shareTo: currentUserRange.shareTo,
          shareClass: currentUserRange.shareClass,
          movementType: DECREMENT,
        });
        const pricePerShare = calculatePricePerShare(
          priceType,
          +participationPrice,
          +participationsToSell
        );
        // agrega el movement de las participaciones adquiridas por el buyPartner
        newMovements.push({
          partner: buyPartner,
          shareFrom: +actualRange.from,
          shareTo: +actualRange.to,
          shareClass: currentUserRange.shareClass,
          sharePremium: currentUserRange.sharePremium,
          sharePrice: pricePerShare,
          operation: 'THIS', // se utiliza 'THIS' para indicarle a la api que esta share debe crearse con referencia a la operacion actual
        });
        // agrega la transaction de tipo compra
        newTransactions.push({
          partner: buyPartner,
          shareFrom: +actualRange.from,
          shareTo: +actualRange.to,
          shareClass: currentUserRange.shareClass,
          sharePremium: currentUserRange.sharePremium,
          sharePrice: pricePerShare,
          transactionType: transactionTypes.BUY,
        });
        // agrega el tramo adquirido como rango disponible en historyRanges
        newHistoryRanges[buyPartner].push({
          shareFrom: +actualRange.from,
          shareTo: +actualRange.to,
          shareClass: currentUserRange.shareClass,
          sharePremium: currentUserRange.sharePremium,
          sharePrice: pricePerShare,
          operation: 'THIS',
        });
        // agrega la transaction de tipo venta
        newTransactions.push({
          partner: sellPartner,
          shareFrom: +actualRange.from,
          shareTo: +actualRange.to,
          shareClass: currentUserRange.shareClass,
          sharePremium: currentUserRange.sharePremium,
          sharePrice: pricePerShare,
          transactionType: transactionTypes.SELL,
          operation: currentUserRange?.operation || null,
        });
        const className = holdingClasses?.find(
          (shareClass) => shareClass['_id'] === holdingClass
        )?.name;
        newHistorySells.push({
          seller:
            getActualPartner(actualSociety, sellPartner)?.name ??
            getNewPartner(sellPartner)?.name,
          buyer:
            getActualPartner(actualSociety, buyPartner)?.name ??
            getNewPartner(buyPartner)?.name,
          shareFrom: +actualRange.from,
          shareTo: +actualRange.to,
          shareClass: className,
          sharePremium: currentUserRange.sharePremium,
          sharePrice: pricePerShare,
        });
        // agrega los nuevos rangos resultantes a actualUserShareRanges
        if (currentUserRange.shareFrom !== +actualRange.from) {
          actualUserShareRanges.push({
            shareFrom: currentUserRange.shareFrom,
            shareTo: +actualRange.from - 1,
            sharePremium: currentUserRange.sharePremium,
            shareClass: currentUserRange.shareClass,
            sharePrice: currentUserRange.sharePrice,
            operation: currentUserRange?.operation || null,
          });
          newMovements.push({
            partner: sellPartner,
            shareFrom: currentUserRange.shareFrom,
            shareTo: +actualRange.from - 1,
            sharePremium: currentUserRange.sharePremium,
            shareClass: currentUserRange.shareClass,
            sharePrice: currentUserRange.sharePrice,
            operation: currentUserRange?.operation || null,
          });
        }
        if (currentUserRange.shareTo !== +actualRange.to) {
          actualUserShareRanges.push({
            shareFrom: +actualRange.to + 1,
            shareTo: currentUserRange.shareTo,
            sharePremium: currentUserRange.sharePremium,
            shareClass: currentUserRange.shareClass,
            sharePrice: currentUserRange.sharePrice,
            operation: currentUserRange?.operation || null,
          });
          newMovements.push({
            partner: sellPartner,
            shareFrom: +actualRange.to + 1,
            shareTo: currentUserRange.shareTo,
            sharePremium: currentUserRange.sharePremium,
            shareClass: currentUserRange.shareClass,
            sharePrice: currentUserRange.sharePrice,
            operation: currentUserRange?.operation || null,
          });
        }
      }
      const incrementMovements = newMovements.filter(
        (movement) => !movement.movementType
      );
      const decrementMovements = newMovements.filter(
        (movement) => movement.movementType
      );
      const finalMovements = [...incrementMovements, ...decrementMovements];
      setMovements([...movements, ...finalMovements]);
      setTransactions([...transactions, ...newTransactions]);

      const subsetRanges = historyRanges[sellPartner]?.filter(
        (share) => share.shareClass !== holdingClass
      );
      const compareFunction = (a, b) => +a.shareFrom - +b.shareFrom;
      const orderedRanges = actualUserShareRanges.sort(compareFunction);
      newHistoryRanges[sellPartner] = [...subsetRanges, ...orderedRanges];
      setHistoryRanges(newHistoryRanges);

      const newSharesCount = Object.entries(newHistoryRanges).reduce(
        (acc, [id, ranges]) => {
          const count = ranges.reduce(
            (acc, curr) => acc + curr.shareTo - curr.shareFrom + 1,
            0
          );
          acc[id] = count;
          return acc;
        },
        {}
      );
      setSharesCount(newSharesCount);

      setStartedSell(true);
      setHistorySells(newHistorySells);
      setSellPartner(SELECCIONAR_SOCIO);
      setBuyPartner(SELECCIONAR_SOCIO);
      setSelectedSellPartnerOption({
        value: SELECCIONAR_SOCIO,
        label: t('SelectPartner'),
      });
      setSelectedBuyPartnerOption({
        value: SELECCIONAR_SOCIO,
        label: t('SelectPartner'),
      });
      setSelectedClassOption({ value: SELECT_OPTION, label: t('Select') });
      setUserShareRanges(orderedRanges);
      setSharesRange([{ from: '', to: '' }]);
      setIsValidRange(false);
      setParticipationPrice('');
      const buyOptions = [...actualSociety?.partners, ...newPartners].map(
        (partner) => ({
          value: partner?.['_id'],
          label: `${partner?.cif} | ${partner?.name} | ${
            newSharesCount[partner?.['_id']] ||
            partner?.sharesCount?.actual ||
            0
          } ${t('shares')}`,
        })
      );
      buyOptions.push({
        value: 'disabled',
        label: '─────────────────',
        isDisabled: true,
      });

      buyOptions.push({
        value: ANADIR_SOCIO,
        label: ANADIR_SOCIO,
      });
      setBuyPartnerOptions(buyOptions);
      const sellOptions = actualSociety?.partners
        .filter((partner) => newHistoryRanges[partner['_id']]?.length > 0)
        .map((partner) => ({
          value: partner?.['_id'],
          label: `${partner?.cif} | ${partner?.name} | ${
            newSharesCount[partner?.['_id']] || partner?.sharesCount?.actual
          } ${t('shares')}`,
        }));
      setSellPartnerOptions(sellOptions);
    }
  }

  async function saveOperation(event) {
    event.preventDefault();
    let finalMovements = [...movements];
    let finalTransactions = [...transactions];
    if (
      sellPartner !== SELECCIONAR_SOCIO &&
      buyPartner !== SELECCIONAR_SOCIO &&
      isValidRange &&
      participationsToSell &&
      participationPrice !== ''
    ) {
      const newMovements = [];
      const newTransactions = [];
      for (const actualRange of sharesRange) {
        if (actualRange.from === '' || actualRange.to === '') break;
        const currentUserRange = userShareRanges.find(
          (range) =>
            +actualRange.from >= range.shareFrom &&
            +actualRange.to <= range.shareTo
        );
        newMovements.push({
          partner: sellPartner,
          shareFrom: currentUserRange.shareFrom,
          shareTo: currentUserRange.shareTo,
          shareClass: currentUserRange.shareClass,
          movementType: DECREMENT,
        });
        const pricePerShare = calculatePricePerShare(
          priceType,
          +participationPrice,
          +participationsToSell
        );
        newMovements.push({
          partner: buyPartner,
          shareFrom: +actualRange.from,
          shareTo: +actualRange.to,
          shareClass: currentUserRange.shareClass,
          sharePremium: currentUserRange.sharePremium,
          sharePrice: pricePerShare,
          operation: 'THIS',
        });
        newTransactions.push({
          partner: buyPartner,
          shareFrom: +actualRange.from,
          shareTo: +actualRange.to,
          shareClass: currentUserRange.shareClass,
          sharePremium: currentUserRange.sharePremium,
          sharePrice: pricePerShare,
          transactionType: transactionTypes.BUY,
        });
        newTransactions.push({
          partner: sellPartner,
          shareFrom: +actualRange.from,
          shareTo: +actualRange.to,
          shareClass: currentUserRange.shareClass,
          sharePremium: currentUserRange.sharePremium,
          sharePrice: pricePerShare,
          transactionType: transactionTypes.SELL,
          operation: currentUserRange?.operation || null,
        });
        if (currentUserRange.shareFrom !== +actualRange.from) {
          newMovements.push({
            partner: sellPartner,
            shareFrom: currentUserRange.shareFrom,
            shareTo: +actualRange.from - 1,
            sharePremium: currentUserRange.sharePremium,
            shareClass: currentUserRange.shareClass,
            sharePrice: currentUserRange.sharePrice,
            operation: currentUserRange?.operation || null,
          });
        }
        if (currentUserRange.shareTo !== +actualRange.to) {
          newMovements.push({
            partner: sellPartner,
            shareFrom: +actualRange.to + 1,
            shareTo: currentUserRange.shareTo,
            sharePremium: currentUserRange.sharePremium,
            shareClass: currentUserRange.shareClass,
            sharePrice: currentUserRange.sharePrice,
            operation: currentUserRange?.operation || null,
          });
        }
      }
      const incrementMovements = newMovements.filter(
        (movement) => !movement.movementType
      );
      const decrementMovements = newMovements.filter(
        (movement) => movement.movementType
      );
      const allMovements = [...incrementMovements, ...decrementMovements];
      finalMovements = [...finalMovements, ...allMovements];
      finalTransactions = [...finalTransactions, ...newTransactions];
    }

    const operationData = {
      date,
      society: actualSociety['_id'],
      user: user['_id'],
      nominalValue: actualSociety.nominalValue,
      movements: finalMovements,
      transactions: finalTransactions,
      comments: cleanText(comments),
      notarialRegistration: {
        notaryName,
        notaryCity,
        protocolNr,
      },
    };

    if (!isDraft) {
      let uploadedDocument = null;

      if (file) {
        const newDocument = {
          file,
          date,
          size: file.size,
          name: operationTypes.SELL_PARTICIPATION_FILENAME,
          author: user['_id'],
          society: actualSociety['_id'],
          fileType: fileTypes[file.type],
          category: documentTypes.SCRIPTURES,
          description: t('DocumentLinkedToOperation'),
        };
        uploadedDocument = await store.dispatch(addDocument(newDocument));
      }

      dispatch(
        sellParticipationsAction({
          ...operationData,
          documents: uploadedDocument?.['_id'] ? [uploadedDocument['_id']] : [],
        })
      );
    } else {
      setDraftData({ ...operationData });
      setDraftPartners(newPartners);
    }

    dispatch(setModal(null));

    const eventName = isDraft
      ? eventTypes.SELL_PARTICIPATION_DRAFT
      : eventTypes.SELL_PARTICIPATION;

    trackEvent(mixpanel, eventName, {
      userId: user?._id,
      userName: user?.name,
      userEmail: user?.email,
      societyId: actualSociety?._id,
      societyName: actualSociety?.name,
      operation: eventName,
    });
  }

  const handleClickRange = (shareFrom, shareTo) => {
    const index = sharesRange.length - 1;
    handleSharesRange(index, 'FROM', cleanValue(shareFrom) || '');
    handleSharesRange(index, 'TO', cleanValue(shareTo) || '');
  };

  useEffect(() => {
    if (sellPartner !== SELECCIONAR_SOCIO) {
      setHoldingClass(SELECT_OPTION);
      setSelectedClassOption({ value: SELECT_OPTION, label: t('Select') });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellPartner]);

  useEffect(() => {
    if (actualSociety) {
      dispatch(getHoldingClasses(actualSociety?.['_id']));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (sellPartner) {
      const totalSharesRange = sharesRange.reduce((acc, curr) => {
        if (+curr.to && +curr.from && +curr.to >= +curr.from)
          return acc + +curr.to - +curr.from + 1;
        return acc;
      }, 0);
      setParticipationsToSell(totalSharesRange);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sharesRange]);

  useEffect(() => {
    if (actualSociety) {
      setCreatePartnerInsite(false);
      // initialize historyRanges for tracking of ranges and sharesCount for partners
      const initial = actualSociety?.partners.reduce(
        (acc, partner) => {
          acc.initialSharesCount[partner['_id']] = partner?.sharesCount?.actual;
          const partnerShares = actualSociety.shares.filter(
            (share) => share.isActive && share.partner === partner['_id']
          );
          const partnerRanges = partnerShares.map((share) => ({
            shareFrom: share.from,
            shareTo: share.to,
            sharePremium: share.sharePremium,
            sharePrice: share.sharePrice,
            shareClass: share.shareClass
              ? share.shareClass['_id']
              : ordinaryClass,
            operation: share?.operation || null,
          }));
          const compareFunction = (a, b) => +a.shareFrom - +b.shareFrom;
          const orderedRanges = partnerRanges.sort(compareFunction);
          acc.initialRanges[partner['_id']] = orderedRanges;
          return acc;
        },
        {
          initialRanges: {},
          initialSharesCount: {},
        }
      );
      setHistoryRanges(initial.initialRanges);
      setSharesCount(initial.initialSharesCount);
      // Get last operation date
      const excludedOps = [
        operationTypes.ADD_PARTNERS,
        operationTypes.ADD_PARTNER,
        operationTypes.ADD_BENEFICIARY,
        operationTypes.STOCK_OPTION,
        operationTypes.PHANTOM_SHARE,
      ];
      const filteredOperations = actualSociety.operations.filter(
        (operation) => !excludedOps.includes(operation.operationType)
      );
      const lastOperation = filteredOperations.reduce((acc, op) =>
        new Date(acc.date) > new Date(op.date) ? acc : op
      );
      // Get last operation date from UTC to local timezone with new Date()
      const lastDate = format(
        new Date(lastOperation.date),
        "yyyy-MM-dd'T'HH:mm"
      );
      const dateLimit = format(new Date(lastOperation.date), 'yyyy-MM-dd');
      setOperationLimit(dateLimit);
      setLastOperationDate(lastDate || '1990-01-01T00:00');

      const sellOptions = actualSociety?.partners
        .filter((partner) => partner?.sharesCount?.actual > 0)
        .map((partner) => ({
          value: partner?.['_id'],
          label: `${partner?.cif} | ${partner?.name} | ${
            sharesCount[partner?.['_id']] || partner?.sharesCount?.actual
          } ${t('shares')}`,
        }));

      const buyOptions = actualSociety?.partners.map((partner) => ({
        value: partner?.['_id'],
        label: `${partner?.cif} | ${partner?.name} | ${
          sharesCount[partner?.['_id']] || partner?.sharesCount?.actual
        } ${t('shares')}`,
      }));

      buyOptions.push({
        value: 'disabled',
        label: '─────────────────',
        isDisabled: true,
      });

      buyOptions.push({
        value: ANADIR_SOCIO,
        label: ANADIR_SOCIO,
      });

      setSellPartnerOptions(sellOptions);
      setBuyPartnerOptions(buyOptions);
    }
  }, [actualSociety]);

  useEffect(() => {
    if (buyPartner === ANADIR_SOCIO) {
      setCreatePartnerInsite(true);
    } else {
      setCreatePartnerInsite(false);
    }
  }, [buyPartner]);

  useEffect(() => {
    if (sellPartner !== SELECCIONAR_SOCIO) {
      const compareFunction = (a, b) => +a.shareFrom - +b.shareFrom;
      const ranges =
        holdingClass !== SELECT_OPTION
          ? historyRanges[sellPartner]
              .filter((share) => share.shareClass === holdingClass)
              .sort(compareFunction)
          : [];
      setUserShareRanges(ranges);
      setSharesRange([{ from: '', to: '' }]);
      setIsValidRange(false);
    } else {
      setUserShareRanges([]);
      setSharesRange([{ from: '', to: '' }]);
      setIsValidRange(false);
    }
  }, [sellPartner, holdingClass]);

  useEffect(() => {
    if (historyRanges && sellPartner !== SELECCIONAR_SOCIO) {
      const sharesByClass = {};
      const sellPartnerShares = historyRanges[sellPartner];
      sellPartnerShares.forEach((share) => {
        if (!sharesByClass[share.shareClass]) {
          sharesByClass[share.shareClass] = 0;
        }
        sharesByClass[share.shareClass] += share.shareTo - share.shareFrom + 1;
      });
      setSharesTotal(sharesByClass);
    }
  }, [sellPartner, historyRanges, historySells]);

  useEffect(() => {
    if (newPartner) {
      const buyOptions = buyPartnerOptions.slice(0, -2);
      const newHistoryRanges = { ...historyRanges };

      buyOptions.push({
        value: newPartner?.['_id'],
        label: `${newPartner?.cif} | ${newPartner?.name} | ${
          sharesCount[newPartner?.['_id']] || 0
        } ${t('shares')}`,
      });
      newHistoryRanges[newPartner?.['_id']] = [];

      buyOptions.push({
        value: 'disabled',
        label: '─────────────────',
        isDisabled: true,
      });

      buyOptions.push({
        value: ANADIR_SOCIO,
        label: ANADIR_SOCIO,
      });
      setNewPartners([...newPartners, newPartner]);
      setBuyPartnerOptions(buyOptions);
      setHistoryRanges(newHistoryRanges);
    }
  }, [newPartner]);

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title">
          {t('SaleOfShares')}

          {isDraft && (
            <span className="text-muted text-right ff-alt fs-14px ml-2">
              ({t('Draft')})
            </span>
          )}
        </h5>
        <a
          className="close cursor-pointer"
          onClick={() => dispatch(setModal(null))}
        >
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
      <Modal.Body>
        <form className="form-validate is-alter" onSubmit={saveOperation}>
          <div className="form-group">
            <label className="form-label" htmlFor="pay-amount">
              {t('TransmissionDate')}
            </label>
            <div className="form-control-wrap">
              <input
                type="date"
                className="form-control date-picker"
                value={operationDate}
                onChange={(event) => handleDate(event.target.value)}
                required
                min={operationLimit}
                max="2100-01-01"
                disabled={startedSell}
              />
            </div>
            {errorMessage ? (
              <span className="sub-text mt-1 text-danger">{errorMessage}</span>
            ) : (
              <></>
            )}
          </div>
          <div className="form-group">
            <label className="form-label" htmlFor="default-01">
              {t('PartnerThatSells')}
            </label>
            <div className="form-control-wrap">
              <div className="form-icon form-icon-left">
                <em className="icon ni ni-user" />
              </div>
              <Select
                closeMenuOnSelect
                className="react-select"
                value={selectedSellPartnerOption}
                options={sellPartnerOptions}
                components={animatedComponents}
                onChange={handleSellPartnerOptionChange}
              />
            </div>
          </div>
          <div className="form-group">
            <label className="form-label" htmlFor="pay-amount">
              {t('ClassOfParticipations')}
            </label>
            <div className="form-control-wrap">
              <div className="">
                <Select
                  closeMenuOnSelect
                  className="react-select"
                  value={selectedClassOption}
                  options={holdingClasses
                    ?.filter(
                      (holdingClass) => sharesTotal?.[holdingClass['_id']]
                    )
                    .map((holdingClass) => ({
                      value: holdingClass['_id'],
                      label: `${holdingClass.name} | ${
                        sharesTotal?.[holdingClass['_id']] || 0
                      }${' '}
              ${t('shares')}`,
                    }))}
                  components={animatedComponents}
                  onChange={handleClassOptionChange}
                  placeholder={SELECT_OPTION}
                />
              </div>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-12 d-flex justify-content-between align-items-center">
              <label className="form-label mb-0" htmlFor="pay-amount">
                {t('NºParticipationsThatSells')}
                <span className="badge-primary rounded px-2 py-1 ml-2">
                  <NumberFormat
                    displayType="text"
                    value={participationsToSell || 0}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...numberFormat}
                  />
                </span>
              </label>

              <button
                className="btn btn-outline-primary btn-dim border-radius-0 float-right"
                type="button"
                onClick={() => handleSellAll()}
              >
                <span>{t('Sell​​AllParticipations')}</span>
              </button>
            </div>
          </div>
          <div className="form-group">
            {sharesRange.map((shareRange, index) => (
              <>
                <div className="form-control-wrap pb-1">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon3">
                        {t('OfTheNo')}
                      </span>
                    </div>
                    <NumberFormat
                      id="outlined-normal"
                      className="form-control form-control-outlined"
                      value={shareRange.from}
                      onChange={(e) =>
                        handleSharesRange(
                          index,
                          'FROM',
                          cleanValue(e.target.value) || ''
                        )
                      }
                      isAllowed={(inputObj) => {
                        const { floatValue, formattedValue } = inputObj;
                        if (formattedValue === '') return true;
                        if (floatValue >= 1 && Number.isInteger(floatValue))
                          return true;
                        return false;
                      }}
                      disabled={!sellPartner}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...numberFormat}
                    />
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon3">
                        {t('ToTheNo')}
                      </span>
                    </div>
                    <NumberFormat
                      id="outlined-normal"
                      className="form-control form-control-outlined"
                      value={shareRange.to}
                      onChange={(e) =>
                        handleSharesRange(
                          index,
                          'TO',
                          cleanValue(e.target.value) || ''
                        )
                      }
                      isAllowed={(inputObj) => {
                        const { floatValue, formattedValue } = inputObj;
                        if (formattedValue === '') return true;
                        if (floatValue >= 1 && Number.isInteger(floatValue))
                          return true;
                        return false;
                      }}
                      disabled={!sellPartner}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...numberFormat}
                    />
                    {sharesRange.length > 1 &&
                    index < sharesRange.length - 1 ? (
                      <button
                        className="btn btn-outline-primary btn-dim border-radius-0 col-4"
                        type="button"
                        onClick={() => handleRemoveRange(index)}
                      >
                        <em className="icon ni ni-minus" />
                        <span>{t('EliminateNumbering')}</span>
                      </button>
                    ) : (
                      <button
                        className="btn btn-outline-primary btn-dim border-radius-0 col-4"
                        type="button"
                        onClick={() => handleAddRange()}
                        disabled={
                          !isValidRange ||
                          !sellPartner ||
                          !shareRange.from ||
                          !shareRange.to ||
                          +shareRange.from > +shareRange.to
                        }
                      >
                        <em className="icon ni ni-plus" />
                        <span>{t('AddNumbering')}</span>
                      </button>
                    )}
                  </div>
                </div>
              </>
            ))}

            {rangeErrorMessage ? (
              <span className="sub-text text-danger">{rangeErrorMessage}</span>
            ) : (
              <></>
            )}

            {sellPartner && (
              <span className="mt-2">
                {t('Available')}
                {userShareRanges.map((userShareRange) => (
                  <>
                    <b
                      className="badge btn-primary ml-1 cursor-pointer "
                      onClick={() =>
                        handleClickRange(
                          userShareRange.shareFrom,
                          userShareRange.shareTo
                        )
                      }
                    >
                      <NumberFormat
                        displayType="text"
                        value={userShareRange.shareFrom}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...numberFormat}
                      />
                      &nbsp;-&nbsp;
                      <NumberFormat
                        displayType="text"
                        value={userShareRange.shareTo}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...numberFormat}
                      />
                      &nbsp;
                    </b>
                  </>
                ))}
              </span>
            )}
          </div>

          <div className="row mb-4">
            <div className="col-6">
              <div className="form-group">
                <label className="form-label" htmlFor="pay-amount">
                  {t(priceLabels[priceType])}
                </label>
                <div className="form-control-wrap">
                  <div className="form-text-hint">
                    <span className="overline-title">€</span>
                  </div>
                  <input
                    type="number"
                    className="form-control"
                    placeholder={t(priceLabels[priceType])}
                    value={participationPrice}
                    onChange={(event) =>
                      setParticipationPrice(event.target.value)
                    }
                    min="0"
                    step="0.000000001"
                  />
                </div>
              </div>
            </div>

            <div className="col-6">
              <div className="form-group">
                <label className="form-label" htmlFor="default-01">
                  {t('PriceType')}
                </label>
                <div className="form-control-wrap">
                  <div className="form-icon form-icon-left">
                    <em className="icon ni ni-user" />
                  </div>
                  <Select
                    closeMenuOnSelect
                    className="react-select"
                    value={{
                      value: priceType,
                      label: t(priceLabels[priceType]),
                    }}
                    options={priceTypeOptions}
                    components={animatedComponents}
                    onChange={handlePriceTypeOptionChange}
                  />
                </div>
              </div>
            </div>
          </div>

          {createPartnerInsite && date ? (
            <AddPartnerEmbeddedModal
              setSelectedPartner={setBuyPartner}
              setSelectedPartnerOption={setSelectedBuyPartnerOption}
              setCreatePartnerInsite={setCreatePartnerInsite}
              date={date}
              isDraft={isDraft}
              setNewPartner={setNewPartner}
            />
          ) : (
            <div className="form-group">
              <label className="form-label" htmlFor="default-01">
                {t('MemberWhoBuys')}
              </label>
              <div className="form-control-wrap">
                <div className="form-icon form-icon-left">
                  <em className="icon ni ni-user" />
                </div>
                <Select
                  closeMenuOnSelect
                  className="react-select"
                  value={selectedBuyPartnerOption}
                  options={buyPartnerOptions}
                  components={animatedComponents}
                  onChange={handleBuyPartnerOptionChange}
                />
              </div>
              {createPartnerInsite && !date && (
                <span className="sub-text mt-1 text-danger">
                  {t('SelectATransmissionDate')}
                </span>
              )}
            </div>
          )}

          <div className="form-group">
            <button
              type="button"
              className="btn btn-outline-primary btn-dim border-radius-0"
              disabled={
                !operationDate ||
                sellPartner === SELECCIONAR_SOCIO ||
                buyPartner === SELECCIONAR_SOCIO ||
                buyPartner === ANADIR_SOCIO ||
                sellPartner === buyPartner ||
                !isValidRange ||
                !participationsToSell ||
                participationPrice === '' ||
                holdingClass === SELECT_OPTION
              }
              onClick={() => saveSell()}
            >
              {t('AddAnotherSale')}
            </button>
          </div>

          {!isDraft && (
            <UploadDocument
              file={file}
              setFile={setFile}
              label="AddWritingOptional"
            />
          )}

          <div className="form-group">
            <label className="form-label" htmlFor="operation-comments">
              {t('CommentsLabel')}
            </label>
            <div className="form-control-wrap">
              <div className="form-editor-custom">
                <textarea
                  id="operation-comments"
                  maxLength="500"
                  className="form-control form-control-lg no-resize"
                  value={comments}
                  onChange={(e) => setComments(e.target.value)}
                />
              </div>
            </div>
            <div className="form-note d-flex justify-content-between">
              <>{t('CommentsVisibleOnlyAdmins')}</>
              <CommentsCharCounter comments={comments} />
            </div>
          </div>

          <div className="row mb-4">
            <div className="col-4">
              <div className="form-group">
                <label className="form-label">{t('NotaryName')}</label>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    className="form-control form-control-md"
                    placeholder={t('Mr. John Smith')}
                    value={notaryName}
                    onChange={(e) => setNotaryName(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label className="form-label">
                  {t('NotaryProtocolNumber')}
                </label>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    className="form-control form-control-md"
                    placeholder={t('P-8234290A')}
                    value={protocolNr}
                    onChange={(e) => setProtocolNr(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label className="form-label">{t('NotaryCity')}</label>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    className="form-control form-control-md"
                    placeholder={t('Barcelona')}
                    value={notaryCity}
                    onChange={(e) => setNotaryCity(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>

          {historySells.length ? (
            <div className="table-responsive">
              <table className="table my-2">
                <thead className="table-light">
                  <tr>
                    <th>{t('Sell')}</th>
                    <th>{t('Purchase')}</th>
                    <th>{t('Class')}</th>
                    <th className="text-center">{t('From')}</th>
                    <th className="text-center">{t('To')}</th>
                    <th className="text-center">{t('Price')}</th>
                  </tr>
                </thead>
                <tbody>
                  {historySells?.map((operation) => (
                    <tr>
                      <td>{operation.seller}</td>
                      <td>{operation.buyer}</td>
                      <td>{operation.shareClass}</td>
                      <td className="text-center">
                        <NumberFormat
                          displayType="text"
                          value={operation.shareFrom}
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...numberFormat}
                        />
                      </td>
                      <td className="text-center">
                        <NumberFormat
                          displayType="text"
                          value={operation.shareTo}
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...numberFormat}
                        />
                      </td>
                      <td className="text-center">
                        <NumberFormat
                          value={operation.sharePrice}
                          displayType="text"
                          {...currencyFormat}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <></>
          )}

          <button
            type="submit"
            className="btn btn-lg btn-primary float-sm-left"
            disabled={
              !operationDate ||
              (!startedSell &&
                (sellPartner === SELECCIONAR_SOCIO ||
                  buyPartner === SELECCIONAR_SOCIO ||
                  buyPartner === ANADIR_SOCIO ||
                  sellPartner === buyPartner ||
                  !isValidRange ||
                  !participationsToSell ||
                  participationPrice === '' ||
                  holdingClass === SELECT_OPTION))
            }
          >
            {t('SaveOperation')}
          </button>
        </form>
      </Modal.Body>
    </>
  );
}

export default SellParticipationsModal;
