import React, { useState } from 'react';
import i18n from 'i18n/config';

import List from 'components/Documents/List';
import Folders from 'components/Documents/Folders';
import documentSections from 'constants/documentSections';
import documentTypes from 'constants/documentTypes';

const currentSection = documentTypes.MYFOLDERS;

const MyFolders = ({
  documents,
  isLoadingData,
  currentFolder,
  setCurrentFolder,
  searchTerm,
}) => {
  const [section] = useState(
    documentSections(i18n).find((s) => s.tag === currentSection)
  );

  return (
    <>
      {!currentFolder ? (
        <div className="nk-fmg-body-content">
          <Folders setCurrentFolder={setCurrentFolder} section={section} />
        </div>
      ) : (
        <List
          section={section}
          documents={documents}
          isLoadingData={isLoadingData}
          currentFolder={currentFolder}
          setCurrentFolder={setCurrentFolder}
          searchTerm={searchTerm}
        />
      )}
    </>
  );
};

export default MyFolders;
