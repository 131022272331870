/* eslint-disable no-underscore-dangle */
import documentSections from 'constants/documentSections';
import i18n from 'i18n/config';
import React from 'react';
import store from 'redux/store';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { setModal } from 'redux/actions/modalActions';
import DocumentsSettingsModal from 'components/Modals/DocumentsSettingsModal';

const SideNav = ({
  selectedSection,
  setSelectedSection,
  setIsSideNavClick,
  setIsLoading,
}) => {
  const { t } = useTranslation();
  const actualSociety = useSelector((state) => state.society?.actualSociety);
  const isAdmin = useSelector((state) => state.society?.role?.isAdmin);

  const [selectedGroupMenu, setSelectedGroupMenu] = React.useState();

  const handleSelectSection = (section) => {
    if (section.url) {
      setSelectedGroupMenu(null);
      setSelectedSection(section);
      setIsSideNavClick(true);
    } else {
      setSelectedSection(null);
      setSelectedGroupMenu(section);
    }
  };

  return (
    <div
      className="nk-fmg-aside toggle-screen-lg"
      data-content="files-aside"
      data-toggle-overlay="true"
      data-toggle-body="true"
      data-toggle-screen="lg"
      data-simplebar="init"
    >
      <div className="simplebar-wrapper" style={{ margin: '0px' }}>
        <div className="simplebar-height-auto-observer-wrapper">
          <div className="simplebar-height-auto-observer" />
        </div>
        <div className="simplebar-mask">
          <div
            className="simplebar-offset"
            style={{ right: '0px', bottom: '0px' }}
          >
            <div
              className="simplebar-content-wrapper"
              role="region"
              aria-label="scrollable content"
              style={{ height: '100%', overflow: 'hidden' }}
            >
              <div className="simplebar-content" style={{ padding: '0px' }}>
                <div className="nk-fmg-aside-wrap">
                  <div className="nk-fmg-aside-top" data-simplebar="init">
                    <div
                      className="simplebar-wrapper"
                      style={{ margin: '0px' }}
                    >
                      <div className="simplebar-height-auto-observer-wrapper">
                        <div className="simplebar-height-auto-observer" />
                      </div>
                      <div className="simplebar-mask">
                        <div
                          className="simplebar-offset"
                          style={{ right: '0px', bottom: '0px' }}
                        >
                          <div
                            className="simplebar-content-wrapper"
                            role="region"
                            aria-label="scrollable content"
                            style={{ height: 'auto', overflow: 'hidden' }}
                          >
                            <div
                              className="simplebar-content"
                              style={{ padding: '0px' }}
                            >
                              <ul className="nk-fmg-menu cursor-pointer">
                                {documentSections(i18n)?.map((section) =>
                                  !section.group ? (
                                    <li
                                      className={
                                        section?.title ===
                                          selectedSection?.title ||
                                        (section.title ===
                                          selectedGroupMenu?.title &&
                                          !selectedSection)
                                          ? 'active'
                                          : ''
                                      }
                                      key={section.id}
                                      disabled={!section.enabled}
                                      onClick={() =>
                                        handleSelectSection(section)
                                      }
                                    >
                                      {section?.url ? (
                                        <Link
                                          className="nk-fmg-menu-item"
                                          to={`${section.url}/${actualSociety?._id}`}
                                        >
                                          <em
                                            className={`icon ni ${section.icon}`}
                                          />
                                          <span className="nk-fmg-menu-text">
                                            {t(section?.title)}
                                          </span>
                                        </Link>
                                      ) : (
                                        <span className="nk-fmg-menu-item">
                                          <em
                                            className={`icon ni ${section.icon}`}
                                          />
                                          <span className="nk-fmg-menu-text">
                                            {t(section?.title)}
                                          </span>
                                        </span>
                                      )}
                                    </li>
                                  ) : (
                                    selectedGroupMenu?.tag ===
                                      section.group && (
                                      <li
                                        className={
                                          section?.title ===
                                          selectedSection?.title
                                            ? 'active'
                                            : ''
                                        }
                                        key={section.id}
                                        disabled={!section.enabled}
                                      >
                                        <Link
                                          className="nk-fmg-menu-item"
                                          to={`${section.url}/${actualSociety?._id}`}
                                          style={{ paddingLeft: '44px' }}
                                          onClick={() =>
                                            setSelectedSection(
                                              documentSections[section.id]
                                            )
                                          }
                                        >
                                          <span className="nk-fmg-menu-text">
                                            {t(section?.title)}
                                          </span>
                                        </Link>
                                      </li>
                                    )
                                  )
                                )}
                                {isAdmin && (
                                  <li
                                    className=""
                                    key="documents-configuration"
                                    onClick={() => {
                                      store.dispatch(
                                        setModal(
                                          <DocumentsSettingsModal
                                            society={actualSociety}
                                            setIsLoading={setIsLoading}
                                          />
                                        )
                                      );
                                    }}
                                  >
                                    <hr />
                                    <div className="nk-fmg-menu-item">
                                      <em className="icon ni ni-setting-alt" />
                                      <span className="nk-fmg-menu-text">
                                        {t('Settings')}
                                      </span>
                                    </div>
                                  </li>
                                )}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="simplebar-placeholder"
                        style={{ width: 'auto', height: '750px' }}
                      />
                    </div>
                    <div
                      className="simplebar-track simplebar-horizontal"
                      style={{ visibility: 'hidden' }}
                    >
                      <div
                        className="simplebar-scrollbar"
                        style={{ width: '0px', display: 'none' }}
                      />
                    </div>
                    <div
                      className="simplebar-track simplebar-vertical"
                      style={{ visibility: 'hidden' }}
                    >
                      <div
                        className="simplebar-scrollbar"
                        style={{ height: '0px', display: 'none' }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="simplebar-placeholder"
          style={{ width: 'auto', height: '1228px' }}
        />
      </div>
      <div
        className="simplebar-track simplebar-horizontal"
        style={{ visibility: 'hidden' }}
      >
        <div
          className="simplebar-scrollbar"
          style={{ width: '0px', display: 'none' }}
        />
      </div>
      <div
        className="simplebar-track simplebar-vertical"
        style={{ visibility: 'hidden' }}
      >
        <div
          className="simplebar-scrollbar"
          style={{ height: '0px', display: 'none' }}
        />
      </div>
    </div>
  );
};

export default SideNav;
