/* eslint-disable no-nested-ternary */
import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { setModal } from 'redux/actions/modalActions';
import '../../assets/styles/Icons.scss';
import '../../assets/styles/Bootstrap.scss';
import './OperationMenuDots.scss';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    className="dropdown-toggle mr-lg-n1"
  >
    {children}
  </div>
));
CustomToggle.displayName = 'CustomToggle';

function OperationMenuDots({
  menuOptions,
  handleModal,
  title = '',
  params = {},
  variant = 'primary',
  disabled = false,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const menu = menuOptions.map((menuOption) => {
    if (menuOption.url)
      return (
        <Dropdown.Item
          onClick={() => handleModal(menuOption.url)}
          key={menuOption.text}
          disabled={menuOption?.disabled}
        >
          <span>{menuOption.text}</span>
        </Dropdown.Item>
      );

    if (menuOption.action) {
      const calssNameText = menuOption.disabled
        ? 'disabled'
        : menuOption.dontShowCurrent && menuOption.dontShowCurrent(params)
        ? 'disabled'
        : 'cursor-pointer';

      // Custom text: for options in which the text may vary depending on some variable
      const text = menuOption.customText
        ? menuOption?.customText(params)
        : menuOption.text;

      if (menuOption.dropdown) {
        return (
          <Dropdown.Item
            onClick={() => menuOption.action(params)}
            key={menuOption.text}
            disabled={menuOption?.disabled}
            className={calssNameText}
          >
            <em className={`icon ni ${menuOption.icon}`} />
            <span>{text}</span>
          </Dropdown.Item>
        );
      }
      return (
        <a
          key={menuOption.text}
          onClick={() => menuOption.action(params)}
          className={`${menuOption.disabled ? 'disabled' : 'cursor-pointer'}
          ${menuOption.borderTop ? 'border-top' : ''}`}
        >
          <span className={`${menuOption.borderTop ? 'pt-1' : ''}`}>
            {menuOption.text}
          </span>
        </a>
      );
    }
    if (menuOption.modal)
      return (
        <a
          key={menuOption.text}
          onClick={() => dispatch(setModal(menuOption.modal))}
          className={menuOption.disabled ? 'disabled' : 'cursor-pointer'}
        >
          <span className={`${menuOption.borderTop ? 'pt-1' : ''}`}>
            {menuOption.text}
          </span>
        </a>
      );

    return null;
  });

  return (
    <Dropdown drop={menuOptions.direction}>
      <Dropdown.Toggle as={CustomToggle}>
        <button
          type="button"
          className={`btn ${
            params?.outlined ? `btn-outline-${variant}` : `btn-${variant}`
          } ${disabled ? 'disabled' : ''}`}
          disabled={disabled}
        >
          {title || t('AddOperation')}
          <span className="icon ni ni-caret-down ml-2" />
        </button>
      </Dropdown.Toggle>
      <Dropdown.Menu
        size="sm"
        title=""
        className="link-list-opt no-bdr dropdown-menu dropdown-menu-s1"
      >
        {menu}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default OperationMenuDots;
