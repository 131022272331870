/* eslint-disable no-await-in-loop */
/* eslint-disable no-alert */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-plusplus */
import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMixpanel } from 'react-mixpanel-browser';
import { Modal } from 'react-bootstrap';

import { setModal } from 'redux/actions/modalActions';
import { createSociety as createSocietyAction } from 'redux/actions/societyActions';

import eventTypes from 'constants/eventTypes';
import queryTypes from 'constants/queryTypes';

import {
  NOT_FOUND_TAG,
  extractDataFromPDF,
  extractDataFromText,
  getDocxContent,
  mapResultToParams,
} from 'utils/documents';
import trackEvent from 'utils/trackEvent';
import { fileToArrayBuffer } from 'utils/files';

import PDFPreview from 'components/PDFViewer';
import DOCXViewer from 'components/DOCXViewer';

import documentsLogo from '../../../assets/images/icon-sections/documents.svg';
import CustomLoading from '../../CustomLoading';

const fileTypes = {
  PDF: 'application/pdf',
  DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
};

const params = [
  'Nombre de la sociedad',
  'Número de identificación fiscal',
  'Dirección',
  'Código postal',
  'Ciudad',
  'País',
];

const labels = {
  'Nombre de la sociedad': 'Sociedad',
  'Número de identificación fiscal': 'CIF',
  Dirección: 'Dirección',
  'Código postal': 'Código Postal',
  Ciudad: 'Ciudad',
  País: 'País',
};

const fields = {
  'Nombre de la sociedad': 'name',
  'Número de identificación fiscal': 'cif',
  Dirección: 'line1',
  'Código postal': 'zip',
  Ciudad: 'city',
  País: 'country',
};

const AddSocietyAI = ({ file }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();

  const user = useSelector((state) => state.user);

  const [isLoading, setIsLoading] = useState(false);
  const [extractedData, setExtractedData] = useState(null);
  const [pendingParams, setPendingParams] = useState(params);

  const [customSocialDenomination, setCustomSocialDenomination] = useState('');
  const [customCIF, setCustomCIF] = useState('');
  const [customLine1, setCustomLine1] = useState('');
  const [customZip, setCustomZip] = useState('');
  const [customCity, setCustomCity] = useState('');
  const [customCountry, setCustomCountry] = useState('');

  const [modalPrivacy, setModalPrivacy] = useState(false);
  const [modalSendNotifications, setModalSendNotifications] = useState(true);
  const [modalHasAccessCaptable, setModalHasAccessCaptable] = useState(false);
  const [modalIsSocietyHolding, setModalIsSocietyHolding] = useState(true);

  const createSociety = async () => {
    const societyName =
      extractedData?.['Nombre de la sociedad'] || customSocialDenomination;
    const societyCIF =
      extractedData?.['Número de identificación fiscal'] || customCIF;

    const society = {
      name: societyName,
      cif: societyCIF,
      legalAddress: {
        line1: extractedData?.['Dirección'] || customLine1,
        zip: extractedData?.['Código postal'] || customZip,
        city: extractedData?.['Ciudad'] || customCity,
        country: extractedData?.['País'] || customCountry,
      },
      creationDate: new Date(),
      mainAdmin: user['_id'],
      administrators: [user['_id']],
      modalSendNotifications,
      modalHasAccessCaptable,
      modalIsSocietyHolding,
    };
    const newSociety = await dispatch(createSocietyAction(society));

    const eventData = {
      operation: eventTypes.ADD_SOCIETY_AI,
      societyName,
      societyCIF,
      notifications: modalSendNotifications,
      userId: user['_id'],
      userName: user?.name,
      userEmail: user?.email,
    };

    trackEvent(mixpanel, eventData.operation, eventData);

    history.push(`/socios/${newSociety?.['_id']}`);
  };

  const getCustomValue = (key) => {
    switch (fields[key]) {
      case 'name':
        return customSocialDenomination;

      case 'cif':
        return customCIF;

      case 'line1':
        return customLine1;

      case 'zip':
        return customZip;

      case 'city':
        return customCity;

      case 'country':
        return customCountry;

      default:
        return '';
    }
  };

  const updateCustomValue = (key, value) => {
    switch (fields[key]) {
      case 'name':
        setCustomSocialDenomination(value);
        break;

      case 'cif':
        setCustomCIF(value);
        break;

      case 'line1':
        setCustomLine1(value);
        break;

      case 'zip':
        setCustomZip(value);
        break;

      case 'city':
        setCustomCity(value);
        break;

      case 'country':
        setCustomCountry(value);
        break;

      default:
        break;
    }
  };

  const handlePDF = async (file) => {
    try {
      const paramsList = params.join(', ');
      const extracted = await extractDataFromPDF(
        file,
        paramsList,
        queryTypes.SOCIETY
      );
      setExtractedData(mapResultToParams(extracted));
    } catch (error) {
      alert('Error extracting data from PDF file.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDocx = async (file) => {
    try {
      const arrayBuffer = await fileToArrayBuffer(file);
      const textElements = await getDocxContent(arrayBuffer);

      let text = '';
      Array.from(textElements).forEach((element) => {
        text += element.textContent;
      });

      const paramsList = params.join(', ');
      const extracted = await extractDataFromText(
        text,
        paramsList,
        queryTypes.SOCIETY
      );
      setExtractedData(mapResultToParams(extracted));
    } catch (error) {
      alert('Error extracting data from DOCX file.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (file) => {
    if (!file) return;

    setIsLoading(true);
    setPendingParams([]);

    try {
      if (file.type === fileTypes.PDF) {
        await handlePDF(file);
      }

      if (file.type === fileTypes.DOCX) {
        await handleDocx(file);
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleConfirmData = () => {
    dispatch(setModal(null));
    createSociety();
  };

  const handleChangeMissingParam = (key, value) => {
    updateCustomValue(key, value);
  };

  useEffect(() => {
    if (file) {
      handleSubmit(file);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (extractedData) {
      Object.entries(extractedData).forEach(([key, value]) => {
        if (value === NOT_FOUND_TAG) {
          setPendingParams((prev) => [...prev, key]);
        }
      });
    }
  }, [extractedData]);

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title">Añadir Sociedad desde una escritura</h5>
        <a
          className="close cursor-pointer"
          onClick={() => dispatch(setModal(null))}
        >
          <em className="icon ni ni-cross cursor-pointer" />
        </a>
      </Modal.Header>

      <Modal.Body>
        {isLoading && <CustomLoading />}

        <div
          className="d-flex justify-content-between align-items-baseline"
          style={{ gap: '20px' }}
        >
          <div style={{ width: '50%' }}>
            {file?.name && (
              <div>
                <h6>Archivo seleccionado:</h6>
                <div className="card card-bordered card-preview mb-4">
                  <div className="card-inner">
                    <div className="preview-block d-flex justify-content-between align-items-center">
                      <div className="preview-icon w-100px">
                        <img src={documentsLogo} alt="" width={50} />
                      </div>

                      <div className="preview-text w-100">
                        <span className="preview-title pb-0">
                          <span>
                            <b>{file.name}</b>
                          </span>
                          <br />

                          <div className="d-flex justify-content-between w-100">
                            {file?.size && (
                              <span className="text-muted">
                                Tamaño: &nbsp;
                                {file.size / 1000} KB
                              </span>
                            )}
                            {file?.lastModifiedDate && (
                              <span className="text-muted">
                                Última modificación: &nbsp;
                                {file.lastModifiedDate.toLocaleDateString()}
                              </span>
                            )}
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {!isLoading && extractedData && (
              <>
                <div>
                  <h6>Datos extraídos:</h6>

                  <div className="card card-bordered card-preview mb-2">
                    <table
                      className="table table-tranx"
                      id="dividends-distribution-table"
                    >
                      <thead>
                        <tr className="tb-tnx-head">
                          <th className="text-left">
                            <span>{t('Campo')}</span>
                          </th>

                          <th className="text-left">
                            <span>{t('Valor')}</span>
                          </th>

                          <th className="w-50px" />
                        </tr>
                      </thead>
                      <tbody>
                        {Object.entries(extractedData).map(([key, value]) => (
                          <tr className="tb-tnx-item" key={key}>
                            <td className="tb-tnx-info w-150px">
                              <div className="tb-tnx-info w-150px">
                                <span className="title">
                                  <b>{labels[key] || key}</b>
                                </span>
                              </div>
                            </td>

                            <td className="tb-tnx-info">
                              <div className="tb-tnx-info w-100">
                                {value !== NOT_FOUND_TAG ? (
                                  <input
                                    id={fields[key]}
                                    type="text"
                                    value={value}
                                    className="amount w-100 bg-none border-0"
                                    readOnly
                                  />
                                ) : (
                                  <input
                                    id={fields[key]}
                                    type="text"
                                    placeholder={t(value)}
                                    value={getCustomValue(key)}
                                    className="amount w-100 bg-none"
                                    readOnly={false}
                                    onChange={(e) =>
                                      handleChangeMissingParam(
                                        key,
                                        e.target.value
                                      )
                                    }
                                  />
                                )}
                              </div>
                            </td>

                            <td className="tb-tnx-info">
                              {value === NOT_FOUND_TAG &&
                                (getCustomValue(key) === '' ? (
                                  <em
                                    className="icon ni ni-cross-round-fill text-danger"
                                    style={{
                                      fontSize: '24px',
                                      lineHeight: '24px',
                                    }}
                                  />
                                ) : (
                                  <em
                                    className="icon ni ni-check-round-fill text-success"
                                    style={{
                                      fontSize: '24px',
                                      lineHeight: '24px',
                                    }}
                                  />
                                ))}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="row mb-4">
                  <div className="col-md-12">
                    <div className="form-group mt-2">
                      <div className="custom-control custom-control-xs custom-checkbox">
                        <input
                          type="checkbox"
                          id="checkbox-modal-notifications"
                          className="custom-control-input"
                          value={!modalSendNotifications}
                          checked={!modalSendNotifications}
                          onChange={() =>
                            setModalSendNotifications(!modalSendNotifications)
                          }
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="checkbox-modal-notifications"
                        >
                          {t('DoNotNotifyThePartners')}
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group mt-2">
                      <div className="custom-control custom-control-xs custom-checkbox">
                        <input
                          type="checkbox"
                          id="checkbox-modal-access-captable"
                          className="custom-control-input"
                          value={modalHasAccessCaptable}
                          checked={modalHasAccessCaptable}
                          onChange={() =>
                            setModalHasAccessCaptable(!modalHasAccessCaptable)
                          }
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="checkbox-modal-access-captable"
                        >
                          {t('PartnersWithAccessCaptable')}
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group mt-2">
                      <div className="custom-control custom-control-xs custom-checkbox">
                        <input
                          type="checkbox"
                          id="checkbox-modal-society-holding"
                          className="custom-control-input"
                          value={modalIsSocietyHolding}
                          checked={modalIsSocietyHolding}
                          onChange={() =>
                            setModalIsSocietyHolding(!modalIsSocietyHolding)
                          }
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="checkbox-modal-society-holding"
                        >
                          {t('IsSocietyHolding')}
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group mt-2">
                      <div className="custom-control custom-control-xs custom-checkbox">
                        <input
                          type="checkbox"
                          id="checkbox-modal-privacy"
                          className="custom-control-input"
                          value={modalPrivacy}
                          checked={modalPrivacy}
                          onChange={(event) =>
                            setModalPrivacy(event.target.checked)
                          }
                          required
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="checkbox-modal-privacy"
                        >
                          {t('IAgree')}{' '}
                          <a
                            href="https://www.sttok.com/privacidad/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {t('PrivacyPolicy')}
                          </a>{' '}
                          {t('and')}{' '}
                          <a
                            href="https://www.sttok.com/aviso-legal/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {t('TermsAndConditions')}
                          </a>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <p>
                  Puedes editar los datos que no se pudieron extraer (marcados
                  con una{' '}
                  <em className="icon ni ni-cross-round-fill text-danger" />
                  ), luego haz click en el botón y la sociedad será añadida a tu
                  lista de sociedades.
                </p>
              </>
            )}
          </div>

          <div style={{ width: '50%', height: '100%' }}>
            <h6>Documento original:</h6>
            <div className="card card-bordered card-preview mb-4 h-100">
              {file?.type === fileTypes.DOCX ? (
                <DOCXViewer file={file} height={660} />
              ) : (
                <PDFPreview file={file} height={660} />
              )}
            </div>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleConfirmData}
          disabled={
            !modalPrivacy ||
            (pendingParams.includes('Número de identificación fiscal') &&
              !getCustomValue('Número de identificación fiscal')) ||
            (pendingParams.includes('Nombre de la sociedad') &&
              !getCustomValue('Nombre de la sociedad'))
          }
        >
          Añadir Sociedad
        </button>
      </Modal.Footer>
    </>
  );
};

export default AddSocietyAI;
