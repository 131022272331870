export default {
  ADD_PARTNER: 'ADD_PARTNER',
  ADD_PARTNERS: 'ADD_PARTNERS',
  ADD_BENEFICIARY: 'ADD_BENEFICIARY',
  ADD_INVESTOR: 'ADD_INVESTOR',
  ADD_BENEFICIARY_DRAFT: 'ADD_BENEFICIARY_DRAFT',
  CONSTITUTION: 'CONSTITUTION',
  CAPITAL_INCREASE: 'CAPITAL_INCREASE',
  CAPITAL_DECREASE: 'CAPITAL_DECREASE',
  SELL_PARTICIPATION: 'SELL_PARTICIPATION',
  RENUMERATION: 'RENUMERATION',
  SPLIT: 'SPLIT',
  ADD_HOLDING_CLASS: 'ADD_HOLDING_CLASS',
  STOCK_OPTION: 'STOCK_OPTION',
  PHANTOM_SHARE: 'PHANTOM_SHARE',
  WARRANT: 'WARRANT',
  ROLLBACK: 'ROLLBACK',
  DRAFT: 'DRAFT',
  ADD_TENDER_OFFER: 'ADD_TENDER_OFFER',
  ADD_TENDER_OFFER_SHARES: 'ADD_TENDER_OFFER_SHARES',
  ADD_BOARD: 'ADD_BOARD',
  ADD_EMPLOYEES_PLAN: 'ADD_EMPLOYEES_PLAN',
  DIVIDENDS_DISTRIBUTION: 'DIVIDENDS_DISTRIBUTION',
  RECLASSIFICATION: 'RECLASSIFICATION',

  ADD_PARTNER_FILENAME: 'Añadir Socio',
  ADD_PARTNERS_FILENAME: 'Añadir Socios',
  ADD_BENEFICIARY_FILENAME: 'Añadir Beneficiario',
  ADD_INVESTOR_FILENAME: 'Añadir Inversor',
  ADD_BENEFICIARY_DRAFT_FILENAME: 'Añadir Beneficiario Borrador',
  ADD_PARTNER_DRAFT_FILENAME: 'Añadir Inversor',
  CONSTITUTION_FILENAME: 'Constitución de Sociedad',
  CAPITAL_INCREASE_FILENAME: 'Aumento de Capital',
  CAPITAL_DECREASE_FILENAME: 'Reducción de Capital',
  SELL_PARTICIPATION_FILENAME: 'Compraventa',
  RENUMERATION_FILENAME: 'Renumeración',
  SPLIT_FILENAME: 'Split',
  ADD_HOLDING_CLASS_FILENAME: 'Añadir Clase',
  STOCK_OPTION_FILENAME: 'Plan de Stock Options',
  PHANTOM_SHARE_FILENAME: 'Plan de Phantom Shares',
  WARRANT_FILENAME: 'Plan de Warrants',
  ROLLBACK_FILENAME: 'Deshacer Operación',
  DRAFT_FILENAME: 'Añadir borrador',
  ADD_TENDER_OFFER_FILENAME: 'Oferta de Compra',
  ADD_TENDER_OFFER_SHARES_FILENAME: 'Oferta de Compra de Acciones',
  ADD_BOARD_FILENAME: 'Junta',
  ADD_EMPLOYEES_PLAN_FILENAME: 'Plan de Empleados',
  DIVIDENDS_DISTRIBUTION_FILENAME: 'Reparto de Dividendos',
  RECLASSIFICATION_FILENAME: 'Reclasificación',
};
