import LogoSttokBase64 from 'components/Logos/LogoSttokBase64';

export default function getTenderOfferShareTemplateBody(language, offerType) {
  const saleTemplateBodys = {
    es: `<!DOCTYPE html>
    <html lang="en">
      <head>
        <meta charset="UTF-8"/>
        <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        <title>Template</title>
      </head>
      <style>
        @import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;600&display=swap");
        @media (max-width: 768px) {
          .main {
              padding: 0 !important;
          }
          .email-body {
            padding: 1.75rem 20px !important;
            width: 85% !important;
          }
        }
      </style>
      <body>
        <div class="main" style="padding: 24px">
          <div style="
              background: #f5f6fa;
              font-family: Roboto;
              font-size: 14px;
              line-height: 22px;
              font-weight: 400;
              color: #8094ae;
              width: 100%;
              text-align: center;
            ">
            <a href="https://www.sttok.com">
              <img src="${LogoSttokBase64}" alt="logo" style="height: 40px; margin-top: 2.75rem"/>
            </a>
            <p style="
                font-size: 13px;
                color: #6576ff;
                padding-top: 6px;
                margin-top: 0;
                padding-bottom: 24px;
              ">
              Gestión de Socios
            </p>
            <div class="email-body" style="
                width: 96%;
                max-width: 620px;
                text-align: left;
                margin: 0 auto;
                padding: 1.75rem 2.75rem;
                background: #ffffff;
              ">
              <h3 style="
                  font-family: Nunito, sans-serif;
                  font-size: 18px;
                  color: #6576ff;
                  font-weight: 600;
                  margin: 10px 0;
                  padding-bottom: 15px;
                  line-height: 1.4;
                  text-align: center;
                ">
                Oferta de venta
              </h3>

              <p style="text-align: justify; font-weight: 500">
                  Hola socio
              </p>
              <p style="text-align: justify">
                  Como socio/a de la sociedad, te comunicamos que tienes opción de compra de nuevas participaciones de la sociedad. Puedes ver el detalle de la oferta accediendo a Sttok.
              </p>
              <a href="" style="
                  background: #6576ff;
                  border-radius: 4px;
                  border: none;
                  color: #ffffff !important;
                  display: block;
                  font-size: 13px;
                  font-weight: 700;
                  line-height: 44px;
                  text-align: center;
                  text-decoration: none;
                  text-transform: uppercase;
                  padding: 0 30px;
                  margin: 40px auto 30px;
            width: fit-content;
                ">
                Ir al perfil del socio
              </a>
              <p style="text-align: justify">
                Saludos cordiales,
              </p>
                <p style="text-align: justify; font-weight: 600">
                Sttok
              </p>
            </div>

              <p style="padding-bottom: 2.75rem; padding-top: 2.75rem; margin: 0">
              Correo enviado por
                  <a style="color: #6576ff; text-decoration: none" href="https://sttok.com/">sttok.com</a> Puede contactarnos en <a href="mailto:info@sttok.com" target="_blank">info@sttok.com</a>
            </p>
          </div>
        </div>
      </body>
    </html>`,
    en: `<!DOCTYPE html>
    <html lang="en">
      <head>
        <meta charset="UTF-8"/>
        <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        <title>Template</title>
      </head>
      <style>
        @import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;600&display=swap");
        @media (max-width: 768px) {
          .main {
              padding: 0 !important;
          }
          .email-body {
            padding: 1.75rem 20px !important;
            width: 85% !important;
          }
        }
      </style>
      <body>
        <div class="main" style="padding: 24px">
          <div style="
              background: #f5f6fa;
              font-family: Roboto;
              font-size: 14px;
              line-height: 22px;
              font-weight: 400;
              color: #8094ae;
              width: 100%;
              text-align: center;
            ">
            <a href="https://www.sttok.com">
              <img src="${LogoSttokBase64}" alt="logo" style="height: 40px; margin-top: 2.75rem"/>
            </a>
            <p style="
                font-size: 13px;
                color: #6576ff;
                padding-top: 6px;
                margin-top: 0;
                padding-bottom: 24px;
              ">
              Partner Management
            </p>
            <div class="email-body" style="
                width: 96%;
                max-width: 620px;
                text-align: left;
                margin: 0 auto;
                padding: 1.75rem 2.75rem;
                background: #ffffff;
              ">
              <h3 style="
                  font-family: Nunito, sans-serif;
                  font-size: 18px;
                  color: #6576ff;
                  font-weight: 600;
                  margin: 10px 0;
                  padding-bottom: 15px;
                  line-height: 1.4;
                  text-align: center;
                ">
                Sale Offer
              </h3>

              <p style="text-align: justify; font-weight: 500">
                  Hello partner
              </p>
              <p style="text-align: justify">
                  As a partner of the society, we inform you that you have the option to purchase new shares in the society. You can see the details of the offer by accessing Sttok.
              </p>
              <a href="" style="
                  background: #6576ff;
                  border-radius: 4px;
                  border: none;
                  color: #ffffff !important;
                  display: block;
                  font-size: 13px;
                  font-weight: 700;
                  line-height: 44px;
                  text-align: center;
                  text-decoration: none;
                  text-transform: uppercase;
                  padding: 0 30px;
                  margin: 40px auto 30px;
            width: fit-content;
                ">
                Go to partner profile
              </a>
              <p style="text-align: justify">
                Greetings,
              </p>
                <p style="text-align: justify; font-weight: 600">
                Sttok
              </p>
            </div>

              <p style="padding-bottom: 2.75rem; padding-top: 2.75rem; margin: 0">
              Mail sent by
                  <a style="color: #6576ff; text-decoration: none" href="https://sttok.com/">sttok.com</a> You can contact at <a href="mailto:info@sttok.com" target="_blank">info@sttok.com</a>
            </p>
          </div>
        </div>
      </body>
    </html>`,
    fr: `<!DOCTYPE html>
    <html lang="en">
      <head>
        <meta charset="UTF-8"/>
        <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        <title>Template</title>
      </head>
      <style>
        @import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;600&display=swap");
        @media (max-width: 768px) {
          .main {
              padding: 0 !important;
          }
          .email-body {
            padding: 1.75rem 20px !important;
            width: 85% !important;
          }
        }
      </style>
      <body>
        <div class="main" style="padding: 24px">
          <div style="
              background: #f5f6fa;
              font-family: Roboto;
              font-size: 14px;
              line-height: 22px;
              font-weight: 400;
              color: #8094ae;
              width: 100%;
              text-align: center;
            ">
            <a href="https://www.sttok.com">
              <img src="${LogoSttokBase64}" alt="logo" style="height: 40px; margin-top: 2.75rem"/>
            </a>
            <p style="
                font-size: 13px;
                color: #6576ff;
                padding-top: 6px;
                margin-top: 0;
                padding-bottom: 24px;
              ">
              Gestion des Partenaires
            </p>
            <div class="email-body" style="
                width: 96%;
                max-width: 620px;
                text-align: left;
                margin: 0 auto;
                padding: 1.75rem 2.75rem;
                background: #ffffff;
              ">
              <h3 style="
                  font-family: Nunito, sans-serif;
                  font-size: 18px;
                  color: #6576ff;
                  font-weight: 600;
                  margin: 10px 0;
                  padding-bottom: 15px;
                  line-height: 1.4;
                  text-align: center;
                ">
                Offre de Vente
              </h3>

              <p style="text-align: justify; font-weight: 500">
                  Bonjour partenaire
              </p>
              <p style="text-align: justify">
                  En tant que partenaire de la société, nous vous informons que vous avez la possibilité d'acheter de nouvelles actions de la société. Vous pouvez voir les détails de l'offre en accédant à Sttok.
              </p>
              <a href="" style="
                  background: #6576ff;
                  border-radius: 4px;
                  border: none;
                  color: #ffffff !important;
                  display: block;
                  font-size: 13px;
                  font-weight: 700;
                  line-height: 44px;
                  text-align: center;
                  text-decoration: none;
                  text-transform: uppercase;
                  padding: 0 30px;
                  margin: 40px auto 30px;
            width: fit-content;
                ">
                Accéder au profil du partenaire
              </a>
              <p style="text-align: justify">
                Cordialement,
              </p>
                <p style="text-align: justify; font-weight: 600">
                Sttok
              </p>
            </div>

              <p style="padding-bottom: 2.75rem; padding-top: 2.75rem; margin: 0">
              Courrier envoyé par
                  <a style="color: #6576ff; text-decoration: none" href="https://sttok.com/">sttok.com</a> Vous pouvez contacter au <a href="mailto:info@sttok.com" target="_blank">info@sttok.com</a>
            </p>
          </div>
        </div>
      </body>
    </html>`,
  };

  const buyTemplateBodys = {
    es: `<!DOCTYPE html>
    <html lang="en">
      <head>
        <meta charset="UTF-8"/>
        <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        <title>Template</title>
      </head>
      <style>
        @import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;600&display=swap");
        @media (max-width: 768px) {
          .main {
              padding: 0 !important;
          }
          .email-body {
            padding: 1.75rem 20px !important;
            width: 85% !important;
          }
        }
      </style>
      <body>
        <div class="main" style="padding: 24px">
          <div style="
              background: #f5f6fa;
              font-family: Roboto;
              font-size: 14px;
              line-height: 22px;
              font-weight: 400;
              color: #8094ae;
              width: 100%;
              text-align: center;
            ">
            <a href="https://www.sttok.com">
              <img src="${LogoSttokBase64}" alt="logo" style="height: 40px; margin-top: 2.75rem"/>
            </a>
            <p style="
                font-size: 13px;
                color: #6576ff;
                padding-top: 6px;
                margin-top: 0;
                padding-bottom: 24px;
              ">
              Gestión de Socios
            </p>
            <div class="email-body" style="
                width: 96%;
                max-width: 620px;
                text-align: left;
                margin: 0 auto;
                padding: 1.75rem 2.75rem;
                background: #ffffff;
              ">
              <h3 style="
                  font-family: Nunito, sans-serif;
                  font-size: 18px;
                  color: #6576ff;
                  font-weight: 600;
                  margin: 10px 0;
                  padding-bottom: 15px;
                  line-height: 1.4;
                  text-align: center;
                ">
                Oferta de compra
              </h3>

              <p style="text-align: justify; font-weight: 500">
                  Hola socio
              </p>
              <p style="text-align: justify">
                  Como socio/a de la sociedad, te comunicamos la opción de venta de tus participaciones. Puedes ver el detalle de la oferta accediendo a Sttok.
              </p>
              <a href="" style="
                  background: #6576ff;
                  border-radius: 4px;
                  border: none;
                  color: #ffffff !important;
                  display: block;
                  font-size: 13px;
                  font-weight: 700;
                  line-height: 44px;
                  text-align: center;
                  text-decoration: none;
                  text-transform: uppercase;
                  padding: 0 30px;
                  margin: 40px auto 30px;
            width: fit-content;
                ">
                Ir al perfil del socio
              </a>
              <p style="text-align: justify">
                Saludos cordiales,
              </p>
                <p style="text-align: justify; font-weight: 600">
                Sttok
              </p>
            </div>

              <p style="padding-bottom: 2.75rem; padding-top: 2.75rem; margin: 0">
              Correo enviado por
                  <a style="color: #6576ff; text-decoration: none" href="https://sttok.com/">sttok.com</a> Puede contactarnos en <a href="mailto:info@sttok.com" target="_blank">info@sttok.com</a>
            </p>
          </div>
        </div>
      </body>
    </html>`,
    en: `<!DOCTYPE html>
    <html lang="en">
      <head>
        <meta charset="UTF-8"/>
        <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        <title>Template</title>
      </head>
      <style>
        @import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;600&display=swap");
        @media (max-width: 768px) {
          .main {
              padding: 0 !important;
          }
          .email-body {
            padding: 1.75rem 20px !important;
            width: 85% !important;
          }
        }
      </style>
      <body>
        <div class="main" style="padding: 24px">
          <div style="
              background: #f5f6fa;
              font-family: Roboto;
              font-size: 14px;
              line-height: 22px;
              font-weight: 400;
              color: #8094ae;
              width: 100%;
              text-align: center;
            ">
            <a href="https://www.sttok.com">
              <img src="${LogoSttokBase64}" alt="logo" style="height: 40px; margin-top: 2.75rem"/>
            </a>
            <p style="
                font-size: 13px;
                color: #6576ff;
                padding-top: 6px;
                margin-top: 0;
                padding-bottom: 24px;
              ">
              Partner Management
            </p>
            <div class="email-body" style="
                width: 96%;
                max-width: 620px;
                text-align: left;
                margin: 0 auto;
                padding: 1.75rem 2.75rem;
                background: #ffffff;
              ">
              <h3 style="
                  font-family: Nunito, sans-serif;
                  font-size: 18px;
                  color: #6576ff;
                  font-weight: 600;
                  margin: 10px 0;
                  padding-bottom: 15px;
                  line-height: 1.4;
                  text-align: center;
                ">
                Buy Offer
              </h3>

              <p style="text-align: justify; font-weight: 500">
                  Hello partner
              </p>
              <p style="text-align: justify">
                  As a partner of the company, we inform you of the option to sell your shares. You can see the details of the offer by accessing Sttok.
              </p>
              <a href="" style="
                  background: #6576ff;
                  border-radius: 4px;
                  border: none;
                  color: #ffffff !important;
                  display: block;
                  font-size: 13px;
                  font-weight: 700;
                  line-height: 44px;
                  text-align: center;
                  text-decoration: none;
                  text-transform: uppercase;
                  padding: 0 30px;
                  margin: 40px auto 30px;
            width: fit-content;
                ">
                Go to partner profile
              </a>
              <p style="text-align: justify">
                Greetings,
              </p>
                <p style="text-align: justify; font-weight: 600">
                Sttok
              </p>
            </div>

              <p style="padding-bottom: 2.75rem; padding-top: 2.75rem; margin: 0">
              Mail sent by
                  <a style="color: #6576ff; text-decoration: none" href="https://sttok.com/">sttok.com</a> You can contact at <a href="mailto:info@sttok.com" target="_blank">info@sttok.com</a>
            </p>
          </div>
        </div>
      </body>
    </html>`,
    fr: `<!DOCTYPE html>
    <html lang="en">
      <head>
        <meta charset="UTF-8"/>
        <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        <title>Template</title>
      </head>
      <style>
        @import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;600&display=swap");
        @media (max-width: 768px) {
          .main {
              padding: 0 !important;
          }
          .email-body {
            padding: 1.75rem 20px !important;
            width: 85% !important;
          }
        }
      </style>
      <body>
        <div class="main" style="padding: 24px">
          <div style="
              background: #f5f6fa;
              font-family: Roboto;
              font-size: 14px;
              line-height: 22px;
              font-weight: 400;
              color: #8094ae;
              width: 100%;
              text-align: center;
            ">
            <a href="https://www.sttok.com">
              <img src="${LogoSttokBase64}" alt="logo" style="height: 40px; margin-top: 2.75rem"/>
            </a>
            <p style="
                font-size: 13px;
                color: #6576ff;
                padding-top: 6px;
                margin-top: 0;
                padding-bottom: 24px;
              ">
              Gestion des Partenaires
            </p>
            <div class="email-body" style="
                width: 96%;
                max-width: 620px;
                text-align: left;
                margin: 0 auto;
                padding: 1.75rem 2.75rem;
                background: #ffffff;
              ">
              <h3 style="
                  font-family: Nunito, sans-serif;
                  font-size: 18px;
                  color: #6576ff;
                  font-weight: 600;
                  margin: 10px 0;
                  padding-bottom: 15px;
                  line-height: 1.4;
                  text-align: center;
                ">
                Offre d'achat
              </h3>

              <p style="text-align: justify; font-weight: 500">
                  Bonjour partenaire
              </p>
              <p style="text-align: justify">
                  En tant qu'associé de la société, nous vous informons de la possibilité de vendre vos actions. Vous pouvez voir les détails de l'offre en accédant à Sttok.
              </p>
              <a href="" style="
                  background: #6576ff;
                  border-radius: 4px;
                  border: none;
                  color: #ffffff !important;
                  display: block;
                  font-size: 13px;
                  font-weight: 700;
                  line-height: 44px;
                  text-align: center;
                  text-decoration: none;
                  text-transform: uppercase;
                  padding: 0 30px;
                  margin: 40px auto 30px;
            width: fit-content;
                ">
                Accéder au profil du partenaire
              </a>
              <p style="text-align: justify">
                Cordialement,
              </p>
                <p style="text-align: justify; font-weight: 600">
                Sttok
              </p>
            </div>

              <p style="padding-bottom: 2.75rem; padding-top: 2.75rem; margin: 0">
              Courrier envoyé par
                  <a style="color: #6576ff; text-decoration: none" href="https://sttok.com/">sttok.com</a> Vous pouvez contacter au <a href="mailto:info@sttok.com" target="_blank">info@sttok.com</a>
            </p>
          </div>
        </div>
      </body>
    </html>`,
  };
  const selectedTemplate =
    offerType === 'BUY' ? buyTemplateBodys : saleTemplateBodys;
  return selectedTemplate[language];
}
