/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { setMenu } from 'redux/actions/menuActions';
import { getBoards, updateBoard } from 'redux/actions/boardActions';
import { getActualSociety } from 'utils/filters';

import menuTypes from 'constants/menuTypes';
import BoardCardPartner from 'components/BoardCardPartner';
import CustomLoading from 'components/CustomLoading';
import boardStatus from 'constants/boardStatus';
import BoardBanner from './BoardBanner';

const BoardsPartnerList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { societyId } = useParams();

  const { actualSociety, user, boards } = useSelector((state) => ({
    actualSociety: state.society?.actualSociety,
    user: state.user,
    boards: state.boards.sort((a, b) => new Date(b.date) - new Date(a.date)),
  }));

  const [boardsUpdated, setBoardsUpdated] = useState(false);

  const setCurrentMenu = () => {
    dispatch(
      setMenu({
        type: menuTypes.EXTENDED,
        societyId: actualSociety?.['_id'],
        societyName: actualSociety?.name,
      })
    );
  };

  const getCurrentValues = async () => {
    dispatch(getBoards(societyId));
  };

  const updateBoardStatusIfOutdated = async (boards) => {
    if (!boards.length) return;

    const today = new Date();

    boards.forEach((board) => {
      if (
        today > new Date(board.date) &&
        board.status !== boardStatus.CELEBRATED.value
      ) {
        dispatch(
          updateBoard(board['_id'], {
            status: boardStatus.CELEBRATED.value,
          })
        );
      }
    });
    setBoardsUpdated(true);
  };

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId) {
      getActualSociety(user, societyId);
    }
  }, [actualSociety, user, societyId]);

  useEffect(() => {
    if (actualSociety) {
      setCurrentMenu();
      getCurrentValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety]);

  useEffect(() => {
    if (boards.length && !boardsUpdated) {
      updateBoardStatusIfOutdated(boards);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boards]);

  return actualSociety ? (
    <>
      <div className="nk-content-body">
        <div className="nk-content-wrap">
          <div className="nk-block-head nk-block-head-lg">
            <div className="nk-block-head-sub">
              <span>{actualSociety.name}</span>
            </div>
            <div className="nk-block-between-md g-4">
              <div className="nk-block-head-content">
                <h2 className="nk-block-title fw-normal">
                  {t('Junta Socios')}
                </h2>
              </div>
            </div>
          </div>
          {boards.length > 0 &&
            boards.map((board) => (
              <BoardCardPartner
                key={board?.['_id']}
                data={{
                  ...board,
                  society: actualSociety,
                  societyId: actualSociety?.['_id'],
                  user,
                }}
              />
            ))}

          <BoardBanner />
        </div>
      </div>
    </>
  ) : (
    <CustomLoading />
  );
};

export default BoardsPartnerList;
