import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import store from 'redux/store';
import { setModal } from 'redux/actions/modalActions';

import CustomLoading from 'components/CustomLoading';

import { Board, Participant, Society } from 'types';

import Swal from 'sweetalert2';
import RetryAnnouncementModal from './RetryAnnouncementModal';

type EditRecipientModalProps = {
  currentBoard: Board;
  actualSociety: Society;
  recipient: Participant;
  announcementTemplateDoc: any;
  recipients: Participant[];
};

const EditRecipientModal: FC<EditRecipientModalProps> = ({
  currentBoard,
  actualSociety,
  recipient,
  announcementTemplateDoc,
  recipients,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const user: any = useSelector((state: any) => state.user);

  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSaveRecipient = async (
    event: React.FormEvent<HTMLFormElement>
  ): Promise<any> => {
    event.preventDefault();
    try {
      // Update participant with new name and email
      const newRecipient = { ...recipient, name, email };
      const newRecipients = recipients.map((recipient) =>
        recipient._id === newRecipient._id ? newRecipient : recipient
      );

      Swal.fire({
        icon: 'success',
        title: `<h4 class="nk-modal-title">${t(
          'ParticipantUpdatedSuccessfully'
        )}<br></h4>`,
        confirmButtonText: t('OK'),
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonColor: '#6576FF',
      });
      store.dispatch(setModal(null));
      dispatch(
        setModal(
          <RetryAnnouncementModal
            currentBoard={currentBoard}
            actualSociety={actualSociety}
            announcementTemplateDoc={announcementTemplateDoc}
            recipients={newRecipients}
          />
        )
      );
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleClose = (): any => {
    store.dispatch(setModal(null));
    dispatch(
      setModal(
        <RetryAnnouncementModal
          currentBoard={currentBoard}
          actualSociety={actualSociety}
          announcementTemplateDoc={announcementTemplateDoc}
          recipients={recipients}
        />
      )
    );
  };

  useEffect(() => {
    if (recipient) {
      setName(recipient?.name);
      setEmail(recipient?.email);
    }
  }, [recipient]);

  return (
    <>
      <Modal.Header>
        <h5>{t('UpdateParticipant')}</h5>
        <a className="close" onClick={handleClose}>
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>

      <Modal.Body>
        <form className="form-validate is-alter" onSubmit={handleSaveRecipient}>
          <div className="form-group">
            <label className="form-label" htmlFor="name">
              {t('Name')}
            </label>
            <div className="form-control-wrap">
              <input
                type="text"
                className="form-control"
                value={name}
                onChange={(event) => setName(event.target.value)}
              />
            </div>
          </div>
          <div className="form-group">
            <label className="form-label" htmlFor="email">
              {t('Email')}
            </label>
            <div className="form-control-wrap">
              <input
                type="text"
                className="form-control"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
            </div>
          </div>

          <button
            type="submit"
            className="btn btn-lg btn-primary"
            disabled={!name?.length || !email?.length}
          >
            {t('Save')}
          </button>
        </form>
      </Modal.Body>
      {isLoading && <CustomLoading />}
    </>
  );
};

export default EditRecipientModal;
