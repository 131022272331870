/* eslint-disable no-underscore-dangle */
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import store from 'redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import i18n from 'i18n/config';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

import { setModal } from 'redux/actions/modalActions';
import { setPreview } from 'redux/actions/previewActions';

import BoardAnnouncementPreview from 'components/BoardAnnouncementPreview';
import { updateDocumentWithValues } from 'components/EditorWYSIWYG/helpers';
import tags from 'components/EditorWYSIWYG/tags';
import EditAnnouncementModal from 'components/Modals/EditAnnouncement';
import DocumentsGenerator from 'components/Documents/Creators/DocumentsGenerator';
import AddBoard from 'components/Modals/AddBoard';
import TableLoader from 'components/Tables/TableLoader';
import AnnouncementTemplateViewer from 'components/Modals/AnnouncementTemplateViewer';
import MenuDots from 'components/MenuDots';
import menuOptions from 'components/BoardDocuments/menuOptions';

import actionTypes from 'constants/actionTypes';
import sizes from 'constants/sizes';
import voteTypes from 'constants/voteTypes';
import attendanceTypes from 'constants/attendanceTypes';
import votePeriods from 'constants/votePeriods';
import documentTypes from 'constants/documentTypes';
import { getDocumentsByReference } from 'redux/actions/documentActions';
import boardTypes from 'constants/boardTypes';
import boardDocumentSections from 'constants/boardDocumentSections';
import fileTypes from 'constants/fileTypes';
import { formatDateToTime } from 'constants/formats';

import downloadDocument, { getSingleDocument } from 'utils/downloadDocument';

import DocumentModal from 'pages/Documents/documentModal';

import pdfIcon from '../../assets/images/file-types/pdf.svg';
import docIcon from '../../assets/images/file-types/doc.svg';
import xlsIcon from '../../assets/images/file-types/xls.svg';
import pptIcon from '../../assets/images/file-types/ppt.svg';
import defaultIcon from '../../assets/images/file-types/default.svg';
import templateIcon from '../../assets/images/file-types/templates.svg';

const BoardAnnouncement = ({ board }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const printRef = useRef();

  const { user, actualSociety, societyDocuments, preview } = useSelector(
    (state) => ({
      user: state?.user,
      actualSociety: state.society?.actualSociety,
      societyDocuments: state.documents,
      preview: state.preview,
    })
  );
  const isDemo = useSelector((state) => state.society?.role?.isDemo);

  const {
    date,
    place,
    announcement,
    announcementComm,
    announcementTemplate,
    announcementDocument,
    attachedDocuments,
    attendanceType,
    votePeriod,
    boardType,
  } = board;

  const [announcementTemplateData, setAnnouncementTemplateData] = useState();
  const [announcementDoc, setAnnouncementDoc] = useState();
  const [announcementUrl, setAnnouncementUrl] = useState();
  const [isTemplate, setIsTemplate] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [boardDocs, setBoardDocs] = useState([]);
  const [currentMenuOptions, setCurrentMenuOptions] = useState(
    menuOptions(i18n)
  );

  const [printOption, setPrintOption] = useState();

  const generateAnnouncementTemplatePreview = async () => {
    try {
      const documentUpdated = updateDocumentWithValues(
        announcementTemplateData?.editorModel,
        tags({
          society: actualSociety,
          board,
        })
      );
      const dataToDocument = (
        <DocumentsGenerator
          invitationModel={documentUpdated?.html}
          hasConsolidationCalendar={false}
          consolidationCalendar={{}}
        />
      );

      await store.dispatch(
        setPreview({
          name: announcementTemplateData.name,
          bdDocument: announcementTemplateData,
          document: dataToDocument,
          documentModel: documentUpdated?.html,
          template: announcementTemplateData?.['_id'],
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getDocumentIcon = (document) => {
    const { fileType } = document;

    switch (fileType) {
      case fileTypes.PDF:
        return pdfIcon;

      case fileTypes.DOC:
      case fileTypes.DOCX:
        return docIcon;

      case fileTypes.XLS:
      case fileTypes.XLSX:
        return xlsIcon;

      case fileTypes.PPT:
      case fileTypes.PPTX:
        return pptIcon;

      case fileTypes.TEMPLATE:
        return templateIcon;

      default:
        return defaultIcon;
    }
  };

  const fetchDocument = async (docId) => {
    try {
      const fileBlob = await getSingleDocument(docId);
      const pdfUrl = URL.createObjectURL(fileBlob);
      setAnnouncementUrl(pdfUrl);
      setIsLoadingData(false);
    } catch (error) {
      console.log('Error fetching data:', error);
    }
  };

  useEffect(() => {
    setCurrentMenuOptions(menuOptions(i18n));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  useEffect(() => {
    if (actualSociety) {
      dispatch(getDocumentsByReference({ societyId: actualSociety['_id'] }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety]);

  useEffect(() => {
    if (societyDocuments.length) {
      const docs = societyDocuments.filter(
        (doc) =>
          doc?.category !== documentTypes.DELETED &&
          attachedDocuments?.includes(doc['_id'])
      );
      setBoardDocs(docs);
      setAnnouncementTemplateData(
        societyDocuments.find((doc) => doc['_id'] === announcementTemplate)
      );
      const document = societyDocuments.find(
        (doc) => doc['_id'] === announcementDocument
      );
      if (document) {
        setPrintOption({
          icon: 'ni-printer',
          text: i18n.t('Print'),
          print: true,
          content: printRef.current,
          documentTitle: document?.name,
          disabled: false,
        });
        setAnnouncementDoc(document);
        if (document.subcategory === documentTypes.BOARDS) {
          fetchDocument(document['_id']);
          setIsTemplate(false);
        } else {
          setIsTemplate(true);
        }
      } else setIsTemplate(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [societyDocuments]);

  useEffect(() => {
    if (isTemplate && actualSociety && announcementTemplateData) {
      generateAnnouncementTemplatePreview();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [announcementTemplateData, isTemplate]);

  useEffect(() => {
    if (preview) {
      setIsLoadingData(false);
    }
  }, [preview]);

  return (
    <>
      <div className="nk-block pt-4">
        <div className="row">
          <div className="col-xl-8">
            <div className="card card-bordered">
              <div className="card-inner-group">
                <div className="card-inner">
                  <div className="row sp-plan-head">
                    <h6 className="title col-6">{t('Information')}</h6>
                    <div className="col-6 text-right">
                      <button
                        type="button"
                        className="link text-primary"
                        onClick={() =>
                          dispatch(
                            setModal(
                              <AddBoard
                                action={actionTypes.EDIT}
                                board={board}
                              />
                            )
                          )
                        }
                        disabled={isDemo}
                      >
                        <span className="title">{t('Edit')}</span>
                      </button>
                    </div>
                  </div>

                  <div className="sp-plan-desc sp-plan-desc-mb">
                    <ul className="row gx-1">
                      <li className="col-6 col-lg-4">
                        <p>
                          <span className="text-soft">{t('Date')}</span>{' '}
                          {format(new Date(date), 'dd/MM/yyyy')}
                        </p>
                      </li>
                      <li className="col-6 col-lg-4">
                        <p>
                          <span className="text-soft">{t('StartTime')}</span>{' '}
                          {formatDateToTime(date)}
                        </p>
                      </li>

                      {attendanceType === attendanceTypes.FACE_TO_FACE.value ||
                      attendanceType === attendanceTypes.HYBRID.value ? (
                        <li className="col-6 col-lg-4">
                          <p>
                            <span className="text-soft">
                              {t('PlaceOfCelebration')}
                            </span>{' '}
                            <div
                              id="PlaceOfCelebration"
                              className="w-150px d-block text-ellipsis"
                            >
                              {place || '-'}
                            </div>
                            <Tooltip
                              anchorId="PlaceOfCelebration"
                              place="bottom"
                              style={{
                                zIndex: 9999,
                              }}
                            >
                              {place}
                            </Tooltip>
                          </p>
                        </li>
                      ) : (
                        <></>
                      )}
                      <li className="col-6 col-lg-4">
                        <p>
                          <span className="text-soft">
                            {t('AssistanceForm')}
                          </span>{' '}
                          <div
                            id="AssistanceForm"
                            className="w-150px d-block text-ellipsis"
                          >
                            {attendanceTypes?.[attendanceType]?.text || '-'}
                          </div>
                          <Tooltip
                            anchorId="AssistanceForm"
                            place="bottom"
                            style={{
                              zIndex: 9999,
                            }}
                          >
                            {attendanceTypes?.[attendanceType]?.text}
                          </Tooltip>
                        </p>
                      </li>
                      <li className="col-6 col-lg-4">
                        <p>
                          <span className="text-soft">{t('VotingPeriod')}</span>{' '}
                          <div
                            id="VotingPeriod"
                            className="w-150px d-block text-ellipsis"
                          >
                            {votePeriods.find(
                              (period) => period.value === votePeriod
                            )?.text || '-'}
                          </div>
                          <Tooltip
                            anchorId="VotingPeriod"
                            place="bottom"
                            style={{
                              zIndex: 9999,
                            }}
                          >
                            {
                              votePeriods.find(
                                (period) => period.value === votePeriod
                              )?.text
                            }
                          </Tooltip>
                        </p>
                      </li>
                      <li className="col-6 col-lg-4">
                        <p>
                          <span className="text-soft">{t('BoardType')}</span>{' '}
                          {boardTypes?.[boardType]?.text || '-'}
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>

                {board.orders.length ? (
                  <div className="card-inner">
                    <div className="row sp-plan-head">
                      <h6 className="title col-6">{t('Subjects')}</h6>
                      <div className="col-6 text-right">
                        <button
                          type="button"
                          className="link text-primary"
                          onClick={() =>
                            dispatch(
                              setModal(
                                <AddBoard
                                  action={actionTypes.EDIT}
                                  board={board}
                                />
                              )
                            )
                          }
                          disabled={isDemo}
                        >
                          <span className="title">{t('Edit')}</span>
                        </button>
                      </div>
                    </div>
                    <br />
                    {board.orders.map((order, index) => (
                      <div
                        className=" row sp-plan-head-group mb-1"
                        key={order._id}
                      >
                        <div className="col-md-9 nk-file-name">
                          <div className="nk-file-name-text">
                            <div className="row">
                              <div className="col-md-12">
                                <span
                                  id={`subject${index + 1}`}
                                  className="title pb-3"
                                >
                                  {`${index + 1}. ${order.subject}`}
                                </span>
                              </div>
                              <div className="col-md-12">
                                <span className="title font-italic text-soft">
                                  {order?.description || ''}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-3 text-right nk-file-name">
                          <div className="nk-file-name-text pr-0">
                            <span className="title">
                              {voteTypes?.[order.voteType]?.text || '-'}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <></>
                )}

                <div className="card-inner">
                  <div className="sp-plan-head-group">
                    <div className="row sp-plan-head mb-2">
                      <h6 className="col-md-6 title">{t('Announcement')}</h6>

                      <div className="col-md-6">
                        <ul className="nk-block-tools justify-content-md-end flex-wrap g-2">
                          <li>
                            <button
                              type="button"
                              className="link text-primary"
                              onClick={() =>
                                dispatch(
                                  setModal(
                                    <DocumentModal
                                      userId={user['_id']}
                                      societyId={actualSociety['_id']}
                                      boardId={board['_id']}
                                      boardSection={
                                        boardDocumentSections.ANNOUNCEMENT
                                      }
                                    />
                                  )
                                )
                              }
                              disabled={isDemo}
                            >
                              <span className="title">{t('Replace')}</span>
                            </button>
                          </li>
                          <li>
                            <Link
                              to="#announcement-box"
                              className="link text-primary"
                            >
                              <span className="title">{t('Edit')}</span>
                            </Link>
                          </li>
                        </ul>
                      </div>

                      <span className="col-12 ff-regular text-soft">
                        {t('AnnouncementText')}
                      </span>
                    </div>
                  </div>
                  <div className="nk-files nk-files-view-list">
                    <div className="nk-files-head">
                      <div className="nk-file-item">
                        <div className="nk-file-info">
                          <span> {t('Name')}</span>
                        </div>
                        <div className="nk-file-meta">
                          <span> {t('Date')}</span>
                        </div>
                        <div className="nk-file-members">
                          <span> {t('Link')}</span>
                        </div>
                        <div className="nk-file-members" />
                      </div>
                    </div>
                    <div className="nk-files-list">
                      {announcementDoc ? (
                        <div className="nk-file-item nk-file">
                          <div className="nk-file-info">
                            <div className="nk-file-title">
                              <div className="nk-file-icon">
                                <span className="nk-file-icon-type">
                                  <img
                                    src={getDocumentIcon(announcementDoc)}
                                    alt="icon-file"
                                  />
                                </span>
                              </div>
                              <div className="nk-file-name">
                                <div className="nk-file-name-text">
                                  <span className="title">
                                    {announcementDoc?.name}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="nk-file-meta">
                            <div className="tb-lead">
                              {announcementDoc?.date
                                ? format(
                                    new Date(announcementDoc?.date),
                                    'dd/MM/yy'
                                  )
                                : '-'}
                            </div>
                          </div>
                          <div className="nk-file-members">
                            <span
                              className="btn btn-icon btn-trigger"
                              onClick={async () =>
                                downloadDocument({
                                  documentId: announcementDoc?.['_id'],
                                  documentName: announcementDoc?.name,
                                })
                              }
                            >
                              <em className="icon ni ni-file-download" />
                            </span>
                          </div>
                          {!isDemo && (
                            <div className="nk-file-members">
                              <MenuDots
                                menuOptions={currentMenuOptions.map((option) =>
                                  option.index === 3
                                    ? { ...option, disabled: true }
                                    : option
                                )}
                                id={announcementDoc?.['_id']}
                                params={{
                                  documentId: announcementDoc?.['_id'],
                                  documentName: announcementDoc?.name,
                                  document: announcementDoc,
                                  society: actualSociety,
                                  user,
                                  boardId: board['_id'],
                                }}
                                direction="left"
                              />
                            </div>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>

                <div className="card-inner">
                  <div className="sp-plan-head-group">
                    <div className="row sp-plan-head mb-2">
                      <h6 className="col-md-6 title">
                        {t('DocumentsForPartners')}
                      </h6>

                      <div className="col-md-6">
                        <ul className="nk-block-tools justify-content-md-end flex-wrap g-2">
                          <li>
                            <button
                              type="button"
                              className="link text-primary"
                              onClick={() =>
                                dispatch(
                                  setModal(
                                    <DocumentModal
                                      userId={user['_id']}
                                      societyId={actualSociety['_id']}
                                      boardId={board['_id']}
                                    />
                                  )
                                )
                              }
                              disabled={isDemo}
                            >
                              <span className="title">{t('Add')}</span>
                            </button>
                          </li>
                        </ul>
                      </div>

                      <span className="col-12 ff-regular text-soft">
                        {t('DocumentsForPartnersText')}
                      </span>
                    </div>
                  </div>
                  <div className="nk-files nk-files-view-list">
                    <div className="nk-files-head">
                      <div className="nk-file-item">
                        <div className="nk-file-info">
                          <span> {t('Name')}</span>
                        </div>
                        <div className="nk-file-meta">
                          <span> {t('Date')}</span>
                        </div>
                        <div className="nk-file-members">
                          <span> {t('Link')}</span>
                        </div>
                        <div className="nk-file-members" />
                      </div>
                    </div>
                    <div className="nk-files-list">
                      {boardDocs.map((doc) => (
                        <div className="nk-file-item nk-file">
                          <div className="nk-file-info">
                            <div className="nk-file-title">
                              <div className="nk-file-icon">
                                <span className="nk-file-icon-type">
                                  <img
                                    src={getDocumentIcon(doc)}
                                    alt="icon-file"
                                  />
                                </span>
                              </div>
                              <div className="nk-file-name">
                                <div className="nk-file-name-text">
                                  <span className="title">{doc?.name}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="nk-file-meta">
                            <div className="tb-lead">
                              {doc.date
                                ? format(new Date(doc?.date), 'dd/MM/yy')
                                : '-'}
                            </div>
                          </div>
                          <div className="nk-file-members">
                            <span
                              className="btn btn-icon btn-trigger"
                              onClick={async () =>
                                downloadDocument({
                                  documentId: doc?.['_id'],
                                  documentName: doc?.name,
                                })
                              }
                            >
                              <em className="icon ni ni-file-download" />
                            </span>
                          </div>
                          {!isDemo && (
                            <div className="nk-file-members">
                              <MenuDots
                                menuOptions={currentMenuOptions.filter(
                                  (option) => option.index !== 1
                                )}
                                id={doc?.['_id']}
                                params={{
                                  documentId: doc?.['_id'],
                                  documentName: doc?.name,
                                  document: doc,
                                  society: actualSociety,
                                  user,
                                }}
                                direction="left"
                              />
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4">
            <div className="card card-bordered card-top d-none d-xl-block">
              <div className="nk-help-plain card-inner text-left">
                <div className="sp-plan-action card-inner">
                  <div className="card-title-group">
                    <div className="sp-plan-head">
                      <h6 className="title">
                        {t('SendState')}:{' '}
                        <span
                          className={`badge rounded-pill ${
                            announcementComm
                              ? 'bg-outline-success'
                              : 'bg-outline-warning'
                          }`}
                        >
                          {announcementComm ? t('NOTIFIED') : t('Pending')}
                        </span>{' '}
                      </h6>
                      <button
                        type="button"
                        className="link text-primary mb-2"
                        style={{ paddingLeft: 0 }}
                        onClick={() =>
                          dispatch(
                            setModal(
                              <AnnouncementTemplateViewer
                                board={board}
                                size={sizes.LG}
                              />
                            )
                          )
                        }
                      >
                        {t('ViewComm')}
                      </button>
                      <em className="icon ni ni-external" />
                      {announcementComm && (
                        <>
                          <Link
                            to={`/comunicaciones/${actualSociety['_id']}/${announcementComm}/certificacion`}
                            className={`${!announcementComm && 'is-disable'}`}
                          >
                            <span className="link text-primary">
                              {t('ViewSendCertificate')}
                            </span>{' '}
                          </Link>
                          <em className="icon ni ni-external" />
                        </>
                      )}

                      <h6 className="title" style={{ marginTop: '10px' }}>
                        {t('TextComm')}:{' '}
                      </h6>
                      <span
                        className="ff-regular text-soft d-block w-200px"
                        style={{ whiteSpace: 'pre-line' }}
                      >
                        {announcement}
                      </span>
                      {!announcementComm && (
                        <div className="sp-plan">
                          <button
                            type="button"
                            className="link text-primary"
                            style={{ paddingLeft: 0 }}
                            onClick={() =>
                              dispatch(
                                setModal(
                                  <EditAnnouncementModal board={board} />
                                )
                              )
                            }
                            disabled={isDemo}
                          >
                            <span className="title">{t('Edit')}</span>
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      {!isLoadingData && isTemplate && announcementTemplateData && preview ? (
        <div className="nk-block" id="announcement-box">
          <BoardAnnouncementPreview board={board} />
        </div>
      ) : (
        isLoadingData && (
          <div className="nk-block">
            <TableLoader rows={10} />
          </div>
        )
      )}
      {!isLoadingData && !isTemplate && announcementUrl ? (
        <div className="nk-block">
          <div className="row">
            <div className="col-12">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between position-relative">
                  <div className="nk-block-head-content">
                    <h5 className="title nk-block-title">Convocatoria:</h5>
                  </div>
                </div>
              </div>
              <div className="card card-bordered">
                <div className="card-inner p-0">
                  <iframe
                    src={announcementUrl}
                    title={announcementDoc?.name || 'PDF Viewer'}
                    width="100%"
                    height="600px"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        isLoadingData && (
          <div className="nk-block">
            <TableLoader rows={10} />
          </div>
        )
      )}
    </>
  );
};

export default BoardAnnouncement;
