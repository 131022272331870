/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import Record from 'typescript';
import WidgetChartDoughnut, {
  legendModes,
} from 'components/WidgetChartDoughnut';
import { dateFormat } from 'constants/formats';
import tenderOfferStatus from 'constants/tenderOfferStatus';
import { tenderOfferSharesTypes } from 'utils/tenderOfferSharesTypes';
import {
  formatCurrency,
  formatNumber,
  formatNumberDecimals,
} from 'utils/formats';
import { getPercentCategory, tenderOfferChartOptions } from 'utils/charts';
import { useSelector } from 'react-redux';
import './TenderOfferRow.scss';

interface TenderOfferSharesRowProps {
  data: Record<string, any>;
}

type StatusType = 'DRAFT' | 'ACTIVE' | 'CANCELED' | 'FINISHED';

const TenderOfferSharesRow: FC<TenderOfferSharesRowProps> = ({ data }) => {
  const {
    _id,
    name,
    details,
    status,
    startDate,
    endDate,
    price,
    sellerName,
    maxAmount,
    maxShares,
    maxPercentShares,
    society,
    paymentConditions,
    retentionPercent,
    sellerId,
    participants,
    type,
  } = data;
  const actualSociety = useSelector(
    (state: any) => state.society?.actualSociety
  );
  const { t } = useTranslation();

  const labels = [
    type === tenderOfferSharesTypes.BUY ? t('Bought') : t('Sold'),
    t('Available'),
  ];
  const colors = ['#798bff', '#7de1f8'];
  const chartOptions = tenderOfferChartOptions;
  const [chartLegend, setChartLegend] = useState<any[]>([]);
  const [chartData, setChartData] = useState<any>();

  const getStatus = (
    status: StatusType
  ): { label: string; value: string; style: string } => {
    const currentStatus = tenderOfferStatus[status];

    if (!currentStatus) {
      return {
        label: '',
        value: '',
        style: 'badge-light',
      };
    }

    return {
      label: currentStatus.label,
      value: currentStatus.value,
      style: currentStatus.style,
    };
  };

  useEffect(() => {
    if (!data) return;

    let participantsShares = 0;
    participants?.forEach((participant: any) => {
      participantsShares += participant.totalShares;
    });
    const sellerPartner = actualSociety?.partners?.find(
      (partner: any) => partner['_id'] === sellerId
    );
    const sellerShares = sellerPartner?.sharesCount?.actual;

    const totalOffer = sellerShares + participantsShares;

    const chartValues = [participantsShares, sellerShares];

    const legend = [
      {
        title: type === tenderOfferSharesTypes.BUY ? t('Bought') : t('Sold'),
        icon: 'ni-user',
        color: '#798bff',
        value: participantsShares,
        percent: getPercentCategory(totalOffer, participantsShares),
      },
      {
        title: t('Available'),
        icon: 'ni-user',
        color: '#7de1f8',
        value: sellerShares,
        percent: getPercentCategory(totalOffer, sellerShares),
      },
    ];
    setChartLegend(legend);
    setChartData({
      labels,
      datasets: [
        {
          fill: true,
          label: 'Porciento',
          data: chartValues,
          borderWidth: 2,
          borderColor: '#ffffff',
          backgroundColor: colors,
        },
      ],
    });
  }, [data]);

  return (
    <div className="nk-block">
      <div className="card card-bordered sp-plan">
        <div className="row no-gutters">
          <div className="col-md-8 d-flex flex-column justify-content-between">
            <div className="sp-plan-info card-inner">
              <div className="row gx-0 gy-3">
                <div className="col-xl-9 col-sm-8">
                  <div className="sp-plan-name">
                    <h6 className="title mb-3">
                      <Link
                        to={`/detalle-tender-offer-shares/${society}/${_id}`}
                      >
                        {name}&nbsp;
                        <span
                          className={`badge badge-pill ml-1 ${
                            getStatus(status).style
                          }`}
                        >
                          {t(getStatus(status).label)}
                        </span>
                      </Link>
                    </h6>
                    <p>
                      {t('PaymentConditions')}:&nbsp;
                      <span className="text-base">{paymentConditions}</span>
                    </p>
                    <p>
                      {t('Comments')}:&nbsp;
                      <span className="text-base">{details}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="sp-plan-desc card-inner">
              <ul className="row gx-1">
                <li className="col-6 col-lg-3">
                  <p>
                    <span className="text-soft">{t('StartDate')}</span>
                    {startDate && format(new Date(startDate), dateFormat)}
                  </p>
                </li>
                <li className="col-6 col-lg-3">
                  <p>
                    <span className="text-soft">{t('EndDate')}</span>
                    {endDate && format(new Date(endDate), dateFormat)}
                  </p>
                </li>
                <li className="col-6 col-lg-3">
                  <p>
                    <span className="text-soft">{t('PriceShareUnits')}</span>
                    {formatCurrency(price)}
                  </p>
                </li>
                <li className="col-6 col-lg-3">
                  <p>
                    <span className="text-soft">{t('Seller')}</span>
                    {sellerName}
                  </p>
                </li>
                <li className="col-6 col-lg-3">
                  <p>
                    <span className="text-soft">{t('MaximumAmount')}</span>
                    {maxAmount > 0 ? `${formatCurrency(maxAmount)}` : '-'}
                  </p>
                </li>
                <li className="col-6 col-lg-3">
                  <p>
                    <span className="text-soft">{t('MaximumUnits')}</span>
                    {maxShares > 0 ? `${formatNumber(maxShares)}` : '-'}
                  </p>
                </li>
                <li className="col-6 col-lg-3">
                  <p>
                    <span className="text-soft">{t('MaximumPercent')}</span>
                    {maxPercentShares > 0
                      ? `${formatNumber(maxPercentShares)}`
                      : '-'}
                  </p>
                </li>
                <li className="col-6 col-lg-3">
                  <p>
                    <span className="text-soft">% {t('Retention')}</span>
                    {formatNumberDecimals(retentionPercent || 0)}%
                  </p>
                </li>
              </ul>
            </div>
          </div>
          {chartData && chartOptions && chartLegend && (
            <div className="col-md-4">
              <div className="sp-plan-action card-inner p-1 tender-chart">
                <h6 className="text-center pt-3">{t('OfferEvolution')}</h6>
                <WidgetChartDoughnut
                  title={t('OfferEvolution')}
                  data={{ data: chartData, options: chartOptions }}
                  legendMode={legendModes.VERTICAL}
                  legend={chartLegend}
                  isEmbedded
                  subtitle
                  height
                  linkText
                  linkUrl
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TenderOfferSharesRow;
