/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import getTenderOfferRowData from './utils/getTenderOfferRowData';

import BeneficiaryTenderOffersTabRow from './components/BeneficiaryTenderOffersTabRow';

function BeneficiaryTenderOffersTab({ society, beneficiaryAllPlans }) {
  const { t } = useTranslation();

  const [rows, setRows] = useState([]);
  const [currentTenderOffers, setCurrentTenderOffers] = useState([]);

  useEffect(() => {
    if (currentTenderOffers?.length) {
      setRows(
        currentTenderOffers?.map(
          (
            { tenderOfferData: tenderOffer, beneficiaryData: beneficiary },
            index
          ) => {
            const {
              units,
              totalPriceUnits,
              soldUnits,
              totalPriceSoldUnits,
              netAmount,
              status,
            } = getTenderOfferRowData({ tenderOffer, beneficiary });

            return (
              <BeneficiaryTenderOffersTabRow
                index={index + 1}
                key={tenderOffer._id}
                rowKey={tenderOffer._id}
                units={units}
                totalPriceUnits={totalPriceUnits}
                soldUnits={soldUnits}
                totalPriceSoldUnits={totalPriceSoldUnits}
                status={status}
                societyId={society.societyId}
                tenderOfferId={tenderOffer._id}
                beneficiaryId={beneficiary._id}
                retentionPercent={tenderOffer?.retentionPercent || 0}
                netAmount={netAmount}
              />
            );
          }
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTenderOffers]);

  useEffect(() => {
    if (beneficiaryAllPlans.length) {
      const allTenderOffers = beneficiaryAllPlans
        .flatMap((b) => ({
          tenderOfferData: b.tenderOffers?.length ? b.tenderOffers[0] : null,
          beneficiaryData: b,
        }))
        ?.filter((b) => b.tenderOfferData);
      setCurrentTenderOffers(allTenderOffers);
    }
  }, [beneficiaryAllPlans]);

  return (
    <div className="card-inner">
      <div className="nk-block-head">
        <div className="nk-block-between g-3">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title">{t('Transactions')}</h4>
            <div className="nk-block-des">
              <p>{t('Detalle de las ofertas de compra y transacciones')}</p>
            </div>
          </div>
        </div>
      </div>

      {rows?.length ? (
        <>
          <div className="nk-block nk-block-lg">
            <div className="card card-bordered card-preview">
              <table className="table table-striped" id="consolidationTable">
                <thead>
                  <tr>
                    <th scope="col" className="d-none d-md-table-cell">
                      {t('Nr')}
                    </th>
                    <th scope="col" className="d-none d-md-table-cell">
                      {t('Unidades')}
                    </th>
                    <th scope="col" className="d-none d-md-table-cell">
                      {t('Importe Oferta')}
                    </th>
                    <th scope="col" className="text-center text-md-left">
                      {t('Importe Vendido')}
                    </th>
                    <th scope="col" className="d-none d-md-table-cell">
                      {t('% Retención')}
                    </th>
                    <th scope="col" className="d-none d-md-table-cell">
                      {t('Importe Neto')}
                    </th>
                    <th scope="col" className="text-center text-md-left">
                      {t('Unidades Vendidas')}
                    </th>
                    <th scope="col">{t('Estado')}</th>
                  </tr>
                </thead>
                <tbody>{rows}</tbody>
              </table>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

export default BeneficiaryTenderOffersTab;
