const getUnitsAndPercents = ({
  plansTypeShares,
  societyShares,
  newShares,
  newCN,
  totalCN,
  societySharesActual,
  plansTotalShares,
  draftView,
}) => {
  const {
    stockAssignedShares,
    stockUnassignedShares,
    phantomAssignedShares,
    phantomUnassignedShares,
  } = plansTypeShares;

  /* Convertible Notes */
  const inForceCNShares = totalCN;
  const currentCNShares = newCN;

  /* This is for Ampliacion, pending to validate Draft type */
  const currentDraftShares = draftView ? newShares : 0;

  const totalShares = draftView
    ? societySharesActual + currentDraftShares
    : societySharesActual;
  const totalNDShares = societySharesActual + currentDraftShares;
  const totalFDShares =
    societySharesActual +
    currentDraftShares +
    stockAssignedShares +
    phantomAssignedShares +
    inForceCNShares +
    currentCNShares;
  const totalPFDShares =
    societySharesActual +
    currentDraftShares +
    stockAssignedShares +
    stockUnassignedShares +
    phantomAssignedShares +
    phantomUnassignedShares +
    inForceCNShares +
    currentCNShares;

  const stockAssignedPercent =
    (plansTypeShares.stockAssignedShares * 100) / societyShares;
  const stockAssignedPercentDraft =
    (plansTypeShares.stockAssignedShares * 100) /
    (societyShares + newShares + newCN);

  const stockUnassignedPercent =
    (plansTypeShares.stockUnassignedShares * 100) / societyShares;
  const stockUnassignedPercentDraft =
    (plansTypeShares.stockUnassignedShares * 100) / totalPFDShares;

  const phantomAssignedPercent =
    (plansTypeShares.phantomAssignedShares * 100) / societyShares;
  const phantomAssignedPercentDraft =
    (plansTypeShares.phantomAssignedShares * 100) /
    (societyShares + newShares + newCN);

  const phantomUnassignedPercent =
    (plansTypeShares.phantomUnassignedShares * 100) / societyShares;
  const phantomUnassignedPercentDraft =
    (plansTypeShares.phantomUnassignedShares * 100) /
    (societyShares + newShares + newCN);

  const inForceCNPercent = (totalCN * 100) / societyShares;
  const inForceCNPercentDraft =
    (totalCN * 100) / (societyShares + newShares + newCN);

  const currentCNPercent = (newCN * 100) / societyShares;
  const currentCNPercentDraft =
    (newCN * 100) / (societyShares + newShares + newCN);

  const totalNDPercent = (totalNDShares * 100) / totalNDShares;
  const totalFDPercent = (totalFDShares * 100) / totalFDShares;
  const totalPFDPercent = (totalPFDShares * 100) / totalPFDShares;

  return {
    stockAssignedShares,
    stockAssignedPercent,
    stockAssignedPercentDraft,

    stockUnassignedShares,
    stockUnassignedPercent,
    stockUnassignedPercentDraft,

    phantomAssignedShares,
    phantomAssignedPercent,
    phantomAssignedPercentDraft,

    phantomUnassignedShares,
    phantomUnassignedPercent,
    phantomUnassignedPercentDraft,

    inForceCNShares,
    inForceCNPercent,
    inForceCNPercentDraft,

    currentCNShares,
    currentCNPercent,
    currentCNPercentDraft,

    totalShares,

    totalNDShares,
    totalNDPercent,

    totalFDShares,
    totalFDPercent,

    totalPFDShares,
    totalPFDPercent,
  };
};

export default getUnitsAndPercents;
