/* eslint-disable react/jsx-props-no-spreading */
import { format } from 'date-fns';
import React from 'react';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import operationTypesRealNames from 'constants/operationTypesRealNames';
import i18n from 'i18n/config';
import { formatNumber } from 'utils/formats';
import { dateFormat, numberFormat } from '../../constants/formats';

const WidgetList = ({
  title,
  subtitle,
  linkText,
  linkUrl,
  data,
  maxRows = 5,
}) => (
  <div className="col-12 col-xl-6 mb-4">
    <div className="card card-bordered h-100">
      <div className="card-inner border-bottom">
        <div className="card-title-group">
          <div className="card-title card-title-sm">
            <h6 className="title">{title}</h6>
          </div>
          <div className="card-tools">
            <Link to={linkUrl} className="link">
              {linkText}
            </Link>
          </div>
        </div>
      </div>
      <div className="card-inner">
        <div className="timeline">
          <h6 className="timeline-head">{subtitle}</h6>
          <ul className="timeline-list">
            {data?.slice(0, maxRows)?.map((item, index) => (
              <li className="timeline-item" key={uuidv4()}>
                <div
                  className={`timeline-status bg-primary ${
                    index === 0 && 'is-outline'
                  }`}
                />
                <div className="timeline-date ml-1">
                  {format(new Date(item?.date), dateFormat)}&nbsp;
                  <em className="icon ni ni-calendar" />
                </div>
                <div className="timeline-data">
                  <h6 className="timeline-title">
                    {i18n.t(item?.operationType)}
                  </h6>
                  <div className="timeline-des">
                    <p>
                      {i18n.t(item?.operationType)}{' '}
                      {i18n.t('OperationDescriptionWithValues', {
                        units: formatNumber(item?.shares),
                        price: formatNumber(item?.amount),
                      })}
                    </p>
                    <Link
                      to={`/detalle-transacciones/${item?.societyId}/${item?.operationId}`}
                      className="time text-primary"
                    >
                      Ver
                    </Link>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  </div>
);

export default WidgetList;
