/* eslint-disable no-case-declarations */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useMixpanel } from 'react-mixpanel-browser';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import eventTypes from 'constants/eventTypes';
import transactionTypes from 'constants/transactionTypes';
import { SELECT_OPTION } from 'constants/defaultConstants';

import store from 'redux/store';
import { addAlert } from 'redux/actions/alertActions';
import { setModal } from 'redux/actions/modalActions';
import { sellParticipationsAction } from 'redux/actions/modalsActions';

import {
  formatCurrencyDecimals,
  formatNumber,
  formatNumberDecimals,
  formatNumberDecimalsLong,
} from 'utils/formats';
import trackEvent from 'utils/trackEvent';
import tenderOfferSharesSignature from 'utils/tenderOfferSharesSignature';

import alertBodyTypes from 'components/Alert/alertBodyTypes';

import Swal from 'sweetalert2';
import TenderOfferBuySharesInvoice from './TenderOfferBuySharesInvoice';
import './TenderOfferBuyShares.scss';

const customOptionTypes = {
  BY_UNITS: 'BY_UNITS',
  BY_PRICE: 'BY_PRICE',
};

const TenderOfferBuyShares = ({ partner }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();

  const user = useSelector((state) => state?.user);
  const actualSociety = useSelector((state) => state.society?.actualSociety);
  const tenderOffersShares = useSelector((state) => state.tenderOffersShares);

  const [sellPartner, setSellPartner] = useState();
  const [holdingClass, setHoldingClass] = useState();
  const [selectedOfferOption, setSelectedOfferOption] = useState(SELECT_OPTION);
  const [tenderOffer, setTenderOffer] = useState();

  const [customOptionSelected, setCustomOptionSelected] = useState(null);

  const [customShares, setCustomShares] = useState(0);
  const [customAmount, setCustomAmount] = useState(0);
  const [customTotalAmount, setCustomTotalAmount] = useState(0);
  const [customTotalShares, setCustomTotalShares] = useState(0);

  const [price, setPrice] = useState(0);

  const [maxShares, setMaxShares] = useState(0);
  const [maxAmount, setMaxAmount] = useState(0);

  const [finalShares, setFinalShares] = useState([]);

  const [step, setStep] = useState(1);

  const animatedComponents = makeAnimated();

  const isSaveDisabled = () => {
    if (!customOptionSelected || !customTotalAmount) return true;

    return false;
  };

  const handleClickNext = () => {
    if (step === 1) {
      setStep(2);
    }
  };

  const handleClickBack = () => {
    if (step === 2) {
      setStep(1);
    }
  };

  const handleTenderOfferChange = (selectedOption) => {
    setSelectedOfferOption(selectedOption);
  };

  const handleBuyTenderOffer = async () => {
    try {
      if (sellPartner && sellPartner?.sharesCount?.actual >= customShares) {
        const transactions = [];
        const movements = [];
        const shares = [];
        const sellPartnerShares = actualSociety?.shares
          ?.filter(
            (share) =>
              share?.isActive && share?.partner === sellPartner?.['_id']
          )
          .sort((a, b) => a?.from - b?.from);

        let remainingShares = customShares;

        // eslint-disable-next-line no-restricted-syntax
        for (const share of sellPartnerShares) {
          if (remainingShares === 0) break;

          const count = share?.to - share?.from + 1;

          if (remainingShares < count) {
            movements.push({
              partner: sellPartner?.['_id'],
              shareFrom: +share.from,
              shareTo: +share.to,
              shareClass: share?.shareClass?.['_id'],
              movementType: 'DECREMENT',
            });
            movements.push({
              partner: sellPartner?.['_id'],
              shareFrom: +share.from + remainingShares,
              shareTo: +share.to,
              shareClass: share?.shareClass?.['_id'],
              sharePremium: share?.sharePremium || 0,
              sharePrice: share?.sharePrice,
              operation: share?.operation || null,
            });
            movements.push({
              partner: partner?.['_id'],
              shareFrom: +share.from,
              shareTo: +share.from + remainingShares - 1,
              shareClass: share?.shareClass?.['_id'],
              sharePremium: share?.sharePremium || 0,
              sharePrice: price,
              operation: 'THIS',
            });
            transactions.push({
              partner: partner?.['_id'],
              shareFrom: +share.from,
              shareTo: +share.from + remainingShares - 1,
              shareClass: share?.shareClass?.['_id'],
              sharePremium: share?.sharePremium || 0,
              sharePrice: price,
              transactionType: transactionTypes.BUY,
            });
            transactions.push({
              partner: sellPartner?.['_id'],
              shareFrom: +share.from,
              shareTo: +share.from + remainingShares - 1,
              shareClass: share?.shareClass?.['_id'],
              sharePremium: share?.sharePremium || 0,
              sharePrice: price,
              operation: share?.operation || null,
              transactionType: transactionTypes.SELL,
            });
            shares.push({
              partner: partner?.['_id'],
              shareFrom: +share.from,
              shareTo: +share.from + remainingShares - 1,
              className: share?.shareClass?.name,
              sharePremium: share?.sharePremium || 0,
              sharePrice: price,
            });

            remainingShares -= count;

            break;
          } else {
            movements.push({
              partner: sellPartner?.['_id'],
              shareFrom: +share.from,
              shareTo: +share.to,
              shareClass: share?.shareClass?.['_id'],
              movementType: 'DECREMENT',
            });
            movements.push({
              partner: partner?.['_id'],
              shareFrom: +share.from,
              shareTo: +share.to,
              shareClass: share?.shareClass?.['_id'],
              sharePremium: share?.sharePremium || 0,
              sharePrice: price,
              operation: 'THIS',
            });
            transactions.push({
              partner: partner?.['_id'],
              shareFrom: +share.from,
              shareTo: +share.to,
              shareClass: share?.shareClass?.['_id'],
              sharePremium: share?.sharePremium || 0,
              sharePrice: price,
              transactionType: transactionTypes.BUY,
            });
            transactions.push({
              partner: sellPartner?.['_id'],
              shareFrom: +share.from,
              shareTo: +share.to,
              shareClass: share?.shareClass?.['_id'],
              sharePremium: share?.sharePremium || 0,
              sharePrice: price,
              operation: share?.operation || null,
              transactionType: transactionTypes.SELL,
            });
            shares.push({
              partner: partner?.['_id'],
              shareFrom: +share.from,
              shareTo: +share.to,
              className: share?.shareClass?.name,
              sharePremium: share?.sharePremium || 0,
              sharePrice: price,
            });

            remainingShares -= count;
          }
        }
        setFinalShares(shares);

        await store.dispatch(
          sellParticipationsAction(
            {
              date: new Date(),
              society: actualSociety['_id'],
              user: user?.['_id'],
              nominalValue: actualSociety.nominalValue,
              movements,
              transactions,
              documents: [],
              comments: '',
            },
            false // showAlert
          )
        );

        dispatch(setModal(null));
        Swal.fire({
          icon: 'success',
          title: `<h4 class="nk-modal-title">${t('AcceptedOffer')}</h4>`,
          html: `<div class="caption-text">${t('AcceptedOfferMessage')}</div>`,
          footer: `<div class="bg-lighter"><div class="text-center w-100"><p>${t(
            'AcceptedOfferMessageFooter'
          )}</p></div></div>`,
          confirmButtonText: t('OK'),
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonColor: '#6576FF',
        });

        const tenderOfferData = {
          sellerCif: sellPartner?.cif,
          sellerName: sellPartner?.name,
          sellerAddress: sellPartner?.address,
          buyerCif: partner?.cif,
          buyerName: partner?.name,
          buyerAddress: partner?.address,
          sharesFrom: shares[0]?.shareFrom,
          sharesTo: shares[shares.length - 1]?.shareTo,
          sharePrice: price,
          totalPrice: customTotalAmount,
          totalShares: customTotalShares,
          class: holdingClass?.['_id'],
        };

        dispatch(
          tenderOfferSharesSignature({
            user,
            partner,
            tenderOffer,
            tenderOfferData,
            actualSociety,
            translate: t,
          })
        );

        // TODO: Confirm track event name
        trackEvent(mixpanel, eventTypes.SELL_PARTICIPATION, {
          userId: user?.['_id'],
          userName: user?.name,
          userEmail: user?.email,
          societyId: actualSociety?.['_id'],
          societyName: actualSociety?.name,
          operation: eventTypes.SELL_PARTICIPATION,
        });
      }
    } catch (error) {
      return Swal.fire({
        icon: 'error',
        title: `<h4 class="nk-modal-title">${t('RejectedOffer')}</h4>`,
        footer: `<div class="bg-lighter"><div class="text-center w-100"><p>${t(
          'RejectedOfferMessageFooter'
        )}</p></div></div>`,
        confirmButtonText: t('OK'),
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonColor: '#6576FF',
      });
    }
  };

  const handleCancelTenderOffer = async () => {
    dispatch(setModal(null));
  };

  const handleChangeCustomShares = (value) => {
    if (+value >= 0 && +value <= maxShares) {
      setCustomShares(+value);
    }
  };

  const handleChangeCustomAmount = (value) => {
    if (+value >= 0 && +value <= maxAmount) {
      setCustomAmount(+value);
    }
  };

  useEffect(() => {
    setCustomTotalAmount(customShares * price);
    setCustomTotalShares(customShares);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customShares]);

  useEffect(() => {
    setCustomTotalAmount(customAmount);
    setCustomTotalShares(Math.floor(customAmount / price));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customAmount]);

  useEffect(() => {
    if (customOptionSelected === customOptionTypes.BY_UNITS) {
      setCustomAmount(0);
    }
    if (customOptionSelected === customOptionTypes.BY_PRICE) {
      setCustomShares(0);
    }
  }, [customOptionSelected]);

  useEffect(() => {
    const activeTenders = tenderOffersShares?.filter(
      (tender) => tender?.isActive && tender?.type === 'SELL'
    );
    if (activeTenders?.length === 1) {
      setSelectedOfferOption({
        value: activeTenders[0]?.['_id'],
        label: activeTenders[0]?.name,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenderOffersShares]);

  useEffect(() => {
    if (selectedOfferOption === SELECT_OPTION) {
      setTenderOffer();
      setPrice(0);
      setMaxShares(0);
      setMaxAmount(0);
      setSellPartner();
      setHoldingClass();
      setCustomShares(0);
      setCustomAmount(0);
      setCustomTotalShares(0);
      setCustomTotalAmount(0);
      return;
    }
    if (actualSociety && selectedOfferOption !== SELECT_OPTION) {
      const tender = tenderOffersShares?.find(
        (offer) => offer['_id'] === selectedOfferOption?.value
      );
      if (tender) {
        const stockPartner = actualSociety?.partners?.find(
          (partner) => partner['_id'] === tender?.sellerId
        );
        setTenderOffer(tender);
        setPrice(tender?.price);
        if (tender?.sellAll && stockPartner) {
          setMaxShares(stockPartner?.sharesCount?.actual);
          setMaxAmount(stockPartner?.sharesCount?.actual * tender?.price);
        } else {
          setMaxShares(tender?.maxShares);
          setMaxAmount(tender?.maxAmount);
        }
        if (stockPartner) {
          const stockPartnerClass = actualSociety?.shares?.find(
            (share) =>
              share?.isActive && share?.partner === stockPartner?.['_id']
          )?.shareClass;
          setHoldingClass(stockPartnerClass);
          setSellPartner(stockPartner);
        }
      }
    }
  }, [tenderOffersShares, actualSociety, selectedOfferOption]);

  return (
    <Modal.Body>
      <div className="text-right">
        <a
          className="text-soft fs-22px  cursor-pointer"
          onClick={() => dispatch(setModal(null))}
        >
          <em className="icon ni ni-cross" />
        </a>
      </div>
      <div className="modal-body modal-body-md pt-0">
        <div className="sp-package text-center" id="sell-tender-offer">
          {step === 1 && (
            <>
              <div className="sp-package-head">
                <h4 className="title">{t('QuantityToBuyIndication')}</h4>
              </div>

              <div className="form-group mt-3">
                <label className="form-label" htmlFor="pay-amount">
                  {t('SelectOffer')}
                </label>
                <div className="form-control-wrap">
                  <div className="">
                    <Select
                      closeMenuOnSelect
                      className="react-select"
                      value={selectedOfferOption}
                      options={tenderOffersShares
                        ?.filter(
                          (tender) =>
                            tender?.isActive && tender?.type === 'SELL'
                        )
                        .map((tender) => ({
                          value: tender?.['_id'],
                          label: tender?.name,
                        }))}
                      components={animatedComponents}
                      onChange={handleTenderOfferChange}
                      placeholder={SELECT_OPTION}
                    />
                  </div>
                </div>
              </div>

              <ul className="sp-package-list">
                <li className="sp-package-item">
                  <input
                    className="sp-package-choose"
                    type="radio"
                    name="subscription-pack-plan"
                    id="sell-custom-by-unit"
                    value={customOptionTypes.BY_UNITS}
                    checked={
                      customOptionSelected === customOptionTypes.BY_UNITS
                    }
                    disabled={selectedOfferOption === SELECT_OPTION}
                    onChange={(e) => setCustomOptionSelected(e.target.value)}
                  />
                  <label
                    className="sp-package-desc"
                    htmlFor="sell-custom-by-unit"
                  >
                    <span className="sp-package-info">
                      <span className="sp-package-title title">
                        {t('HowManyUnitsWantToBuy')}
                      </span>

                      <br />
                      <div className="form-control-wrap mb-2">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span
                              className="input-group-text"
                              id="inputGroup-sizing-default"
                            >
                              {t('Shares')}
                            </span>
                          </div>
                          <input
                            type="number"
                            className="form-control text-right"
                            value={customShares}
                            onChange={(e) =>
                              handleChangeCustomShares(e.target.value)
                            }
                            disabled={
                              customOptionSelected ===
                              customOptionTypes.BY_PRICE
                            }
                          />
                        </div>
                      </div>

                      <span className="sp-package-detail d-block">
                        <strong>{t('MaxNumber')}:&nbsp;</strong>
                        {formatNumber(maxShares)}
                      </span>
                      <span className="sp-package-detail d-block">
                        <strong>{t('PriceForParticipation')}:&nbsp;</strong>
                        {formatNumberDecimalsLong(price)}€
                      </span>
                    </span>

                    <span className="sp-package-price d-flex justify-content-center align-items-center">
                      <span className="sp-package-amount yearly">
                        <span className="amount">
                          {customOptionSelected === customOptionTypes.BY_UNITS
                            ? formatNumberDecimals(customTotalAmount)
                            : formatNumberDecimals(0)}
                          €
                        </span>
                      </span>
                    </span>
                  </label>
                </li>

                <li className="sp-package-item">
                  <input
                    className="sp-package-choose"
                    type="radio"
                    name="subscription-pack-plan"
                    id="sell-custom-by-price"
                    value={customOptionTypes.BY_PRICE}
                    checked={
                      customOptionSelected === customOptionTypes.BY_PRICE
                    }
                    disabled={selectedOfferOption === SELECT_OPTION}
                    onChange={(e) => setCustomOptionSelected(e.target.value)}
                  />
                  <label
                    className="sp-package-desc"
                    htmlFor="sell-custom-by-price"
                  >
                    <span className="sp-package-info">
                      <span className="sp-package-title title">
                        {t('WhatAmountDoYouWantToBuy')}
                      </span>

                      <br />
                      <div className="form-control-wrap mb-2">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span
                              className="input-group-text"
                              id="inputGroup-sizing-default"
                            >
                              {t('TotalAmount')}
                            </span>
                          </div>
                          <input
                            type="number"
                            className="form-control text-right"
                            value={customAmount}
                            onChange={(e) =>
                              handleChangeCustomAmount(e.target.value)
                            }
                            disabled={
                              customOptionSelected ===
                              customOptionTypes.BY_UNITS
                            }
                          />
                        </div>
                      </div>

                      <span className="sp-package-detail d-block">
                        <strong>{t('Maximum')}: </strong>
                        {formatCurrencyDecimals(maxAmount)}
                      </span>
                    </span>
                    <span className="sp-package-price d-flex justify-content-center align-items-center">
                      <span className="sp-package-amount yearly">
                        <span className="amount">
                          {customOptionSelected === customOptionTypes.BY_PRICE
                            ? formatNumber(customTotalShares)
                            : formatNumber(0)}
                          &nbsp;
                          {t('Shar.')}
                        </span>
                      </span>
                    </span>
                  </label>
                </li>
              </ul>
            </>
          )}

          {step === 2 && (
            <>
              <div className="sp-package-head">
                <h4 className="title mb-4">{t('TransactionSummary')}</h4>
              </div>

              <TenderOfferBuySharesInvoice
                data={{
                  partner,
                  actualSociety,
                  sellerName: sellPartner?.name,
                  sharesClass: holdingClass?.name || '',
                  sharesPrice: price,
                  totalShares: customTotalShares,
                  totalAmount: customTotalAmount,
                }}
              />
            </>
          )}

          <div className="sp-package-action">
            {step === 1 && (
              <>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleClickNext}
                  disabled={isSaveDisabled()}
                >
                  {t('Next')}
                </button>
                <button
                  type="button"
                  className="btn btn-dim btn-danger"
                  onClick={handleCancelTenderOffer}
                >
                  {t('Cancel')}
                </button>
              </>
            )}

            {step === 2 && (
              <>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleClickBack}
                >
                  {t('Back')}
                </button>
                <button
                  type="button"
                  className="btn btn-primary btn-success text-secondary"
                  onClick={handleBuyTenderOffer}
                  disabled={isSaveDisabled()}
                >
                  {t('ConfirmTransaction')}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </Modal.Body>
  );
};

export default TenderOfferBuyShares;
