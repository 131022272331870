import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import html2pdf from 'html3pdf';

import store from 'redux/store';
import { setModal } from 'redux/actions/modalActions';
import { updateBoard, getBoards } from 'redux/actions/boardActions';
import { sendCommEmail } from 'redux/actions/communicationActions';

import { getPartnerActualShares } from 'utils/filters';

import { formatDateToTime } from 'constants/formats';
import attendanceTypes from 'constants/attendanceTypes';
import boardTypes from 'constants/boardTypes';
import boardStatus from 'constants/boardStatus';

import { updateDocumentWithValues } from 'components/EditorWYSIWYG/helpers';
import tags from 'components/EditorWYSIWYG/tags';
import CustomLoading from 'components/CustomLoading';
import LogoSttokBase64 from 'components/Logos/LogoSttokBase64';

import { getSingleDocument } from 'utils/downloadDocument';

import EditParticipantModal from './EditParticipantModal';

type SharesCount = {
  actual: number;
};

type ParticipantsType = {
  _id: string;
  member: string;
  name: string;
  email: string;
  userType: string;
  assists: boolean;
  isAbsent: boolean;
  isRepresented: boolean;
  hasDelegated: boolean;
  representative: string;
  votes: any[];
};

type PartnersType = {
  _id: string;
  name: string;
  email: string;
  sharesCount: SharesCount;
  user: string;
};

type BoardType = {
  _id: string;
  name: string;
  date: Date;
  participants: ParticipantsType[];
  attendanceType: keyof typeof attendanceTypes;
  announcement: string;
  place: string;
  onlineAccess: string;
  boardType: keyof typeof boardTypes;
  announcementDocument: string;
  votePeriod: string;
};

type SocietyType = {
  _id: string;
  name: string;
  partners: PartnersType[];
};

type Props = {
  currentBoard: BoardType;
  actualSociety: SocietyType;
  announcementTemplateDoc: any;
};

const SendAnnouncementModal: FC<Props> = ({
  currentBoard,
  actualSociety,
  announcementTemplateDoc,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const user: any = useSelector((state: any) => state.user);

  const [date, setDate] = useState<string>();
  const [time, setTime] = useState<string>();
  const [expandDirection, setExpandDirection] = useState<string>('down');
  const [expandView, setExpandView] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isParticipantAllowedForAnnouncement = (
    participant: ParticipantsType
  ): boolean => getPartnerActualShares(participant.member, actualSociety) > 0;

  const getTotalParticipantsAllowed = (): number =>
    currentBoard?.participants?.filter((participant) =>
      isParticipantAllowedForAnnouncement(participant)
    )?.length || 0;

  const handleEditParticipant = (participant: any): void => {
    store.dispatch(
      setModal(
        <EditParticipantModal
          currentBoard={currentBoard}
          actualSociety={actualSociety}
          participant={participant}
          announcementTemplateDoc={announcementTemplateDoc}
        />
      )
    );
  };

  const handleViewChange = (): void => {
    const newView = !expandView;
    const newDirection = newView ? 'up' : 'down';
    setExpandView(newView);
    setExpandDirection(newDirection);
  };

  const handleSendAnnouncement = async (): Promise<void> => {
    try {
      setIsLoading(true);
      const buttonText =
        currentBoard?.votePeriod === 'FROM_SENDING_TO_CLOSING' ||
        currentBoard?.votePeriod === 'FROM_SENDING_TO_ONE_HOUR_AFTER_BOARD_DATE'
          ? 'VOTAR AHORA'
          : 'ACCEDER A LA JUNTA';

      const activePartner = currentBoard?.participants?.filter((participant) =>
        isParticipantAllowedForAnnouncement(participant)
      );

      const recipients = activePartner.map((partner) => ({
        email: partner.email,
        name: partner.name,
      }));

      const recipientsForDynamicData = activePartner.map((recipient) => {
        const { member, email, name } = recipient;
        return {
          id: member,
          email: email ? email.trim() : '',
          name,
        };
      });
      const data = {
        societyName: actualSociety?.name || '-',
        boardName: currentBoard?.name || '',
        date,
        time,
        announcement: currentBoard?.announcement || '',
        place: currentBoard?.place || '-',
        onlineAccess: currentBoard?.onlineAccess || '-',
        boardType: boardTypes?.[currentBoard?.boardType]?.text || '-',
        delegationUrl: '',
      };
      let fileBlob;
      if (currentBoard?.announcementDocument) {
        fileBlob = await getSingleDocument(currentBoard?.announcementDocument);
      } else {
        const documentUpdated = updateDocumentWithValues(
          announcementTemplateDoc?.editorModel,
          tags({
            society: actualSociety,
            board: currentBoard,
            plan: null,
            partner: null,
            beneficiary: null,
            signatures: null,
            tenderOffer: null,
            tenderOfferShares: null,
            operation: null,
            holdingClasses: null,
            annualValue: null,
          })
        );

        const options = {
          filename: `${t('AnnouncementBoard')}.pdf`,
          margin: [10, 10, 10, 10], // top, right, bottom, left
          pagebreak: { mode: 'avoid-all' },
          html2canvas: { scale: 2 },
          jsPDF: {
            unit: 'mm',
            format: 'a4',
            orientation: 'portrait',
          },
        };
        fileBlob = await html2pdf()
          .from(documentUpdated?.html)
          .set(options)
          .output('blob');
      }
      const announcementFile = new File(
        [fileBlob],
        `${t('AnnouncementBoard')}.pdf`,
        {
          type: 'application/pdf',
        }
      );

      const emailData = {
        to: recipients,
        cc: '',
        bcc: '',
        files: announcementFile ? [announcementFile] : [],
        subject: t('BoardAnnouncement'),
        bodySummary: data.announcement,
        template: 'board-announcement-new-es',
        templateBody: `<div class="main" style="padding: 24px">
                <div style="
                    background: #f5f6fa;
                    font-family: Roboto;
                    font-size: 14px;
                    line-height: 22px;
                    font-weight: 400;
                    color: #8094ae;
                    width: 100%;
                    text-align: center;
                  ">
                  <a href="https://www.sttok.com">
                    <img src="${LogoSttokBase64}" alt="logo" style="height: 40px; margin-top: 2.75rem"/>
                  </a>
                  <p style="
                      font-size: 13px;
                      color: #6576ff;
                      padding-top: 6px;
                      margin-top: 0;
                      padding-bottom: 24px;
                    ">
                    Gestión de Socios
                  </p>
                  <div class="email-body" style="
                      width: 96%;
                      max-width: 620px;
                      text-align: left;
                      margin: 0 auto;
                      padding: 1.75rem 2.75rem;
                      background: #ffffff;
                    ">
                    <h2 style="
                      font-family: Nunito, sans-serif;
                      font-size: 18px;
                      color: #6576ff;
                      font-weight: 600;
                      margin: 0;
                      line-height: 1.4;
                      margin: 10px 0;
                      padding-bottom: 15px;
                      text-align: left;
                      ">
                        Convocatoria Junta de ${data.societyName}
                    </h2>
                    <p style="text-align: left;">${data.announcement}</p>
                    <h2 style="
                      font-size: 18px;
                      text-align: left;
                      margin-bottom: 0.5rem;
                      font-family: Nunito, sans-serif;
                      font-weight: 700;
                      line-height: 1.1;
                      color: #364a63;
                    ">
                        ${data.boardName}
                    </h2>
                    <table style="width:100%;">
                        <tr>
                            <td style="text-align: left; padding-bottom: 10px;">
                              <span>Fecha: ${data.date}</span>
                            </td>
                            <td style="text-align: left; padding-bottom: 10px;">
                              <span>Hora: ${data.time}</span>
                            </td>
                        </tr>
                        <tr>
                            <td style="text-align: left;">
                              <span>Tipo Junta: ${data.boardType}</span>
                            </td>
                            <td style="text-align: left;">
                              <span>Videollamada: ${data.onlineAccess}</span>
                            </td>
                        </tr>
                      </table>
                      <p style="text-align: left; margin-top: 10px;">Lugar de celebración: ${data.place}</p>
                      <h2 style="
                      font-size: 18px;
                      text-align: left;
                      margin-bottom: 0.5rem;
                      font-family: Nunito, sans-serif;
                      font-weight: 700;
                      line-height: 1.1;
                      color: #364a63;
                      ">
                        Delegación de voto
                    </h2>
                    <p style="text-align: left;">Si no puedes asistir a la Junta, puedes delegar tu voto aquí:</p>
                    <span style="
                        background: rgb(101, 118, 255);
                        border-radius: 4px;
                        border: none;
                        color: white;
                        display: inline-block;
                        font-size: 13px;
                        font-weight: 600;
                        line-height: 38px;
                        text-align: center;
                        text-decoration: none;
                        text-transform: uppercase;
                        margin: 0 auto 30px;
                        padding: 0 30px;
                        width: fit-content;
                      ">
                        DELEGAR MI VOTO
                    </span>
                    <br/>
                    <h2 style="
                      font-size: 18px;
                      text-align: left;
                      margin-bottom: 0.5rem;
                      font-family: Nunito, sans-serif;
                      font-weight: 700;
                      line-height: 1.1;
                      color: #364a63;
                      ">
                        Acceso a la Junta y votación
                    </h2>
                    <p style="text-align: left;">Puedes acceder a los detalles y documentación de la Junta:</p>
                    <span style="
                        background: rgb(101, 118, 255);
                        border-radius: 4px;
                        border: none;
                        color: white;
                        display: inline-block;
                        font-size: 13px;
                        font-weight: 600;
                        line-height: 38px;
                        text-align: center;
                        text-decoration: none;
                        text-transform: uppercase;
                        margin: 0 auto 30px;
                        padding: 0 30px;
                        width: fit-content;
                      ">
                        ${buttonText}
                      </span>
                      <br/>
                      <p style="text-align: justify">
                          El proceso está gestionado a través de la plataforma Sttok desde la cual podrás acceder a la convocatoria, poder revisar la documentación y votar.
                      </p>
                    </div>
                    <p style="padding-bottom: 2.75rem; padding-top: 2.75rem; margin: 0">
                      Correo enviado por
                        <a style="color: #6576ff; text-decoration: none" href="https://sttok.com/">sttok.com</a> Puede contactar en <a href="mailto:info@sttok.com" target="_blank">info@sttok.com
                        </a>
                    </p>
                </div>
              </div>`,
        templateData: [
          {
            name: 'societyName',
            content: data.societyName,
          },
          {
            name: 'boardName',
            content: data.boardName,
          },
          {
            name: 'boardDate',
            content: data.date,
          },
          {
            name: 'boardTime',
            content: data.time,
          },
          {
            name: 'onlineAccess',
            content: data.onlineAccess,
          },
          {
            name: 'boardPlace',
            content: data.place,
          },
          {
            name: 'announcement',
            content: data.announcement,
          },
          {
            name: 'boardType',
            content: data.boardType,
          },
          {
            name: 'delegationUrl',
            content: data.delegationUrl,
          },
          {
            name: 'buttonText',
            content: buttonText,
          },
        ],
        templateDynamicData: recipientsForDynamicData.map(
          ({ id, name, email }) => ({
            rcpt: email,
            vars: [
              { name: 'name', content: name },
              {
                name: 'delegationUrl',
                content: `${process.env.REACT_APP_DOMAIN}?redirect=delegacion/${actualSociety['_id']}/${currentBoard._id}/${id}`,
              },
              {
                name: 'boardUrl',
                content: `${process.env.REACT_APP_DOMAIN}?redirect=voto-junta/${actualSociety['_id']}/${currentBoard._id}/${id}`,
              },
            ],
          })
        ),
        sender: {
          name: `Sttok | ${actualSociety?.name}`,
          email: 'info@sttok.com',
        },
        user: user['_id'],
        society: actualSociety['_id'],
        date: new Date(),
        iCal: {
          societyEmail: user.email,
          societyName: data.societyName,
          boardName: data.boardName,
          boardDate: currentBoard.date,
          boardLink: data.onlineAccess,
        },
      };

      const communicationId = await store.dispatch(sendCommEmail(emailData));
      await store.dispatch(
        updateBoard(
          currentBoard['_id'],
          {
            announcementComm: communicationId,
            status: boardStatus.ACTIVE.value,
          },
          false
        )
      );

      await store.dispatch(getBoards(actualSociety['_id']));
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (currentBoard) {
      setTime(currentBoard?.date ? formatDateToTime(currentBoard?.date) : '');
      setDate(
        currentBoard?.date
          ? format(new Date(currentBoard?.date), 'dd/MM/yyyy')
          : ''
      );

      const showDetails = JSON.parse(
        localStorage.getItem('showDetails') || 'false'
      );
    }
  }, [currentBoard]);

  return (
    <>
      <Modal.Body>
        <div className="modal-body modal-body-lg text-center">
          <div className="nk-modal">
            <button
              type="button"
              className="close"
              onClick={() => store.dispatch(setModal(null))}
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-send bg-primary-alt" />
            <h4 className="nk-modal-title">{t('SendAnnouncement')}</h4>
            <div className="nk-modal-text">
              <p
                className="lead"
                dangerouslySetInnerHTML={{
                  __html: t('SendAnnouncementMessage', {
                    societyName: actualSociety.name,
                    date,
                    time,
                    attendanceType:
                      attendanceTypes[currentBoard.attendanceType].text,
                  }),
                }}
              />
              <p className="text-soft fs-14px">
                {t('SendAnnouncementFootMessage')}
              </p>
            </div>
            <div key="participants-card" style={{ paddingBottom: '20px' }}>
              <table
                className="table"
                id="participants-table"
                style={{ marginBottom: '0px' }}
              >
                <thead>
                  <tr className="tb-tnx-head">
                    <th className="text-left" colSpan={2}>
                      <span>
                        {t('Participants')} ({getTotalParticipantsAllowed()})
                      </span>
                    </th>
                    <th className="col-2 text-right">
                      <button
                        type="button"
                        className="badge btn-primary"
                        onClick={() => handleViewChange()}
                      >
                        <em
                          className={`icon ni ni-chevron-${expandDirection}`}
                        />
                      </button>
                    </th>
                  </tr>
                </thead>
              </table>
              <div
                key="participants-card"
                style={{
                  maxHeight: '200px',
                  overflowY: 'auto',
                }}
              >
                <table className="table" id="participants-body">
                  {expandView && (
                    <tbody>
                      {currentBoard?.participants
                        ?.filter((participant) =>
                          isParticipantAllowedForAnnouncement(participant)
                        )
                        ?.map((participant, index) => (
                          <tr className="tb-tnx-item">
                            <td
                              className="tb-tnx-info text-left"
                              id={`participant-${index}`}
                            >
                              <span className="title">{participant.name}</span>
                            </td>
                            <td
                              className="tb-tnx-info text-soft text-left"
                              id={`participant-${index}-email`}
                            >
                              <span className="title">{participant.email}</span>
                            </td>
                            <td
                              className="tb-tnx-info text-soft text-left"
                              id={`participant-${index}-blank`}
                            >
                              <div className="nk-block float-right">
                                <button
                                  type="button"
                                  className="btn btn-icon btn-trigger btn-tooltip"
                                  onClick={() => {
                                    handleEditParticipant(participant);
                                  }}
                                >
                                  <em className="icon ni ni-edit mr-1" />
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => handleSendAnnouncement()}
            >
              {t('SendNow')}
            </button>
          </div>
        </div>
      </Modal.Body>
      {isLoading && <CustomLoading />}
    </>
  );
};

export default SendAnnouncementModal;
