/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Tooltip } from 'react-tooltip';
import { v4 as uuidv4 } from 'uuid';

import './UsersArray.scss';

function UsersArray({
  users,
  customUsers = false,
  show = 3,
  bgColor = 'bg-blue',
  size = 'sm',
  hasTooltip = false,
  tooltipField = 'name',
  hasLink = false,
  linkField,
  linkPrefix,
  spaced = true,
}) {
  const hasValidLink = hasLink && linkPrefix;

  let icons = [];

  if (users?.length > 1) {
    icons = users.slice(0, show);
  } else if (users?.length === 1) {
    icons = users;
  }

  icons = icons.map((icon, index) => {
    const currentId = uuidv4();

    return (
      <div
        className={`user-avatar ${bgColor} ${size} ${
          hasValidLink ? 'cursor-pointer' : ''
        }`}
        key={`icon-${index}`}
        id={currentId}
        onClick={() => {
          if (hasValidLink) {
            window.location.href = customUsers
              ? `#/${icon.linkPrefix}/${icon.linkField}`
              : `#/${linkPrefix}/${icon[linkField]}`;
          }
        }}
      >
        {icon?.image ? (
          <img
            src={icon?.image}
            alt={icon?.name?.slice(0, 2).toUpperCase()}
            className="user-avatar__picture"
          />
        ) : (
          <div>{icon?.name?.slice(0, 2).toUpperCase()}</div>
        )}

        {hasTooltip && icon[tooltipField] && (
          <Tooltip anchorId={currentId} place="top">
            {icon[tooltipField]}
          </Tooltip>
        )}
      </div>
    );
  });

  if (users?.length > show) {
    icons[show - 1] = (
      <div
        className={`user-avatar ${bgColor} ${size}`}
        key="extra-user-counter"
      >
        <span>+{users?.length - (show - 1)}</span>
      </div>
    );
  }

  if (users?.length === 0) {
    icons = [];
  }

  return (
    <div className={`project-users g-1 ${!spaced ? 'no-spaced' : ''}`}>
      {icons}
    </div>
  );
}

export default UsersArray;
