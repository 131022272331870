import i18n from 'i18n/config';

export const columns = {
  CONSTITUTION: { colSpan: 2 },
  CAPITAL_INCREASE: { colSpan: 3 },
  STOCK_OPTION: { colSpan: 3 },
  PHANTOM_SHARE: { colSpan: 3 },
  CAPITAL_DECREASE: { colSpan: 3 },
  SELL_PARTICIPATION: { colSpan: 4 },
  SPLIT: { colSpan: 3 },
};

export const titleColumn = {
  NUEVAS: 'Nuevas',
  TOTAL: 'Total',
  PERCENT: '%',
  PARTNERS: 'Socios',
};

export const transactionNames = {
  ADD_PARTNER: i18n.t('AddPartner'),
  CONSTITUTION: i18n.t('Constitution'),
  CAPITAL_INCREASE: i18n.t('CapitalIncrease'),
  CAPITAL_DECREASE: i18n.t('CapitalDecrease'),
  SELL_PARTICIPATION: i18n.t('SELL_PARTICIPATION'),
  SPLIT: i18n.t('Split'),
  RENUMERATION: i18n.t('Renumeration'),
  RECLASSIFICATION: i18n.t('Reclassification'),
  DIVIDENDS_DISTRIBUTION: i18n.t('DividendsDistribution'),
  CAPITAL_DECREASE_DRAFT: i18n.t('CapitalIncreaseDraft'),
  SELL_PARTICIPATION_DRAFT: i18n.t('SellParticipationsDraft'),
};
