/* eslint-disable no-underscore-dangle */
import i18n from 'i18n/config';
import { deleteCommEmail } from 'redux/actions/communicationActions';
import store from 'redux/store';
import Swal from 'sweetalert2';

export default [
  {
    icon: 'ni-eye',
    text: i18n.t('View'),
    action: (params) => {
      // navigate to view

      const communicationId = params?.data?._id;
      const societyId = params?.societyId;

      window.location.href = `#/comunicaciones/${societyId}/${communicationId}`;
    },
  },
  {
    icon: 'ni-trash',
    text: i18n.t('Delete'),
    action: (params) => {
      const communicationId = params?.data?._id;

      Swal.fire({
        icon: 'warning',
        title:
          '<h4 class="nk-modal-title">¿Está seguro de que desea eliminar esta comunicación?</h4>',
        confirmButtonText: 'Ok, eliminar',
        confirmButtonColor: '#6576ff',
        allowOutsideClick: false,
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          store.dispatch(deleteCommEmail(communicationId));
        }
      });
    },
  },
];
