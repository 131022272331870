/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable prefer-template */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import ReactToPrint from 'react-to-print';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import bigDecimal from 'js-big-decimal';

import { setMenu } from 'redux/actions/menuActions';
import { getDocuments } from 'redux/actions/documentActions';

import downloadFile from 'utils/downloadFile';
import { getActualPartner, getActualSociety } from 'utils/filters';

import MenuDots from 'components/MenuDots';
import PrintHeader from 'components/Headers/PrintHeader';

import menuTypes from 'constants/menuTypes';
import operationTypes from 'constants/operationTypes';
import transactionTypes from 'constants/transactionTypes';
import {
  currencyFormatDecimals,
  dateFormat,
  numberFormat,
} from 'constants/formats';

import './TransactionDetails.scss';

const TransactionDetails = () => {
  const { t } = useTranslation();

  const transactionNames = {
    ADD_PARTNER: t('AddPartner'),
    CONSTITUTION: t('Constitution'),
    CAPITAL_INCREASE: t('CapitalIncrease'),
    CAPITAL_DECREASE: t('CapitalDecrease'),
    SELL_PARTICIPATION: t('SELL_PARTICIPATION'),
    SPLIT: t('Split'),
    RENUMERATION: t('Renumeration'),
    RECLASSIFICATION: t('Reclassification'),
    DIVIDENDS_DISTRIBUTION: t('DividendsDistribution'),
  };

  const printRef = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const { societyId, transactionId } = useParams();
  const { user, actualSociety } = useSelector((state) => ({
    user: state.user,
    actualSociety: state.society?.actualSociety,
  }));

  const [actualTransaction, setActualTransaction] = useState();
  const [totalShares, setTotalShares] = useState();
  const [totalSum, setTotalSum] = useState();
  const [rows, setRows] = useState([]);
  const [transactionDocuments, setTransactionDocuments] = useState([]);

  const showLoadedDocuments = (documents) => {
    if (documents?.length === 1) {
      return (
        <span
          className="mr-2 px-2 border"
          style={{
            borderRadius: '5px',
            padding: '4px 12px',
          }}
        >
          <span className="mr-1">{t('Scripture')}</span>

          <span
            className="btn btn-icon btn-trigger"
            onClick={() => {
              // eslint-disable-next-line no-underscore-dangle
              downloadFile({ documentId: documents[0]?._id });
            }}
          >
            <em className="icon ni ni-file-download" />
          </span>
        </span>
      );
    }
    if (documents?.length > 1) {
      const options = documents.map((doc) => ({
        icon: 'ni-file',
        text: doc.name,
        download: true,
        // eslint-disable-next-line no-underscore-dangle
        documentId: doc._id,
      }));
      return (
        <span
          className="mr-2 px-2 border"
          style={{
            borderRadius: '5px',
            padding: '9px 12px',
          }}
        >
          <span className="mr-1">{t('Scriptures')}</span>
          <MenuDots
            menuOptions={options}
            direction="left"
            customIcon="ni-files"
          />
        </span>
      );
    }
    return <span>-</span>;
  };

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId) getActualSociety(user, societyId);
  }, [actualSociety, user, societyId]);

  useEffect(() => {
    if (actualSociety) {
      dispatch(
        setMenu({
          type: menuTypes.EXTENDED,
          societyId: actualSociety?.['_id'],
          societyName: actualSociety?.name,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety]);

  useEffect(() => {
    const currentTransaction = actualSociety?.operations.find(
      (operation) => operation['_id'] === transactionId
    );
    if (currentTransaction) {
      setActualTransaction(currentTransaction);
    }
  }, [actualSociety, transactionId]);

  useEffect(() => {
    if (transactionId) {
      dispatch(
        getDocuments({ operation: transactionId }, setTransactionDocuments)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionId]);

  useEffect(() => {
    let totalShares = 0;
    let totalSum = 0;
    const nominalValue =
      actualTransaction?.nominalValue || actualSociety?.nominalValue;

    let rows;
    if (
      actualTransaction?.operationType === operationTypes.DIVIDENDS_DISTRIBUTION
    ) {
      rows = actualTransaction?.dividends.map((dividend) => {
        const partner = getActualPartner(actualSociety, dividend.partner);
        const name = partner?.name || '';
        const shares = 0;
        const sharePremium = 0;
        const total = dividend.amount;

        totalSum += dividend.amount;

        const sharesTD = (
          <td className="text-right">
            {shares ? (
              <NumberFormat
                value={shares}
                displayType="text"
                {...numberFormat}
              />
            ) : (
              '-'
            )}
          </td>
        );
        const nominalValueTD = (
          <td className="text-right">
            <NumberFormat
              value={nominalValue}
              displayType="text"
              {...numberFormat}
            />
          </td>
        );
        const sharePremiumTD = (
          <td className="text-right">
            {sharePremium ? (
              <NumberFormat
                value={sharePremium}
                displayType="text"
                {...numberFormat}
              />
            ) : (
              '-'
            )}
          </td>
        );

        const totalTD = (
          <td className="text-right">
            <NumberFormat
              value={total}
              displayType="text"
              renderText={(value) => <div>{value}</div>}
              {...currencyFormatDecimals}
            />
          </td>
        );

        return (
          <tr key={dividend['_id']}>
            <td>{name}</td>
            <td>{transactionNames[actualTransaction.operationType]}</td>
            {sharesTD}
            {nominalValueTD}
            {sharePremiumTD}
            {totalTD}
          </tr>
        );
      });
    } else {
      rows = actualTransaction?.transactions.map((transaction) => {
        const partner = getActualPartner(actualSociety, transaction.partner);
        const name = partner?.name || '';
        const shares = transaction.shareTo - transaction.shareFrom + 1;
        const sharePremium = transaction.sharePremium || 0;
        const sharePrice = transaction.sharePrice || 0;
        let nominalValueTD;
        let sharePremiumTD;
        let sharePriceTD;
        let sharesTD;
        let totalTD;
        let totalNominalValueTD;
        let totalSharesPremiumTD;

        switch (actualTransaction.operationType) {
          case operationTypes.SELL_PARTICIPATION: {
            const sign =
              transaction.transactionType === transactionTypes.SELL ? '-' : '+';
            const total = transaction.sharePrice * shares;
            if (transaction.transactionType === transactionTypes.BUY) {
              totalSum += total;
              totalShares += shares;
            }

            sharePremiumTD = (
              <td className="text-right">
                {sharePremium ? (
                  <NumberFormat
                    value={sharePremium}
                    displayType="text"
                    {...currencyFormatDecimals}
                  />
                ) : (
                  '-'
                )}
              </td>
            );
            sharePriceTD = (
              <td className="text-right">
                {sharePrice ? (
                  <NumberFormat
                    value={sharePrice}
                    displayType="text"
                    {...currencyFormatDecimals}
                  />
                ) : (
                  '-'
                )}
              </td>
            );
            nominalValueTD = (
              <td className="text-right">
                <NumberFormat
                  value={nominalValue}
                  displayType="text"
                  {...currencyFormatDecimals}
                />
              </td>
            );
            sharesTD = (
              <td className="text-right">
                <NumberFormat
                  value={shares}
                  displayType="text"
                  renderText={(value) => (
                    <div>
                      {sign}
                      {value}
                    </div>
                  )}
                  {...numberFormat}
                />
              </td>
            );
            totalTD = (
              <td className="text-right">
                <NumberFormat
                  value={total}
                  displayType="text"
                  renderText={(value) => (
                    <div>
                      {sign}
                      {value}
                    </div>
                  )}
                  {...currencyFormatDecimals}
                />
              </td>
            );
            break;
          }
          default: {
            const sign =
              transaction.transactionType ===
                transactionTypes.CAPITAL_DECREASE && transaction?.sharePrice
                ? '+'
                : '';
            const total =
              transaction.transactionType ===
                transactionTypes.CAPITAL_DECREASE && transaction?.sharePrice
                ? transaction.sharePrice * shares
                : shares * (nominalValue + sharePremium);
            totalSum += total;
            totalShares += shares;
            const totalNominalValue = +bigDecimal.multiply(
              shares,
              nominalValue
            );
            const totalSharePremium = +bigDecimal.multiply(
              shares,
              sharePremium
            );

            sharesTD = (
              <td className="text-right">
                <NumberFormat
                  value={shares}
                  displayType="text"
                  {...numberFormat}
                />
              </td>
            );
            nominalValueTD = (
              <td className="text-right">
                <NumberFormat
                  value={nominalValue}
                  displayType="text"
                  {...numberFormat}
                />
              </td>
            );
            sharePremiumTD = (
              <td className="text-right">
                {sharePremium ? (
                  <NumberFormat
                    value={sharePremium}
                    displayType="text"
                    {...numberFormat}
                  />
                ) : (
                  '-'
                )}
              </td>
            );
            totalNominalValueTD = (
              <td className="text-right">
                <NumberFormat
                  value={totalNominalValue}
                  displayType="text"
                  {...numberFormat}
                />
              </td>
            );
            totalSharesPremiumTD = (
              <td className="text-right">
                <NumberFormat
                  value={totalSharePremium}
                  displayType="text"
                  {...numberFormat}
                />
              </td>
            );
            totalTD = (
              <td className="text-right">
                <NumberFormat
                  value={total}
                  displayType="text"
                  renderText={(value) => (
                    <div>
                      {sign}
                      {value}
                    </div>
                  )}
                  {...currencyFormatDecimals}
                />
              </td>
            );
            break;
          }
        }
        return (
          <tr key={transaction['_id']}>
            <td>{name}</td>
            <td>{transactionNames[actualTransaction.operationType]}</td>
            {sharesTD}
            {nominalValueTD}
            {actualTransaction.operationType ===
            operationTypes.SELL_PARTICIPATION
              ? sharePriceTD
              : sharePremiumTD}
            {actualTransaction.operationType ===
              operationTypes.CAPITAL_INCREASE && totalNominalValueTD}
            {actualTransaction.operationType ===
              operationTypes.CAPITAL_INCREASE && totalSharesPremiumTD}
            {totalTD}
          </tr>
        );
      });
    }

    setTotalShares(totalShares);
    setTotalSum(totalSum);
    if (rows) setRows(rows);
  }, [actualSociety, actualTransaction, transactionId]);

  return !actualTransaction ? (
    <>{t('Loading')}...</>
  ) : (
    <div className="nk-content-body">
      <div className="nk-content-wrap">
        <div className="nk-block-head">
          <div className="nk-block-between g-3">
            <div className="nk-block-head-content">
              <h3 className="nk-block-title page-title">
                {t('Transaction')}{' '}
                <strong className="text-primary small">#{transactionId}</strong>
              </h3>
            </div>
            <div className="nk-block-head-content">
              <span
                className="btn btn-outline-light bg-white d-none d-sm-inline-flex"
                onClick={() => history.goBack()}
              >
                <em className="icon ni ni-arrow-left" />
                <span>{t('Back')}</span>
              </span>
              <span
                className="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"
                onClick={() => history.goBack()}
              >
                <em className="icon ni ni-arrow-left" />
              </span>
            </div>
          </div>
        </div>

        <div className="nk-block">
          <div className="invoice">
            <div className="invoice-action d-flex align-items-center">
              {transactionDocuments?.length > 0 &&
                showLoadedDocuments(transactionDocuments)}
              <ReactToPrint
                trigger={() => (
                  <a className="btn btn-icon btn-lg btn-white btn-dim btn-outline-primary">
                    <em className="icon ni ni-printer-fill" />
                  </a>
                )}
                content={() => printRef.current}
                documentTitle={`${actualSociety?.name} - ${
                  transactionNames[actualTransaction.operationType]
                } ${format(new Date(actualTransaction.date), 'dd-MM-yyyy')}`}
              />
            </div>

            <div className="invoice-wrap" ref={printRef}>
              <PrintHeader title={t('PrintHeaderTransactionsList')} />

              <div className="invoice-brand text-center d-flex justify-content-between">
                <div className="user-avatar lg bg-primary">
                  {actualSociety.additional.logo ? (
                    <img src={actualSociety.additional.logo} alt="Logo" />
                  ) : (
                    <span>{actualSociety.name.slice(0, 2).toUpperCase()}</span>
                  )}
                </div>
              </div>

              <div className="invoice-head">
                <div className="invoice-contact flex-grow-1">
                  <span className="overline-title">{t('TransactionType')}</span>

                  <div className="invoice-contact-info">
                    <h4 className="title">
                      {transactionNames[actualTransaction?.operationType]}
                    </h4>

                    <ul className="list-plain">
                      {actualTransaction?.comments && (
                        <li>
                          <em className="icon ni ni-notes-alt" />
                          <span>{actualTransaction.comments || '-'}</span>
                        </li>
                      )}
                      <li>
                        <em className="icon ni ni-calender-date" />
                        <span>
                          {format(
                            new Date(actualTransaction?.date),
                            dateFormat
                          )}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                {actualTransaction?.notarialRegistration && (
                  <div className="invoice-contact flex-grow-1">
                    <span className="overline-title">
                      {t('NotarialRegistration')}
                    </span>

                    <ul className="list-plain mt-2">
                      <li>
                        <em className="icon ni ni-user-list" />
                        <span>
                          {actualTransaction.notarialRegistration?.notaryName ||
                            '-'}
                        </span>
                      </li>
                      <li>
                        <em className="icon ni ni-article" />
                        <span>
                          {actualTransaction.notarialRegistration?.protocolNr ||
                            '-'}
                        </span>
                      </li>
                      <li>
                        <em className="icon ni ni-map-pin" />
                        <span>
                          {actualTransaction.notarialRegistration?.notaryCity ||
                            '-'}
                        </span>
                      </li>
                    </ul>
                  </div>
                )}
              </div>

              <div className="invoice-bills">
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th className="text-left">{t('Partner')}</th>
                        <th className="text-left">{t('Description')}</th>
                        <th className="text-center">{t('NrPart.')}</th>
                        <th className="text-center">{t('NominalValue')}</th>
                        {actualTransaction.operationType ===
                        operationTypes.SELL_PARTICIPATION ? (
                          <th className="text-center">{t('PricePart.')}</th>
                        ) : (
                          <th className="text-center">{t('Premium')}</th>
                        )}
                        {actualTransaction.operationType ===
                          operationTypes.CAPITAL_INCREASE && (
                          <>
                            <th className="text-center">
                              {t('TotalVNominal')}
                            </th>
                            <th className="text-center">{t('TotalPremium')}</th>
                          </>
                        )}
                        {actualTransaction.operationType ===
                        operationTypes.DIVIDENDS_DISTRIBUTION ? (
                          <th className="text-center">{t('DividendsTotal')}</th>
                        ) : (
                          <th className="text-center">
                            {t('TotalInvestment')}
                          </th>
                        )}
                      </tr>
                    </thead>

                    <tbody>{rows}</tbody>

                    <tfoot>
                      <tr>
                        <td colSpan="1">&nbsp;</td>
                        <td colSpan="1">TOTAL</td>
                        <td className="text-right">
                          {actualTransaction.operationType !==
                            operationTypes.DIVIDENDS_DISTRIBUTION && (
                            <NumberFormat
                              value={totalShares}
                              displayType="text"
                              {...numberFormat}
                            />
                          )}
                        </td>
                        <td>{}</td>
                        <td>{}</td>
                        {actualTransaction.operationType ===
                          operationTypes.CAPITAL_INCREASE && (
                          <>
                            <td>{}</td>
                            <td>{}</td>
                          </>
                        )}
                        <td className="text-right">
                          <NumberFormat
                            value={totalSum}
                            displayType="text"
                            {...currencyFormatDecimals}
                          />
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionDetails;
