import signatureTypes from './signatureTypes';

export const redirectMarks = Object.values(signatureTypes).map(
  (type) => `${type}=`
);

export const redirectUrls = {
  [signatureTypes.INVITATION_LETTER]: '#/detalle-beneficiario/',
  [signatureTypes.TENDER_OFFER]: '#/participacion-tender-offer/',
  [signatureTypes.TENDER_OFFER_PARTNER]: '#/participacion-tender-offer/',
  [signatureTypes.TENDER_OFFER_BENEF]: '#/detalle-beneficiario/',
  [signatureTypes.TENDER_OFFER_SHARES]: '#/detalle-socio/',
  [signatureTypes.FREE_SIGNATURE]: '#/sociedad/',
  [signatureTypes.FREE_SIGNATURE_BENEF]: '#/detalle-beneficiario/',
  [signatureTypes.FREE_SIGNATURE_PARTNER]: '#/detalle-socio/',
  [signatureTypes.FREE_SIGNATURE_INVESTOR]: '#/detalle-inversor/',
  [signatureTypes.FREE_SIGNATURE_DIRECTOR]: '#/sociedad/',
  [signatureTypes.FREE_SIGNATURE_BENEF_ALL]: '#/sociedad/',
  [signatureTypes.FREE_SIGNATURE_PARTNER_ALL]: '#/sociedad/',
  [signatureTypes.FREE_SIGNATURE_INVESTOR_ALL]: '#/sociedad/',
  [signatureTypes.FREE_SIGNATURE_DIRECTOR_ALL]: '#/sociedad/',
  [signatureTypes.BOARD_ASSISTANTS]: '#/socios/',
  [signatureTypes.BOARD_ACT]: '#/detalle-junta/',
  [signatureTypes.BOARD_CERTIFICATE]: '#/detalle-junta/',
};
