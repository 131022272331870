/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import store from 'redux/store';
import { setModal } from 'redux/actions/modalActions';
import { updateStockPlan } from 'redux/actions/planActions';

import { formatNumber } from 'utils/formats';
import getTenderParticipantsByPlan from './utils/getTenderParticipantsByPlan';
import updateTenderParticipants from './utils/updateTenderParticipants';

const UpdateUnits = ({ plan, soldUnits }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const plans = useSelector((state) => state?.plans);
  const tenderOffers = useSelector((state) => state?.tenderOffers);
  const actualSociety = useSelector((state) => state?.society?.actualSociety);

  const [availablePlans, setAvailablePlans] = useState([]);
  const [selectedPlanId, setSelectedPlanId] = useState('');
  const [selectedPlan, setSelectedPlan] = useState(null);

  const isSelectDisabled = !plans.length;

  const getPlanOptionLabel = (plan) => {
    const { name, sharesTotal } = plan;

    const separator = ' | ';
    const units = `${formatNumber(sharesTotal)} ${t('units')}`;

    return `${name} ${separator} ${units}`;
  };

  const handleChangeSelectedPlan = (e) => {
    const planId = e.target.value;
    const plan = availablePlans.find((p) => p._id === planId);

    setSelectedPlanId(planId);
    setSelectedPlan(plan);
  };

  const handleUpdatePlan = async () => {
    try {
      const sourcePlan = plan;
      const targetPlan = selectedPlan;

      const sourcePlanSharesTotal = sourcePlan.sharesTotal - soldUnits;
      const targetPlanSharesTotal = targetPlan.sharesTotal + soldUnits;

      const participants = getTenderParticipantsByPlan(
        tenderOffers,
        actualSociety,
        sourcePlan._id
      );

      const participantsUpdated = participants.map((participant) => ({
        ...participant,
        removedFromPlan: true,
      }));

      await updateTenderParticipants(participantsUpdated);

      await store.dispatch(
        updateStockPlan(
          sourcePlan._id,
          { sharesTotal: sourcePlanSharesTotal },
          false
        )
      );

      await store.dispatch(
        updateStockPlan(
          targetPlan._id,
          { sharesTotal: targetPlanSharesTotal },
          true
        )
      );

      dispatch(setModal(null));
    } catch (error) {
      throw new Error(error);
    }
  };

  useEffect(() => {
    const currentPlanId = plan?._id;
    const otherPlans = plans.filter((p) => p._id !== currentPlanId);

    setAvailablePlans(otherPlans);
  }, [plan?._id, plans]);

  return (
    <>
      <Modal.Header>
        <h5 className="mb-0">{t('UpdateUnits')}</h5>
        <a
          className="close cursor-pointer"
          onClick={() => dispatch(setModal(null))}
        >
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>

      <Modal.Body>
        <div className="nk-modal gy-4">
          <p>
            A través de Ofertas de Compra esta sociedad ha adquirido de este
            Plan
          </p>
          <h3>
            <b>{soldUnits}</b> {t('units')}
          </h3>

          <div className="form-group">
            <label className="form-label" htmlFor="default-01">
              {t('Selecciona en cuál plan quieres depositar estas unidades?')}
            </label>
            <div className="form-control-wrap ">
              <div className="form-control-select">
                <select
                  className="form-control"
                  value={selectedPlanId}
                  disabled={isSelectDisabled}
                  onChange={handleChangeSelectedPlan}
                >
                  <option value="default-option">{t('Select')}</option>

                  {availablePlans?.map((plan) => (
                    <option value={plan._id} key={plan._id}>
                      {getPlanOptionLabel(plan)}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="nk-modal-action mt-3 mb-2">
            <button
              type="button"
              className="btn btn-lg btn-block btn-primary"
              disabled={!selectedPlanId}
              onClick={handleUpdatePlan}
            >
              {t('Update')}
            </button>
          </div>
        </div>
      </Modal.Body>
    </>
  );
};

export default UpdateUnits;
