/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import i18n from 'i18n/config';

import documentTypes from 'constants/documentTypes';

import MenuDots from 'components/MenuDots';

import menuOptions from './menuOptions';
import NoDocumentsAlert from '../List/NoDocumentsAlert';
import folders from '../../../assets/images/icon-sections/folders.svg';

import './Books.scss';

const Books = ({ setCurrentBook, section }) => {
  const { t } = useTranslation();

  const actualSociety = useSelector((state) => state.society?.actualSociety);
  const documents = useSelector((state) => state.documents);
  const minuteBooks = useSelector(
    (state) => state.society?.actualSociety?.minutebookFolders
  );
  const isDemo = useSelector((state) => state.society?.role?.isDemo);

  const [currentMenuOptions] = useState(menuOptions(i18n));

  const handleClick = (book) => {
    if (setCurrentBook) {
      setCurrentBook(book);
    }
  };

  const getDocumentsCountInBook = (id) => {
    const documentsInBook = documents.filter(
      (doc) =>
        doc?.category === documentTypes.MINUTEBOOK && doc?.subcategory === id
    ).length;

    const suffix = documentsInBook === 1 ? t('document') : t('documents');
    return `${documentsInBook} ${suffix}`;
  };

  return (
    <div className="nk-fmg-quick-list nk-block">
      <div className="nk-block-head-xs">
        <div className="nk-block-between g-2">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">{t('MinuteBooks')}</h3>
          </div>
        </div>
      </div>

      {minuteBooks?.length > 0 ? (
        <>
          <div
            className="toggle-expand-content expanded"
            data-content="quick-access"
          >
            <div className="nk-files nk-files-view-grid">
              <div className="nk-files row">
                {minuteBooks &&
                  minuteBooks.map((book) => (
                    <div className="col-6 col-sm-6 col-md-4 col-xl-3">
                      <div
                        key={book['_id']}
                        className="nk-file-item nk-file cursor-pointer w-100 h-100 mx-0"
                      >
                        <div onClick={() => handleClick(book)}>
                          <div className="nk-file-info">
                            <div className="nk-file-link">
                              <div className="nk-file-title">
                                <div className="nk-file-icon">
                                  <span className="nk-file-icon-type">
                                    <img src={folders} alt="section" />
                                  </span>
                                </div>
                                <div className="nk-file-name">
                                  <div className="nk-file-name-text">
                                    <span className="title">
                                      {t(book.label)}
                                    </span>
                                    <span className="sub-text">
                                      {getDocumentsCountInBook(book._id)}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="nk-file-meta books-menudots">
                          <div className="tb-lead">
                            <MenuDots
                              menuOptions={currentMenuOptions}
                              id={book._id}
                              params={{
                                currentBook: book,
                                actualSociety,
                                documents,
                                isDemo,
                              }}
                              direction="left"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </>
      ) : (
        <NoDocumentsAlert section={section} />
      )}
    </div>
  );
};

export default Books;
