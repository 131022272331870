import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

const labels = {
  'Society Name': 'Name',
  'Society Address': 'Address',
  'Society Fiscal Identification Number': 'CIF',
  'Society Protocol Number': 'ProtocolNumber',
  'Society Commercial Registry': 'CommercialRegistry',
  'Society Social object': 'SocialObject',
};

const SocietyTable = ({ societyData, changeSocietyParam }) => {
  const { t } = useTranslation();

  const [societyExpandView, setSocietyExpandView] = useState(false);

  return (
    <div key="society-card" className="card card-bordered card-preview mb-2">
      <table className="table table-tranx" id="society--table">
        <thead>
          <tr className="tb-tnx-head">
            <th className="text-left" colSpan={3}>
              <span>{t('Society')}</span>
            </th>
          </tr>
        </thead>
        {societyExpandView ? (
          <tbody>
            {Object.entries(societyData).map(([key, value], index) => (
              <tr className="tb-tnx-item" key={`society-${key}-card`}>
                <td className="col-4">
                  <span>
                    <b>{t(labels[key]) || key}</b>
                  </span>
                </td>
                <td className="col-6">
                  <input
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                    id={`constitution-${key}-input`}
                    type={
                      key === 'Society Commercial Registry' ? 'date' : 'text'
                    }
                    value={value}
                    className="form-control date-picker"
                    onChange={(event) =>
                      changeSocietyParam(key, event.target.value)
                    }
                  />
                </td>
                {index === 0 ? (
                  <td className="col-2 text-right">
                    <button
                      type="button"
                      className="badge btn-primary"
                      onClick={() => setSocietyExpandView(false)}
                    >
                      <em className="icon ni ni-chevron-up" />
                    </button>
                  </td>
                ) : (
                  <td className="col-2 text-right" />
                )}
              </tr>
            ))}
          </tbody>
        ) : (
          <tbody>
            <tr className="tb-tnx-item" key="society-name">
              <td className="col-8">
                <span>
                  <b>{societyData['Society Name']}</b>
                </span>
              </td>
              <td className="col-2" />
              <td className="col-2 text-right">
                <button
                  type="button"
                  className="badge btn-primary"
                  onClick={() => setSocietyExpandView(true)}
                >
                  <em className="icon ni ni-chevron-down" />
                </button>
              </td>
            </tr>
          </tbody>
        )}
      </table>
    </div>
  );
};

export default SocietyTable;
