/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import store from 'redux/store';
import { setModal } from 'redux/actions/modalActions';
import { getDocumentsByReference } from 'redux/actions/documentActions';
import AddSocietyRepresentative from 'components/Modals/AddSocietyRepresentative';
import documentTypes from 'constants/documentTypes';
import NoItemsAvailable from 'components/Modals/NoItemsAvailable';
import SocietyRepresentativeRow from './SocietyRepresentativeRow';
import statusTypes from './statusTypes';

function SocietyRepresentative({ society, isAdmin, setPage, userId }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const societyDocuments = useSelector((state) => state.documents);
  const isDemo = useSelector((state) => state.society?.role?.isDemo);

  const [representatives, setRepresentatives] = useState([]);

  const [seeNotCurrent, setSeeNotCurrent] = useState(false);

  function changeView() {
    setSeeNotCurrent(!seeNotCurrent);
  }
  const getStatus = (representative) => {
    if (!representative?.representativeStatus) {
      if (
        !representative?.endDate ||
        new Date(representative?.endDate) - new Date() > 0
      )
        return statusTypes.var.CURRENT;
      return statusTypes.var.REVOKED;
    }
    return representative?.representativeStatus;
  };

  useEffect(() => {
    dispatch(getDocumentsByReference({ societyId: society['_id'] }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [society]);

  useEffect(() => {
    if (society) {
      const filteredRepresentatives = society?.representatives?.filter(
        (representative) => {
          if (
            seeNotCurrent ||
            (!seeNotCurrent &&
              getStatus(representative) === statusTypes.var.CURRENT)
          ) {
            return representative;
          }
        }
      );

      setRepresentatives(
        filteredRepresentatives?.map((representative) => {
          const representativeDocuments = societyDocuments.filter((doc) =>
            representative.documents.includes(doc['_id'])
          );

          return (
            <SocietyRepresentativeRow
              key={representative['_id']}
              userId={userId}
              representativeId={representative['_id']}
              societyId={society['_id']}
              socialDenomination={representative.socialDenomination}
              nationality={representative.nationality}
              initDate={representative.initDate}
              endDate={representative.endDate}
              birthDate={representative.birthDate}
              nif={representative.nif}
              address={representative.address}
              email={representative.email}
              representativeType={representative.representativeType}
              representativeStatus={representative.representativeStatus}
              documents={representativeDocuments?.filter(
                (document) => document.category !== documentTypes.DELETED
              )}
            />
          );
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [society, societyDocuments, seeNotCurrent]);

  return (
    <div className="nk-block">
      <div className="nk-block-head">
        <div className="nk-block-head-content">
          <h5 className="nk-block-title title">
            {t('CompanyRepresentatives')}
          </h5>
          <div className="nk-block-des">
            <p>{t('TheseAreThePeopleOrCompanRepresentative')}</p>
          </div>
        </div>
      </div>
      <div className="nk-block">
        {isAdmin ? (
          <div className="nk-block-head">
            <div
              className="nk-block-head-content"
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <button
                type="button"
                className="btn btn-success"
                onClick={() => {
                  store.dispatch(
                    setModal(
                      <AddSocietyRepresentative
                        societyId={society['_id']}
                        action="ADD"
                        userId={userId}
                      />
                    )
                  );
                  setPage(2);
                }}
                disabled={isDemo}
              >
                {t('AddRepresentative')}
              </button>
              <div>
                <button
                  type="button"
                  className="btn btn-light"
                  onClick={() => changeView()}
                >
                  <em
                    className={
                      !seeNotCurrent ? 'icon ni ni-eye' : 'icon ni ni-eye-off'
                    }
                  />
                  <span>
                    {!seeNotCurrent ? t('SeeNotCurrent') : t('HideNotCurrent')}
                  </span>
                </button>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="card card-bordered card-preview">
          <div className="table-responsive">
            <table className="nk-tb-list nk-tb-ulist">
              <thead>
                <tr className="nk-tb-item nk-tb-head tb-tnx-head fs-11px">
                  <th className="nk-tb-col">
                    <span>{t('NameAndSurname')}</span>
                  </th>
                  <th className="nk-tb-col tb-col-xl">
                    <span>{t('Nationality')}</span>
                  </th>
                  <th className="nk-tb-col tb-col-xl">
                    <span>{t('RepresentativeDate')}</span>
                  </th>
                  <th className="nk-tb-col tb-col-xl">
                    <span>{t('RevocationDate')}</span>
                  </th>
                  <th className="nk-tb-col tb-col-xl">
                    <span>{t('E-Mail')}</span>
                  </th>
                  <th className="nk-tb-col">
                    <span>{t('TypeOfAuthority')}</span>
                  </th>
                  <th className="nk-tb-col">
                    <span>{t('Status')}</span>
                  </th>
                  <th className="nk-tb-col tb-col-xl text-center">
                    <span>{t('Doc')}</span>
                  </th>
                  {!isDemo && (
                    <th className="nk-tb-col">
                      <span />
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {representatives?.length
                  ? representatives
                  : NoItemsAvailable({
                      mainMessage: t('NoRepresentativesAvailable'),
                      infoMessage: '',
                    })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SocietyRepresentative;
