import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';

import toggleSideNav from 'redux/actions/sideNavActions';

import isSuperAdmin from 'utils/isSuperAdmin';

import useRolesValidation from 'hooks/useRolesValidation';

import DilutionBadge from './DilutionBadge';
import './SideNav.scss';

function ExtendedMenu({ societyId, userId = null }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { hasAccessCaptable, isSocietyHolding } = useSelector(
    (state) => state?.society?.actualSociety
  ) || {
    hasAccessCaptable: false,
    isSocietyHolding: false,
  };

  const { role } = useSelector((state) => state?.society) || {};
  const { user } = useSelector((state) => state);
  const isDemo = useSelector((state) => state.society?.role?.isDemo);

  const { isAdmin, isPartner, isBeneficiary, isInvestor } = useSelector(
    (state) =>
      state?.society?.role || {
        isAdmin: false,
        isPartner: false,
        isBeneficiary: false,
        isInvestor: false,
        isHolder: false,
      }
  );

  const rolesValidation = useRolesValidation(role);

  const hasDashboardMenu = rolesValidation.isAdmin || rolesValidation.isPartner;
  const hasPartnersMenu = !rolesValidation.isBeneficiaryOnly;
  const hasDraftsMenu = !rolesValidation.isBeneficiaryOnly;
  const hasCommunicationsMenu = !rolesValidation.isBeneficiaryOnly;
  const hasBoardMenu = !rolesValidation.isBeneficiaryOnly;
  const hasDocumentsMenu = !rolesValidation.isBeneficiaryOnly;
  const hasSocietyDataMenu = !rolesValidation.isBeneficiaryOnly;
  const hasPlansMenu = true;
  const hasTransactionsMenu = true;
  const hasPortfolioMenu =
    rolesValidation.isHolder || (rolesValidation.isAdmin && isSocietyHolding);

  const goToDashboard = () => {
    window.location.href = `#/sociedad/${societyId}`;
  };

  const getBoardLinkByRole = () => {
    if (isAdmin) {
      return `/juntas/${societyId}`;
    }

    if (isPartner) {
      return `/juntas/${societyId}/${userId}`;
    }

    return '';
  };

  return (
    <Accordion id="main-menu__extended" className="pl-4 p-md-0">
      {hasDashboardMenu && (
        <Accordion.Toggle as={Button} variant="link" eventKey="0">
          <li
            className={`nk-menu-item has-sub ${
              !isAdmin ? 'is-disable cursor-not-allowed' : ''
            }`}
            onClick={() => goToDashboard()}
          >
            <a className="nk-menu-link nk-menu-toggle">
              <span className="nk-menu-icon">
                <em className="icon ni ni-meter" />
              </span>
              <span className="nk-menu-text">Dashboard</span>
            </a>
          </li>
        </Accordion.Toggle>
      )}

      {hasPartnersMenu && (
        <>
          <Accordion.Toggle as={Button} variant="link" eventKey="1">
            <li className="nk-menu-item has-sub">
              <a className="nk-menu-link nk-menu-toggle">
                <span className="nk-menu-icon">
                  <em className="icon ni ni-users" />
                </span>
                <span className="nk-menu-text">{t('Partners')}</span>
                <DilutionBadge />
              </a>
            </li>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="1">
            <ul className="nk-menu-sub">
              <li className="nk-menu-item">
                <Link
                  to={`/socios/${societyId}/${userId}`}
                  className={`nk-menu-link
              ${
                (!isAdmin && (!isPartner || !hasAccessCaptable)) ||
                (isAdmin && !isPartner)
                  ? 'is-disable'
                  : ''
              }
              `}
                  onClick={() => dispatch(toggleSideNav())}
                >
                  <span className="nk-menu-text">{t('MyCard')}</span>
                </Link>
              </li>

              <li className="nk-menu-item">
                <Link
                  to={`/socios/${societyId}`}
                  className={`nk-menu-link ${
                    !isAdmin && !isPartner && 'is-disable'
                  }`}
                  onClick={() => dispatch(toggleSideNav())}
                >
                  <span className="nk-menu-text">{t('Partners')}</span>
                </Link>
              </li>

              <li className="nk-menu-item">
                <Link
                  to={`/socios-libro/${societyId}`}
                  className={`nk-menu-link ${
                    !isAdmin &&
                    (!isPartner || !hasAccessCaptable) &&
                    'is-disable'
                  }`}
                  onClick={() => dispatch(toggleSideNav())}
                >
                  <span className="nk-menu-text">{t('PartnerBook')}</span>
                </Link>
              </li>

              <li className="nk-menu-item">
                <Link
                  to={`/socios-fichas/${societyId}`}
                  className={`nk-menu-link ${
                    !isAdmin && !isPartner && 'is-disable'
                  }`}
                  onClick={() => dispatch(toggleSideNav())}
                >
                  <span className="nk-menu-text">{t('PartnersCards')}</span>
                </Link>
              </li>
              <li className="nk-menu-item">
                <Link
                  to={`/inversores/${societyId}`}
                  className={`nk-menu-link ${
                    !isAdmin && !isInvestor && 'is-disable'
                  }`}
                  onClick={() => dispatch(toggleSideNav())}
                >
                  <span className="nk-menu-text">{t('Investors')}</span>
                </Link>
              </li>
              <li className="nk-menu-item">
                <Link
                  to={`/clases-participaciones/${societyId}`}
                  className={`nk-menu-link ${
                    !isAdmin &&
                    (!isPartner || !hasAccessCaptable) &&
                    'is-disable'
                  }`}
                  onClick={() => dispatch(toggleSideNav())}
                >
                  <span className="nk-menu-text">{t('HoldingClasses')}</span>
                </Link>
              </li>

              <li className="nk-menu-item">
                <Link
                  to={`/socios-categorias/${societyId}`}
                  className={`nk-menu-link ${
                    !isAdmin &&
                    (!isPartner || !hasAccessCaptable) &&
                    'is-disable'
                  }`}
                  onClick={() => dispatch(toggleSideNav())}
                >
                  <span className="nk-menu-text">{t('PartnerCategories')}</span>
                </Link>
              </li>
            </ul>
          </Accordion.Collapse>
        </>
      )}

      {hasPortfolioMenu && (
        <>
          <Accordion.Toggle as={Button} variant="link" eventKey="2">
            <li className="nk-menu-item has-sub">
              <a className="nk-menu-link nk-menu-toggle">
                <span className="nk-menu-icon">
                  <em className="icon ni ni-layers" />
                </span>
                <span className="nk-menu-text">{t('Portfolio')}</span>
              </a>
            </li>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="2">
            <ul className="nk-menu-sub">
              <li className="nk-menu-item">
                <Link
                  to={`/cuadro-portafolio/${societyId}`}
                  className="nk-menu-link"
                  onClick={() => dispatch(toggleSideNav())}
                >
                  <span className="nk-menu-text">{t('Portfolio')}</span>
                </Link>
              </li>
            </ul>
          </Accordion.Collapse>

          <Accordion.Collapse eventKey="2">
            <ul className="nk-menu-sub">
              <li className="nk-menu-item">
                <Link
                  to={`/diagrama-portafolio/${societyId}`}
                  className="nk-menu-link"
                  onClick={() => dispatch(toggleSideNav())}
                >
                  <span className="nk-menu-text">{t('Chart')}</span>
                </Link>
              </li>
            </ul>
          </Accordion.Collapse>
        </>
      )}

      {hasDraftsMenu && (
        <>
          <Accordion.Toggle as={Button} variant="link" eventKey="3">
            <li className="nk-menu-item has-sub">
              <a className="nk-menu-link nk-menu-toggle">
                <span className="nk-menu-icon">
                  <em className="icon ni ni-edit" />
                </span>
                <span className="nk-menu-text">{t('Drafts')}</span>
              </a>
            </li>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="3">
            <ul className="nk-menu-sub">
              <li className="nk-menu-item">
                <Link
                  to={`/borradores/${societyId}`}
                  className={`nk-menu-link ${!isAdmin && 'is-disable'}`}
                  onClick={() => dispatch(toggleSideNav())}
                >
                  <span className="nk-menu-text">{t('DraftsList')}</span>
                </Link>
              </li>
              <li className="nk-menu-item">
                <Link
                  to={`/borrador-ampliacion/${societyId}`}
                  className={`nk-menu-link ${!isAdmin && 'is-disable'}`}
                  onClick={() => dispatch(toggleSideNav())}
                >
                  <span className="nk-menu-text">
                    {t('DraftCapitalIncrease')}
                  </span>
                </Link>
              </li>
              <li className="nk-menu-item">
                <Link
                  to={`/borrador-venta/${societyId}`}
                  className={`nk-menu-link ${!isAdmin && 'is-disable'}`}
                  onClick={() => dispatch(toggleSideNav())}
                >
                  <span className="nk-menu-text">{t('DraftSellShares')}</span>
                </Link>
              </li>
              <li className="nk-menu-item">
                <Link
                  to={`/borrador-nota/${societyId}`}
                  className={`nk-menu-link ${!isAdmin && 'is-disable'}`}
                  onClick={() => dispatch(toggleSideNav())}
                >
                  <span className="nk-menu-text">
                    {t('DraftConvertibleNote')}
                  </span>
                </Link>
              </li>
              <li className="nk-menu-item">
                <Link
                  to={`/borrador-compra-venta/${societyId}`}
                  className={`nk-menu-link ${!isAdmin && 'is-disable'}`}
                  onClick={() => dispatch(toggleSideNav())}
                >
                  <span className="nk-menu-text">
                    {t('DraftSellParticipations')}
                  </span>
                </Link>
              </li>
            </ul>
          </Accordion.Collapse>
        </>
      )}

      {hasPlansMenu && (
        <>
          <Accordion.Toggle as={Button} variant="link" eventKey="4">
            <li className="nk-menu-item has-sub">
              <a className="nk-menu-link nk-menu-toggle">
                <span className="nk-menu-icon">
                  <em className="icon ni ni-star" />
                </span>
                <span className="nk-menu-text">{t('Plans')}</span>
              </a>
            </li>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="4">
            <ul className="nk-menu-sub">
              <li className="nk-menu-item">
                <Link
                  to={`/beneficiarios-planes/${societyId}`}
                  className={`nk-menu-link ${!isAdmin && 'is-disable'}`}
                  onClick={() => dispatch(toggleSideNav())}
                >
                  <span className="nk-menu-text">{t('PlansList')}</span>
                </Link>
              </li>
              <li className="nk-menu-item">
                <Link
                  to={`/beneficiarios/${societyId}`}
                  className={`nk-menu-link ${
                    !isAdmin && !isBeneficiary && 'is-disable'
                  }`}
                  onClick={() => dispatch(toggleSideNav())}
                >
                  <span className="nk-menu-text">{t('BeneficiariesList')}</span>
                </Link>
              </li>
              <li className="nk-menu-item">
                <Link
                  to={`/beneficiarios-fichas/${societyId}`}
                  className={`nk-menu-link ${
                    !isAdmin && !isBeneficiary && 'is-disable'
                  }`}
                  onClick={() => dispatch(toggleSideNav())}
                >
                  <span className="nk-menu-text">
                    {t('BeneficiariesCards')}
                  </span>
                </Link>
              </li>
              <li className="nk-menu-item">
                <Link
                  to={`/resumen-planes/${societyId}`}
                  className={`nk-menu-link ${!isAdmin && 'is-disable'}`}
                  onClick={() => dispatch(toggleSideNav())}
                >
                  <span className="nk-menu-text">{t('SummaryView')}</span>
                </Link>
              </li>
              <li className="nk-menu-item">
                <Link
                  to={`/beneficiarios-borradores/${societyId}`}
                  className={`nk-menu-link ${!isAdmin && 'is-disable'}`}
                  onClick={() => dispatch(toggleSideNav())}
                >
                  <span className="nk-menu-text">
                    {t('BeneficiariesDraftsMenu')}
                  </span>
                </Link>
              </li>
              {isSuperAdmin(user) && (
                <>
                  <li className="nk-menu-item">
                    <Link
                      to={`/empleados/${societyId}`}
                      className={`nk-menu-link ${!isAdmin && 'is-disable'}`}
                      onClick={() => dispatch(toggleSideNav())}
                    >
                      <span className="nk-menu-text">{t('Employees')}</span>
                    </Link>
                  </li>
                  <li className="nk-menu-item">
                    <Link
                      to={`/empleados-planes/${societyId}`}
                      className={`nk-menu-link ${!isAdmin && 'is-disable'}`}
                      onClick={() => dispatch(toggleSideNav())}
                    >
                      <span className="nk-menu-text">
                        {t('EmployeesFilter')}
                      </span>
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </Accordion.Collapse>
        </>
      )}

      {hasTransactionsMenu && (
        <>
          <Accordion.Toggle as={Button} variant="link" eventKey="5">
            <li className="nk-menu-item has-sub">
              <a className="nk-menu-link nk-menu-toggle">
                <span className="nk-menu-icon">
                  <em className="icon ni ni-exchange" />
                </span>
                <span className="nk-menu-text">{t('Transactions')}</span>
              </a>
            </li>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="5">
            <ul className="nk-menu-sub">
              <li className="nk-menu-item">
                <Link
                  to={`/transacciones/${societyId}`}
                  className={`nk-menu-link ${!isAdmin && 'is-disable'}`}
                  onClick={() => dispatch(toggleSideNav())}
                >
                  <span className="nk-menu-text">{t('TransactionsList')}</span>
                </Link>
              </li>
              {isAdmin && (
                <>
                  <li className="nk-menu-item">
                    <Link
                      to={`/tender-offers/${societyId}`}
                      className={`nk-menu-link ${!isAdmin && 'is-disable'}`}
                      onClick={() => dispatch(toggleSideNav())}
                    >
                      <span className="nk-menu-text">{t('TenderOffers')}</span>
                    </Link>
                  </li>
                  <li className="nk-menu-item">
                    <Link
                      to={`/tender-offers-shares/${societyId}`}
                      className={`nk-menu-link ${!isAdmin && 'is-disable'}`}
                      onClick={() => dispatch(toggleSideNav())}
                    >
                      <span className="nk-menu-text">
                        {t('TenderOffersShares')}
                      </span>
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </Accordion.Collapse>
        </>
      )}

      {hasCommunicationsMenu && (
        <>
          <Accordion.Toggle as={Button} variant="link" eventKey="6">
            <li className="nk-menu-item has-sub">
              <a className="nk-menu-link nk-menu-toggle">
                <span className="nk-menu-icon">
                  <em className="icon ni ni-mail" />
                </span>
                <span className="nk-menu-text">{t('Communications')}</span>
              </a>
            </li>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="6">
            <ul className="nk-menu-sub">
              <li className="nk-menu-item">
                <Link
                  to={`/comunicaciones/${societyId}`}
                  className={`nk-menu-link ${!isAdmin && 'is-disable'}`}
                  onClick={() => dispatch(toggleSideNav())}
                >
                  <span className="nk-menu-text">{t('Communications')}</span>
                </Link>
              </li>
            </ul>
          </Accordion.Collapse>
        </>
      )}

      {hasBoardMenu && (
        <>
          <Accordion.Toggle as={Button} variant="link" eventKey="7">
            <li className="nk-menu-item has-sub">
              <a className="nk-menu-link nk-menu-toggle">
                <span className="nk-menu-icon">
                  <em className="icon ni ni-chat-circle" />
                </span>
                <span className="nk-menu-text">{t('BoardCouncil')}</span>
              </a>
            </li>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="7">
            <ul className="nk-menu-sub">
              <li className="nk-menu-item">
                <Link
                  to={getBoardLinkByRole()}
                  className={`nk-menu-link ${
                    !isAdmin && !isPartner && 'is-disable'
                  }`}
                  onClick={() => dispatch(toggleSideNav())}
                >
                  <span className="nk-menu-text">{t('Boards')}</span>
                </Link>
              </li>
            </ul>
          </Accordion.Collapse>
        </>
      )}

      {hasDocumentsMenu && (
        <>
          <Accordion.Toggle as={Button} variant="link" eventKey="8">
            <li className="nk-menu-item has-sub">
              <a className="nk-menu-link nk-menu-toggle">
                <span className="nk-menu-icon">
                  <em className="icon ni ni-files" />
                </span>
                <span className="nk-menu-text">{t('Documents')}</span>
              </a>
            </li>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="8">
            <ul className="nk-menu-sub">
              <li className="nk-menu-item">
                <Link
                  to={`/documentos/${societyId}`}
                  className={`nk-menu-link ${!isAdmin && 'is-disable'}`}
                  onClick={() => dispatch(toggleSideNav())}
                >
                  <span className="nk-menu-text">{t('Documents')}</span>
                </Link>
              </li>
            </ul>
          </Accordion.Collapse>
        </>
      )}

      {hasSocietyDataMenu && (
        <>
          <Accordion.Toggle as={Button} variant="link" eventKey="9">
            <li className="nk-menu-item has-sub">
              <a className="nk-menu-link nk-menu-toggle">
                <span className="nk-menu-icon">
                  <em className="icon ni ni-setting" />
                </span>
                <span className="nk-menu-text">{t('SocietyData')}</span>
              </a>
            </li>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="9">
            <ul className="nk-menu-sub">
              <li className="nk-menu-item">
                <Link
                  to={`/perfil-sociedad/datos/${societyId}`}
                  className={`nk-menu-link ${!isAdmin && 'is-disable'}`}
                  onClick={() => dispatch(toggleSideNav())}
                >
                  <span className="nk-menu-text">{t('SocietyProfile')}</span>
                </Link>
              </li>
              <li className="nk-menu-item">
                <Link
                  to={`/perfil-sociedad/organo/${societyId}`}
                  className={`nk-menu-link ${!isAdmin && 'is-disable'}`}
                >
                  <span className="nk-menu-text">{t('Directorship')}</span>
                </Link>
              </li>
              <li className="nk-menu-item">
                <Link
                  to={`/perfil-sociedad/representative/${societyId}`}
                  className={`nk-menu-link ${!isAdmin && 'is-disable'}`}
                >
                  <span className="nk-menu-text">{t('Representatives')}</span>
                </Link>
              </li>
              <li className="nk-menu-item">
                <Link
                  to={`/perfil-sociedad/auditors/${societyId}`}
                  className={`nk-menu-link ${!isAdmin && 'is-disable'}`}
                >
                  <span className="nk-menu-text">{t('Auditors')}</span>
                </Link>
              </li>
              <li className="nk-menu-item">
                <Link
                  to={`/perfil-sociedad/valor/${societyId}`}
                  className={`nk-menu-link ${!isAdmin && 'is-disable'}`}
                  onClick={() => dispatch(toggleSideNav())}
                >
                  <span className="nk-menu-text">{t('ValueSociety')}</span>
                </Link>
              </li>
              <li className="nk-menu-item">
                <Link
                  to={`/perfil-sociedad/valor-participaciones/${societyId}`}
                  className={`nk-menu-link ${!isAdmin && 'is-disable'}`}
                  onClick={() => dispatch(toggleSideNav())}
                >
                  <span className="nk-menu-text">{t('ValueShares')}</span>
                </Link>
              </li>
              <li className="nk-menu-item">
                <Link
                  to={`/perfil-sociedad/administradores/${societyId}`}
                  className={`nk-menu-link ${
                    (!isAdmin || isDemo) && 'is-disable'
                  }`}
                  onClick={() => dispatch(toggleSideNav())}
                >
                  <span className="nk-menu-text">{t('OtherUsers')}</span>
                </Link>
              </li>
              {isSuperAdmin(user) && (
                <li className="nk-menu-item">
                  <Link
                    to={`/perfil-sociedad/integraciones/${societyId}`}
                    className={`nk-menu-link ${
                      (!isAdmin || isDemo) && 'is-disable'
                    }`}
                    onClick={() => dispatch(toggleSideNav())}
                  >
                    <span className="nk-menu-text">{t('Integrations')}</span>
                  </Link>
                </li>
              )}
              {/* <li className="nk-menu-item">
                <Link
                  to={`/perfil-sociedad/suscripcion/${societyId}`}
                  className="nk-menu-link is-disable"
                >
                  <span className="nk-menu-text">{t('Subscription')}</span>
                </Link>
              </li> */}
              <li className="nk-menu-item">
                <Link
                  to={`/perfil-sociedad/configuracion/${societyId}`}
                  className={`nk-menu-link ${!isAdmin && 'is-disable'}`}
                >
                  <span className="nk-menu-text">{t('Customization')}</span>
                </Link>
              </li>
            </ul>
          </Accordion.Collapse>
        </>
      )}
    </Accordion>
  );
}

export default ExtendedMenu;
