import React from 'react';
import { useTranslation } from 'react-i18next';

import alertIcon from '../../../assets/images/icon-sections/alert-primary.svg';

const NoDocumentsAlert = ({ section }) => {
  const { t } = useTranslation();

  const message = {
    ALL: t('NoDocsInThisSection'),
    DOCUMENTS: t('NoDocsInThisSection'),
    SCRIPTURES: t('NoDocsInThisSection'),
    MINUTEBOOK: t('NoMinutebookInThisSection'),
    AUTHORITY: t('NoDocsInThisSection'),
    ASSIGNMENTS: t('NoDocsInThisSection'),
    ANNUALREPORTS: t('NoDocsInThisSection'),
    SIGNATURES: t('NoDocsInThisSection'),
    TEMPLATES: t('NoDocsInThisSection'),
    LETTERS: t('NoDocsInThisSection'),
    CERTIFICATES: t('NoDocsInThisSection'),
    BOARDS: t('NoDocsInThisSection'),
    DELETED: t('NoDocsInThisSection'),
    MYFOLDERS: t('NoFolderInThisSection'),
    COUNCILMINUTES: t('NoCouncilMinutesInThisSection'),
  };
  return (
    <div className="nk-files-list">
      <div className="card card-bordered w-100">
        <div className="card-inner">
          <div className="nk-help">
            <div className="nk-help-img">
              <img src={alertIcon} alt="section" />
            </div>
            <div className="nk-help-text">
              <h5>{message[section.tag]}</h5>
              <p className="text-soft">{t(section?.emptyStateMessage) || ''}</p>
            </div>
            {section?.emptyStateAction && (
              <div className="nk-help-action">
                <li>
                  <a
                    href="#file-upload"
                    className="btn btn-primary"
                    data-toggle="modal"
                  >
                    <em className="icon ni ni-upload-cloud" />{' '}
                    <span>{t('UploadScripture')}</span>
                  </a>
                </li>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoDocumentsAlert;
