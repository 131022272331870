import React from 'react';

const NewTemplate = ({ templateData }) => (
  <table className="email-body text-left">
    <tbody>
      <tr>
        <td className="px-3 px-sm-5 pt-3 pt-sm-5 pb-3">
          <h2 className="email-heading">
            Convocatoria Junta de {templateData?.societyName}
          </h2>
        </td>
      </tr>

      <tr>
        <td className="px-3 px-sm-5 pb-2">
          <p>{templateData?.announcement}</p>

          <h6 className="title">{templateData?.boardName}</h6>

          <table className="w-100">
            <tbody>
              <tr>
                <td className="w-100">
                  <ul className="email-ul email-ul-col2">
                    <li>Fecha: {templateData?.date}</li>
                    <li>Hora: {templateData?.time}</li>
                    <li>Tipo junta: {templateData?.boardType}</li>
                    <li>Videollamada: {templateData?.onlineAccess}</li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
          <p>Lugar de celebración: {templateData?.place}</p>

          <h6 className="title">Delegación de voto</h6>
          <p>Si no puedes asistir a la Junta, puedes delegar tu voto aquí:</p>
          <span className="email-btn email-btn-sm mt-2">Delegar mi voto</span>

          <div className="sp-plan-desc sp-plan-desc-mb">
            <br />
            <br />
            <h6 className="title">Acceso a la Junta y votación</h6>
            <p>Puedes acceder a los detalles y documentación de la Junta:</p>
          </div>
          <br />
          <span className="email-btn">Acceder a la junta</span>
        </td>
      </tr>

      <tr>
        <td className="px-3 px-sm-5 pt-4 pb-3 pb-sm-5">
          <p>
            El proceso está gestionado a través de la plataforma Sttok desde la
            cual podrás acceder a la convocatoria, poder revisar la
            documentación y votar.
          </p>
        </td>
      </tr>
    </tbody>
  </table>
);

export default NewTemplate;
