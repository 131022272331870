/* eslint-disable no-case-declarations */
import actionTypes from 'redux/actions/actionTypes';
import initialState from '../initialState';

export default function reducer(
  tenderOffer = initialState.tenderOffers,
  action
) {
  switch (action.type) {
    case actionTypes.CREATE_TENDER_OFFER:
      return [...tenderOffer, action.tenderOffer];

    case actionTypes.GET_TENDER_OFFERS:
      return action.data;

    case actionTypes.UPDATE_TENDER_OFFER:
      return [
        ...tenderOffer.filter(
          (tenderOffer) => tenderOffer['_id'] !== action.tenderOffer['_id']
        ),
        action.tenderOffer,
      ];

    case actionTypes.DELETE_TENDER_OFFER:
      return [
        ...tenderOffer.filter(
          (currentPlan) => currentPlan['_id'] !== action.tenderOffer['_id']
        ),
      ];

    default:
      return tenderOffer;
  }
}
