import React from 'react';
import { useTranslation } from 'react-i18next';

import BeneficiaryPlanDetails from 'components/BeneficiaryPlanDetails';

const DetailsTab = ({ beneficiary, society, plan }) => {
  const { t } = useTranslation();
  return (
    <div className="card-inner p-0">
      <h6>{t('PlanDetail')}</h6>
      <p className="text-justify">
        {t('PlanInvitationOfSoc', { societyName: society?.name })}
      </p>

      <BeneficiaryPlanDetails
        plan={plan}
        society={society}
        beneficiary={beneficiary}
      />

      <p>{t('YouWillHaveCopyWithDetails')}</p>
    </div>
  );
};

export default DetailsTab;
