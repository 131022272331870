/* eslint-disable react/jsx-no-bind */
import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';

import '../../assets/styles/Icons.scss';
import '../../assets/styles/Bootstrap.scss';
import './UserMenuDots.scss';

function UserMenuDots({ menuOptions }) {
  const { logout } = useAuth0();
  const { user } = useSelector((state) => state);
  const isDemo = useSelector((state) => state.society?.role?.isDemo);

  const options = isDemo ? menuOptions.slice(0, -1) : menuOptions;

  function onLogout() {
    localStorage.removeItem('token');
    localStorage.removeItem('access-token');
    logout({ returnTo: window.location.origin });
  }

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="dropdown-toggle mr-lg-n1"
    >
      {children}
      <div className="user-toggle">
        <div className="user-avatar sm">
          {user ? (
            <img src={user.image} alt={user.name} className="rounded-circle" />
          ) : (
            <em className="icon ni ni-user-alt" />
          )}
        </div>
      </div>
    </div>
  ));
  CustomToggle.displayName = 'CustomToggle';

  const menu = options.map((menuOption) => (
    <Link to={menuOption.url} key={menuOption.text}>
      <em className={`icon ni ${menuOption.icon}`} />
      <span>{menuOption.text}</span>
    </Link>
  ));

  menu.push(
    <Dropdown.Item onClick={onLogout} key="signout">
      <em className="icon ni ni-signout" />
      <span>Salir</span>
    </Dropdown.Item>
  );

  return (
    <Dropdown drop={menuOptions.direction}>
      <Dropdown.Toggle as={CustomToggle} />
      <Dropdown.Menu
        size="sm"
        title=""
        className="link-list-opt no-bdr dropdown-menu dropdown-menu-s1"
      >
        <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block py-2">
          <div className="user-card">
            <div className="user-avatar">
              {user ? (
                <img
                  src={user.image}
                  alt={user.name}
                  className="rounded-circle"
                />
              ) : (
                <em className="icon ni ni-user-alt" />
              )}
            </div>
            <div className="user-info">
              <span className="lead-text">{user?.name || ''}</span>
              <span className="sub-text">{user?.email}</span>
            </div>
          </div>
        </div>
        {menu}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default UserMenuDots;
