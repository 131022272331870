import alertBodyTypes from 'components/Alert/alertBodyTypes';

import { decryptResponse } from 'utils/token';
import axios from '../../interceptors/axios';

import { addAlert } from './alertActions';

export const getBeneficiaryById = (id) => async (dispatch) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/beneficiaries/${id}`
    );

    const decodedData = await decryptResponse(data);
    return decodedData;
  } catch (error) {
    dispatch(addAlert(alertBodyTypes.ERROR_GETTING_BENEFICIARY));
    throw new Error('Error obteniendo el beneficiario', error);
  }
};
export async function getBeneficiaryData(
  { beneficiaryId, societyId, userId, source },
  setter
) {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL_V2}/beneficiaries`,
      { params: { beneficiaryId, societyId, userId, source } }
    );

    const decodedData = await decryptResponse(data);
    if (setter) setter(decodedData);
    return decodedData;
  } catch (error) {
    console.log(error);
  }
}

export const updateBeneficiary =
  ({ id, data, showAlert = true }) =>
  async (dispatch) => {
    const body = {
      beneficiaryId: id,
      ...data,
    };

    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL_V2}/beneficiaries`,
        body
      );

      if (showAlert) {
        dispatch(addAlert(alertBodyTypes.BENEFICIARY_UPDATED));
      }
    } catch (error) {
      dispatch(addAlert(alertBodyTypes.ERROR_UPDATING_BENEFICIARY));
    }
  };
