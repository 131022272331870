import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import IconSuccess from './IconSuccess';
import IconInfo from './IconInfo';

const sttokURL = 'https://www.sttok.com';
const sttokDemoURL = 'https://calendly.com/sttok/30min';

const DelegationMessageBox = ({ icon, title, description, link, linkText }) => {
  const { t } = useTranslation();

  return (
    <div
      className="nk-block nk-block-middle nk-auth-body wide-xs"
      style={{
        height: 'calc(100vh - 80px)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div className="brand-logo pb-5 text-center">
        <a className="logo-link">
          <img
            className="logo-dark logo-img logo-img-lg"
            src="https://www.sttok.com/images/logo-dark2x.png"
            alt="logo-dark"
          />
        </a>
      </div>

      <div className="card card-bordered">
        <div className="card-inner card-inner-lg">
          <div className="nk-block-head">
            <div className="nk-block-head-content">
              {icon === 'SUCCESS' && <IconSuccess />}
              {icon === 'INFO' && <IconInfo />}

              <h4 className="nk-block-title text-center">{title}</h4>
              <div className="nk-block-des">
                <p>{description}</p>
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="form-label-group">
              <label className="form-label" htmlFor="default-01">
                {t('NowYouCan')}:
              </label>
            </div>
          </div>

          <div className="form-group">
            <Link to={link} className="btn btn-lg btn-primary btn-block">
              {linkText}
            </Link>
          </div>
          <div className="nk-block-des text-center">
            <p>
              <b>{t('DoYouWantToKnowMoreAboutSttok')}</b>
            </p>
          </div>

          <ul className="nav justify-center gx-4">
            <a
              className="nav-link"
              target="_blank"
              href={sttokURL}
              rel="noreferrer"
            >
              Sttok
            </a>
            <a
              className="nav-link"
              target="_blank"
              href={sttokDemoURL}
              rel="noreferrer"
            >
              {t('BookADemo')}
            </a>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default DelegationMessageBox;
