/* eslint-disable no-case-declarations */
import actionTypes from 'redux/actions/actionTypes';
import initialState from '../initialState';

export default function reducer(employees = initialState.employees, action) {
  switch (action.type) {
    case actionTypes.CREATE_EMPLOYEE:
      return [...employees, action.employees];

    case actionTypes.GET_EMPLOYEES:
      return action.data;

    case actionTypes.UPDATE_EMPLOYEE:
      return [
        ...employees.filter(
          (employee) => employee['_id'] !== action.employee['_id']
        ),
        action.employee,
      ];

    case actionTypes.DELETE_EMPLOYEE:
      return [
        ...employees.filter(
          (employee) => employee['_id'] !== action.employee['_id']
        ),
      ];

    default:
      return employees;
  }
}
