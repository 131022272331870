import React, { FC } from 'react';

import DrawCell from '../DrawCell';

type Props = {
  rowIndex: number;
  rowsCount: number;
  text: boolean;
  previousColumnsLength: number;
  accumulatedNominalValue: number;
  totalPremiumValue: number;
};

const DrawInversionColumns: FC<Props> = ({
  rowIndex,
  rowsCount,
  text,
  previousColumnsLength,
  accumulatedNominalValue,
  totalPremiumValue,
}) => (
  <>
    <DrawCell
      columnIndex={0}
      value={accumulatedNominalValue}
      text={text}
      previousColumnsLength={previousColumnsLength}
      rowIndex={rowIndex}
      rowsCount={rowsCount}
      decimal
    />
    <DrawCell
      columnIndex={1}
      value={totalPremiumValue}
      text={text}
      previousColumnsLength={previousColumnsLength}
      rowIndex={rowIndex}
      rowsCount={rowsCount}
      decimal
    />
    <DrawCell
      columnIndex={2}
      value={accumulatedNominalValue + totalPremiumValue}
      text={text}
      previousColumnsLength={previousColumnsLength}
      rowIndex={rowIndex}
      rowsCount={rowsCount}
      decimal
    />
  </>
);

export default DrawInversionColumns;
