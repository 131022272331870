import React, { useState } from 'react';
import i18n from 'i18n/config';

import List from 'components/Documents/List';
import Books from 'components/Documents/Books';
import documentSections from 'constants/documentSections';
import documentTypes from 'constants/documentTypes';

const currentSection = documentTypes.MINUTEBOOK;

const MinuteBook = ({
  documents,
  isLoadingData,
  currentBook,
  setCurrentBook,
  searchTerm,
}) => {
  const [section] = useState(
    documentSections(i18n).find((s) => s.tag === currentSection)
  );

  return (
    <>
      {!currentBook ? (
        <div className="nk-fmg-body-content">
          <Books setCurrentBook={setCurrentBook} section={section} />
        </div>
      ) : (
        <List
          section={section}
          documents={documents}
          isLoadingData={isLoadingData}
          currentBook={currentBook}
          setCurrentBook={setCurrentBook}
          searchTerm={searchTerm}
        />
      )}
    </>
  );
};

export default MinuteBook;
