/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { updateUser } from 'redux/actions/userActions';
import { updateSociety } from 'redux/actions/societyActions';
import { addInvestor } from 'redux/actions/modalsActions';
import { updateDraft } from 'redux/actions/draftActions';
import { setModal } from 'redux/actions/modalActions';
import convertibleNoteStatus from 'constants/convertibleNoteStatus';
import convertibleNoteStatusOptions from 'constants/convertibleNoteStatusOptions';
import '../Modals.scss';

function ChangeNcStatus({ draft, setStatus }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user, drafts, actualSociety } = useSelector((state) => ({
    user: state?.user,
    actualSociety: state?.society?.actualSociety,
    drafts: state?.drafts,
  }));
  const [currentDraft, setCurrentDraft] = useState({
    ...draft,
    draftPartners:
      typeof draft?.draftPartners === 'string'
        ? draft?.draftPartners
        : JSON.stringify(draft?.draftPartners),
  });
  const [partners, setPartners] = useState();
  const [noteStatus, setNoteStatus] = useState(currentDraft?.status);

  const handleUpdateStatus = async () => {
    if (
      noteStatus === convertibleNoteStatus.var.NC_ACTIVE &&
      draft?.status !== convertibleNoteStatus.var.NC_ACTIVE
    ) {
      for (const partner of partners) {
        const investorData = {
          name: partner?.name,
          cif: partner?.cif || null,
          email: partner?.email || null,
          societyPartnerId: partner?.societyPartnerId || null,
          sharesCount: partner?.sharesCount || { drafts: partner?.newShares },
          contributionDate: new Date(partner?.contributionDate),
          contribution: partner?.contribution,
          totalContribution: partner?.totalPartnerContribution,
          discountPercent: partner?.discountPercent,
        };
        if (partner?.investorId) {
          const currentInvestor = actualSociety?.investors?.find(
            (investor) => investor['_id'] === partner.investorId
          );
          await dispatch(
            updateUser(
              currentInvestor?.user,
              {
                investor: currentInvestor['_id'],
                society: actualSociety?.['_id'],
                investorData: { ...investorData },
              },
              user['_id'],
              false
            )
          );
        } else {
          const newInvestor = {
            ...investorData,
            society: actualSociety['_id'],
            address: partner?.address,
            userId: user?.['_id'],
            userEmail: user?.email,
            draft: draft?.['_id'],
          };
          dispatch(addInvestor(newInvestor));
        }
      }
    } else if (
      noteStatus !== convertibleNoteStatus.var.NC_ACTIVE &&
      draft?.status === convertibleNoteStatus.var.NC_ACTIVE
    ) {
      const investorsToDelete = actualSociety?.investors?.filter(
        (investor) => investor?.draft === draft['_id']
      );
      for (const investor of investorsToDelete) {
        dispatch(
          updateSociety(
            actualSociety['_id'],
            { investor: { remove: investor['_id'], user: investor?.user } },
            false
          )
        );
      }
    }
    dispatch(updateDraft(currentDraft['_id'], currentDraft));
    if (setStatus) {
      setStatus(currentDraft.status);
    }
    dispatch(setModal(null));
  };

  useEffect(() => {
    setCurrentDraft({
      ...currentDraft,
      status: noteStatus,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noteStatus]);

  useEffect(() => {
    if (actualSociety && draft) {
      let currentPartners =
        typeof draft?.draftPartners === 'string'
          ? JSON.parse(draft?.draftPartners)
          : draft?.draftPartners;

      if (draft?.status === convertibleNoteStatus.var.NC_ACTIVE) {
        const draftInvestors = actualSociety?.investors
          ?.filter((investor) => investor?.draft === draft?.['_id'])
          .map((investor) => ({
            ...investor,
            investorId: investor?.['_id'],
          }));
        if (draftInvestors?.length === currentPartners?.length) {
          currentPartners = draftInvestors;
        }
      }
      setPartners(currentPartners);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety, draft]);

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title">
          {t('ChangeStatusOf')} {` ${draft['name'] || t('ConvertibleNote')}`}
        </h5>
        <a
          className="close cursor-pointer"
          onClick={() => dispatch(setModal(null))}
        >
          <em className="icon ni ni-cross cursor-pointer" />
        </a>
      </Modal.Header>
      <Modal.Body>
        <form className="form-validate is-alter">
          <div className="row mb-4">
            <div className="col">
              <div className="form-group">
                <div className="form-control-wrap">
                  <div className="form-control-select">
                    <select
                      className="form-control"
                      value={noteStatus}
                      onChange={(event) => setNoteStatus(event.target.value)}
                    >
                      {convertibleNoteStatusOptions?.length > 0 &&
                        convertibleNoteStatusOptions.map((status) => (
                          <option value={status.value} key={status.value}>
                            {status.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <button
            type="button"
            className="btn btn-lg btn-primary"
            onClick={handleUpdateStatus}
          >
            {t('Save')}
          </button>
        </form>
      </Modal.Body>
    </>
  );
}

export default ChangeNcStatus;
