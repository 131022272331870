/* eslint-disable no-underscore-dangle */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { setModal } from 'redux/actions/modalActions';
import { format } from 'date-fns';
import MenuDots from 'components/MenuDots';
import AddSocietyAuditor from 'components/Modals/AddSocietyAuditor';
import { dateFormat } from 'constants/formats';
import downloadFile from 'utils/downloadFile';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import { updateSociety } from 'redux/actions/societyActions';
import statusTypes from './statusTypes';

function SocietyAuditorRow({
  userId,
  auditorId,
  societyId,
  socialDenomination,
  cif,
  nationality,
  address,
  email,
  initDate,
  endDate,
  auditorStatus,
  documents,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { actualSociety } = useSelector((state) => ({
    actualSociety: state.society?.actualSociety,
  }));
  const isDemo = useSelector((state) => state.society?.role?.isDemo);

  const status = () => {
    if (!auditorStatus) {
      if (!endDate || new Date(endDate) - new Date() > 0)
        return statusTypes.var.ACTIVE;
      return statusTypes.var.INACTIVE;
    }
    return auditorStatus;
  };

  const handleEditAuditor = () => {
    let auditorData = {
      socialDenomination,
      cif,
      nationality,
      address,
      email,
      initDate,
      endDate,
      documents,
    };
    if (auditorStatus)
      auditorData = {
        ...auditorData,
        auditorStatus,
      };
    if (!isDemo) {
      dispatch(
        setModal(
          <AddSocietyAuditor
            societyId={societyId}
            action="EDIT"
            auditorData={auditorData}
            auditorId={auditorId}
            userId={userId}
          />
        )
      );
    }
  };

  const handleDeleteAuditor = () => {
    Swal.fire({
      icon: 'warning',
      title: `<h4 className="nk-block-title page-title">${t(
        'AreYouSureAboutDelAuditor'
      )}  `,
      html: `<h3 class="text-primary fw-normal">${socialDenomination} </h3><br /><div class="caption-text">${t(
        'ThisProcedureCantUndo'
      )}</div>`,
      confirmButtonText: t('OkDelete'),
      confirmButtonColor: '#6576ff',
      allowOutsideClick: false,
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const filteredAuditors = actualSociety?.auditors.filter(
          (auditor) => auditor['_id'] !== auditorId
        );
        const newSocietyData = {
          auditors: filteredAuditors,
        };
        await dispatch(updateSociety(societyId, newSocietyData, false));
        Swal.fire({
          icon: 'success',
          title: `<h4 class="nk-modal-title">${t(
            'AuditorSuccessfullyRemoved'
          )}<br></h4>`,
          confirmButtonText: t('OK'),
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonColor: '#6576FF',
        });
      }
    });
  };

  const showLoadedDocuments = () => {
    if (documents?.length === 1) {
      return (
        <span
          className="btn btn-icon btn-trigger"
          onClick={() => {
            downloadFile({ documentId: documents[0]?._id });
          }}
        >
          <em className="icon ni ni-file-download" />
        </span>
      );
    }
    if (documents?.length > 1) {
      const options = documents.map((doc) => ({
        icon: 'ni-file',
        text: doc.name,
        download: true,
        documentId: doc._id,
      }));
      return (
        <MenuDots
          menuOptions={options}
          direction="left"
          customIcon="ni-files"
        />
      );
    }
    return <span>-</span>;
  };

  const SettingsToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="dropdown-toggle btn-trigger mr-lg-n1"
    >
      {children}
      <em className="icon ni ni-more-h fs-22px" />
    </div>
  ));
  SettingsToggle.displayName = 'SettingsToggle';

  return (
    <tr className="nk-tb-item" key={auditorId}>
      <td className="nk-tb-col">
        <span
          className="text-primary font-weight-bold cursor-pointer"
          onClick={handleEditAuditor}
        >
          {socialDenomination || ''}
        </span>
      </td>

      <td className="nk-tb-col tb-col-xl">
        <span>{nationality || ''}</span>
      </td>

      <td className="nk-tb-col tb-col-xl">
        <span>
          {initDate && initDate !== ''
            ? format(new Date(initDate), dateFormat)
            : ''}
        </span>
      </td>

      <td className="nk-tb-col tb-col-xl">
        <span>
          {endDate && endDate !== ''
            ? format(new Date(endDate), dateFormat)
            : 'Indefinida'}
        </span>
      </td>

      <td className="nk-tb-col tb-col-xl">
        <span>{email || ''}</span>
      </td>

      <td className="nk-tb-col">
        {status && (
          <span
            className={`badge badge-dot badge-${statusTypes.badge[status()]}`}
          >
            {statusTypes.name[status()]}
          </span>
        )}
      </td>

      <td className="nk-tb-col tb-col-xl text-center">
        {documents.length > 0 ? (
          showLoadedDocuments()
        ) : (
          <span className="btn btn-icon disabled">
            <em className="icon ni ni-file-download" />
          </span>
        )}
      </td>

      {!isDemo && (
        <td className="nk-tb-col">
          <Dropdown className="pr-0">
            <Dropdown.Toggle as={SettingsToggle} />
            <Dropdown.Menu
              size="sm"
              title=""
              className="link-list-opt no-bdr dropdown-menu"
            >
              <a className="d-flex cursor-pointer" onClick={handleEditAuditor}>
                <em className="icon ni ni-edit fs-20px" />
                <span className="ml-1">{t('Edit')}</span>
              </a>
              <a
                className="d-flex cursor-pointer"
                onClick={handleDeleteAuditor}
              >
                <em className="icon ni ni-trash fs-20px" />
                <span className="ml-1">{t('Delete')}</span>
              </a>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      )}
    </tr>
  );
}

export default SocietyAuditorRow;
