/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import store from 'redux/store';
import {
  getDocumentsByReference,
  updateDocument,
} from 'redux/actions/documentActions';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { setModal } from 'redux/actions/modalActions';
import documentTypes from 'constants/documentTypes';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';

function MoveDocumentModal({
  societyId,
  document,
  changeBook = false,
  changeFolder = false,
  changeCouncilMinute = false,
}) {
  const books = useSelector(
    (state) => state.society?.actualSociety.minutebookFolders
  );
  const folders = useSelector(
    (state) => state.society?.actualSociety?.myFolders
  );
  const councilMinutes = useSelector(
    (state) => state.society?.actualSociety?.councilMinutes
  );

  const { t } = useTranslation();
  const animatedComponents = makeAnimated();

  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedMinuteBook, setSelectedMinuteBook] = useState();
  const [selectedCouncilMinute, setSelectedCouncilMinute] = useState();
  const [selectedCategoryOption, setSelectedCategoryOption] = useState();
  const [minuteBookOptions, setMinuteBookOptions] = useState();
  const [selectedFolder, setSelectedFolder] = useState();
  const [foldersOptions, setFoldersOptions] = useState();
  const [councilMinutesOptions, setCouncilMinutesOptions] = useState();

  const categoryOptions = Object.keys(documentTypes)
    .filter(
      (type) =>
        type !== documentTypes.ALL &&
        type !== documentTypes.BOARDS &&
        type !== documentTypes.TEMPLATES &&
        type !== documentTypes.DELETED
    )
    .map((type) => ({
      value: type,
      label: t(type),
    }));

  const handleCategoryChange = (selectedOption) => {
    setSelectedCategory(selectedOption.value);
    setSelectedCategoryOption(selectedOption);
  };
  const handleMinuteBookChange = (selectedOption) => {
    setSelectedMinuteBook(selectedOption.value);
  };
  const handleCouncilMinuteChange = (selectedOption) => {
    setSelectedCouncilMinute(selectedOption.value);
  };
  const handleFolderChange = (selectedOption) => {
    setSelectedFolder(selectedOption.value);
  };

  async function saveDocument(event) {
    event.preventDefault();
    await store.dispatch(
      updateDocument(
        {
          ...document,
          category: selectedCategory,
          subcategory:
            selectedMinuteBook || selectedFolder || selectedCouncilMinute,
        },
        false
      )
    );
    store.dispatch(getDocumentsByReference({ societyId }));
    store.dispatch(setModal(null));
    return Swal.fire({
      icon: 'success',
      title: `<h4 class="nk-modal-title">${t(
        'DocumentMovedSuccessfully'
      )}<br></h4>`,
      confirmButtonText: t('OK'),
      allowOutsideClick: false,
      showCancelButton: false,
      confirmButtonColor: '#6576FF',
    });
  }
  useEffect(() => {
    if (books) {
      const options = books.map((book) => ({
        value: book._id,
        label: book.label,
      }));
      setMinuteBookOptions(options);
    }
  }, [books]);

  useEffect(() => {
    if (changeBook) {
      setSelectedCategoryOption({
        value: documentTypes.MINUTEBOOK,
        label: t(documentTypes.MINUTEBOOK),
      });
      setSelectedCategory(documentTypes.MINUTEBOOK);
    }
  }, [changeBook]);

  useEffect(() => {
    if (folders) {
      const options = folders.map((folder) => ({
        value: folder._id,
        label: folder.label,
      }));
      setFoldersOptions(options);
    }
  }, [folders]);

  useEffect(() => {
    if (changeFolder) {
      setSelectedCategoryOption({
        value: documentTypes.MYFOLDERS,
        label: t(documentTypes.MYFOLDERS),
      });
      setSelectedCategory(documentTypes.MYFOLDERS);
    }
  }, [changeFolder]);

  useEffect(() => {
    if (changeCouncilMinute) {
      setSelectedCategoryOption({
        value: documentTypes.COUNCILMINUTES,
        label: t(documentTypes.COUNCILMINUTES),
      });
      setSelectedCategory(documentTypes.COUNCILMINUTES);
    }
  }, [changeCouncilMinute]);

  useEffect(() => {
    if (councilMinutes) {
      const options = councilMinutes.map((councilMinute) => ({
        value: councilMinute._id,
        label: councilMinute.label,
      }));
      setCouncilMinutesOptions(options);
    }
  }, [councilMinutes]);

  return (
    <>
      <Modal.Header>
        <h5>{t('MoveDocument')}</h5>
        <a className="close" onClick={() => store.dispatch(setModal(null))}>
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>

      <Modal.Body>
        <form className="form-validate is-alter" onSubmit={saveDocument}>
          <div className="form-group">
            <label className="form-label" htmlFor="full-name">
              {t('Category')}
            </label>
            <div className="form-control-wrap">
              <Select
                closeMenuOnSelect
                className="react-select"
                value={selectedCategoryOption}
                options={categoryOptions}
                components={animatedComponents}
                onChange={handleCategoryChange}
                placeholder={t('SelectCategory')}
                isDisabled={changeBook || changeFolder}
              />
            </div>
          </div>
          <div className="form-group">
            {selectedCategory === documentTypes.MINUTEBOOK && (
              <div>
                <label className="form-label" htmlFor="full-name">
                  {t('MinuteBook')}
                </label>
                <div className="form-control-wrap">
                  <Select
                    closeMenuOnSelect
                    className="react-select"
                    options={minuteBookOptions}
                    components={animatedComponents}
                    onChange={handleMinuteBookChange}
                    placeholder={t('SelectMinuteBook')}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="form-group">
            {selectedCategory === documentTypes.MYFOLDERS && (
              <div>
                <label className="form-label" htmlFor="full-name">
                  {t('MyFolders')}
                </label>
                <div className="form-control-wrap">
                  <Select
                    closeMenuOnSelect
                    className="react-select"
                    options={foldersOptions}
                    components={animatedComponents}
                    onChange={handleFolderChange}
                    placeholder={t('SelectFolder')}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="form-group">
            {selectedCategory === documentTypes.COUNCILMINUTES && (
              <div>
                <label className="form-label" htmlFor="full-name">
                  {t('CouncilMinutes')}
                </label>
                <div className="form-control-wrap">
                  <Select
                    closeMenuOnSelect
                    className="react-select"
                    options={councilMinutesOptions}
                    components={animatedComponents}
                    onChange={handleCouncilMinuteChange}
                    placeholder={t('SelectCouncilMinute')}
                  />
                </div>
              </div>
            )}
          </div>
          <button
            type="submit"
            className="btn btn-lg btn-primary"
            disabled={
              !selectedCategory ||
              (selectedCategory === documentTypes.MINUTEBOOK &&
                !selectedMinuteBook) ||
              (selectedCategory === documentTypes.MYFOLDERS &&
                !selectedFolder) ||
              (selectedCategory === documentTypes.COUNCILMINUTES &&
                !selectedCouncilMinute)
            }
          >
            {t('Move')}
          </button>
        </form>
      </Modal.Body>
    </>
  );
}

export default MoveDocumentModal;
