/* eslint-disable no-restricted-syntax */
import { getUserSocieties } from 'redux/actions/societyActions';
import Record from 'typescript';
import i18n from 'i18n/config';

import filterUniqueElementsByProperty from './filterUniqueElementsByProperty';
import partners from '../mocks/partners.json';

const getPartnersFromSocieties = async (user: Record<string, any>) => {
  // @ts-ignore
  const partnersFromSocieties: any[] = await getUserSocieties(
    user['_id'],
    'admin'
  );

  const allPartners = partnersFromSocieties?.reduce(
    (acc: Record<string, any>[], curr: Record<string, any>) =>
      acc.concat(curr?.partners || []),
    []
  );
  const uniquePartnersByCif = filterUniqueElementsByProperty(
    allPartners,
    'cif'
  );

  const manualPartners = uniquePartnersByCif?.map((partner, index) => ({
    id: index,
    cif: partner?.cif || '',
    name: partner?.name || '',
    address: partner?.address || {},
    phone: partner?.phone || '',
    nationality: partner?.nationality || '',
    birthdate: partner?.birthdate || '',
    image: partner?.image || '',
    isNaturalPerson: partner?.isNaturalPerson,
    civilStatus: partner?.civilStatus || '',
    legalInfo: partner?.legalInfo || {},
    representative: partner?.representative || {},
    taxAddress: partner?.taxAddress || {},
    employeeNumber: partner?.employeeNumber || '',
    bankAccountNumber: partner?.bankAccountNumber || '',
    shares: '',
    shareFrom: '',
    shareTo: '',
    holdingClass: {
      id: 0,
      name: i18n.t('Ordinary'),
      numerationOption: null,
    },
  }));
  return manualPartners;
};

export default getPartnersFromSocieties;
