export default {
  var: {
    NC_DRAFT: 'NC_DRAFT',
    NC_ACTIVE: 'NC_ACTIVE',
    NC_EXECUTED: 'NC_EXECUTED'
  },
  name: {
    NC_DRAFT: 'Borrador',
    NC_ACTIVE: 'En Vigor',
    NC_EXECUTED: 'Convertida'
  },
  style: {
    NC_DRAFT: 'badge-success',
    NC_ACTIVE: 'badge-danger',
    NC_EXECUTED: 'badge-gray',
  }
};
