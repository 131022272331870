/* eslint-disable react/self-closing-comp */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';

import transactionTypes from 'constants/transactionTypes';
import { currencyFormatDecimals, numberFormat } from 'constants/formats';
import { dayMonYea } from 'utils/formatDate';
import downloadFile from 'utils/downloadFile';

import MenuDots from 'components/MenuDots';

const showDetails = JSON.parse(localStorage.getItem('showDetails'));

function PartnerTransactionsRow({
  transaction,
  index,
  society,
  documents,
  tag,
}) {
  const { t } = useTranslation();
  const transactionNames = {
    ADD_PARTNER: t('AddPartner'),
    CONSTITUTION: t('Constitution'),
    CAPITAL_INCREASE: t('CapitalIncrease'),
    CAPITAL_DECREASE: t('CapitalDecrease'),
    BUY: t('Buy'),
    SELL: t('Sale'),
    SPLIT: t('Split'),
    RENUMERATION: t('Renumeration'),
    RECLASSIFICATION: t('Reclassification'),
  };
  const isAdmin = useSelector((state) => state.society?.role?.isAdmin);
  const holdingClasses = useSelector((state) => state.holdingClasses);

  const [shares] = useState(transaction.shareTo - transaction.shareFrom + 1);

  const nominalValue = transaction?.nominalValue || society?.nominalValue;
  const amount =
    ((transaction?.transactionType === transactionTypes.BUY ||
      transaction?.transactionType === transactionTypes.SELL ||
      (transaction?.transactionType === transactionTypes.CAPITAL_DECREASE &&
        transaction?.sharePrice)) &&
      `${transaction?.sharePrice * shares}`) ||
    `${(nominalValue + (transaction?.sharePremium || 0)) * shares}`;

  const showLoadedDocuments = () => {
    // Have 1 document.
    if (documents?.length === 1) {
      return (
        <span
          className="btn btn-icon btn-trigger mx-auto "
          onClick={() => {
            downloadFile({ documentId: documents[0]?._id });
          }}
        >
          <em className="icon ni ni-file-download text-center" />
        </span>
      );
    }
    // Have more than 1 document.
    if (documents?.length > 1) {
      const options = documents.map((doc) => ({
        icon: 'ni-file',
        text: doc.name,
        download: true,
        documentId: doc._id,
      }));
      return (
        <span className="d-flex justify-content-center mt-2 mr-1">
          <MenuDots
            menuOptions={options}
            direction="left"
            customIcon="ni-files"
            dropdownClassName="w-250px"
          />
        </span>
      );
    }
    // Don't have documents but the transaction does. It hasn't loaded yet.
    if (transaction?.documents?.length > 0) {
      return (
        <div className="text-center">
          <em className="spinner-border spinner-border-sm" role="status" />
        </div>
      );
    }
    // Don't have documents.
    return (
      <span className="btn btn-icon disabled">
        <em className="icon ni ni-file-download" />
      </span>
    );
  };

  return (
    <tr key={transaction._id}>
      <td className="d-none d-xl-table-cell text-right">
        <b>{index}</b>
      </td>
      <td id={transaction['_id']}>
        {transactionNames[transaction?.transactionType]}
        {isAdmin && (
          <Tooltip anchorId={transaction['_id']} place="bottom">
            {transaction?.comments}
          </Tooltip>
        )}
      </td>
      <td className="d-none d-xl-table-cell text-right">
        {dayMonYea(transaction?.date)}
      </td>
      <td className="tb-col-end">
        <NumberFormat
          value={+amount}
          displayType="text"
          {...currencyFormatDecimals}
        />
      </td>
      <td className="text-right">
        {shares ? (
          <NumberFormat
            value={shares}
            displayType="text"
            {...numberFormat}
            decimals={0}
          />
        ) : (
          '-'
        )}
      </td>
      {society?.hasShareTags && showDetails && (
        <td className="d-none d-xl-table-cell">{tag || '-'}</td>
      )}
      <td className="d-none d-xl-table-cell">
        {holdingClasses.find(
          (holding) => holding['_id'] === transaction?.shareClass
        )?.name || '-'}
      </td>
      <td className="d-none d-xl-table-cell text-right">
        {transaction?.shareFrom ? (
          <NumberFormat
            value={transaction.shareFrom}
            displayType="text"
            {...numberFormat}
            decimals={0}
          />
        ) : (
          '-'
        )}
      </td>
      <td className="d-none d-xl-table-cell text-right">
        {transaction?.shareTo ? (
          <NumberFormat
            value={transaction.shareTo}
            displayType="text"
            {...numberFormat}
            decimals={0}
          />
        ) : (
          '-'
        )}
      </td>
      <td className="text-center p-0 w-20px mx-auto" style={{ width: '30px' }}>
        {showLoadedDocuments(document)}
      </td>
    </tr>
  );
}

export default PartnerTransactionsRow;
