import numeral from 'numeral';
import locales from 'numeral/locales';

numeral.locale('es');

export const formatNumber = (value) =>
  typeof value !== 'undefined' && value !== null
    ? numeral(value).format('0,0')
    : '';

export const formatNumberDecimals = (value) =>
  typeof value !== 'undefined' && value !== null
    ? numeral(value).format('0,0.00')
    : '';

export const formatNumberDecimalsLong = (value) =>
  typeof value !== 'undefined' && value !== null
    ? numeral(value).format('0,0[.][0000000000]')
    : '';

export const formatCurrencyDecimalsLong = (value) =>
  typeof value !== 'undefined' && value !== null
    ? `${numeral(value).format('0,0[.][0000000000]')}€`
    : '';

export const formatCurrency = (value) =>
  typeof value !== 'undefined' && value !== null
    ? `${numeral(value).format('0,0[.][0000000]')}€`
    : '';

export const formatCurrencyDecimals = (value) =>
  typeof value !== 'undefined' && value !== null
    ? `${numeral(value).format('0,0.00')}€`
    : '';
