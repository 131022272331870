import editPreview from 'utils/editPreview';
import duplicateDocument from 'utils/duplicateDocument';
import downloadWord from 'utils/downloadWord';
import Swal from 'sweetalert2';
import { updateDocument } from 'redux/actions/documentActions';
import store from 'redux/store';
import documentTypes from 'constants/documentTypes';

function menuOptions(i18n) {
  return [
    {
      icon: 'ni-file-pdf',
      text: i18n.t('DownloadPDF'),
      action: ({setDownload}) =>
        setDownload(true),
      disabled: false,
    },
    {
      icon: 'ni-file-doc',
      text: i18n.t('DownloadWord'),
      action: (params) =>
        downloadWord(params.preview.documentModel, params.preview.name),
    },
    {
      icon: 'ni-edit',
      text: i18n.t('Edit'),
      action: editPreview,
      disabled: false,
    },
    {
      icon: 'ni-copy',
      text: i18n.t('Duplicate'),
      action: (params) => duplicateDocument(params.preview.bdDocument),
    },
    {
      icon: 'ni-trash',
      text: i18n.t('Delete'),
      action: (params) => {
        Swal.fire({
          icon: 'warning',
          title: `<h4 class="nk-modal-title">${i18n.t(
            'AreYouSureAboutDelDocument'
          )}</h4>`,
          confirmButtonText: i18n.t('Delete'),
          confirmButtonColor: '#6576ff',
          allowOutsideClick: false,
          showCancelButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            store.dispatch(
              updateDocument({
                ...params.preview.bdDocument,
                category: documentTypes.DELETED,
              })
            );
          }
        });
      },
    },
  ];
}
export default menuOptions;
