/* eslint-disable no-case-declarations */
import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  formatCurrencyDecimals,
  formatNumber,
  formatNumberDecimalsLong,
} from 'utils/formats';

import { setModal } from 'redux/actions/modalActions';
import { Modal } from 'react-bootstrap';

import './SellTenderOffer.scss';

const tabTypes = {
  ALL: 'ALL',
  PART: 'PART',
};

const optionTypes = {
  FULL: 'FULL',
  HALF: 'HALF',
  NONE: 'NONE',
};

const customOptionTypes = {
  BY_UNITS: 'BY_UNITS',
  BY_PRICE: 'BY_PRICE',
};

const SellTenderOffer = ({
  actualTenderOffer,
  participant,
  handleModifyUnits,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [tabSelected, setTabSelected] = React.useState(tabTypes.ALL);
  const [optionSelected, setOptionSelected] = React.useState(null);
  const [customOptionSelected, setCustomOptionSelected] = React.useState(null);

  const [customShares, setCustomShares] = React.useState(0);
  const [customPhantoms, setCustomPhantoms] = React.useState(0);
  const [customStocks, setCustomStocks] = React.useState(0);
  const [customAmount, setCustomAmount] = React.useState(0);
  const [customTotalUnits, setCustomTotalUnits] = React.useState(0);
  const [customTotalPriceByUnits, setCustomTotalPriceByUnits] =
    React.useState(0);
  const [customTotalPriceByAmount, setCustomTotalPriceByAmount] =
    React.useState(0);

  const [planUnitPrice] = React.useState(
    participant?.planData?.sharePrice || 0
  );
  const [tenderOfferUnitPrice] = React.useState(
    actualTenderOffer?.totalPriceByShare || 0
  );
  const { shares, phantoms, stocks } = participant;

  const totalUnits = shares + phantoms + stocks;

  const totalPrice =
    (shares + phantoms + stocks) * actualTenderOffer.totalPriceByShare;

  const totalHalfUnits =
    Math.floor(shares / 2) + Math.floor(phantoms / 2) + Math.floor(stocks / 2);

  const totalHalfPrice =
    (Math.floor(shares / 2) +
      Math.floor(phantoms / 2) +
      Math.floor(stocks / 2)) *
    actualTenderOffer.totalPriceByShare;

  const maxAmountAllowed = Math.min(
    actualTenderOffer.maxAmount,
    totalUnits * actualTenderOffer.totalPriceByShare
  );

  const isSaveDisabled = () => {
    if (!optionSelected && !customOptionSelected) return true;
  };

  const handleSellTenderOffer = async () => {
    dispatch(setModal(null));

    let sellOptions = {};

    switch (true) {
      case tabSelected === tabTypes.ALL && optionSelected === optionTypes.FULL:
        sellOptions = { customSell: true, totalAccepted: totalUnits };
        break;

      case tabSelected === tabTypes.ALL && optionSelected === optionTypes.HALF:
        const halfShares = Math.floor(participant.shares / 2);
        const halfStocks = Math.floor(participant.stocks / 2);
        const halfPhantoms = Math.floor(participant.phantoms / 2);
        sellOptions = {
          customSell: true,
          totalAccepted: halfShares + halfStocks + halfPhantoms,
        };
        break;

      case tabSelected === tabTypes.ALL && optionSelected === optionTypes.NONE:
        sellOptions = { customSell: true, totalAccepted: 0 };
        break;

      case tabSelected === tabTypes.PART &&
        customOptionSelected === customOptionTypes.BY_UNITS:
        sellOptions = {
          customSell: true,
          totalAccepted: +customShares + +customPhantoms + +customStocks,
        };
        break;

      case tabSelected === tabTypes.PART &&
        customOptionSelected === customOptionTypes.BY_PRICE:
        sellOptions = {
          customSell: true,
          totalAccepted: Math.floor(
            +customAmount / actualTenderOffer.totalPriceByShare
          ),
        };
        break;

      default:
        break;
    }

    handleModifyUnits(sellOptions);
  };

  const handleCancelTenderOffer = async () => {
    dispatch(setModal(null));
  };

  const handleChangeCustomShares = (value) => {
    const sumAll = +value + +customPhantoms + +customStocks;
    if (
      actualTenderOffer.selAll ||
      (+value >= 0 &&
        +value <= participant.shares &&
        +value <= actualTenderOffer.maxUnits &&
        sumAll <= actualTenderOffer.maxUnits &&
        sumAll * actualTenderOffer.totalPriceByShare <=
          actualTenderOffer.maxAmount)
    ) {
      setCustomShares(value);
    }
  };

  const handleChangeCustomPhantoms = (value) => {
    if (
      actualTenderOffer.selAll ||
      (+value >= 0 && +value <= participant.phantoms)
    ) {
      setCustomPhantoms(value);
    }
  };

  const handleChangeCustomStocks = (value) => {
    if (
      actualTenderOffer.selAll ||
      (+value >= 0 && +value <= participant.stocks)
    ) {
      setCustomStocks(value);
    }
  };

  const handleChangeCustomAmount = (value) => {
    if (+value >= 0 && +value <= maxAmountAllowed) {
      setCustomAmount(value);
    }
  };

  const isCustomSharesDisabled = () =>
    !!(
      participant.shares === 0 ||
      customOptionSelected === customOptionTypes.BY_PRICE
    );

  const isCustomPhantomsDisabled = () =>
    !!(
      participant.phantoms === 0 ||
      customOptionSelected === customOptionTypes.BY_PRICE
    );

  const isCustomStocksDisabled = () =>
    !!(
      participant.stocks === 0 ||
      customOptionSelected === customOptionTypes.BY_PRICE
    );

  const isCustomAmountDisabled = () =>
    !!(
      actualTenderOffer.maxAmount === 0 ||
      customOptionSelected === customOptionTypes.BY_UNITS
    );

  React.useEffect(() => {
    setCustomTotalPriceByUnits(
      (+customShares + +customPhantoms + +customStocks) *
        actualTenderOffer.totalPriceByShare
    );
    setCustomTotalUnits(+customShares + +customPhantoms + +customStocks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customShares, customPhantoms, customStocks]);

  React.useEffect(() => {
    setCustomTotalPriceByAmount(customAmount);
  }, [customAmount]);

  React.useEffect(() => {
    if (customOptionSelected === customOptionTypes.BY_UNITS) {
      setCustomAmount(0);
    }
    if (customOptionSelected === customOptionTypes.BY_PRICE) {
      setCustomShares(0);
      setCustomPhantoms(0);
      setCustomStocks(0);
    }
  }, [customOptionSelected]);

  React.useEffect(() => {
    if (tabSelected === tabTypes.ALL) {
      setCustomShares(0);
      setCustomPhantoms(0);
      setCustomStocks(0);
      setCustomAmount(0);
    }
  }, [tabSelected]);

  return (
    <Modal.Body>
      <div className="text-right">
        <a
          className="text-soft fs-22px  cursor-pointer"
          onClick={() => dispatch(setModal(null))}
        >
          <em className="icon ni ni-cross" />
        </a>
      </div>
      <div className="modal-body modal-body-md pt-0">
        <div className="sp-package text-center" id="sell-tender-offer">
          <div className="sp-package-head">
            <h4 className="title">{t('QuantityToSellIndication')}</h4>
            <p className="text-soft">{t('AmountToSell')}</p>
          </div>

          <ul className="sp-package-plan nav mx-auto my-4 w-200px d-flex flex-row justify-content-center">
            <li className="nav-item">
              <span
                className={`nav-link py-1 ${
                  tabSelected === tabTypes.ALL ? 'active' : ''
                }`}
                onClick={() => setTabSelected(tabTypes.ALL)}
              >
                {t('All')}
              </span>
            </li>
            <li className="nav-item">
              <span
                className={`nav-link py-1 ${
                  tabSelected === tabTypes.PART ? 'active' : ''
                }`}
                onClick={() => setTabSelected(tabTypes.PART)}
              >
                {t('APart')}
              </span>
            </li>
          </ul>

          {tabSelected === tabTypes.ALL ? (
            <ul className="sp-package-list">
              <li className="sp-package-item">
                <input
                  className="sp-package-choose"
                  type="radio"
                  name="subscription-pack-plan"
                  id="pack-plan-entprise"
                  value={optionTypes.FULL}
                  checked={optionSelected === optionTypes.FULL}
                  onChange={(e) => setOptionSelected(e.target.value)}
                />
                <label className="sp-package-desc" htmlFor="pack-plan-entprise">
                  <span className="sp-package-info">
                    <span className="sp-package-title title">
                      {t('SellingMax')}&nbsp;
                      {optionSelected === optionTypes.FULL && (
                        <span className="badge badge-primary badge-pill">
                          {t('Selected')}
                        </span>
                      )}
                    </span>
                    <span className="sp-package-detail d-block">
                      {t('Shar.')}: {formatNumber(shares)} | {t('Phantoms')}
                      :&nbsp;
                      {formatNumber(phantoms)} | {t('Stocks')}:&nbsp;
                      {formatNumber(stocks)}
                    </span>
                    <span className="sp-package-detail d-block">
                      {t('StrikePriceUnit')}:&nbsp;
                      {formatNumberDecimalsLong(planUnitPrice)}€
                    </span>
                    <span className="sp-package-detail d-block">
                      {t('SellingPriceUnit')}:&nbsp;
                      {formatNumberDecimalsLong(tenderOfferUnitPrice)}€
                    </span>
                  </span>
                  <span className="sp-package-price">
                    <span className="sp-package-amount yearly">
                      <span className="amount">
                        {formatCurrencyDecimals(totalPrice)}
                      </span>
                      <span className="text-soft">
                        {t('ExercisePrice')}:&nbsp;
                        {formatNumber(totalUnits * planUnitPrice) || 0} €
                      </span>
                      <span className="text-soft">
                        {/* @Marti: cómo nombramos a este campo */}
                        {t('Difference')}:&nbsp;
                        <span className="badge badge-light badge-pill">
                          {formatNumber(
                            totalPrice - totalUnits * planUnitPrice
                          ) || 0}
                          &nbsp;€
                        </span>
                      </span>
                    </span>
                  </span>
                </label>
              </li>

              <li className="sp-package-item">
                <input
                  className="sp-package-choose"
                  type="radio"
                  name="subscription-pack-plan"
                  id="pack-plan-pro"
                  value={optionTypes.HALF}
                  checked={optionSelected === optionTypes.HALF}
                  onChange={(e) => setOptionSelected(e.target.value)}
                />
                <label className="sp-package-desc" htmlFor="pack-plan-pro">
                  <span className="sp-package-info">
                    <span className="sp-package-title title">
                      {t('HalfOf')}
                      {optionSelected === optionTypes.HALF && (
                        <span className="badge badge-primary badge-pill">
                          {t('Selected')}
                        </span>
                      )}
                    </span>
                    <span className="sp-package-detail d-block">
                      {t('Shar.')} {formatNumber(Math.floor(shares / 2))} |
                      {t('Phantoms')}:&nbsp;
                      {formatNumber(Math.floor(phantoms / 2))} | {t('Stocks')}
                      :&nbsp;
                      {formatNumber(Math.floor(stocks / 2))}
                    </span>
                    <span className="sp-package-detail d-block">
                      {t('StrikePriceUnit')}:&nbsp;
                      {formatNumberDecimalsLong(planUnitPrice)}€
                    </span>
                    <span className="sp-package-detail d-block">
                      {t('SellingPriceUnit')}:&nbsp;
                      {formatNumberDecimalsLong(tenderOfferUnitPrice)}€
                    </span>
                  </span>
                  <span className="sp-package-price">
                    <span className="sp-package-amount yearly">
                      <span className="amount">
                        {formatCurrencyDecimals(totalHalfPrice)}
                      </span>
                      <span className="text-soft">
                        {t('ExercisePrice')}:&nbsp;
                        {formatNumber(totalHalfUnits * planUnitPrice) || 0} €
                      </span>
                      <span className="text-soft">
                        {/* @Marti: cómo nombramos a este campo */}
                        {t('Difference')}:&nbsp;
                        <span className="badge badge-light badge-pill">
                          {formatNumber(
                            totalHalfPrice - totalHalfUnits * planUnitPrice
                          ) || 0}
                          &nbsp;€
                        </span>
                      </span>
                    </span>
                  </span>
                </label>
              </li>

              <li className="sp-package-item">
                <input
                  className="sp-package-choose"
                  type="radio"
                  name="subscription-pack-plan"
                  id="pack-plan-free"
                  value={optionTypes.NONE}
                  checked={optionSelected === optionTypes.NONE}
                  onChange={(e) => setOptionSelected(e.target.value)}
                />
                <label className="sp-package-desc" htmlFor="pack-plan-free">
                  <span className="sp-package-info">
                    <span className="sp-package-title title">
                      {t('DoNotSellAnything')}
                      {optionSelected === optionTypes.NONE && (
                        <span className="badge badge-primary badge-pill">
                          {t('Selected')}
                        </span>
                      )}
                    </span>
                    <span className="sp-package-detail">
                      {t('Shar.')} 0 | {t('Phantoms')}: 0 | {t('Stocks')}: 0
                    </span>
                  </span>
                  <span className="sp-package-price">
                    <span className="sp-package-amount yearly">
                      <span className="amount">0,00€</span>
                      <span className="text-soft">
                        {t('ExercisePrice')}: 0 €
                      </span>
                    </span>
                  </span>
                </label>
              </li>
            </ul>
          ) : (
            <ul className="sp-package-list">
              <li className="sp-package-item">
                <input
                  className="sp-package-choose"
                  type="radio"
                  name="subscription-pack-plan"
                  id="sell-custom-by-unit"
                  value={customOptionTypes.BY_UNITS}
                  checked={customOptionSelected === customOptionTypes.BY_UNITS}
                  onChange={(e) => setCustomOptionSelected(e.target.value)}
                />
                <label
                  className="sp-package-desc"
                  htmlFor="sell-custom-by-unit"
                >
                  <span className="sp-package-info">
                    <span className="sp-package-title title">
                      {t('HowManyUnitsWantToSell')}
                    </span>

                    <br />
                    <div className="form-control-wrap mb-2">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text"
                            id="inputGroup-sizing-default"
                          >
                            {t('Shares')}
                          </span>
                        </div>
                        <input
                          type="number"
                          className="form-control text-right"
                          value={customShares}
                          onChange={(e) =>
                            handleChangeCustomShares(e.target.value)
                          }
                          disabled={isCustomSharesDisabled()}
                        />
                      </div>
                    </div>

                    <div className="form-control-wrap mb-2">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text"
                            id="inputGroup-sizing-default"
                          >
                            {t('Phantoms')}
                          </span>
                        </div>
                        <input
                          type="number"
                          className="form-control text-right"
                          value={customPhantoms}
                          onChange={(e) =>
                            handleChangeCustomPhantoms(e.target.value)
                          }
                          disabled={isCustomPhantomsDisabled()}
                        />
                      </div>
                    </div>

                    <div className="form-control-wrap mb-2">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text"
                            id="inputGroup-sizing-default"
                          >
                            {t('Stocks')}
                          </span>
                        </div>
                        <input
                          type="number"
                          className="form-control text-right"
                          value={customStocks}
                          onChange={(e) =>
                            handleChangeCustomStocks(e.target.value)
                          }
                          disabled={isCustomStocksDisabled()}
                        />
                      </div>
                    </div>

                    <span className="sp-package-detail d-block">
                      <strong>{t('SellingMax')}:</strong>
                      <br />
                      {t('Shar.')} {formatNumber(shares)} | {t('Phantoms')}
                      :&nbsp;
                      {formatNumber(phantoms)} | {t('Stocks')}:&nbsp;
                      {formatNumber(stocks)}
                    </span>
                    <span className="sp-package-detail d-block">
                      {t('StrikePriceUnit')}:&nbsp;
                      {formatNumberDecimalsLong(planUnitPrice)}€
                    </span>
                    <span className="sp-package-detail d-block">
                      {t('SellingPriceUnit')}:&nbsp;
                      {formatNumberDecimalsLong(tenderOfferUnitPrice)}€
                    </span>
                  </span>

                  <span className="sp-package-price d-flex justify-content-center align-items-center">
                    <span className="sp-package-amount yearly">
                      <span className="amount">
                        {formatCurrencyDecimals(customTotalPriceByUnits)}
                      </span>
                      <span className="text-soft">
                        {t('ExercisePrice')}:&nbsp;
                        {formatNumber(customTotalUnits * planUnitPrice) || 0} €
                      </span>
                      <span className="text-soft">
                        {/* @Marti: cómo nombramos a este campo */}
                        {t('Difference')}:&nbsp;
                        <span className="badge badge-light badge-pill">
                          {formatNumber(
                            customTotalPriceByUnits -
                              customTotalUnits * planUnitPrice
                          ) || 0}
                          &nbsp;€
                        </span>
                      </span>
                    </span>
                  </span>
                </label>
              </li>
              <li className="sp-package-item">
                <input
                  className="sp-package-choose"
                  type="radio"
                  name="subscription-pack-plan"
                  id="sell-custom-by-price"
                  value={customOptionTypes.BY_PRICE}
                  checked={customOptionSelected === customOptionTypes.BY_PRICE}
                  onChange={(e) => setCustomOptionSelected(e.target.value)}
                />
                <label
                  className="sp-package-desc"
                  htmlFor="sell-custom-by-price"
                >
                  <span className="sp-package-info">
                    <span className="sp-package-title title">
                      {t('WhatAmountWantToSell')}
                    </span>

                    <br />
                    <div className="form-control-wrap mb-2">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text"
                            id="inputGroup-sizing-default"
                          >
                            {t('TotalAmount')}
                          </span>
                        </div>
                        <input
                          type="number"
                          className="form-control text-right"
                          value={customAmount}
                          onChange={(e) =>
                            handleChangeCustomAmount(e.target.value)
                          }
                          disabled={isCustomAmountDisabled()}
                        />
                      </div>
                    </div>

                    <span className="sp-package-detail d-block">
                      <strong>{t('Maximum')}: </strong>
                      {formatCurrencyDecimals(maxAmountAllowed)}
                    </span>
                    <span className="sp-package-detail d-block">
                      {t('StrikePriceUnit')}:&nbsp;
                      {formatNumberDecimalsLong(planUnitPrice)}€
                    </span>
                    <span className="sp-package-detail d-block">
                      {t('SellingPriceUnit')}:&nbsp;
                      {formatNumberDecimalsLong(tenderOfferUnitPrice)}€
                    </span>
                  </span>
                  <span className="sp-package-price d-flex justify-content-center align-items-center">
                    <span className="sp-package-amount yearly">
                      <span className="amount">
                        {formatNumber(
                          Math.floor(
                            customTotalPriceByAmount / tenderOfferUnitPrice
                          )
                        )}
                        &nbsp;{t('uds')}
                      </span>
                      <span className="text-soft">
                        {t('ExercisePrice')}:&nbsp;
                        {formatNumber(
                          (customTotalPriceByAmount / tenderOfferUnitPrice) *
                            planUnitPrice
                        ) || 0}{' '}
                        €
                      </span>
                      <span className="text-soft">
                        {/* @Marti: cómo nombramos a este campo */}
                        {t('Difference')}:&nbsp;
                        <span className="badge badge-light badge-pill">
                          {formatNumber(
                            customTotalPriceByAmount -
                              (customTotalPriceByAmount /
                                tenderOfferUnitPrice) *
                                planUnitPrice
                          ) || 0}
                          &nbsp;€
                        </span>
                      </span>
                    </span>
                  </span>
                </label>
              </li>
            </ul>
          )}

          <div className="sp-package-action">
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSellTenderOffer}
              disabled={isSaveDisabled()}
            >
              {t('Save')}
            </button>
            <button
              type="button"
              className="btn btn-dim btn-danger"
              onClick={handleCancelTenderOffer}
            >
              {t('Cancel')}
            </button>
          </div>
        </div>
      </div>
    </Modal.Body>
  );
};

export default SellTenderOffer;
