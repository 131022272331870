/* eslint-disable react/jsx-indent */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ParticipatedRow from 'components/SocietyRow';
import TableLoader from 'components/Tables/TableLoader';
import { countSocietyShares } from 'utils/getShareCount';
import {
  getActualSociety,
  getDecimalScale,
  getSocietyValue,
  getUserPartnerSince,
  getUserSocietyPercent,
  getUserSocietyPercentFD,
} from 'utils/filters';

import { getHolderSocieties } from 'redux/actions/societyActions';
import { setMenu } from 'redux/actions/menuActions';
import menuTypes from 'constants/menuTypes';

import CustomDataTable from 'components/Tables/CustomDataTable';
import transformData from 'components/Tables/helpers';
import tableColumns from './tableColumns';

import './PortfolioList.scss';

const portfolioFilters = {
  ALL: 'ALL',
  ACTIVE: 'ACTIVE',
  EXIT: 'EXIT',
};

function PortfolioList({ items }) {
  const dispatch = useDispatch();
  const { societyId } = useParams();
  const { t, i18n } = useTranslation();

  const user = useSelector((state) => state.user);
  const role = useSelector((state) => state.society?.role);
  const actualSociety = useSelector((state) => state.society?.actualSociety);

  const [rows, setRows] = useState([]);
  const [societies, setSocieties] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [currentTableColumns, setCurrentTableColumns] = useState(
    tableColumns(i18n)
  );
  const [filterBy, setFilterBy] = useState(portfolioFilters.ALL);

  const getSocietyDecimalScale = (society) => {
    const societyValue = getSocietyValue(society);
    return societyValue ? getDecimalScale(societyValue.value) : 2;
  };

  useEffect(() => {
    if (!isLoadingData) {
      setIsLoadingData(true);
    }
  }, [filterBy]);

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId) {
      getActualSociety(user, societyId);
    }
  }, [actualSociety, user, societyId]);

  useEffect(() => {
    if (actualSociety) {
      dispatch(
        setMenu({
          type: menuTypes.EXTENDED,
          societyId: actualSociety?.['_id'],
          societyName: actualSociety?.name,
        })
      );
    }
  }, [actualSociety, dispatch]);

  useEffect(() => {
    if (actualSociety && user && filterBy) {
      getHolderSocieties(
        actualSociety['_id'],
        user['_id'],
        filterBy,
        setSocieties
      ).then(() => {
        setIsLoadingData(false);
      });
    }
  }, [actualSociety, user, filterBy]);

  useEffect(() => {
    setRows(
      societies.length && societies[1]?.length
        ? societies[1].map((society) => (
            <ParticipatedRow
              society={countSocietyShares(society)}
              key={society.name}
              userId={user['_id']}
            />
          ))
        : []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [societies]);

  useEffect(() => {
    const newData = rows.map(({ props }) => ({
      society: props.society,
      name: props.society.name,
      actualSharesCount: props.society.sharesCount?.actual,
      partners: props.society.partners,
      decimalScale: getSocietyDecimalScale(props.society),
      percent: getUserSocietyPercent(user['_id'], props.society),
      percentFD: getUserSocietyPercentFD(user['_id'], props.society),
      valueFD:
        ((getSocietyValue(props.society)?.value || 0) *
          getUserSocietyPercentFD(user['_id'], props.society)) /
        100,
      partnerSince: getUserPartnerSince(user['_id'], props.society),
      isAdmin: props.isAdmin,
      userRoles: role,
      isSocietyHolding: props.society?.isSocietyHolding,
    }));

    if (newData.length > 0) {
      setTableData(
        transformData({
          data: newData,
          columns: tableColumns(i18n),
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows, i18n.language]);

  useEffect(() => {
    setCurrentTableColumns(tableColumns(i18n));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return (
    <div className="nk-content-body">
      <div className="nk-content-wrap">
        <div className="nk-block-head nk-block-head-sm">
          <div className="nk-block-between">
            <div className="nk-block-head-content">
              <h3 className="nk-block-title page-title">
                {t('SocietiesHolding')}
              </h3>
              <div className="nk-block-des text-soft">
                <p>
                  {societies?.length === 1
                    ? t('PortfolioNoSocieties', {
                        societyName: actualSociety?.name,
                      })
                    : t('PortfolioHaveSocieties', {
                        societyName: actualSociety?.name,
                        count: societies[1] ? societies[1]?.length : 0,
                      })}
                </p>
              </div>
            </div>
            <div className="nk-block-head-content">
              <div className="toggle-wrap nk-block-tools-toggle">
                <a
                  className="btn btn-icon btn-trigger toggle-expand mr-n1"
                  data-target="pageMenu"
                  aria-label="link"
                >
                  <em className="icon ni ni-menu-alt-r" />
                </a>
                <div className="toggle-expand-content" data-content="pageMenu">
                  <ul className="nk-block-tools g-3">
                    <li>
                      <div className="form-group cursor-pointer">
                        <div className="form-control-wrap ">
                          <div className="form-control-select">
                            <div className="form-icon form-icon-left">
                              <em
                                className={`icon ni ${
                                  filterBy === portfolioFilters.ALL
                                    ? 'ni-filter'
                                    : 'ni-filter-fill'
                                }`}
                              />
                            </div>
                            <select
                              className="form-control pr-4  cursor-pointer"
                              id="filter-society"
                              name="filter-society"
                              value={filterBy}
                              onChange={(event) =>
                                setFilterBy(event.target.value)
                              }
                            >
                              <option
                                value={portfolioFilters.ALL}
                                className="cursor-pointer"
                              >
                                {t('PortfolioFilterAll')}
                              </option>
                              <option
                                value={portfolioFilters.ACTIVE}
                                className="cursor-pointer"
                              >
                                {t('PortfolioFilterActive')}
                              </option>
                              <option
                                value={portfolioFilters.EXIT}
                                className="cursor-pointer"
                              >
                                {t('PortfolioFilterExit')}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <Link to={`/cuadro-portafolio/${societyId}`}>
                        <button type="button" className="btn btn-primary">
                          <em className="icon ni ni-menu-squared" />
                          <span>{t('View')}</span>
                        </button>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        {tableData.length && !!(tableData.length === rows.length) ? (
          <CustomDataTable
            data={tableData}
            columns={currentTableColumns}
            searchBy={t('Society')}
            pagination
            className="nk-tb-list"
            // expandableRows
            rowsPerPage={items}
            actions
            showDense
          />
        ) : (
          isLoadingData && <TableLoader rows={items} />
        )}
      </div>
    </div>
  );
}

export default PortfolioList;
