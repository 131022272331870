import jwtDecode from 'jwt-decode';
import sign from 'jwt-encode';
import { base64url, jwtDecrypt } from 'jose';
import hasShowDetails from './showDetails';

export const generateToken = (user) => {
  const payload = { ...user, check: true };
  const secret = process.env.REACT_APP_JWT_SECRET_KEY || 'key-not-found';

  return sign(payload, secret, {
    expiresIn: '8h',
  });
};

const decryptJwt = async (jwt, secret) => {
  const options = {
    issuer: process.env.REACT_APP_JWT_ISSUER,
    audience: process.env.REACT_APP_JWT_AUDIENCE,
    contentEncryptionAlgorithms: [process.env.REACT_APP_JWT_ENCODE_ALGORITHM],
    keyManagementAlgorithms: ['dir'],
  };
  return jwtDecrypt(jwt, secret, options);
};

export const decode = (token) => jwtDecode(token);

export const decodeResponse = (token) => {
  const decodedToken = decode(token);
  const { iat, exp, ...rest } = decodedToken;

  return rest?.response;
};

export const decryptResponse = async (token) => {
  const secret = base64url.decode(process.env.REACT_APP_JWT_SECRET_KEY);
  const decrypted = await decryptJwt(token, secret);

  if (hasShowDetails()) {
    console.log('cclog:', decrypted?.payload?.response || null);
  }

  return decrypted?.payload?.response || null;
};

export const decodeFromUrl = (token) => {
  const base64Url = token[1].split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  const decodedToken = JSON.parse(window.atob(base64));

  return decodedToken;
};

export const saveToStorage = (fieldName, token) => {
  window.localStorage.setItem(fieldName, token);
};
