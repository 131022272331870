export default {
  // Societies
  ADD_SOCIETY: 'Añadir Sociedad',
  ADD_SOCIETY_AI: 'Añadir Sociedad - IA',
  ADD_SOCIETY_WIZARD: 'Añadir Sociedad - Wizard',
  UPDATE_SOCIETY: 'Actualizar Sociedad',
  DELETE_SOCIETY: 'Eliminar Sociedad',
  DELETE_SOCIETY_VALUE: 'Eliminar Valor Sociedad',

  // Operations
  ADD_PARTNER: 'Añadir Socio',
  ADD_PARTNERS: 'Añadir Socios XLS',
  ADD_PARTNER_EMBEDDED: 'Añadir Socio Embebido',
  ADD_BENEFICIARY: 'Añadir Beneficiario',
  ROLLBACK_OPERATION: 'Deshacer Operación',

  CONSTITUTION: 'Operación Constitución',
  CAPITAL_INCREASE: 'Operación Ampliación',
  CAPITAL_DECREASE: 'Operación Reducción',
  SELL_PARTICIPATION: 'Operación Compra / Venta',
  SPLIT: 'Operación Split',
  RENUMERATION: 'Operación Renumeración',
  RECLASSIFICATION: 'Operación Reclasificación',
  DIVIDENDS_DISTRIBUTION: 'Operación Reparto de Dividendos',

  // Plans
  STOCK_OPTION: 'Añadir Plan Stock Options',
  PHANTOM_SHARES: 'Añadir Plan Phantom Shares',
  WARRANT: 'Añadir Plan Warrant',

  // Tender Offers
  ADD_TENDER_OFFER: 'Añadir Oferta de Compra',
  EDIT_TENDER_OFFER: 'Editar Oferta de Compra',

  // Boards
  ADD_BOARD: 'Añadir Junta',
  EDIT_BOARD: 'Editar Junta',
  ASSIST_BOARD: 'Asistente Junta',

  // Employees Plans
  ADD_EMPLOYEES_PLAN: 'Añadir Plan de Empleados',
  EDIT_EMPLOYEES_PLAN: 'Editar Plan de Empleados',

  // Drafts
  SELL_PARTICIPATION_DRAFT: 'Borrador Compra / Venta',

  // Notifications
  VESTING_UNITS_NOTIFICATION: 'Notificación de Unidades Vesteadas',
};
