import documents from '../assets/images/icon-sections/documents.svg';
import scriptures from '../assets/images/icon-sections/scriptures.svg';
import certificates from '../assets/images/icon-sections/certificates.svg';
import letters from '../assets/images/icon-sections/letters.svg';
import signatures from '../assets/images/icon-sections/signatures.svg';
import templates from '../assets/images/icon-sections/templates.svg';
import minutebook from '../assets/images/icon-sections/minutesbook.svg';
import assignments from '../assets/images/icon-sections/assignments.svg';
import authority from '../assets/images/icon-sections/authority.svg';
import annualreports from '../assets/images/icon-sections/annualreports.svg';

const documentSections = (i18n) => [
  {
    id: 0,
    tag: 'ALL',
    title: 'Home',
    url: '/documentos',
    icon: 'ni-home-alt',
    logo: null,
    enabled: true,
    hasSectionsHeader: true,
    emptyStateMessage: 'NoAllsMessage',
  },
  {
    id: 1,
    tag: 'DOCUMENTS',
    title: 'Documents',
    url: '/documentos/documentos',
    icon: 'ni-file-docs',
    logo: documents,
    enabled: true,
    hasSectionsHeader: false,
    emptyStateMessage: 'NoDocumentssMessage',
  },
  {
    id: 2,
    tag: 'SCRIPTURES',
    title: 'Scriptures',
    url: '/documentos/escrituras',
    icon: 'ni-file-pdf',
    logo: scriptures,
    hasSectionsHeader: false,
    enabled: false,
    emptyStateMessage: 'NoScriptureMessage',
  },
  {
    id: 3,
    tag: 'MINUTEBOOK',
    title: 'MinuteBooks',
    url: '/documentos/libro-actas',
    icon: 'ni-book',
    logo: minutebook,
    enabled: true,
    hasSectionsHeader: false,
    emptyStateMessage: 'NoMinuteBookMessage',
  },
  {
    id: 4,
    tag: 'COUNCILMINUTES',
    title: 'CouncilMinutes',
    url: '/documentos/actas-consejo',
    icon: 'ni-book-read',
    logo: minutebook,
    enabled: true,
    hasSectionsHeader: false,
    emptyStateMessage: 'NoCouncilMinutesMessage',
  },
  {
    id: 5,
    tag: 'AUTHORITY',
    title: 'Authorithy',
    url: '/documentos/poderes',
    icon: 'ni ni-file-text',
    logo: authority,
    enabled: true,
    hasSectionsHeader: false,
    emptyStateMessage: 'NoAuthorityMessage',
  },
  {
    id: 6,
    tag: 'ASSIGNMENTS',
    title: 'Assignments',
    url: '/documentos/nombramientos',
    icon: 'ni-shield-star',
    logo: assignments,
    enabled: true,
    hasSectionsHeader: false,
    emptyStateMessage: 'NoAssignmentsMessage',
  },
  {
    id: 7,
    tag: 'ANNUALREPORTS',
    title: 'AnnualReports',
    url: '/documentos/annualreports',
    icon: 'ni-reports',
    logo: annualreports,
    enabled: false,
    hasSectionsHeader: true,
    emptyStateMessage: 'NoAnnualReportsMessage',
  },
  {
    id: 8,
    tag: 'SIGNATURES',
    title: 'Signatures',
    url: '/documentos/firmas',
    icon: 'ni-pen',
    logo: signatures,
    enabled: false,
    hasSectionsHeader: true,
    emptyStateMessage: 'NoSignatureMessage',
  },
  {
    id: 9,
    tag: 'TEMPLATES',
    title: 'Templates',
    url: '/documentos/plantillas',
    icon: 'ni-file-code',
    logo: templates,
    enabled: true,
    hasSectionsHeader: true,
    emptyStateMessage: 'NoTemplateMessage',
  },
  {
    id: 10,
    tag: 'LETTERS',
    title: 'PlansLetters',
    url: '/documentos/cartas',
    icon: 'ni-mail',
    logo: letters,
    enabled: false,
    hasSectionsHeader: false,
    emptyStateMessage: 'NoLetterMessage',
  },
  {
    id: 11,
    tag: 'CERTIFICATES',
    title: 'Certificates',
    url: '/documentos/certificados',
    icon: 'ni-shield-check',
    logo: certificates,
    enabled: true,
    hasSectionsHeader: false,
    emptyStateMessage: 'NoCertificateMessage',
  },
  {
    id: 12,
    tag: 'MYFOLDERS',
    title: 'MyFolders',
    url: '/documentos/mis-carpetas',
    icon: 'ni-folder',
    logo: certificates,
    enabled: true,
    hasSectionsHeader: false,
    emptyStateMessage: 'NoFoldersMessage',
  },
  {
    id: 13,
    tag: 'DELETED',
    title: 'Deleted',
    url: '/documentos/eliminados',
    icon: 'ni-trash-alt',
    logo: null,
    enabled: true,
    hasSectionsHeader: false,
    emptyStateMessage: 'NoDeletedMessage',
  },
];

export default documentSections;
