import i18n from 'i18n/config';

export default {
  NO_CALCULATE: {
    value: 'NO_CALCULATE',
    text: i18n.t('NoCalculate'),
  },
  SIMPLE_MAJORITY: {
    value: 'SIMPLE_MAJORITY',
    text: i18n.t('SimpleMajority'),
  },
  SOCIETY_CAPITAL_50: {
    value: 'SOCIETY_CAPITAL_50',
    text: i18n.t('SocietyCapital50'),
  },
  PRESENT_CAPITAL_50_VOTE_33: {
    value: 'PRESENT_CAPITAL_50_VOTE_33',
    text: i18n.t('PresentCapital50IfVote33'),
  },
  SOCIETY_CAPITAL_66: {
    value: 'SOCIETY_CAPITAL_66',
    text: i18n.t('SocietyCapital66'),
  },
};
