/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getActualSociety } from 'utils/filters';
import { getDocumentsByReference } from 'redux/actions/documentActions';

import { setMenu } from 'redux/actions/menuActions';
import { setModal } from 'redux/actions/modalActions';
import menuTypes from 'constants/menuTypes';

import { format } from 'date-fns';
import { dateFormat } from 'constants/formats';
import downloadFile from 'utils/downloadFile';
import DocumentModal from './documentModal';

function SocietyDocuments() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { societyId } = useParams();
  const { user, documents, actualSociety } = useSelector((state) => ({
    user: state.user,
    documents: state.documents,
    actualSociety: state.society?.actualSociety,
  }));

  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId) getActualSociety(user, societyId);
  }, [actualSociety, user, societyId]);

  useEffect(() => {
    setRows(
      documents?.map(
        (document) =>
          !document.isGenerated && (
            <tr className="tb-ticket-item is-unread" key={document?.['_id']}>
              <td className="tb-ticket-id">#{document?.['_id'].slice(0, 6)}</td>
              <td className="tb-ticket-desc">
                <a>
                  <span className="title">{document?.name}</span>
                </a>
              </td>
              <td className="tb-ticket-date tb-col-md">
                <span className="date">
                  {format(new Date(document?.date), dateFormat)}
                </span>
              </td>
              <td className="tb-ticket-seen tb-col-md">
                <span className="date-last">
                  <em
                    className="icon-avatar bg-danger-dim icon ni ni-user-fill nk-tooltip"
                    title="Support Team"
                  />
                  {document?.author?.name}
                </span>
              </td>
              <td className="tb-ticket-action py-0">
                <span
                  className="btn btn-icon btn-trigger"
                  onClick={() =>
                    downloadFile({ documentId: document?.['_id'] })
                  }
                >
                  <em className="icon ni ni-file-download" />
                </span>
              </td>
            </tr>
          )
      )
    );
  }, [documents]);

  useEffect(() => {
    if (actualSociety) {
      dispatch(
        setMenu({
          type: menuTypes.EXTENDED,
          societyId: actualSociety['_id'],
          societyName: actualSociety?.name,
        })
      );
    }
  }, [actualSociety, dispatch]);

  useEffect(() => {
    if (societyId) {
      dispatch(getDocumentsByReference({ societyId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [societyId]);

  return (
    <div className="nk-content-body">
      <div className="nk-content-wrap">
        <div className="nk-block-head">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">{actualSociety?.name}</h3>
            <p />
          </div>
          <div className="nk-block-between g-3">
            <div className="nk-block-head-content">
              <h3 className="nk-block-title page-title">{t('Documents')}</h3>
              <div className="nk-block-des text-soft">
                <p>
                  {`${t('YouHaveDocuments', {
                    count: actualSociety?.documents?.length,
                  })} ${actualSociety?.name}`}
                </p>
              </div>
            </div>
            <div className="nk-block-head-content">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() =>
                  dispatch(
                    setModal(
                      <DocumentModal
                        userId={user['_id']}
                        societyId={actualSociety['_id']}
                        showLinkOptions
                      />
                    )
                  )
                }
              >
                {t('AddDocument')}
              </button>
            </div>
          </div>
        </div>
        {rows?.length > 0 ? (
          <div className="nk-block">
            <div className="card card-bordered">
              <table className="table table-tickets">
                <thead className="tb-ticket-head">
                  <tr className="tb-ticket-title">
                    <th className="tb-ticket-id">
                      <span>{t('Nr')}</span>
                    </th>
                    <th className="tb-ticket-desc">
                      <span>{t('Document')}</span>
                    </th>
                    <th className="tb-ticket-date tb-col-md">
                      <span>{t('Date')}</span>
                    </th>
                    <th className="tb-ticket-seen tb-col-md">
                      <span>{t('Author')}</span>
                    </th>
                    <th className="tb-ticket-action"> &nbsp; </th>
                  </tr>
                </thead>
                <tbody className="tb-ticket-body">{rows}</tbody>
              </table>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default SocietyDocuments;
