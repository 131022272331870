/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import LineChart from 'components/Charts/LineChart';
import i18n from 'i18n/config';

import './WidgetChartLine.scss';

const WidgetChartLine = ({
  title,
  subtitle,
  height,
  kpis,
  data,
  extraLabel,
  extraValue,
}) => (
  <div className="col-12 col-xl-7 mb-4">
    <div className="card card-bordered h-100">
      <div className="card-inner">
        <div className="card-title-group pb-3 g-2">
          <div className="card-title card-title-sm">
            <h6 className="title">{title}</h6>
            <p>{subtitle}</p>
          </div>

          <div className="card-title card-title-sm text-right">
            {extraLabel && <h6 className="title">{extraLabel}</h6>}
            {extraValue && <p>{extraValue}</p>}
          </div>
        </div>

        <div className="analytic-ov">
          <div className="analytic-data-group analytic-ov-group mt-1">
            {kpis.map((item) => (
              <div className="analytic-data" key={uuidv4()}>
                <div className="title">{item?.title}</div>
                <div className="amount">{item?.value}</div>
                <Link to={item?.link} className="fs-12px">
                  <em className={`icon mr-1 ni ${item?.icon}`} />
                  {i18n.t('SeeMore')}
                </Link>
              </div>
            ))}
          </div>

          <div className="mt-3">
            <LineChart
              data={data.data}
              options={data.options}
              height={height}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default WidgetChartLine;
