import React, { FC, useEffect, useRef, useState } from 'react';
import Record from 'typescript';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { AsyncTypeahead, ClearButton } from 'react-bootstrap-typeahead';
import { useTranslation } from 'react-i18next';
import { getSocietiesRegistered } from 'redux/actions/societyActions';

import IconSocietyDemo from 'assets/images/wizard-icon-demo.png';
import IconSocietyScripture from 'assets/images/wizard-icon-scripture.png';

interface StepProps {
  data: Record<string, any>;
  setData: (data: any) => void;
  setCompletedSteps: (data: any) => void;
  setActiveStep: (data: any) => void;
}
interface LanguageOption {
  value: string;
  label: string;
}

const Step1: FC<StepProps> = ({
  data,
  setData,
  setCompletedSteps,
  setActiveStep,
}) => {
  const { t } = useTranslation();

  const animatedComponents = makeAnimated();

  const [hasSelectedWay, setHasSelectedWay] = useState<boolean>(false);

  const [cif, setCif] = useState<string>('');
  const [line1, setLine1] = useState<string>('');
  const [zip, setZip] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [country, setCountry] = useState<string>('');
  const [socialDenomination, setSocialDenomination] = useState<string>('');
  const [webDomain, setWebDomain] = useState<string>('');
  const [societyLogo, setSocietyLogo] = useState<string>('');
  const [societySource, setSocietySource] = useState<string>('');

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [textFromSociety, setTextFromSociety] = useState<string>('');
  const [societiesList, setSocietiesList] = useState<Record<string, any>[]>([]);

  const [sendNotifications, setSendNotifications] = useState<boolean>(false);
  const [hasAccessCaptable, setHasAccessCaptable] = useState<boolean>(false);

  const commsLanguage = useRef<string>('es');
  const [selectedLanguageOption, setSelectedLanguageOption] =
    useState<LanguageOption>({
      value: 'es',
      label: t('Spanish'),
    });

  const languageOptions: LanguageOption[] = [
    {
      value: 'es',
      label: t('Spanish'),
    },
    {
      value: 'en',
      label: t('English'),
    },
    {
      value: 'fr',
      label: t('French'),
    },
  ];

  const handleLanguageOptionChange = (selectedOption: any): void => {
    if (selectedOption) {
      setSelectedLanguageOption(selectedOption);
      commsLanguage.current = selectedOption.value;
      setData((prevData: any) => ({
        ...prevData,
        commsLanguage: selectedOption.value,
      }));
    }
  };

  const handleChangeValue = (e: any, setValue: any): void => {
    const newValue =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setValue(newValue);
    setData((prevData: any) => ({
      ...prevData,
      [e.target.name]: newValue,
    }));
  };

  useEffect(() => {
    if (socialDenomination && cif) {
      setCompletedSteps((prevData: any) => ({
        ...prevData,
        0: true,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cif, socialDenomination]);

  useEffect(() => {
    setSocialDenomination(data.socialDenomination || '');
    setCif(data.cif || '');
    setLine1(data.line1 || '');
    setZip(data.zip || '');
    setCity(data.city || '');
    setCountry(data.country || '');
    if (data?.commsLanguage) {
      commsLanguage.current = data?.commsLanguage;
      const currOption = languageOptions.find(
        (option) => option?.value === data?.commsLanguage
      );
      setSelectedLanguageOption(
        currOption || {
          value: 'es',
          label: t('Spanish'),
        }
      );
    }
    setSendNotifications(data.sendNotifications || false);
    setHasAccessCaptable(data.hasAccessCaptable || false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectUpload = (): void => {
    setData((prevData: any) => ({
      ...prevData,
      societySource: 'FILE',
    }));
    setSocietySource('FILE');
    setHasSelectedWay(true);
  };

  const handleSelectDemo = (): void => {
    setData((prevData: any) => ({
      ...prevData,
      societySource: 'DEMO',
    }));
    setSocietySource('DEMO');
    setHasSelectedWay(true);
  };

  const handleDrop = (e: any): void => {
    console.log(e);
  };

  const handleDragOver = (e: any): void => {
    console.log(e);
  };

  const handleChooseDocument = (e: any): void => {
    console.log(e);
  };

  const handleSelectCompany = (society: Record<string, any>): void => {
    if (society?.customOption) {
      setSocialDenomination(society.label);
      setData((prevData: any) => ({
        ...prevData,
        socialDenomination: society.label,
        commsLanguage: 'es',
      }));
    } else {
      const { name, address } = society?.nameAddress;
      const { registeredOfficeCode } = society?.registeredOffice;

      setSocialDenomination(name);
      setCif(registeredOfficeCode);
      setLine1(address.street);
      setZip(address.postCode);
      setCity(address.city);
      setCountry(address.country);
      setData((prevData: any) => ({
        ...prevData,
        socialDenomination: name,
        cif: registeredOfficeCode,
        line1: address.street,
        zip: address.postCode,
        city: address.city,
        country: address.country,
        commsLanguage: 'es',
      }));
    }
    setSocietiesList([]);
    setTextFromSociety('');
  };

  const clearSelectCompany = () => {
    setSocialDenomination('');
    setCif('');
    setLine1('');
    setZip('');
    setCity('');
    setCountry('');
    setData((prevData: any) => ({
      ...prevData,
      socialDenomination: '',
      cif: '',
      line1: '',
      zip: '',
      city: '',
      country: '',
    }));
  };

  const handleAutoCompleteBlur = () => {
    if (textFromSociety) {
      handleSelectCompany({
        customOption: true,
        label: textFromSociety,
        id: 'new-id-1',
      });
    }
  };

  const handleBlurWebDomain = () => {
    if (webDomain.includes('.')) {
      setSocietyLogo(`https://logo.clearbit.com/${webDomain}`);
    } else {
      setSocietyLogo('');
    }
  };

  const handleGoNextStep = () => {
    if (societySource === 'FILE') {
      setActiveStep(1);
    }

    if (societySource === 'DEMO') {
      setActiveStep(2);
    }
  };

  return (
    <div className="nk-stepper-step">
      <h5 className="title mb-2">
        {hasSelectedWay
          ? t('Datos de la sociedad')
          : t('¿Cómo quieres añadir la Sociedad?')}
      </h5>

      {!hasSelectedWay && (
        <div className="d-flex flex-row justify-content-between g-5 mt-4">
          {/* UPLOAD SCRIPTURE */}
          <div className="flex-item flex-grow-1">
            <div className="card card-bordered wizard--final-button">
              <img
                src={IconSocietyScripture}
                className="card-img-top mx-auto"
                style={{ width: 'fit-content', height: '250px' }}
                alt="Upload society scripture"
              />
              <div className="card-inner">
                <h5 className="card-title">Subiré una Escritura</h5>
                <p className="card-text">
                  Extraeremos los datos principales de la Sociedad así como de
                  sus socios y constitución. Puede ser en formato .PDF o .DOCX.
                </p>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSelectUpload}
                >
                  <em className="icon ni ni-upload mr-1" />
                  Seleccionar archivo
                </button>
              </div>
            </div>
          </div>

          {/* USE DEMO */}
          <div className="flex-item flex-grow-1">
            <div className="card card-bordered wizard--final-button">
              <img
                src={IconSocietyDemo}
                className="card-img-top h-250px mx-auto"
                style={{ width: 'fit-content', height: '250px' }}
                alt="Use society demo"
              />
              <div className="card-inner">
                <h5 className="card-title">Utilizaré una Demo</h5>
                <p className="card-text">
                  Añadiremos una Sociedad de ejemplo para que puedas ver cómo
                  funciona la plataforma y tengas acceso a todas las
                  funcionalidades.
                </p>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSelectDemo}
                >
                  <em className="icon ni ni-arrow-from-right mr-1" />
                  Solicitar demo
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {hasSelectedWay && (
        <>
          <div className="row mt-4">
            <div className="col-6">
              <div className="form-group">
                <label className="form-label" htmlFor="society-web-domain">
                  {t('Dominio web')}
                  <span className="text-danger ml-1 d-none">*</span>
                </label>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    className="form-control"
                    id="society-web-domain"
                    name="web-domain"
                    value={webDomain}
                    onChange={(e) => handleChangeValue(e, setWebDomain)}
                    onBlur={handleBlurWebDomain}
                    required
                  />
                </div>
              </div>
            </div>

            <div className="col-6">
              <label className="form-label w-100" htmlFor="fw-mobile-number">
                {t('SocialDenomination')}
                <span className="text-danger ml-1">*</span>
                <AsyncTypeahead
                  id="societies-search-box"
                  className="mt-1 mb-0"
                  isLoading={isLoading}
                  labelKey="label"
                  minLength={3}
                  onSearch={(query: any) => {
                    setIsLoading(true);
                    setTextFromSociety(query);
                    getSocietiesRegistered(query).then((societies) => {
                      setSocietiesList(societies);
                    });
                    setIsLoading(false);
                  }}
                  onChange={([society]: any) => {
                    if (society) {
                      handleSelectCompany(society);
                    } else {
                      clearSelectCompany();
                    }
                  }}
                  onBlur={handleAutoCompleteBlur}
                  options={societiesList}
                  useCache={false}
                  defaultInputValue={socialDenomination}
                  newSelectionPrefix={`${t('Select:')} `}
                  allowNew
                >
                  {({ onClear, selected }: any) => (
                    <div className="rbt-aux">
                      {!!selected.length && <ClearButton onClick={onClear} />}
                    </div>
                  )}
                </AsyncTypeahead>
              </label>
            </div>

            <div className="col-6">
              <div className="form-group mt-2">
                <label className="form-label" htmlFor="society-cif">
                  {t('Cif')}
                  <span className="text-danger ml-1">*</span>
                </label>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    className="form-control"
                    id="society-cif"
                    name="cif"
                    value={cif}
                    onChange={(e) => handleChangeValue(e, setCif)}
                    required
                  />
                </div>
              </div>
            </div>

            <div className="col-6">
              <div className="form-group mt-2">
                <label className="form-label" htmlFor="society-address">
                  {t('Address')}
                </label>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    className="form-control"
                    id="society-address"
                    name="address"
                    value={line1}
                    onChange={(e) => handleChangeValue(e, setLine1)}
                    required
                  />
                </div>
              </div>
            </div>

            <div className="col-3">
              <div className="form-group mt-4">
                <label className="form-label" htmlFor="society-zip">
                  {t('PostalCode')}
                </label>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    className="form-control"
                    id="society-zip"
                    name="zip"
                    value={zip}
                    onChange={(e) => handleChangeValue(e, setZip)}
                    required
                  />
                </div>
              </div>

              <div className="form-group mt-3">
                <label className="form-label" htmlFor="society-country">
                  {t('Country')}
                </label>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    className="form-control"
                    id="society-country"
                    name="country"
                    value={country}
                    onChange={(e) => handleChangeValue(e, setCountry)}
                    required
                  />
                </div>
              </div>
            </div>

            <div className="col-3">
              <div className="form-group mt-4">
                <label className="form-label" htmlFor="society-city">
                  {t('City')}
                </label>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    className="form-control"
                    id="society-city"
                    name="city"
                    value={city}
                    onChange={(e) => handleChangeValue(e, setCity)}
                    required
                  />
                </div>
              </div>

              <div className="form-group mt-3">
                <label className="form-label" htmlFor="society-comms-language">
                  {t('CommunicationsLanguage')}
                </label>
                <div className="form-control-wrap">
                  <Select
                    closeMenuOnSelect
                    className="react-select"
                    value={selectedLanguageOption}
                    options={languageOptions}
                    components={animatedComponents}
                    onChange={handleLanguageOptionChange}
                  />
                </div>
              </div>
            </div>

            <div className="col-6 d-flex justify-content-center align-items-center mt-4">
              {societyLogo && (
                <img src={societyLogo} alt="Society Logo" className="mx-auto" />
              )}
            </div>
          </div>

          <div className="wizard--pagination card-inner p-0 mt-5">
            <ul className="nk-stepper-pagination gx-4 stepper-pagination">
              <li className="step-prev col-3">
                <button
                  type="button"
                  className="btn btn-dim btn-primary btn-block"
                  onClick={() => setHasSelectedWay(false)}
                >
                  {t('Back')}
                </button>
              </li>

              <li className="step-next col-3">
                <button
                  type="button"
                  className="btn btn-primary btn-block"
                  onClick={handleGoNextStep}
                  disabled={!socialDenomination || !cif}
                >
                  {t('Next')}
                </button>
              </li>
            </ul>
          </div>
        </>
      )}
    </div>
  );
};

export default Step1;
