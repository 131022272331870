import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import parse from 'html-react-parser';

import { Communication } from 'types';

type CommunicationPreviewProps = {
  data: { communication: Communication };
};

const CommunicationPreview: FC<CommunicationPreviewProps> = ({ data }) => {
  const { t } = useTranslation();

  const { communication } = data;

  const [parsedDocument, setParsedDocument] = useState<
    string | JSX.Element | JSX.Element[]
  >();

  const handleSendNotification = () => {
    console.log('Send');
  };

  useEffect(() => {
    if (communication?.body) {
      // Parse the HTML content.
      const parsedResult = parse(communication?.body);
      setParsedDocument(parsedResult);
    }
  }, [communication?.body]);

  return (
    <div className="nk-reply-entry entry">
      <p
        style={{
          whiteSpace:
            typeof parsedDocument === 'string' ? 'pre-line' : 'normal',
        }}
      >
        {parsedDocument || ''}
      </p>
    </div>
  );
};

export default CommunicationPreview;
