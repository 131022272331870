import store from 'redux/store';
import React from 'react';
import { setModal } from 'redux/actions/modalActions';
import GenerateDocument from 'components/Modals/GenerateDocument';
import previewDownload from 'utils/previewDownload';
import downloadWord from 'utils/downloadWord';

function menuOptions(i18n) {
  return [
    {
      icon: 'ni-file-pdf',
      text: i18n.t('DownloadPDF'),
      action: previewDownload,
      disabled: false,
    },
    {
      icon: 'ni-file-doc',
      text: i18n.t('DownloadWord'),
      action: ({preview}) =>
        downloadWord(preview.documentModel, preview.name),
    },
    {
      icon: 'ni-edit',
      text: i18n.t('Edit'),
      action: ({ setShowEditor }) => setShowEditor(true),
      disabled: false,
    },
    {
      icon: 'ni-files',
      text: i18n.t('ChangeTemplate'),
      action: ({ board }) =>
        store.dispatch(setModal(<GenerateDocument currentBoard={board} />)),
      disabled: false,
    },
  ];
}
export default menuOptions;
