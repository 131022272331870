import { useMemo } from 'react';

const useUserValidation = (user) => {
  const hasAdministrated = useMemo(
    () => user.societies?.administrated.length > 0,
    [user]
  );

  const hasParticipated = useMemo(
    () => user.societies?.participated.length > 0,
    [user]
  );

  const hasBeneficiated = useMemo(
    () => user.societies?.beneficiated.length > 0,
    [user]
  );

  const hasInvested = useMemo(
    () => user.societies?.invested.length > 0,
    [user]
  );

  const isAdministratorOnly = useMemo(
    () =>
      hasAdministrated && !hasParticipated && !hasBeneficiated && !hasInvested,
    [hasAdministrated, hasBeneficiated, hasInvested, hasParticipated]
  );

  const isParticipantOnly = useMemo(
    () =>
      !hasAdministrated && hasParticipated && !hasBeneficiated && !hasInvested,
    [hasAdministrated, hasBeneficiated, hasInvested, hasParticipated]
  );

  const isBeneficiaryOnly = useMemo(
    () =>
      !hasAdministrated && !hasParticipated && hasBeneficiated && !hasInvested,
    [hasAdministrated, hasBeneficiated, hasInvested, hasParticipated]
  );

  const isInvestorOnly = useMemo(
    () =>
      !hasAdministrated && !hasParticipated && !hasBeneficiated && hasInvested,
    [hasAdministrated, hasBeneficiated, hasInvested, hasParticipated]
  );

  return {
    hasAdministrated,
    hasParticipated,
    hasBeneficiated,
    hasInvested,
    isAdministratorOnly,
    isParticipantOnly,
    isBeneficiaryOnly,
    isInvestorOnly,
  };
};

export default useUserValidation;
