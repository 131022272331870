/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import exportFromJSON from 'export-from-json';
import { Col, Modal, ModalBody } from 'reactstrap';
import i18n from 'i18n/config';

import { dateFormat } from 'constants/formats';
import { format } from 'date-fns';
import styles from './styles';

const cleanData = ({ data, columns }) => {
  const cleanData = [];

  data.forEach((row) => {
    const cleanRow = {};

    columns.forEach((column) => {
      if (column.export) cleanRow[column.name] = row[column.name];
    });

    cleanData.push(cleanRow);
  });
  return cleanData;
};

const Export = ({
  data,
  fileName,
  filterByParam,
  filterText,
  filterIcon,
  statusFilter,
  setStatusFilter,
}) => {
  const [modal, setModal] = useState(false);

  useEffect(() => {
    if (modal === true) {
      setTimeout(() => setModal(false), 2000);
    }
  }, [modal]);

  const SettingsToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="dropdown-toggle btn-trigger mr-lg-n1"
    >
      {children}
      <em className="icon ni ni-setting fs-22px" />
    </div>
  ));
  SettingsToggle.displayName = 'SettingsToggle';

  return filterByParam ? (
    <>
      <div className="dt-export-buttons d-flex align-center">
        <div className="dt-buttons btn-group flex-wrap">
          <Dropdown className="pr-0">
            <Dropdown.Toggle as={SettingsToggle} />
            <Dropdown.Menu
              size="sm"
              title=""
              className="link-list-opt no-bdr dropdown-menu"
            >
              {/* <Dropdown.Item onClick={() => exportExcel()}>
                <em className="icon ni ni-file-download fs-20px" />
                {i18n.t('DownloadXLS')}
              </Dropdown.Item> */}

              {filterByParam && (
                <Dropdown.Item onClick={() => setStatusFilter(!statusFilter)}>
                  <em className={`icon fs-20px ${filterIcon[statusFilter]}`} />
                  {filterText[statusFilter]}
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <Modal
        isOpen={modal}
        className="modal-dialog-centered text-center"
        size="sm"
      >
        <ModalBody className="text-center m-2">
          <h5>Copied to clipboard</h5>
        </ModalBody>
        <div className="p-3 bg-light">
          <div className="text-center">
            Copied {data.length} rows to clipboard
          </div>
        </div>
      </Modal>
    </>
  ) : (
    <></>
  );
};

const ExpandableRowComponent = ({ data }) => (
  <ul className="dtr-details p-2 border-bottom ml-1">
    {Object.entries(data).map(([key, value]) => (
      <li className="d-block">
        <span className="dtr-title">{key}</span>{' '}
        <span className="dtr-data">{value || '-'}</span>
      </li>
    ))}
  </ul>
);

const CustomCheckbox = React.forwardRef(({ onClick, ...rest }, ref) => (
  <div className="custom-control custom-control-sm custom-checkbox notext">
    <input
      id={rest.name}
      type="checkbox"
      className="custom-control-input form-control"
      ref={ref}
      onClick={onClick}
      {...rest}
    />
    <label className="custom-control-label" htmlFor={rest.name} />
  </div>
));

const CustomDataTable = ({
  data,
  columns,
  pagination,
  actions,
  className,
  selectableRows,
  expandableRows,
  // custom params
  rowsPerPage,
  searchBy,
  searchByPlaceholder = '',
  defaultSortFieldId,
  defaultSortAsc = true,
  showDense = false,
  showDownload = true,
  showNationality = false,
  toggleNationality,
  customData,
  fileName = 'Export',
  filterText,
  filterIcon,
  filterByParam,
}) => {
  const [tableData, setTableData] = useState(data);
  const [searchText, setSearchText] = useState('');
  const [rowsPerPageS, setRowsPerPage] = useState(rowsPerPage || 10);
  const [mobileView, setMobileView] = useState();
  const [dense, setDense] = useState(false);
  const [statusFilter, setStatusFilter] = useState(true);

  const paginationComponentOptions = {
    rowsPerPageText: i18n.t('Show'),
    rangeSeparatorText: i18n.t('Of'),
    selectAllRowsItem: true,
    selectAllRowsItemText: i18n.t('Todos'),
  };

  const exportExcel = ({ data, fileName }) => {
    const exportType = exportFromJSON.types.xls;

    const parsedFileName = fileName.replace(/ /g, '_');

    const dataConverted = data.map((row) => {
      const currentRow = {};

      Object.entries(row).forEach(([key, value]) => {
        if (typeof value === 'number') {
          currentRow[key] = value.toString().replace('.', ',');
        } else if (value instanceof Date) {
          currentRow[key] = format(new Date(value), dateFormat);
        } else {
          currentRow[key] = value;
        }
      });

      return currentRow;
    });

    exportFromJSON({
      data: dataConverted,
      fileName: parsedFileName,
      exportType,
    });
  };

  useEffect(() => {
    let defaultData = tableData;
    if (searchText !== '') {
      defaultData = data.filter((item) => {
        const normalizedSearchText = searchText
          ?.normalize('NFD')
          ?.replace(/[\u0300-\u036f]/g, '')
          ?.toLowerCase();

        const isMatch = Array.isArray(searchBy)
          ? searchBy.some((key) => {
              const normalizedItemText = item[key]
                ?.normalize('NFD')
                ?.replace(/[\u0300-\u036f]/g, '')
                ?.toLowerCase();
              return normalizedItemText?.includes(normalizedSearchText);
            })
          : item[searchBy]
              ?.normalize('NFD')
              ?.replace(/[\u0300-\u036f]/g, '')
              ?.toLowerCase()
              ?.includes(normalizedSearchText);

        return isMatch;
      });

      setTableData(defaultData);
    } else {
      setTableData(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  // function to change the design view under 1200 px
  const viewChange = () => {
    if (window.innerWidth < 960 && expandableRows) {
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  };

  const getSearchPlaceholder = (searchBy) => {
    const prefix = searchBy ? i18n.t('SearchBy') : i18n.t('Search');

    if (Array.isArray(searchBy)) {
      const searchByFields = searchBy?.map((key) => key)?.join(', ');
      return `${prefix} ${searchByFields}`;
    }

    if (searchBy) {
      return `${prefix} ${searchBy}`;
    }

    return prefix;
  };

  useEffect(() => {
    window.addEventListener('load', viewChange);
    window.addEventListener('resize', viewChange);
    return () => {
      window.removeEventListener('resize', viewChange);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (filterByParam) {
      if (statusFilter) {
        const filteredData = data.filter(
          (item) => item[filterByParam] === true
        );
        setTableData(filteredData);
      } else {
        setTableData(data);
      }
    } else {
      setTableData(data);
    }
  }, [data, statusFilter, searchBy, columns, filterByParam]);

  return (
    <div
      className={`dataTables_wrapper dt-bootstrap4 no-footer ${
        className || ''
      }`}
    >
      <div className="nk-block">
        <div className="card card-bordered card-stretch">
          <div className="card-inner-group">
            <div className="card-inner position-relative card-tools-toggle">
              {/* TABLE HEADER */}
              <div className="card-title-group">
                {searchBy && (
                  <Col className="col-6 text-left" sm="4">
                    <div
                      id="DataTables_Table_0_filter"
                      className="dataTables_filter d-flex-inline"
                    >
                      <div className="form-control-wrap">
                        <div className="form-icon form-icon-left">
                          <em className="icon ni ni-search" />
                        </div>
                        <input
                          type="search"
                          className="form-control"
                          placeholder={getSearchPlaceholder(
                            searchByPlaceholder || searchBy
                          )}
                          onChange={(ev) => setSearchText(ev.target.value)}
                        />
                      </div>
                    </div>
                  </Col>
                )}

                <Col className="col-6 text-right" sm="8">
                  <div className="datatable-filter">
                    <div className="d-flex justify-content-end g-2">
                      {toggleNationality && (
                        <div className="dt-export-buttons d-flex align-center">
                          <div className="dt-buttons btn-group flex-wrap">
                            <button
                              type="button"
                              className="btn btn-white btn-trigger p-0"
                              onClick={toggleNationality}
                            >
                              {showNationality ? (
                                <em className="icon ni ni-flag" />
                              ) : (
                                <em className="icon ni ni-flag-fill" />
                              )}
                            </button>
                          </div>
                        </div>
                      )}
                      {actions && (
                        <Export
                          data={cleanData({ data, columns })}
                          fileName={fileName || 'Export'}
                          filterText={filterText}
                          filterIcon={filterIcon}
                          filterByParam={filterByParam}
                          statusFilter={statusFilter}
                          setStatusFilter={setStatusFilter}
                        />
                      )}
                      {showDownload && (
                        <div className="dt-export-buttons d-flex align-center">
                          <div className="dt-buttons btn-group flex-wrap">
                            <button
                              type="button"
                              className="btn btn-white btn-trigger p-0"
                              onClick={() => {
                                if (customData) {
                                  exportExcel({
                                    data: customData,
                                    fileName,
                                  });
                                } else {
                                  exportExcel({
                                    data: cleanData({ data, columns }),
                                    fileName,
                                  });
                                }
                              }}
                            >
                              <em className="icon ni ni-download" />
                            </button>
                          </div>
                        </div>
                      )}
                      {showDense && (
                        <div className="dt-export-buttons d-flex align-center">
                          <div className="dt-buttons btn-group flex-wrap">
                            <button
                              type="button"
                              className="btn btn-white btn-trigger p-0"
                              onClick={() => setDense(!dense)}
                            >
                              {dense ? (
                                <em className="icon ni ni-view-list-wd" />
                              ) : (
                                <em className="icon ni ni-view-list-fill" />
                              )}
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </Col>
              </div>
            </div>

            <DataTable
              data={tableData}
              columns={columns}
              className={className}
              selectableRows={selectableRows}
              selectableRowsComponent={CustomCheckbox}
              expandableRowsComponent={ExpandableRowComponent}
              expandableRows={mobileView}
              noDataComponent={
                <div className="p-2">No se han encontrado registros</div>
              }
              sortIcon={
                <div>
                  <span>&darr;</span>
                  <span>&uarr;</span>
                </div>
              }
              defaultSortFieldId={defaultSortFieldId}
              defaultSortAsc={defaultSortAsc}
              customStyles={styles}
              pagination={pagination}
              paginationComponentOptions={paginationComponentOptions}
              // paginationComponent={({
              //   currentPage,
              //   rowsPerPage,
              //   rowCount,
              //   onChangePage,
              //   onChangeRowsPerPage,
              // }) => (
              //   <DataTablePagination
              //     customItemPerPage={rowsPerPageS}
              //     itemPerPage={rowsPerPage}
              //     totalItems={rowCount}
              //     paginate={onChangePage}
              //     currentPage={currentPage}
              //     onChangeRowsPerPage={onChangeRowsPerPage}
              //     setRowsPerPage={setRowsPerPage}
              //   />
              // )}
              dense={dense}
              highlightOnHover
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomDataTable;
