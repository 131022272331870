import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import store from 'redux/store';
import { setModal } from 'redux/actions/modalActions';
import { getUsers } from 'redux/actions/userActions';

import SocietyAdministratorRow from './SocietyAdministratorRow';
import AddAdmin from './AddAdmin';
import InformationComponent from './InformationComponent';

import restrictedview from '../../assets/images/restricted-view.svg';

const SocietyAdministrator = () => {
  const { t } = useTranslation();
  const [rows, setRows] = useState([]);
  const { actualSociety, isAdmin, user } = useSelector((state) => ({
    actualSociety: state?.society?.actualSociety,
    isAdmin: state?.society?.role?.isAdmin,
    user: state.user,
  }));

  const isDemo = useSelector((state) => state.society?.role?.isDemo);

  const [societyAdmins, setSocietyAdmins] = useState([]);

  const getAdmins = async (admins) => {
    try {
      const adminsList = await store.dispatch(
        getUsers({
          usersList: admins,
        })
      );
      setSocietyAdmins(adminsList);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (actualSociety?.administrators.length || user?.accountAdmins.length) {
      if (isAdmin)
        getAdmins([...actualSociety?.administrators, ...user?.accountAdmins]);
      else getAdmins(actualSociety?.administrators);
    } else setSocietyAdmins([]);
  }, [actualSociety?.administrators, user?.accountAdmins]);

  useEffect(() => {
    if (societyAdmins) {
      setRows(
        societyAdmins?.map((administrator, index) => (
          <SocietyAdministratorRow
            administrator={administrator}
            society={actualSociety}
            user={user}
            isAdmin={isAdmin}
            index={index}
            key={administrator['_id'] || ''}
          />
        ))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [societyAdmins]);

  return (
    <div className="nk-block">
      <div className="nk-block-head">
        <div className="nk-block-head-content">
          <h5 className="nk-block-title title">
            {t('ManagerUsersOfTheCompany')}
          </h5>
          <div className="nk-block-des">
            <p>{t('HereYouCanGiveAccessToOth')}</p>
          </div>
        </div>
      </div>
      <div className="nk-block">
        {isAdmin ? (
          <div className="nk-block-head">
            <div className="nk-block-head-content">
              <button
                type="button"
                className="btn btn-success"
                onClick={() =>
                  store.dispatch(
                    setModal(<AddAdmin societyId={actualSociety?.['_id']} />)
                  )
                }
                disabled={isDemo}
              >
                {t('AddUsers')}
              </button>
            </div>
          </div>
        ) : (
          <> </>
        )}
        {!isDemo ? (
          <div className="card card-bordered card-preview">
            <table className="nk-tb-list nk-tb-ulist">
              <thead>
                <tr className="nk-tb-item nk-tb-head tb-tnx-head fs-11px">
                  <th className="nk-tb-col tb-col-xl">
                    <span>#</span>
                  </th>

                  <th className="nk-tb-col">
                    <span>{t('NameAndSurname')}</span>
                  </th>

                  <th className="nk-tb-col tb-col-xl">
                    <span>{t('HighDate')}</span>
                  </th>

                  <th className="nk-tb-col tb-col-xl">
                    <span>{t('LastAccess')}</span>
                  </th>

                  <th className="nk-tb-col tb-col-xl">
                    <span>{t('E-Mail')}</span>
                  </th>

                  <th className="nk-tb-col">
                    <span>{t('AccessLevel')}</span>
                  </th>

                  {isAdmin && !isDemo && (
                    <th className="nk-tb-col nk-tb-col-tools">
                      <span />
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </table>
          </div>
        ) : (
          <div className="d-flex justify-content-center">
            <InformationComponent
              mainMessage={t('RestrictedInfo')}
              infoMessage={t('NoAccessToThisInfo')}
              imageIcon={restrictedview}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SocietyAdministrator;
