import { useMemo } from 'react';

const useRolesValidation = (roles) => {
  const isAdmin = roles?.isAdmin || false;
  const isPartner = roles?.isPartner || false;
  const isBeneficiary = roles?.isBeneficiary || false;
  const isInvestor = roles?.isInvestor || false;
  const isHolder = roles?.isHolder || false;
  const isDemo = roles?.isDemo || false;

  const isAdminOnly = useMemo(
    () => isAdmin && !isPartner && !isBeneficiary && !isInvestor && !isHolder,
    [isAdmin, isPartner, isBeneficiary, isInvestor, isHolder]
  );

  const isPartnerOnly = useMemo(
    () => !isAdmin && isPartner && !isBeneficiary && !isInvestor && !isHolder,
    [isAdmin, isPartner, isBeneficiary, isInvestor, isHolder]
  );

  const isBeneficiaryOnly = useMemo(
    () => !isAdmin && !isPartner && isBeneficiary && !isInvestor && !isHolder,
    [isAdmin, isPartner, isBeneficiary, isInvestor, isHolder]
  );

  const isInvestorOnly = useMemo(
    () => !isAdmin && !isPartner && !isBeneficiary && isInvestor && !isHolder,
    [isAdmin, isPartner, isBeneficiary, isInvestor, isHolder]
  );

  const isHolderOnly = useMemo(
    () => !isAdmin && !isPartner && !isBeneficiary && !isInvestor && isHolder,
    [isAdmin, isPartner, isBeneficiary, isInvestor, isHolder]
  );

  const isDemoOnly = useMemo(
    () =>
      !isAdmin &&
      !isPartner &&
      !isBeneficiary &&
      !isInvestor &&
      !isHolder &&
      isDemo,
    [isAdmin, isPartner, isBeneficiary, isInvestor, isHolder, isDemo]
  );

  return {
    isAdmin,
    isPartner,
    isBeneficiary,
    isInvestor,
    isHolder,
    isDemo,
    isAdminOnly,
    isPartnerOnly,
    isBeneficiaryOnly,
    isInvestorOnly,
    isHolderOnly,
    isDemoOnly,
  };
};

export default useRolesValidation;
