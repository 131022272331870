/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Packer } from 'docx';

import { addDocument } from 'redux/actions/documentActions';
import { setModal } from 'redux/actions/modalActions';
import { updateSociety } from 'redux/actions/societyActions';

import fileTypes from 'constants/fileTypes';
import documentTypes from 'constants/documentTypes';

import templates from './templates';
import AssistentsModal from './assistentsModal';

function TemplateDocumentModal({ user, society }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [documentName, setDocumentName] = useState('');
  const [date, setDate] = useState('');
  const [template, setTemplate] = useState('');
  const [assistants, setAssistants] = useState([]);

  const [step, setStep] = useState(1);

  const handleChangeTemplate = (e) => {
    const selectedTemplate = templates.find(
      (template) => template['_id'] === e.target.value
    );
    setTemplate(selectedTemplate);
  };

  async function saveDocument(event) {
    event.preventDefault();

    let file;
    const societyWithAssistants = { ...society, assistants };

    Packer.toBlob(template.generator(societyWithAssistants))
      .then((blob) => {
        blob.lastModifiedDate = new Date();
        blob.filename = `${documentName}.docx`;
        blob.name = `${documentName}.docx`;

        file = new File([blob], blob.name, {
          type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          lastModified: Date.now(),
        });
      })
      .then(async () => {
        const newDocument = {
          file,
          date,
          name: `${documentName}.docx`,
          author: user['_id'],
          society: society['_id'],
          isGenerated: true,

          size: file?.size,

          fileType: fileTypes.DOCX,
          category: documentTypes,
          description: t('DocumentLinkedManually'),
        };

        const uploadedDocument = await dispatch(addDocument(newDocument));

        dispatch(
          updateSociety(society['_id'], {
            docs: {
              add: uploadedDocument,
            },
          })
        );
        dispatch(setModal(null));
      });
  }

  return step === 1 ? (
    <>
      <Modal.Header>
        <h5 className="modal-title">Generar nuevo Documento</h5>
        <a
          className="close cursor-pointer"
          onClick={() => dispatch(setModal(null))}
        >
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
      <Modal.Body>
        <form className="form-validate is-alter" onSubmit={saveDocument}>
          <div className="form-group">
            <label className="form-label" htmlFor="full-name">
              Nombre documento
            </label>
            <div className="form-control-wrap">
              <div className="form-text-hint">
                <span className="overline-title">.DOCX</span>
              </div>
              <input
                type="text"
                className="form-control"
                value={documentName}
                onChange={(event) => setDocumentName(event.target.value)}
              />
            </div>
          </div>

          <div className="form-group">
            <label className="form-label" htmlFor="beneficiary-plan">
              Plantilla
            </label>
            <div className="form-control-wrap ">
              <div className="form-control-select">
                <select
                  className="form-control"
                  id="beneficiary-plan"
                  name="beneficiary-plan"
                  value={template['_id']}
                  onChange={handleChangeTemplate}
                >
                  <option value="" default>
                    Seleccionar
                  </option>
                  {templates?.length > 0 &&
                    templates.map((template) => (
                      <option
                        value={template['_id']}
                        key={template['_id']}
                        disabled={!template.generator}
                      >
                        {template.name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          </div>

          <div className="form-group">
            <label className="form-label" htmlFor="email-address">
              Fecha y Hora
            </label>
            <div className="form-control-wrap">
              <input
                type="date"
                className="form-control"
                value={date}
                onChange={(event) => setDate(event.target.value)}
                max="2100-01-01"
                min="1000-01-01"
              />
            </div>
          </div>

          <button
            type="submit"
            className="btn btn-lg btn-primary mt-3"
            disabled={
              !documentName ||
              !date ||
              !template ||
              (template.hasAssistants && !assistants.length)
            }
          >
            Guardar documento
          </button>

          {template?.hasAssistants && (
            <button
              type="button"
              className="btn btn-lg  btn-outline-primary btn-dim float-right mt-3"
              onClick={() => setStep(2)}
            >
              Añadir asistentes
            </button>
          )}
        </form>
      </Modal.Body>
      <Modal.Footer>
        <span className="sub-text">
          Los documentos serán accesibles por todos los socios
        </span>
      </Modal.Footer>
    </>
  ) : (
    <></>
  );
}

export default TemplateDocumentModal;
