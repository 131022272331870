/* eslint-disable no-underscore-dangle */
/* eslint-disable comma-dangle */
/* eslint-disable object-curly-newline */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

import store from 'redux/store';
import { getUsers } from 'redux/actions/userActions';
import { setModal } from 'redux/actions/modalActions';

import sizes from 'constants/sizes';
import userTypes from 'constants/userTypes';

import EditModal from 'components/UserMyData/EditModal';
import statusTypes from 'components/SocietyRepresentative/statusTypes';
import representativeTypes from 'components/SocietyRepresentative/representativeTypes';

function PartnerPersonal({ society, partner, incorporationDate }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const partners = useSelector(
    (state) => state.society?.actualSociety?.partners
  );
  const partnerCategories = useSelector((state) => state.partnerCategories);
  const isDemo = useSelector((state) => state.society?.role?.isDemo);

  const [address, setAddress] = useState();
  const [emails, setEmails] = useState([]);
  const [categoryName, setCategoryName] = useState();
  const [sindication, setSindication] = useState();

  async function getOtherUsers(users) {
    try {
      const otherUsersList = await dispatch(
        getUsers({
          usersList: users,
        })
      );
      setEmails(
        otherUsersList?.length ? otherUsersList.map((curr) => curr.email) : ['']
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  }

  useEffect(() => {
    if (partner?.sindication) {
      const sindication = partners.find((p) => p._id === partner.sindication);
      setSindication(sindication?.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partners]);

  useEffect(() => {
    const { line1, zip, city, country } = partner.address || {};
    setAddress(
      `${line1 || ''} ${zip || ''} ${city || ''} ${
        country ? `(${country})` : ''
      }`
    );
  }, [partner.address]);

  useEffect(() => {
    if (partner?.otherUsers?.length) {
      getOtherUsers(partner.otherUsers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partner.otherUsers]);

  useEffect(() => {
    if (partner?.category) {
      const category = partnerCategories.find(
        (curr) => curr['_id'] === partner.category
      );
      setCategoryName(category?.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnerCategories, partner?.category]);

  return (
    <div className="card-inner">
      <div
        className="nk-block-head"
        onClick={() => {
          if (!isDemo) {
            store.dispatch(
              setModal(
                <EditModal
                  user={partner}
                  societyId={society['_id']}
                  userType={userTypes.PARTNER}
                  size={sizes.XL}
                />
              )
            );
          }
        }}
      >
        <div className="nk-block-head-content">
          <div className="nk-block float-right">
            <button
              type="button"
              className="btn btn-icon btn-trigger btn-tooltip"
              onClick={() => {
                store.dispatch(
                  setModal(
                    <EditModal
                      user={partner}
                      societyId={society?.['_id']}
                      userType={userTypes.PARTNER}
                      size={sizes.XL}
                    />
                  )
                );
              }}
              disabled={isDemo}
            >
              <em className="icon ni ni-edit mr-1" />
            </button>
          </div>
        </div>
        <h4 className="title">{t('PersonalInformation')}</h4>
        <p>{t('BasicPersonalInformation')}</p>
        <div className="nk-block-head nk-block-head-line" />

        <div className="profile-ud wider d-flex flex-column flex-md-row">
          <span className="profile-ud-label w-200px">{t('FullName')}</span>
          <span className="profile-ud-value text-left">
            {partner?.name || '-'}
          </span>
        </div>

        <div className="profile-ud wider d-flex flex-column flex-md-row">
          <span className="profile-ud-label w-200px">{t('Nif')}</span>
          <span className="profile-ud-value text-left">
            {partner.cif || '-'}
          </span>
        </div>

        <div className="profile-ud wider d-flex flex-column flex-md-row">
          <span className="profile-ud-label w-200px">{t('Telephone')}</span>
          <span className="profile-ud-value text-left">
            {partner?.phone || '-'}
          </span>
        </div>

        <div className="profile-ud wider d-flex flex-column flex-md-row">
          <span className="profile-ud-label w-200px">{t('Address')}</span>
          <span className="profile-ud-value text-left">{address}</span>
        </div>

        <div className="profile-ud wider d-flex flex-column flex-md-row">
          <span className="profile-ud-label w-200px">
            {emails.length === 0 && t('Email')}
            {emails.length > 0 && t('Emails')}
          </span>
          <span className="profile-ud-value text-left">
            <div className="d-block">
              {partner.email}
              <span className="badge badge-outline-primary badge-pill text-primary ucap mx-1">
                {t('primary')}
              </span>
            </div>
            {emails?.map((email) => (
              <div className="d-block mt-1">
                <span className="profile-ud-value text-left">{email}</span>
              </div>
            ))}
          </span>
        </div>

        <div className="profile-ud wider d-flex flex-column flex-md-row">
          <span className="profile-ud-label w-200px">
            {t('IncoporationDate')}
          </span>
          <span className="profile-ud-value text-left">
            {incorporationDate}
          </span>
        </div>

        <div className="profile-ud wider d-flex flex-column flex-md-row">
          <span className="profile-ud-label w-200px">{t('Category')}</span>
          <span className="profile-ud-value text-left">
            {categoryName || '-'}
          </span>
        </div>

        <div className="profile-ud wider d-flex flex-column flex-md-row">
          <span className="profile-ud-label w-200px">{t('Nationality')}</span>
          <span className="profile-ud-value text-left">
            {partner?.nationality || '-'}
          </span>
        </div>

        <div className="profile-ud wider d-flex flex-column flex-md-row">
          <span className="profile-ud-label w-200px">{t('Sindication')}</span>
          <span className="profile-ud-value text-left">
            {sindication || '-'}
          </span>
        </div>

        {partner?.isNaturalPerson && (
          <div className="profile-ud wider d-flex flex-column flex-md-row">
            <span className="profile-ud-label w-200px">{t('CivilStatus')}</span>
            <span className="profile-ud-value text-left">
              {t(partner?.civilStatus) || '-'}
            </span>
          </div>
        )}

        {!partner?.isNaturalPerson && (
          <div>
            <div className="profile-ud wider d-flex flex-column flex-md-row">
              <span className="profile-ud-label w-200px">
                {t('CommercialRegister')}
              </span>
              <span className="profile-ud-value text-left">
                {t(partner?.legalInfo?.register) || '-'}
              </span>
            </div>
            <div className="profile-ud wider d-flex flex-column flex-md-row">
              <span className="profile-ud-label w-200px">
                {t('PartnerSection')}
              </span>
              <span className="profile-ud-value text-left">
                {t(partner?.legalInfo?.section) || '-'}
              </span>
            </div>
            <div className="profile-ud wider d-flex flex-column flex-md-row">
              <span className="profile-ud-label w-200px">{t('Sheet')}</span>
              <span className="profile-ud-value text-left">
                {t(partner?.legalInfo?.sheet) || '-'}
              </span>
            </div>

            <h6
              style={{ marginTop: '20px', marginBottom: '30px' }}
              className="title"
            >
              {t('RepresentativeInformation')}
            </h6>

            <div className="profile-ud wider d-flex flex-column flex-md-row">
              <span className="profile-ud-label w-200px">{t('Name')}</span>
              <span className="profile-ud-value text-left">
                {t(partner?.representative?.name) || '-'}
              </span>
            </div>
            <div className="profile-ud wider d-flex flex-column flex-md-row">
              <span className="profile-ud-label w-200px">{t('Nif')}</span>
              <span className="profile-ud-value text-left">
                {t(partner?.representative?.nif) || '-'}
              </span>
            </div>
            <div className="profile-ud wider d-flex flex-column flex-md-row">
              <span className="profile-ud-label w-200px">{t('Email')}</span>
              <span className="profile-ud-value text-left">
                {t(partner?.representative?.email) || '-'}
              </span>
            </div>
            <div className="profile-ud wider d-flex flex-column flex-md-row">
              <span className="profile-ud-label w-200px">
                {t('RepresentativeDate')}
              </span>
              <span className="profile-ud-value text-left">
                {partner?.representative?.initDate
                  ? format(
                      new Date(partner?.representative?.initDate),
                      'yyyy-MM-dd'
                    )
                  : '-'}
              </span>
            </div>
            <div className="profile-ud wider d-flex flex-column flex-md-row">
              <span className="profile-ud-label w-200px">
                {t('RevocationDate')}
              </span>
              <span className="profile-ud-value text-left">
                {partner?.representative?.endDate
                  ? format(
                      new Date(partner?.representative?.endDate),
                      'yyyy-MM-dd'
                    )
                  : '-'}
              </span>
            </div>
            <div className="profile-ud wider d-flex flex-column flex-md-row">
              <span className="profile-ud-label w-200px">
                {t('TypeOfAuthority')}
              </span>
              <span className="profile-ud-value text-left">
                {t(
                  representativeTypes?.name[partner?.representative?.authority]
                ) || '-'}
              </span>
            </div>
            <div className="profile-ud wider d-flex flex-column flex-md-row">
              <span className="profile-ud-label w-200px">{t('Status')}</span>
              <span
                className={
                  partner?.representative?.status &&
                  `profile-ud-value text-left badge badge-dot badge-${
                    statusTypes.badge[partner?.representative?.status]
                  }`
                }
              >
                {t(statusTypes?.name[partner?.representative?.status]) || '-'}
              </span>
            </div>
            <div className="profile-ud wider d-flex flex-column flex-md-row">
              <span className="profile-ud-label w-200px">
                {t('Nationality')}
              </span>
              <span className="profile-ud-value text-left">
                {t(partner?.representative?.nationality) || '-'}
              </span>
            </div>
            <div className="profile-ud wider d-flex flex-column flex-md-row">
              <span className="profile-ud-label w-200px">{t('Address')}</span>
              <span className="profile-ud-value text-left">
                {t(partner?.representative?.address) || '-'}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default PartnerPersonal;
