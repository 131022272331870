import Record from 'typescript';
import { Partner } from 'types';
import {
  attendeeDescription,
  holdingClassesNameAndRange,
  naturalPersonsList,
  participantLegalPerson,
  sharesInvestorsDetails,
  sharesOwnership,
  sharesPerfection,
} from './scriptureTemplates';

/**
 * COMPARECIENTES_VENDEDORES
 */
export const getAttendeesSellers = (attendees: Partner[]) => {
  if (!attendees?.length) return '';
  return attendees
    .map((attendee) => attendeeDescription(attendee, attendee?.isNaturalPerson))
    .join('');
};

/**
 * COMPARECIENTES_COMPRADORES
 */
export const getAttendeesBuyers = (attendees: Partner[]) => {
  if (!attendees?.length) return '';
  return attendees
    .map((attendee) => attendeeDescription(attendee, attendee?.isNaturalPerson))
    .join('');
};

/**
 * VENDEDORES_PERSONAS_FISICAS
 */
export const getParticipantsNaturalSellers = (participants: Partner[]) => {
  if (!participants?.length) return '';

  return naturalPersonsList(participants);
};

/**
 * COMPRADORES_PERSONAS_FISICAS
 */
export const getParticipantsNaturalBuyers = (participants: Partner[]) => {
  if (!participants?.length) return '';

  return naturalPersonsList(participants);
};

/**
 * VENDEDORES_PERSONAS_JURIDICAS
 */
export const getParticipantsLegalSellers = (participants: Partner[]) => {
  if (!participants?.length) return '';

  return participants
    .map((participant) => {
      if (participant?.isNaturalPerson) return null;

      return participantLegalPerson(participant, {
        notaryCity: '[CIUDAD DEL NOTARIO]',
        notaryName: '[NOMBRE DEL NOTARIO]',
        notaryNumber: '[NÚMERO DE PROTOCOLO]',
      });
    })
    .filter((participant) => participant)
    .join('');
};

/**
 * COMPRADORES_PERSONAS_JURIDICAS
 */
export const getParticipantsLegalBuyers = (participants: Partner[]) => {
  if (!participants?.length) return '';

  return participants
    .map((participant) => {
      if (participant?.isNaturalPerson) return null;

      return participantLegalPerson(participant, {
        notaryCity: '[CIUDAD DEL NOTARIO]',
        notaryName: '[NOMBRE DEL NOTARIO]',
        notaryNumber: '[NÚMERO DE PROTOCOLO]',
      });
    })
    .filter((participant) => participant)
    .join('');
};

/**
 * VENDEDORES_PERSONAS_FISICAS_Y_JURIDICAS
 */
export const getParticipantsNaturalAndLegalSellers = (
  participants: Partner[]
) => {
  if (!participants?.length) return '';

  const hasNaturalPersons = participants.some(
    (participant) => participant?.isNaturalPerson
  );
  const hasLegalPersons = participants.some(
    (participant) => !participant?.isNaturalPerson
  );

  const naturalPersonsText: any = hasNaturalPersons
    ? getParticipantsNaturalSellers(participants)
    : '';
  const legalPersonsText: any = hasLegalPersons
    ? getParticipantsLegalSellers(participants)
    : '';

  let finalText = '';

  if (hasNaturalPersons) finalText += naturalPersonsText;
  if (hasLegalPersons) finalText += legalPersonsText;

  return finalText;
};

/**
 * COMPRADORES_PERSONAS_FISICAS_Y_JURIDICAS
 */
export const getParticipantsNaturalAndLegalBuyers = (
  participants: Partner[]
) => {
  if (!participants?.length) return '';

  const hasNaturalPersons = participants.some(
    (participant) => participant?.isNaturalPerson
  );
  const hasLegalPersons = participants.some(
    (participant) => !participant?.isNaturalPerson
  );

  const naturalPersonsText: any = hasNaturalPersons
    ? getParticipantsNaturalBuyers(participants)
    : '';
  const legalPersonsText: any = hasLegalPersons
    ? getParticipantsLegalBuyers(participants)
    : '';

  let finalText = '';

  if (hasNaturalPersons) finalText += naturalPersonsText;
  if (hasLegalPersons) finalText += legalPersonsText;

  return finalText;
};

/**
 * VENDEDORES_PERSONAS_TODAS
 */
export const getFullNameSellers = (participants: Partner[]) => {
  if (!participants?.length) return '';

  return naturalPersonsList(participants);
};

/**
 * TITULARIDAD_VENDEDORES
 */
export const getOwnershipSellers = (operation: Record<string, any>) => {
  if (!operation) return '';

  const sellers = operation?.sellers || [];

  const participants = [...sellers];
  const society = operation?.society;

  return participants
    .map((participant) => sharesOwnership(participant, operation, society))
    .join('');
};

/**
 * PERFECCIONAMIENTO_VENDEDORES
 */
export const getPerfectionSellers = (operation: Record<string, any>) => {
  if (!operation) return '';

  const sellers = operation?.sellers || [];

  const participants = [...sellers];
  const society = operation?.society;

  return participants
    .map((participant) => sharesPerfection(participant, operation, society))
    .join('');
};

/**
 * INVERSORES_PARTICIPACION_AMPLIACION
 */
export const getInvestorsDetailsCapitalIncrease = (
  operation: Record<string, any>
) => {
  if (!operation) return '';

  const investors = operation?.investors || [];
  const society = operation?.society;

  return investors
    .map((participant: any) =>
      sharesInvestorsDetails(participant, operation, society)
    )
    .join('');
};

/**
 * LISTADO_CLASES_NOMBRE_Y_NUMERACION
 */
export const getHoldingClassesList = (
  holdingClasses: Record<string, any>[],
  society: Record<string, any>
) => {
  if (!holdingClasses?.length) return '';

  const societyShares: Record<string, any>[] = society?.shares?.filter(
    (share: Record<string, any>) => share?.isActive
  );

  const societyRanges: Record<string, any> = {};

  societyShares.forEach((share: Record<string, any>) => {
    const shareId = share?.shareClass?._id;

    if (!societyRanges[shareId]) {
      societyRanges[shareId] = [{ from: share?.from, to: share?.to }];
    } else {
      societyRanges[shareId].push({ from: share?.from, to: share?.to });
    }
  });

  const holdingClassesList = holdingClasses
    .filter((holdingClass) => societyRanges?.[holdingClass?._id]?.length)
    .map((holdingClass, index) =>
      holdingClassesNameAndRange(
        holdingClass,
        societyRanges?.[holdingClass?._id],
        index
      )
    );

  return holdingClassesList.join('') || '';
};
