import types from './actionTypes';

export function showNotification(data) {
  const { id } = data;
  const alreadyClosed = JSON.parse(sessionStorage.getItem(id));

  if (!alreadyClosed) {
    return {
      type: types.SHOW_NOTIFICATION,
      data,
    };
  }
  return {
    type: types.HIDE_NOTIFICATION,
  };
}

export function hideNotification(id) {
  sessionStorage.setItem(id, true);

  return {
    type: types.HIDE_NOTIFICATION,
  };
}
