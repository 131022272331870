/* eslint-disable no-nested-ternary */
import React from 'react';
import { Tooltip } from 'react-tooltip';
import { formatNumber } from 'utils/formats';
import { useTranslation } from 'react-i18next';

import { hasInternalView } from 'utils/showDetails';

import '../PartnerBook.scss';

function PlanSummaryBeneficiaryMainTable({ view, summary, names }) {
  const { t } = useTranslation();

  if (hasInternalView()) {
    console.log('cclog > PlanSummaryBeneficiaryMainTable');
    console.log('cclog > summary', summary);
  }

  return (
    summary && (
      <div className={view === 2 ? 'nk-block' : 'd-none'}>
        <div className="card card-bordered">
          <div className="table-responsive">
            <table
              className="table table-striped text-right border-right partner-book-table"
              id="beneficiarySummaryTable"
            >
              <tr className="text-center table-header">
                <th className="w-200px border-right">{t('Beneficiaries')}</th>
                <th className="no-break table-subheader border-right">
                  {t('AssignedUnits')}
                </th>
                <th className="no-break table-subheader border-right">
                  {t('Vested')}
                </th>
                <th className="no-break table-subheader border-right">
                  {t('CanceledUnits')}
                </th>
                <th className="no-break table-subheader border-right">
                  {t('Acquired')}
                </th>
              </tr>

              {Object.keys(summary)?.map((beneficiaryId, index) => (
                <>
                  <tr>
                    <td
                      id={beneficiaryId}
                      className="text-left w-200px text-ellipsis border-right"
                    >
                      {names[beneficiaryId]}
                      <Tooltip anchorId={beneficiaryId} place="bottom">
                        {names[beneficiaryId]}
                      </Tooltip>
                    </td>
                    {Object.values(summary[beneficiaryId]).map((value) => (
                      <td
                        className={
                          index === 0
                            ? 'text-right text-ellipsis border-right'
                            : 'text-right border-right'
                        }
                      >
                        {formatNumber(value)}
                      </td>
                    ))}
                  </tr>
                </>
              ))}
            </table>
          </div>
        </div>
      </div>
    )
  );
}

export default PlanSummaryBeneficiaryMainTable;
