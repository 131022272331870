import CardBoardAttendance from 'components/Cards/CardBoardAttendance';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { formatNumber, formatNumberDecimals } from 'utils/formats';

type BoardAssistansMetricsProps = {
  metrics: {
    assistants: number;
    represented: number;
    delegated: number;
    pending: number;
    absents: number;
  };
};

const BoardAssistantsMetrics: FC<BoardAssistansMetricsProps> = ({
  metrics,
}) => {
  const { t } = useTranslation();

  const { assistants, represented, delegated, pending, absents } = metrics;
  return (
    <div className="nk-block row mb-4">
      <div className="col-xl-3 col-md-6 mt-2">
        <CardBoardAttendance
          title={t('AssistantsCap')}
          value={assistants + represented}
          text={`${t('Present', {
            percent: formatNumberDecimals(assistants),
          })}`}
          secondaryText={`${t('RepresentedAssistants', {
            percent: formatNumberDecimals(represented),
          })}`}
        />
      </div>

      <div className="col-xl-3 col-md-6 mt-2">
        <CardBoardAttendance
          title={t('Delegations')}
          value={delegated}
          text={`${t('HasDelegatedAttendance', {
            percent: formatNumberDecimals(delegated),
          })}`}
        />
      </div>

      <div className="col-xl-3 col-md-6 mt-2">
        <CardBoardAttendance
          title={t('PendingAssistants')}
          value={pending}
          text={`${t('ArePending', {
            percent: formatNumberDecimals(pending),
          })}`}
        />
      </div>

      <div className="col-xl-3 col-md-6 mt-2">
        <CardBoardAttendance
          title={t('AbsentAssistants')}
          value={absents}
          text={`${t('DoNotAttend', {
            percent: formatNumberDecimals(absents),
          })}`}
        />
      </div>
    </div>
  );
};

export default BoardAssistantsMetrics;
