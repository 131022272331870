import AddPartner from 'components/Modals/AddPartner';
import Constitution from 'components/Modals/Constitution';
import CapitalIncrease from 'components/Modals/CapitalIncrease';
import CapitalDecrease from 'components/Modals/CapitalDecrease';
import SellParticipations from 'components/Modals/SellParticipations';
import Renumeration from 'components/Modals/Renumeration';
import Reclassification from 'components/Modals/Reclassification';
import Split from 'components/Modals/Split';
import DividendsDistribution from 'components/Modals/DividendsDistribution';
import rollbackOperation from 'utils/rollbackOperation';

function menuOptions(i18n) {
  return [
    {
      icon: 'ni-info',
      text: i18n.t('NewPartner'),
      url: AddPartner,
    },
    {
      icon: 'ni-book-read',
      text: i18n.t('Constitution'),
      url: Constitution,
    },
    {
      icon: 'ni-book-read',
      text: i18n.t('CapitalExpansion'),
      url: CapitalIncrease,
    },
    {
      icon: 'ni-book-read',
      text: i18n.t('CapitalReduction'),
      url: CapitalDecrease,
    },
    {
      icon: 'ni-book-read',
      text: i18n.t('SaleOfShares'),
      url: SellParticipations,
    },
    {
      icon: 'ni-book-read',
      text: i18n.t('Split'),
      url: Split,
    },
    {
      icon: 'ni-book-read',
      text: i18n.t('Renumeration'),
      url: Renumeration,
    },
    {
      icon: 'ni-book-read',
      text: i18n.t('Reclassification'),
      url: Reclassification,
    },
    {
      icon: 'ni-book-read',
      text: i18n.t('DividendsDistribution'),
      url: DividendsDistribution,
    },
    {
      icon: 'ni-undo',
      text: i18n.t('RollbackOperation'),
      action: rollbackOperation,
      borderTop: true,
    },
  ];
}
export default menuOptions;
