import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap';

import { EMAIL_PATTERN } from 'constants/defaultConstants';

import alertBodyTypes from 'components/Alert/alertBodyTypes';
import { setModal } from 'redux/actions/modalActions';
import { addAlert } from 'redux/actions/alertActions';
import { updateUser } from 'redux/actions/userActions';

import '../Modals/Modals.scss';

function AddAdminModal() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state);
  const [email, setEmail] = useState('');

  function savePartner(event) {
    event.preventDefault();

    dispatch(
      updateUser(user['_id'], {
        admin: { add: email },
        userToAccess: user['_id'],
      })
    );
    dispatch(setModal(null));
  }

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title">Añadir administrador</h5>
        <a
          className="close cursor-pointer"
          onClick={() => dispatch(setModal(null))}
        >
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
      <Modal.Body>
        <form className="form-validate is-alter" onSubmit={savePartner}>
          <div className="row mb-4">
            <div className="col">
              <div className="form-group">
                <label className="form-label" htmlFor="email-address">
                  Correo electrónico
                </label>
                <div className="form-control-wrap">
                  <div className="form-icon form-icon-left">
                    <em className="icon ni ni-mail" />
                  </div>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email del nuevo administrador"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    pattern={EMAIL_PATTERN}
                  />
                </div>
              </div>
            </div>
          </div>

          <button type="submit" className="btn btn-lg btn-primary">
            Añadir administrador para tu cuenta
          </button>
        </form>
      </Modal.Body>
      <Modal.Footer className="bg-light">
        <span className="sub-text">
          El administrador recibirá una notificación por email
        </span>
      </Modal.Footer>
    </>
  );
}

export default AddAdminModal;
