/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import menuTypes from 'constants/menuTypes';

import { setMenu } from 'redux/actions/menuActions';
import { setModal } from 'redux/actions/modalActions';
import { getBoards } from 'redux/actions/boardActions';

import { getActualSociety } from 'utils/filters';

import BoardCard from 'components/BoardCard';
import AddBoard from 'components/Modals/AddBoard';
import CustomLoading from 'components/CustomLoading';

import BoardBanner from './BoardBanner';

const BoardsList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { societyId } = useParams();

  const { actualSociety, user, isAdmin, boards } = useSelector((state) => ({
    actualSociety: state.society?.actualSociety,
    user: state.user,
    isAdmin: state.society?.role?.isAdmin,
    boards: state.boards.sort((a, b) => new Date(b.date) - new Date(a.date)),
  }));
  const isDemo = useSelector((state) => state.society?.role?.isDemo);

  const setCurrentMenu = () => {
    dispatch(
      setMenu({
        type: menuTypes.EXTENDED,
        societyId: actualSociety?.['_id'],
        societyName: actualSociety?.name,
      })
    );
  };

  const getCurrentValues = async () => {
    dispatch(getBoards(societyId));
  };

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId) {
      getActualSociety(user, societyId);
    }
  }, [actualSociety, user, societyId]);

  useEffect(() => {
    if (actualSociety) {
      setCurrentMenu();
      getCurrentValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety]);

  return actualSociety ? (
    <>
      <div className="nk-content-body">
        <div className="nk-content-wrap">
          <div className="nk-block-head nk-block-head-lg">
            <div className="nk-block-head-sub">
              <span>{actualSociety.name}</span>
            </div>
            <div className="nk-block-between-md g-4">
              <div className="nk-block-head-content">
                <h2 className="nk-block-title fw-normal">
                  {t('BoardPartners')}
                </h2>
              </div>
              <div className="nk-block-head-content">
                <ul className="nk-block-tools gx-3">
                  {isAdmin && !isDemo ? (
                    <li className="order-md-last">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => dispatch(setModal(<AddBoard />))}
                      >
                        <span>{t('NewBoardCouncil')}</span>
                      </button>
                    </li>
                  ) : (
                    <> </>
                  )}
                </ul>
              </div>
            </div>
          </div>
          {boards.length ? (
            boards.map((board) => (
              <BoardCard key={board?.['_id']} data={{ ...board }} />
            ))
          ) : (
            <></>
          )}

          <BoardBanner />
        </div>
      </div>
    </>
  ) : (
    <CustomLoading />
  );
};

export default BoardsList;
