/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import MenuDots from 'components/MenuDots';
import BadgeSocietyHolderStatus from 'components/Badges/BadgeSocietyHolderStatus';

import NumberFormat from 'react-number-format';
import { numberFormatDecimals } from 'constants/formats';
import { formatNumber } from 'utils/formats';
import UsersArray from '../UsersArray';
import Badge from '../Badge';

import menuOptions from '../SocietyRow/menuOptions';

import '../../assets/styles/Components.scss';
import '../../assets/styles/Ribbons.scss';
import './SocietyCard.scss';

function SocietyCard({
  data,
  userId,
  isAdmin,
  isHolder,
  isExternal = false,
  alternativeMenuOptions,
  holdingStatus,
  holdingStatusColor,
  holdingPartnerCreationDate,
}) {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user);

  const showSharesTotal = () => {
    if (isAdmin) return false;
    if (isHolder && userId) return true;

    return false;
  };

  const showPartnersTotal = () => {
    if (isAdmin) return true;
    if (isHolder) return true;

    return false;
  };

  const showPartnersAvatar = () => {
    if (isAdmin) return true;
    if (isHolder) return true;

    return false;
  };

  const showSocietyCreationBadge = () => {
    if (isAdmin && !isHolder) return true;
    if (isHolder) return false;

    return false;
  };

  const showSocietyHolderStatusBadge = () => {
    if (isAdmin && !isHolder) return false;
    if (isHolder) return true;

    return false;
  };

  return data ? (
    <div className="col-sm-6 col-xl-4">
      <div className="card card-bordered h-100">
        {isExternal && (
          <div className="ribbon ribbon-top-right">
            <span>{t('External')}</span>
          </div>
        )}

        <div className="card-inner">
          <div className="project">
            <div className="project-head">
              <Link
                to={`/socios/${data.society['_id']}`}
                className="project-title"
              >
                <div className="user-avatar md bg-primary">
                  {data.society.additional.logo ? (
                    <img
                      src={data.society.additional.logo}
                      alt={data.society.name}
                    />
                  ) : (
                    <span>{data.society.name.slice(0, 2).toUpperCase()}</span>
                  )}
                </div>
                <div className="project-info">
                  <h6 className="title">{data.society.name}</h6>
                  <span className="sub-text">
                    {isAdmin ? t('Administrator') : t('Shareholder')}
                  </span>
                </div>
              </Link>
              <div>
                <MenuDots
                  menuOptions={alternativeMenuOptions || menuOptions}
                  id={data.society['_id']}
                  direction="left"
                  params={{
                    societyName: data.society?.name,
                  }}
                />
              </div>
            </div>
            <div className="project-details">
              <p>{data.society.additional.description}</p>
            </div>

            {showSharesTotal() && (
              <div className="project-progress">
                <div className="project-progress-details">
                  <div className="project-progress-task">
                    <em className="icon ni ni-layers" />
                    <span>
                      {formatNumber(data?.societyShares?.actual) || '0'}&nbsp;
                      {t('shares')}
                    </span>
                  </div>
                  <div className="project-progress-percent">
                    <NumberFormat
                      value={data?.userPercent || 0}
                      displayType="text"
                      suffix="%"
                      {...numberFormatDecimals}
                    />
                  </div>
                </div>
                <div className="progress progress-pill progress-md bg-light">
                  <div
                    className="progress-bar"
                    data-progress={data?.userPercent}
                    style={{ width: `${data?.userPercent}%` }}
                  />
                </div>
              </div>
            )}

            {showPartnersTotal() ? (
              <div className="project-progress-percent mb-1">
                <em className="icon ni ni-users mr-1" />
                {t('HasSocietyPartnersLengthP', {
                  partners: data?.partnersCount || 0,
                })}
              </div>
            ) : (
              <div className="mb-1" style={{ height: '22px' }} />
            )}

            <div className="project-meta">
              {showPartnersAvatar() ? (
                <UsersArray users={data.partners} />
              ) : (
                <UsersArray users={data.userPartner} />
              )}

              {showSocietyCreationBadge() && data?.society.creationDate && (
                <Badge creationDate={new Date(data?.society.creationDate)} />
              )}

              {showSocietyHolderStatusBadge() && (
                <div>
                  <BadgeSocietyHolderStatus
                    status={holdingStatus}
                    color={holdingStatusColor}
                  />
                  &nbsp;
                  {holdingPartnerCreationDate && (
                    <Badge
                      creationDate={new Date(holdingPartnerCreationDate)}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}

export default SocietyCard;
