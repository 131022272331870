/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { setModal } from 'redux/actions/modalActions';
import { updateBoard } from 'redux/actions/boardActions';
import voteTypes from 'constants/voteTypes';
import { SELECCIONAR_SOCIO } from 'constants/defaultConstants';
import voteValues from 'constants/voteValues';
import instructionTypes from 'constants/instructionTypes';
import userTypes from 'constants/userTypes';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import Swal from 'sweetalert2';
import store from 'redux/store';

function SendVoteModal({ board, order, partnerId }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { actualSociety } = useSelector((state) => ({
    actualSociety: state.society?.actualSociety,
  }));

  const [currentPartner, setCurrentPartner] = useState();
  const [representedPartners, setRepresentedPartners] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState(SELECCIONAR_SOCIO);
  const [hasPendingVote, setHasPendingVote] = useState(false);
  const [voteValue, setVoteValue] = useState('');
  const [voteInstruction, setVoteInstruction] = useState('');
  const [representedPartnersOptions, setRepresentedPartnersOptions] = useState(
    []
  );
  const [selectedRepresented, setSelectedRepresented] = useState([]);
  const animatedComponents = makeAnimated();

  const sendSingleVote = async (currentParticipant) => {
    if (currentParticipant) {
      currentParticipant.assists = true;
      currentParticipant.isAbsent = false;
      currentParticipant?.votes.push({
        order: order?.['_id'],
        vote: voteValue,
        voterData: {
          voterId: currentPartner?.['_id'],
          voterType: userTypes.PARTNER,
          voterName: currentPartner?.name,
        },
      });
      // Update votes and assistance of partner selected
      await store.dispatch(
        updateBoard(
          board['_id'],
          { participantData: currentParticipant },
          false
        )
      );
      // Update current voter participant to assistant
      if (currentParticipant.member !== partnerId) {
        const currentPartnerParticipant = board?.participants?.find(
          (participant) => participant.member === partnerId
        );
        await store.dispatch(
          updateBoard(
            board['_id'],
            {
              participantData: {
                ...currentPartnerParticipant,
                assists: true,
                isAbsent: false,
              },
            },
            false
          )
        );
      }
      // List of participants represented by partnerId which will be updated as assistants
      const participantsToUpdate = board?.participants?.filter(
        (participant) =>
          participant.member !== currentParticipant.member &&
          participant?.representative &&
          participant?.representative === currentPartner?.['_id']
      );
      // eslint-disable-next-line no-restricted-syntax
      for (const participant of participantsToUpdate) {
        if (!participant?.assists) {
          // eslint-disable-next-line no-await-in-loop
          await store.dispatch(
            updateBoard(
              board['_id'],
              {
                participantData: {
                  ...participant,
                  assists: true,
                  isAbsent: false,
                },
              },
              false
            )
          );
        }
      }
    }
  };

  const saveVote = async (event) => {
    event.preventDefault();
    const participantMember = representedPartners.length
      ? selectedPartner
      : partnerId;

    if (representedPartners.length > 0) {
      for (const represented of selectedRepresented) {
        const currentParticipant = board?.participants?.find(
          (participant) => participant.member === represented.value
        );
        if (currentParticipant) {
          await sendSingleVote(currentParticipant);
        }
      }
    } else {
      const currentParticipant = board?.participants?.find(
        (participant) => participant.member === partnerId
      );
      if (currentParticipant) {
        await sendSingleVote(currentParticipant);
      }
    }

    dispatch(setModal(null));
    return Swal.fire({
      icon: 'success',
      title: `<h4 class="nk-modal-title">${t('VoteSent')}<br></h4>`,
      confirmButtonText: t('OK'),
      allowOutsideClick: false,
      showCancelButton: false,
      confirmButtonColor: '#6576FF',
    });
  };

  useEffect(() => {
    let instruction = '';
    if (selectedRepresented.length === 1) {
      const participant = board?.participants?.find(
        (p) => p.member === selectedRepresented[0].value
      );
      const { instructionType } = participant?.delegationVote || {};
      switch (instructionType) {
        case instructionTypes.ALL_YES.value:
          instruction = voteValues.YES.text;
          break;
        case instructionTypes.ALL_NO.value:
          instruction = voteValues.NO.text;
          break;
        case instructionTypes.ALL_ABS.value:
          instruction = voteValues.ABS.text;
          break;
        case instructionTypes.POINT_BY_POINT.value: {
          const orderInstruction =
            participant?.delegationVote?.instructions?.find(
              (i) => i.order === order['_id']
            );
          instruction = voteValues[orderInstruction?.vote]?.text || '';
          break;
        }
        default:
          break;
      }
    }
    setVoteInstruction(instruction);
  }, [selectedRepresented]);

  useEffect(() => {
    if (actualSociety) {
      setCurrentPartner(
        actualSociety?.partners.find((partner) => partner['_id'] === partnerId)
      );
      const representedPartnersIds = board.participants.reduce((acc, curr) => {
        if (!curr?.isRepresented && curr?.member === partnerId) {
          if (!curr?.votes.some((v) => v.order === order['_id']))
            setHasPendingVote(true);
        }
        if (curr?.isRepresented && curr?.representative === partnerId) {
          if (!curr?.votes.some((v) => v.order === order['_id']))
            acc.push(curr?.member);
        }
        return acc;
      }, []);
      setRepresentedPartners(
        actualSociety.partners.filter((partner) =>
          representedPartnersIds.includes(partner['_id'])
        )
      );
    }
  }, [actualSociety]);

  useEffect(() => {
    if (representedPartners && currentPartner) {
      const options = representedPartners.map((partner) => ({
        value: partner['_id'],
        label: `${partner?.cif} | ${partner?.name}`,
      }));

      if (hasPendingVote) {
        options.push({
          value: currentPartner['_id'],
          label: `${currentPartner?.cif} | ${currentPartner?.name}`,
        });
      }
      setRepresentedPartnersOptions(options);
    }
  }, [representedPartners, currentPartner]);

  return (
    <>
      <Modal.Header>
        <div className="sp-package text-center">
          <h4 className="title">{order.subject}</h4>
          <p className="text-soft">{t('WhatDoYouVote')}</p>
        </div>
        <a
          className="close cursor-pointer"
          onClick={() => dispatch(setModal(null))}
        >
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
      <Modal.Body>
        {board.participants.length && representedPartners.length ? (
          <div className="form-group">
            <label className="form-label" htmlFor="default-01">
              {t('Representando a:')}
            </label>
            <div className="form-control-wrap">
              <Select
                closeMenuOnSelect={false}
                className="react-select"
                options={representedPartnersOptions}
                components={animatedComponents}
                onChange={setSelectedRepresented}
                placeholder={SELECCIONAR_SOCIO}
                isMulti
              />
            </div>
            <span className="sub-text mt-1">
              {t('Seleccione el socio que representa')}
            </span>
          </div>
        ) : (
          <></>
        )}
        {voteInstruction ? (
          <span className="text-info">
            El socio representado ha dado la instrucción de que se vote{' '}
            {voteInstruction} en este asunto.
          </span>
        ) : (
          <></>
        )}
        {order.voteType === voteTypes.YES_NO_ABS.value ? (
          <div className="form-group mt-2">
            <ul className="sp-package-list">
              <li className="sp-package-item">
                <input
                  className="sp-package-choose"
                  type="radio"
                  id="yes-vote"
                  value={voteValues.YES.value}
                  checked={voteValue === voteValues.YES.value}
                  onChange={(event) => setVoteValue(event.target.value)}
                />
                <label className="sp-package-desc" htmlFor="yes-vote">
                  <span className="sp-package-info">
                    <span className="sp-package-title title">{t('Yes')}</span>
                    <span className="sp-package-detail">
                      {t('VoteInFavor')}
                    </span>
                  </span>
                  <span className="sp-package-price">
                    <span className="sp-package-amount yearly">
                      <span className="amount">{t('Yes')}</span>
                    </span>
                  </span>
                </label>
              </li>
              <li className="sp-package-item">
                <input
                  className="sp-package-choose"
                  type="radio"
                  id="no-vote"
                  value={voteValues.NO.value}
                  checked={voteValue === voteValues.NO.value}
                  onChange={(event) => setVoteValue(event.target.value)}
                />
                <label className="sp-package-desc" htmlFor="no-vote">
                  <span className="sp-package-info">
                    <span className="sp-package-title title">No</span>
                    <span className="sp-package-detail">
                      {t('VoteAgainst')}
                    </span>
                  </span>
                  <span className="sp-package-price">
                    <span className="sp-package-amount yearly">
                      <span className="amount">No</span>
                    </span>
                  </span>
                </label>
              </li>
              <li className="sp-package-item">
                <input
                  className="sp-package-choose"
                  type="radio"
                  id="abs-vote"
                  value={voteValues.ABS.value}
                  checked={voteValue === voteValues.ABS.value}
                  onChange={(event) => setVoteValue(event.target.value)}
                />
                <label className="sp-package-desc" htmlFor="abs-vote">
                  <span className="sp-package-info">
                    <span className="sp-package-title title">
                      {t('Abstention')}
                    </span>
                    <span className="sp-package-detail">{t('IAbstain')}</span>
                  </span>
                  <span className="sp-package-price">
                    <span className="sp-package-amount yearly">
                      <span className="amount">{t('Abstention')}</span>
                    </span>
                  </span>
                </label>
              </li>
            </ul>
          </div>
        ) : (
          <></>
        )}
        <div className="sp-package-action text-center">
          <button
            type="button"
            className="btn btn-primary"
            disabled={
              (representedPartners.length &&
                selectedRepresented.length === 0) ||
              voteValue === ''
            }
            onClick={saveVote}
          >
            {t('Vote')}
          </button>
          <button
            type="button"
            className="btn btn-dim btn-danger"
            onClick={() => dispatch(setModal(null))}
          >
            {t('Cancel')}
          </button>
        </div>
      </Modal.Body>
    </>
  );
}

export default SendVoteModal;
