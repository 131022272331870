/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable prefer-template */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable-next-line react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { setMenu } from 'redux/actions/menuActions';
import { getStockPlans } from 'redux/actions/planActions';
import { getSocietyEmployees } from 'redux/actions/employeeActions';

import menuTypes from 'constants/menuTypes';

import { getActualSociety, getEmployeesEligibles } from 'utils/filters';

import transformData from 'components/Tables/helpers';
import CustomDataTable from 'components/Tables/CustomDataTable';
import CustomLoading from 'components/CustomLoading';

import { setModal } from 'redux/actions/modalActions';
import AddEmployeesToPlan from 'components/Modals/AddEmployeesToPlan';
import sizes from 'constants/sizes';
import { getSocietyEmployeesPlans } from 'redux/actions/employeesPlanActions';
import { employeesIncentiveTypes } from 'utils/employeesFilterTypes';
import {
  getEmployeeFirstStartDate,
  getEmployeeIncentive,
  getEmployeeJobTitle,
  getEmployeeSalaryAmount,
} from 'utils/employees';
import actionTypes from 'constants/actionTypes';
import tableColumns from './tableColumns';

function EmployeesPlanDetailsList() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { societyId, employeesPlanId } = useParams();

  const user = useSelector((state) => state.user);
  const isAdmin = useSelector((state) => state.society?.role?.isAdmin);
  const plans = useSelector((state) => state.plans);
  const employees = useSelector((state) => state.employees);
  const employeesPlans = useSelector((state) => state.employeesPlans);
  const actualSociety = useSelector((state) => state.society?.actualSociety);

  const [currentEmployeesPlan, setCurrentEmployeesPlan] = useState({});
  const [eligibles, setEligibles] = useState([]);

  const [tableData, setTableData] = useState([]);
  const [currentTableColumns, setCurrentTableColumns] = useState(
    tableColumns(i18n)
  );

  const [activeEmployees, setActiveEmployees] = useState([]);
  const [beneficiariesCIFList, setBeneficiariesCIFList] = useState([]);

  const getEmployeesPlanMainInfo = (employeesPlan) => {
    const currentPlan = plans?.find(
      (plan) => plan['_id'] === employeesPlan?.planSelected
    );

    return {
      name: employeesPlan?.name,
      units: currentPlan?.sharesTotal,
      planName: currentPlan?.name,
      planType: currentPlan?.planType,
      planIsDraft: currentPlan?.isDraft,
    };
  };

  const handleFilterEmployees = () => {
    dispatch(setModal(<AddEmployeesToPlan size={sizes.LG} />));
  };

  const handleOpenModalEditEmployeesPlan = () => {
    dispatch(
      setModal(
        <AddEmployeesToPlan
          data={currentEmployeesPlan}
          action={actionTypes.EDIT}
          size={sizes.LG}
        />
      )
    );
  };

  const getAllBeneficiariesCIFs = (beneficiaries) => {
    const cifs = beneficiaries?.map((beneficiary) =>
      beneficiary.cif?.toUpperCase()
    );
    setBeneficiariesCIFList(cifs);
  };

  const getEmployeeIncentiveStatus = (employee) => {
    const hasIncentive = getEmployeeIncentive(employee, beneficiariesCIFList);

    if (hasIncentive) {
      // TODO: define the behavior if the employee has multiple incentives
    }

    return employeesIncentiveTypes.NOTHING;
  };

  useEffect(() => {
    if (employeesPlanId && employeesPlans?.length) {
      const currentEmployeesPlan = employeesPlans?.find(
        (employeesPlan) => employeesPlan['_id'] === employeesPlanId
      );

      setCurrentEmployeesPlan(currentEmployeesPlan);
    }
  }, [employeesPlans, employeesPlanId]);

  useEffect(() => {
    dispatch(getStockPlans(societyId));
    dispatch(getSocietyEmployees(societyId));
    dispatch(getSocietyEmployeesPlans(societyId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [societyId]);

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId) {
      getActualSociety(user, societyId);
    }
  }, [actualSociety, user, societyId]);

  useEffect(() => {
    if (actualSociety) {
      dispatch(
        setMenu({
          type: menuTypes.EXTENDED,
          societyId: actualSociety?.['_id'],
          societyName: actualSociety?.name,
        })
      );

      getAllBeneficiariesCIFs(actualSociety?.beneficiaries);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety]);

  useEffect(() => {
    if (eligibles.length && currentEmployeesPlan && beneficiariesCIFList) {
      const newData = eligibles?.map((eligible) => {
        const { name, planType } =
          getEmployeesPlanMainInfo(currentEmployeesPlan);

        return {
          id: eligible['_id'],
          employee: eligible,
          employeesPlan: { name, planType },
          name: eligible?.fullName,
          jobTitle: getEmployeeJobTitle(eligible),
          cif: eligible?.cif,
          salary: getEmployeeSalaryAmount(eligible),
          currency: 'EUR',
          hasIncentive: {
            text: getEmployeeIncentive(eligible, beneficiariesCIFList)
              ? t('Yes')
              : t('Not'),
            color: getEmployeeIncentive(eligible, beneficiariesCIFList)
              ? 'success'
              : 'danger',
          },
          incentiveStatus: getEmployeeIncentiveStatus(eligible),
          isEmployed: {
            text: eligible?.terminationDate ? t('Not') : t('Empleado'),
            color: eligible?.terminationDate ? 'danger' : 'success',
          },
          startDate: getEmployeeFirstStartDate(eligible),
        };
      });

      if (JSON.parse(localStorage.getItem('showDetails'))) {
        console.log('newData', newData);
      }

      if (newData?.length > 0) {
        setTableData(
          transformData({
            data: newData,
            columns: tableColumns(i18n),
          })
        );
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [eligibles, beneficiariesCIFList]);

  useEffect(() => {
    setCurrentTableColumns(tableColumns(i18n));
  }, [i18n.language]);

  useEffect(() => {
    if (employees?.length) {
      setActiveEmployees(
        employees.filter((employee) => !employee.terminationDate)
      );
    } else {
      setActiveEmployees([]);
    }
  }, [employees]);

  useEffect(() => {
    if (employees?.length && currentEmployeesPlan && beneficiariesCIFList) {
      const currentPlanEligibles = getEmployeesEligibles(
        employees,
        currentEmployeesPlan,
        beneficiariesCIFList
      );

      setEligibles(currentPlanEligibles);
    }
  }, [beneficiariesCIFList, currentEmployeesPlan, employees]);

  return actualSociety ? (
    <>
      <div className="nk-content-body">
        <div className="nk-content-wrap">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">{actualSociety.name}</h3>
            <p />
          </div>
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title"> </h3>
          </div>
          <div className="nk-block-head">
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <h3 className="nk-block-title page-title">
                  {t('Borradores')}
                  {` / `}
                  <strong className="text-primary small">
                    {currentEmployeesPlan?.name}
                  </strong>
                </h3>
                <div className="nk-block-des text-soft">
                  <p>
                    {`${currentEmployeesPlan?.name} ${t('HaveEmployees', {
                      count: eligibles?.length,
                    })}`}
                  </p>
                </div>
              </div>

              <div className="nk-block-head-content">
                <div className="toggle-wrap nk-block-tools-toggle">
                  <a
                    className="btn btn-icon btn-trigger toggle-expand mr-n1"
                    data-target="pageMenu"
                    aria-label="link"
                  >
                    <em className="icon ni ni-menu-alt-r" />
                  </a>
                  <div
                    className="toggle-expand-content"
                    data-content="pageMenu"
                  >
                    <ul className="nk-block-tools g-3">
                      {isAdmin && (
                        <>
                          <li>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={handleOpenModalEditEmployeesPlan}
                            >
                              <em className="icon ni ni-edit" />
                              <span>{t('EditFilter')}</span>
                            </button>
                          </li>
                          <li>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={handleFilterEmployees}
                              disabled
                            >
                              <em className="icon ni ni-user-check" />
                              <span>{t('GiveIncentive')}</span>
                            </button>
                          </li>
                        </>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <CustomDataTable
            data={tableData}
            columns={currentTableColumns}
            searchBy={t('name')}
            pagination
            className="nk-tb-list"
            // expandableRows
            actions
            showDense
            fileName={`${t('EmployeesList')} ${actualSociety?.name}`}
          />
        </div>
      </div>
    </>
  ) : (
    <CustomLoading />
  );
}

export default EmployeesPlanDetailsList;
