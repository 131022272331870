import ActaJuntaGeneral from 'components/Templates/ActaJuntaGeneral';
import ActaJuntaAprobacionCuentas from 'components/Templates/ActaJuntaAprobacionCuentas';
import ConvocatoriaJuntaGeneral from 'components/Templates/ConvocatoriaJuntaGeneral';

export default [
  {
    _id: '001',
    name: 'Convocatoria Junta Accionistas',
    date: '2022-01-01',
    size: 4215,
    members: 3,
    generator: ConvocatoriaJuntaGeneral,
  },
  {
    _id: '002',
    name: 'Acta de la Junta General de Accionistas',
    date: '2022-02-05',
    size: 990,
    members: 5,
    generator: ActaJuntaGeneral,
    hasAssistants: true,
  },
  {
    _id: '003',
    name: 'Acta Junta Accionistas Aprobación Cuentas',
    date: '2022-03-12',
    size: 1256,
    members: 4,
    generator: ActaJuntaAprobacionCuentas,
    hasAssistants: true,
  },
];
