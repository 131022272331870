import store from 'redux/store';
import i18n from 'i18n/config';
import { updateSociety } from 'redux/actions/societyActions';
import { editUser } from 'utils/modals';
import { updateUser } from 'redux/actions/userActions';

export default [
  {
    icon: 'ni-edit',
    text: i18n.t('Edit'),
    action: editUser,
  },
  {
    icon: 'ni-trash',
    text: i18n.t('Delete'),
    action: ({ societyId }, adminId) => {
      store.dispatch(updateSociety(societyId, { admin: { remove: adminId } }));
      store.dispatch(
        updateUser(null, {
          admin: { remove: adminId },
          society: societyId,
        })
      );
    },
  },
];
