/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { useMixpanel } from 'react-mixpanel-browser';
import i18n from 'i18n/config';

import {
  validateIsAbsent,
  validateOpenVoting,
} from 'redux/actions/validationActions';
import { setMenu } from 'redux/actions/menuActions';
import { setModal } from 'redux/actions/modalActions';
import { getBoards } from 'redux/actions/boardActions';
import { getDocumentsByReference } from 'redux/actions/documentActions';

import getUserMetadata from 'utils/userMetadata';
import { getActualSociety } from 'utils/filters';
import { isBoardClosed, isOpenVoting, parseEventsToBoard } from 'utils/boards';
import trackEvent from 'utils/trackEvent';
import fetchMixpanelEvents from 'utils/mixpanel';

import menuTypes from 'constants/menuTypes';
import eventTypes from 'constants/eventTypes';
import boardStatus from 'constants/boardStatus';

import BoardVotes from 'components/BoardVotes';
import BoardDocuments from 'components/BoardDocuments';
import DelegateVoteModal from 'components/Modals/DelegateVote';
import CustomLoading from 'components/CustomLoading';

const BoardPartner = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();
  const { societyId, boardId, partnerId } = useParams();

  const actualSociety = useSelector((state) => state.society?.actualSociety);
  const user = useSelector((state) => state.user);
  const isAdmin = useSelector((state) => state.society?.role?.isAdmin);
  const boards = useSelector((state) => state.boards);

  const [currentBoard, setCurrentBoard] = useState();
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState([]);
  const [isDelegated, setIsDelegated] = useState(false);
  const [isAbsent, setIsAbsent] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [representativeName, setRepresentativeName] = useState('');
  const [isParticipant, setIsParticipant] = useState();
  const [isRepresentative, setIsRepresentative] = useState();
  const [hasDelegated, setHasDelegated] = useState();
  const [haveVote, setHaveVote] = useState(false);
  const [userMetadata, setUserMetadata] = useState();
  const [userEvents, setUserEvents] = useState([]);
  const [currentPartner, setCurrentPartner] = useState(null);

  const showDetails = JSON.parse(localStorage.getItem('showDetails'));

  const setCurrentMenu = () => {
    dispatch(
      setMenu({
        type: menuTypes.EXTENDED,
        societyId: actualSociety?.['_id'],
        societyName: actualSociety?.name,
      })
    );
  };

  const getCurrentValues = async () => {
    dispatch(getBoards(societyId));
  };

  const getCurrentPartner = () => {
    const partnerFound = actualSociety?.partners?.find(
      (partner) => partner?.['_id'] === partnerId
    );
    setCurrentPartner(partnerFound);
  };

  const saveEventOnMixpanel = async () => {
    const currentEvent = eventTypes.ASSIST_BOARD;
    await trackEvent(mixpanel, currentEvent, {
      userId: user?.['_id'],
      userName: user?.name,
      userEmail: user?.email,
      societyId: actualSociety?.['_id'],
      societyName: actualSociety?.name,
      operation: currentEvent,
      boardId: currentBoard?.['_id'],
      userIpAddress: userMetadata?.ipAddress,
      userBrowser: userMetadata?.browser,
      userBrowserVersion: userMetadata?.browserVersion,
      userDevice: userMetadata?.device,
    });
  };

  const getEventsFromMixpanel = async () => {
    // fetch all the events from mixpanel with the property 'operation' = event
    fetchMixpanelEvents('boardId', currentBoard?._id).then((events) =>
      setUserEvents(parseEventsToBoard(events))
    );
  };

  useEffect(() => {
    if (currentBoard) {
      const resultParticipant = currentBoard.participants.find(
        (participant) => participant.member === partnerId
      );
      const representative = currentBoard.participants.find(
        (participant) =>
          participant.member === resultParticipant?.representative
      );
      setHaveVote(resultParticipant?.votes?.length > 0);
      setIsDelegated(resultParticipant?.isRepresented);
      setRepresentativeName(representative?.name);
      setIsAbsent(resultParticipant?.isAbsent);
      const openVoting = isOpenVoting(currentBoard);
      if (!openVoting) {
        dispatch(validateOpenVoting(openVoting, currentBoard));
      }
    }
  }, [partnerId, currentBoard]);

  useEffect(() => {
    dispatch(validateIsAbsent(isAbsent && !hasDelegated));
  }, [isAbsent, i18n.language]);

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId) {
      getActualSociety(user, societyId);
    }
  }, [actualSociety, user, societyId]);

  useEffect(() => {
    if (actualSociety) {
      // Get current partner's data
      getCurrentPartner();

      dispatch(getDocumentsByReference({ societyId: actualSociety['_id'] }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety]);

  useEffect(() => {
    if (actualSociety) {
      setCurrentMenu();
      getCurrentValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety]);

  useEffect(() => {
    if (boards.length) {
      const actualBoard = boards.find((board) => board['_id'] === boardId);
      setIsActive(actualBoard?.isActive);
      setCurrentBoard(actualBoard);
    }
  }, [boards, boardId]);

  useEffect(() => {
    if (currentBoard && partnerId) {
      // Set partner representation values
      setIsParticipant(
        currentBoard?.participants.some((p) => p?.member === partnerId)
      );
      setIsRepresentative(
        currentBoard?.participants.some((p) => p?.representative === partnerId)
      );
      setHasDelegated(
        currentBoard?.participants.some(
          (p) => p?.member === partnerId && p?.representative
        )
      );

      setPages([
        <BoardVotes key="board-votes" board={currentBoard} />,
        <BoardDocuments
          key="board-documents"
          board={currentBoard}
          partnerId={partnerId}
        />,
      ]);
    }
  }, [currentBoard, partnerId]);

  useEffect(() => {
    if (currentBoard && user && actualSociety && userMetadata) {
      saveEventOnMixpanel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety, user, currentBoard, userMetadata]);

  useEffect(() => {
    getUserMetadata().then((data) => {
      setUserMetadata(data);
    });
  }, []);

  useEffect(() => {
    const route = window.location.hash.replace('#', '');
    const socket = new WebSocket(
      `${process.env.REACT_APP_API_WSS}?origin=${encodeURIComponent(route)}`
    );

    socket.onopen = () => {
      socket.send(JSON.stringify({ type: 'trackRoute', route }));
    };

    return () => {
      socket.close();
    };
  }, []);

  // eslint-disable-next-line no-nested-ternary
  return isParticipant && currentBoard ? (
    <div className="nk-content-body">
      <div className="nk-content-wrap">
        <div className="nk-block-head nk-block-head-lg">
          <div className="nk-block-head-sub">
            <Link
              to={`/juntas/${societyId}`}
              className={`back-to ${!isAdmin && 'is-disable'}`}
            >
              <em className="icon ni ni-arrow-left" />
              <span>{t('BoardsList')}</span>
            </Link>
          </div>
          <div className="nk-block-between-md g-4">
            <div className="nk-block-head-content">
              <h2 className="nk-block-title fw-normal">
                {currentBoard.name}{' '}
                <span
                  className={`badge badge-pill ${
                    boardStatus?.[currentBoard?.status]?.style
                  } mr-1`}
                >
                  {boardStatus?.[currentBoard?.status]?.text || ''}
                </span>
                {isDelegated && (
                  <span className="badge badge-pill badge-warning mr-1">
                    {`${t('DelegateVoteIn')} ${representativeName}`}
                  </span>
                )}
                {isAbsent && (
                  <span className="badge badge-pill badge-danger mr-1">
                    {t('Absent')}
                  </span>
                )}
                {!isActive && (
                  <span className="badge badge-pill badge-info mr-1">
                    {t('ClosedVote')}
                  </span>
                )}
              </h2>
              {showDetails && (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => getEventsFromMixpanel()}
                >
                  Mixpanel
                </button>
              )}
              <Link
                to={`/detalle-socio/${actualSociety?.['_id']}/${partnerId}`}
              >
                {currentPartner?.name || ''}&nbsp;
              </Link>

              <span className="text-soft">{currentPartner?.email || ''}</span>
            </div>

            {isParticipant &&
            !hasDelegated &&
            !isRepresentative &&
            !haveVote ? (
              <div className="nk-block-head-content">
                <ul className="nk-block-tools justify-content-md-end g-4 flex-wrap">
                  <button
                    type="button"
                    className="btn btn-primary"
                    disabled={isBoardClosed(currentBoard)}
                    onClick={() =>
                      dispatch(
                        setModal(
                          <DelegateVoteModal
                            board={currentBoard}
                            partnerId={partnerId}
                          />
                        )
                      )
                    }
                  >
                    <em className="icon ni ni-user-add" />
                    <span>{t('DelegateVote')}</span>
                  </button>
                </ul>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="nk-block">
          <ul className="nk-nav nav nav-tabs">
            <li className="nav-item">
              <p
                className={`nav-link ${page === 0 ? 'active' : ''}`}
                onClick={() => setPage(0)}
              >
                <span>{t('AttendanceAndVotes')}</span>
              </p>
            </li>
            <li className="nav-item">
              <p
                className={`nav-link ${page === 1 ? 'active' : ''}`}
                onClick={() => setPage(1)}
              >
                <span>{t('Documents')}</span>
              </p>
            </li>
          </ul>
          {pages[page]}
        </div>
      </div>
    </div>
  ) : (
    <CustomLoading />
  );
};

export default BoardPartner;
