import {
  AlignmentType,
  convertInchesToTwip,
  Document,
  Footer,
  HeadingLevel,
  ImageRun,
  Paragraph,
  TabStopPosition,
  LevelFormat,
  TextRun,
  TabStopType,
  Header,
} from 'docx';
import BlankLogo from './BlankLogo';

const breakLine = new Paragraph({
  text: '',
});

function ConvocatoriaJuntaGeneral(society = {}) {
  const { name } = society;
  const { logo } = society?.additional;
  const { line1, zip, city } = society?.legalAddress;
  const imageBase64Data = logo
    ? logo?.replace(/^data:image\/png;base64,/, '')
    : BlankLogo?.replace(/^data:image\/png;base64,/, '');

  const doc = new Document({
    creator: name,
    title: 'Convocatoria Junta General de Socios de la Sociedad',
    description: 'Convocatoria Junta General de Socios de la Sociedad',

    numbering: {
      config: [
        {
          reference: 'ref1',
          levels: [
            {
              level: 0,
              format: LevelFormat.DECIMAL,
              text: '%1.',
              start: 1,
            },
          ],
        },
      ],
    },
    styles: {
      default: {
        heading1: {
          run: {
            font: 'Arial',
            size: 28,
            bold: true,
            color: '000000',
          },
          paragraph: {
            alignment: AlignmentType.CENTER,
            spacing: { line: 340 },
          },
        },
        heading2: {
          run: {
            font: 'Arial',
            size: 28,
            bold: true,
            color: '000000',
          },
          paragraph: {
            alignment: AlignmentType.CENTER,
            spacing: { line: 340 },
          },
        },
        heading3: {
          run: {
            font: 'Arial',
            size: 26,
            bold: true,
          },
          paragraph: {
            spacing: { line: 276 },
          },
        },
        heading4: {
          run: {
            font: 'Arial',
            size: 26,
            bold: true,
          },
          paragraph: {
            alignment: AlignmentType.JUSTIFIED,
          },
        },
      },
      paragraphStyles: [
        {
          id: 'normalPara',
          name: 'Normal Para',
          basedOn: 'Normal',
          next: 'Normal',
          quickFormat: true,
          run: {
            font: 'Arial',
            size: 22,
          },
          paragraph: {
            alignment: AlignmentType.JUSTIFIED,
            spacing: { line: 276, before: 20 * 72 * 0.1, after: 40 * 72 * 0.1 },
            rightTabStop: TabStopPosition.MAX,
            leftTabStop: 453.543307087,
          },
        },
        {
          id: 'highLight',
          name: 'HighLight',
          basedOn: 'Normal',
          next: 'Normal',
          quickFormat: true,
          run: {
            font: 'Arial',
            size: 22,
            bold: false,
            fill: 'FFD966',
            color: 'FFD966',
          },
          paragraph: {
            alignment: AlignmentType.JUSTIFIED,
            spacing: { line: 276, before: 20 * 72 * 0.1, after: 40 * 72 * 0.1 },
            rightTabStop: TabStopPosition.MAX,
            leftTabStop: 453.543307087,
          },
        },
        {
          id: 'normalPara2',
          name: 'Normal Para2',
          basedOn: 'Normal',
          next: 'Normal',
          quickFormat: true,
          run: {
            font: 'Arial',
            size: 22,
          },
          paragraph: {
            alignment: AlignmentType.JUSTIFIED,
            spacing: {
              line: 276,
              before: 20 * 72 * 0.1,
              after: 20 * 72 * 0.05,
            },
          },
        },
        {
          id: 'aside',
          name: 'Aside',
          basedOn: 'Normal',
          next: 'Normal',
          run: {
            color: '999999',
            italics: true,
          },
          paragraph: {
            spacing: { line: 276 },
            indent: { left: convertInchesToTwip(0.5) },
          },
        },
        {
          id: 'wellSpaced',
          name: 'Well Spaced',
          basedOn: 'Normal',
          paragraph: {
            spacing: {
              line: 276,
              before: 20 * 72 * 0.1,
              after: 20 * 72 * 0.05,
            },
          },
        },
        {
          id: 'numberedPara',
          name: 'Numbered Para',
          basedOn: 'Normal',
          next: 'Normal',
          quickFormat: true,
          run: {
            font: 'Arial',
            size: 20,
          },
          paragraph: {
            spacing: {
              line: 276,
              before: 20 * 72 * 0.1,
              after: 20 * 72 * 0.05,
            },
            rightTabStop: TabStopPosition.MAX,
            leftTabStop: 453.543307087,
            numbering: {
              reference: 'ref1',
              instance: 0,
              level: 0,
            },
          },
        },
      ],
    },
    sections: [
      {
        properties: {
          page: {
            margin: {
              top: 400,
              right: 1400,
              bottom: 700,
              left: 1400,
            },
          },
        },
        headers: {
          default: new Header({
            children: [
              new Paragraph({
                children: [
                  new ImageRun({
                    data: Buffer.from(imageBase64Data, 'base64'),
                    transformation: {
                      width: 100,
                      height: 100,
                    },
                  }),
                ],
                alignment: AlignmentType.CENTER,
              }),
            ],
          }),
        },
        footers: {
          default: new Footer({
            children: [
              new Paragraph({
                text: '1',
                style: 'normalPara',
                alignment: AlignmentType.RIGHT,
              }),
            ],
          }),
        },
        children: [
          breakLine,

          new Paragraph({
            text: 'CONVOCATORIA JUNTA GENERAL DE SOCIOS DE LA SOCIEDAD',
            heading: HeadingLevel.HEADING_1,
            alignment: AlignmentType.CENTER,
          }),

          new Paragraph({
            text: name || '[DENOMINACIÓN SOCIAL]',
            heading: HeadingLevel.HEADING_1,
            alignment: AlignmentType.CENTER,
          }),

          breakLine,

          new Paragraph({
            children: [
              new TextRun(
                `Por acuerdo del Órgano de Administración de la Sociedad ${
                  name || '[DENOMINACIÓN SOCIAL]'
                } (en adelante, `
              ),
              new TextRun({ text: '“la Sociedad”', bold: true }),
              new TextRun({
                text:
                  '), se convoca a los socios de la misma a la Junta General de Socios que se celebrará el próximo ',
              }),
              new TextRun({
                text: '[día]',
                highlight: 'yellow',
              }),
              new TextRun({
                text: ' de ',
              }),
              new TextRun({
                text: '[mes]',
                highlight: 'yellow',
              }),
              new TextRun({
                text: ' de ',
              }),
              new TextRun({
                text: '[año]',
                highlight: 'yellow',
              }),
              new TextRun({
                text: ' a las ',
              }),
              new TextRun({
                text: '[hora:minutos]',
                highlight: 'yellow',
              }),
              new TextRun({
                text: ` horas y que tendrá lugar en el domicilio social de la Sociedad, sito en ${
                  line1 || '[dirección]'
                }, ${zip || '[código postal]'} - ${
                  city || '[ciudad]'
                }. La asistencia a la Junta General de Socios podrá realizarse acudiendo presencialmente al domicilio social de la Sociedad anteriormente referenciado `,
              }),
              new TextRun({
                text:
                  '[OPCIONAL]: o bien por medios telemáticos a través de sistemas de videoconferencia, de conformidad con lo previsto en los Estatutos de la Sociedad. ',
                highlight: 'yellow',
              }),
              new TextRun({
                text:
                  'En la Junta General de Socios será objeto de debate y votación el siguiente',
              }),
            ],
            style: 'normalPara',
          }),

          breakLine,

          new Paragraph({
            text: 'ORDEN DEL DÍA',
            heading: HeadingLevel.HEADING_2,
            alignment: AlignmentType.CENTER,
          }),

          new Paragraph({
            children: [
              new TextRun({
                text: '[Primer tema a tratar en la Junta].',
                highlight: 'yellow',
              }),
            ],
            style: 'numberedPara',
          }),

          new Paragraph({
            children: [
              new TextRun({
                text: '[Segundo tema a tratar en la Junta].',
                highlight: 'yellow',
              }),
            ],
            style: 'numberedPara',
          }),

          new Paragraph({
            children: [
              new TextRun({
                text: '[Tercer tema a tratar en la Junta].',
                highlight: 'yellow',
              }),
            ],
            style: 'numberedPara',
          }),

          new Paragraph({
            text: 'Ruegos y preguntas.',
            style: 'numberedPara',
          }),

          new Paragraph({
            text:
              'Confección, lectura y aprobación, si procede, del Acta de la reunión.',
            style: 'numberedPara',
          }),

          breakLine,

          new Paragraph({
            text:
              'Todos los documentos y cuentas que han de ser sometidos a la aprobación de la Junta General de Socios se encuentran a disposición de los Señores Socios en el domicilio social, donde podrán ser examinados y obtener copia de los mismos de forma gratuita. Asimismo, se recuerda a los socios que también pueden solicitar el envío de la documentación por correo electrónico o por correo certificado de manera inmediata y gratuita.',
            style: 'normalPara',
          }),

          new Paragraph({
            children: [
              new TextRun({
                text:
                  '[OPCIONAL]: Finalmente, se recuerda a los Socios que, en el correo electrónico por el cual se hace llegar esta convocatoria figuran los medios y canales a través de los cuales los socios podrán conectarse y hacer el correcto seguimiento de la celebración de la Junta General de Socios, en el día y hora señalados.',
                highlight: 'yellow',
              }),
            ],
            style: 'normalPara',
          }),

          breakLine,

          new Paragraph({
            children: [
              new TextRun(`En ${city || '[Ciudad]'}, a `),
              new TextRun({ text: '[día]', highlight: 'yellow' }),
              new TextRun({ text: ' de ' }),
              new TextRun({ text: '[mes]', highlight: 'yellow' }),
              new TextRun({ text: ' de ' }),
              new TextRun({ text: '[año]', highlight: 'yellow' }),
            ],
            style: 'normalPara',
          }),

          breakLine,
          breakLine,

          new Paragraph({
            children: [
              new TextRun({
                text: '[Nombre y Apellidos]',
                highlight: 'yellow',
              }),
              new TextRun({
                text: '\t[Nombre y Apellidos]',
                highlight: 'yellow',
              }),
            ],
            tabStops: [
              {
                type: TabStopType.RIGHT,
                position: TabStopPosition.MAX,
              },
            ],
            style: 'normalPara2',
          }),
          new Paragraph({
            children: [new TextRun('Presidente'), new TextRun('\tSecretario')],
            tabStops: [
              {
                type: TabStopType.RIGHT,
                position: TabStopPosition.MAX,
              },
            ],
            style: 'normalPara',
          }),
        ],
      },
    ],
  });

  return doc;
}

export default ConvocatoriaJuntaGeneral;
