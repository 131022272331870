/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { votingResultsChartOptions } from 'utils/charts';

function OrderRow({
  order,
  votingResult,
  chartData,
  percents,
  setSelectedOrder,
}) {
  return (
    <tr className="nk-tb-item">
      <td className="nk-tb-col w-40 py-1">
        <div
          className="d-flex flex-row justify-content-start align-items-center"
          onClick={() => setSelectedOrder(order['_id'])}
        >
          <div
            className={`nk-activity-media user-avatar sq bg-${votingResult.style} mr-2 cursor-pointer`}
          >
            <em className={votingResult.icon} />
          </div>
          <span
            className="tb-lead cursor-pointer"
            onClick={() => setSelectedOrder(order['_id'])}
          >
            {order.subject}
          </span>
        </div>
      </td>
      <td className="nk-tb-col tb-col-xl text-right w-10 py-1">
        {percents.total ? (
          <span className="tb-sub">{percents?.yes?.toFixed(2)}%</span>
        ) : (
          <>-</>
        )}
      </td>
      <td className="nk-tb-col tb-col-xl text-right w-10 py-1">
        {percents.total ? (
          <span className="tb-sub">{percents?.no?.toFixed(2)}%</span>
        ) : (
          <>-</>
        )}
      </td>
      <td className="nk-tb-col tb-col-xl text-right w-10 py-1">
        {percents.total ? (
          <span className="tb-sub">{percents?.abs?.toFixed(2)}%</span>
        ) : (
          <>-</>
        )}
      </td>
      <td className="nk-tb-col tb-col-md text-right w-20 pt-4 pb-0">
        {percents.total ? (
          <>
            <div className="progress progress-md bg-light">
              <div
                className="progress-bar"
                data-progress={percents.total}
                style={{
                  width: `${percents.total}%`,
                }}
              />
            </div>
            <span className="tb-sub">{percents?.total?.toFixed(2)}%</span>
          </>
        ) : (
          <></>
        )}
      </td>
      <td className="nk-tb-col text-center w-10 py-1">
        {percents.total ? (
          <Doughnut data={chartData} options={votingResultsChartOptions} />
        ) : (
          <></>
        )}
      </td>
    </tr>
  );
}
export default OrderRow;
