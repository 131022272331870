/* eslint-disable no-nested-ternary */
import React, { FC, useEffect, useState } from 'react';
import html2pdf from 'html3pdf';
import Record from 'typescript';
import { format } from 'date-fns';
import ReactDOMServer from 'react-dom/server';
import { MemoryRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
// @ts-ignore
import ReactHtmlTableToExcel from 'react-html-table-to-excel';

import { addAlert } from 'redux/actions/alertActions';
import { getPartnerNDPercent } from 'utils/filters';
import { formatNumberDecimals } from 'utils/formats';
import { isBoardClosed, requestBoardAssistantsSignature } from 'utils/boards';

import boardStatus from 'constants/boardStatus';
import { formatDateToTime } from 'constants/formats';

import Dialog from 'components/Dialog';
import CustomLoading from 'components/CustomLoading';
import transformData from 'components/Tables/helpers';
import alertBodyTypes from 'components/Alert/alertBodyTypes';
import CustomDataTable from 'components/Tables/CustomDataTable';
import OperationMenuDots from 'components/MenuDots/OperationMenuDots';

import AssistantRow from './AssistantRow';
import tableColumns from './tableColumns';
import downloadOptions from './downloadOptions';

type tableProps = {
  board: Record<string, any>;
  attendanceSummaryByCapital: Record<string, number>;
};

const BoardAssistantsTable: FC<tableProps> = ({
  board,
  attendanceSummaryByCapital,
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const actualSociety = useSelector(
    (state: any) => state.society?.actualSociety
  );
  const isDemo = useSelector((state: any) => state.society?.role?.isDemo);

  const [currentDownloadOptions, setCurrentDownloadOptions] = useState<
    Record<string, any>[]
  >(downloadOptions(i18n));
  const [rows, setRows] = useState<any[]>([]);
  const [rowsPrint, setRowsPrint] = useState<any[]>([]);

  const [tableData, setTableData] = useState<Record<string, any>[]>([]);
  const [certificateData, setCertificateData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const columns = tableColumns(i18n);

  const certificateHtml = (secretaryName: string, print = false) =>
    ReactDOMServer.renderToString(
      <MemoryRouter>
        <div>
          <h6
            style={{
              marginBottom: '20px',
              display: 'flex',
              textAlign: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              lineHeight: '24px',
            }}
          >
            {t('AttendanceCertificateTitle', {
              societyName: actualSociety?.name,
            })}
          </h6>
          <div style={{ marginBottom: '20px' }}>
            {t('AttendanceCertificateBodyPart1', {
              boardPlace:
                board?.place || `[${t('PlaceOfCelebration').toUpperCase()}]`,
            })}{' '}
            {format(new Date(board.date), 'dd/MM/yyyy')}{' '}
            {t('AttendanceCertificateBodyPart2', {
              boardTime: formatDateToTime(board.date),
              societyName: actualSociety?.name,
            })}
          </div>

          <div className="card card-bordered w-100 mb-5">
            <table className="table table-tranx" id="assistants-table">
              <thead>
                <tr
                  style={{
                    fontSize: '11px',
                    fontWeight: '700',
                    textTransform: 'uppercase',
                    letterSpacing: '0.12em',
                    backgroundColor: '#f5f6fa',
                  }}
                >
                  <th className="">
                    <span>Nº</span>
                  </th>
                  <th className="" style={{ minWidth: '150px' }}>
                    <span>{t('Name')}</span>
                  </th>
                  <th className="">
                    <span>Nº {t('Share.')}</span>
                  </th>
                  <th className="">
                    <span>% ND</span>
                  </th>
                  <th className="">
                    <span>{t('RepresentativeBoard')}</span>
                  </th>
                  <th className="">
                    <span>{t('Attend')}</span>
                  </th>
                  {print && (
                    <th className="">
                      <span>{t('Signature')}</span>
                    </th>
                  )}
                </tr>
              </thead>
              <tbody className="fs-12px">{print ? rowsPrint : rows}</tbody>
            </table>
          </div>

          <div style={{ marginBottom: '20px' }}>
            {t('AttendanceCertificatePercentages')}
          </div>

          <div
            className="sp-plan-desc sp-plan-desc-mb border-0"
            style={{ marginBottom: '40px' }}
          >
            <ul className="row gx-1">
              <li className="col-3">
                <p>
                  <span className="text-soft">{t('Attend')}:</span>{' '}
                  {formatNumberDecimals(attendanceSummaryByCapital.assistants)}
                  %&nbsp;
                  <span className="d-inline text-soft fs-12px">
                    ({t('OfCapital')})
                  </span>
                </p>
              </li>
              <li className="col-3">
                <p>
                  <span className="text-soft">{t('AttendsRepresented')}:</span>{' '}
                  {formatNumberDecimals(attendanceSummaryByCapital.represented)}
                  %&nbsp;
                  <span className="d-inline text-soft fs-12px">
                    ({t('OfCapital')})
                  </span>
                </p>
              </li>

              <li className="col-3">
                <p>
                  <span className="text-soft">{t('Missing')}:</span>{' '}
                  {formatNumberDecimals(attendanceSummaryByCapital.absents)}
                  %&nbsp;
                  <span className="d-inline text-soft fs-12px">
                    ({t('OfCapital')})
                  </span>
                </p>
              </li>
              <li className="col-3">
                <p>
                  <span className="text-soft">Quorum:</span>{' '}
                  {formatNumberDecimals(attendanceSummaryByCapital.quorum)}
                  %&nbsp;
                  <span className="d-inline text-soft fs-12px">
                    ({t('OfCapital')})
                  </span>
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div style={{ marginBottom: '20px' }}>
          {t('AttendanceCertificateFooter')}
        </div>
        <div style={{ marginBottom: '20px' }}>
          {secretaryName || `[${t('SecretaryName').toUpperCase()}]`}
        </div>
        <div style={{ marginBottom: '20px' }}>{t('Secretary')}.</div>
      </MemoryRouter>
    );

  const downloadCertificate = (secretaryName: any) => {
    const html = certificateHtml(secretaryName);

    const options = {
      filename: `${'Certificate'}.pdf`,
      margin: [10, 10, 10, 10], // top, right, bottom, left
      pagebreak: { mode: 'avoid-all' },
      html2canvas: { scale: 2 },
      jsPDF: {
        unit: 'mm',
        format: 'a4',
        orientation: 'portrait',
      },
    };
    html2pdf().from(html).set(options).save();
  };

  const handleDownload = (type: any) => {
    if (type === 0) {
      const btn = document.getElementById('custom-table-xls-button');
      if (btn) {
        btn.click();
      }
    } else {
      // TODO Pass secretary name as param
      downloadCertificate('');
    }
  };

  const createAndSendCertificate = async () => {
    setIsLoading(true);
    try {
      const certificate = {
        header: t('AttendanceCertificateTitle', {
          societyName: actualSociety?.name,
        }),
        subHeader: `${t('AttendanceCertificateBodyPart1', {
          boardPlace:
            board?.place || `[${t('PlaceOfCelebration').toUpperCase()}]`,
        })} ${format(new Date(board.date), 'dd/MM/yyyy')} ${t(
          'AttendanceCertificateBodyPart2',
          {
            boardTime: formatDateToTime(board.date),
            societyName: actualSociety?.name,
          }
        )}`,
        tableColumns: [
          'Nº',
          t('Name'),
          `Nº ${t('Share.')}`,
          '% ND',
          t('RepresentativeBoard'),
          t('Attend'),
          t('Signature'),
        ],
        tableData: certificateData,
        summaryTableColumns: [
          t('Attend'),
          t('AttendsRepresented'),
          t('Missing'),
          t('Quorum'),
        ],
        summaryTableData: [
          `${formatNumberDecimals(attendanceSummaryByCapital.assistants)}% (${t(
            'OfCapital'
          )})`,
          `${formatNumberDecimals(
            attendanceSummaryByCapital.represented
          )}% (${t('OfCapital')})`,
          `${formatNumberDecimals(attendanceSummaryByCapital.absents)}% (${t(
            'OfCapital'
          )})`,
          `${formatNumberDecimals(attendanceSummaryByCapital.quorum)}% (${t(
            'OfCapital'
          )})`,
        ],
        paragraph: t('AttendanceCertificatePercentages'),
        footerText: t('AttendanceCertificateFooter'),
        signerName: board?.secretary
          ? actualSociety?.directors.find(
              (director: any) => director['_id'] === board?.secretary
            )?.socialDenomination
          : `[${t('SecretaryName').toUpperCase()}]`,
        signerJob: t('Secretary'),
      };

      await requestBoardAssistantsSignature(
        actualSociety,
        board,
        certificate,
        t
      );

      dispatch(addAlert(alertBodyTypes.BOARD_ASSISTANTS_SIGNATURE_REQUESTED));
    } catch (error) {
      console.log(error);
      dispatch(addAlert(alertBodyTypes.BOARD_ASSISTANTS_SIGNATURE_ERROR));
    } finally {
      setIsLoading(false);
    }
  };

  const handleRequestSignature = async () => {
    Dialog({
      icon: 'ni-send',
      title: t('¿Estás seguro?'),
      body: t(
        'Se enviará un correo a todos los asistentes. Una vez firmado, el documento se guardará en los Documentos Privados de la Junta'
      ),
      buttonText: t('Sí, enviar'),
      onConfirm: () => createAndSendCertificate(),
      onError: () => {},
    });
  };

  useEffect(() => {
    setCurrentDownloadOptions(downloadOptions(i18n));
  }, [i18n.language]);

  useEffect(() => {
    if (board && actualSociety) {
      if (board?.participants?.length) {
        setTableData(
          board.participants.map(
            (participant: Record<string, any>, index: number) => {
              const partner = actualSociety?.partners.find(
                (partner: any) => partner['_id'] === participant?.member
              );
              const representative = participant?.representative
                ? actualSociety?.partners.find(
                    (p: any) => p['_id'] === participant?.representative
                  )
                : null;
              const participantData = {
                participant,
                participantNumber: index + 1,
                participantMemberId: participant?.member,
                participantName: participant?.name,
                representative,
                representativeName: representative?.name || '-',
                percentage: getPartnerNDPercent(partner, actualSociety),
                shares: partner?.sharesCount?.actual || 0,
                societyId: actualSociety['_id'],
                decimals: 3,
              };
              return { ...participantData };
            }
          )
        );

        const assistants = board.participants.map(
          (participant: Record<string, any>, index: number) => {
            const partner = actualSociety?.partners.find(
              (partner: any) => partner['_id'] === participant.member
            );
            const representative = participant?.representative
              ? actualSociety?.partners.find(
                  (p: any) => p['_id'] === participant?.representative
                )
              : null;
            return {
              ...participant,
              representative,
              index: index + 1,
              percentage: getPartnerNDPercent(partner, actualSociety),
              sharesCount: partner?.sharesCount?.actual || 0,
              societyId: actualSociety['_id'],
              attend: participant.isRepresented
                ? t('Represented')
                : participant.assists
                ? t('Yes')
                : participant.isAbsent
                ? t('Nope')
                : '',
            };
          }
        );

        setCertificateData(assistants);

        setRows(
          assistants.map((assistant: Record<string, any>) => (
            <AssistantRow
              key={`assistant-${assistant.index}`}
              assistant={assistant}
            />
          ))
        );

        setRowsPrint(
          assistants.map((assistant: Record<string, any>) => (
            <AssistantRow
              key={`assistant-${assistant.index}`}
              assistant={assistant}
              print
            />
          ))
        );
      }
    }
  }, [board, actualSociety]);

  return (
    <>
      <div className="nk-block">
        <div className="card card-bordered">
          <div className="card-inner card-inner-md">
            <div className="card-title-group">
              <h6 className="card-title">{t('AssistantsList')}</h6>
              <div className="card-action">
                <ReactHtmlTableToExcel
                  id="custom-table-xls-button"
                  className="d-none"
                  table="assistants-table"
                  filename={`${t('BoardAssistants')} - ${board?.name}`}
                  sheet={t('BoardAssistants')}
                  buttonText={t('DownloadList')}
                />

                <button
                  type="button"
                  className="btn btn-outline-light mr-3"
                  onClick={handleRequestSignature}
                  disabled={isDemo}
                >
                  <em className="icon ni ni-edit-alt" />
                  <span>{t('RequestAssistantsSignature')}</span>
                </button>

                {/* @ts-ignore */}
                <OperationMenuDots
                  title={t('Download')}
                  menuOptions={currentDownloadOptions}
                  variant="light"
                  params={{
                    handleDownload,
                    outlined: true,
                  }}
                />
              </div>
            </div>
          </div>

          {tableData?.length && (
            <>
              {/* @ts-ignore */}
              <CustomDataTable
                data={transformData({
                  data: tableData,
                  columns,
                })}
                columns={columns}
                searchBy={t('Name')}
                pagination
                rowsPerPage={30}
                className="nk-tb-list"
                actions
                showDense
                showDownload={false}
                fileName={`${t('AssistantsList')} ${board.name}`}
              />
            </>
          )}
        </div>

        <table className="d-none table table-tranx" id="assistants-table">
          <thead>
            <tr>
              <th>
                <span>Nº</span>
              </th>
              <th>
                <span>{t('Name')}</span>
              </th>
              <th>
                <span>Nº {t('Share.')}</span>
              </th>
              <th>
                <span>% ND</span>
              </th>
              <th>
                <span>{t('RepresentativeBoard')}</span>
              </th>
              <th>
                <span>{t('Attend')}</span>
              </th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>

      {isLoading && <CustomLoading />}
    </>
  );
};

export default BoardAssistantsTable;
