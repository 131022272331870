/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

import PartnerSociety from 'components/PartnerSociety';
import PartnerPersonal from 'components/PartnerPersonal';
import PartnerTransactions from 'components/PartnerTransactions';
import PartnerAside from 'components/PartnerAside';
import PartnerDocuments from 'components/PartnerDocuments';
import PartnerInvestment from 'components/PartnerInvestment';
import CustomLoading from 'components/CustomLoading';

import { getTenderOffersShares } from 'redux/actions/tenderOffersSharesActions';
import { getHoldingClasses } from 'redux/actions/holdingClassActions';
import { getUsers } from 'redux/actions/userActions';
import { validateHasTenderOffer } from 'redux/actions/validationActions';
import {
  getActualSociety,
  getActualPartner,
  getPartnerRegistrationDate,
  getSocietyValue,
} from 'utils/filters';

import { setMenu } from 'redux/actions/menuActions';
import { dateFormat } from 'constants/formats';
import screens from 'constants/screens';

import menuTypes from 'constants/menuTypes';
import './PartnerDetail.scss';

const PartnerDetail = ({ initialPage = 0 }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { societyId, partnerId, pageId } = useParams();

  const user = useSelector((state) => state.user);
  const isAdmin = useSelector((state) => state.society?.role?.isAdmin);
  const actualSociety = useSelector((state) => state.society?.actualSociety);
  const tenderOffers = useSelector((state) => state.tenderOffers);

  const [actualPartner, setActualPartner] = useState();
  const [partnerNumber, setPartnerNumber] = useState();
  const [incorporationDate, setIncorporationDate] = useState(null);
  const [mainAdmin, setMainAdmin] = useState();
  const [page, setPage] = useState(+pageId || initialPage);
  const [pages, setPages] = useState([]);
  const [isCardVisible, setIsCardVisible] = useState(false);
  const [screenShowAside, setScreenShowAside] = useState(screens.XL);

  const getAdmins = async (admins) => {
    try {
      const adminsList = await dispatch(
        getUsers({
          usersList: admins,
        })
      );
      setMainAdmin(adminsList?.[0]);
    } catch (error) {
      console.log(error);
    }
  };

  const getTenderOffers = () => {
    dispatch(getTenderOffersShares(societyId));
  };

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId) getActualSociety(user, societyId);
  }, [actualSociety, user, societyId]);

  useEffect(() => {
    if (actualSociety) {
      getAdmins([actualSociety?.administrators?.[0]]);
      getTenderOffers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety]);

  useEffect(() => {
    if (user && actualSociety) {
      const societyValue = getSocietyValue(actualSociety);
      const partner = getActualPartner(actualSociety, partnerId);
      setPartnerNumber(partner?.partnerNumber);
      if (partner) {
        const registrationDate = getPartnerRegistrationDate(
          actualSociety,
          partner
        );
        setIncorporationDate(
          registrationDate
            ? format(new Date(registrationDate), dateFormat)
            : '-'
        );
        const partnerData = {
          ...partner,
          societyValue,
          mainAdmin,
        };
        setActualPartner(partnerData);

        const currentPages = [
          <PartnerSociety
            key="PartnerSociety"
            society={actualSociety}
            partner={partnerData}
            setPage={setPage}
          />,
          <PartnerPersonal
            key="PartnerPersonal"
            incorporationDate={incorporationDate}
            society={actualSociety}
            partner={partnerData}
          />,
          <PartnerTransactions
            key="PartnerTransactions"
            society={actualSociety}
            partner={partnerData}
            setScreenShowAside={setScreenShowAside}
          />,
          <PartnerDocuments
            key="PartnerDocuments"
            society={actualSociety}
            partner={partnerData}
          />,
          <PartnerInvestment
            key="PartnerInvestment"
            society={actualSociety}
            partner={partnerData}
            setPage={setPage}
          />,
        ];

        setPages(currentPages);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    partnerId,
    societyId,
    user,
    partnerNumber,
    actualSociety,
    incorporationDate,
    mainAdmin,
  ]);

  useEffect(() => {
    if (actualSociety) {
      dispatch(
        setMenu({
          type: menuTypes.EXTENDED,
          societyName: actualSociety?.name,
          societyId: actualSociety?.['_id'],
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety]);

  useEffect(() => {
    if (actualSociety) {
      dispatch(getHoldingClasses(actualSociety?.['_id']));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety]);

  useEffect(() => {
    if (actualSociety && tenderOffers.length) {
      dispatch(validateHasTenderOffer(tenderOffers, partnerId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety, tenderOffers, partnerId]);

  useEffect(() => {
    if (page !== 2 && page !== 3 && page !== 4) {
      setIsCardVisible(true);
    } else {
      setIsCardVisible(false);
    }
  }, [page]);

  const handleClick = () => {
    setIsCardVisible(!isCardVisible);
  };

  return actualPartner ? (
    <div className="nk-content-body">
      <div className="nk-block-head-content">
        <h3 className="nk-block-title page-title">{actualSociety.name}</h3>
      </div>
      <div className="nk-content-wrap">
        <div className="nk-block-head">
          <div className="nk-block-between g-3">
            <div className="nk-block-head-content">
              <h3 className="nk-block-title page-title">
                {t('Partner')} /{' '}
                <strong className="text-primary small">
                  {actualPartner.name || ''}
                </strong>
              </h3>
              <div className="nk-block-des text-soft">
                <ul className="list-inline">
                  <li>
                    {t('PartnerNo')}:
                    <span className="text-base">{partnerNumber}</span>
                  </li>
                  <li>{`${t('Since')}: ${incorporationDate}`}</li>
                </ul>
              </div>
            </div>
            <div className="nk-block-head-content">
              <span
                className="btn btn-outline-light bg-white d-none d-sm-inline-flex"
                onClick={() => history.goBack()}
              >
                <em className="icon ni ni-arrow-left" />
                <span>{t('Back')}</span>
              </span>
              <span
                className="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"
                onClick={() => history.goBack()}
              >
                <em className="icon ni ni-arrow-left" />
              </span>
            </div>
          </div>
        </div>
        <div className="nk-block">
          <div className="card card-bordered">
            <div className="card-aside-wrap">
              <div className="card-content">
                <ul
                  className="nav nav-tabs nav-tabs-mb-icon nav-tabs-card"
                  id="partner-details--tabs"
                >
                  <li className="nav-item">
                    <p
                      className={`nav-link ${page === 0 ? 'active' : ''}`}
                      onClick={() => setPage(0)}
                    >
                      <em className="icon ni ni-user-circle" />
                      <span>{t('Partner')}</span>
                    </p>
                  </li>
                  <li className="nav-item">
                    <p
                      className={`nav-link ${page === 1 ? 'active' : ''}`}
                      onClick={() => setPage(1)}
                    >
                      <em className="icon ni ni-list" />
                      <span>{t('Data')}</span>
                    </p>
                  </li>
                  <li className="nav-item">
                    <p
                      className={`nav-link ${page === 2 ? 'active' : ''}`}
                      onClick={() => setPage(2)}
                    >
                      <em className="icon ni ni-repeat" />
                      <span>{t('Transactions')}</span>
                    </p>
                  </li>
                  <li className="nav-item">
                    <p
                      className={`nav-link ${page === 3 ? 'active' : ''}`}
                      onClick={() => setPage(3)}
                    >
                      <em className="icon ni ni-file-text" />
                      <span>{t('Documents')}</span>
                    </p>
                  </li>
                  <li className="nav-item">
                    <p
                      className={`nav-link ${page === 4 ? 'active' : ''}`}
                      onClick={() => setPage(4)}
                    >
                      <em className="icon ni ni-invest" />
                      <span>{t('ROI')}</span>
                    </p>
                  </li>
                  <li
                    className={`nav-item nav-item-trigger d-${screenShowAside}-none ml-0`}
                  >
                    <p
                      className="toggle btn btn-icon btn-trigger bg-success rounded-circle text-white"
                      data-target="userAside"
                      onClick={() => setIsCardVisible(!isCardVisible)}
                    >
                      <em className="icon ni ni-user-list-fill" />
                    </p>
                  </li>
                </ul>
                {pages[page]}
              </div>

              {isCardVisible && (
                <div
                  className={
                    isCardVisible
                      ? `card-aside card-aside-right user-aside toggle-slide toggle-slide-right toggle-break-${screenShowAside} toggle-screen-${screenShowAside} content-active`
                      : `card-aside card-aside-right user-aside toggle-slide toggle-slide-right toggle-break-${screenShowAside} toggle-screen-${screenShowAside}`
                  }
                  data-content="userAside"
                  data-toggle-screen={screenShowAside}
                  data-toggle-overlay="true"
                  data-toggle-body="true"
                >
                  <div className="card-inner-group">
                    <div className="simplebar-wrapper">
                      <div className="simplebar-height-auto-observer-wrapper">
                        <div className="simplebar-height-auto-observer" />
                      </div>
                      <div className="simplebar-mask">
                        <div className="simplebar-offset">
                          <div className="simplebar-content-wrapper">
                            <div className="simplebar-content">
                              <ul>
                                <li
                                  className={`nav-item nav-item-trigger d-${screenShowAside}-none m-4`}
                                >
                                  <p
                                    className="toggle btn btn-icon btn-trigger"
                                    data-target="userAside"
                                    onClick={handleClick}
                                  >
                                    <em className="icon ni ni-cross" />
                                  </p>
                                </li>
                              </ul>
                              <PartnerAside
                                user={user}
                                isAdmin={isAdmin}
                                partner={actualPartner}
                                society={actualSociety}
                                partnerNumber={partnerNumber}
                                incorporationDate={incorporationDate}
                                setIsCardVisible={setIsCardVisible}
                                setPage={setPage}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="simplebar-placeholder" />
                  </div>
                  <div className="simplebar-track simplebar-horizontal">
                    <div className="simplebar-scrollbar" />
                  </div>
                  <div className="simplebar-track simplebar-vertical">
                    <div className="simplebar-scrollbar" />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <CustomLoading />
  );
};

export default PartnerDetail;
