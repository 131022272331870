import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getActualSociety } from 'utils/filters';
import { setMenu } from 'redux/actions/menuActions';
import { getBoards, updateBoard } from 'redux/actions/boardActions';
import { SELECCIONAR_SOCIO } from 'constants/defaultConstants';
import instructionTypes from 'constants/instructionTypes';
import voteTypes from 'constants/voteTypes';
import voteValues from 'constants/voteValues';
import { format } from 'date-fns';
import Swal from 'sweetalert2';
import { isBoardClosedOrCanceled } from 'utils/boards';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import DelegationMessageBox from 'components/DelegationMessageBox';

const DelegationPartner = () => {
  const { t } = useTranslation();
  const { societyId, boardId, partnerId } = useParams();
  const dispatch = useDispatch();

  const { actualSociety, user, boards } = useSelector((state) => ({
    actualSociety: state.society?.actualSociety,
    user: state.user,
    boards: state.boards,
  }));

  const [board, setBoard] = useState();
  const [isClosed, setIsClosed] = useState(false);
  const [currentPartner, setCurrentPartner] = useState();
  const [selectedPartner, setSelectedPartner] = useState(SELECCIONAR_SOCIO);
  const [selectablePartners, setSelectablePartners] = useState([]);
  const [hasInstructions, setHasInstructions] = useState(false);
  const [instructionType, setInstructionType] = useState('');
  const [orders, setOrders] = useState([]);
  const [instructions, setInstructions] = useState([]);
  const [date, setDate] = useState('');

  const animatedComponents = makeAnimated();
  const [selectedPartnerOption, setSelectedPartnerOption] = useState({
    value: SELECCIONAR_SOCIO,
    label: SELECCIONAR_SOCIO,
  });

  const handlePartnerOptionChange = (selectedOption) => {
    setSelectedPartner(selectedOption.value);
    setSelectedPartnerOption(selectedOption);
  };

  const handleSaveInstruction = (value, index) => {
    const newInstructions = [...instructions];
    if (newInstructions[index]) {
      newInstructions[index].vote = value;
      setInstructions(newInstructions);
    }
  };

  const isDelegationAllowed = () =>
    !currentPartner?.hasDelegated &&
    !isClosed &&
    currentPartner?.votes?.length === 0;

  const isAlreadyClosed = () => isClosed;

  const isAlreadyDelegated = () => currentPartner?.hasDelegated && !isClosed;

  const isAlreadyVoted = () =>
    currentPartner?.votes.length > 0 &&
    !currentPartner?.hasDelegated &&
    !isClosed;

  const saveDelegation = (event) => {
    event.preventDefault();
    if (selectedPartner !== SELECCIONAR_SOCIO) {
      const participant = board?.participants.find(
        (p) => p?.member === partnerId
      );
      if (participant) {
        const representative = board?.participants.find(
          (p) => p?.member === selectedPartner
        );
        if (representative?.assists) {
          participant.assists = true;
          participant.isAbsent = false;
        } else if (representative?.isAbsent) {
          participant.assists = false;
          participant.isAbsent = true;
        }
        participant.isRepresented = true;
        participant.representative = selectedPartner;
        participant.hasDelegated = false;
        const newInstructions =
          instructionType === instructionTypes.POINT_BY_POINT.value
            ? instructions
            : [];
        participant.delegationVote = hasInstructions
          ? { instructionType, instructions: newInstructions }
          : null;

        return Swal.fire({
          icon: 'info',
          title: `<h4 class="nk-modal-title">${t('Attention')}<br></h4>`,
          html: `<p>${t('SignDocumentToComplete')}`,
          confirmButtonText: t('Sign'),
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonColor: '#6576FF',
        }).then((result) => {
          if (result.isConfirmed) {
            dispatch(
              updateBoard(boardId, { participantData: participant }, false)
            );
            window.location.href = `#/firma-delegacion/${societyId}/${boardId}/${partnerId}`;
          }
        });
      }
    }
  };

  useEffect(() => {
    if (societyId) {
      dispatch(getBoards(societyId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [societyId]);

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId) {
      getActualSociety(user, societyId);
    }
  }, [actualSociety, user, societyId]);

  useEffect(() => {
    if (boards.length && boardId) {
      const actualBoard = boards.find((board) => board['_id'] === boardId);
      setIsClosed(isBoardClosedOrCanceled(actualBoard));
      setBoard(actualBoard);
    }
  }, [boards, boardId]);

  useEffect(
    () =>
      dispatch(
        setMenu({
          type: null,
          societyId: actualSociety?.['_id'] || null,
          societyName: actualSociety?.name,
        })
      ),
    [actualSociety, dispatch]
  );

  useEffect(() => {
    if (board) {
      setDate(board.date ? format(new Date(board.date), 'dd/MM/yyyy') : '');
      const validOrders = board?.orders?.filter(
        (order) => order.voteType === voteTypes.YES_NO_ABS.value
      );
      setOrders(validOrders);
      const orderInstructions = validOrders?.map((order) => ({
        order: order['_id'],
        vote: '',
      }));
      setInstructions(orderInstructions);
      const actualPartner = board?.participants.find(
        (p) => p?.member === partnerId
      );
      if (actualPartner) {
        if (actualPartner?.representative) {
          setSelectablePartners(
            actualSociety?.partners.filter(
              (partner) => partner['_id'] === actualPartner?.representative
            ) || []
          );
        } else {
          let filteredPartners = [];
          if (board?.delegationPartners?.length > 0) {
            filteredPartners = actualSociety?.partners.filter(
              (partner) =>
                board?.delegationPartners.includes(partner['_id']) &&
                partner['_id'] !== partnerId
            );
          } else {
            filteredPartners = actualSociety?.partners.filter(
              (partner) => partner['_id'] !== partnerId // && partner?.sharesCount?.actual > 0 // TODO: Uncomment this line when we want to filter partners with shares
            );
          }

          setSelectablePartners(filteredPartners || []);
        }
        if (actualPartner?.hasDelegated) {
          const delegate = board?.participants.find(
            (p) => p?.member === actualPartner?.representative
          );
          setCurrentPartner({
            ...actualPartner,
            representativeName: delegate?.name,
          });
        } else {
          setCurrentPartner(actualPartner);
        }
      }
    }
  }, [board, partnerId, actualSociety]);

  useEffect(() => {
    if (selectedPartner === SELECCIONAR_SOCIO) {
      setHasInstructions(false);
    }
  }, [selectedPartner]);

  return (
    <>
      {isDelegationAllowed() ? (
        <div className="nk-wrap nk-wrap-nosidebar">
          <div className="nk-content">
            <div className="nk-block nk-auth-body wide-xs mt-5">
              <div className="brand-logo pb-4 text-center">
                <a className="logo-link">
                  <img
                    className="logo-dark logo-img logo-img-lg"
                    src="https://www.sttok.com/images/logo-dark2x.png"
                    alt="logo-dark"
                  />
                </a>
              </div>
              <div className="card card-bordered">
                <div className="card-inner card-inner-lg">
                  <div className="nk-block-head">
                    <div className="nk-block-head-content">
                      <h4 className="nk-block-title">
                        {t('VotingDelegation')}
                      </h4>
                      <div className="nk-block-des">
                        <p>
                          {t('DelegateVoteMessagePart1', {
                            societyName: actualSociety?.name,
                          })}
                          {date}.{t('DelegateVoteMessagePart2')}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="form-label-group">
                      <label className="form-label" htmlFor="default-01">
                        {t('SelectPartnerToDelegateVote')}
                      </label>
                    </div>
                    <Select
                      closeMenuOnSelect
                      className="form-control-wrap react-select"
                      value={selectedPartnerOption}
                      options={selectablePartners.map((partner) => ({
                        value: partner['_id'],
                        label: `${partner?.cif} | ${partner?.name}`,
                      }))}
                      components={animatedComponents}
                      onChange={handlePartnerOptionChange}
                    />
                  </div>
                  <div className="nk-modal-text text-left">
                    <div
                      className="custom-control custom-control-sm custom-checkbox custom-control-pro"
                      style={{
                        zIndex: 0,
                      }}
                    >
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="instruction-checkbox"
                        checked={hasInstructions}
                        onChange={(event) =>
                          setHasInstructions(event.target.checked)
                        }
                        disabled={selectedPartner === SELECCIONAR_SOCIO}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="instruction-checkbox"
                      >
                        {t('GiveVotingInstructions')}
                      </label>
                    </div>
                    {hasInstructions ? (
                      <>
                        <div className="form-group mt-3">
                          <label className="form-label" htmlFor="full-name">
                            {t('SelectHowTheDelegateVote')}
                          </label>
                          <div className="row">
                            <div className="col-md-3">
                              <div className="custom-control custom-control-sm custom-radio custom-control-pro">
                                <input
                                  type="radio"
                                  className="custom-control-input"
                                  name="btnRadio"
                                  id="btnRadio1"
                                  value={instructionTypes.ALL_YES.value}
                                  checked={
                                    instructionType ===
                                    instructionTypes.ALL_YES.value
                                  }
                                  onChange={(event) =>
                                    setInstructionType(event.target.value)
                                  }
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="btnRadio1"
                                >
                                  {t('AllYes')}
                                </label>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="custom-control custom-control-sm custom-radio custom-control-pro">
                                <input
                                  type="radio"
                                  className="custom-control-input"
                                  name="btnRadio"
                                  id="btnRadio2"
                                  value={instructionTypes.ALL_NO.value}
                                  checked={
                                    instructionType ===
                                    instructionTypes.ALL_NO.value
                                  }
                                  onChange={(event) =>
                                    setInstructionType(event.target.value)
                                  }
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="btnRadio2"
                                >
                                  {t('AllNo')}
                                </label>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="custom-control custom-control-sm custom-radio custom-control-pro">
                                <input
                                  type="radio"
                                  className="custom-control-input"
                                  name="btnRadio"
                                  id="btnRadio3"
                                  value={instructionTypes.ALL_ABS.value}
                                  checked={
                                    instructionType ===
                                    instructionTypes.ALL_ABS.value
                                  }
                                  onChange={(event) =>
                                    setInstructionType(event.target.value)
                                  }
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="btnRadio3"
                                >
                                  {t('AllAbs')}
                                </label>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="custom-control custom-control-sm custom-radio custom-control-pro">
                                <input
                                  type="radio"
                                  className="custom-control-input"
                                  name="btnRadio"
                                  id="btnRadio4"
                                  value={instructionTypes.POINT_BY_POINT.value}
                                  checked={
                                    instructionType ===
                                    instructionTypes.POINT_BY_POINT.value
                                  }
                                  onChange={(event) =>
                                    setInstructionType(event.target.value)
                                  }
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="btnRadio4"
                                >
                                  {t('PointByPoint')}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        {instructionType ===
                          instructionTypes.POINT_BY_POINT.value &&
                        orders.length ? (
                          <>
                            <label
                              className="form-label mt-2"
                              htmlFor="full-name"
                            >
                              {t('SetOneVoteForEachPoint')}
                            </label>
                            {orders.map((order, index) => (
                              <div
                                key={order['_id']}
                                className="card-inner card-bordered mt-2 round-lg"
                              >
                                <span className="p-2">
                                  {`${index + 1}. ${order.subject}`}
                                </span>
                                {order.voteType ===
                                  voteTypes.YES_NO_ABS.value && (
                                  <div className="form-group p-2 mt-1">
                                    <div className="row">
                                      <div className="col-md-4">
                                        <div className="custom-control custom-control-sm custom-radio custom-control-pro">
                                          <input
                                            type="radio"
                                            className="custom-control-input"
                                            name={`btnRadio-${order['_id']}`}
                                            id={`btnRadio1-${order['_id']}`}
                                            value={voteValues.YES.value}
                                            checked={
                                              instructions[index].vote ===
                                              voteValues.YES.value
                                            }
                                            onChange={(event) =>
                                              handleSaveInstruction(
                                                event.target.value,
                                                index
                                              )
                                            }
                                          />
                                          <label
                                            className="custom-control-label"
                                            htmlFor={`btnRadio1-${order['_id']}`}
                                          >
                                            {t('Yes')}
                                          </label>
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="custom-control custom-control-sm custom-radio custom-control-pro">
                                          <input
                                            type="radio"
                                            className="custom-control-input"
                                            name={`btnRadio-${order['_id']}`}
                                            id={`btnRadio2-${order['_id']}`}
                                            value={voteValues.NO.value}
                                            checked={
                                              instructions[index].vote ===
                                              voteValues.NO.value
                                            }
                                            onChange={(event) =>
                                              handleSaveInstruction(
                                                event.target.value,
                                                index
                                              )
                                            }
                                          />
                                          <label
                                            className="custom-control-label"
                                            htmlFor={`btnRadio2-${order['_id']}`}
                                          >
                                            {t('Nope')}
                                          </label>
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="custom-control custom-control-sm custom-radio custom-control-pro">
                                          <input
                                            type="radio"
                                            className="custom-control-input"
                                            name={`btnRadio-${order['_id']}`}
                                            id={`btnRadio3-${order['_id']}`}
                                            value={voteValues.ABS.value}
                                            checked={
                                              instructions[index].vote ===
                                              voteValues.ABS.value
                                            }
                                            onChange={(event) =>
                                              handleSaveInstruction(
                                                event.target.value,
                                                index
                                              )
                                            }
                                          />
                                          <label
                                            className="custom-control-label"
                                            htmlFor={`btnRadio3-${order['_id']}`}
                                          >
                                            {t('Abstention')}
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            ))}
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="form-group">
                    <button
                      type="button"
                      className="btn btn-lg btn-primary btn-block"
                      disabled={
                        selectedPartner === SELECCIONAR_SOCIO ||
                        (hasInstructions && !instructionType)
                      }
                      onClick={saveDelegation}
                    >
                      {t('DelegateVote')}
                    </button>
                  </div>

                  <div className="form-note-s2 text-center pt-4">
                    {' '}
                    {t('IfDoNotWantToDelegateCanLeave')}
                  </div>

                  <ul className="nav justify-center gx-4">
                    <li className="nav-item">
                      <a className="nav-link" href="https://www.sttok.com">
                        Sttok
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          {isAlreadyClosed() && (
            <DelegationMessageBox
              icon="INFO"
              title={t('BoardIsAlreadyClosed')}
              description={t('BoardIsAlreadyClosedDescription', {
                societyName: actualSociety?.name,
              })}
              link={`/detalle-socio/${societyId}/${partnerId}`}
              linkText={t('SeeMyPartnerDetails')}
            />
          )}
          {isAlreadyDelegated() && (
            <DelegationMessageBox
              icon="INFO"
              title={t('DelegationAlreadyExists', {
                representativeName: currentPartner?.representativeName,
              })}
              description={t('DelegationAlreadyExistsDescription')}
              link={`/detalle-socio/${societyId}/${partnerId}`}
              linkText={t('SeeMyPartnerDetails')}
            />
          )}
          {isAlreadyVoted() && (
            <DelegationMessageBox
              icon="INFO"
              title={t('YouAlreadyVoted')}
              description={t('YouAlreadyVotedDescription', {
                boardName: board?.name,
                boardDate: date.replace(/\//g, '-'),
                societyName: actualSociety?.name,
              })}
              link={`/detalle-socio/${societyId}/${partnerId}`}
              linkText={t('SeeMyPartnerDetails')}
            />
          )}
        </>
      )}
    </>
  );
};

export default DelegationPartner;
