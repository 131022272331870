import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Beneficiary } from 'types';

type AddressDataViewProps = {
  beneficiary: Beneficiary;
  setUpdatedBeneficiary: any;
};

const AddressDataView: FC<AddressDataViewProps> = ({
  beneficiary,
  setUpdatedBeneficiary,
}) => {
  const { t } = useTranslation();

  const [line1, setLine1] = useState<string>(beneficiary?.address?.line1);
  const [city, setCity] = useState<string>(beneficiary?.address?.city);
  const [zip, setZip] = useState<string>(beneficiary?.address?.zip);
  const [country, setCountry] = useState<string>(beneficiary?.address?.country);
  const [hasTaxAddress, setHasTaxAddress] = useState<boolean>(
    !!(
      beneficiary?.taxAddress?.line1 ||
      beneficiary?.taxAddress?.city ||
      beneficiary?.taxAddress?.zip ||
      beneficiary?.taxAddress?.country
    )
  );
  const [taxLine1, setTaxLine1] = useState<string>(
    beneficiary?.taxAddress?.line1
  );
  const [taxCity, setTaxCity] = useState<string>(beneficiary?.taxAddress?.city);
  const [taxZip, setTaxZip] = useState<string>(beneficiary?.taxAddress?.zip);
  const [taxCountry, setTaxCountry] = useState<string>(
    beneficiary?.taxAddress?.country
  );

  const handleChangeTaxAddress = () => {
    if (hasTaxAddress) {
      setTaxLine1('');
      setTaxCity('');
      setTaxZip('');
      setTaxCountry('');
      setUpdatedBeneficiary({
        ...beneficiary,
        taxAddress: { line1: '', city: '', zip: '', country: '' },
      });
    }
    setHasTaxAddress(!hasTaxAddress);
  };

  const handleChangeLine1 = (event: any) => {
    const beneficiaryLine1 = event.target.value;
    setLine1(beneficiaryLine1);
    setUpdatedBeneficiary({
      ...beneficiary,
      address: { ...beneficiary.address, line1: beneficiaryLine1 },
    });
  };

  const handleChangeCity = (event: any) => {
    const beneficiaryCity = event.target.value;
    setCity(beneficiaryCity);
    setUpdatedBeneficiary({
      ...beneficiary,
      address: { ...beneficiary.address, city: beneficiaryCity },
    });
  };

  const handleChangeZip = (event: any) => {
    const beneficiaryZip = event.target.value;
    setZip(beneficiaryZip);
    setUpdatedBeneficiary({
      ...beneficiary,
      address: { ...beneficiary.address, zip: beneficiaryZip },
    });
  };

  const handleChangeCountry = (event: any) => {
    const beneficiaryCountry = event.target.value;
    setCountry(beneficiaryCountry);
    setUpdatedBeneficiary({
      ...beneficiary,
      address: { ...beneficiary.address, country: beneficiaryCountry },
    });
  };

  const handleChangeTaxLine1 = (event: any) => {
    const taxLine1 = event.target.value;
    setTaxLine1(taxLine1);
    setUpdatedBeneficiary({
      ...beneficiary,
      taxAddress: { ...beneficiary.taxAddress, line1: taxLine1 },
    });
  };

  const handleChangeTaxCity = (event: any) => {
    const taxCity = event.target.value;
    setTaxCity(taxCity);
    setUpdatedBeneficiary({
      ...beneficiary,
      taxAddress: { ...beneficiary.taxAddress, city: taxCity },
    });
  };

  const handleChangeTaxZip = (event: any) => {
    const taxZip = event.target.value;
    setTaxZip(taxZip);
    setUpdatedBeneficiary({
      ...beneficiary,
      taxAddress: { ...beneficiary.taxAddress, zip: taxZip },
    });
  };

  const handleChangeTaxCountry = (event: any) => {
    const taxCountry = event.target.value;
    setTaxCountry(taxCountry);
    setUpdatedBeneficiary({
      ...beneficiary,
      taxAddress: { ...beneficiary.taxAddress, country: taxCountry },
    });
  };

  return (
    <>
      <div className="tab-pane active">
        <div className="row gy-4">
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label w-100" htmlFor="address-l1">
                {t('Address')}
              </label>
              <input
                type="text"
                className="form-control form-control-lg"
                id="address-l1"
                placeholder={t('Line1')}
                value={line1}
                onChange={handleChangeLine1}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label w-100" htmlFor="address-l2">
                {t('City')}
              </label>
              <input
                type="text"
                className="form-control form-control-lg"
                id="address-l2"
                placeholder={t('IntroduceCity')}
                value={city}
                onChange={handleChangeCity}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label w-100" htmlFor="address-st">
                {t('PostalCode')}
              </label>
              <input
                type="text"
                className="form-control form-control-lg"
                id="address-st"
                placeholder={t('IntroduceZipCode')}
                value={zip}
                onChange={handleChangeZip}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label w-100" htmlFor="address-county">
                {t('Country')}
              </label>
              <input
                type="text"
                className="form-control form-control-lg"
                id="country-st"
                placeholder={t('IntroduceCountry')}
                value={country}
                onChange={handleChangeCountry}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 mt-2">
            <div className="form-group mt-2">
              <div className="custom-control custom-control-xs custom-checkbox">
                <input
                  type="checkbox"
                  id="checkbox-notifications"
                  className="custom-control-input"
                  checked={hasTaxAddress}
                  onChange={handleChangeTaxAddress}
                />
                <label
                  className="custom-control-label"
                  htmlFor="checkbox-notifications"
                >
                  {t('HasFiscalResidenceDifferentFromAddress')}
                </label>
              </div>
            </div>
          </div>
        </div>

        {hasTaxAddress && (
          <>
            <h6 className="my-4">{t('FiscalResidence')}</h6>

            <div className="row gy-4">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="form-label w-100" htmlFor="tax-address-l1">
                    {t('Address')}
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    id="tax-address-l1"
                    placeholder={t('Line1')}
                    value={taxLine1}
                    onChange={handleChangeTaxLine1}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label w-100"
                    htmlFor="tax-address-city"
                  >
                    {t('City')}
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    id="tax-address-city"
                    placeholder={t('IntroduceCity')}
                    value={taxCity}
                    onChange={handleChangeTaxCity}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="form-label w-100" htmlFor="tax-address-zip">
                    {t('PostalCode')}
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    id="tax-address-zip"
                    placeholder={t('IntroduceZipCode')}
                    value={taxZip}
                    onChange={handleChangeTaxZip}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label w-100"
                    htmlFor="tax-address-country"
                  >
                    {t('Country')}
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    id="tax-address-country"
                    placeholder={t('IntroduceCountry')}
                    value={taxCountry}
                    onChange={handleChangeTaxCountry}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default AddressDataView;
