import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { format } from 'date-fns';

import InputError from 'components/InputError';

import { dateFormatInverted } from 'constants/formats';
import { EMAIL_PATTERN } from 'constants/defaultConstants';

import { Beneficiary } from 'types';

type PersonalDataViewProps = {
  beneficiary: Beneficiary;
  setUpdatedBeneficiary: any;
};

const PersonalDataView: FC<PersonalDataViewProps> = ({
  beneficiary,
  setUpdatedBeneficiary,
}) => {
  const { t } = useTranslation();

  const user: any = useSelector((state: any) => state.user);
  const isAdmin: boolean = useSelector(
    (state: any) => state.society?.role?.isAdmin
  );

  const [name, setName] = useState<string>(beneficiary?.name || '');
  const [cif, setCif] = useState<string>(beneficiary?.cif || '');
  const [email, setEmail] = useState<string>(beneficiary?.email || '');
  const [emails, setEmails] = useState<string[]>([]);
  const [birthdate, setBirthdate] = useState<string>(
    beneficiary?.birthdate
      ? format(new Date(beneficiary?.birthdate), dateFormatInverted)
      : ''
  );
  const [jobTitle, setJobTitle] = useState<string>(beneficiary?.jobTitle);
  const [employeeNumber, setEmployeeNumber] = useState<string>(
    beneficiary?.employeeNumber || ''
  );
  const [nationality, setNationality] = useState<string>(
    beneficiary?.nationality || ''
  );
  const [phone, setPhone] = useState<string>(beneficiary?.phone || '');
  const [image, setImage] = useState<string>(beneficiary?.image || '');

  const isValidEmail = (email: string) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };
  const validEmails = () => {
    const emails = [beneficiary.email, ...beneficiary.emails];
    return emails.every((email) => email !== '' && isValidEmail(email));
  };

  const handleChangeSelectedImage = async (event: any) => {
    if (event.target.files.length > 0) {
      const reader = new FileReader();
      reader.onloadend = (e) => {
        const result = e.target?.result || '';
        setImage(result as string);
        setUpdatedBeneficiary({ ...beneficiary, image: result as string });
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const handleChangeName = (event: any) => {
    const beneficiaryName = event.target.value;
    setName(beneficiaryName);
    setUpdatedBeneficiary({ ...beneficiary, name: beneficiaryName });
  };

  const handleChangeCif = (event: any) => {
    const beneficiaryCif = event.target.value;
    setCif(beneficiaryCif);
    setUpdatedBeneficiary({ ...beneficiary, cif: beneficiaryCif });
  };

  const handleChangeEmail = (event: any) => {
    const beneficiaryEmail = event.target.value;
    setEmail(beneficiaryEmail);
    setUpdatedBeneficiary({ ...beneficiary, email: beneficiaryEmail });
  };

  const handleChangeBirthdate = (event: any) => {
    const beneficiaryBirthdate = event.target.value;
    setBirthdate(beneficiaryBirthdate);
    setUpdatedBeneficiary({ ...beneficiary, birthdate: beneficiaryBirthdate });
  };

  const handleChangeJobTitle = (event: any) => {
    const beneficiaryJobTitle = event.target.value;
    setJobTitle(beneficiaryJobTitle);
    setUpdatedBeneficiary({ ...beneficiary, jobTitle: beneficiaryJobTitle });
  };

  const handleChangeEmployeeNumber = (event: any) => {
    const beneficiaryEmployeeNumber = event.target.value;
    setEmployeeNumber(beneficiaryEmployeeNumber);
    setUpdatedBeneficiary({
      ...beneficiary,
      employeeNumber: beneficiaryEmployeeNumber,
    });
  };

  const handleChangeNationality = (event: any) => {
    const beneficiaryNationality = event.target.value;
    setNationality(beneficiaryNationality);
    setUpdatedBeneficiary({
      ...beneficiary,
      nationality: beneficiaryNationality,
    });
  };

  const handleChangePhone = (event: any) => {
    const beneficiaryPhone = event.target.value;
    setPhone(beneficiaryPhone);
    setUpdatedBeneficiary({ ...beneficiary, phone: beneficiaryPhone });
  };

  const handleAddEmail = () => {
    setEmails(['']);
    setUpdatedBeneficiary({ ...beneficiary, emails: [''] });
  };

  const handleRemoveEmail = () => {
    setEmails([]);
    setUpdatedBeneficiary({ ...beneficiary, emails: [] });
  };

  const handleChangeOtherEmail = (event: any) => {
    const beneficiaryOtherEmail = event.target.value;
    setEmails([beneficiaryOtherEmail]);
    setUpdatedBeneficiary({ ...beneficiary, emails: [beneficiaryOtherEmail] });
  };

  return (
    <>
      <div className="tab-pane active">
        <div className="row gy-4">
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label w-100" htmlFor="name">
                {t('Name')}
              </label>
              <input
                type="text"
                className="form-control form-control-lg"
                id="name"
                value={name}
                onChange={handleChangeName}
                placeholder={t('IntroduceFullName')}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label" htmlFor="cif">
                {t('FiscalIdentification')}
              </label>
              <div className="form-control-wrap">
                <div className="form-icon form-icon-left">
                  <em className="icon ni ni-cc-alt2" />
                </div>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  id="cif"
                  value={cif}
                  onChange={handleChangeCif}
                  placeholder={t('EnterTheCifOrNif')}
                  required
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label w-100" htmlFor="email">
                {t('Email')}
              </label>
              <input
                type="email"
                className="form-control form-control-lg"
                id="email"
                value={email}
                onChange={handleChangeEmail}
                disabled={!isAdmin && user['_id'] !== beneficiary?.user}
                placeholder={t('IntroduceNewEmail')}
                pattern={EMAIL_PATTERN}
              />
            </div>

            {emails.length > 0 ? (
              <div className="form-control-wrap mb-3">
                <div className="input-group">
                  <input
                    type="email"
                    className="form-control form-control-lg"
                    value={emails[0]}
                    onChange={handleChangeOtherEmail}
                    placeholder={t('IntroduceNewEmail')}
                    pattern={EMAIL_PATTERN}
                  />

                  <div className="input-group-append">
                    <button
                      className="btn btn-outline-primary btn-dim px-1"
                      type="button"
                      onClick={handleRemoveEmail}
                    >
                      <em className="icon ni ni-minus" />
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <button
                type="button"
                className="btn btn-lg btn-dim btn-outline-primary w-100"
                disabled={emails.length > 0}
                onClick={handleAddEmail}
              >
                <em className="icon ni ni-plus" />
                <span>{t('AddAnotherEmail')}</span>
              </button>
            )}
            {!validEmails() && <InputError errorMessage={t('InvalidEmail')} />}
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label w-100" htmlFor="birth-day">
                {t('DateOfBirth')}
              </label>
              <input
                type="date"
                className="form-control form-control-lg date-picker"
                id="birth-day"
                value={birthdate}
                onChange={handleChangeBirthdate}
                placeholder={t('IntroduceBirthDate')}
                max="2100-01-01"
                min="1000-01-01"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label" htmlFor="job-title">
                {t('JobTitle')}
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  className="form-control form-control-lg text-nowrap overflow-hidden text-truncate"
                  id="job-title"
                  value={jobTitle}
                  onChange={handleChangeJobTitle}
                  placeholder={t('IntroduceJobTitle')}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label" htmlFor="employee-number">
                {t('EmployeeNumber')}
              </label>
              <div className="form-control-wrap">
                <input
                  type="text"
                  className="form-control form-control-lg text-nowrap overflow-hidden text-truncate"
                  id="employee-number"
                  value={employeeNumber}
                  onChange={handleChangeEmployeeNumber}
                  placeholder={t('IntroduceEmployeeNumber')}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label w-100" htmlFor="nationality">
                {t('Nationality')}
              </label>
              <input
                type="text"
                className="form-control form-control-lg text-nowrap overflow-hidden text-truncate"
                id="nationality"
                value={nationality}
                onChange={handleChangeNationality}
                placeholder={t('IntroduceNationality')}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="form-label w-100" htmlFor="phone-no">
                {t('Telephone')}
              </label>
              <input
                type="text"
                className="form-control form-control-lg text-nowrap overflow-hidden text-truncate"
                id="phone-no"
                value={phone}
                onChange={handleChangePhone}
                placeholder={t('IntroducePhoneNumber')}
              />
            </div>
          </div>
          <div className="col-md-3 col-8">
            <div className="form-group">
              <label className="form-label" htmlFor="imageInput">
                {t('Image')}
              </label>
              <input
                type="file"
                id="imageInput"
                style={{ display: 'none' }}
                onChange={handleChangeSelectedImage}
                accept="image/*"
              />
              <label
                htmlFor="imageInput"
                className="btn btn-primary form-control-lg"
              >
                {t('Choose')}
              </label>
            </div>
          </div>
          <div className="col-md-3 col-4">
            <div
              className="user-avatar mx-auto lg bg-primary-dim"
              style={{
                maxWidth: '80px',
                maxHeight: '80px',
              }}
            >
              {image ? (
                <img
                  id="user-image"
                  src={image}
                  alt={name}
                  style={{
                    maxWidth: '80px',
                    maxHeight: '80px',
                  }}
                />
              ) : (
                <span>{name ? name.slice(0, 2).toUpperCase() : ''}</span>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PersonalDataView;
