/* eslint-disable no-underscore-dangle */
import Record from 'typescript';

import store from 'redux/store';
import { getStockPlanById } from 'redux/actions/planActions';

import { redirectMarks, redirectUrls } from 'constants/redirectTypes';

interface RedirectUrlProps {
  prefix: string | undefined;
  redirectUrl: string | null;
}

const getRedirectURL = async (): Promise<RedirectUrlProps> => {
  let prefix: string | undefined;
  let redirectUrl: string | null = localStorage.getItem('redirect_url');

  const redirectUrlOld: string | null = localStorage.getItem('sign_redirect');
  const redirectSource: string | null = localStorage.getItem('redirect_source');

  if (redirectUrlOld) {
    /* NEW version of redirect on signed documents */
    const redirectUrlDecoded: string = decodeURIComponent(redirectUrlOld);

    const hasRedirectMarks: string | undefined = redirectMarks.find((mark) =>
      redirectUrlDecoded.includes(mark)
    );

    if (hasRedirectMarks) {
      const currentMark: string = hasRedirectMarks.replace(/=/g, '');
      const newUrlPrefix: string = redirectUrls[currentMark];
      const newUrlSuffix: string = redirectUrlDecoded.replace(
        hasRedirectMarks,
        ''
      );

      prefix = newUrlPrefix;
      redirectUrl = newUrlSuffix;
    } else {
      /* OLD version of redirect on signed documents */
      const containsBeneficiary: boolean = /beneficiario/gi.test(
        redirectUrlDecoded
      );
      const containsPartner: boolean = /socio/gi.test(redirectUrlDecoded);

      const prefixes: Record<string, any> = {
        tenderOfferParticipation: '#/participacion-tender-offer/',
        beneficiaryDetails: '#/detalle-beneficiario/',
        beneficiariesList: '#/beneficiarios/',
        partnerDetails: '#/detalle-socio/',
      };

      if (containsBeneficiary || containsPartner) {
        prefix = prefixes.tenderOfferParticipation;
      } else {
        prefix = prefixes.beneficiaryDetails;
      }

      /* This is an special case for Invitations sent out directly */
      if (redirectSource === 'docusign') {
        const constainSlashes: boolean = /\//gi.test(redirectUrlDecoded);
        const contentLength: number = redirectUrlDecoded.length;

        try {
          if (!constainSlashes && contentLength === 24) {
            const currentPlan = await store.dispatch(
              getStockPlanById(redirectUrlDecoded)
            );

            if (!currentPlan) {
              throw new Error('Plan not found');
            }

            prefix = prefixes.beneficiariesList;
            redirectUrl = `${currentPlan.society}/${currentPlan._id}`;
          } else {
            prefix = prefixes.beneficiaryDetails;
            redirectUrl = redirectUrlDecoded;
          }
        } catch (error) {
          if (!constainSlashes) {
            prefix = '#/';
            redirectUrl = '';
          }
        }
      }
      /* END of special case */
    }
  } else if (redirectUrl) {
    prefix = '#/';
  }

  return { prefix, redirectUrl };
};

export default getRedirectURL;
