/* eslint-disable react/display-name */
import screens from 'constants/screens';
import CellData from 'components/Tables/CellData';

function tableColumns(i18n) {
  return [
    {
      name: i18n.t('DraftConvertibleNote'),
      field: 'draftName',
      selector: (row) => row[i18n.t('DraftConvertibleNote')],
      sortable: true,
      grow: 2,
      cell: (row) =>
        CellData.Plan({
          name: row[i18n.t('DraftConvertibleNote')],
          url: `/detalle-inversor/${row.societyId}/${row.investorId}`,
          initials: 'NC',
        }),
      export: true,
    },
    {
      name: i18n.t('Society'),
      field: 'societyName',
      selector: (row) => row[i18n.t('Society')],
      sortable: true,
      center: true,
      compact: true,
      grow: 1,
      cell: (row) =>
        CellData.PlainText({
          text: i18n.t(`${row[i18n.t('Society')]}`),
        }),
      export: true,
    },
    {
      name: i18n.t('ContributionDate'),
      field: 'contributionDate',
      selector: (row) => row[i18n.t('ContributionDate')],
      sortable: true,
      center: true,
      compact: true,
      grow: 1,
      cell: (row) =>
        CellData.PlainText({
          text: row[i18n.t('ContributionDate')],
        }),
      export: true,
    },
    {
      name: i18n.t('Contribution'),
      field: 'contribution',
      selector: (row) => row[i18n.t('Contribution')],
      sortable: true,
      grow: 1,
      compact: true,
      center: true,
      hide: screens.LG,
      cell: (row) =>
        CellData.CurrencyDecimals({
          value: row[i18n.t('Contribution')],
        }),
      export: true,
    },
    {
      name: i18n.t('DiscountPercent'),
      field: 'discountPercent',
      selector: (row) => row[i18n.t('DiscountPercent')],
      sortable: true,
      compact: true,
      center: true,
      grow: 1,
      hide: screens.LG,
      cell: (row) =>
        CellData.PercentDecimals({
          value: row[i18n.t('DiscountPercent')],
          noPadding: false,
        }),
      export: true,
    },
  ];
}
export default tableColumns;
