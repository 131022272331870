/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMixpanel } from 'react-mixpanel-browser';

import { setModal } from 'redux/actions/modalActions';
import AddSocietyValue from 'components/Modals/AddSocietyValue';
import NumberFormat from 'react-number-format';
import { format } from 'date-fns';
import { currencyFormatDecimals, numberFormat } from 'constants/formats';
import { useDispatch, useSelector } from 'react-redux';
import MenuDots from 'components/MenuDots';
import { hasOutdatedSocietyValue } from 'utils/filters';
import menuOptions from './menuOptions';

const showDate = (date) => {
  if (typeof date === 'string') return format(new Date(date), 'dd/MM/yyyy');
  return format(date, 'dd/MM/yyyy');
};

const SocietyValues = ({ society, isAdmin, setPage }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();
  const user = useSelector((state) => state.user);
  const isDemo = useSelector((state) => state.society?.role?.isDemo);

  const [updatedValues, setUpdatedValues] = useState();
  const [showOutdatedWarning, setShowOutdatedWarning] = useState(false);

  useEffect(() => {
    const sortedValues = society?.societyValues
      ?.sort((a, b) => new Date(b.date) - new Date(a.date))
      ?.map((societyValue) => ({
        ...societyValue,
        totalShares: societyValue?.isPriceByShare
          ? (societyValue?.value || 0) / societyValue?.shareValue
          : '-',
      }));

    setUpdatedValues(sortedValues);
    if (society?.societyValues?.length > 0) {
      setShowOutdatedWarning(hasOutdatedSocietyValue(society));
    } else {
      setShowOutdatedWarning(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [society?.societyValues]);

  return (
    <div className="nk-block">
      <div className="nk-block-head">
        <div className="nk-block-head-content">
          <h5 className="nk-block-title title">{t('SocietyValues')}</h5>
          <div className="nk-block-des">
            <p>{t('SocietyValuesDescription')}</p>
          </div>
        </div>
      </div>

      <div className="nk-block">
        {isAdmin ? (
          <div className="nk-block-head">
            {showOutdatedWarning && (
              <div className="nk-block-head-content">
                <div
                  className="alert alert-fill alert-icon alert-danger mb-4"
                  role="alert"
                >
                  <em className="icon ni ni-alert-circle" />
                  <strong>{t('ValuationOutdated')}</strong>.&nbsp;
                  {t('ValuationOutdatedMessage')}
                </div>
              </div>
            )}
            <div className="nk-block-head-content">
              <button
                type="button"
                className="btn btn-success"
                onClick={() => {
                  dispatch(
                    setModal(
                      <AddSocietyValue
                        society={society}
                        setUpdatedValues={setUpdatedValues}
                        updatedValues={updatedValues}
                      />
                    )
                  );
                  setPage(4);
                }}
                disabled={isDemo}
              >
                {t('AddSocietyValue')}
              </button>
            </div>
          </div>
        ) : (
          <></>
        )}

        {updatedValues?.length > 0 && (
          <div className="card card-bordered card-preview w-100">
            <div className="table-responsive">
              <table className="nk-tb-list nk-tb-ulist">
                <thead>
                  <tr className="nk-tb-item nk-tb-head tb-tnx-head fs-11px">
                    <th className="nk-tb-col">#</th>
                    <th className="nk-tb-col text-center">{t('Date')}</th>
                    <th className="nk-tb-col text-right">
                      {t('SocietyValue')}
                    </th>
                    <th className="nk-tb-col text-right">{t('ShareValue')}</th>
                    <th className="nk-tb-col text-right">{t('TotalShares')}</th>
                    <th className="nk-tb-col text-center">{t('PriceShare')}</th>
                    <th className="nk-tb-col">
                      <span className="sub-text" />
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {updatedValues?.map((societyValue, index) => (
                    <tr>
                      <th className="text-center" scope="row">
                        {index + 1}
                      </th>

                      <td className="text-center">
                        {showDate(societyValue?.date)}
                      </td>

                      <td className="text-right">
                        <NumberFormat
                          value={societyValue?.value}
                          displayType="text"
                          {...currencyFormatDecimals}
                        />
                      </td>

                      <td className="text-right">
                        {societyValue?.shareValue ? (
                          <NumberFormat
                            value={societyValue?.shareValue}
                            displayType="text"
                            {...currencyFormatDecimals}
                            decimalScale={8}
                          />
                        ) : (
                          '-'
                        )}
                      </td>

                      <td className="text-right">
                        {societyValue?.isPriceByShare ? (
                          <NumberFormat
                            value={societyValue?.totalShares || 0}
                            displayType="text"
                            {...numberFormat}
                          />
                        ) : (
                          '-'
                        )}
                      </td>

                      <td className="text-center">
                        {societyValue?.isPriceByShare ? t('Yes') : t('Not')}
                      </td>

                      {isAdmin && !isDemo && (
                        <td className="nk-tb-col nk-tb-col-tools">
                          <ul className="nk-tb-actions gx-1">
                            <li>
                              <div className="dropdown">
                                <MenuDots
                                  menuOptions={menuOptions}
                                  id={societyValue['_id']}
                                  params={{
                                    user,
                                    societyId: society?.['_id'],
                                    societyName: society?.name,
                                    societyValue: societyValue?.value,
                                    societyValueId: societyValue['_id'],
                                    societyValueDate: societyValue?.date,
                                    mixpanel,
                                  }}
                                  direction="left"
                                />
                              </div>
                            </li>
                          </ul>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SocietyValues;
