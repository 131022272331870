import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ANADIR_SOCIO, SELECCIONAR_SOCIO } from 'constants/defaultConstants';

function UserDropdown({
  users = [],
  sharesCount = {},
  action,
  user,
  userData = false,
  enableAdd = false,
  showShares = false,
  returnAllData = false,
  directorsData= false,
}) {
  const { t } = useTranslation();

  const usersList = useMemo(
    () =>
      users.map((partner) => {
        const value = returnAllData
          ? JSON.stringify(partner)
          : JSON.stringify({
              id: partner['_id'],
              cif: partner?.cif,
            });

        let optionInfo;
        if (userData) {
          optionInfo = `${partner?.name} | ${partner?.email}`;
        } else if (directorsData) {
          optionInfo = `${partner?.socialDenomination} | ${partner?.email}`;
        } else {
          let userName = partner.name;
          if (showShares) {
            if (Object.keys(sharesCount).length > 0) {
              userName += ` | ${sharesCount[partner['_id']]} ${t('shares')}`;
            } else {
              userName += ` | ${partner.sharesCount.actual} ${t('shares')}`;
            }
          }
          optionInfo = `${partner.cif} | ${userName}`;
        }

        return (
          <option value={value} key={value}>
            {optionInfo}
          </option>
        );
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [users, sharesCount, showShares, returnAllData, userData, t]
  );

  return (
    <select
      className="form-control"
      onChange={(event) => action(event.target.value)}
      required
      value={user}
    >
      <option>{SELECCIONAR_SOCIO}</option>
      {usersList}
      {enableAdd && (
        <>
          <option disabled>─────────────────</option>
          <option>{ANADIR_SOCIO}</option>
        </>
      )}
    </select>
  );
}

export default UserDropdown;
