/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/config';

import { EMAIL_PATTERN } from 'constants/defaultConstants';

import sendEmail from 'redux/actions/mailActions';
import { setModal } from 'redux/actions/modalActions';
import { updateUser } from 'redux/actions/userActions';
import { updateSociety } from 'redux/actions/societyActions';

import '../Modals/Modals.scss';

function AddAdminModal() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const actualSociety = useSelector((state) => state.society?.actualSociety);

  const [email, setEmail] = useState('');

  const sendNotificationEmail = () => {
    const emailData = {
      to: [
        {
          email,
          name: 'Administrador',
        },
      ],
      subject: t('MailAddSocietyAdminSubject'),
      template: `add-administrator-${i18n.language}`,
      templateData: [
        {
          name: 'mainAdminName',
          content: user?.name || '',
        },
        {
          name: 'newAdminEmail',
          content: email,
        },
        {
          name: 'newAdminName',
          content: '',
        },
        {
          name: 'societyName',
          content: actualSociety?.name || '',
        },
        {
          name: 'targetUrl',
          content: `${process.env.REACT_APP_DOMAIN}/#/sociedad/${actualSociety?._id}`,
        },
      ],
      sender: {
        name: `Sttok | ${actualSociety?.name}`,
        email: 'info@sttok.com',
      },
      user: user['_id'],
      society: actualSociety['_id'],
      date: new Date(),
    };

    dispatch(sendEmail(emailData));
  };

  async function savePartner(event) {
    event.preventDefault();

    await dispatch(
      updateSociety(
        actualSociety['_id'],
        {
          admin: {
            add: email,
          },
        },
        false
      )
    );

    await dispatch(
      updateUser(
        null,
        {
          admin: { add: email },
          society: actualSociety['_id'],
        },
        user['_id'],
        false
      )
    );

    sendNotificationEmail();
    dispatch(setModal(null));
  }

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title">{t('AddAdmin')}</h5>
        <a
          className="close cursor-pointer"
          onClick={() => dispatch(setModal(null))}
        >
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
      <Modal.Body>
        <form className="form-validate is-alter" onSubmit={savePartner}>
          <div className="row mb-4">
            <div className="col">
              <div className="form-group">
                <label className="form-label" htmlFor="email-address">
                  {t('Email')}
                </label>
                <div className="form-control-wrap">
                  <div className="form-icon form-icon-left">
                    <em className="icon ni ni-mail" />
                  </div>
                  <input
                    type="email"
                    className="form-control"
                    placeholder={t('EmailOfTheNewAdmin')}
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    pattern={EMAIL_PATTERN}
                  />
                </div>
              </div>
            </div>
          </div>

          <button type="submit" className="btn btn-lg btn-primary">
            {t('AddAdmin')}
          </button>
        </form>
      </Modal.Body>
      <Modal.Footer className="bg-light">
        <span className="sub-text">{t('TheAdminWillReceive')}</span>
      </Modal.Footer>
    </>
  );
}

export default AddAdminModal;
