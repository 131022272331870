/* eslint-disable no-console */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import store from 'redux/store';
import { setModal } from 'redux/actions/modalActions';
import { updateBeneficiary } from 'redux/actions/beneficiaryActions';

import { getActualSociety } from 'utils/filters';
import invitationStatus from 'constants/invitationStatus';

import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import Swal from 'sweetalert2';

import '../Modals.scss';

function EditLetterStatusModal({ beneficiary, societyId }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const animatedComponents = makeAnimated();
  const user = useSelector((state) => state.user);

  const [invitationStatusSelected, setInvitationStatusSelected] = useState({
    value: beneficiary?.invitationStatus,
    label: invitationStatus[beneficiary?.invitationStatus].text,
  });
  const [currentInvitationStatus, setInvitationStatus] = useState(
    beneficiary?.invitationStatus
  );
  const [assumeCkeckbox, setAssumeCheckbox] = useState(false);

  const invitationStatusOptions = Object.values(invitationStatus).map(
    (status) => ({
      value: status.value,
      label: status.text,
    })
  );

  const handleSelectChange = (selectedOptions) => {
    setInvitationStatusSelected(selectedOptions);
    setInvitationStatus(selectedOptions.value);
  };

  async function handleSaveInvitationStatus() {
    const updatedBeneficiary = {
      invitationStatus: currentInvitationStatus,
    };

    await store.dispatch(
      updateBeneficiary({
        id: beneficiary['_id'],
        data: {
          ...updatedBeneficiary,
          userId: user['_id'],
          societyId,
          cascade: false,
        },
        showAlert: false,
      })
    );

    await getActualSociety(user, societyId);
    Swal.fire({
      icon: 'success',
      title: `<h4 class="nk-modal-title">${t(
        'SuccessfullyEditedStatusLetter'
      )}<br></h4>`,
      confirmButtonText: t('OK'),
      allowOutsideClick: false,
      showCancelButton: false,
      confirmButtonColor: '#6576FF',
    });
    dispatch(setModal(null));
  }

  return (
    <>
      <form
        className="form-validate is-alter"
        onSubmit={handleSaveInvitationStatus}
      >
        <Modal.Header
          style={{ borderBottom: 'none', padding: '36px 40px 0 40px' }}
        >
          <h5 className="modal-title">{t('EditLetterStatus')}</h5>
          <a
            className="close cursor-pointer"
            onClick={() => dispatch(setModal(null))}
          >
            <em className="icon ni ni-cross" />
          </a>
        </Modal.Header>
        <Modal.Body style={{ margin: '0px 16px 0px 16px' }}>
          <div
            className="form-group"
            style={{ position: 'relative', zIndex: 2 }}
          >
            <div className="form-control-wrap">
              <Select
                closeMenuOnSelect
                className="react-select"
                options={invitationStatusOptions}
                components={animatedComponents}
                onChange={handleSelectChange}
                value={invitationStatusSelected}
              />
            </div>
          </div>
          <div
            className="form-group"
            style={{ position: 'relative', zIndex: 1 }}
          >
            <div className="custom-control custom-control-xs custom-checkbox">
              <input
                type="checkbox"
                id="checkbox-notifications"
                className="custom-control-input"
                value={assumeCkeckbox}
                checked={assumeCkeckbox}
                onChange={(event) => setAssumeCheckbox(event.target.checked)}
              />
              <label
                className="custom-control-label"
                htmlFor="checkbox-notifications"
              >
                {t('EditLetterStatusCheckboxMessage')}
              </label>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer
          style={{ borderTop: 'none', padding: '0px 40px 36px 40px' }}
        >
          <button
            type="submit"
            className="btn btn-lg btn-primary"
            disabled={!currentInvitationStatus || !assumeCkeckbox}
          >
            {t('Save')}
          </button>
        </Modal.Footer>
      </form>
    </>
  );
}

export default EditLetterStatusModal;
