/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../../assets/styles/Layouts.scss';
import './Footer.scss';

const languages = {
  es: { displayName: 'ES' },
  en: { displayName: 'EN' },
  fr: { displayName: 'FR' },
};

function Footer() {
  const { t, i18n } = useTranslation();

  return (
    <div className="nk-footer footer">
      <div className="container wide-xl">
        <div className="nk-footer-wrap g-2">
          <div className="nk-footer-copyright">
            {' '}
            &copy; <Link to="/">Sttok</Link>
          </div>
          <div className="nk-footer-links">
            <ul className="nav nav-sm">
              <li className="nav-item d-flex flex-row">
                {Object.keys(languages).map((lng, index) => (
                  <div key={`${index}-${lng}`}>
                    <span
                      className="nav-link text-primary cursor-pointer"
                      style={{
                        fontWeight:
                          i18n.resolvedLanguage === lng ? 'bold' : 'normal',
                      }}
                      onClick={() => i18n.changeLanguage(lng)}
                    >
                      {languages[lng].displayName}
                    </span>
                    {index < Object.keys(languages).length - 1 && (
                      <span key={`divider-${lng}`}> | </span>
                    )}
                  </div>
                ))}
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://www.sttok.com/aviso-legal/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('LegalWarning')}
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://www.sttok.com/privacidad/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('Privacy')}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
