import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  onApplyChanges: any;
  onResetValues: any;
};

function SocietyConfigurationHeaderButtons(props: Props) {
  const { t } = useTranslation();

  const { onApplyChanges, onResetValues } = props;

  return (
    <div className="nk-block-head-content d-flex justify-content-between">
      <button
        type="button"
        className="btn btn-success"
        onClick={onApplyChanges}
      >
        {t('ApplyChanges')}
      </button>

      <button type="button" className="btn btn-light" onClick={onResetValues}>
        {t('ResetValues')}
      </button>
    </div>
  );
}

export default SocietyConfigurationHeaderButtons;
