import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AsyncTypeahead, ClearButton } from 'react-bootstrap-typeahead';
import { useMixpanel } from 'react-mixpanel-browser';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import store from 'redux/store';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import {
  createSociety as createSocietyAction,
  getSocietiesRegistered,
} from 'redux/actions/societyActions';
import { setModal } from 'redux/actions/modalActions';
import trackEvent from 'utils/trackEvent';
import eventTypes from 'constants/eventTypes';
import sizes from 'constants/sizes';
import '../../assets/styles/Misc.scss';
import './SocietiesAdd.scss';

import AddSocietyAI from '../../components/OpenAI/AddSociety';
import starsIcon from '../../assets/images/icon-stars.png';

const showDetails = JSON.parse(localStorage.getItem('showDetails'));

const SocietiesAdd = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();
  const { user } = useSelector((state) => state);

  const [socialDenomination, setSocialDenomination] = useState('');
  const [CIF, setCIF] = useState('');
  const [line1, setLine1] = useState('');
  const [zip, setZip] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [sendNotifications, setSendNotifications] = useState(true);
  const [hasAccessCaptable, setHasAccessCaptable] = useState(false);
  const [isSocietyHolding, setIsSocietyHolding] = useState(true);

  const [file, setFile] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const [societiesList, setSocietiesList] = useState([]);

  const [textFromSociety, setTextFromSociety] = useState('');

  const animatedComponents = makeAnimated();

  const selectedLanguage = useRef('es');
  const [selectedLanguageOption, setSelectedLanguageOption] = useState({
    value: 'es',
    label: t('Spanish'),
  });

  const languageOption = [
    {
      value: 'es',
      label: t('Spanish'),
    },
    {
      value: 'en',
      label: t('English'),
    },
  ];

  const handleLanguageOptionChange = (selectedOption) => {
    setSelectedLanguageOption(selectedOption);
    selectedLanguage.current = selectedOption.value;
  };

  const createSociety = async (event) => {
    event.preventDefault();

    const society = {
      name: socialDenomination,
      cif: CIF,
      legalAddress: {
        line1,
        zip,
        city,
        country,
      },
      creationDate: new Date(),
      mainAdmin: user['_id'],
      administrators: [user['_id']],
      sendNotifications,
      hasAccessCaptable,
      isSocietyHolding,
      societyLanguage: selectedLanguage.current,
    };
    const newSociety = await store.dispatch(createSocietyAction(society));

    const eventData = {
      operation: eventTypes.ADD_SOCIETY,
      societyName: socialDenomination,
      societyCIF: CIF,
      notifications: sendNotifications,
      userId: user['_id'],
      userName: user?.name,
      userEmail: user?.email,
    };

    trackEvent(mixpanel, eventData.operation, eventData);

    history.push(`/socios/${newSociety?.['_id']}`);
  };

  const handleChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSelectCompany = (society) => {
    if (society?.customOption) {
      setSocialDenomination(society.label);
    } else {
      const { name, address } = society?.nameAddress;
      const { registeredOfficeCode } = society?.registeredOffice;

      setSocialDenomination(name);
      setCIF(registeredOfficeCode);
      setLine1(address.street);
      setZip(address.postCode);
      setCity(address.city);
      setCountry(address.country);
    }
    setSocietiesList([]);
    setTextFromSociety('');
  };

  const clearSelectCompany = () => {
    setSocialDenomination('');
    setCIF('');
    setLine1('');
    setZip('');
    setCity('');
    setCountry('');
  };

  const handleAutoCompleteBlur = () => {
    if (textFromSociety) {
      handleSelectCompany({
        customOption: true,
        label: textFromSociety,
        id: 'new-id-1',
      });
    }
  };

  useEffect(() => {
    if (file) dispatch(setModal(<AddSocietyAI file={file} size={sizes.XXL} />));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  return (
    <>
      <div className="nk-content-body">
        <div className="nk-content-wrap">
          <div className="components-preview wide-md mx-auto">
            <div className="nk-block-head nk-block-head-lg wide-sm">
              <div className="nk-block-head-content">
                <h2 className="nk-block-title fw-normal">{t('AddSociety')}</h2>
                <div className="nk-block-des">
                  <p className="lead">{t('AddASocietyWithTheFollowingForm')}</p>
                </div>
              </div>
            </div>
            <div className="nk-block nk-block-lg">
              <div className="nk-block-head">
                <div className="nk-block-head-content">
                  <h4 className="title nk-block-title">
                    {t('FormToRegisterASociety')}
                  </h4>
                </div>
              </div>
              <div className="card card-bordered">
                <div className="card-inner">
                  <div className="content clearfix">
                    <div
                      className={`nk-wizard-head current'}`}
                      id="steps-uid-0-h-0"
                      tabIndex="-1"
                    >
                      <h5>{t('Step1-BasicInfo')}</h5>
                    </div>
                    <div
                      className="nk-wizard-content body current"
                      id="steps-uid-0-p-0"
                      role="tabpanel"
                      aria-labelledby="steps-uid-0-h-0"
                      aria-hidden="false"
                    >
                      <div className="row gy-3">
                        <div className="col-md-6">
                          <label
                            className="form-label w-100"
                            htmlFor="fw-mobile-number"
                          >
                            {t('SocialDenomination')}
                            <AsyncTypeahead
                              id="societies-search-box"
                              isLoading={isLoading}
                              labelKey="label"
                              minLength={3}
                              onSearch={(query) => {
                                setIsLoading(true);
                                setTextFromSociety(query);
                                getSocietiesRegistered(query).then(
                                  (societies) => {
                                    setSocietiesList(societies);
                                  }
                                );
                                setIsLoading(false);
                              }}
                              onChange={([society]) => {
                                if (society) {
                                  handleSelectCompany(society);
                                } else {
                                  clearSelectCompany();
                                }
                              }}
                              onBlur={handleAutoCompleteBlur}
                              options={societiesList}
                              useCache={false}
                              defaultInputValue={socialDenomination}
                              newSelectionPrefix={`${t('Select:')} `}
                              allowNew
                            >
                              {({ onClear, selected }) => (
                                <div className="rbt-aux">
                                  {!!selected.length && (
                                    <ClearButton onClick={onClear} />
                                  )}
                                </div>
                              )}
                            </AsyncTypeahead>
                          </label>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label
                              className="form-label w-100"
                              htmlFor="fw-mobile-number"
                            >
                              {t('Cif')}
                              <div className="form-control-wrap">
                                <input
                                  required
                                  type="text"
                                  className="form-control "
                                  value={CIF}
                                  onChange={(event) =>
                                    setCIF(event.target.value)
                                  }
                                />
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label
                              className="form-label w-100"
                              htmlFor="fw-username"
                            >
                              {t('Address')}
                              <div className="form-control-wrap">
                                <input
                                  type="text"
                                  className="form-control required"
                                  value={line1}
                                  onChange={(event) =>
                                    setLine1(event.target.value)
                                  }
                                />
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label
                              className="form-label w-100"
                              htmlFor="fw-username"
                            >
                              {t('PostalCode')}
                              <div className="form-control-wrap">
                                <input
                                  type="text"
                                  maxLength="5"
                                  minLength="5"
                                  className="form-control"
                                  value={zip}
                                  onChange={(event) =>
                                    setZip(event.target.value)
                                  }
                                />
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label
                              className="form-label w-100"
                              htmlFor="fw-username"
                            >
                              {t('City')}
                              <div className="form-control-wrap">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={city}
                                  onChange={(event) =>
                                    setCity(event.target.value)
                                  }
                                />
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label
                              className="form-label w-100"
                              htmlFor="fw-username"
                            >
                              {t('Country')}
                              <div className="form-control-wrap">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={country}
                                  onChange={(event) =>
                                    setCountry(event.target.value)
                                  }
                                />
                              </div>
                            </label>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label
                              className="form-label w-100"
                              htmlFor="fw-username"
                            >
                              {t('CommunicationsLanguage')}
                            </label>

                            <div className="form-control-wrap">
                              <Select
                                closeMenuOnSelect
                                className="react-select"
                                value={selectedLanguageOption}
                                options={languageOption}
                                components={animatedComponents}
                                onChange={handleLanguageOptionChange}
                              />
                            </div>
                          </div>
                        </div>

                        {showDetails && (
                          <div className="col-md-12 my-4">
                            <div className="card card-bordered card-preview">
                              <div className="card-inner">
                                <div className="d-flex gx-8 align-items-center">
                                  <img
                                    src={starsIcon}
                                    alt=""
                                    width={20}
                                    height={26}
                                  />
                                  <p className="mx-2 mb-0">
                                    O puedes subir una escritura en formato
                                    &nbsp;<b>.doc/.docx/.pdf</b> y extraeremos
                                    los datos utilizando IA
                                  </p>
                                  <input
                                    type="file"
                                    onChange={handleChange}
                                    className="rounded p-1"
                                    accept=".doc,.docx,.pdf"
                                    style={{
                                      height: '40px',
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        <div className="col-md-6">
                          <div className="form-group mt-2">
                            <div className="custom-control custom-control-xs custom-checkbox">
                              <input
                                type="checkbox"
                                id="checkbox-notifications"
                                className="custom-control-input"
                                value={!sendNotifications}
                                checked={!sendNotifications}
                                onChange={() =>
                                  setSendNotifications(!sendNotifications)
                                }
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="checkbox-notifications"
                              >
                                {t('DoNotNotifyThePartners')}
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group mt-2">
                            <div className="custom-control custom-control-xs custom-checkbox">
                              <input
                                type="checkbox"
                                id="checkbox-access-captable"
                                className="custom-control-input"
                                value={hasAccessCaptable}
                                checked={hasAccessCaptable}
                                onChange={() =>
                                  setHasAccessCaptable(!hasAccessCaptable)
                                }
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="checkbox-access-captable"
                              >
                                {t('PartnersWithAccessCaptable')}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <button
                      type="button"
                      className="btn btn-primary mt-4"
                      onClick={createSociety}
                      disabled={!CIF || !socialDenomination}
                    >
                      {t('AddSociety')}
                    </button>
                    <div style={{ paddingTop: '10px' }}>
                      <label>
                        {t('IAgree')}{' '}
                        <a
                          href="https://www.sttok.com/privacidad/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {t('PrivacyPolicy')}
                        </a>{' '}
                        {t('and')}{' '}
                        <a
                          href="https://www.sttok.com/aviso-legal/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {t('TermsAndConditions')}
                        </a>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SocietiesAdd;
