import types from '../actions/actionTypes';
import initialState from '../initialState';

export default function reducer(documents = initialState.documents, action) {
  switch (action.type) {
    case types.ADD_DOCUMENT:
      return [...documents, action.documents];

    case types.SET_DOCUMENTS:
      return action.documents;

    case types.UPDATE_DOCUMENT:
      return documents.map((doc) => {
        // eslint-disable-next-line no-underscore-dangle
        if (doc._id === action.document._id) {
          return action.document;
        }
        return doc;
      });

    case types.DELETE_DOCUMENT:
      return documents.filter((doc) => doc.id !== action.id);

    default:
      return documents;
  }
}
