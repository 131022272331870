/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-new */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import diagramTypes from 'constants/diagramTypes';

import { getUserSocieties } from 'redux/actions/societyActions';

import { countSocietyShares } from 'utils/getShareCount';
import {
  getUserSocietyPercent,
  getUserSocietyPercentFD,
  getUserSocietyShares,
} from 'utils/filters';

import CustomLoading from 'components/CustomLoading';
import OrganizationChart from 'components/OrganizationChart';
import ToggleWidthButton from 'components/Buttons/ToggleWidthButton';

import CompanyLogo from 'assets/images/logo-company.png';

const ParticipatedDiagram = () => {
  const { t } = useTranslation();
  const user = useSelector((state) => state?.user);

  const [participatedSocieties, setParticipatedSocieties] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const mainSociety = useRef(null);
  const updatedSocieties = useRef([]);

  function getSecondLevelSocieties() {
    const result = {};
    // For each society where you are partner get the partnered societies.
    participatedSocieties?.forEach((societyOutside) => {
      participatedSocieties?.forEach((societyInside) => {
        // If is not the same society.
        if (societyOutside['_id'] !== societyInside['_id']) {
          // societyOutside is partner of societyInside.
          const isPartner = societyInside?.partners?.find(
            (partner) => partner?.cif === societyOutside?.cif
          );

          if (isPartner) {
            // If societyInside has been added to result, add a new holdingReference.
            if (societyInside['_id'] in result) {
              result[societyInside['_id']]?.holdingReference.push(
                societyOutside['_id']
              );
            }
            // If societyInside has not been added to result, add it.
            else {
              result[societyInside['_id']] = {
                holdingId: societyInside?.['_id'],
                holdingName: societyInside?.name,
                holdingCIF: societyInside?.cif,
                holdingLogo: societyInside?.additional?.logo || CompanyLogo,
                holdingShares: societyInside?.userShares,
                holdingPercentND: societyInside?.userSharesPercent,
                holdingPercentFD: societyInside?.userFdPercent,
                holdingReference: [societyOutside['_id']],
                holdingReferenceLevel: 1,
                holdingKey: uuidv4(),
              };
            }
          }
        }
      });
    });
    return result;
  }

  useEffect(() => {
    if (user) {
      getUserSocieties(
        user['_id'],
        'partner',
        setParticipatedSocieties,
        'chart'
      ).then((result) => {
        if (!result?.length) {
          setIsLoading(false);
        }
      });
    }
  }, [user]);

  useEffect(() => {
    if (participatedSocieties?.length) {
      const societiesWithHoldingInfo = participatedSocieties?.map(
        (society) => ({
          holdingId: society?.['_id'],
          holdingName: society?.name,
          holdingCIF: society?.cif,
          holdingLogo: society?.additional?.logo || CompanyLogo,
          holdingShares: society?.userShares,
          holdingPercentND: society?.userSharesPercent,
          holdingPercentFD: society?.userFdPercent,
          holdingReference: [user['_id']],
          holdingReferenceLevel: 0,
          holdingKey: uuidv4(),
        })
      );

      const secondLevelSocieties = getSecondLevelSocieties();
      updatedSocieties.current = [
        societiesWithHoldingInfo,
        Object.values(secondLevelSocieties),
      ];

      mainSociety.current = {
        _id: user?.['_id'],
        name: user?.name,
        additional: { logo: user?.image },
        userType: true,
        email: user?.email,
      };
      setIsLoading(false);
    } else {
      updatedSocieties.current = [];
    }
  }, [participatedSocieties]);

  return (
    <>
      <div className="nk-content-body">
        <div className="nk-content-wrap">
          <div className="nk-block-head">
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <h3 className="nk-block-title page-title">
                  {t('ParticipatedTitle')}
                </h3>
                <div className="nk-block-des text-soft">
                  <p>
                    {participatedSocieties?.length === 0
                      ? t('ParticipatedNoSocieties')
                      : t('ParticipatedHaveSocieties', {
                          count: participatedSocieties?.length,
                        })}
                  </p>
                </div>
              </div>
              <div className="nk-block-head-content">
                <ul className="nk-block-tools g-3">
                  <li>
                    <Link to="/cuadro-participadas">
                      <button type="button" className="btn btn-primary">
                        <em className="icon ni ni-menu-squared" />
                        <span>{t('Cards')}</span>
                      </button>
                    </Link>
                  </li>
                  <li>
                    <Link to="/lista-participadas">
                      <button type="button" className="btn btn-primary">
                        <em className="icon ni ni-view-list-wd" />
                        <span>{t('Table')}</span>
                      </button>
                    </Link>
                  </li>
                  <li>
                    <ToggleWidthButton variant="light" hideMenu />
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {updatedSocieties.current?.length > 0 && (
            <OrganizationChart
              actualSociety={{ ...mainSociety.current, holdingKey: uuidv4() }}
              societies={updatedSocieties.current}
              diagramType={diagramTypes.PARTNER}
            />
          )}
        </div>
      </div>
      {isLoading && <CustomLoading />}
    </>
  );
};

export default ParticipatedDiagram;
