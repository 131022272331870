/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-new */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { v4 as uuidv4 } from 'uuid';

import diagramTypes from 'constants/diagramTypes';

import { getUserSocieties } from 'redux/actions/societyActions';

import CustomLoading from 'components/CustomLoading';
import OrganizationChart from 'components/OrganizationChart';
import ToggleWidthButton from 'components/Buttons/ToggleWidthButton';

import CompanyLogo from 'assets/images/logo-company.png';

const AdministratedDiagram = () => {
  const { t } = useTranslation();
  const user = useSelector((state) => state?.user);

  const mainSociety = useRef(null);
  const updatedSocieties = useRef([]);
  const [isLoading, setIsLoading] = useState(true);
  const [administratedSocieties, setAdministratedSocieties] = useState([]);

  const getSocietyLogo = (society) => society?.additional?.logo || CompanyLogo;

  const getSecondLevelSocieties = () => {
    const result = {};
    // For each society where you are administrator get the partnered societies.
    administratedSocieties?.forEach((societyOutside) => {
      administratedSocieties?.forEach((societyInside) => {
        // If is not the same society.
        if (societyOutside['_id'] !== societyInside['_id']) {
          // societyOutside is partner of societyInside.
          const isPartner = societyInside?.partners?.find(
            (partner) => partner?.cif === societyOutside?.cif
          );

          if (isPartner) {
            // If societyInside has been added to result, add a new holdingReference.
            if (societyInside['_id'] in result) {
              result[societyInside['_id']]?.holdingReference.push(
                societyOutside['_id']
              );
            }
            // If societyInside has not been added to result, add it.
            else {
              result[societyInside['_id']] = {
                holdingId: societyInside?.['_id'],
                holdingName: societyInside?.name,
                holdingCIF: societyInside?.cif,
                holdingLogo: getSocietyLogo(societyInside),
                holdingValue: societyInside?.societyValue,
                holdingPartners: societyInside?.partnersCount,
                holdingReference: [societyOutside['_id']],
                holdingReferenceLevel: 1,
                holdingKey: uuidv4(),
              };
            }
          }
        }
      });
    });
    return result;
  };

  useEffect(() => {
    if (user) {
      getUserSocieties(
        user['_id'],
        'admin',
        setAdministratedSocieties,
        'chart'
      ).then((result) => {
        if (!result?.length) {
          setIsLoading(false);
        }
      });
    }
  }, [user]);

  useEffect(() => {
    if (administratedSocieties?.length) {
      const societiesWithHoldingInfo = administratedSocieties?.map(
        (society) => ({
          holdingId: society?.['_id'],
          holdingName: society?.name,
          holdingCIF: society?.cif,
          holdingLogo: getSocietyLogo(society),
          holdingValue: society?.societyValue,
          holdingPartners: society?.partnersCount,
          holdingReference: [user['_id']],
          holdingReferenceLevel: 0,
          holdingKey: uuidv4(),
        })
      );

      const secondLevelSocieties = getSecondLevelSocieties();

      // Do not show second level societies in the first level.
      const firstLevelSocieties = societiesWithHoldingInfo.filter(
        (elemet) => !(elemet.holdingId in secondLevelSocieties)
      );

      updatedSocieties.current = [
        firstLevelSocieties,
        Object.values(secondLevelSocieties),
      ];

      mainSociety.current = {
        _id: user?.['_id'],
        name: user?.name,
        additional: { logo: user?.image },
        userType: true,
        email: user?.email,
      };
      setIsLoading(false);
    } else {
      updatedSocieties.current = [];
    }
  }, [administratedSocieties]);

  return (
    <>
      <div className="nk-content-body">
        <div className="nk-content-wrap">
          <div className="nk-block-head">
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <h3 className="nk-block-title page-title">
                  {t('AdministratedTitle')}
                </h3>
                <div className="nk-block-des text-soft">
                  <p>
                    {administratedSocieties?.length === 0
                      ? t('AdministratedNoSocieties')
                      : t('AdministratedHaveSocieties', {
                          count: administratedSocieties?.length,
                        })}
                  </p>
                </div>
              </div>
              <div className="nk-block-head-content">
                <ul className="nk-block-tools g-3">
                  <li>
                    <Link to="/cuadro-administradas">
                      <button type="button" className="btn btn-primary">
                        <em className="icon ni ni-menu-squared" />
                        <span>{t('Cards')}</span>
                      </button>
                    </Link>
                  </li>
                  <li>
                    <Link to="/lista-administradas">
                      <button type="button" className="btn btn-primary">
                        <em className="icon ni ni-view-list-wd" />
                        <span>{t('Table')}</span>
                      </button>
                    </Link>
                  </li>
                  <li>
                    <ToggleWidthButton variant="light" hideMenu />
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {updatedSocieties.current?.length > 0 && (
            <OrganizationChart
              actualSociety={{ ...mainSociety.current, holdingKey: uuidv4() }}
              societies={updatedSocieties.current}
              diagramType={diagramTypes.ADMIN}
            />
          )}
        </div>
      </div>
      {isLoading && <CustomLoading />}
    </>
  );
};

export default AdministratedDiagram;
