import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  formatCurrencyDecimals,
  formatNumber,
  formatNumberDecimals,
} from 'utils/formats';

function BeneficiaryTenderOffersTabRow({
  index,
  rowKey,
  units,
  totalPriceUnits,
  soldUnits,
  totalPriceSoldUnits,
  status,
  societyId,
  tenderOfferId,
  beneficiaryId,
  retentionPercent,
  netAmount,
}) {
  const { t } = useTranslation();

  const handleNavigateToTenderOffer = () => {
    window.location.href = `#/participacion-tender-offer/${societyId}/${tenderOfferId}/beneficiario/${beneficiaryId}`;
  };

  return (
    <>
      <tr
        key={rowKey}
        onClick={handleNavigateToTenderOffer}
        className="cursor-pointer"
      >
        <td className="fw-bold d-none d-md-table-cell">{index}</td>

        <td className="text-right d-none d-md-table-cell">
          {formatNumber(units)}
        </td>

        <td className="text-right d-none d-md-table-cell">
          {formatCurrencyDecimals(totalPriceUnits)}
        </td>

        <td className="text-right">
          {formatCurrencyDecimals(totalPriceSoldUnits)}
        </td>

        <td className="text-right d-none d-md-table-cell">
          {formatNumberDecimals(retentionPercent)}
        </td>

        <td className="text-right d-none d-md-table-cell">
          {formatCurrencyDecimals(netAmount)}
        </td>

        <td className="text-right">{formatNumber(soldUnits)}</td>

        <td className="text-left">
          <span className={`badge badge-check ${status?.style}`}>
            {t(status?.value) || ''}
          </span>
        </td>
      </tr>
    </>
  );
}

export default BeneficiaryTenderOffersTabRow;
