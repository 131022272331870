import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Editor } from '@tinymce/tinymce-react';
import '../EditorWYSIWYG/EditorWYSIWYG.scss';

const ReadOnlyEditor = ({ editorContent }) => {
  const { i18n } = useTranslation();
  const editorRef = useRef(null);
  const [editorLanguage, setEditorLanguage] = useState();

  useEffect(() => {
    setEditorLanguage(i18n.language === 'es' ? 'es' : 'en_US');
  }, [i18n.language]);

  React.useEffect(() => {
    if (editorContent) {
      editorRef?.current?.setContent(editorContent);
    }
  }, []);

  return (
    <>
      <Editor
        apiKey={process.env.REACT_APP_TINY_EDITOR_KEY}
        value={editorContent}
        onInit={async (_evt, editor) => {
          editorRef.current = editor;
        }}
        init={{
          width: 940,
          height: 842,
          selector: 'textarea',
          menubar: false,
          toolbar: false,
          font_formats:
            'Arial=arial,helvetica,sans-serif;Comic Sans MS=comic sans ms,sans-serif;Courier New=courier new,courier,monospace;Georgia=georgia,palatino,serif',
          fontsize_formats: '8pt 10pt 12pt 14pt 18pt 24pt 36pt',
          content_style:
            'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
          language: editorLanguage,
          readonly: true,
          resize: false,
        }}
      />
    </>
  );
};

export default ReadOnlyEditor;
