/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import i18n from 'i18n/config';

import documentTypes from 'constants/documentTypes';

import MenuDots from 'components/MenuDots';

import menuOptions from './menuOptions';
import NoDocumentsAlert from '../List/NoDocumentsAlert';
import folders from '../../../assets/images/icon-sections/folders.svg';

import './CouncilMinute.scss';

const CouncilMinute = ({ setCurrentCouncilMinute, section }) => {
  const { t } = useTranslation();

  const actualSociety = useSelector((state) => state.society?.actualSociety);
  const documents = useSelector((state) => state.documents);
  const councilMinutes = useSelector(
    (state) => state.society?.actualSociety?.councilMinutes
  );
  const isDemo = useSelector((state) => state.society?.role?.isDemo);

  const [currentMenuOptions] = useState(menuOptions(i18n));

  const handleClick = (councilMinute) => {
    if (setCurrentCouncilMinute) {
      setCurrentCouncilMinute(councilMinute);
    }
  };

  const getDocumentsCountInCouncilMinute = (id) => {
    const documentsInCouncilMinute = documents.filter(
      (document) =>
        document?.category === documentTypes.COUNCILMINUTES &&
        document?.subcategory === id
    ).length;

    const suffix =
      documentsInCouncilMinute === 1 ? t('document') : t('documents');
    return `${documentsInCouncilMinute} ${suffix}`;
  };

  return (
    <div className="nk-fmg-quick-list nk-block">
      <div className="nk-block-head-xs">
        <div className="nk-block-between g-2">
          <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">{t('CouncilMinutes')}</h3>
          </div>
        </div>
      </div>

      {councilMinutes?.length > 0 ? (
        <>
          <div
            className="toggle-expand-content expanded"
            data-content="quick-access"
          >
            <div className="nk-files nk-files-view-grid">
              <div className="nk-files row">
                {councilMinutes &&
                  councilMinutes.map((councilMinute) => (
                    <div className="col-6 col-sm-6 col-md-4 col-xl-3">
                      <div
                        key={councilMinute['_id']}
                        className="nk-file-item nk-file cursor-pointer w-100 h-100 mx-0"
                      >
                        <div onClick={() => handleClick(councilMinute)}>
                          <div className="nk-file-info">
                            <div className="nk-file-link">
                              <div className="nk-file-title">
                                <div className="nk-file-icon">
                                  <span className="nk-file-icon-type">
                                    <img src={folders} alt="section" />
                                  </span>
                                </div>
                                <div className="nk-file-name">
                                  <div className="nk-file-name-text">
                                    <span className="title">
                                      {t(councilMinute.label)}
                                    </span>
                                    <span className="sub-text">
                                      {getDocumentsCountInCouncilMinute(
                                        councilMinute._id
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="nk-file-meta councilminutes-menudots">
                          <div className="tb-lead">
                            <MenuDots
                              menuOptions={currentMenuOptions}
                              id={councilMinute._id}
                              params={{
                                currentCouncilMinute: councilMinute,
                                actualSociety,
                                documents,
                                isDemo,
                              }}
                              direction="left"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </>
      ) : (
        <NoDocumentsAlert section={section} />
      )}
    </div>
  );
};

export default CouncilMinute;
