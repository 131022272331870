/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMixpanel } from 'react-mixpanel-browser';

import { setMenuType } from 'redux/actions/menuActions';
import { getUserSocieties } from 'redux/actions/societyActions';

import menuTypes from 'constants/menuTypes';

import TableLoader from 'components/Tables/TableLoader';
import transformData from 'components/Tables/helpers';
import CustomDataTable from 'components/Tables/CustomDataTable';

import tableColumns from './tableColumns';

function AdministratedList({ items = 10 }) {
  const { t, i18n } = useTranslation();
  const mixpanel = useMixpanel();

  const user = useSelector((state) => state.user);

  const [administratedData, setAdministratedData] = useState([]);
  const [tableData, setTableData] = useState([]);

  const [isLoadingData, setIsLoadingData] = useState(true);
  const [currentTableColumns, setCurrentTableColumns] = useState(
    tableColumns(i18n)
  );

  useEffect(() => {
    if (user && user.societies.administrated?.length) {
      getUserSocieties(
        user['_id'],
        'admin',
        setAdministratedData,
        'table'
      ).then((result) => {
        if (!result?.length) {
          setIsLoadingData(false);
        }
      });
    } else {
      setIsLoadingData(false);
    }
  }, [user]);

  useEffect(() => {
    setMenuType(menuTypes.MAIN);
  }, []);

  useEffect(() => {
    const newData = administratedData?.map((data) => ({
      society: data?.society,
      name: data?.society?.name,
      partners: data?.partnersCount,
      value: data?.societyValue,
      creationDate: data?.society?.creationDate || '',
      isAdmin: data?.isUserAdmin,
      user,
      mixpanel,
    }));

    if (newData.length > 0) {
      setTableData(
        transformData({
          data: newData,
          columns: tableColumns(i18n),
        })
      );
    } else {
      setTableData([]);
    }
  }, [administratedData, i18n.language]);

  useEffect(() => {
    setCurrentTableColumns(tableColumns(i18n));
  }, [i18n.language]);

  return (
    <div className="nk-content-body">
      <div className="nk-content-wrap">
        <div className="nk-block-head nk-block-head-sm">
          <div className="nk-block-between">
            <div className="nk-block-head-content">
              <h3 className="nk-block-title page-title">
                {t('AdministratedTitle')}
              </h3>
              <div className="nk-block-des text-soft">
                <p>
                  {administratedData?.length === 0
                    ? t('AdministratedNoSocieties')
                    : t('AdministratedHaveSocieties', {
                        count: administratedData?.length,
                      })}
                </p>
              </div>
            </div>

            <div className="nk-block-head-content">
              <div className="toggle-wrap nk-block-tools-toggle">
                <div className="toggle-expand-content" data-content="pageMenu">
                  <ul className="nk-block-tools g-3">
                    <li>
                      <Link to="/diagrama-administradas">
                        <button type="button" className="btn btn-outline-light">
                          <em className="icon ni ni-network" />
                          <span>{t('Chart')}</span>
                        </button>
                      </Link>
                    </li>
                    <li>
                      <Link to="/cuadro-administradas">
                        <button type="button" className="btn btn-outline-light">
                          <em className="icon ni ni-menu-squared" />
                          <span>{t('View')}</span>
                        </button>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        {administratedData.length > 0 ? (
          <CustomDataTable
            data={tableData}
            columns={currentTableColumns}
            searchBy={t('Society')}
            pagination
            className="nk-tb-list"
            rowsPerPage={items}
            actions
            showDense
          />
        ) : (
          isLoadingData && <TableLoader rows={items} />
        )}
      </div>
    </div>
  );
}

export default AdministratedList;
