import columns from './uploadExcelColumns';

export const getNameColumn = (data: Record<string, any>) =>
  data?.[columns.NOMBRE] ||
  data?.[columns.NOMBRE_APELLIDOS] ||
  data?.[columns.NOMBRE_Y_APELLIDOS] ||
  '';

export const getNationalityColumn = (data: Record<string, any>) =>
  data?.[columns.NACIONALIDAD] || '';

export const getTypeIdColumn = (data: Record<string, any>) =>
  data?.[columns.TIPO_ID] || '';

export const getNroIdColumn = (data: Record<string, any>) =>
  data?.[columns.NRO_ID] || data?.[columns.CIF] || '';

export const getAddressColumn = (data: Record<string, any>) =>
  data?.[columns.DIRECCION]
    ? {
        line1: data?.[columns.DIRECCION] || '',
        zip: '',
        city: '',
        state: '',
        country: '',
      }
    : {
        line1: data?.[columns.DOMICILIO] || '',
        zip: data?.[columns.CODIGO_POSTAL] || data?.[columns.CP] || '',
        city: data?.[columns.POBLACION] || data?.[columns.CIUDAD] || '',
        state: '',
        country: data?.[columns.PAIS] || '',
      };

export const getPhoneColumn = (data: Record<string, any>) =>
  data?.[columns.PHONE] || data?.[columns.TELEFONO] || '';

export const getEmailColumn = (data: Record<string, any>) =>
  data?.[columns.EMAIL] || data?.[columns.CORREO_ELECTRONICO] || '';

export const getCivilStatusColumn = (data: Record<string, any>) =>
  data?.[columns.ESTADO_CIVIL] || '';

export const getBirthDateColumn = (data: Record<string, any>) =>
  data?.[columns.FECHA_NACIMIENTO] || data?.[columns.FECHA_DE_NACIMIENTO] || '';

export const getAmountColumn = (data: Record<string, any>) =>
  data?.[columns.IMPORTE_APORTACION] || '';

export const getCapitalColumn = (data: Record<string, any>) =>
  data?.[columns.CAPITAL] || '';

export const getNroPPSSColumn = (data: Record<string, any>) =>
  data?.[columns.NRO_PPSS] ||
  data?.[columns.NRO_DE_PPSS] ||
  data?.[columns.PARTICIPACIONES] ||
  0;

export const getNroPSInitialColumn = (data: Record<string, any>) =>
  data?.[columns.NRO_PS_INICIAL] || '';

export const getNroPSFinalColumn = (data: Record<string, any>) =>
  data?.[columns.NRO_PS_FINAL] || '';

export const getInvestorTypeColumn = (data: Record<string, any>) =>
  data?.[columns.TIPO_INVERSOR] || null;

export const getInvestmentDateColumn = (data: Record<string, any>) =>
  data?.[columns.FECHA_INVERSION] || '';

export const getPriceParticipationColumn = (data: Record<string, any>) =>
  data?.[columns.PRECIO_PARTICIPACION] || '';

export const getNominalPSColumn = (data: Record<string, any>) =>
  data?.[columns.NOMINAL_PS] || '';

export const getPrimaPSColumn = (data: Record<string, any>) =>
  data?.[columns.PRIMA_PS] || data?.[columns.PRIMA] || '';

export const getHoldingClassColumn = (data: Record<string, any>) =>
  data?.[columns.CLASE] || '';

export const getNroEmployeeColumn = (data: Record<string, any>) =>
  data?.[columns.NRO_EMPLEADO] || '';

export const getBankAccountColumn = (data: Record<string, any>) =>
  data?.[columns.CUENTA_BANCARIA] || '';

export const getFiscalResidenceColumn = (data: Record<string, any>) =>
  data?.[columns.RESIDENCIA_FISCAL]
    ? {
        line1: data?.[columns.RESIDENCIA_FISCAL] || '',
        zip: '',
        city: '',
        state: '',
        country: '',
      }
    : getAddressColumn(data) || '';

export const getLegalPersonColumn = (data: Record<string, any>) =>
  data?.[columns.PERSONA_JURIDICA] || 'No';
