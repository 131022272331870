/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';

import { countOperationShares, getOperationAmount } from 'utils/filters';
import { currencyFormatDecimals } from 'constants/formats';

import operationTypesRealNames from 'constants/operationTypesRealNames';
import operationTypes from 'constants/operationTypes';
import { format } from 'date-fns';
import MenuDots from '../MenuDots';
import menuOptions from './menuOptions';

import '../../assets/styles/Components.scss';

function TransactionsRow({ transaction, society, user }) {
  const [displayDate] = useState(
    format(new Date(transaction.date), 'dd/MM/yy')
  );

  const [participations, setParticipations] = useState();
  const [amount, setAmount] = useState();

  const [logo, setLogo] = useState();
  const [name, setName] = useState('');
  const [link, setLink] = useState('');

  useEffect(() => {
    setLogo(society?.additional?.logo);
    setName(society?.name);
    setLink(`/socios/${society?.['_id']}`);
    const totalShares = countOperationShares(transaction);
    setParticipations(totalShares);
    const totalAmount = getOperationAmount(transaction, society) || '-';
    setAmount(totalAmount);
  }, [transaction, society, user]);

  return (
    <div className="nk-tb-item">
      <div className="nk-tb-col d-none d-md-table-cell">
        <Link
          to={`/detalle-transacciones/${transaction.society}/${transaction['_id']}`}
          className="tb-lead text-primary"
        >
          {`#${transaction['_id'].slice(0, 6)}...`}
        </Link>
      </div>

      <div className="nk-tb-col d-md-none text-center">
        <span>
          <Link
            to={`/detalle-transacciones/${transaction.society}/${transaction['_id']}`}
            className="tb-lead text-primary"
          >
            <em className="icon ni ni-external fs-16px" />
          </Link>
        </span>
      </div>

      <div className="nk-tb-col tb-col-xl">
        <Link to={link}>
          <div className="user-card">
            <div className="user-avatar user-avatar-sm bg-warning">
              {logo ? (
                <img src={logo} alt={name} className="rounded-circle" />
              ) : (
                <span>{name ? name.slice(0, 2).toUpperCase() : ''}</span>
              )}
            </div>
            <div className="user-name">
              <span className="tb-lead">{name}</span>
            </div>
          </div>
        </Link>
      </div>

      <div className="nk-tb-col">
        <span className="tb-sub">{displayDate}</span>
      </div>

      <div className="nk-tb-col tb-col-xl">
        <span className="tb-sub text-primary">{participations || '-'}</span>
      </div>

      <div className="nk-tb-col">
        <span className="tb-sub tb-amount">
          {amount !== '-' ? (
            <NumberFormat
              value={amount}
              displayType="text"
              renderText={(value) => <div>{value}</div>}
              {...currencyFormatDecimals}
            />
          ) : (
            amount
          )}
        </span>
      </div>

      <div className="nk-tb-col">
        <span className="badge badge-dot badge-dot-xs badge-success">
          {operationTypesRealNames[transaction.operationType]}
        </span>
      </div>

      <div className="nk-tb-col nk-tb-col-action">
        <div className="dropdown">
          <MenuDots
            menuOptions={menuOptions}
            id={transaction.society}
            direction="left"
          />
        </div>
      </div>
    </div>
  );
}

function mapStateToProps({ user }) {
  return { user };
}

export default connect(mapStateToProps)(TransactionsRow);
