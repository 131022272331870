import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';

import WidgetChartDoughnut, {
  legendModes,
} from 'components/WidgetChartDoughnut';
import { dateFormat } from 'constants/formats';
import tenderOfferStatus from 'constants/tenderOfferStatus';

import {
  getChartTenderOfferData,
  tenderOfferChartColors,
  tenderOfferChartLabels,
  tenderOfferChartOptions,
} from 'utils/charts';
import {
  formatCurrency,
  formatNumber,
  formatNumberDecimals,
} from 'utils/formats';
import { getEligiblesData, getTotalUnitsFromEligibles } from 'utils/filters';

import './TenderOfferRow.scss';

const TenderOfferRow = ({ data, plans, actualSociety, availableOptions }) => {
  const {
    _id,
    name,
    details, // @Marti - Details he sustituido temporalmente por "paymentConditions" => se mostrará Details
    status,
    startDate,
    endDate,
    calculationDate,
    eligiblesType,
    eligiblesSubgroup,
    totalPriceByShare,
    totalPriceBySociety,
    buyerName,
    maxAmount,
    maxUnits,
    society,
    paymentConditions,
    retentionPercent,
  } = data;
  const { t } = useTranslation();

  const labels = tenderOfferChartLabels;
  const colors = tenderOfferChartColors;
  const chartOptions = tenderOfferChartOptions;

  const [chartLegend, setChartLegend] = React.useState([]);
  const [chartData, setChartData] = React.useState(null);

  React.useState(() => {
    if (!actualSociety || !data) return;

    const eligiblesList = getEligiblesData(
      availableOptions,
      data,
      actualSociety,
      plans
    );

    const totalOffer = getTotalUnitsFromEligibles(eligiblesList);

    const { legend, chartValues } = getChartTenderOfferData(
      actualSociety,
      data,
      totalOffer
    );

    setChartLegend(legend);
    setChartData({
      labels,
      datasets: [
        {
          fill: true,
          label: 'Porciento',
          data: chartValues,
          borderWidth: 2,
          borderColor: '#ffffff',
          backgroundColor: colors,
        },
      ],
    });
  }, [actualSociety, data]);

  return (
    <div className="nk-block">
      <div className="card card-bordered sp-plan">
        <div className="row no-gutters">
          <div className="col-md-8 d-flex flex-column justify-content-between">
            <div className="sp-plan-info card-inner">
              <div className="row gx-0 gy-3">
                <div className="col-xl-9 col-sm-8">
                  <div className="sp-plan-name">
                    <h6 className="title mb-3">
                      <Link to={`/detalle-tender-offer/${society}/${_id}`}>
                        {name}&nbsp;
                        <span
                          className={`badge badge-pill ml-1 ${tenderOfferStatus[status]?.style}`}
                        >
                          {t(tenderOfferStatus[status]?.label)}
                        </span>
                      </Link>
                    </h6>
                    <p>
                      {t('PaymentConditions')}:&nbsp;
                      <span className="text-base">{paymentConditions}</span>
                    </p>
                    <p>
                      {t('Comments')}:&nbsp;
                      <span className="text-base">{details}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="sp-plan-desc card-inner">
              <ul className="row gx-1">
                <li className="col-6 col-lg-3">
                  <p>
                    <span className="text-soft">{t('StartDate')}</span>
                    {startDate && format(new Date(startDate), dateFormat)}
                  </p>
                </li>
                <li className="col-6 col-lg-3">
                  <p>
                    <span className="text-soft">{t('EndDate')}</span>
                    {endDate && format(new Date(endDate), dateFormat)}
                  </p>
                </li>
                <li className="col-6 col-lg-3">
                  <p>
                    <span className="text-soft">{t('CalculationDate')}</span>
                    {calculationDate
                      ? calculationDate &&
                        format(new Date(calculationDate), dateFormat)
                      : '-'}
                  </p>
                </li>
                <li className="col-6 col-lg-3">
                  <p>
                    <span className="text-soft">{t('Elegible')}</span>
                    {t(eligiblesType)} ({t(eligiblesSubgroup)})
                  </p>
                </li>
                <li className="col-6 col-lg-3">
                  <p>
                    <span className="text-soft">{t('SocietyValuation')}</span>
                    {formatCurrency(totalPriceBySociety)}
                  </p>
                </li>
                <li className="col-6 col-lg-3">
                  <p>
                    <span className="text-soft">{t('PriceShareUnits')}</span>
                    {formatCurrency(totalPriceByShare)}
                  </p>
                </li>
                <li className="col-6 col-lg-3">
                  <p>
                    <span className="text-soft">{t('Buyer')}</span>
                    {buyerName}
                  </p>
                </li>
                <li className="col-6 col-lg-3">
                  <p>
                    <span className="text-soft">{t('MaximumAmount')}</span>
                    {formatCurrency(maxAmount)}
                  </p>
                </li>
                <li className="col-6 col-lg-3">
                  <p>
                    <span className="text-soft">{t('MaximumUnits')}</span>
                    {formatNumber(maxUnits)}
                  </p>
                </li>
                <li className="col-6 col-lg-3">
                  <p>
                    <span className="text-soft">% {t('Retention')}</span>
                    {formatNumberDecimals(retentionPercent || 0)}%
                  </p>
                </li>
              </ul>
            </div>
          </div>

          {chartData && chartOptions && chartLegend && (
            <div className="col-md-4">
              <div className="sp-plan-action card-inner p-1 tender-chart">
                <h6 className="text-center pt-3">{t('OfferEvolution')}</h6>
                <WidgetChartDoughnut
                  title={t('OfferEvolution')}
                  data={{ data: chartData, options: chartOptions }}
                  legendMode={legendModes.VERTICAL}
                  legend={chartLegend}
                  isEmbedded
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TenderOfferRow;
