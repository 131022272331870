import store from 'redux/store';
import { addAlert } from 'redux/actions/alertActions';
import alertBodyTypes from 'components/Alert/alertBodyTypes';
import {
  addDocument,
  getDocumentsByReference,
} from 'redux/actions/documentActions';
import { setModal } from 'redux/actions/modalActions';
import axios from '../interceptors/axios';
import { decryptResponse } from './token';

export default async function duplicateDocument(document, notPreview) {
  try {
    const { name, _id, date, ...documentData } = document;
    let newDocument = {};
    if (notPreview) {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/download/${_id}`
      );
      const decodedData = await decryptResponse(data);
      const url = decodedData?.url;
      await fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const documentFile = new File([blob], `Copia ${name}`, {
            type: 'application/pdf',
          });
          newDocument = {
            name: `Copia ${name}`,
            file: documentFile,
            size: documentFile.size,
            date: new Date(),
            ...documentData,
          };
        })
        .catch(() => {
          store.dispatch(addAlert(alertBodyTypes.ERROR_DUPLICATING_DOCUMENT));
        });
    } else {
      newDocument = {
        name: `Copia ${name}`,
        date: new Date(),
        ...documentData,
      };
    }
    await store.dispatch(addDocument(newDocument));
    store.dispatch(
      getDocumentsByReference({ societyId: documentData.society })
    );
    store.dispatch(addAlert(alertBodyTypes.DOCUMENT_DUPLICATED));
  } catch (error) {
    store.dispatch(addAlert(alertBodyTypes.ERROR_DUPLICATING_DOCUMENT));
  } finally {
    store.dispatch(setModal(null));
  }
}
