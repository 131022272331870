/* eslint-disable no-underscore-dangle */
import { dateFormat } from 'constants/formats';
import { format } from 'date-fns';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { sendCommEmail } from 'redux/actions/communicationActions';
import { setModal } from 'redux/actions/modalActions';
import { updateTenderOfferShares } from 'redux/actions/tenderOffersSharesActions';
import Swal from 'sweetalert2';
import getTenderOfferShareTemplateBody from 'utils/getTenderOfferShareTemplateBody';
import {
  tenderOfferSharesStatusTypes,
  tenderOfferSharesTypes,
} from 'utils/tenderOfferSharesTypes';

const SendTenderOfferShares = ({ data }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const actualSociety = useSelector((state) => state?.society?.actualSociety);
  const offerId = data?._id;
  const offerName = data?.name;
  const offerStartDate = data?.startDate;
  const offerEndDate = data?.endDate;
  const type = data?.type;
  const sellerId = data?.sellerId;

  const handleSendNotification = async () => {
    try {
      const activePartner = actualSociety.partners.filter(
        (partner) =>
          partner?.sharesCount?.actual > 0 && partner?._id !== sellerId
      );

      const recipients = activePartner.map((partner) => ({
        email: partner.email,
        name: partner.name,
      }));

      await dispatch(
        updateTenderOfferShares(offerId, {
          isActive: true,
          status: tenderOfferSharesStatusTypes.ACTIVE.value,
          notificationDate: Date.now(),
        })
      );

      const recipientsForDynamicData = activePartner.map((recipient) => {
        const { _id, email, name } = recipient;
        return {
          id: _id,
          email: email ? email.trim() : '',
          name,
        };
      });
      const subjectTranslationText =
        type === tenderOfferSharesTypes.BUY
          ? 'BuyOfferMailSubject'
          : 'SaleOfferMailSubject';
      const subject = t(subjectTranslationText, {
        societyName: actualSociety?.name,
      });

      const emailTemplate =
        type === tenderOfferSharesTypes.BUY
          ? 'send-buy-offer-'
          : 'send-sale-offer-';

      const emailData = {
        to: recipients,
        subject,
        template: `${emailTemplate}${i18n.language}`,
        templateData: [
          {
            name: 'societyName',
            content: actualSociety?.name,
          },
        ],
        templateDynamicData: recipientsForDynamicData.map(
          ({ id, name, email }) => ({
            rcpt: email,
            vars: [
              { name: 'partnerName', content: name },
              {
                name: 'targetUrl',
                content: `${process.env.REACT_APP_DOMAIN}/#/detalle-socio/${actualSociety._id}/${id}`,
              },
            ],
          })
        ),
        templateBody: getTenderOfferShareTemplateBody(i18n.language, type),
        sender: {
          name: `Sttok | ${actualSociety?.name}`,
          email: 'info@sttok.com',
        },
        user: user['_id'],
        society: actualSociety['_id'],
        date: new Date(),
        status: 'SENT',
      };

      dispatch(sendCommEmail(emailData, false));
      return Swal.fire({
        icon: 'success',
        title: `<h4 class="nk-modal-title">${
          type === tenderOfferSharesTypes.BUY
            ? t('PurchaseOfferSuccessfullySent')
            : t('SalesOfferSuccessfullySent')
        }<br></h4>`,
        confirmButtonText: t('OK'),
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonColor: '#6576FF',
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('[ERROR]', error);
    } finally {
      dispatch(setModal(null));
    }
  };

  return (
    <>
      <Modal.Body>
        <div className="text-right">
          <a
            className="text-soft fs-22px  cursor-pointer"
            onClick={() => dispatch(setModal(null))}
          >
            <em className="icon ni ni-cross" />
          </a>
        </div>
        <div className="modal-body modal-body-lg pt-0 text-center">
          <div className="nk-modal">
            <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-send bg-warning" />
            <h4 className="nk-modal-title">{t('SendOffer')}</h4>
            <div className="nk-modal-text">
              <p className="lead">
                {t('SendSaleOfferModalMessagePart1', { offerName })}{' '}
                {t('SendSaleOfferModalMessagePart2')}
              </p>
              <p className="text-soft">
                {t('SendSaleOfferModalMessagePart3')}&nbsp;
                <b>{format(new Date(offerStartDate), dateFormat)}</b> y&nbsp;
                <b>{format(new Date(offerEndDate), dateFormat)}</b>.
              </p>
            </div>
            <div className="nk-modal-action mt-5">
              <button
                type="button"
                className="btn btn-light"
                onClick={handleSendNotification}
              >
                {t('SendNow')}
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </>
  );
};

export default SendTenderOfferShares;
