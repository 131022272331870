/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { format } from 'date-fns';
import Swal from 'sweetalert2';

import store from 'redux/store';
import { setModal } from 'redux/actions/modalActions';
import { updateBoard } from 'redux/actions/boardActions';

import { isBoardClosed, isOpenVoting } from 'utils/boards';

import voteTypes from 'constants/voteTypes';
import userTypes from 'constants/userTypes';
import voteValues from 'constants/voteValues';
import boardTypes from 'constants/boardTypes';
import votePeriods from 'constants/votePeriods';
import attendanceTypes from 'constants/attendanceTypes';
import { formatDateToTime } from 'constants/formats';

import SendVoteModal from 'components/Modals/SendVote';
import CustomLoading from 'components/CustomLoading';

import voteDelegatedImage from 'assets/images/signature-completed.svg';

const BoardVotes = ({ board }) => {
  const { t } = useTranslation();
  const { partnerId } = useParams();
  const dispatch = useDispatch();

  const { actualSociety } = useSelector((state) => ({
    actualSociety: state.society?.actualSociety,
  }));
  const { date, place, attendanceType, votePeriod, boardType } = board;

  const [isAbsent, setIsAbsent] = useState(false);
  const [isDelegated, setIsDelegated] = useState(false);
  const [openVoting, setOpenVoting] = useState(false);

  const [votesHistory, setVotesHistory] = useState({});
  const [availableVotes, setAvailableVotes] = useState({});
  const [votes, setVotes] = useState({});
  const [hasPendingVotes, setHasPendingVotesVote] = useState(false);
  const [currentPartner, setCurrentPartner] = useState();
  const [representedPartners, setRepresentedPartners] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRepresented, setSelectedRepresented] = useState([]);

  const sendSingleVote = async (currentParticipant, order, voteValue) => {
    currentParticipant.assists = true;
    currentParticipant.isAbsent = false;
    currentParticipant?.votes.push({
      order: order?.['_id'],
      vote: voteValue,
      voterData: {
        voterId: currentPartner?.['_id'],
        voterType: userTypes.PARTNER,
        voterName: currentPartner?.name,
      },
    });
    // Update votes and assistance of partner selected
    await store.dispatch(
      updateBoard(board['_id'], { participantData: currentParticipant }, false)
    );

    if (currentParticipant.member !== partnerId) {
      const currentPartnerParticipant = board?.participants?.find(
        (participant) => participant.member === partnerId
      );
      await store.dispatch(
        updateBoard(
          board['_id'],
          {
            participantData: {
              ...currentPartnerParticipant,
              assists: true,
              isAbsent: false,
            },
          },
          false
        )
      );
    }
    const participantsToUpdate = board?.participants?.filter(
      (participant) =>
        participant.member !== currentParticipant.member &&
        participant?.representative &&
        participant?.representative === currentPartner?.['_id']
    );

    for (const participant of participantsToUpdate) {
      if (!participant?.assists) {
        await store.dispatch(
          updateBoard(
            board['_id'],
            {
              participantData: {
                ...participant,
                assists: true,
                isAbsent: false,
              },
            },
            false
          )
        );
      }
    }
  };

  const saveVote = async (voteValue) => {
    setIsLoading(true);
    const filteredOrders = board.orders.filter(
      (order) => order.voteType === voteTypes.YES_NO_ABS.value
    );

    for (const order of filteredOrders) {
      for (const represented of selectedRepresented) {
        const currentParticipant = board?.participants?.find(
          (participant) => participant.member === represented.value
        );
        if (
          currentParticipant &&
          !currentParticipant?.votes.some((v) => v.order === order['_id'])
        ) {
          await sendSingleVote(currentParticipant, order, voteValue);
        }
      }
    }
    setIsLoading(false);
    return Swal.fire({
      icon: 'success',
      title: `<h4 class="nk-modal-title">${t('VoteSent')}<br></h4>`,
      confirmButtonText: t('OK'),
      allowOutsideClick: false,
      showCancelButton: false,
      confirmButtonColor: '#6576FF',
    });
  };

  const isVoteButtonDisabled = () =>
    !board?.participants?.length ||
    !hasPendingVotes ||
    !board?.isActive ||
    isDelegated ||
    isBoardClosed(board) ||
    !openVoting ||
    isAbsent;

  const getDisabledVoteButtonMessage = () => {
    if (!board?.participants?.length) {
      return t('YouCantVote_NoParticipants');
    }
    if (isBoardClosed(board)) {
      return t('YouCantVote_BoardClosed');
    }
    if (!board?.isActive) {
      return t('YouCantVote_BoardNotActive');
    }
    if (!hasPendingVotes) {
      return t('YouCantVote_NoPendingVotes');
    }
    if (isDelegated) {
      return t('YouCantVote_DelegationDone');
    }
    if (!openVoting) {
      return t('YouCantVote_VotingNotOpen');
    }
    if (isAbsent) {
      return t('YouCantVote_Absent');
    }
    return '';
  };

  const handleMultipleVotes = (voteValue) =>
    Swal.fire({
      icon: 'info',
      title: `<h4 class="nk-modal-title">${t('VoteAllMessage', {
        voteValue: voteValues[voteValue]?.text,
      })}</h4>`,
      confirmButtonText: t('Vote'),
      confirmButtonColor: '#6576ff',
      allowOutsideClick: false,
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await saveVote(voteValue);
        } catch (error) {
          console.log(error);
        }
      }
    });

  useEffect(() => {
    if (actualSociety) {
      setCurrentPartner(
        actualSociety?.partners.find((partner) => partner['_id'] === partnerId)
      );
      const representedPartnersIds = board.participants.reduce((acc, curr) => {
        if (curr?.isRepresented && curr?.representative === partnerId) {
          acc.push(curr?.member);
        }
        return acc;
      }, []);
      setRepresentedPartners(
        actualSociety.partners.filter((partner) =>
          representedPartnersIds.includes(partner['_id'])
        )
      );
    }
  }, [actualSociety]);

  useEffect(() => {
    if (representedPartners && currentPartner) {
      const options = representedPartners.map((partner) => ({
        value: partner['_id'],
        label: `${partner?.cif} | ${partner?.name}`,
      }));
      options.push({
        value: currentPartner['_id'],
        label: `${currentPartner?.cif} | ${currentPartner?.name}`,
      });

      setSelectedRepresented(options);
    }
  }, [representedPartners, currentPartner]);

  useEffect(() => {
    if (board) {
      const resultParticipant = board.participants.find(
        (participant) => participant.member === partnerId
      );
      setIsAbsent(resultParticipant?.isAbsent);
      setIsDelegated(resultParticipant?.isRepresented);
      setOpenVoting(isOpenVoting(board));
    }
  }, [board, partnerId]);

  useEffect(() => {
    const result = Object.keys(votesHistory).reduce(
      (acc, order) => {
        const hasAvailableVote = votesHistory?.[order].some(
          (elem) => !elem.vote && !elem.representative
        );
        acc.availableVotes[order] = hasAvailableVote;

        const partnerHistory = votesHistory?.[order]?.find(
          (elem) => elem?.partner === partnerId && !elem?.representatedPartner
        );
        acc.votes[order] = partnerHistory?.vote?.vote || '';
        return acc;
      },
      { availableVotes: {}, votes: {} }
    );
    setAvailableVotes(result.availableVotes);
    setVotes(result.votes);
  }, [votesHistory]);

  useEffect(() => {
    if (board?.participants?.length) {
      // Create votes history object of current partner for each order
      const history = board?.orders?.reduce((acc, order) => {
        const votes = board?.participants?.reduce((acc, curr) => {
          const currentVote = curr?.votes?.find(
            (v) => v.order === order['_id']
          );
          if (curr?.member === partnerId) {
            acc.push({
              partner: partnerId,
              vote: currentVote || null,
              representative: curr?.representative || null,
              representatedPartner: null,
            });
          }
          if (curr?.representative === partnerId) {
            acc.push({
              partner: partnerId,
              vote: currentVote || null,
              representative: null,
              representatedPartner: curr?.member,
            });
          }
          return acc;
        }, []);
        acc[order['_id']] = votes;
        return acc;
      }, {});
      setVotesHistory(history);
    }
  }, [board]);

  useEffect(() => {
    if (availableVotes) {
      setHasPendingVotesVote(false);
      board.orders.forEach((order) => {
        if (
          availableVotes[order['_id']] &&
          order.voteType === voteTypes.YES_NO_ABS.value
        ) {
          setHasPendingVotesVote(true);
        }
      });
    }
  }, [availableVotes]);

  return !isLoading ? (
    <div className="nk-block">
      <div className="row">
        <div className="col-xl-12">
          <div className="card card-bordered">
            <div className="card-inner-group">
              <div className="card-inner">
                <div className="sp-plan-head">
                  <h6 className="title">{t('BasicInformation')}</h6>
                </div>

                <div className="sp-plan-desc sp-plan-desc-mb">
                  <ul className="row gx-1">
                    <li className="col-12 col-md-6 col-lg-4">
                      <p>
                        <span className="text-soft">{t('Date')}</span>{' '}
                        {format(new Date(date), 'dd/MM/yyyy')}
                      </p>
                    </li>
                    <li className="col-12 col-md-6 col-lg-4">
                      <p>
                        <span className="text-soft">{t('StartHour')}</span>{' '}
                        {formatDateToTime(date)}
                      </p>
                    </li>

                    {attendanceType === attendanceTypes.FACE_TO_FACE.value ||
                    attendanceType === attendanceTypes.HYBRID.value ? (
                      <li className="col-12 col-md-6 col-lg-4">
                        <p>
                          <span className="text-soft">
                            {t('PlaceOfCelebration')}
                          </span>{' '}
                          {place || '-'}
                        </p>
                      </li>
                    ) : (
                      <></>
                    )}
                    <li className="col-12 col-md-6 col-lg-4">
                      <p>
                        <span className="text-soft">{t('AssistanceForm')}</span>{' '}
                        {attendanceTypes?.[attendanceType]?.text || '-'}
                      </p>
                    </li>
                    <li className="col-12 col-md-6 col-lg-4">
                      <p>
                        <span className="text-soft">{t('VotingPeriod')}</span>{' '}
                        {votePeriods.find(
                          (period) => period.value === votePeriod
                        )?.text || '-'}
                      </p>
                    </li>
                    <li className="col-12 col-md-6 col-lg-4">
                      <p>
                        <span className="text-soft">{t('BoardType')}</span>{' '}
                        {boardTypes?.[boardType]?.text || '-'}
                      </p>
                    </li>
                  </ul>
                </div>
              </div>

              {isVoteButtonDisabled() ? (
                <div className="card-inner bg-primary-dim text-center py-5">
                  <img
                    src={voteDelegatedImage}
                    alt="Sign new document"
                    height={80}
                    width={100}
                    className="mb-3"
                  />
                  <h5>{getDisabledVoteButtonMessage()}</h5>
                </div>
              ) : (
                <div className="card-inner">
                  <div className="row g-4">
                    <div className="col-12 col-md-4">
                      <button
                        type="button"
                        className="btn btn-block badge-success"
                        disabled={isVoteButtonDisabled()}
                        onClick={() =>
                          handleMultipleVotes(voteValues.YES.value)
                        }
                      >
                        <span>{t('VoteAllYes')}</span>
                      </button>
                    </div>
                    <div className="col-12 col-md-4">
                      <button
                        type="button"
                        className="btn btn-block badge-danger"
                        disabled={isVoteButtonDisabled()}
                        onClick={() => handleMultipleVotes(voteValues.NO.value)}
                      >
                        <span>{t('VoteAllNo')}</span>
                      </button>
                    </div>
                    <div className="col-12 col-md-4">
                      <button
                        type="button"
                        className="btn btn-block badge-gray"
                        disabled={isVoteButtonDisabled()}
                        onClick={() =>
                          handleMultipleVotes(voteValues.ABS.value)
                        }
                      >
                        <span>{t('VoteAllAbstention')}</span>
                      </button>
                    </div>
                  </div>
                </div>
              )}

              {board.orders.length ? (
                <>
                  {board.orders
                    .filter(
                      (order) => order.voteType === voteTypes.YES_NO_ABS.value
                    )
                    .map((order, index) => (
                      <div className="row no-gutters">
                        <div className="col-12 col-md-8">
                          <div
                            className={`sp-plan-info card-inner ${
                              index !== 0 ? 'border-top' : ''
                            }`}
                          >
                            <div className="sp-plan-name">
                              <h6 className="title">
                                {`${index + 1}. ${order.subject}`}
                              </h6>
                              <p>
                                <span className="ff-italic text-soft">
                                  {order?.description || ''}
                                </span>
                              </p>
                              <br />
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-4">
                          <div
                            className={`sp-plan-action card-inner ${
                              index !== 0 ? 'border-top' : ''
                            }`}
                          >
                            <div className="sp-plan-btn">
                              <button
                                type="button"
                                className="btn btn-primary"
                                disabled={
                                  !board?.participants?.length ||
                                  !availableVotes?.[order['_id']] ||
                                  !board?.isActive ||
                                  isDelegated ||
                                  isBoardClosed(board) ||
                                  !openVoting ||
                                  isAbsent
                                }
                                onClick={() =>
                                  dispatch(
                                    setModal(
                                      <SendVoteModal
                                        board={board}
                                        order={order}
                                        partnerId={partnerId}
                                      />
                                    )
                                  )
                                }
                              >
                                <span>{t('Vote')}</span>
                              </button>
                            </div>
                            {availableVotes?.[order['_id']] ? (
                              <div className="sp-plan-note text-md-center">
                                <p>{t('YouCanVote')}</p>
                              </div>
                            ) : (
                              voteValues[votes?.[order['_id']]] && (
                                <div className="sp-plan-note text-md-center">
                                  <p>
                                    {t('HasVoted')}:{' '}
                                    {voteValues[votes?.[order['_id']]]?.text}{' '}
                                  </p>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <CustomLoading />
  );
};

export default BoardVotes;
