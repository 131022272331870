/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
/* eslint-disable prefer-const */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/config';

import {
  currencyFormat,
  currencyFormatDecimals,
  numberFormat,
  numberFormatDecimals,
} from 'constants/formats';
import {
  BLANK_PROFILE_PICTURE,
  DATE_FORMAT,
  DATE_FORMAT_MONTH_ONLY,
} from 'constants/defaultConstants';
import operationTypes from 'constants/operationTypes';
import beneficiaryStatus from 'constants/beneficiaryStatus';

import Badge from 'components/Badge';
import UsersArray from 'components/UsersArray';
import BadgeSocietyHolding from 'components/Badges/BadgeSocietyHolding';

import dateIsValid from 'utils/dateValidation';
import activePartner from 'utils/activePartner';

const ColoredDate = ({ date, isAdmin }) => (
  <>
    {date ? <Badge creationDate={new Date(date)} isAdmin={!!isAdmin} /> : <></>}
  </>
);

const PlainText = ({ text, className = '' }) => (
  <span className={className}>{text}</span>
);

const PlainTextLink = ({ text, url = '' }) => (
  <Link to={url}>
    <span>{text}</span>
  </Link>
);

const PlainDate = ({ date, monthYear = false }) => {
  const finalDate = date ? new Date(date) : '';

  return (
    <div className="nk-tb-col pl-0 py-0 d-flex w-100 justify-content-end">
      {date ? (
        <span className="tb-sub text-right">
          {monthYear
            ? dateIsValid(finalDate)
              ? format(finalDate, DATE_FORMAT_MONTH_ONLY)
              : '-'
            : dateIsValid(finalDate)
            ? format(finalDate, DATE_FORMAT)
            : '-'}
        </span>
      ) : (
        <span className="tb-sub text-right">-</span>
      )}
    </div>
  );
};

const NumberNoDecimals = ({ value }) => (
  <div className="nk-tb-col py-0 pr-0 d-flex w-100 justify-content-end fs-12px ">
    {value ? (
      <NumberFormat
        value={value}
        displayType="text"
        {...numberFormat}
        decimals={0}
      />
    ) : (
      '-'
    )}
  </div>
);

const NumberDecimals = ({ value, decimals = 2 }) => (
  <div className="nk-tb-col py-0 d-flex w-100 justify-content-end">
    <NumberFormat
      value={value}
      displayType="text"
      {...numberFormatDecimals}
      decimalScale={decimals}
    />
  </div>
);

const CurrencyNoDecimals = ({ value }) => (
  <div className="nk-tb-col py-0 d-flex w-100 justify-content-end">
    {value ? (
      <NumberFormat value={value} displayType="text" {...currencyFormat} />
    ) : (
      '-'
    )}
  </div>
);

const CurrencyDecimals = ({ value }) => (
  <div className="nk-tb-col p-0 d-flex w-100 justify-content-end fs-12px">
    {value ? (
      <NumberFormat
        value={value}
        displayType="text"
        {...currencyFormatDecimals}
      />
    ) : (
      '-'
    )}
  </div>
);

const PercentNoDecimals = ({ value }) => (
  <>
    {value ? (
      <div className="nk-tb-col py-0 d-flex w-100 justify-content-end">
        <NumberFormat value={value} displayType="text" {...numberFormat} />%
      </div>
    ) : (
      '-'
    )}
  </>
);

const PercentDecimals = ({ value, noPadding = true, decimals = 2 }) => (
  <>
    {value ? (
      <div
        className={`nk-tb-col py-0 d-flex w-100 justify-content-end fs-12px pr-3 ${
          noPadding ? 'pr-0' : ''
        }`}
      >
        <NumberFormat
          value={value}
          displayType="text"
          {...numberFormatDecimals}
          decimalScale={+value ? decimals : 0}
        />
        %
      </div>
    ) : (
      '-'
    )}
  </>
);

const User = ({
  partner,
  societyId,
  hasLink = true,
  url,
  color = 'primary',
  size = '',
}) => {
  const { _id, email, image, name, sindication } = partner;

  const dotStyle = activePartner(partner) ? 'dot-success' : 'dot-danger';

  return (
    <div className="user-card">
      {image ? (
        <div className={`user-avatar bg-dim-primary d-flex ${size}`}>
          <img src={image} alt={name || ''} className="rounded-circle" />
        </div>
      ) : (
        <div className={`user-avatar bg-dim-primary d-flex ${size}`}>
          <img
            src={BLANK_PROFILE_PICTURE}
            alt={name || ''}
            className="rounded-circle"
          />
        </div>
      )}
      <div className="user-info">
        <span className="tb-lead">
          {hasLink ? (
            <Link to={`${url}/${societyId}/${_id}`}>{name || ''}</Link>
          ) : (
            <>
              <span className={`text-${color}`}>{name || ''}</span>
              <em className="icon ni ni-lock text-secondary ml-1 text-black-50" />
            </>
          )}
          <span className={`dot ${dotStyle} d-md-none ml-1`} />
          {sindication && (
            <span className="badge badge-pill badge-primary ml-1 fs-9px">
              {i18n.t('Sindicated')}
            </span>
          )}
        </span>
        <span
          className="d-block text-ellipsis"
          style={{
            maxWidth: '180px',
            lineHeight: '20px',
          }}
        >
          {hasLink && email}
        </span>
      </div>
    </div>
  );
};

const Beneficiary = ({
  beneficiary,
  status,
  planId,
  isDraft,
  societyId,
  url,
}) => (
  <div className="user-card">
    <div className="user-avatar bg-dim-primary d-none d-md-flex">
      {beneficiary?.image ? (
        <img
          src={beneficiary?.image}
          alt={beneficiary?.name || ''}
          className="rounded-circle"
        />
      ) : (
        <span>{beneficiary?.name.slice(0, 2).toUpperCase()}</span>
      )}
    </div>
    <div className="user-info">
      <span className="tb-lead">
        <Link to={`${url}/${societyId}/${planId}/${beneficiary['_id']}`}>
          {beneficiary?.name || ''}
        </Link>
        <span
          className={`dot dot-${beneficiaryStatus.style[status]} d-md-none ml-1`}
        />
      </span>
      <span className="d-block text-ellipsis">{beneficiary?.email}</span>
      <div className="d-none d-md-flex">
        {isDraft ? (
          <span
            className={`badge rounded-pill mt-1 bg-${beneficiaryStatus.style.DRAFT} text-white`}
          >
            {beneficiaryStatus?.name.DRAFT}
          </span>
        ) : (
          <span
            className={`badge rounded-pill mt-1 text-${beneficiaryStatus.style[status]}`}
          >
            {beneficiaryStatus?.name[status]}
          </span>
        )}
      </div>
    </div>
  </div>
);

const Employee = ({ employee }) => (
  <div className="user-card">
    <div className="user-avatar bg-dim-primary d-none d-md-flex">
      <span>
        {employee?.firstName[0].toUpperCase()}
        {employee?.lastName[0].toUpperCase()}
      </span>
    </div>
    <div className="user-info">
      <span className="tb-lead text-ellipsis">
        {`${employee?.firstName} ${employee?.lastName}` || ''}
      </span>
      <span className="d-block text-ellipsis">{employee?.email}</span>
    </div>
  </div>
);

const EmployeesPlan = ({ employeesPlan }) => (
  <div className="user-card">
    <div className="user-avatar bg-dim-primary d-none d-md-flex">
      <span>
        {employeesPlan?.name[0].toUpperCase()}
        {employeesPlan?.name[1].toUpperCase()}
      </span>
    </div>
    <div className="user-info">
      <span className="tb-lead text-ellipsis">{employeesPlan?.name || ''}</span>
      <span className="d-block text-ellipsis">
        {employeesPlan?.planType || ''}
      </span>
    </div>
  </div>
);

const Society = ({
  society,
  transactionRef,
  url,
  type,
  showHolding = false,
  userRoles,
  customLink,
  noRedirect = false,
}) => {
  if (JSON.parse(localStorage.getItem('showDetails'))) {
    // eslint-disable-next-line no-console
  }
  const getSocietyLinkTo = () => {
    if (customLink) {
      return customLink;
    }
    if (transactionRef) {
      return `${url}/${transactionRef.society}/${transactionRef['_id']}`;
    }

    if (showHolding && society.isSocietyHolding && userRoles?.isAdmin) {
      return `/cuadro-portafolio/${society['_id']}`;
    }

    return `${url}/${society['_id']}`;
  };
  if (society) {
    const { name, isSocietyHolding } = society;
    const { logo } = society?.additional || {};
    const linkTo = getSocietyLinkTo();

    const societyNameJSX = (
      <>
        <div className={`user-avatar bg-${type}`}>
          {logo ? (
            <img src={logo} alt={name} className="rounded-circle" />
          ) : (
            <span>{name ? name.slice(0, 2).toUpperCase() : ''}</span>
          )}
        </div>
        <div className="project-info">
          {type === 'warning' ? (
            <span className="tb-lead">{name}</span>
          ) : (
            <h6 className="title">{name}</h6>
          )}

          {showHolding && isSocietyHolding && <BadgeSocietyHolding />}
        </div>
      </>
    );

    return noRedirect ? (
      <div className="project-title">{societyNameJSX}</div>
    ) : (
      <Link to={linkTo} className="project-title">
        {societyNameJSX}
      </Link>
    );
  }
};

const SocietyMobile = ({ society, url, size }) => {
  if (society) {
    let name =
      society.name.length > size ? society.name.slice(0, size) : society.name;
    if (name.includes(' ')) {
      const parts = society.name.split(' ');
      name = '';
      for (let part of parts) if (part !== 'SL') name += part[0];
    }

    return (
      <Link to={`${url}/${society['_id']}`}>
        <div className="d-flex flex-column">
          <div className="">
            <span className="badge bg-light">{name}</span>
          </div>
          <span className="text-dark fs-12px">{society.name}</span>
        </div>
      </Link>
    );
  }
};

const SocietyPartners = ({ partners, isAdmin, society }) => {
  if (partners) {
    let activePartners;

    if (typeof partners === 'number') {
      activePartners = partners;
    } else {
      activePartners =
        partners.filter((partner) => partner?.sharesCount?.actual > 0)
          ?.length ||
        society?.activePartners ||
        partners.length;
    }

    return !isAdmin ? (
      <ul className="project-users g-1">
        <UsersArray users={activePartners} show={2} />
      </ul>
    ) : (
      <div className="nk-tb-col py-0 d-flex w-100 justify-content-end fs-12px">
        {activePartners}
      </div>
    );
  }
  return (
    <div className="nk-tb-col py-0 d-flex w-100 justify-content-end">-</div>
  );
};

const SocietyBilling = ({ billing }) => (
  <div className="nk-tb-col py-0 d-flex w-100 justify-content-end fs-12px">
    <NumberFormat
      value={billing > 0 ? billing : '-'}
      displayType="text"
      {...currencyFormat}
    />
  </div>
);

const Plan = ({ name, isDraft, url, initials, showAvatar = true }) => (
  <div className="user-info">
    <span className="tb-lead">
      <Link to={`${url}`} className="project-title">
        {showAvatar && (
          <div className="user-avatar bg-light">
            <span>{initials}</span>
          </div>
        )}
        <div className="project-info">
          <h6 className="title">{name}</h6>
        </div>
      </Link>
    </span>
    <div className="d-none d-md-flex">
      {isDraft && (
        <span
          className={`badge rounded-pill mt-1 bg-${beneficiaryStatus.style.DRAFT} text-white`}
        >
          {i18n.t('Draft')}
        </span>
      )}
    </div>
  </div>
);

const SocietyValue = ({ value }) => (
  <div className="nk-tb-col py-0 d-flex w-100 justify-content-end mr-1 fs-12px">
    <NumberFormat
      value={value > 0 ? value : '-'}
      displayType="text"
      {...currencyFormat}
    />
  </div>
);

const SocietyPartnerValue = ({ value }) => (
  <div className="nk-tb-col py-0 d-flex w-100 justify-content-end mr-1">
    <NumberFormat
      value={value > 0 ? value : '-'}
      displayType="text"
      {...currencyFormat}
    />
  </div>
);

const SocietyPartnerPercent = ({
  percent,
  progressBar = true,
  decimals = false,
  decimalScale = 2,
}) => (
  <div className="project-list-progress w-100 ml-2 fs-12px">
    {progressBar ? (
      <div className="progress progress-pill w-60 bg-light">
        <div
          className="progress-bar"
          data-progress={percent > 0 ? percent : 0}
          style={{ width: `${percent > 0 ? percent : 0}%` }}
        />
      </div>
    ) : (
      <></>
    )}
    {decimals ? (
      <div
        className={`project-progress-percent text-right ${
          progressBar ? 'w-40' : 'w-100'
        }`}
      >
        <NumberFormat
          value={percent}
          displayType="text"
          {...numberFormatDecimals}
          decimalScale={+percent ? decimalScale : 0}
        />
        %
      </div>
    ) : (
      <div className="project-progress-percent text-right">
        {`${percent > 0 ? percent : 0}%`}
      </div>
    )}
  </div>
);

const BadgeText = ({ text, style, outline = true }) =>
  outline ? (
    <span className={`badge badge-outline-${style} ml-1`}>{text}</span>
  ) : (
    <span className={`badge badge-${style} ml-1`}>{text}</span>
  );

const InvitationStatus = ({ text, style, outline = true, isSuperAdmin }) => {
  const badgeStyle = outline ? `badge-outline-${style}` : `badge-${style}`;

  return isSuperAdmin ? (
    <div className="d-flex flex-column">
      <span className={`badge ${badgeStyle} ml-1`}>{text}</span>
    </div>
  ) : (
    <span className={`badge ${badgeStyle} ml-1`}>{text}</span>
  );
};

const ConsolidationPercent = ({ consolidatedPercent }) => (
  <span className="w-100 d-flex justify-content-end">
    <div className="progress progress-lg">
      <div
        className="progress-bar progress-bar-striped progress-bar-animated"
        data-progress={consolidatedPercent}
        style={{ width: { consolidatedPercent } }}
      >
        {consolidatedPercent}%
      </div>
    </div>
  </span>
);

const TransactionReference = ({ transaction, url }) => {
  const excludedOps = [
    operationTypes.ADD_PARTNER,
    operationTypes.ADD_PARTNERS,
    operationTypes.ADD_BENEFICIARY,
  ];
  return (
    <>
      <div className="text-center">
        {!excludedOps.includes(transaction.operationType) ? (
          <span>
            <Link
              to={`${url}/${transaction.society}/${transaction['_id']}`}
              className="tb-lead text-primary"
            >
              {`#${transaction['_id'].slice(0, 6)}...`}
            </Link>
          </span>
        ) : (
          <span>
            <a className="tb-lead text-primary">
              <em className="icon ni ni-external fs-16px" />
            </a>
          </span>
        )}
      </div>
    </>
  );
};

const TransactionType = ({ transaction, society, url, type }) => {
  const { t } = useTranslation();

  const transactionNames = {
    ADD_PARTNER: t('AddPartner'),
    CONSTITUTION: t('Constitution'),
    CAPITAL_INCREASE: t('CapitalIncrease'),
    CAPITAL_DECREASE: t('CapitalDecrease'),
    SELL_PARTICIPATION: t('SELL_PARTICIPATION'),
    SPLIT: t('Split'),
    RENUMERATION: t('Renumeration'),
    RECLASSIFICATION: t('Reclassification'),
    DIVIDENDS_DISTRIBUTION: t('DividendsDistribution'),
  };

  if (society) {
    const { _id, name } = society;
    const { logo } = society.additional;
    const linkTo = transaction
      ? `${url}/${transaction.society}/${transaction['_id']}`
      : `${url}/${_id}`;
    return (
      <Link to={linkTo} className="project-title">
        <div className={`user-avatar bg-${type}`}>
          {logo ? (
            <img
              src={logo}
              alt={transactionNames[transaction.operationType]}
              className="rounded-circle"
            />
          ) : (
            <span>{name ? name.slice(0, 2).toUpperCase() : ''}</span>
          )}
        </div>
        <div className="project-info">
          {type === 'warning' ? (
            <span className="tb-lead">
              {transactionNames[transaction.operationType]}
            </span>
          ) : (
            <h6 className="title">
              {transactionNames[transaction.operationType]}
            </h6>
          )}
        </div>
      </Link>
    );
  }
};

const TransactionAmount = ({ amount }) => (
  <div className="nk-tb-col py-0 d-flex w-100 justify-content-end">
    <span className="tb-sub tb-amount">
      {amount !== '-' ? (
        <NumberFormat
          value={amount}
          displayType="text"
          renderText={(value) => <div>{value}</div>}
          {...currencyFormatDecimals}
        />
      ) : (
        amount
      )}
    </span>
  </div>
);

const TransactionParticipations = ({ participations }) => (
  <div className="nk-tb-col py-0 d-flex w-100 justify-content-end text-primary">
    <NumberFormat
      value={participations || 0}
      displayType="text"
      {...numberFormat}
      decimals={0}
    />
  </div>
);

const AttendanceBadge = ({ assistant }) => {
  let badgeData = { elem: '', text: '' };
  if (assistant?.isRepresented) {
    badgeData = { elem: 'badge-info', text: i18n.t('Represented') };
  } else if (assistant?.assists) {
    badgeData = { elem: 'badge-success', text: i18n.t('Yes') };
  } else if (assistant?.isAbsent) {
    badgeData = { elem: 'badge-danger', text: i18n.t('Nope') };
  }
  return (
    <span className={`badge badge-check ${badgeData.elem}`}>
      {badgeData.text}
    </span>
  );
};

export default {
  ColoredDate,
  PlainText,
  PlainTextLink,
  PlainDate,
  NumberNoDecimals,
  NumberDecimals,
  CurrencyNoDecimals,
  CurrencyDecimals,
  PercentNoDecimals,
  PercentDecimals,
  User,
  Employee,
  EmployeesPlan,
  InvitationStatus,
  BadgeText,
  Beneficiary,
  Society,
  SocietyMobile,
  SocietyPartners,
  SocietyBilling,
  SocietyValue,
  SocietyPartnerValue,
  SocietyPartnerPercent,
  ConsolidationPercent,
  TransactionReference,
  TransactionType,
  TransactionAmount,
  TransactionParticipations,
  Plan,
  AttendanceBadge,
};
