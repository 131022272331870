function downloadOptions(i18n) {
  return [
    {
      icon: 'ni-book-read',
      text: i18n.t('EquityStory'),
      action: ({ handleExportTable, buttons }) => {
        handleExportTable(buttons.equity);
      },
      dropdown: true,
    },
    {
      icon: 'ni-book-read',
      text: i18n.t('CommercialRegister'),
      action: ({ handleExportTable, buttons }) => {
        handleExportTable(buttons.comercial);
      },
      dropdown: true,
    },
    {
      icon: 'ni-book-read',
      text: i18n.t('Summary'),
      action: ({ handleExportTable, buttons }) => {
        handleExportTable(buttons.summary);
      },
      dropdown: true,
    },
    {
      icon: 'ni-book-read',
      text: i18n.t('SummaryAccumulated'),
      action: ({ handleExportTable, buttons }) => {
        handleExportTable(buttons.summaryaccumulated);
      },
      dropdown: true,
    },
  ];
}
export default downloadOptions;
