import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { updateSociety } from 'redux/actions/societyActions';
import { Modal } from 'react-bootstrap';
import store from 'redux/store';
import { setModal } from 'redux/actions/modalActions';
import Swal from 'sweetalert2';

function ChangeCouncilMinuteNameModal({ actualSociety, currentCouncilMinute }) {
  const { t } = useTranslation();

  const [councilMinuteName, setCouncilMinuteName] = useState();

  function saveCouncilMinute() {
    const newCouncilMinuteData = actualSociety?.councilMinutes.map(
      (councilMinute) => {
        if (councilMinute['_id'] === currentCouncilMinute['_id']) {
          return { ...councilMinute, label: councilMinuteName };
        }
        return councilMinute;
      }
    );

    store.dispatch(
      updateSociety(
        actualSociety['_id'],
        {
          councilMinutes: newCouncilMinuteData,
        },
        false
      )
    );
    store.dispatch(setModal(null));
    return Swal.fire({
      icon: 'success',
      title: `<h4 class="nk-modal-title">${t(
        'CouncilMinuteNameUpdatedSuccessfully'
      )}<br></h4>`,
      confirmButtonText: t('OK'),
      allowOutsideClick: false,
      showCancelButton: false,
      confirmButtonColor: '#6576FF',
    });
  }
  return (
    <>
      <Modal.Header>
        <h5>{t('UpdateCouncilMinute')}</h5>
        <a className="close" onClick={() => store.dispatch(setModal(null))}>
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>

      <Modal.Body>
        <form className="form-validate is-alter" onSubmit={saveCouncilMinute}>
          <div className="form-group">
            <label className="form-label" htmlFor="full-name">
              {t('EnterNewName')}
            </label>
            <div className="form-control-wrap">
              <input
                type="text"
                className="form-control"
                value={councilMinuteName}
                onChange={(event) => setCouncilMinuteName(event.target.value)}
              />
            </div>
          </div>

          <button
            type="submit"
            className="btn btn-lg btn-primary"
            disabled={!councilMinuteName}
          >
            {t('Save')}
          </button>
        </form>
      </Modal.Body>
    </>
  );
}

export default ChangeCouncilMinuteNameModal;
