/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-plusplus */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import { format } from 'date-fns';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import store from 'redux/store';
import {
  addDocument,
  getDocumentsByReference,
} from 'redux/actions/documentActions';
import { setModal } from 'redux/actions/modalActions';
import { getUsers, updateUser } from 'redux/actions/userActions';
import { updateSociety } from 'redux/actions/societyActions';
import { getPartnerCategories } from 'redux/actions/partnerCategoryActions';
import { getBeneficiaryData } from 'redux/actions/beneficiaryActions';

import { getActualSociety } from 'utils/filters';

import {
  EMAIL_PATTERN,
  MAX_FILESIZE_ALLOWED,
  SELECCIONAR_SOCIO,
  SELECT_CATEGORY,
} from 'constants/defaultConstants';
import fileTypes from 'constants/fileTypes';
import userTypes from 'constants/userTypes';
import civilStatus from 'constants/civilStatus';
import documentTypes from 'constants/documentTypes';
import { dateFormatInverted } from 'constants/formats';
import legalPersonDocumentTypes from 'constants/legalPersonDocumentTypes';

import statusTypes from 'components/SocietyRepresentative/statusTypes';
import statusTypesOptions from 'components/SocietyRepresentative/statusTypeOptions';
import representativeTypes from 'components/SocietyRepresentative/representativeTypes';
import representativeTypesOptions from 'components/SocietyRepresentative/representativeTypesOptions';

import './EditModal.scss';

const rolesWithMultipleEmails = [userTypes.PARTNER, userTypes.BENEFICIARY];

const extraEmailsAllowed = {
  [userTypes.PARTNER]: 4,
  [userTypes.BENEFICIARY]: 1,
};

const userLabels = {
  [userTypes.PARTNER]: () => 'Partner',
  [userTypes.BENEFICIARY]: () => 'Beneficiary',
  [userTypes.BENEFICIARY_DRAFT]: () => 'Beneficiary draft',
  [userTypes.INVESTOR]: () => 'Investor',
  [userTypes.USER]: () => 'User',
};

const EditModal = ({
  user,
  societyId,
  userType,
  setBeneficiarySocietyData,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isAdmin = useSelector((state) => state?.society?.role?.isAdmin);
  const documents = useSelector((state) => state?.documents);
  const loggedUser = useSelector((state) => state.user);
  const actualSociety = useSelector((state) => state?.society?.actualSociety);
  const partnerCategories = useSelector((state) => state?.partnerCategories);

  const [page, setPage] = useState(0);
  const [name, setName] = useState(user?.name || '');
  const [email, setEmail] = useState(user?.email);
  const [cif, setCif] = useState(user?.cif);
  const [jobTitle, setJobTitle] = useState(user?.jobTitle || '');
  const [phone, setPhone] = useState(user?.phone || '');
  const [birthdate, setBirthdate] = useState(
    user?.birthdate ? format(new Date(user?.birthdate), dateFormatInverted) : ''
  );
  const [image, setImage] = useState(user?.image);
  const [nationality, setNationality] = useState(user?.nationality);
  const [line1, setLine1] = useState(user?.address?.line1 || '');
  const [city, setCity] = useState(user?.address?.city || '');
  const [zip, setZip] = useState(user?.address?.zip || '');
  const [country, setCountry] = useState(user?.address?.country || '');
  const [sindication, setSindication] = useState(
    actualSociety?.partners?.find(
      (partner) => partner?.['_id'] === user?.sindication
    )
  );
  const [emails, setEmails] = useState([]);
  const [currentEmail, setCurrentEmail] = useState('');
  const [civilStatusSelected, setCivilStatusSelected] = useState(
    user?.civilStatus || ''
  );
  const [civilStatusOptionValue, setCivilStatusOptionValue] = useState('');
  const [sindicationOptionValue, setSindicationOptionValue] = useState({
    value: SELECCIONAR_SOCIO,
    label: SELECCIONAR_SOCIO,
  });
  const [sindicationPartnerOptions, setSindicationPartnerOptions] = useState(
    []
  );
  const [employeeNumber, setEmployeeNumber] = useState(
    user?.employeeNumber || ''
  );
  const [hasTaxAddress, setHasTaxAddress] = useState(
    !!(
      user?.taxAddress?.line1 ||
      user?.taxAddress?.city ||
      user?.taxAddress?.zip ||
      user?.taxAddress?.country
    )
  );
  const [taxLine1, setTaxLine1] = useState(user?.taxAddress?.line1 || '');
  const [taxCity, setTaxCity] = useState(user?.taxAddress?.city || '');
  const [taxZip, setTaxZip] = useState(user?.taxAddress?.zip || '');
  const [taxCountry, setTaxCountry] = useState(user?.taxAddress?.country || '');
  const [bankAccountNumber, setBankAccountNumber] = useState(
    user?.bankAccountNumber || ''
  );
  const [hideAccountNumber, setHideAccountNumber] = useState(
    !!user?.bankAccountNumber
  );

  const [constitutionFile, setConstitutionFile] = useState();
  const [constitutionDocumentName, setConstitutionDocumentName] = useState();
  const [appointmentFile, setAppointmentFile] = useState();
  const [appintmentDocumentName, setAppointmentDocumentName] = useState();
  const [ownershipFile, setOwnershipFile] = useState();
  const [ownershipDocumentName, setOwnershipDocumentName] = useState();
  const [register, setRegister] = useState();
  const [section, setSection] = useState();
  const [sheet, setSheet] = useState();
  const [representativeName, setRepresentativeName] = useState();
  const [representativeEmail, setRepresentativeEmail] = useState();
  const [representativeInitDate, setRepresentativeInitDate] = useState();
  const [representativeEndDate, setRepresentativeEndDate] = useState();
  const [representativeType, setRepresentativeType] = useState();
  const [representativeStatus, setRepresentativeStatus] = useState();
  const [typeOptionValue, setTypeOptionValue] = useState();
  const [statusOptionValue, setStatusOptionValue] = useState();
  const [representativeNif, setRepresentativeNif] = useState();
  const [representativeAddress, setRepresentativeAddress] = useState();
  const [representativeNationality, setRepresentativeNationality] = useState();
  const [naturalPerson, setNaturalPerson] = useState();
  const [partnerCategory, setPartnerCategory] = useState('');
  const [partnerCategoriesOptions, setPartnerCategoriesOptions] = useState([]);
  const [selectedPartnerCategoryOption, setSelectedPartnerCategoryOption] =
    useState({
      value: SELECT_CATEGORY,
      label: t('SelectCategory'),
    });

  const animatedComponents = makeAnimated();

  const getMaskValue = (value) => {
    if (!value) return '';

    return value?.replace(/.(?=.{4})/g, '*');
  };

  const setJuridicPersonValues = () => {
    const { representative } = user;
    setRepresentativeName(representative?.name);
    setRepresentativeEmail(representative?.email);
    if (representative?.initDate) {
      setRepresentativeInitDate(
        format(new Date(representative.initDate), 'yyyy-MM-dd')
      );
    }
    if (representative?.endDate) {
      setRepresentativeEndDate(
        format(new Date(representative.endDate), 'yyyy-MM-dd')
      );
    }
    if (representative?.authority) {
      setRepresentativeType(representative?.authority);
      setTypeOptionValue({
        label: representativeTypes.name[representative?.authority],
        value: representative?.authority,
      });
    }
    if (representative?.status) {
      setRepresentativeStatus(representative?.status);
      setStatusOptionValue({
        label: statusTypes.name[representative?.status],
        value: representative?.status,
      });
    }
    setRepresentativeNif(representative?.nif);
    setRepresentativeNationality(representative?.nationality);
    setRepresentativeAddress(representative?.address);
  };

  const hasPersonOptions = () => userType === userTypes.PARTNER;

  const handleUploadDocument = async (file, subcategory) => {
    const newDocument = {
      file,
      date: new Date(Date.now()),
      size: file?.size,
      name: file?.name,
      author: user?.['_id'],
      society: societyId,
      fileType: fileTypes[file?.type],
      category: documentTypes.SCRIPTURES,
      subcategory,
      description: t('DocumentLinkedManually'),
      isForAdmins: false,
    };
    const uploadedDocument = await store.dispatch(addDocument(newDocument));
    const data = {
      docs: {
        add: uploadedDocument,
        ...{ partner: [user?.['_id']] },
      },
    };
    store.dispatch(updateSociety(societyId, data, false));
    store.dispatch(getDocumentsByReference({ societyId }));
  };

  const handleChangeCivilStatus = (selectedOption) => {
    setCivilStatusSelected(selectedOption.value);
    setCivilStatusOptionValue(selectedOption);
  };

  const handleChangeSelectedPartner = (selectedOption) => {
    setSindication(selectedOption.value);
    setSindicationOptionValue(selectedOption);
  };

  const handleChangeSelectedType = (selectedOption) => {
    setRepresentativeType(selectedOption.value);
    setTypeOptionValue(selectedOption);
  };

  const handleChangeSelectedStatus = (selectedOption) => {
    setRepresentativeStatus(selectedOption.value);
    setStatusOptionValue(selectedOption);
  };

  const handleChangeTaxAddress = () => {
    if (hasTaxAddress) {
      setTaxLine1('');
      setTaxCity('');
      setTaxZip('');
      setTaxCountry('');
    }
    setHasTaxAddress(!hasTaxAddress);
  };

  const handleHideAccountNumber = () => {
    setHideAccountNumber(!hideAccountNumber);
  };

  function handleChangePersonValue() {
    if (!naturalPerson && page > 1) {
      setPage(1);
    }
    setNaturalPerson(!naturalPerson);
  }

  const handlePartnerCategoryOptionChange = (selectedOption) => {
    setPartnerCategory(selectedOption.value);
    setSelectedPartnerCategoryOption(selectedOption);
  };

  const handleRemoveEmail = (index) => {
    const newEmails = [...emails];
    newEmails[index] = currentEmail;
    newEmails.splice(index, 1);
    if (index === emails.length - 1) {
      setCurrentEmail(newEmails[newEmails.length - 1]);
    }
    setEmails(newEmails);
  };

  const handleAddEmail = () => {
    const newEmails = [...emails];
    if (emails.length > 0) {
      newEmails[newEmails.length - 1] = currentEmail;
    }
    newEmails.push('');
    setCurrentEmail('');
    setEmails(newEmails);
  };

  const isValidEmail = (email) => {
    if (email) {
      const re = /\S+@\S+\.\S+/;
      return re.test(email);
    }
  };

  async function fileSelected(event) {
    if (event.target.files.length > 0) {
      const reader = new FileReader();
      reader.onloadend = (e) => {
        setImage(e.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  async function getOtherUsers(users) {
    try {
      const otherUsersList = await dispatch(
        getUsers({
          usersList: users,
        })
      );
      if (otherUsersList?.length) {
        setEmails(
          otherUsersList?.length
            ? otherUsersList.map((curr) => curr.email)
            : ['']
        );
        setCurrentEmail(otherUsersList[otherUsersList.length - 1]?.email || '');
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  }

  async function onUpdateUser(event) {
    event.preventDefault();

    const newData = {
      name,
      email,
      phone,
      birthdate,
      image,
      nationality,
      address: {
        line1,
        city,
        zip,
        country,
      },
    };

    const taxAddress = {
      line1: hasTaxAddress ? taxLine1 : '',
      city: hasTaxAddress ? taxCity : '',
      zip: hasTaxAddress ? taxZip : '',
      country: hasTaxAddress ? taxCountry : '',
    };

    if (userType === userTypes.INVESTOR) {
      await dispatch(
        updateUser(
          user?.user,
          {
            investor: user?.['_id'],
            society: societyId,
            investorData: { ...newData },
          },
          loggedUser?.['_id']
        )
      );
      getActualSociety(loggedUser, societyId);
      dispatch(setModal(null));
    }

    if (userType === userTypes.PARTNER) {
      if (
        currentEmail !== '' &&
        isValidEmail(currentEmail) &&
        currentEmail !== emails[emails.length - 1]
      ) {
        emails.push(currentEmail);
      }
      const newEmails = emails.filter(
        (curr) => curr !== '' && isValidEmail(curr)
      );
      newData['cif'] = cif;
      newData['category'] = partnerCategory || null;
      newData['sindication'] = sindication || null;
      newData['isNaturalPerson'] = naturalPerson;
      newData['employeeNumber'] = employeeNumber;
      newData['taxAddress'] = taxAddress;
      newData['bankAccountNumber'] = bankAccountNumber;

      if (naturalPerson) {
        newData['civilStatus'] = civilStatusSelected;
      } else {
        newData['legalInfo'] = { register, section, sheet };
        newData['representative'] = {
          name: representativeName,
          email: representativeEmail,
          initDate: representativeInitDate,
          endDate: representativeEndDate,
          authority: representativeType,
          status: representativeStatus,
          nif: representativeNif,
          nationality: representativeNationality,
          address: representativeAddress,
        };
        if (constitutionFile) {
          await handleUploadDocument(
            constitutionFile,
            legalPersonDocumentTypes.CONSTITUTION
          );
        }
        if (appointmentFile) {
          await handleUploadDocument(
            appointmentFile,
            legalPersonDocumentTypes.APPOINTMENT
          );
        }
        if (ownershipFile) {
          await handleUploadDocument(
            ownershipFile,
            legalPersonDocumentTypes.OWNERSHIP
          );
        }
      }

      await dispatch(
        updateUser(
          user?.user,
          {
            partner: user?.['_id'],
            society: societyId,
            partnerData: { ...newData, newEmails },
          },
          loggedUser?.['_id']
        )
      );
      getActualSociety(loggedUser, societyId);
      dispatch(setModal(null));
    } else if (userType === userTypes.BENEFICIARY) {
      if (
        currentEmail !== '' &&
        isValidEmail(currentEmail) &&
        currentEmail !== emails[emails.length - 1]
      ) {
        emails.push(currentEmail);
      }
      const newEmails = emails.filter(
        (curr) => curr !== '' && isValidEmail(curr)
      );
      newData['cif'] = cif;
      newData['jobTitle'] = jobTitle;
      newData['employeeNumber'] = employeeNumber;
      newData['taxAddress'] = taxAddress;
      newData['bankAccountNumber'] = bankAccountNumber;

      await dispatch(
        updateUser(
          user?.user,
          {
            beneficiary: user?.['_id'],
            society: societyId,
            beneficiaryData: { ...newData, newEmails },
          },
          loggedUser?.['_id']
        )
      );
      getBeneficiaryData(
        {
          beneficiaryId: user['_id'],
          societyId,
          userId: loggedUser?.['_id'],
          source: 'society',
        },
        setBeneficiarySocietyData
      );
      getActualSociety(loggedUser, societyId);
      dispatch(setModal(null));
    } else if (userType === userTypes.BENEFICIARY_DRAFT) {
      newData['cif'] = cif;
      newData['jobTitle'] = jobTitle;
      newData['employeeNumber'] = employeeNumber;
      newData['taxAddress'] = taxAddress;
      newData['bankAccountNumber'] = bankAccountNumber;

      await store.dispatch(
        updateUser(
          user?.user,
          {
            beneficiaryDraft: user?.['_id'],
            society: societyId,
            beneficiaryData: { ...newData },
          },
          loggedUser?.['_id']
        )
      );
      getActualSociety(loggedUser, societyId);
      dispatch(setModal(null));
    } else if (userType === userTypes.USER) {
      dispatch(updateUser(user?.['_id'], newData, loggedUser?.['_id']));
      dispatch(setModal(null));
    } else {
      dispatch(setModal(null));
    }
  }

  const getUserLabel = (user) => t(userLabels[user]?.() ?? '');

  useEffect(() => {
    if (userType === userTypes.PARTNER) {
      if (user?.otherUsers?.length) {
        getOtherUsers(user?.otherUsers);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userType]);

  useEffect(() => {
    if (actualSociety) {
      dispatch(getDocumentsByReference({ societyId: actualSociety['_id'] }));
      if (actualSociety?.partners) {
        const options = actualSociety?.partners
          ?.filter((partner) => partner?._id !== user?._id)
          .map((partner) => ({
            value: partner?.['_id'],
            label: `${partner?.cif} | ${partner?.name}`,
          }));
        setSindicationPartnerOptions(options);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety]);

  useEffect(() => {
    if (userType === userTypes.PARTNER) {
      dispatch(getPartnerCategories(actualSociety?._id));
    }
    if (userType !== userTypes.USER) {
      if (sindication) {
        const sindicationPartner = actualSociety?.partners?.find(
          (partner) => partner?.['_id'] === user?.sindication
        );
        setSindicationOptionValue({
          value: sindication || '',
          label: `${sindicationPartner?.cif} | ${sindicationPartner?.name}`,
        });
      }

      if (!user?.isNaturalPerson) {
        setJuridicPersonValues();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userType !== userTypes.USER) {
      if (!user?.representative?.status && representativeEndDate) {
        if (new Date(representativeEndDate) - new Date() > 0) {
          setRepresentativeStatus(statusTypes.var.CURRENT);
          setStatusOptionValue({
            label: statusTypes.name[statusTypes.var.CURRENT],
            value: statusTypes.var.CURRENT,
          });
        } else {
          setRepresentativeStatus(statusTypes.var.REVOKED);
          setStatusOptionValue({
            label: statusTypes.name[statusTypes.var.REVOKED],
            value: statusTypes.var.REVOKED,
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [representativeEndDate]);

  useEffect(() => {
    if (userType !== userTypes.USER) {
      if (user?.isNaturalPerson) {
        setCivilStatusOptionValue({
          value: user?.civilStatus || '',
          label: t(user?.civilStatus) || t('SelectCivilStatus'),
        });
      } else {
        setRegister(user?.legalInfo?.register);
        setSection(user?.legalInfo?.section);
        setSheet(user?.legalInfo?.sheet);
      }
      setNaturalPerson(user?.isNaturalPerson);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (documents && userType !== userTypes.USER) {
      const constitutionDocName = documents?.filter((document) =>
        user?.documents.some(
          (userDocumentId) =>
            userDocumentId === document['_id'] &&
            document?.subcategory === legalPersonDocumentTypes.CONSTITUTION
        )
      )?.[0]?.name;
      setConstitutionDocumentName(constitutionDocName);
      const appointmentDocName = documents?.filter((document) =>
        user?.documents.some(
          (userDocumentId) =>
            userDocumentId === document['_id'] &&
            document?.subcategory === legalPersonDocumentTypes.APPOINTMENT
        )
      )?.[0]?.name;
      setAppointmentDocumentName(appointmentDocName);
      const ownershipDocName = documents?.filter((document) =>
        user?.documents.some(
          (userDocumentId) =>
            userDocumentId === document['_id'] &&
            document?.subcategory === legalPersonDocumentTypes.OWNERSHIP
        )
      )?.[0]?.name;
      setOwnershipDocumentName(ownershipDocName);
    }
  }, [documents, user]);

  useEffect(() => {
    if (constitutionFile) {
      setConstitutionDocumentName(constitutionFile?.name);
    }
    if (appointmentFile) {
      setAppointmentDocumentName(appointmentFile?.name);
    }
    if (ownershipFile) {
      setOwnershipDocumentName(ownershipFile?.name);
    }
  }, [constitutionFile, appointmentFile, ownershipFile]);

  useEffect(() => {
    if (!naturalPerson) {
      setJuridicPersonValues();
    }
  }, [naturalPerson]);

  useEffect(() => {
    if (partnerCategories?.length) {
      const partnerCategoriesOptions = partnerCategories.map((category) => ({
        value: category['_id'],
        label: category.name,
      }));
      setPartnerCategoriesOptions(partnerCategoriesOptions);

      if (user?.category) {
        const selectedPartnerCategory = partnerCategories.find(
          (category) => category['_id'] === user?.category
        );
        setSelectedPartnerCategoryOption({
          value: selectedPartnerCategory['_id'],
          label: selectedPartnerCategory.name,
        });
      }
    } else {
      setPartnerCategoriesOptions([]);
    }
  }, [partnerCategories]);

  return (
    <>
      <Modal.Header size="xl">
        <div className="row">
          <h5 className="title col-4">
            {t('UpdateData')}&nbsp;
            <span className="text-muted ff-alt fs-14px text-right">
              ({getUserLabel(userType)})
            </span>
          </h5>

          {hasPersonOptions() && (
            <div className="col-4">
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  className="custom-control-input"
                  name="natural-person"
                  id="natural-person"
                  value={naturalPerson}
                  checked={naturalPerson}
                  onChange={() => handleChangePersonValue()}
                />
                <label
                  className="custom-control-label"
                  htmlFor="natural-person"
                >
                  {t('NaturalPerson')}
                </label>
              </div>
            </div>
          )}
          {hasPersonOptions() && (
            <div className="col-4">
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  className="custom-control-input"
                  name="legal-person"
                  id="legal-person"
                  value={!naturalPerson}
                  checked={!naturalPerson}
                  onChange={handleChangePersonValue}
                />
                <label className="custom-control-label" htmlFor="legal-person">
                  {t('LegalPerson')}
                </label>
              </div>
            </div>
          )}
        </div>
        <a
          className="close cursor-pointer"
          onClick={() => dispatch(setModal(null))}
        >
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
      <Modal.Body size="xl">
        <ul className="nk-nav nav nav-tabs mt-xl">
          <li className="nav-item" onClick={() => setPage(0)}>
            <p className={`nav-link ${page === 0 && 'active'}`}>
              {t('PersonalInformation')}
            </p>
          </li>
          <li className="nav-item" onClick={() => setPage(1)}>
            <p className={`nav-link ${page === 1 && 'active'}`}>
              {t('Address')}
            </p>
          </li>
          {!naturalPerson && hasPersonOptions() && (
            <li className="nav-item" onClick={() => setPage(2)}>
              <p className={`nav-link ${page === 2 && 'active'}`}>
                {t('DeedAndRegistryData')}
              </p>
            </li>
          )}
          {!naturalPerson && hasPersonOptions() && (
            <li className="nav-item" onClick={() => setPage(3)}>
              <p className={`nav-link ${page === 3 && 'active'}`}>
                {t('LegalPartnerRepresentative')}
              </p>
            </li>
          )}
          <li className="nav-item" onClick={() => setPage(4)}>
            <p className={`nav-link ${page === 4 && 'active'}`}>
              {t('OtherInformation')}
            </p>
          </li>
        </ul>

        <div className="tab-content">
          {/* PERSONAL INFORMATION TAB */}
          {page === 0 && (
            <div className={`tab-pane ${page || 'active'}`}>
              <div className="row gy-4">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label w-100" htmlFor="full-name">
                      {user?.isNaturalPerson || !hasPersonOptions()
                        ? t('Name')
                        : t('SocialDenomination')}
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      value={name}
                      onChange={(event) => setName(event.target.value)}
                      placeholder={t('IntroduceFullName')}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  {userType === userTypes.PARTNER ||
                  userType === userTypes.BENEFICIARY ||
                  userType === userTypes.BENEFICIARY_DRAFT ? (
                    <div className="form-group">
                      <label className="form-label" htmlFor="partner-name">
                        {t('FiscalIdentification')}
                      </label>
                      <div className="form-control-wrap">
                        <div className="form-icon form-icon-left">
                          <em className="icon ni ni-cc-alt2" />
                        </div>
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          value={cif}
                          onChange={(event) => setCif(event.target.value)}
                          placeholder={t('EnterTheCifOrNif')}
                          required
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="form-group">
                      <label className="form-label w-100" htmlFor="phone-no">
                        {t('Telephone')}
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="phone-no"
                        value={phone}
                        onChange={(event) => setPhone(event.target.value)}
                        placeholder={t('IntroducePhoneNumber')}
                      />
                    </div>
                  )}
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label w-100" htmlFor="birth-day">
                      {t('Email')}
                    </label>
                    <input
                      type="email"
                      className="form-control form-control-lg"
                      value={email}
                      onChange={(event) => setEmail(event.target.value)}
                      disabled={
                        !isAdmin &&
                        userType === userTypes.PARTNER &&
                        loggedUser['_id'] !== user?.user
                      }
                      placeholder={t('IntroduceNewEmail')}
                      pattern={EMAIL_PATTERN}
                    />
                  </div>

                  {emails?.map((email, index) => (
                    <React.Fragment key={email}>
                      <div className="form-control-wrap mb-3">
                        <div className="input-group">
                          <input
                            type="email"
                            className="form-control form-control-lg"
                            value={
                              index + 1 === emails.length ? currentEmail : email
                            }
                            onChange={(event) =>
                              setCurrentEmail(event.target.value)
                            }
                            placeholder={t('IntroduceNewEmail')}
                            pattern={EMAIL_PATTERN}
                          />

                          <div className="input-group-append">
                            <button
                              className="btn btn-outline-primary btn-dim px-1"
                              type="button"
                              onClick={() => handleRemoveEmail(index)}
                            >
                              <em className="icon ni ni-minus" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  ))}

                  {rolesWithMultipleEmails.includes(userType) ? (
                    emails.length + 1 <= extraEmailsAllowed[userType] && (
                      <button
                        type="button"
                        className="btn btn-lg btn-dim btn-outline-primary w-100"
                        disabled={
                          emails.length > 0 && !isValidEmail(currentEmail)
                        }
                        onClick={() => {
                          handleAddEmail();
                        }}
                      >
                        <em className="icon ni ni-plus" />
                        <span>{t('AddAnotherEmail')}</span>
                      </button>
                    )
                  ) : (
                    <></>
                  )}
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label w-100" htmlFor="birth-day">
                      {user?.isNaturalPerson || !hasPersonOptions()
                        ? t('DateOfBirth')
                        : t('ConstitutionDate')}
                    </label>
                    <input
                      type="date"
                      className="form-control form-control-lg date-picker"
                      value={birthdate}
                      onChange={(event) => setBirthdate(event.target.value)}
                      placeholder={t('IntroduceBirthDate')}
                      max="2100-01-01"
                      min="1000-01-01"
                    />
                  </div>
                </div>
                {(userType === userTypes.BENEFICIARY ||
                  userType === userTypes.BENEFICIARY_DRAFT) && (
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="partner-job">
                        {t('JobTitle')}
                      </label>
                      <div className="form-control-wrap">
                        <input
                          type="text"
                          className="form-control form-control-lg text-nowrap overflow-hidden text-truncate"
                          value={jobTitle}
                          onChange={(event) => setJobTitle(event.target.value)}
                          placeholder={t('IntroduceJobTitle')}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {[
                  userTypes.PARTNER,
                  userTypes.BENEFICIARY,
                  userTypes.BENEFICIARY_DRAFT,
                ].includes(userType) && (
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="employee-number">
                        {t('EmployeeNumber')}
                      </label>
                      <div className="form-control-wrap">
                        <input
                          type="text"
                          className="form-control form-control-lg text-nowrap overflow-hidden text-truncate"
                          value={employeeNumber}
                          onChange={(event) =>
                            setEmployeeNumber(event.target.value)
                          }
                          placeholder={t('IntroduceEmployeeNumber')}
                        />
                      </div>
                    </div>
                  </div>
                )}

                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label w-100" htmlFor="nationality">
                      {t('Nationality')}
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-lg text-nowrap overflow-hidden text-truncate"
                      value={nationality}
                      onChange={(event) => setNationality(event.target.value)}
                      placeholder={t('IntroduceNationality')}
                    />
                  </div>
                </div>
                {userType !== userTypes.USER && (
                  <>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="form-label w-100" htmlFor="phone-no">
                          {t('Telephone')}
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg text-nowrap overflow-hidden text-truncate"
                          id="phone-no"
                          value={phone}
                          onChange={(event) => setPhone(event.target.value)}
                          placeholder={t('IntroducePhoneNumber')}
                        />
                      </div>
                    </div>
                    {userType === userTypes.PARTNER && (
                      <>
                        <div className="col-md-6">
                          <label className="form-label" htmlFor="default-01">
                            {t('Category')}
                          </label>
                          <div className="form-control-wrap">
                            <div className="form-icon form-icon-left">
                              <em className="icon ni ni-user" />
                            </div>
                            <Select
                              closeMenuOnSelect
                              className="react-select react-select-lg"
                              value={selectedPartnerCategoryOption}
                              options={partnerCategoriesOptions}
                              components={animatedComponents}
                              onChange={handlePartnerCategoryOptionChange}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label
                              className="form-label w-100"
                              htmlFor="sindication-shares"
                            >
                              {t('SindicationShares')}
                            </label>

                            <div className="form-control-wrap">
                              <div className="form-icon form-icon-left">
                                <em className="icon ni ni-user" />
                              </div>
                              <Select
                                closeMenuOnSelect
                                value={sindicationOptionValue}
                                className="react-select react-select-lg"
                                options={sindicationPartnerOptions}
                                components={animatedComponents}
                                onChange={handleChangeSelectedPartner}
                              />
                            </div>
                          </div>
                        </div>
                        {naturalPerson && (
                          <div className="col-md-6">
                            <div className="form-group">
                              <label
                                className="form-label w-100"
                                htmlFor="sindication-shares"
                              >
                                {t('CivilStatus')}
                              </label>

                              <div className="form-control-wrap">
                                <div className="form-icon form-icon-left">
                                  <em className="icon ni ni-user" />
                                </div>
                                <Select
                                  closeMenuOnSelect
                                  value={civilStatusOptionValue}
                                  className="react-select react-select-lg"
                                  options={Object.entries(civilStatus).map(
                                    ([, value]) => ({
                                      value,
                                      label: t(value),
                                    })
                                  )}
                                  components={animatedComponents}
                                  onChange={handleChangeCivilStatus}
                                  placeholder={t('SelectCivilStatus')}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
                <div className="col-md-3 col-8">
                  <div className="form-group">
                    <label className="form-label" htmlFor="imageInput">
                      {t('Image')}
                    </label>
                    <input
                      type="file"
                      id="imageInput"
                      style={{ display: 'none' }}
                      onChange={fileSelected}
                      accept="image/*"
                    />
                    <label
                      htmlFor="imageInput"
                      className="btn btn-primary form-control-lg"
                    >
                      {t('Choose')}
                    </label>
                  </div>
                </div>
                <div className="col-md-3 col-4">
                  <div
                    className="user-avatar mx-auto lg bg-primary-dim"
                    style={{
                      maxWidth: '80px',
                      maxHeight: '80px',
                    }}
                  >
                    {image ? (
                      <img
                        id="user-image"
                        src={image}
                        alt={name}
                        style={{
                          maxWidth: '80px',
                          maxHeight: '80px',
                        }}
                      />
                    ) : (
                      <span>{name ? name.slice(0, 2).toUpperCase() : ''}</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* ADDRESS TAB */}
          {page === 1 && (
            <div className={`tab-pane ${page && 'active'}`}>
              <div className="row gy-4">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label w-100" htmlFor="address-l1">
                      {t('Address')}
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      id="address-l1"
                      placeholder={t('Line1')}
                      value={line1}
                      onChange={(event) => setLine1(event.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label w-100" htmlFor="address-l2">
                      {t('City')}
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      id="address-l2"
                      placeholder={t('IntroduceCity')}
                      value={city}
                      onChange={(event) => setCity(event.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label w-100" htmlFor="address-st">
                      {t('PostalCode')}
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      id="address-st"
                      placeholder={t('IntroduceZipCode')}
                      value={zip}
                      onChange={(event) => setZip(event.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label
                      className="form-label w-100"
                      htmlFor="address-county"
                    >
                      {t('Country')}
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      id="country-st"
                      placeholder={t('IntroduceCountry')}
                      value={country}
                      onChange={(event) => setCountry(event.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12 mt-2">
                  <div className="form-group mt-2">
                    <div className="custom-control custom-control-xs custom-checkbox">
                      <input
                        type="checkbox"
                        id="checkbox-notifications"
                        className="custom-control-input"
                        checked={hasTaxAddress}
                        onChange={handleChangeTaxAddress}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="checkbox-notifications"
                      >
                        {t('HasFiscalResidenceDifferentFromAddress')}
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              {hasTaxAddress && (
                <>
                  <h6 className="my-4">{t('FiscalResidence')}</h6>

                  <div className="row gy-4">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label
                          className="form-label w-100"
                          htmlFor="tax-address-l1"
                        >
                          {t('Address')}
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          id="tax-address-l1"
                          placeholder={t('Line1')}
                          value={taxLine1}
                          onChange={(event) => setTaxLine1(event.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label
                          className="form-label w-100"
                          htmlFor="tax-address-city"
                        >
                          {t('City')}
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          id="tax-address-city"
                          placeholder={t('IntroduceCity')}
                          value={taxCity}
                          onChange={(event) => setTaxCity(event.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label
                          className="form-label w-100"
                          htmlFor="tax-address-zip"
                        >
                          {t('PostalCode')}
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          id="tax-address-zip"
                          placeholder={t('IntroduceZipCode')}
                          value={taxZip}
                          onChange={(event) => setTaxZip(event.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label
                          className="form-label w-100"
                          htmlFor="tax-address-country"
                        >
                          {t('Country')}
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          id="tax-address-country"
                          placeholder={t('IntroduceCountry')}
                          value={taxCountry}
                          onChange={(event) =>
                            setTaxCountry(event.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}

          {/* SCRIPTURES TAB - JURIDIC PERSON */}
          {page === 2 && (
            <div className={`tab-pane ${page && 'active'}`}>
              <div className="row gy-4">
                <div className="col-md-6">
                  <div className="form-group">
                    <label
                      className="form-label w-100"
                      htmlFor="commercial-register"
                    >
                      {t('CommercialRegister')}
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      id="commercial-register"
                      placeholder={t('EnterCommercialRegister')}
                      value={register}
                      onChange={(event) => setRegister(event.target.value)}
                    />
                  </div>
                </div>
                <div className="form-group col-6">
                  <label className="form-label" htmlFor="default-06">
                    {t('DeedOfConstitution')}
                  </label>
                  <div className="form-control-wrap">
                    <div className="custom-file">
                      <input
                        id="customFile"
                        type="file"
                        accept="application/pdf"
                        className="custom-file-input"
                        onChange={(event) =>
                          setConstitutionFile(event.target.files[0])
                        }
                      />
                      <label className="custom-file-label" htmlFor="customFile">
                        {constitutionDocumentName || t('SelectDocument')}
                      </label>
                    </div>
                  </div>

                  {constitutionFile &&
                    constitutionFile.size > MAX_FILESIZE_ALLOWED.value && (
                      <div
                        className="alert alert-icon alert-danger mt-2"
                        role="alert"
                      >
                        <em className="icon ni ni-alert-circle" />
                        <strong>Archivo no válido</strong>. Excede el tamaño
                        máximo permitido.
                      </div>
                    )}
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label w-100" htmlFor="section">
                      {t('PartnerSection')}
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      id="section"
                      placeholder={t('EnterSection')}
                      value={section}
                      onChange={(event) => setSection(event.target.value)}
                    />
                  </div>
                </div>
                <div className="form-group col-6">
                  <label className="form-label" htmlFor="default-06">
                    {t('DeedAppointmentRepresentative')}
                  </label>
                  <div className="form-control-wrap">
                    <div className="custom-file">
                      <input
                        id="customFile"
                        type="file"
                        accept="application/pdf"
                        className="custom-file-input"
                        onChange={(event) =>
                          setAppointmentFile(event.target.files[0])
                        }
                      />
                      <label className="custom-file-label" htmlFor="customFile">
                        {appintmentDocumentName || t('SelectDocument')}
                      </label>
                    </div>
                  </div>

                  {appointmentFile &&
                    appointmentFile.size > MAX_FILESIZE_ALLOWED.value && (
                      <div
                        className="alert alert-icon alert-danger mt-2"
                        role="alert"
                      >
                        <em className="icon ni ni-alert-circle" />
                        <strong>Archivo no válido</strong>. Excede el tamaño
                        máximo permitido.
                      </div>
                    )}
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label w-100" htmlFor="sheet">
                      {t('Sheet')}
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-lg"
                      id="sheet"
                      placeholder={t('EnterSheet')}
                      value={sheet}
                      onChange={(event) => setSheet(event.target.value)}
                    />
                  </div>
                </div>
                <div className="form-group col-6">
                  <label className="form-label" htmlFor="default-06">
                    {t('DeedOfRealOwnership')}
                  </label>
                  <div className="form-control-wrap">
                    <div className="custom-file">
                      <input
                        id="customFile"
                        type="file"
                        accept="application/pdf"
                        className="custom-file-input"
                        onChange={(event) =>
                          setOwnershipFile(event.target.files[0])
                        }
                      />
                      <label className="custom-file-label" htmlFor="customFile">
                        {ownershipDocumentName || t('SelectDocument')}
                      </label>
                    </div>
                  </div>

                  {ownershipFile &&
                    ownershipFile.size > MAX_FILESIZE_ALLOWED.value && (
                      <div
                        className="alert alert-icon alert-danger mt-2"
                        role="alert"
                      >
                        <em className="icon ni ni-alert-circle" />
                        <strong>Archivo no válido</strong>. Excede el tamaño
                        máximo permitido.
                      </div>
                    )}
                </div>
              </div>
            </div>
          )}

          {/* REPRESENTATION TAB - JURIDIC PERSON */}
          {page === 3 && (
            <div className="row gy-4">
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label"
                    htmlFor="societyRepresentative-socialDenom"
                  >
                    {t('NameAndSurname')} *
                  </label>
                  <input
                    type="text"
                    id="societyRepresentative-socialDenom"
                    className="form-control form-control-lg"
                    value={representativeName}
                    onChange={(event) =>
                      setRepresentativeName(event.target.value)
                    }
                    placeholder={t('NameAndSurname')}
                    required
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label"
                    htmlFor="societyRepresentative-email"
                  >
                    {t('Email')} *
                  </label>
                  <input
                    type="email"
                    className="form-control form-control-lg"
                    id="societyRepresentative-email"
                    value={representativeEmail}
                    onChange={(event) =>
                      setRepresentativeEmail(event.target.value)
                    }
                    placeholder={t('Email')}
                    pattern={EMAIL_PATTERN}
                    required
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label"
                    htmlFor="societyRepresentative-initDate"
                  >
                    {t('RepresentativeDate')}
                  </label>
                  <input
                    type="date"
                    className="form-control form-control-lg date-picker"
                    id="societyRepresentative-initDate"
                    value={representativeInitDate}
                    onChange={(event) =>
                      setRepresentativeInitDate(event.target.value)
                    }
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <div className="d-flex">
                    <label
                      className="form-label"
                      htmlFor="societyRepresentative-endDate"
                    >
                      {t('RevocationDate')}
                    </label>
                    <span className="sub-text ml-1 fs-12px">
                      {t('IndefiniteDefault')}
                    </span>
                  </div>
                  <input
                    type="date"
                    className="form-control form-control-lg date-picker"
                    id="societyRepresentative-endDate"
                    value={representativeEndDate}
                    onChange={(event) =>
                      setRepresentativeEndDate(event.target.value)
                    }
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label"
                    htmlFor="societyRepresentative-representativeType"
                  >
                    {t('TypeOfAuthority')} *
                  </label>
                  <div className="form-control-wrap ">
                    <Select
                      closeMenuOnSelect
                      className="react-select react-select-lg"
                      value={typeOptionValue}
                      options={
                        representativeTypesOptions?.length > 0 &&
                        representativeTypesOptions
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .map((type) => ({
                            value: type.value,
                            label: type.name,
                          }))
                      }
                      placeholder={t('Select')}
                      components={animatedComponents}
                      onChange={handleChangeSelectedType}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label"
                    htmlFor="societyRepresentative-status"
                  >
                    {t('Status')}
                  </label>
                  <Select
                    closeMenuOnSelect
                    className="react-select react-select-lg"
                    value={statusOptionValue}
                    options={
                      statusTypesOptions?.length > 0 &&
                      statusTypesOptions
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((type) => ({
                          value: type.value,
                          label: type.name,
                        }))
                    }
                    placeholder={t('Select')}
                    components={animatedComponents}
                    onChange={handleChangeSelectedStatus}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label"
                    htmlFor="societyRepresentative-nif"
                  >
                    {t('Nif')}
                  </label>
                  <input
                    type="text"
                    id="societyDirectors-cif"
                    className="form-control form-control-lg"
                    value={representativeNif}
                    onChange={(event) =>
                      setRepresentativeNif(event.target.value)
                    }
                    placeholder={t('Nif')}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label"
                    htmlFor="societyRepresentative-nationality"
                  >
                    {t('Nationality')}
                  </label>
                  <input
                    type="text"
                    id="societyRepresentative-nationality"
                    className="form-control form-control-lg"
                    value={representativeNationality}
                    onChange={(event) =>
                      setRepresentativeNationality(event.target.value)
                    }
                    placeholder={t('Nationality')}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    className="form-label"
                    htmlFor="societyRepresentative-address"
                  >
                    {t('Address')}
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    id="societyRepresentative-address"
                    value={representativeAddress}
                    onChange={(event) =>
                      setRepresentativeAddress(event.target.value)
                    }
                    placeholder={t('Address')}
                  />
                </div>
              </div>
            </div>
          )}

          {/* OTHER INFO */}
          {page === 4 && (
            <div className={`tab-pane ${page && 'active'}`}>
              <div className="row gy-4">
                <div className="col-md-6">
                  <div className="form-group">
                    <label
                      className="form-label w-100"
                      htmlFor="commercial-register"
                    >
                      {t('BankAccountNumber')}
                    </label>
                    <div className="form-control-wrap">
                      <div
                        className="form-icon form-icon-right"
                        onClick={handleHideAccountNumber}
                      >
                        {hideAccountNumber ? (
                          <em className="icon ni ni-eye cursor-pointer" />
                        ) : (
                          <em className="icon ni ni-eye-off cursor-pointer" />
                        )}
                      </div>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id="commercial-register"
                        placeholder={t('EnterIBAN')}
                        disabled={hideAccountNumber}
                        value={
                          hideAccountNumber
                            ? getMaskValue(bankAccountNumber)
                            : bankAccountNumber
                        }
                        onChange={(event) =>
                          setBankAccountNumber(event.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="row mt-4">
            <div className="col-12">
              <p className="btn btn-lg btn-primary" onClick={onUpdateUser}>
                {t('SaveChanges')}
              </p>
            </div>
          </div>
        </div>
      </Modal.Body>
    </>
  );
};

export default EditModal;
