import alertBodyTypes from 'components/Alert/alertBodyTypes';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { addAlert } from 'redux/actions/alertActions';
import { setModal } from 'redux/actions/modalActions';
import Swal from 'sweetalert2';
import '../Modals.scss';

const UploadAnnouncementModal = ({
  setAnnouncementFile,
  setIsUploadModalVisible,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [file, setFile] = useState();

  const handleDrop = (event) => {
    event.preventDefault();
    const files = [...event.dataTransfer.files];
    if (files.length > 1) {
      Swal.fire({
        icon: 'info',
        title: `<h4 class="nk-modal-title">${t('MustUploadOneDocument')} </h4>`,
        confirmButtonText: t('Ok'),
        confirmButtonColor: '#6576ff',
        allowOutsideClick: false,
      });
    } else {
      Swal.fire({
        icon: 'info',
        title: `<h4 class="nk-modal-title">${t(
          'WantUploadThisDocument'
        )} </h4>`,
        confirmButtonText: t('Upload'),
        confirmButtonColor: '#6576ff',
        allowOutsideClick: false,
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          setFile(files[0]);
        }
      });
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleChooseDocument = (event) => {
    event.preventDefault();
    const file = event.target.files[0];

    Swal.fire({
      icon: 'info',
      title: `<h4 class="nk-modal-title">${t('WantUploadThisDocument')} </h4>`,
      confirmButtonText: t('Upload'),
      confirmButtonColor: '#6576ff',
      allowOutsideClick: false,
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        setFile(file);
      }
    });
  };

  const saveDocument = (event) => {
    event.preventDefault();
    if (file) {
      setAnnouncementFile(file);
      setIsUploadModalVisible(false);
    }
  };

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title">{t('UpdateAnnouncement')}</h5>
        <a
          className="close cursor-pointer"
          onClick={() => {
            dispatch(addAlert(alertBodyTypes.BOARD_UPDATED));
            dispatch(setModal(null));
          }}
        >
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
      <Modal.Body>
        <form className="form-validate is-alter" onSubmit={saveDocument}>
          <div className="form-group">
            <label className="form-label" htmlFor="default-06">
              {t('YouHaveModifiedTheBoard')}
            </label>
            <div
              className="nk-block-head-content"
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <div onDrop={handleDrop} onDragOver={handleDragOver}>
                <div className="card card-bordered sp-plan">
                  <div
                    className="sp-plan-action card-inner"
                    style={{
                      height: '10px',
                      width: '460px',
                    }}
                  >
                    <div>
                      <span className="text-center w-300px d-block text-ellipsis">
                        {file?.name || t('DragDocumentToUpload')}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <input
                type="file"
                id="fileInput"
                style={{ display: 'none' }}
                onChange={handleChooseDocument}
                accept=".pdf"
              />
              <label
                htmlFor="fileInput"
                className="btn btn-light"
                style={{ marginBottom: '0px' }}
              >
                {t('Choose')}
              </label>
            </div>
          </div>
          <button
            type="submit"
            className="btn btn-lg btn-primary"
            disabled={!file}
          >
            {t('Accept')}
          </button>
        </form>
      </Modal.Body>
    </>
  );
};

export default UploadAnnouncementModal;
