import React, { FC } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { setModal } from 'redux/actions/modalActions';
import {
  getEmailColumn,
  getNameColumn,
  getNroIdColumn,
  getNroPPSSColumn,
} from 'constants/getExcelColumns';
import { Partner } from 'types';

type PartnersPreviewProps = {
  // extend Partner type to include isExisting property
  partners: (Partner & { isExisting?: boolean })[];
  onClose: () => void;
};

const PartnersPreview: FC<PartnersPreviewProps> = ({ partners, onClose }) => {
  const { t } = useTranslation();

  const nonExistingPartners = partners.filter(
    (partner) => !partner?.isExisting
  )?.length;

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <Modal.Header className="border-0 pb-0 d-flex justify-content-between">
        <div className="d-flex align-items-baseline">
          <h5 className="modal-title">
            {`${partners?.length || 0} ${t('partners')}`}
          </h5>
          <span className="sub-text ml-2">
            (
            {t('OnlyWillBeUploaded', {
              partners: nonExistingPartners,
            })}
            )
          </span>
        </div>

        <a className="close cursor-pointer" onClick={handleClose}>
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
      <Modal.Body>
        <div className="nk-block">
          <div className="card">
            <table className="table table-tranx">
              <thead>
                <tr className="tb-tnx-head">
                  <th className="nk-tb-col p-3">
                    <span>Nº</span>
                  </th>
                  <th className="nk-tb-col p-3">
                    <span>{t('Name')}</span>
                  </th>
                  <th className="nk-tb-col p-3">
                    <span>{t('E-Mail')}</span>
                  </th>
                  <th className="nk-tb-col p-0" />
                  <th className="nk-tb-col p-3">
                    <span>{t('CIF')}</span>
                  </th>
                  <th className="nk-tb-col text-right p-3">
                    <span>{t('Shares')}</span>
                  </th>
                </tr>
              </thead>

              <tbody>
                {partners?.map((partner, index) => (
                  <tr className="tb-tnx-item" key={partner?.['_id']}>
                    <td className="tb-tnx-id">
                      <span>{index + 1}</span>
                    </td>
                    <td className="tb-tnx-info">
                      <span className="tb-tnx-info">
                        {getNameColumn(partner) || '-'}
                      </span>
                    </td>
                    <td className="tb-tnx-info">
                      <span className="tb-tnx-info">
                        {getEmailColumn(partner) || '-'}
                      </span>
                    </td>
                    <td className="tb-tnx-info">
                      <span className="tb-tnx-info">
                        {partner?.isExisting ? (
                          <em className="icon ni ni-alert-circle-fill text-danger" />
                        ) : (
                          <em className="icon ni ni-check-circle-fill text-success" />
                        )}
                      </span>
                    </td>
                    <td className="tb-tnx-info">
                      <span className="tb-tnx-info">
                        {getNroIdColumn(partner) || '-'}
                      </span>
                    </td>
                    <td className="tb-tnx-info text-right">
                      <span className="tb-tnx-info text-right">
                        {getNroPPSSColumn(partner) || '-'}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Modal.Body>
    </>
  );
};

export default PartnersPreview;
