import React, { FC } from 'react';
import NumberFormat from 'react-number-format';
import { v4 as uuidv4 } from 'uuid';

import { numberFormat, numberFormatDecimals } from 'constants/formats';

import getColumnClass from './utils/getColumnClass';
import newFormat from './utils/newFormat';
import getRowStyle from './utils/getRowStyle';

type Props = {
  columnIndex: number;
  value: number | string;
  text: boolean;
  suffix?: string;
  previousColumnsLength?: number;
  rowIndex?: number;
  rowsCount?: number;
  decimal?: boolean;
  keyText?: string;
};

const DrawCell: FC<Props> = ({
  columnIndex,
  value,
  text,
  suffix,
  previousColumnsLength,
  rowIndex,
  rowsCount,
  decimal,
  keyText,
}) => {
  const textIndex = text ? 'text' : 'no-text';

  return (
    <td
      key={`column-${keyText}-${rowIndex}-${columnIndex}-${textIndex}`}
      className={
        previousColumnsLength
          ? getColumnClass(columnIndex, previousColumnsLength)
          : ''
      }
      style={rowIndex && rowsCount ? getRowStyle(rowIndex, rowsCount) : {}}
    >
      {text ? (
        <NumberFormat
          displayType="text"
          value={value}
          thousandSeparator={
            decimal
              ? numberFormatDecimals.thousandSeparator
              : numberFormat.thousandSeparator
          }
          decimalSeparator={
            decimal
              ? numberFormatDecimals.decimalSeparator
              : numberFormat.decimalSeparator
          }
          suffix={suffix}
        />
      ) : (
        newFormat(value)
      )}
    </td>
  );
};

export default DrawCell;
