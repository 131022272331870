/* eslint-disable no-underscore-dangle */
import alertBodyTypes from 'components/Alert/alertBodyTypes';
import types from 'redux/actions/actionTypes';
import { decryptResponse } from 'utils/token';

import axios from '../../interceptors/axios';
import { addAlert } from './alertActions';

// eslint-disable-next-line import/prefer-default-export
export const updateShare = (share, society) => async (dispatch) => {
  try {
    const { data } = await axios.put(
      `${process.env.REACT_APP_API_URL}/shares`,
      {
        _id: share._id,
        tag: share.tag,
        encumbrances: share.encumbrances,
      }
    );
    const decodedData = await decryptResponse(data);
    const updatedSociety = {
      ...society,
      shares: society.shares.map((s) =>
        s._id === share._id ? { ...decodedData } : s
      ),
    };

    dispatch({
      type: types.UPDATE_SOCIETY,
      society: updatedSociety,
    });

    dispatch(addAlert(alertBodyTypes.SHARE_UPDATED));
    return decodedData;
  } catch (error) {
    dispatch(addAlert(alertBodyTypes.ERROR_SHARE_UPDATED));
  }
};
