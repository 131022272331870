/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import store from 'redux/store';
import { setModal } from 'redux/actions/modalActions';
import { getDocumentsByReference } from 'redux/actions/documentActions';
import AddSocietyAuditor from 'components/Modals/AddSocietyAuditor';
import documentTypes from 'constants/documentTypes';
import NoItemsAvailable from 'components/Modals/NoItemsAvailable';
import SocietyAuditorRow from './SocietyAuditorsRow';
import statusTypes from './statusTypes';

function SocietyAuditors({ society, isAdmin, setPage, userId }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const societyDocuments = useSelector((state) => state.documents);
  const isDemo = useSelector((state) => state.society?.role?.isDemo);

  const [auditors, setAuditors] = useState([]);
  const [seeNotCurrent, setSeeNotCurrent] = useState(false);

  function changeView() {
    setSeeNotCurrent(!seeNotCurrent);
  }
  const getStatus = (auditor) => {
    if (!auditor?.auditorStatus) {
      if (!auditor?.endDate || new Date(auditor?.endDate) - new Date() > 0)
        return statusTypes.var.ACTIVE;
      return statusTypes.var.INACTIVE;
    }
    return auditor?.auditorStatus;
  };
  useEffect(() => {
    dispatch(getDocumentsByReference({ societyId: society['_id'] }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [society]);

  useEffect(() => {
    if (society) {
      const filteredAuditors = society?.auditors?.filter((auditor) => {
        if (
          seeNotCurrent ||
          (!seeNotCurrent && getStatus(auditor) === statusTypes.var.ACTIVE)
        ) {
          return auditor;
        }
      });

      setAuditors(
        filteredAuditors?.map((auditor) => {
          const auditorsDocuments = societyDocuments.filter((doc) =>
            auditor.documents.includes(doc['_id'])
          );

          return (
            <SocietyAuditorRow
              key={auditor['_id']}
              userId={userId}
              auditorId={auditor['_id']}
              societyId={society['_id']}
              socialDenomination={auditor.socialDenomination}
              nationality={auditor.nationality}
              initDate={auditor.initDate}
              endDate={auditor.endDate}
              cif={auditor.cif}
              address={auditor.address}
              email={auditor.email}
              auditorStatus={auditor.auditorStatus}
              documents={auditorsDocuments?.filter(
                (document) => document.category !== documentTypes.DELETED
              )}
            />
          );
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [society, societyDocuments, seeNotCurrent]);

  return (
    <div className="nk-block">
      <div className="nk-block-head">
        <div className="nk-block-head-content">
          <h5 className="nk-block-title title">{t('CompanyAuditors')}</h5>
          <div className="nk-block-des">
            <p>{t('TheseAreThePeopleOrCompanAuditors')}</p>
          </div>
        </div>
      </div>
      <div className="nk-block">
        {isAdmin ? (
          <div className="nk-block-head">
            <div
              className="nk-block-head-content"
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <button
                type="button"
                className="btn btn-success"
                onClick={() => {
                  store.dispatch(
                    setModal(
                      <AddSocietyAuditor
                        societyId={society['_id']}
                        action="ADD"
                        userId={userId}
                      />
                    )
                  );
                  setPage(3);
                }}
                disabled={isDemo}
              >
                {t('AddAuditor')}
              </button>
              <div>
                <button
                  type="button"
                  className="btn btn-light"
                  onClick={() => changeView()}
                >
                  <em
                    className={
                      !seeNotCurrent ? 'icon ni ni-eye' : 'icon ni ni-eye-off'
                    }
                  />
                  <span>
                    {!seeNotCurrent ? t('SeeNotCurrent') : t('HideNotCurrent')}
                  </span>
                </button>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="card card-bordered card-preview">
          <div className="table-responsive">
            <table className="nk-tb-list nk-tb-ulist">
              <thead>
                <tr className="nk-tb-item nk-tb-head tb-tnx-head fs-11px">
                  <th className="nk-tb-col">
                    <span>{t('NameAndSurnameSociety')}</span>
                  </th>
                  <th className="nk-tb-col tb-col-xl">
                    <span>{t('Nationality')}</span>
                  </th>
                  <th className="nk-tb-col tb-col-xl">
                    <span>{t('AppointmentDate')}</span>
                  </th>
                  <th className="nk-tb-col tb-col-xl">
                    <span>{t('EndDate')}</span>
                  </th>
                  <th className="nk-tb-col tb-col-xl">
                    <span>{t('E-Mail')}</span>
                  </th>
                  <th className="nk-tb-col">
                    <span>{t('Status')}</span>
                  </th>
                  <th className="nk-tb-col tb-col-xl text-center">
                    <span>{t('Doc')}</span>
                  </th>
                  {!isDemo && (
                    <th className="nk-tb-col">
                      <span />
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {auditors?.length
                  ? auditors
                  : NoItemsAvailable({
                      mainMessage: t('NoAuditorsAvailable'),
                      infoMessage: '',
                    })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SocietyAuditors;
