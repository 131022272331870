import React, { useState } from 'react';
import i18n from 'i18n/config';

import List from 'components/Documents/List';
import documentSections from 'constants/documentSections';

const currentSection = 'SCRIPTURES';

const Scriptures = ({ documents, isLoadingData, searchTerm }) => {
  const [section] = useState(
    documentSections(i18n).find((s) => s.tag === currentSection)
  );
  return (
    <>
      <List
        section={section}
        documents={documents}
        isLoadingData={isLoadingData}
        searchTerm={searchTerm}
      />
    </>
  );
};

export default Scriptures;
