/* eslint-disable import/no-dynamic-require */
/* eslint-disable no-console */
import React from 'react';
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';
import { addAlert } from 'redux/actions/alertActions';
import alertTypes from 'constants/alertTypes';

import store from 'redux/store';

import getDownloadURL from 'utils/getDownloadURL';

import '../Modals.scss';

async function DocumentViewerModal(document) {
  const doc = [
    {
      uri: await getDownloadURL({ documentId: document?.['_id'] }).then(
        (result) => result
      ),
    },
  ];

  store.dispatch(
    addAlert({
      type: alertTypes.NONE,
      html: (
        <div style={{ height: '80vh' }}>
          <DocViewer
            pluginRenderers={DocViewerRenderers}
            documents={doc}
            style={{ height: '100%' }}
            config={{
              header: { disableFileName: true },
            }}
          />
        </div>
      ),
      props: {
        showConfirmButton: false,
        width: '80%',
        height: '80%',
      },
    })
  );
}

export default DocumentViewerModal;
