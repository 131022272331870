import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import i18n from 'i18n/config';

import List from 'components/Documents/List';
import documentSections from 'constants/documentSections';
import Preview from 'components/Documents/Preview';

const currentSection = 'DOCUMENTS';

const Documents = ({ documents, isLoadingData, searchTerm }) => {
  const [section] = useState(
    documentSections(i18n).find((s) => s.tag === currentSection)
  );

  const hasPreview = useLocation().search === '?preview=true';
  const hasEdit = useLocation().search === '?edit=true';

  return (
    <>
      {hasPreview || hasEdit ? (
        <Preview hasEdit={hasEdit} />
      ) : (
        <List
          section={section}
          documents={documents}
          isLoadingData={isLoadingData}
          searchTerm={searchTerm}
        />
      )}
    </>
  );
};

export default Documents;
