import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

const labels = {
  Name: 'Name',
  'Fiscal Identification Number': 'CIF',
  Email: 'Email',
  'Empowerment Date': 'RepresentativeDate',
  Address: 'Address',
  Nationality: 'Nationality',
};

const RepresentativeTable = ({
  representativeData,
  partnerIndex,
  changePartnerParam,
}) => {
  const { t } = useTranslation();

  const [expandedView, setExpandedView] = useState();

  function changeExpandView() {
    setExpandedView(!expandedView);
  }

  return (
    <>
      <div
        style={{
          width: '100%',
          height: '100%',
        }}
        key={`representative-${partnerIndex}-card`}
      >
        <table id={`representative-${partnerIndex}-table`}>
          <tbody>
            {expandedView ? (
              Object.entries(representativeData).map(([key, value], index) => (
                <tr key={`representative-${partnerIndex}-${key}`}>
                  <td>
                    <span>
                      <b>{t(labels[key]) || key}</b>
                    </span>
                  </td>

                  <td>
                    <input
                      style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                      type={key === 'Empowerment Date' ? 'date' : 'text'}
                      value={value}
                      className="w-100 form-control date-picker"
                      readOnly={false}
                      onChange={(event) =>
                        changePartnerParam(
                          partnerIndex,
                          key,
                          event.target.value,
                          true
                        )
                      }
                    />
                  </td>

                  {index === 0 ? (
                    <td className="text-right">
                      <button
                        type="button"
                        className="badge btn-primary"
                        onClick={changeExpandView}
                      >
                        <em className="icon ni ni-chevron-up" />
                      </button>
                    </td>
                  ) : (
                    <td className="" />
                  )}
                </tr>
              ))
            ) : (
              <tr className="tb-tnx-item">
                <td className="tb-tnx-info">
                  <span>
                    <b>{representativeData['Name']}</b>
                  </span>
                </td>

                <td className="tb-tnx-info text-right">
                  <button
                    type="button"
                    className="badge btn-primary"
                    onClick={changeExpandView}
                  >
                    <em className="icon ni ni-chevron-down" />
                  </button>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default RepresentativeTable;
