/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
// eslint-disable-next-line import/no-extraneous-dependencies
import PDFMerger from 'pdf-merger-js';
import axios from '../interceptors/axios';
import { decryptResponse } from './token';

export default async function downloadMerged({ documents, currentFolder }) {
  const mergedPdf = new PDFMerger();
  for (const document of documents) {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/download/${document['_id']}`
    );

    const decodedData = await decryptResponse(data);
    const url = decodedData?.url;

    await fetch(url)
      .then((response) => response.blob())
      .then(async (blob) => {
        await mergedPdf.add(blob);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const pdfData = await mergedPdf.saveAsBuffer();
  const blob = new Blob([pdfData], { type: 'application/pdf' });
  const url = URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = url;
  a.download = currentFolder.label;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export async function createBlobMerged({ documents }) {
  const mergedPdf = new PDFMerger();

  try {
    for (const document of documents) {
      if (document) {
        await mergedPdf.add(document);
      }
    }
    const pdfData = await mergedPdf.saveAsBuffer();
    const blob = new Blob([pdfData], { type: 'application/pdf' });
    return blob;
  } catch (error) {
    console.log(error);
  }
}
