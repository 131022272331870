import {
  AlignmentType,
  convertInchesToTwip,
  Document,
  Footer,
  HeadingLevel,
  ImageRun,
  Paragraph,
  TabStopPosition,
  LevelFormat,
  TextRun,
  TabStopType,
  Header,
  Table,
  TableRow,
  TableCell,
  WidthType,
  VerticalAlign,
  PageNumber,
} from 'docx';
import BlankLogo from './BlankLogo';

const breakLine = new Paragraph({
  text: '',
});

function ActaJuntaGeneral(society = {}) {
  const { name, assistants } = society;
  const { logo } = society?.additional;
  const { city } = society?.legalAddress;

  const imageBase64Data = logo
    ? logo?.replace(/^data:image\/png;base64,/, '')
    : BlankLogo?.replace(/^data:image\/png;base64,/, '');

  const totalND = assistants.reduce(
    (acc, cur) => acc + Number(cur.percentND),
    0
  );

  const assistantRows = assistants.map(
    (assistant) =>
      new TableRow({
        children: [
          new TableCell({
            width: {
              size: 3500,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: `${assistant.fullname}`,
                  }),
                ],
                style: 'tableContent',
              }),
            ],
            verticalAlign: VerticalAlign.CENTER,
          }),
          new TableCell({
            width: {
              size: 3500,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: assistant.isRepresentated
                      ? 'Representado por: [Nombre y Apellidos]'
                      : '',
                  }),
                ],
                style: 'tableContent',
              }),
            ],
            verticalAlign: VerticalAlign.CENTER,
          }),
          new TableCell({
            width: {
              size: 2010,
              type: WidthType.DXA,
            },
            children: [
              new Paragraph({
                text: `${assistant.percentND}%`,
                alignment: AlignmentType.CENTER,
                style: 'tableContent',
              }),
            ],
            verticalAlign: VerticalAlign.CENTER,
          }),
        ],
      })
  );

  const doc = new Document({
    creator: name,
    title: 'Acta de la Junta General de Socios de la Sociedad',
    description: 'Acta de la Junta General de Socios de la Sociedad',

    numbering: {
      config: [
        {
          reference: 'ref1',
          levels: [
            {
              level: 0,
              format: LevelFormat.DECIMAL,
              text: '%1.',
              start: 1,
            },
          ],
        },
      ],
    },
    styles: {
      default: {
        heading1: {
          run: {
            font: 'Arial',
            size: 28,
            bold: true,
            color: '000000',
          },
          paragraph: {
            alignment: AlignmentType.CENTER,
            spacing: { line: 340 },
          },
        },
        heading2: {
          run: {
            font: 'Arial',
            size: 28,
            bold: true,
            color: '000000',
          },
          paragraph: {
            alignment: AlignmentType.CENTER,
            spacing: { line: 340 },
          },
        },
        heading3: {
          run: {
            font: 'Arial',
            size: 26,
            bold: true,
          },
          paragraph: {
            spacing: { line: 276 },
          },
        },
        heading4: {
          run: {
            font: 'Arial',
            size: 22,
            bold: true,
          },
          paragraph: {
            alignment: AlignmentType.JUSTIFIED,
          },
        },
      },
      paragraphStyles: [
        {
          id: 'normalPara',
          name: 'Normal Para',
          basedOn: 'Normal',
          next: 'Normal',
          quickFormat: true,
          run: {
            font: 'Arial',
            size: 22,
          },
          paragraph: {
            alignment: AlignmentType.JUSTIFIED,
            spacing: { line: 276, before: 20 * 72 * 0.1, after: 40 * 72 * 0.1 },
            rightTabStop: TabStopPosition.MAX,
            leftTabStop: 453.543307087,
          },
        },
        {
          id: 'tableContent',
          name: 'Table Content',
          basedOn: 'Normal',
          next: 'Normal',
          quickFormat: true,
          run: {
            font: 'Arial',
            size: 20,
          },
          paragraph: {
            spacing: { line: 276, before: 20 * 72 * 0.1, after: 20 * 72 * 0.1 },
            rightTabStop: TabStopPosition.MAX,
            leftTabStop: 453.543307087,
          },
        },
        {
          id: 'highLight',
          name: 'HighLight',
          basedOn: 'Normal',
          next: 'Normal',
          quickFormat: true,
          run: {
            font: 'Arial',
            size: 22,
            bold: false,
            fill: 'FFD966',
            color: 'FFD966',
          },
          paragraph: {
            alignment: AlignmentType.JUSTIFIED,
            spacing: { line: 276, before: 20 * 72 * 0.1, after: 40 * 72 * 0.1 },
            rightTabStop: TabStopPosition.MAX,
            leftTabStop: 453.543307087,
          },
        },
        {
          id: 'normalPara2',
          name: 'Normal Para2',
          basedOn: 'Normal',
          next: 'Normal',
          quickFormat: true,
          run: {
            font: 'Arial',
            size: 22,
          },
          paragraph: {
            alignment: AlignmentType.JUSTIFIED,
            spacing: {
              line: 276,
              before: 20 * 72 * 0.1,
              after: 20 * 72 * 0.05,
            },
          },
        },
        {
          id: 'aside',
          name: 'Aside',
          basedOn: 'Normal',
          next: 'Normal',
          run: {
            color: '999999',
            italics: true,
          },
          paragraph: {
            spacing: { line: 276 },
            indent: { left: convertInchesToTwip(0.5) },
          },
        },
        {
          id: 'wellSpaced',
          name: 'Well Spaced',
          basedOn: 'Normal',
          paragraph: {
            spacing: {
              line: 276,
              before: 20 * 72 * 0.1,
              after: 20 * 72 * 0.05,
            },
          },
        },
        {
          id: 'numberedAgreement',
          name: 'Numbered Agreement',
          basedOn: 'Normal',
          next: 'Normal',
          quickFormat: true,
          run: {
            font: 'Arial',
            size: 20,
          },
          paragraph: {
            spacing: {
              line: 276,
              before: 20 * 72 * 0.1,
              after: 20 * 72 * 0.05,
            },
            rightTabStop: TabStopPosition.MAX,
            leftTabStop: 453.543307087,
            numbering: {
              reference: 'ref1',
              instance: 0,
              level: 0,
            },
          },
        },
        {
          id: 'numberedPara',
          name: 'Numbered Para',
          basedOn: 'Normal',
          next: 'Normal',
          quickFormat: true,
          run: {
            font: 'Arial',
            size: 20,
          },
          paragraph: {
            spacing: {
              line: 276,
              before: 20 * 72 * 0.1,
              after: 20 * 72 * 0.05,
            },
            rightTabStop: TabStopPosition.MAX,
            leftTabStop: 453.543307087,
            numbering: {
              reference: 'ref1',
              instance: 0,
              level: 0,
            },
          },
        },
      ],
    },
    sections: [
      {
        properties: {
          page: {
            margin: {
              top: 400,
              right: 1400,
              bottom: 700,
              left: 1400,
            },
          },
        },
        headers: {
          default: new Header({
            children: [
              new Paragraph({
                children: [
                  new ImageRun({
                    data: Buffer.from(imageBase64Data, 'base64'),
                    transformation: {
                      width: 100,
                      height: 100,
                    },
                  }),
                ],
                alignment: AlignmentType.CENTER,
              }),
            ],
          }),
        },
        footers: {
          default: new Footer({
            children: [
              new Paragraph({
                style: 'normalPara',
                alignment: AlignmentType.RIGHT,
                children: [
                  new TextRun({
                    children: [PageNumber.CURRENT],
                  }),
                ],
              }),
            ],
          }),
        },
        children: [
          breakLine,

          new Paragraph({
            text: 'ACTA DE LA JUNTA GENERAL DE SOCIOS DE LA SOCIEDAD',
            heading: HeadingLevel.HEADING_1,
            alignment: AlignmentType.CENTER,
          }),

          new Paragraph({
            text: name || '[DENOMINACIÓN SOCIAL]',
            heading: HeadingLevel.HEADING_1,
            alignment: AlignmentType.CENTER,
          }),

          breakLine,

          new Paragraph({
            children: [
              new TextRun(
                `En ${
                  city || '[ciudad]'
                }, en el domicilio social de la Sociedad, a `
              ),
              new TextRun({
                text: '[día]',
                highlight: 'yellow',
              }),
              new TextRun({
                text: ' de ',
              }),
              new TextRun({
                text: '[mes]',
                highlight: 'yellow',
              }),
              new TextRun({
                text: ' de ',
              }),
              new TextRun({
                text: '[año]',
                highlight: 'yellow',
              }),
              new TextRun({
                text: ' a las ',
              }),
              new TextRun({
                text: '[hora:minutos]',
                highlight: 'yellow',
              }),
              new TextRun({
                text: ` horas, se reúne la Junta General de Socios de ${
                  name || '[DENOMINACIÓN SOCIAL]'
                } (en adelante, `,
              }),
              new TextRun({ text: '“la Sociedad”', bold: true }),
              new TextRun({
                text: `), con la asistencia de los socios que figuran en la Lista de Asistentes que se incorpora a la presente acta, y con el siguiente`,
              }),
            ],
            style: 'normalPara',
          }),

          breakLine,

          new Paragraph({
            text: 'ORDEN DEL DÍA',
            heading: HeadingLevel.HEADING_2,
            alignment: AlignmentType.CENTER,
          }),

          new Paragraph({
            children: [
              new TextRun({
                text: '[Primer tema a tratar en la Junta].',
                highlight: 'yellow',
              }),
            ],
            style: 'numberedPara',
          }),

          new Paragraph({
            children: [
              new TextRun({
                text: '[Segundo tema a tratar en la Junta].',
                highlight: 'yellow',
              }),
            ],
            style: 'numberedPara',
          }),

          new Paragraph({
            children: [
              new TextRun({
                text: '[Tercer tema a tratar en la Junta].',
                highlight: 'yellow',
              }),
            ],
            style: 'numberedPara',
          }),

          new Paragraph({
            text: 'Ruegos y preguntas.',
            style: 'numberedPara',
          }),

          new Paragraph({
            text:
              'Confección, lectura y aprobación, si procede, del Acta de la reunión.',
            style: 'numberedPara',
          }),

          breakLine,

          new Paragraph({
            text: 'La Junta General que se constituye cuenta con la siguiente',
            style: 'normalPara',
          }),

          breakLine,

          new Paragraph({
            text: 'LISTA DE ASISTENTES',
            heading: HeadingLevel.HEADING_2,
            alignment: AlignmentType.CENTER,
          }),

          breakLine,

          new Paragraph({
            text:
              'Los asistentes, por sí o debidamente representados, son los siguientes:',
            style: 'normalPara',
          }),

          breakLine,

          new Table({
            columnWidths: [3000, 4000, 2010],
            rows: [
              new TableRow({
                children: [
                  new TableCell({
                    width: {
                      size: 3000,
                      type: WidthType.DXA,
                    },
                    children: [
                      new Paragraph({
                        text: 'NOMBRE DEL SOCIO',
                        heading: HeadingLevel.HEADING_4,
                        alignment: AlignmentType.CENTER,
                      }),
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                  }),
                  new TableCell({
                    width: {
                      size: 4000,
                      type: WidthType.DXA,
                    },
                    children: [
                      new Paragraph({
                        text: 'REPRESENTANTE',
                        heading: HeadingLevel.HEADING_4,
                        alignment: AlignmentType.CENTER,
                      }),
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                  }),
                  new TableCell({
                    width: {
                      size: 2010,
                      type: WidthType.DXA,
                    },
                    children: [
                      new Paragraph({
                        text: '% PARTICIPACIÓN',
                        heading: HeadingLevel.HEADING_4,
                        alignment: AlignmentType.CENTER,
                      }),
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                  }),
                ],
              }),

              ...assistantRows,

              new TableRow({
                children: [
                  new TableCell({
                    width: {
                      size: 3000,
                      type: WidthType.DXA,
                    },
                    children: [
                      new Paragraph({
                        text: 'TOTAL CAPITAL PRESENTE',
                        heading: HeadingLevel.HEADING_4,
                        alignment: AlignmentType.CENTER,
                      }),
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                  }),
                  new TableCell({
                    width: {
                      size: 4000,
                      type: WidthType.DXA,
                    },
                    children: [
                      new Paragraph({
                        text: '',
                        heading: HeadingLevel.HEADING_4,
                        alignment: AlignmentType.CENTER,
                      }),
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                  }),
                  new TableCell({
                    width: {
                      size: 2010,
                      type: WidthType.DXA,
                    },
                    children: [
                      new Paragraph({
                        text: `${totalND}%`,
                        heading: HeadingLevel.HEADING_4,
                        alignment: AlignmentType.CENTER,
                      }),
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                  }),
                ],
              }),
            ],
          }),

          breakLine,

          new Paragraph({
            text:
              'Abierta la sesión, y sin más deliberación de los asuntos a tratar, se aprueban los siguientes',
            style: 'normalPara',
          }),

          breakLine,
          breakLine,

          new Paragraph({
            text: 'ACUERDOS',
            heading: HeadingLevel.HEADING_2,
            alignment: AlignmentType.CENTER,
          }),

          breakLine,

          new Paragraph({
            children: [
              new TextRun({
                text: '[Primer tema a tratar en la Junta].',
                highlight: 'yellow',
              }),
            ],
            style: 'numberedAgreement',
          }),

          breakLine,

          new Paragraph({
            text: `[Opción 1: Aprobación por unanimidad]: Sometido a votación, se aprueba por unanimidad.
              \n
              [Opción 2: Existencia de votos en contra]: Sometido a votación, se aprueba con los votos favorables correspondientes al [porcentaje]% de las participaciones en que se divide el capital social. Voto en contra de [Nombre y Apellidos] ([porcentaje]% del capital social).
              `,
            style: 'normalPara',
          }),

          breakLine,

          new Paragraph({
            children: [
              new TextRun({
                text: '[Segundo tema a tratar en la Junta].',
                highlight: 'yellow',
              }),
            ],
            style: 'numberedAgreement',
          }),

          breakLine,

          new Paragraph({
            text: `[Opción 1: Aprobación por unanimidad]: Sometido a votación, se aprueba por unanimidad.
            \n
              [Opción 2: Existencia de votos en contra]: Sometido a votación, se aprueba con los votos favorables correspondientes al [porcentaje]% de las participaciones en que se divide el capital social. Voto en contra de [Nombre y Apellidos] ([porcentaje]% del capital social).
              `,
            style: 'normalPara',
          }),

          breakLine,

          new Paragraph({
            children: [
              new TextRun({
                text: '[Tercer tema a tratar en la Junta].',
                highlight: 'yellow',
              }),
            ],
            style: 'numberedAgreement',
          }),

          breakLine,

          new Paragraph({
            text: `[Opción 1: Aprobación por unanimidad]: Sometido a votación, se aprueba por unanimidad.
            \n
              [Opción 2: Existencia de votos en contra]: Sometido a votación, se aprueba con los votos favorables correspondientes al [porcentaje]% de las participaciones en que se divide el capital social. Voto en contra de [Nombre y Apellidos] ([porcentaje]% del capital social).
              `,
            style: 'normalPara',
          }),

          breakLine,

          new Paragraph({
            children: [
              new TextRun({
                text: `Y no habiendo más asuntos que tratar, se levanta la sesión, extendiéndose la presente acta que, leída y aprobada por unanimidad por todos los asistentes, es firmada por el Secretario con el visto bueno del Presidente.
                `,
              }),
            ],
            style: 'numberedAgreement',
          }),

          breakLine,
          breakLine,
          breakLine,

          new Paragraph({
            children: [
              new TextRun({
                text: '[Nombre y Apellidos]',
                highlight: 'yellow',
              }),
              new TextRun({
                text: '\t[Nombre y Apellidos]',
                highlight: 'yellow',
              }),
            ],
            tabStops: [
              {
                type: TabStopType.RIGHT,
                position: TabStopPosition.MAX,
              },
            ],
            style: 'normalPara2',
          }),
          new Paragraph({
            children: [new TextRun('Presidente'), new TextRun('\tSecretario')],
            tabStops: [
              {
                type: TabStopType.RIGHT,
                position: TabStopPosition.MAX,
              },
            ],
            style: 'normalPara',
          }),
        ],
      },
    ],
  });

  return doc;
}

export default ActaJuntaGeneral;
