/* eslint-disable no-undef */
import React from 'react';
import { Page, Text, Document, StyleSheet, Image } from '@react-pdf/renderer';
import Stamp from '../../assets/images/sttok-stamp-v2.jpg';

const styles = StyleSheet.create({
  body: {
    paddingTop: 130,
    paddingHorizontal: 70,
  },
  title: {
    color: '#274673',
    fontSize: 16,
    lineHeight: 1.5,
    fontWeight: 700,
    textAlign: 'center',
    display: 'block',
    marginLeft: 30,
    marginRight: 30,
    marginBottom: 70,
  },
  titleBottom: {
    color: '#274673',
    fontSize: 16,
    fontWeight: 700,
    textAlign: 'center',
    display: 'block',
    marginBottom: 70,
  },
  author: {
    color: '#657796',
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'left',
    marginBottom: 40,
    display: 'block',
  },
  text: {
    color: '#657796',
    fontSize: 13,
    fontWeight: 300,
    textAlign: 'justify',
    lineHeight: 1.6,
    display: 'block',
    marginBottom: 20,
  },
  extraMargin: {
    marginTop: 20,
  },
  stamp: {
    width: 180,
    height: 180,
    marginLeft: 'auto',
    marginRight: 'auto',
    transform: 'rotate(-15deg)',
  },
});

const BeneficiaryCertificate = ({ certificateData }) => {
  const {
    societyName,
    creationDate,
    beneficiaryFullName,
    beneficiaryShares,
    societyCIF,
    planAprobationDate,
    sharePrice,
    totalValueShares,
    downloadDate,
    societyAdmin,
  } = certificateData;

  return (
    <Document>
      <Page style={styles.body}>
        <Text style={styles.title}>
          {`Certificado de participación en el Plan de Incentivos de la Sociedad: ${societyName}`}
        </Text>

        <Text style={styles.text}>
          {`El día ${creationDate}, se otorgó a ${beneficiaryFullName} un total de ${beneficiaryShares} Unidades del Plan de Incentivos de la sociedad ${societyName} con CIF ${societyCIF}, aprobado el día ${planAprobationDate}.`}
        </Text>

        <Text style={styles.text}>
          {`El valor de cada unidad es de ${sharePrice}€. Por tanto, el valor total del Plan de Incentivos para ${beneficiaryFullName} es de ${totalValueShares}€.`}
        </Text>

        <Text style={styles.text}>
          Este Plan está sujeto a las condiciones particulares del mismo así como a la Carta de invitación.
        </Text>

        <Text style={[styles.text, styles.extraMargin]}>
          {`Y para que así conste, se expide el presente certificado el ${downloadDate}.`}
        </Text>

        <Text style={[styles.text, styles.extraMargin]}>
          {`El Administrador de ${societyName}`}
        </Text>
        <Text style={[styles.author, styles.extraMargin]}>{societyAdmin}</Text>
        <Image src={Stamp} style={styles.stamp} />
      </Page>
    </Document>
  );
};

export default BeneficiaryCertificate;
