/* eslint-disable react/require-default-props */
import React from 'react';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';

import { PDF_TYPE_FILE_ALLOWED } from 'constants/defaultConstants';

type Props = {
  label: string;
  inputLabel?: string;
  file: File | undefined;
  setFile: any;
  accept?: string;
};

const UploadDocument = (props: Props) => {
  const { t } = useTranslation();
  const {
    label,
    inputLabel,
    file,
    setFile,
    accept = PDF_TYPE_FILE_ALLOWED,
  } = props;

  const handleDrop = (e: any) => {
    e.preventDefault();
    const files = [...e.dataTransfer.files];

    if (files.length > 1) {
      Swal.fire({
        icon: 'info',
        title: `<h4 class="nk-modal-title">${t('MustUploadOneDocument')} </h4>`,
        confirmButtonText: t('Ok'),
        confirmButtonColor: '#6576ff',
        allowOutsideClick: false,
      });
    } else {
      Swal.fire({
        icon: 'info',
        title: `<h4 class="nk-modal-title">${t(
          'WantUploadThisDocument'
        )} </h4>`,
        confirmButtonText: t('Upload'),
        confirmButtonColor: '#6576ff',
        allowOutsideClick: false,
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          setFile(files[0]);
        }
      });
    }
  };

  const handleDragOver = (e: any) => {
    e.preventDefault();
  };

  const handleChooseDocument = (e: any) => {
    e.preventDefault();
    const file = e.target.files[0];

    Swal.fire({
      icon: 'info',
      title: `<h4 class="nk-modal-title">${t('WantUploadThisDocument')} </h4>`,
      confirmButtonText: t('Upload'),
      confirmButtonColor: '#6576ff',
      allowOutsideClick: false,
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        setFile(file);
      }
    });
  };

  return (
    <div
      className="form-group"
      style={{
        zIndex: 0,
      }}
    >
      <label className="form-label" htmlFor="default-06">
        {t(label)}
      </label>
      <div
        className="nk-block-head-content"
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <div onDrop={handleDrop} onDragOver={handleDragOver}>
          <div className="card card-bordered sp-plan">
            <div
              className="sp-plan-action card-inner"
              style={{
                height: '10px',
                width: '460px',
              }}
            >
              <div>
                <span className="text-center w-300px d-block text-ellipsis">
                  {file?.name || inputLabel || t('DragDocumentToUpload')}
                </span>
              </div>
            </div>
          </div>
        </div>
        <input
          type="file"
          id="fileInput"
          style={{ display: 'none' }}
          onChange={handleChooseDocument}
          accept={accept}
        />
        <label
          htmlFor="fileInput"
          className="btn btn-light"
          style={{ marginBottom: '0px' }}
        >
          {t('Choose')}
        </label>
      </div>
    </div>
  );
};

export default UploadDocument;
