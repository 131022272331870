import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const TenderOfferSharesBanner = () => {
  const { t } = useTranslation();
  return (
    <div className="nk-block">
      <div className="card card-bordered">
        <div className="card-inner card-inner-lg">
          <div className="nk-help">
            <div className="nk-help-img">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 90 90">
                <path
                  d="M43.94,13.5H81a6,6,0,0,1,6,6v36a6,6,0,0,1-6,6H22a6,6,0,0,1-6-6v-36a6,6,0,0,1,6-6H43.94Z"
                  fill="#eff1ff"
                  stroke="#6576ff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                />
                <path
                  d="M38.94,20.5H76a6,6,0,0,1,6,6v36a6,6,0,0,1-6,6H17a6,6,0,0,1-6-6v-36a6,6,0,0,1,6-6H38.94Z"
                  fill="#e3e7fe"
                  stroke="#6576ff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                />
                <rect
                  x="3"
                  y="26.5"
                  width="73"
                  height="50"
                  rx="6"
                  ry="6"
                  fill="#fff"
                  stroke="#6576ff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                />
                <circle
                  cx="64.5"
                  cy="65"
                  r="4.5"
                  fill="none"
                  stroke="#6576ff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                />
                <circle
                  cx="59.5"
                  cy="65"
                  r="4.5"
                  fill="none"
                  stroke="#6576ff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                />
                <rect
                  x="11"
                  y="32.5"
                  width="13"
                  height="9"
                  fill="#e3e7fe"
                  stroke="#6576ff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                />
                <rect
                  x="10"
                  y="47.5"
                  width="2"
                  height="5"
                  rx="1"
                  ry="1"
                  fill="#c4cefe"
                />
                <rect
                  x="16"
                  y="47.5"
                  width="2"
                  height="5"
                  rx="1"
                  ry="1"
                  fill="#c4cefe"
                />
                <rect
                  x="23"
                  y="47.5"
                  width="2"
                  height="5"
                  rx="1"
                  ry="1"
                  fill="#c4cefe"
                />
                <rect
                  x="30"
                  y="47.5"
                  width="2"
                  height="5"
                  rx="1"
                  ry="1"
                  fill="#c4cefe"
                />
                <rect
                  x="37"
                  y="47.5"
                  width="2"
                  height="5"
                  rx="1"
                  ry="1"
                  fill="#c4cefe"
                />
                <rect
                  x="43"
                  y="47.5"
                  width="2"
                  height="5"
                  rx="1"
                  ry="1"
                  fill="#c4cefe"
                />
                <rect
                  x="50"
                  y="47.5"
                  width="2"
                  height="5"
                  rx="1"
                  ry="1"
                  fill="#c4cefe"
                />
                <rect
                  x="57"
                  y="47.5"
                  width="2"
                  height="5"
                  rx="1"
                  ry="1"
                  fill="#c4cefe"
                />
                <rect
                  x="64"
                  y="47.5"
                  width="2"
                  height="5"
                  rx="1"
                  ry="1"
                  fill="#c4cefe"
                />
                <rect x="65" y="32.5" width="4" height="6" fill="#c4cefe" />
                <rect x="58" y="32.5" width="4" height="6" fill="#c4cefe" />
                <rect x="51" y="32.5" width="4" height="6" fill="#c4cefe" />
                <line
                  x1="10.5"
                  y1="61"
                  x2="34.5"
                  y2="61"
                  fill="none"
                  stroke="#c4cefe"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="3"
                />
                <line
                  x1="10.5"
                  y1="68"
                  x2="17.5"
                  y2="68"
                  fill="none"
                  stroke="#c4cefe"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="3"
                />
                <line
                  x1="22.5"
                  y1="68"
                  x2="39.5"
                  y2="68"
                  fill="none"
                  stroke="#c4cefe"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="3"
                />
              </svg>
            </div>
            <div className="nk-help-text">
              <h5>{t('ManageSalesOffers')}</h5>
              <p className="text-soft">{t('TenderOfferSellBannerMessage')}</p>
            </div>
            <div className="nk-help-action">
              <Link to="/contacto" className="btn btn-lg btn-outline-primary">
                {t('MoreInfo')}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TenderOfferSharesBanner;
