/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { format } from 'date-fns';

import store from 'redux/store';
import { setModal } from 'redux/actions/modalActions';
import { addDocument } from 'redux/actions/documentActions';
import { updateSociety } from 'redux/actions/societyActions';

import fileTypes from 'constants/fileTypes';
import { dateFormat } from 'constants/formats';
import documentTypes from 'constants/documentTypes';
import { MAX_FILESIZE_ALLOWED } from 'constants/defaultConstants';
import operationTypesRealNames from 'constants/operationTypesRealNames';

const linkSections = [
  { text: 'General' },
  { text: 'Socios' },
  { text: 'Beneficiarios' },
  { text: 'Operaciones' },
  // { text: 'Borradores' },
];

const labelSections = {
  General: '',
  Socios: 'el Socio',
  Beneficiarios: 'el Beneficiario',
  Operaciones: 'la Operación',
  Borradores: 'el Borrador',
};

function DocumentModal({
  userId,
  societyId,
  partnerId,
  beneficiaryId,
  boardId,
  showLinkOptions = false,
}) {
  const { t } = useTranslation();

  const isAdmin = useSelector((state) => state.society?.role?.isAdmin);
  const actualSociety = useSelector((state) => state.society?.actualSociety);

  const [linkedTo, setLinkedTo] = useState('');
  const [linkedOptions, setLinkedOptions] = useState([]);
  const [linkedSection, setLinkedSection] = useState('');
  const [linkDocument, setLinkDocument] = useState(false);
  const [documentName, setDocumentName] = useState('');
  const [date, setDate] = useState('');
  const [file, setFile] = useState();

  async function saveDocument(event) {
    event.preventDefault();

    const newDocument = {
      file,
      date,
      name: documentName,
      author: userId,
      society: societyId,
      size: file?.size,
      fileType: fileTypes[file?.type],
      category: boardId ? documentTypes.DOCUMENTS : documentTypes.SCRIPTURES,
      description: t('DocumentLinkedManually'),
    };
    const uploadedDocument = await store.dispatch(addDocument(newDocument));

    let data = {};

    if (showLinkOptions) {
      switch (linkedSection) {
        case 'Socios':
          data = { docs: { add: uploadedDocument, partner: linkedTo } };
          break;
        case 'Beneficiarios':
          data = { docs: { add: uploadedDocument, beneficiary: linkedTo } };
          break;
        case 'Operaciones':
          data = { docs: { add: uploadedDocument, operation: linkedTo } };
          break;
        default:
          data = { docs: { add: uploadedDocument } };
          break;
      }
    } else {
      data = beneficiaryId
        ? { docs: { add: uploadedDocument, beneficiary: beneficiaryId } }
        : partnerId
        ? { docs: { add: uploadedDocument, partner: partnerId } }
        : boardId
        ? { docs: { add: uploadedDocument, board: boardId } }
        : { docs: { add: uploadedDocument } };
    }

    store.dispatch(updateSociety(societyId, data));
    store.dispatch(setModal(null));
  }

  const getSectionContent = (section) => {
    let sectionOptions = [];
    setLinkedTo('');

    switch (section) {
      case 'Socios':
        sectionOptions = actualSociety?.partners?.map((partner) => ({
          id: partner?.['_id'],
          text: `${partner?.name} | ${partner?.email}`,
        }));
        break;

      case 'Beneficiarios':
        sectionOptions = actualSociety?.beneficiaries?.map((beneficiary) => ({
          id: beneficiary?.['_id'],
          text: `${beneficiary?.name} | ${beneficiary?.email}`,
        }));
        break;

      case 'Operaciones':
        sectionOptions = actualSociety?.operations
          ?.map((operation) => ({
            id: operation?.['_id'],
            date: new Date(operation?.date).getTime(),
            text: `${format(new Date(operation?.date), dateFormat)} | ${
              operationTypesRealNames[operation?.operationType]
            } ${
              isAdmin && operation?.comments ? `| ${operation?.comments}` : ''
            }`,
          }))
          .sort((a, b) => b.date - a.date)
          .reverse();
        break;

      case 'Borradores':
        break;
      default:
        break;
    }
    if (sectionOptions?.length) {
      setLinkedTo(sectionOptions[0]?.id);
    }
    return sectionOptions;
  };

  useEffect(() => {
    setLinkedOptions(getSectionContent(linkedSection));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkedSection]);

  useEffect(() => {
    if (linkDocument) {
      setLinkedSection(linkSections[0].text);
    }
  }, [linkDocument]);

  return (
    <>
      <Modal.Header>
        <h5 className="modal-title">Añadir Documento</h5>
        <a className="close" onClick={() => store.dispatch(setModal(null))}>
          <em className="icon ni ni-cross" />
        </a>
      </Modal.Header>
      <Modal.Body>
        <form className="form-validate is-alter" onSubmit={saveDocument}>
          <div className="form-group">
            <label className="form-label" htmlFor="full-name">
              Nombre documento
            </label>
            <div className="form-control-wrap">
              <input
                type="text"
                className="form-control"
                value={documentName}
                onChange={(event) => setDocumentName(event.target.value)}
              />
            </div>
          </div>
          <div className="form-group">
            <label className="form-label" htmlFor="email-address">
              Fecha
            </label>
            <div className="form-control-wrap">
              <input
                type="date"
                className="form-control"
                value={date}
                onChange={(event) => setDate(event.target.value)}
                max="2100-01-01"
                min="1000-01-01"
              />
            </div>
          </div>

          <div className="form-group">
            <label className="form-label" htmlFor="default-06">
              Añadir documento
            </label>
            <div className="form-control-wrap">
              <div className="custom-file">
                <input
                  id="customFile"
                  type="file"
                  accept=".docx,.doc,.xls,.xlsx,.ppt,.pptx,.pdf"
                  className="custom-file-input"
                  onChange={(event) => setFile(event.target.files[0])}
                />
                <label className="custom-file-label" htmlFor="customFile">
                  {file?.name || 'Seleccionar documento'}
                </label>
              </div>
            </div>

            <div className="sub-text mt-1">
              Tamaño máximo permitido = <code>{MAX_FILESIZE_ALLOWED.text}</code>
              {' | '}
              Tamaño del archivo actual ={' '}
              <code>
                {(file && (file.size / 1024 / 1024).toFixed(2)) || 0}mb
              </code>
            </div>

            {file && file.size > MAX_FILESIZE_ALLOWED.value && (
              <div className="alert alert-icon alert-danger mt-2" role="alert">
                <em className="icon ni ni-alert-circle" />
                <strong>Archivo no válido</strong>. Excede el tamaño máximo
                permitido.
              </div>
            )}
          </div>

          {showLinkOptions && (
            <div className="form-group mt-2">
              <div className="custom-control custom-control-xs custom-checkbox">
                <input
                  type="checkbox"
                  id="checkbox-notifications"
                  className="custom-control-input"
                  value={linkDocument}
                  checked={linkDocument}
                  onChange={(event) => setLinkDocument(event.target.checked)}
                />
                <label
                  className="custom-control-label"
                  htmlFor="checkbox-notifications"
                >
                  ¿Desea vincular este documento?
                </label>
              </div>
            </div>
          )}

          {linkDocument && (
            <>
              <div className="form-group">
                <label className="form-label" htmlFor="pay-amount">
                  Seleccione la sección a vincular
                </label>
                <div className="form-control-wrap">
                  <div className="form-control-select">
                    <select
                      className="form-control"
                      value={linkedSection}
                      onChange={(event) => setLinkedSection(event.target.value)}
                    >
                      {linkSections.map((section) => (
                        <option
                          value={section.text}
                          key={`class-${section.text}`}
                        >
                          {section.text}
                        </option>
                      ))}
                    </select>
                  </div>
                  {linkedSection !== linkSections[0].text &&
                    linkedOptions.length === 0 && (
                      <span className="badge badge-dot ml-1 mt-1 text-danger w-100">
                        Esta sociedad aún no tiene&nbsp;
                        <b>&quot;{linkedSection}&quot;</b>. Necesita agregar
                        {linkedSection === linkSections[3].text
                          ? ' alguna '
                          : ' alguno '}
                        para poder vincularlo.
                      </span>
                    )}
                </div>
              </div>

              {linkedSection &&
                linkedSection !== 'General' &&
                linkedOptions.length > 0 && (
                  <div className="form-group">
                    <label className="form-label" htmlFor="pay-amount">
                      Seleccione {labelSections[linkedSection]}
                    </label>
                    <div className="form-control-wrap">
                      <div className="form-control-select">
                        <select
                          className="form-control"
                          value={linkedTo}
                          onChange={(event) => setLinkedTo(event.target.value)}
                        >
                          {linkedOptions.map((option) => (
                            <option
                              value={option.id}
                              key={`class-${option.id}`}
                            >
                              {option.text}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                )}
            </>
          )}

          <button
            type="submit"
            className="btn btn-lg btn-primary"
            disabled={
              !documentName ||
              !date ||
              !file ||
              file.size > MAX_FILESIZE_ALLOWED.value ||
              (linkDocument && !linkedSection) ||
              (linkDocument && linkedSection !== 'General' && !linkedTo)
            }
          >
            Guardar documento
          </button>
        </form>
      </Modal.Body>
    </>
  );
}

export default DocumentModal;
