/* eslint-disable react/jsx-props-no-spreading */

import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import { State } from 'redux/initialState';
import { Beneficiary } from 'types';

import {
  formatCurrencyDecimals,
  formatNumber,
  formatNumberDecimals,
} from 'utils/formats';
import hasShowDetails from 'utils/showDetails';
import { getActualSociety, getFullAddress } from 'utils/filters';

import { cleanValue, numberFormatDecimals } from 'constants/formats';

import PrintButton from 'components/Buttons/PrintButton';
import PrintHeader from 'components/Headers/PrintHeader';

import getPriceByPlan from './utils/getPriceByPlan';
import getAssignedAndTotalShares from './utils/getAssignedAndTotalShares';

type Props = {
  participant: any;
  society: any;
  societyValue: any;
  beneficiary: Beneficiary;
  beneficiaryAllPlans: any;
};

const BeneficiarySellInvoiceView = (props: Props) => {
  const {
    participant,
    beneficiary,
    beneficiaryAllPlans,
    society,
    societyValue,
  } = props;

  const { t } = useTranslation();
  const printRef = useRef<HTMLInputElement | null>(null);

  const simulatorSocietyValueRef = useRef<HTMLInputElement | null>(null);

  const { societyId } = society;

  const [simulatorLastSocietyValue, setSimulatorLastSocietyValue] = useState(
    societyValue?.value || 0
  );
  const [simulatorSocietyValue, setSimulatorSocietyValue] = useState(
    societyValue?.value || 0
  );
  const [simulatorRetention, setSimulatorRetention] = useState(0);
  const [simulatorSharePriceUnit, setSimulatorSharePriceUnit] = useState(0);
  const [summaryShares, setSummaryShares] = useState({
    societyShares: 0,
    assignedShares: 0,
    sharesTotal: 0,
  });
  const [simulatorSubtotal, setSimulatorSubtotal] = useState(0);
  const [simulatorRetentionTotal, setSimulatorRetentionTotal] = useState(0);
  const [simulatorTotal, setSimulatorTotal] = useState(0);
  const [simulatorIncludeAllUnits, setSimulatorIncludeAllShares] =
    useState(false);

  const user = useSelector((state: State) => state.user);
  const actualSociety = useSelector(
    (state: State) => state.society?.actualSociety
  );
  const tenderOffers = useSelector((state: State) => state.tenderOffers);

  const downloadFileName = `${t('PrintHeaderIncentiveValueSimulator')} - ${
    actualSociety?.name
  }`;

  const handleChangeSimulatorSocietyValue = (e: any) => {
    setSimulatorSocietyValue(e.target.value);
  };

  const handleChangeSimulatorRetention = (e: any) => {
    setSimulatorRetention(e.target.value);
  };

  const handleChangeIncludeAllUnits = (e: any) => {
    setSimulatorIncludeAllShares(e.target.checked);
  };

  const getFinalPriceByPlan = (
    beneficiaryAllPlans: any,
    simulatorSharePriceUnit: any,
    simulatorRetention: any,
    simulatorIncludeAllUnits: boolean
  ) => {
    let total = 0;
    beneficiaryAllPlans?.forEach((beneficiaryPlan: any) => {
      total += getPriceByPlan(
        beneficiaryPlan,
        simulatorSharePriceUnit,
        simulatorIncludeAllUnits
      );
    });
    return total;
  };

  useEffect(() => {
    if (beneficiaryAllPlans && actualSociety?.beneficiaries?.length) {
      const currentSocietyPlans = beneficiaryAllPlans?.map(
        (beneficiaryPlan: any) => ({ ...beneficiaryPlan?.planData })
      );
      const currentSocietyTenderOffers = tenderOffers?.filter(
        (tenderOffer: any) => tenderOffer?.society === society?.societyId
      );

      if (hasShowDetails()) {
        console.log('cclog', {
          currentSocietyPlans,
          currentSocietyTenderOffers,
        });
      }

      setSummaryShares(
        getAssignedAndTotalShares(
          actualSociety,
          currentSocietyPlans,
          currentSocietyTenderOffers
        )
      );
    }
  }, [beneficiaryAllPlans, actualSociety]);

  useEffect(() => {
    getActualSociety(user, societyId);
  }, [user, societyId]);

  useEffect(() => {
    setSimulatorSharePriceUnit(
      cleanValue(simulatorSocietyValue) / summaryShares?.societyShares
    );
  }, [simulatorSocietyValue, summaryShares?.societyShares]);

  useEffect(() => {
    const subtotal = getFinalPriceByPlan(
      beneficiaryAllPlans,
      cleanValue(simulatorSharePriceUnit),
      cleanValue(simulatorRetention),
      simulatorIncludeAllUnits
    );

    const retention = subtotal * (cleanValue(simulatorRetention) / 100);
    const total = subtotal - retention;

    setSimulatorSubtotal(subtotal);
    setSimulatorRetentionTotal(retention);
    setSimulatorTotal(total);
  }, [simulatorSharePriceUnit, simulatorRetention, simulatorIncludeAllUnits]);

  useEffect(() => {
    if (simulatorSocietyValueRef.current) {
      simulatorSocietyValueRef.current.focus();
    }
  }, []);

  if (hasShowDetails()) {
    console.log('cclog', {
      beneficiaryAllPlans,
      society,
      summaryShares,
      simulatorSharePriceUnit,
      simulatorIncludeAllUnits,
    });
  }

  return (
    <div
      className="invoice-wrap p-0 border-0"
      id="margin-print-only"
      ref={printRef}
    >
      <>
        <PrintButton
          fileName={downloadFileName}
          printRef={
            printRef ? (printRef as React.RefObject<HTMLDivElement>) : null
          }
          hideLabel
          className="border-0 bg-transparent text-primary btn-trigger p-1 position-absolute absolute-top-right mr-5 mt-4"
        />
        <PrintHeader title={t('PrintHeaderIncentiveValueSimulator')} />
        <div className="invoice-head d-print-block">
          <div className="invoice-contact col-6 d-print-inline-block">
            <span className="overline-title">{t('Beneficiary')}</span>

            <div className="invoice-contact-info">
              <h5 className="title">{participant.name}</h5>
              <ul className="list-plain d-flex flex-column">
                <li className="d-inline-flex">
                  <em className="icon ni ni-wallet" />
                  <span>{participant.cif}</span>
                </li>
                <li className="d-inline-flex">
                  <em className="icon ni ni-map-pin-fill" />
                  <span>{getFullAddress(participant.address)}</span>
                </li>
                <li className="d-inline-flex">
                  <em className="icon ni ni-mail" />
                  <span>{participant.email}</span>
                </li>
              </ul>
            </div>
          </div>

          <div className="invoice-contact col-6 d-print-inline-block">
            <span className="overline-title">{t('Conditions')}</span>

            <div className="invoice-contact-info">
              <h5 className="title mb-3">{t('Customizables')}</h5>
            </div>

            <div className="row">
              <div className="form-group col-7">
                <label className="form-label" htmlFor="simulator-society-value">
                  {t('SocietyValuationFD')}
                </label>
                <div className="form-control-wrap">
                  <div className="form-icon form-icon-right">
                    <em className="icon ni ni-sign-eur" />
                  </div>

                  <NumberFormat
                    id="simulator-society-value"
                    className="form-control form-control-outlined"
                    value={simulatorSocietyValue}
                    onChange={handleChangeSimulatorSocietyValue}
                    isAllowed={(inputObj) => {
                      const { floatValue, formattedValue } = inputObj;
                      if (formattedValue === '') return true;

                      // @ts-ignore
                      if (floatValue >= 1 && Number.isInteger(floatValue))
                        return true;
                      return false;
                    }}
                    {...numberFormatDecimals}
                    getInputRef={(inputRef: any) => {
                      simulatorSocietyValueRef.current = inputRef;
                    }}
                  />
                </div>
              </div>

              <div className="form-group col-5">
                <label className="form-label" htmlFor="simulator-society-value">
                  {t('Retention')}
                </label>
                <div className="form-control-wrap">
                  <div className="form-icon form-icon-right">
                    <em className="icon ni ni-percent" />
                  </div>

                  <NumberFormat
                    id="simulator-society-value"
                    className="form-control form-control-outlined"
                    value={simulatorRetention}
                    onChange={handleChangeSimulatorRetention}
                    isAllowed={(inputObj) => {
                      const { floatValue, formattedValue } = inputObj;
                      if (formattedValue === '') return true;

                      // @ts-ignore
                      if (floatValue >= 1 && Number.isInteger(floatValue))
                        return true;
                      return false;
                    }}
                    {...numberFormatDecimals}
                  />
                </div>
              </div>

              <div className="col-12">
                <div className="custom-control custom-checkbox custom-control-sm">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    name="include-all-units"
                    id="include-all-units"
                    checked={simulatorIncludeAllUnits}
                    onChange={handleChangeIncludeAllUnits}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="include-all-units"
                  >
                    {t('IncludeAllVestedUnits')}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="invoice-head mt-2">
          <div className="invoice-contact col-4">
            <span className="overline-title">{t('LastSocietyValuation')}</span>
            <div className="invoice-contact-info mt-2">
              <ul className="list-plain d-flex flex-column">
                <li className="d-inline-flex">
                  <em className="icon ni ni-growth" />
                  <span>
                    {formatNumberDecimals(simulatorLastSocietyValue) || '-'}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="invoice-bills border rounded">
          <div className="table-responsive">
            <table className="table table-striped mb-0">
              <thead>
                <tr>
                  <th className="text-center" style={{ width: '5%' }}>
                    Nº
                  </th>
                  <th className="text-center" style={{ width: '23%' }}>
                    {t('Plan')}
                  </th>
                  <th className="text-center" style={{ width: '9%' }}>
                    {t('Cliff')}
                    <br />
                    {t('(meses)')}
                  </th>
                  <th className="text-center" style={{ width: '9%' }}>
                    {t('Vesting')}
                    <br />
                    {t('(meses)')}
                  </th>
                  <th className="text-center" style={{ width: '9%' }}>
                    {t('NoOfUnits')}
                  </th>
                  <th className="text-center" style={{ width: '9%' }}>
                    {t('Vested')}
                  </th>
                  <th className="text-center" style={{ width: '9%' }}>
                    {t('SoldUnits')}
                  </th>
                  <th className="text-center" style={{ width: '9%' }}>
                    {t('StrikePriceUnit')}
                  </th>
                  <th className="text-center" style={{ width: '9%' }}>
                    {t('SellingPriceUnit')}
                  </th>

                  <th className="text-center" style={{ width: '9%' }}>
                    {t('Profit')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {beneficiaryAllPlans?.map(
                  (beneficiaryPlan: any, index: number) => (
                    <tr>
                      <td className="text-center">{index + 1}</td>
                      <td className="text-left">
                        {beneficiaryPlan?.planData?.name}
                      </td>
                      <td className="text-center">
                        {beneficiaryPlan?.planData?.cliff}
                      </td>
                      <td className="text-center">
                        {beneficiaryPlan?.planData?.vestingPeriod}
                      </td>
                      <td className="text-right">
                        {formatNumber(beneficiaryPlan?.sharesCount?.future)}
                      </td>
                      <td className="text-right">
                        {formatNumber(
                          beneficiaryPlan?.vestedUnits +
                            beneficiaryPlan?.soldUnits
                        )}
                      </td>
                      <td className="text-right">
                        {formatNumber(beneficiaryPlan?.soldUnits)}
                      </td>
                      <td className="text-right">
                        {formatCurrencyDecimals(
                          beneficiaryPlan?.planData?.sharePrice
                        )}
                      </td>
                      <td className="text-right">
                        {formatCurrencyDecimals(simulatorSharePriceUnit)}
                      </td>

                      <td className="text-right">
                        {formatCurrencyDecimals(
                          getPriceByPlan(
                            beneficiaryPlan,
                            cleanValue(simulatorSharePriceUnit),
                            simulatorIncludeAllUnits
                          )
                        )}
                        <sup>&nbsp;</sup>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={6} />
                  <td className="text-right" colSpan={2}>
                    {t('Subtotal')}
                  </td>
                  <td />
                  <td className="text-right">
                    {formatCurrencyDecimals(simulatorSubtotal)}
                  </td>
                </tr>

                <tr>
                  <td colSpan={6} />
                  <td className="text-right" colSpan={2}>
                    {t('Retention')}
                  </td>
                  <td />
                  <td className="text-right">
                    -{formatCurrencyDecimals(simulatorRetentionTotal)}
                  </td>
                </tr>

                <tr>
                  <td colSpan={6} />
                  <td className="text-right fw-bold" colSpan={2}>
                    {t('Total')}
                  </td>
                  <td />
                  <td className="text-right fw-bold">
                    {formatCurrencyDecimals(simulatorTotal)}
                  </td>
                </tr>
              </tfoot>
            </table>

            <span className="text-right text-soft fs-11px d-block mr-4 mb-3">
              {t('AmountWithholdingTax')}
            </span>
          </div>
        </div>
      </>
    </div>
  );
};

export default BeneficiarySellInvoiceView;
