import i18n from 'i18n/config';
import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import RestrictedImage from '../../assets/images/error-403.svg';

const Page403 = () => {
  const user = useSelector((state) => state.user);

  return (
    <div className="nk-app-root">
      <div className="nk-wrap nk-wrap-nosidebar bg-white">
        <div className="nk-content">
          <div className="nk-block-middle wide-md mx-auto">
            <div className="nk-error-ld text-center">
              <img className="nk-error-gfx" src={RestrictedImage} alt="error" />
              <div className="wide-xs mx-auto">
                <h3 className="nk-error-title">{i18n.t('403Title')}</h3>
                <p className="nk-error-text">
                  {i18n.t('403Text', {
                    email: user.email,
                  })}
                </p>
                <Link to="/">
                  <button
                    type="button"
                    color="primary"
                    size="lg"
                    className="btn btn-primary mt-2"
                  >
                    {i18n.t('GoToHome')}
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page403;
