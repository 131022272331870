/* eslint-disable no-nested-ternary */
/* eslint-disable react/display-name */
import React from 'react';
import i18n from 'i18n/config';

import screens from 'constants/screens';
import userTypes from 'constants/userTypes';
import MenuDots from 'components/MenuDots';
import CellData from 'components/Tables/CellData';
import screenSizes from 'constants/screenSizes';

function tableColumns(i18n) {
  return [
    {
      // mandatory props
      name: i18n.t('Beneficiary'),
      field: 'name',
      selector: (row) => row[i18n.t('Beneficiary')],
      sortable: true,
      // optional props
      grow: 2,
      cell: (row) =>
        CellData.Beneficiary({
          beneficiary: row.beneficiary,
          status: row.status,
          planId: row.planId,
          societyId: row.societyId,
          url: '/detalle-beneficiario',
        }),
      export: true,
    },
    {
      name: i18n.t('Email'),
      field: 'email',
      selector: (row) => row[i18n.t('Email')],
      cell: (row) =>
        CellData.PlainText({
          text: row[i18n.t('Email')],
        }),
      export: false,
      omit: true,
    },
    {
      name: i18n.t('CIF'),
      field: 'cif',
      selector: (row) => row[i18n.t('CIF')],
      cell: (row) =>
        CellData.PlainText({
          text: row[i18n.t('cif')],
        }),
      export: false,
      omit: true,
    },
    {
      name: i18n.t('DateOfConcession'),
      field: 'planStartDate',
      selector: (row) => row[i18n.t('DateOfConcession')],
      sortable: true,
      center: true,
      compact: true,
      cell: (row) =>
        CellData.PlainDate({
          date: row[i18n.t('DateOfConcession')],
        }),
      export: true,
    },
    {
      name: i18n.t('Units'),
      field: 'units',
      selector: (row) => row[i18n.t('Units')],
      sortable: true,
      center: true,
      compact: true,
      cell: (row) =>
        CellData.NumberNoDecimals({
          value: row[i18n.t('Units')],
        }),
      export: true,
    },
    {
      name: i18n.t('ExercisePrice'),
      field: 'amount',
      selector: (row) => row[i18n.t('ExercisePrice')],
      sortable: true,
      center: true,
      compact: true,
      cell: (row) =>
        CellData.CurrencyDecimals({
          value: row[i18n.t('ExercisePrice')],
        }),
      export: true,
    },
    {
      name: i18n.t('%FD'),
      field: 'FDpercent',
      selector: (row) => row[i18n.t('%FD')],
      sortable: true,
      center: true,
      compact: true,
      cell: (row) =>
        CellData.PercentDecimals({
          value: row[i18n.t('%FD')],
          decimals: row.decimals,
        }),
      export: true,
      hide: screenSizes.LG,
    },
    {
      name: i18n.t('JobTitle'),
      field: 'jobTitle',
      selector: (row) => row[i18n.t('JobTitle')],
      sortable: true,
      center: true,
      compact: true,
      cell: (row) =>
        CellData.PlainText({
          text: row[i18n.t('JobTitle')],
        }),
      export: true,
      hide: screenSizes.LG,
    },
    {
      name: i18n.t('Incentive'),
      field: 'incentive',
      selector: (row) => row[i18n.t('Incentive')],
      sortable: true,
      compact: true,
      center: true,
      cell: (row) =>
        CellData.PlainText({
          text: row[i18n.t('Incentive')],
        }),
      export: true,
      hide: screenSizes.LG,
    },
    {
      name: i18n.t('Plan'),
      field: 'planName',
      selector: (row) => row[i18n.t('Plan')],
      sortable: true,
      center: true,
      compact: true,
      cell: (row) =>
        CellData.PlainText({
          text: row[i18n.t('Plan')],
          className: 'ml-2',
        }),
      export: true,
    },
    {
      name: i18n.t('ConsolidatedPercent'),
      field: 'consolidatedPercent',
      selector: (row) => row[i18n.t('ConsolidatedPercent')],
      sortable: true,
      compact: true,
      cell: (row) =>
        CellData.ConsolidationPercent({
          consolidatedPercent: row[i18n.t('ConsolidatedPercent')],
        }),
      export: true,
    },
    {
      name: i18n.t('Invitation'),
      field: 'invitationStatus',
      selector: (row) => row[i18n.t('Invitation')],
      sortable: true,
      compact: true,
      cell: (row) =>
        CellData.InvitationStatus({
          text: row[i18n.t('Invitation')]?.text,
          style: row[i18n.t('Invitation')]?.color || 'dark',
          beneficiary: row.beneficiary,
          societyId: row.societyId,
          user: row.user,
          isSuperAdmin: row.isSuperAdmin,
        }),
      export: true,
    },
    {
      name: i18n.t('ConsolidationDate'),
      field: 'consolidationDate',
      selector: (row) => row[i18n.t('ConsolidationDate')],
      sortable: true,
      center: true,
      compact: true,
      cell: (row) =>
        CellData.PlainDate({
          date: row[i18n.t('ConsolidationDate')],
        }),
      export: true,
      hide: screens.LG,
    },
    {
      name: i18n.t('Status'),
      field: 'statusName',
      selector: (row) => row[i18n.t('Status')],
      sortable: true,
      center: true,
      compact: true,
      omit: true,
      cell: (row) =>
        CellData.PlainText({
          text: row[i18n.t('Status')],
        }),
      export: true,
      hide: screens.LG,
    },
    {
      name: i18n.t('UnitsSeen'),
      field: 'vestedUnits',
      selector: (row) => row[i18n.t('UnitsSeen')],
      sortable: true,
      center: true,
      compact: true,
      omit: true,
      cell: (row) =>
        CellData.NumberNoDecimals({
          value: row[i18n.t('UnitsSeen')],
        }),
      export: true,
      hide: screens.LG,
    },
    {
      name: i18n.t('ToVest'),
      field: 'toVestUnits',
      selector: (row) => row[i18n.t('ToVest')],
      sortable: true,
      center: true,
      compact: true,
      omit: true,
      cell: (row) =>
        CellData.NumberNoDecimals({
          value: row[i18n.t('ToVest')],
        }),
      export: true,
      hide: screens.LG,
    },
    {
      name: i18n.t('MonthsToVest'),
      field: 'months',
      selector: (row) => row[i18n.t('MonthsToVest')],
      sortable: true,
      center: true,
      compact: true,
      omit: true,
      cell: (row) =>
        CellData.NumberNoDecimals({
          value: row[i18n.t('MonthsToVest')],
        }),
      export: true,
      hide: screens.LG,
    },
    {
      name: i18n.t('Salary'),
      field: 'salary',
      selector: (row) => row[i18n.t('Salary')],
      sortable: true,
      center: true,
      compact: true,
      omit: true,
      cell: (row) =>
        CellData.NumberNoDecimals({
          value: row[i18n.t('Salary')],
        }),
      export: true,
      hide: screens.LG,
    },
    {
      name: i18n.t('LaboralCategory'),
      field: 'laboralCategory',
      selector: (row) => row[i18n.t('LaboralCategory')],
      sortable: true,
      center: true,
      compact: true,
      omit: true,
      cell: (row) =>
        CellData.PlainText({
          text: row[i18n.t('LaboralCategory')],
        }),
      export: true,
      hide: screens.LG,
    },
    {
      name: i18n.t('StrikePrice'),
      field: 'strikePrice',
      selector: (row) => row[i18n.t('StrikePrice')],
      sortable: true,
      center: true,
      compact: true,
      omit: true,
      cell: (row) =>
        CellData.PlainText({
          text: row[i18n.t('StrikePrice')],
        }),
      export: true,
      hide: screens.LG,
    },
    {
      sortable: false,
      cell: (row) => {
        if (!row.isDemo) {
          return (
            <ul className="nk-tb-actions gx-1">
              <li>
                <div className="dropdown">
                  <MenuDots
                    menuOptions={row.beneficiaryOptions}
                    id={row.beneficiary?.['_id']}
                    params={{
                      data: row.beneficiary,
                      societyId: row.societyId,
                      userType: userTypes.BENEFICIARY,
                      certificateData: row.certificateData,
                    }}
                    downloadURL={row.documentURL}
                    direction="left"
                  />
                </div>
              </li>
            </ul>
          );
        }
      },
      export: false,
      right: true,
      width: '50px',
      allowOverflow: true,
    },
  ];
}

export default tableColumns;
