/* eslint-disable no-underscore-dangle */
/* eslint-disable react/button-has-type */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { NUMERATE_FROM_START } from 'constants/defaultConstants';
import { createHoldingClass } from 'redux/actions/holdingClassActions';

const AddClassEmbedded = ({ setSelectedClassOption, setCreateClassInsite }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { actualSociety, holdingClasses } = useSelector((state) => ({
    actualSociety: state.society?.actualSociety,
    holdingClasses: state.holdingClasses,
  }));

  const [name, setName] = useState('');
  const [numerationOption, setNumerationOption] = useState();

  useEffect(() => {
    setNumerationOption(holdingClasses?.[0]?.['_id']);
  }, [holdingClasses]);

  function saveClass(event) {
    event.preventDefault();
    const newHoldingClass = {
      name,
      votes: 0,
      numerationOption:
        numerationOption !== NUMERATE_FROM_START ? numerationOption : null,
      lockupPeriod: new Date(),
      conversionRate: 0,
      hasConversionRights: false,
      hasLiquidationRights: false,
      hasAntidilutionRights: false,
      society: actualSociety['_id'],
    };
    dispatch(createHoldingClass(newHoldingClass));
    setSelectedClassOption({
      value: '',
      label: t('Select'),
    });
    setCreateClassInsite(false);
  }

  return (
    <>
      <form className="form-validate is-alter">
        <label
          className="form-label d-flex justify-between align-center"
          htmlFor="email-address"
        >
          <span>{t('NewClass')}</span>
          <em
            className="icon ni ni-cross text-primary"
            onClick={() => {
              setSelectedClassOption({
                value: '',
                label: t('Select'),
              });
              setCreateClassInsite(false);
            }}
          />
        </label>

        <div
          className="row"
          style={{
            marginBottom: '20px',
          }}
        >
          <div className="col d-flex">
            <div className="form-group col-6 mb-0 p-0">
              <div className="form-control-wrap">
                <input
                  type="text"
                  className="form-control"
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  placeholder={t('ClassName')}
                  required
                />
              </div>
            </div>
            <div className="form-group col-6 mb-0 p-0">
              <div className="form-control-wrap">
                <div className="form-control-select">
                  <select
                    className="form-control"
                    value={numerationOption}
                    onChange={(event) =>
                      setNumerationOption(event.target.value)
                    }
                  >
                    {holdingClasses?.map((holdingClass) => (
                      <option
                        value={holdingClass['_id']}
                        key={`class-${holdingClass['_id']}`}
                      >
                        {holdingClass.name} ({t('NumberFromTheLastClassPar')}{' '}
                        &quot;
                        {holdingClass.name}&quot;)
                      </option>
                    ))}
                    <option>{NUMERATE_FROM_START}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <button
          type="button"
          className="btn btn-block btn-light mt-2 mb-4"
          disabled={!name || !numerationOption}
          onClick={saveClass}
        >
          {t('AddClass')}
        </button>
      </form>
    </>
  );
};

export default AddClassEmbedded;
