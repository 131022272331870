import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  data: {
    page: number;
    screenShowAside: string;
    isCardVisible: boolean;
  };
  actions: {
    setPage: (page: number) => void;
    setIsCardVisible: (isCardVisible: boolean) => void;
  };
};

const BeneficiaryDetailsTabs: FC<Props> = ({ data, actions }) => {
  const { t } = useTranslation();

  const { page, screenShowAside, isCardVisible } = data;
  const { setPage, setIsCardVisible } = actions;

  const tabs = [
    {
      id: 'beneficiary-summary-tab',
      icon: 'ni ni-user-circle',
      title: 'Beneficiary',
    },
    { id: 'beneficiary-data-tab', icon: 'ni ni-list', title: 'Data' },
    {
      id: 'beneficiary-plans-tab',
      icon: 'ni ni-view-list-sq',
      title: 'Plans.',
    },
    {
      id: 'beneficiary-calendars-tab',
      icon: 'ni ni-calendar',
      title: 'Calendars',
    },
    {
      id: 'beneficiary-documents-tab',
      icon: 'ni ni-file-text',
      title: 'Documents',
    },
    {
      id: 'beneficiary-tender-offers-tab',
      icon: 'ni ni-repeat',
      title: 'Sales',
    },
  ];

  return (
    <ul
      className="nav nav-tabs nav-tabs-mb-icon nav-tabs-card px-3"
      id="beneficiary-details--tabs"
    >
      {tabs.map((tab, index) => (
        <li key={tab.id} className="nav-item">
          <p
            id={tab.id}
            className={`nav-link ${page === index ? 'active' : ''}`}
            onClick={() => setPage(index)}
          >
            <em className={`icon ${tab.icon}`} />
            <span>{t(tab.title)}</span>
          </p>
        </li>
      ))}

      <li className={`nav-item nav-item-trigger d-${screenShowAside}-none`}>
        <p
          className="toggle btn btn-icon btn-trigger bg-success"
          style={{
            borderRadius: '50%',
            color: 'white',
          }}
          data-target="userAside"
          onClick={() => setIsCardVisible(!isCardVisible)}
        >
          <em className="icon ni ni-user-list-fill" />
        </p>
      </li>
    </ul>
  );
};

export default BeneficiaryDetailsTabs;
