import React from 'react';
import i18n from 'i18n/config';

const BadgeSocietyHolderStatus = ({ status, color }) => (
  <span className={`badge text-white ${color || ''}`}>
    {i18n.t(status || '')}
  </span>
);

export default BadgeSocietyHolderStatus;
