import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMixpanel } from 'react-mixpanel-browser';

import { BLANK_COMPANY_LOGO } from 'constants/defaultConstants';

import { addUserDemo } from 'redux/actions/userActions';
import { useDispatch } from 'react-redux';
import { saveToStorage } from 'utils/token';

import '../../assets/styles/Pages.scss';
import '../../assets/styles/Styles.scss';
import YahooAvatar from '../../assets/images/yahoo-avatar.svg';
import GoogleAvatar from '../../assets/images/google-avatar.svg';
import MicrosoftAvatar from '../../assets/images/microsoft-avatar.svg';

import DemoLeftSection from './components/DemoLeftSection';
import getBrandData from './utils/brand';
import errors from './utils/errors';
import {
  defaultDarkColor,
  defaultPrimaryColor,
  stepsPercents,
} from './constants/demoConstants';

const societyDemoId = process.env.REACT_APP_DEMO_SOCIETY_ID;

const TryDemo = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();

  const [currentStep, setCurrentStep] = useState(1);

  const [isCompleted, setIsCompleted] = useState(false);
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [isSocietyExistent, setIsSocietyExistent] = useState(false);

  const [currentBrand, setCurrentBrand] = useState({});
  const [currentLogo, setCurrentLogo] = useState('/images/logo-dark-small.png');

  const [adminEmail, setAdminEmail] = useState('');
  const [societyName, setSocietyName] = useState('');
  const [societyDomain, setSocietyDomain] = useState('');
  const [societyDescription, setSocietyDescription] = useState('');
  const [societyPrimaryColor, setSocietyPrimaryColor] =
    useState(defaultPrimaryColor);
  const [societyDarkColor, setSocietyDarkColor] = useState(defaultDarkColor);

  const [errorMessage, setErrorMessage] = useState('');

  const getDataFromBrand = async () => {
    const brandData = await getBrandData(societyDomain, mixpanel);
    if (brandData) {
      setCurrentBrand(brandData);
    }
  };

  const getProgressBarStyle = () => ({
    width: stepsPercents[currentStep],
    backgroundColor: societyPrimaryColor ?? defaultPrimaryColor,
  });

  const getButtonStyle = () => ({
    borderColor: societyPrimaryColor ?? defaultPrimaryColor,
    backgroundColor: societyPrimaryColor ?? defaultPrimaryColor,
  });

  const goToSocietyDashboard = () => {
    window.location.hash = `/sociedad/${societyDemoId}`;
  };

  const createDemoEntities = async () => {
    setErrorMessage('');
    try {
      saveToStorage('access-token', process.env.REACT_APP_DEMO_TOKEN);

      const currentSocietyName = `${societyName} (Demo)`;
      const currentSocietyLogo =
        currentLogo === '/images/logo-dark-small.png'
          ? BLANK_COMPANY_LOGO
          : currentLogo;

      const userData = {
        email: adminEmail,
        demoData: {
          societyId: process.env.REACT_APP_DEMO_SOCIETY_ID,
          societyName: currentSocietyName,
          societyLogo: currentSocietyLogo,
          societyDomain,
          societyDescription,
          societyPrimaryColor,
          societyDarkColor,
        },
      };

      await dispatch(addUserDemo(userData));

      setIsCompleted(true);
    } catch (error) {
      setIsCompleted(false);

      if (error.message === errors.SOCIETY_ALREADY_EXISTS) {
        setIsSocietyExistent(true);
        setErrorMessage(errors.SOCIETY_ALREADY_EXISTS);
      } else {
        setErrorMessage(error.message);
      }
    }
  };

  const isStepValid = (step) => {
    if (step === 1) {
      return societyDomain.length > 0 && societyDomain.includes('.');
    }
    if (step === 2) {
      return societyName.length > 0;
    }
    if (step === 3) {
      return adminEmail.length > 0;
    }
    return false;
  };

  const handleChangeSocietyDomain = (e) => {
    setSocietyDomain(e.target.value.toLowerCase());
  };

  const handleChangeSocietyName = (e) => {
    setSocietyName(e.target.value);
  };

  const handleChangeAdminEmail = (e) => {
    setAdminEmail(e.target.value);
  };

  const handleNextStep = async () => {
    if (isStepValid(currentStep) === false) {
      return;
    }
    if (currentStep === 1) {
      await getDataFromBrand();
    }
    if (currentStep < 3) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePreviousStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleSubmit = async () => {
    await createDemoEntities();
  };

  const handleGoToSocietyDashboard = () => {
    goToSocietyDashboard();
  };

  const handleGoToLogin = () => {
    window.location.href = `${process.env.REACT_APP_DOMAIN}?redirect=sociedad/${societyDemoId}`;
  };

  const isEmailValid = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  useEffect(() => {
    if (currentBrand?.logo) {
      setCurrentLogo(currentBrand.logo);
    }
    if (currentBrand?.name) {
      setSocietyName(currentBrand.name);
    }
    if (currentBrand?.description) {
      setSocietyDescription(currentBrand.description);
    }
    if (currentBrand?.primaryColor) {
      setSocietyPrimaryColor(currentBrand.primaryColor);
    }
    if (currentBrand?.darkColor) {
      setSocietyDarkColor(currentBrand.darkColor);
    }
  }, [currentBrand]);

  return (
    <div className="nk-split nk-split-page nk-split-lg">
      <DemoLeftSection data={{ societyDarkColor, currentLogo }} />

      <div className="nk-split-content nk-split-stretch bg-white p-5 d-flex justify-center align-center flex-column">
        <div className="wide-xs-fix">
          <div
            className="nk-stepper stepper-init is-alter d-block"
            id="stepper-survey-v1"
          >
            <div className="nk-stepper-content">
              <div className="nk-stepper-progress stepper-progress mb-4">
                <div className="stepper-progress-count mb-2">
                  {currentStep} de 4
                </div>
                <div className="progress progress-md">
                  <div
                    className="progress-bar stepper-progress-bar"
                    style={getProgressBarStyle()}
                  />
                </div>
              </div>

              <div className="nk-stepper-steps stepper-steps">
                {/* STEP 1 -> SOCIETY DOMAIN */}
                {currentStep === 1 && !isCompleted && (
                  <div className="nk-stepper-step active">
                    <h5 className="title mb-3">¿Cuál es su dominio web?</h5>
                    <div className="row g-3">
                      <div className="col-12">
                        <div className="form-group mt-4">
                          <div className="form-control-wrap">
                            <input
                              type="text"
                              className="form-control form-control-lg"
                              id="society-domain"
                              name="society-domain"
                              placeholder="ej: sttok.com"
                              value={societyDomain}
                              onChange={handleChangeSocietyDomain}
                              required
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* STEP 2 -> SOCIETY NAME */}
                {currentStep === 2 && !isCompleted && (
                  <div className="nk-stepper-step active">
                    <h5 className="title mb-3">¿Cómo se llama su empresa?</h5>
                    <div className="row g-3">
                      <div className="col-12">
                        <div className="form-group mt-4">
                          <div className="form-control-wrap">
                            <input
                              type="text"
                              className="form-control form-control-lg"
                              id="society-name"
                              name="society-name"
                              placeholder="ej: Sttok Barcelona S.L."
                              value={societyName}
                              onChange={handleChangeSocietyName}
                              required
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* STEP 3 -> USER EMAIL */}
                {currentStep === 3 && !isCompleted && (
                  <div className="nk-stepper-step active">
                    <h5 className="title mb-3">¿Cuál es su correo?</h5>
                    <div className="row g-3">
                      <div className="col-12">
                        <div className="form-group mt-4">
                          <div className="form-control-wrap">
                            <div className="form-icon form-icon-right w-120px d-flex justify-content-between mr-1 z-1">
                              <img
                                className="z-1"
                                src={GoogleAvatar}
                                alt="Google"
                                width={36}
                                height={36}
                                title="Sólo se aceptan correos de Gmail, Microsoft y Yahoo"
                              />
                              <img
                                className="z-1"
                                src={MicrosoftAvatar}
                                alt="Microsoft"
                                width={30}
                                height={30}
                                title="Sólo se aceptan correos de Gmail, Microsoft y Yahoo"
                              />
                              <img
                                className="z-1"
                                src={YahooAvatar}
                                alt="Yahoo"
                                width={36}
                                height={36}
                                title="Sólo se aceptan correos de Gmail, Microsoft y Yahoo"
                              />
                            </div>
                            <input
                              type="text"
                              className="form-control form-control-lg"
                              id="admin-email"
                              name="admin-email"
                              placeholder="ej: admin@sttok.com"
                              value={adminEmail}
                              onChange={handleChangeAdminEmail}
                              required
                            />
                          </div>
                          {errorMessage && (
                            <span className="fs-12px text-danger">
                              {t(errorMessage)}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {/* STEP 4 -> CONFIRMATION */}
              {isCompleted && (
                <div className="nk-stepper-step">
                  <div className="pt-4 pb-2">
                    <em className="icon icon-circle icon-circle-xxl mb-4 ni ni-check bg-primary-dim" />
                    <h5 className="title mb-2">
                      Sociedad {societyName} creada
                    </h5>
                    <p>
                      Hemos creado una versión de tu empresa con datos ficticios
                      para que veas el gran potencial que tiene Sttok
                    </p>

                    <div className="col-12 px-0">
                      <div className="form-group mt-4">
                        <div className="custom-control custom-control-xs custom-checkbox">
                          <input
                            type="checkbox"
                            id="checkbox-notifications"
                            className="custom-control-input"
                            value={isTermsAccepted}
                            checked={isTermsAccepted}
                            onChange={() =>
                              setIsTermsAccepted(!isTermsAccepted)
                            }
                          />
                          <label
                            className="custom-control-label text-soft"
                            htmlFor="checkbox-notifications"
                          >
                            Acepto la{' '}
                            <a
                              href="https://www.sttok.com/privacidad/"
                              target="_blank"
                              rel="noreferrer"
                              className="text-soft"
                            >
                              política de privacidad
                            </a>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <ul className="nk-stepper-pagination pt-4 gx-4 gy-2 stepper-pagination">
                {currentStep > 1 && currentStep < 4 && !isCompleted && (
                  <li className="step-prev d-block">
                    <button
                      type="button"
                      className="btn btn-dim btn-primary"
                      onClick={handlePreviousStep}
                      style={{
                        color: societyPrimaryColor ?? defaultPrimaryColor,
                        '&::not(:disabled):not(.disabled):hover': {
                          color: societyPrimaryColor ? 'white' : 'black',
                          ...getButtonStyle(),
                        },
                      }}
                    >
                      Anterior
                    </button>
                  </li>
                )}

                {currentStep < 3 && !isCompleted && (
                  <li className="step-next d-block">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={handleNextStep}
                      style={getButtonStyle()}
                    >
                      Siguiente
                    </button>
                  </li>
                )}

                {currentStep === 3 && !isCompleted && !isSocietyExistent && (
                  <li className="step-submit d-block">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={handleSubmit}
                      disabled={!isEmailValid(adminEmail)}
                      style={getButtonStyle()}
                    >
                      Siguiente
                    </button>
                  </li>
                )}

                {currentStep === 3 && isSocietyExistent && (
                  <li className="step-submit d-block">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={handleGoToLogin}
                      disabled={!isEmailValid(adminEmail)}
                      style={getButtonStyle()}
                    >
                      Iniciar sesión
                    </button>
                  </li>
                )}

                {isCompleted && (
                  <li className="step-submit d-block">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={handleGoToSocietyDashboard}
                      disabled={!isTermsAccepted}
                      style={getButtonStyle()}
                    >
                      Acceder a la demo
                    </button>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TryDemo;
