/* eslint-disable no-restricted-syntax */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import percentRound from 'percent-round';
import ReactToPrint from 'react-to-print';
import { format } from 'date-fns';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

import {
  formatCurrencyDecimals,
  formatCurrencyDecimalsLong,
} from 'utils/formats';
import { isMobileScreen } from 'utils/getScreenSize';

import WidgetList from 'components/WidgetList';
import WidgetTableSimple from 'components/WidgetTableSimple';
import WidgetTableExtended from 'components/WidgetTableExtended';
import WidgetChartLine from 'components/WidgetChartLine';
import WidgetChartDoughnut, {
  legendModes,
} from 'components/WidgetChartDoughnut';

import {
  countOperationShares,
  getActualSociety,
  getBeneficiaryFDPercent,
  getOperationAmount,
  getPartnerFDPercent,
  getPartnerNDPercent,
  getSocietyOperations,
  getSocietyPartnersWithShares,
  getTimelinePartnerShares,
  getTotalContribution,
  getUsedSharesFromPlan,
  kFormatter,
} from 'utils/filters';
import { setMenu } from 'redux/actions/menuActions';
import { getDrafts } from 'redux/actions/draftActions';
import { getStockPlans } from 'redux/actions/planActions';
import { getHoldingClasses } from 'redux/actions/holdingClassActions';
import { getTenderOffers } from 'redux/actions/tenderOfferActions';

import menuTypes from 'constants/menuTypes';
import draftIcons from 'constants/draftIcons';
import draftTypes from 'constants/draftTypes';
import { dateFormat, getNumberFormatted } from 'constants/formats';
import operationTypes from 'constants/operationTypes';
import convertibleNoteStatus from 'constants/convertibleNoteStatus';
import tableRows from 'constants/tableRows';

import ClassesTable from 'pages/PartnerBook/ClassesTable';

import WidgetChartBar from 'components/WidgetChartBar';
import PrintHeader from 'components/Headers/PrintHeader';
import CustomLoading from 'components/CustomLoading';

const SocietyDashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { societyId } = useParams();
  const printRef = useRef();

  const user = useSelector((state) => state?.user);
  const role = useSelector((state) => state.society?.role);
  const isAdmin = useSelector((state) => state?.society?.role?.isAdmin);
  const actualSociety = useSelector((state) => state.society?.actualSociety);
  const plans = useSelector((state) => state?.plans);
  const drafts = useSelector((state) => state?.drafts);
  const tenderOffers = useSelector((state) => state.tenderOffers);

  const [sharedData, setSharedData] = React.useState();

  const societyName = actualSociety?.name;

  const getDataSocietyCapital = (society, drafts, plans) => {
    const shares = society?.sharesCount?.actual || 0;
    const sharesWithDrafts = society?.sharesCount?.actual || 0;

    const stockPlans = plans?.reduce(
      (acc, plan) => {
        if (!plan?.isDraft && plan?.planType === operationTypes.PHANTOM_SHARE) {
          acc.phantoms += getUsedSharesFromPlan(plan, society, tenderOffers);
        }
        if (!plan?.isDraft && plan?.planType === operationTypes.STOCK_OPTION) {
          acc.stockOptions += getUsedSharesFromPlan(
            plan,
            society,
            tenderOffers
          );
        }
        return acc;
      },
      { stockOptions: 0, phantoms: 0 }
    );
    const stockPlansWithDrafts = plans?.reduce(
      (acc, plan) => {
        if (plan?.planType === operationTypes.PHANTOM_SHARE) {
          acc.phantoms +=
            getUsedSharesFromPlan(plan, society, tenderOffers, true) +
            getUsedSharesFromPlan(plan, society, tenderOffers, false);
        }
        if (plan?.planType === operationTypes.STOCK_OPTION) {
          acc.stockOptions +=
            getUsedSharesFromPlan(plan, society, tenderOffers, true) +
            getUsedSharesFromPlan(plan, society, tenderOffers, false);
        }
        return acc;
      },
      { stockOptions: 0, phantoms: 0 }
    );

    const activeConvertibleNotes = drafts?.filter(
      (draft) =>
        draft.draftType === draftTypes.DRAFT_CONVERTIBLE_NOTE &&
        draft.status === convertibleNoteStatus.var.NC_ACTIVE
    );
    const activeConvertibleNotesWithDrafts = drafts?.filter(
      (draft) =>
        draft.draftType === draftTypes.DRAFT_CONVERTIBLE_NOTE &&
        draft.status === convertibleNoteStatus.var.NC_ACTIVE
    );

    const convertibleNotes =
      getTotalContribution(activeConvertibleNotes)?.totalShares || 0;
    const convertibleNotesWithDrafts =
      getTotalContribution(activeConvertibleNotesWithDrafts)?.totalShares || 0;

    const total =
      shares + stockPlans.stockOptions + stockPlans.phantoms + convertibleNotes;
    const totalWithDrafts =
      sharesWithDrafts +
      stockPlansWithDrafts.stockOptions +
      stockPlansWithDrafts.phantoms +
      convertibleNotesWithDrafts;

    const percents = total
      ? [
          (shares * 100) / total,
          (convertibleNotes * 100) / total,
          (stockPlans.stockOptions * 100) / total,
          (stockPlans.phantoms * 100) / total,
        ]
      : [0, 0, 0, 0];
    const percentsWithDrafts = totalWithDrafts
      ? [
          (sharesWithDrafts * 100) / totalWithDrafts,
          (convertibleNotesWithDrafts * 100) / totalWithDrafts,
          (stockPlansWithDrafts.stockOptions * 100) / totalWithDrafts,
          (stockPlansWithDrafts.phantoms * 100) / totalWithDrafts,
        ]
      : [0, 0, 0, 0];

    const roundPercents = percentRound(percents, 2);
    const roundPercentsWithDrafts = percentRound(percentsWithDrafts, 2);

    return [
      // NO DRAFTS
      {
        type: tableRows.TEXT,
        name: t('Shares'),
        value1: shares,
        value2: roundPercents[0],
      },
      {
        type: tableRows.TEXT,
        name: t('ConvertibleLoans'),
        value1: convertibleNotes,
        value2: roundPercents[1],
      },
      {
        type: tableRows.TEXT,
        name: t('Stockoptions'),
        value1: stockPlans.stockOptions,
        value2: roundPercents[2],
      },
      {
        type: tableRows.TEXT,
        name: t('Phantomshares'),
        value1: stockPlans.phantoms,
        value2: roundPercents[3],
      },
      { type: tableRows.TOTAL, name: 'TOTAL', value1: total, value2: 100 },
      { type: tableRows.DIVIDER },
      // WITH DRAFTS
      {
        type: tableRows.TITLE,
        name: t('CapitalIncludingDrafts'),
        value1: t('CapitalIncludingDraftsTooltip'),
      },
      {
        type: tableRows.TEXT,
        name: t('Shares'),
        value1: sharesWithDrafts,
        value2: roundPercentsWithDrafts[0],
      },
      {
        type: tableRows.TEXT,
        name: t('ConvertibleLoans'),
        value1: convertibleNotesWithDrafts,
        value2: roundPercentsWithDrafts[1],
      },
      {
        type: tableRows.TEXT,
        name: t('Stockoptions'),
        value1: stockPlansWithDrafts.stockOptions,
        value2: roundPercentsWithDrafts[2],
      },
      {
        type: tableRows.TEXT,
        name: t('Phantomshares'),
        value1: stockPlansWithDrafts.phantoms,
        value2: roundPercentsWithDrafts[3],
      },
      {
        type: tableRows.TOTAL,
        name: 'TOTAL',
        value1: totalWithDrafts,
        value2: 100,
      },
    ];
  };

  const getDataIncentivePlans = (society, plans) => {
    const stockPlans = plans.reduce((acc, plan) => {
      if (!plan?.isDraft) {
        const current = {
          type: tableRows.TEXT,
          name: plan?.name,
          value1: getUsedSharesFromPlan(plan, society, tenderOffers),
          value2: plan?.sharesTotal,
        };
        acc.push(current);
      }
      return acc;
    }, []);
    const totals = stockPlans.reduce(
      (acc, plan) => {
        acc.assigned += plan.value1;
        acc.total += plan.value2;
        return acc;
      },
      {
        assigned: 0,
        total: 0,
      }
    );
    stockPlans.push({
      type: tableRows.TOTAL,
      name: 'TOTAL',
      value1: totals.assigned,
      value2: totals.total,
    });
    return stockPlans;
  };

  const getDataMainPartners = (society) => {
    const orderedPartners = society?.partners
      .filter((partner) => partner?.sharesCount?.actual > 0)
      .sort((a, b) =>
        a?.sharesCount?.actual < b?.sharesCount?.actual ? 1 : -1
      );
    const result = orderedPartners?.map((partner) => ({
      name: partner.name,
      link: `/detalle-socio/${societyId}/${partner?.['_id']}`,
      value1: partner?.sharesCount?.actual,
      value2: getPartnerNDPercent(partner, society),
      value3: getPartnerFDPercent(partner, society),
      icon: 'ni-user',
      hasProgressBar: true,
    }));

    return result;
  };

  const getDataMainBeneficiaries = (society) => {
    const orderedBeneficiaries = society?.beneficiaries.filter(
      (beneficiary) => !beneficiary.isDraft && !beneficiary.isCanceled
    );

    const fullList = orderedBeneficiaries.map((beneficiary) => ({
      name: beneficiary.name,
      link: `/detalle-beneficiario/${societyId}/${beneficiary?.plan}/${beneficiary?.['_id']}`,
      value1: beneficiary?.sharesCount?.future,
      value2: getBeneficiaryFDPercent(beneficiary, society),
      icon: 'ni-user',
      hasProgressBar: true,
      cif: beneficiary.cif,
    }));

    const groupedBeneficiaries = [];

    fullList.forEach((beneficiary) => {
      const index = groupedBeneficiaries.findIndex(
        (item) => item.cif === beneficiary.cif
      );
      if (index === -1) {
        groupedBeneficiaries.push({
          name: beneficiary.name,
          link: beneficiary.link,
          value1: beneficiary.value1,
          value2: beneficiary.value2,
          icon: 'ni-user',
          hasProgressBar: true,
          cif: beneficiary.cif,
        });
      } else {
        groupedBeneficiaries[index].value1 += beneficiary.value1;
        groupedBeneficiaries[index].value2 += beneficiary.value2;
      }
    });

    const sortedBeneficiaries = groupedBeneficiaries.sort((a, b) =>
      a.value1 < b.value1 ? 1 : -1
    );

    return sortedBeneficiaries;
  };

  const getDataSocietyOperations = (society) => {
    const operations = getSocietyOperations(society);

    const excludedOps = [
      operationTypes.ADD_PARTNER,
      operationTypes.ADD_PARTNERS,
      operationTypes.ADD_BENEFICIARY,
      operationTypes.STOCK_OPTION,
      operationTypes.PHANTOM_SHARE,
    ];

    const filteredOperations = operations
      ?.filter(
        (transaction) => !excludedOps.includes(transaction.operationType)
      )
      .sort(
        (transactionA, transactionB) =>
          new Date(transactionB.date) - new Date(transactionA.date)
      );

    const outputOperations = filteredOperations?.map((operation) => ({
      operationId: operation?._id,
      operationType: operation?.operationType,
      societyId: operation?.society,
      date: operation?.date,
      shares: countOperationShares(operation),
      amount: getOperationAmount(operation, actualSociety),
    }));

    return {
      fullOperations: operations,
      chartOperations: outputOperations,
    };
  };

  const getDataDrafts = () => {
    if (!drafts) return [];

    const draftsData = drafts?.map((draft) => ({
      name: draft?.name,
      icon: draftIcons[draft?.draftType],
      value1: parseFloat(draft?.totalContribution?.toFixed(2) || 0),
      hasProgressBar: false,
    }));
    return draftsData;
  };

  const getDataFinancingKpis = (society) => {
    const { fullOperations, chartOperations } =
      getDataSocietyOperations(society);

    const operationsAllowed = [
      operationTypes.CONSTITUTION,
      operationTypes.CAPITAL_INCREASE,
    ];

    const operationsFiltered = chartOperations.filter((op) =>
      operationsAllowed.includes(op.operationType)
    );

    const inversion = operationsFiltered.reduce(
      (acc, op) => acc + op.amount,
      0
    );

    const lastIncreaseOperation = fullOperations
      .filter((op) => op.operationType === operationTypes.CAPITAL_INCREASE)
      .sort((a, b) => new Date(b.date) - new Date(a.date))[0];

    // iterate over transactions in lastIncreaseOperation and select the higher sharePremium
    const higherSharePremium =
      lastIncreaseOperation?.transactions.reduce((acc, transaction) => {
        if (transaction?.sharePremium > acc) {
          // eslint-disable-next-line no-param-reassign
          acc = transaction?.sharePremium;
        }
        return acc;
      }, 0) || 0;

    const nominalValue = lastIncreaseOperation?.nominalValue || 0;

    const shareValue = nominalValue + higherSharePremium;

    return {
      inversion,
      shareValue,
    };
  };

  const getDataSummaryKpis = () => {
    const societyData = getDataSocietyCapital(actualSociety, drafts, plans);

    const shareValue = getDataFinancingKpis(actualSociety)?.shareValue;
    const totalInversion = getDataFinancingKpis(actualSociety)?.inversion;
    const totalPartners = getSocietyPartnersWithShares(actualSociety).length;
    const totalShares = societyData[0].value1;
    const totalNCs = societyData[1].value1;
    const totalStockOptions = societyData[2].value1;
    const totalPhantoms = societyData[3].value1;
    const totalIncentives = societyData[2].value1 + societyData[3].value1;
    const totalWarrants = 0;

    const percentShares = societyData[0].value2;
    const percentNCs = societyData[1].value2;
    const percentStockOptions = societyData[2].value2;
    const percentPhantoms = societyData[3].value2;
    const percentWarrants = societyData[4].value2;

    setSharedData({
      ...sharedData,
      totalPartners,
      totalShares,
      totalNCs,
      totalStockOptions,
      totalPhantoms,
      totalIncentives,
      totalWarrants,
      totalInversion,
      shareValue,
      percentShares,
      percentNCs,
      percentStockOptions,
      percentPhantoms,
      percentWarrants,
    });
  };

  const getDataSummaryChart = (society) => {
    const { timelineShares, timelinePrices } =
      getTimelinePartnerShares(society);

    const sharesOperations = [];
    for (const [key, value] of Object.entries(timelineShares)) {
      const operation = actualSociety?.operations?.find((op) => op._id === key);
      sharesOperations.push({
        date: operation?.date,
        shares: value,
        price: timelinePrices[key],
        type: operation?.operationType,
      });
    }

    const sharesSorted = sharesOperations
      ? sharesOperations.sort((a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          return dateA - dateB;
        })
      : [];

    if (sharesSorted.length) {
      const lastOperation = sharesSorted[sharesSorted.length - 1];
      const today = new Date();
      if (
        new Date(lastOperation.date).toDateString() !== today.toDateString() &&
        new Date(lastOperation.date).getTime() < today.getTime()
      ) {
        sharesSorted.push({
          date: today,
          shares: lastOperation.shares,
          price: lastOperation.price,
          type: '',
        });
      }
    }

    const shares = sharesSorted.map((op) => op.shares);

    const labels = sharesSorted.map((op) => new Date(op.date));

    const operations = sharesSorted.map((op) => t(op.type));

    const options = {
      maintainAspectRatio: true,
      plugins: {
        legend: {
          display: false,
          position: 'top',
        },
        title: {
          display: false,
          text: 'Resumen Sociedad',
        },
        tooltip: {
          displayColors: false,
          callbacks: {
            label: (context) => {
              if (context.datasetIndex === 1) return '';
              return `${context.dataset?.label[context?.dataIndex] || ''}${
                context.dataset?.label[context?.dataIndex] ? ': ' : ''
              }${getNumberFormatted(context?.raw)}`;
            },
          },
        },
      },
      response: true,
      scales: {
        x: {
          type: 'time',
          time: {
            unit: 'month',
          },
        },
      },
    };

    const data = {
      labels,
      datasets: [
        {
          fill: true,
          label: operations,
          data: shares,
          borderColor: '#798bff',
          backgroundColor: '#798bff5c',
          tension: 0.4,
          pointRadius: 3,
          pointHoverRadius: 10,
        },
      ],
    };

    return { data, options };
  };

  const getDataFinancingChart = (society) => {
    const { timelineShares, timelinePrices } =
      getTimelinePartnerShares(society);

    const sharesOperations = [];
    for (const [key, value] of Object.entries(timelineShares)) {
      const operation = actualSociety?.operations?.find((op) => op._id === key);
      sharesOperations.push({
        date: operation?.date,
        shares: value,
        price: timelinePrices[key],
        type: operation?.operationType,
      });
    }

    const sharesSorted = sharesOperations
      ? sharesOperations.sort((a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          return dateA - dateB;
        })
      : [];

    const operationsForPricing = [
      operationTypes.CONSTITUTION,
      operationTypes.CAPITAL_INCREASE,
    ];

    const pricesFiltered = sharesSorted.filter((share) =>
      operationsForPricing?.includes(share.type)
    );

    const sharesByDate = pricesFiltered.reduce((acc, share) => {
      const date = format(new Date(share.date), dateFormat);
      const label = `${t(share.type)}: ${getNumberFormatted(share.price)}`;

      if (!acc[date]) {
        acc[date] = {
          price: share.price,
          type: share.type,
          date: share.date,
          label,
        };
      } else {
        acc[date].price += share.price;
        acc[date].label += ` | ${label}`;
      }
      return acc;
    }, {});

    const sharesByDateArray = Object.values(sharesByDate);

    if (sharesByDateArray.length) {
      const lastOperation = sharesByDateArray[sharesByDateArray.length - 1];
      const today = new Date();
      if (
        new Date(lastOperation.date).toDateString() !== today.toDateString()
      ) {
        sharesByDateArray.push({
          date: today,
          label: '',
          price: 0,
          type: '',
        });
      }
    }

    const prices = sharesByDateArray.map((op) => op.price);

    const labels = sharesByDateArray.map((op) => new Date(op.date));

    const operations = sharesByDateArray.map((op) => op.label);

    const options = {
      maintainAspectRatio: true,
      plugins: {
        legend: {
          display: false,
          position: 'top',
        },
        title: {
          display: false,
          text: 'Resumen Sociedad',
        },
        tooltip: {
          displayColors: false,
          callbacks: {
            label: (context) => {
              if (context.datasetIndex === 1) return '';
              return `${context.dataset?.label[context?.dataIndex] || ''}`;
            },
          },
        },
      },
      response: true,
      scales: {
        x: {
          type: 'time',
          time: {
            unit: 'month',
          },
          offset: true,
        },
      },
    };

    const data = {
      labels,
      datasets: [
        {
          fill: true,
          label: operations,
          data: prices,
          borderColor: '#798bff',
          backgroundColor: '#798bff',
          barThickness: 10,
          minBarThickness: 5,
          maxBarThickness: 30,
          pointStyle: 'circle',
          grouped: true,
        },
      ],
    };

    return { data, options };
  };

  const getDataCapitalDetailsChart = () => {
    const labels = [
      t('Shares'),
      t('ConvertibleLoans'),
      t('Stockoptions'),
      t('Phantomshares'),
    ];

    const details = [
      sharedData?.totalShares,
      sharedData?.totalNCs,
      sharedData?.totalStockOptions,
      sharedData?.totalPhantoms,
    ];

    const options = {
      responsive: true,
      plugins: {
        legend: {
          display: false,
          position: 'bottom',
        },
        title: {
          display: false,
          text: 'Resumen Sociedad',
        },
      },
    };

    const data = {
      labels,
      datasets: [
        {
          fill: true,
          label: 'Total',
          data: details,
          borderWidth: 2,
          borderColor: '#ffffff',
          backgroundColor: [
            '#798bff',
            '#b8acff',
            '#ffa9ce',
            '#7de1f8',
            '#f9db7b',
          ],
        },
      ],
    };

    return { data, options };
  };
  const handleDownload = (event) => {
    event.preventDefault();
    const btn = document.getElementById('summaryBtn');
    if (actualSociety?.partners.length > 0 && plans && btn) {
      btn.click();
    }
  };
  const getDataPartnersChart = (society) => {
    const partners = getDataMainPartners(society);

    const partnersToShow = [];
    const restValues = {
      value1: 0,
      value2: 0,
      value3: 0,
    };

    const chartColors = [
      '#798bff',
      '#b8acff',
      '#ffa9ce',
      '#7de1f8',
      '#f9db7b',
      '#88ea6a',
    ];

    partners.forEach((partner, index) => {
      if (index < 5) {
        partnersToShow.push(partner);
      } else {
        restValues.value1 += partner.value1;
        restValues.value2 += partner.value2;
        restValues.value3 += partner.value3;
      }
    });

    partnersToShow[5] = {
      name: 'Resto',
      icon: 'ni-user',
      ...restValues,
    };

    const labels = partnersToShow.map((partner) => partner.name);
    const values = partnersToShow.map((partner) => partner.value3);

    const partnersToChart = partnersToShow.map((partner, index) => ({
      title: partner.name,
      icon: 'ni-user',
      color: chartColors[index],
      value: partner.value1,
      percent: partner.value3,
    }));

    const options = {
      responsive: true,
      plugins: {
        legend: {
          display: false,
          position: 'bottom',
        },
        title: {
          display: false,
          text: 'Socios',
        },
      },
    };

    const data = {
      labels,
      datasets: [
        {
          fill: true,
          label: 'Porciento',
          data: values,
          borderWidth: 2,
          borderColor: '#ffffff',
          backgroundColor: chartColors,
        },
      ],
    };

    return { chart: { data, options }, partners: partnersToChart };
  };

  useEffect(() => {
    if (actualSociety?.['_id'] !== societyId || !role)
      getActualSociety(user, societyId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety, user, societyId]);

  useEffect(() => {
    if (societyId) {
      dispatch(getDrafts(societyId));
      dispatch(getStockPlans(societyId));
      dispatch(getHoldingClasses(societyId));
      dispatch(getTenderOffers(societyId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [societyId]);

  useEffect(() => {
    if (actualSociety && societyId && drafts && plans && tenderOffers) {
      getDataSummaryKpis();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety, societyId, drafts, plans, tenderOffers]);

  useEffect(() => {
    if (actualSociety) {
      dispatch(
        setMenu({
          type: menuTypes.EXTENDED,
          societyId: actualSociety?.['_id'],
          societyName: actualSociety?.name,
        })
      );
    }
  }, [actualSociety, dispatch]);

  useEffect(() => {
    if (actualSociety) {
      getDataPartnersChart(actualSociety);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualSociety]);

  return actualSociety ? (
    <>
      <div className="nk-content-body" ref={printRef} id="margin-print-only">
        <div className="nk-content-wrap">
          <div className="nk-block-head-content d-flex justify-content-between">
            <h3 className="nk-block-title page-title">{actualSociety.name}</h3>
            <PrintHeader title={t('PrintHeaderDashboardSociety')} />
          </div>

          <div className="nk-block-head">
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <div className="nk-block-des text-soft">
                  <p>
                    {`${t('SocietyDashboardTitle')} ${actualSociety?.name}`}
                  </p>
                </div>
              </div>

              <div className="nk-block-head-content">
                <div className="toggle-wrap nk-block-tools-toggle">
                  <div
                    className="toggle-expand-content"
                    data-content="pageMenu"
                  >
                    <ul className="nk-block-tools">
                      {isAdmin && (
                        <>
                          <li>
                            <ReactToPrint
                              trigger={() => (
                                <button
                                  type="button"
                                  id="screen-only"
                                  className="btn btn-white btn-outline-light"
                                >
                                  <em className="icon ni ni-printer-fill" />
                                  <span>{t('Print')}</span>
                                </button>
                              )}
                              content={() => printRef.current}
                              documentTitle={`${
                                actualSociety?.name
                              } - Sttok ${format(new Date(), 'dd-MM-yyyy')}`}
                            />
                          </li>
                        </>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="nk-block d-flex flex-wrap justify-content-between">
            <div className="row">
              <WidgetChartLine
                title={t('CompanySummary')}
                subtitle={t('CompanyMainIndicators', { societyName })}
                kpis={[
                  {
                    title: t('Partners'),
                    value: kFormatter(sharedData?.totalPartners),
                    icon: 'ni-user',
                    link: `/socios/${actualSociety?._id}`,
                  },
                  {
                    title: t('Shares'),
                    value: kFormatter(sharedData?.totalShares),
                    icon: 'ni-layers',
                    link: `/socios/${actualSociety?._id}`,
                  },
                  !isMobileScreen()
                    ? {
                        title: t('Nominal'),
                        value: formatCurrencyDecimalsLong(
                          actualSociety?.nominalValue
                        ),
                        icon: 'ni-layers',
                        link: `/socios/${actualSociety?._id}`,
                      }
                    : null,
                  {
                    title: t('NoteConv.'),
                    value: kFormatter(sharedData?.totalNCs),
                    icon: 'ni-invest',
                    link: `/borradores/${actualSociety?._id}`,
                  },
                  {
                    title: t('Incentives'),
                    value: kFormatter(sharedData?.totalIncentives),
                    icon: 'ni-layers',
                    link: `/beneficiarios-planes/${actualSociety?._id}`,
                  },
                ].filter((item) => item)}
                data={getDataSummaryChart(actualSociety)}
                extraLabel={t('SocialCapital')}
                extraValue={formatCurrencyDecimals(
                  sharedData?.totalShares * actualSociety?.nominalValue || 0
                )}
              />

              <WidgetChartBar
                title={t('Financing')}
                subtitle={t('FinancingEvolution')}
                height={222}
                kpis={[
                  {
                    title: t('Shar.'),
                    value: kFormatter(sharedData?.totalShares),
                    icon: 'ni-layers',
                    // link: `/socios/${actualSociety?._id}`,
                  },
                  {
                    title: t('Investment.'),
                    value: kFormatter(sharedData?.totalInversion),
                    icon: 'ni-invest',
                    // link: `/borradores/${actualSociety?._id}`,
                  },
                  {
                    title: t('ValueShar.'),
                    value: `${sharedData?.shareValue.toFixed(2)}€`,
                    icon: 'ni-layers',
                    // link: `/beneficiarios-planes/${actualSociety?._id}`,
                  },
                ]}
                data={getDataFinancingChart(actualSociety)}
              />

              <WidgetTableExtended
                title={t('MainPartners')}
                linkText={t('SeeAlls')}
                linkUrl={`/socios/${societyId}`}
                columns={[t('Partner'), t('Shar'), t('%ND'), t('%FD')]}
                data={getDataMainPartners(actualSociety)}
              />

              <WidgetChartDoughnut
                title={t('Partners')}
                linkText={t('SeeAlls')}
                linkUrl={`/socios/${societyId}`}
                data={getDataPartnersChart(actualSociety).chart}
                legendMode={legendModes.VERTICAL}
                legend={getDataPartnersChart(actualSociety).partners}
              />

              <WidgetTableSimple
                title={t('Capital')}
                // linkText={t('Download')}
                // handleDownload={handleDownload}
                subtitle={t('CapitalDistributionTitle')}
                data={getDataSocietyCapital(actualSociety, drafts, plans)}
              />

              <WidgetChartDoughnut
                title={t('CapitalDetail')}
                data={getDataCapitalDetailsChart(actualSociety)}
                legendMode={legendModes.HORIZONTAL}
                legend={[
                  {
                    title: t('Share.'),
                    icon: 'ni-reports-alt',
                    color: '#798bff',
                    value: kFormatter(sharedData?.totalShares),
                    percent: sharedData?.percentShares?.toFixed(2),
                  },
                  {
                    title: t('NoteConv.'),
                    icon: 'ni-report-profit',
                    color: '#b8acff',
                    value: kFormatter(sharedData?.totalNCs),
                    percent: sharedData?.percentNCs?.toFixed(2),
                  },
                  {
                    title: t('StockOptions'),
                    icon: 'ni-users',
                    color: '#ffa9ce',
                    value: kFormatter(sharedData?.totalStockOptions),
                    percent: sharedData?.percentStockOptions?.toFixed(2),
                  },
                  {
                    title: t('Phantoms'),
                    icon: 'ni-users-fill',
                    color: '#7de1f8',
                    value: kFormatter(sharedData?.totalPhantoms),
                    percent: sharedData?.percentPhantoms?.toFixed(2),
                  },
                ]}
              />

              <WidgetTableSimple
                title={t('IncentivePlans')}
                subtitle={t('SocietyHasXPlans', {
                  societyName,
                  plansCount: plans.filter((plan) => !plan?.isDraft).length,
                })}
                linkText={t('SeeAlls')}
                linkUrl={`/beneficiarios-planes/${societyId}`}
                data={getDataIncentivePlans(actualSociety, plans)}
              />

              <WidgetList
                title={t('Transactions')}
                subtitle={t('LastTransactions')}
                linkText={t('SeeAll')}
                linkUrl={`/transacciones/${societyId}`}
                data={getDataSocietyOperations(actualSociety)?.chartOperations}
              />

              <WidgetTableExtended
                title={t('MainBeneficiaries')}
                linkText={t('SeeAlls')}
                linkUrl={`/beneficiarios/${societyId}`}
                columns={[t('Beneficiary'), t('Unit'), t('%FD')]}
                data={getDataMainBeneficiaries(actualSociety)}
              />

              <WidgetTableExtended
                title={t('OperationsSimulator')}
                linkText={t('SeeAlls')}
                linkUrl={`/borradores/${societyId}`}
                columns={[t('Operation'), t('Amount')]}
                data={getDataDrafts()}
              />
            </div>

            {/* <ReactHTMLTableToExcel
              id="summaryBtn"
              className="d-none"
              table="partnerClassTable"
              filename={`${t('Summary')} - ${actualSociety?.name}`}
              sheet="Libro Registro de Socios"
              buttonText={`${t('Download')}`}
              buttonComponent={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <button
                  type="button"
                  className="btn btn-white btn-outline-light"
                >
                  <em className="icon ni ni-file-download" />
                </button>
              }
            />
            {actualSociety?.partners.length > 0 && plans && (
              <ClassesTable view={0} />
            )} */}
          </div>
        </div>
      </div>
    </>
  ) : (
    <CustomLoading />
  );
};

export default SocietyDashboard;
