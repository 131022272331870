/* eslint-disable no-nested-ternary */
/* eslint-disable react/display-name */
import React from 'react';
import CellData from 'components/Tables/CellData';
import screens from 'constants/screens';

function tableColumns(i18n) {
  return [
    {
      name: i18n.t('Nr'),
      field: 'participantNumber',
      selector: (row) => row[i18n.t('Nr')],
      cell: (row) =>
        CellData.PlainText({
          text: row[i18n.t('Nr')],
        }),
      grow: 0.1,
      minWidth: 20,
      sortable: true,
      center: true,
      export: true,
    },
    {
      name: i18n.t('Name'),
      field: 'participantName',
      selector: (row) => row[i18n.t('Name')],
      cell: (row) =>
        CellData.PlainTextLink({
          text: row[i18n.t('Name')],
          url: `/detalle-socio/${row.societyId}/${row.participantMemberId}`,
        }),
      grow: 4,
      sortable: true,
      left: true,
      export: true,
    },
    {
      name: i18n.t('Shares'),
      field: 'shares',
      selector: (row) => row[i18n.t('Shares')],
      cell: (row) =>
        CellData.NumberNoDecimals({ value: row[i18n.t('Shares')] }),
      grow: 2,
      hide: screens.SM,
      sortable: true,
      center: true,
      compact: true,
      export: true,
    },
    {
      name: i18n.t('%ND'),
      field: 'percentage',
      selector: (row) => row[i18n.t('%ND')],
      cell: (row) =>
        CellData.PercentDecimals({
          value: row[i18n.t('%ND')],
          decimals: row.decimals,
        }),
      grow: 2,
      hide: screens.MD,
      sortable: true,
      center: true,
      compact: true,
      export: true,
    },
    {
      name: i18n.t('RepresentativeBoard'),
      field: 'representativeName',
      selector: (row) => row[i18n.t('RepresentativeBoard')],
      cell: (row) =>
        CellData.PlainText({
          text: row[i18n.t('RepresentativeBoard')],
        }),
      grow: 4,
      hide: screens.SM,
      sortable: true,
      left: true,
      compact: false,
      export: true,
    },
    {
      name: i18n.t('Attend'),
      field: 'participant',
      selector: (row) => row[i18n.t('Attend')],
      cell: (row) =>
        CellData.AttendanceBadge({
          assistant: row[i18n.t('Attend')],
        }),
      grow: 3,
      sortable: true,
      left: true,
      compact: true,
      export: true,
    },
  ];
}
export default tableColumns;
