import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { dateFormat } from 'constants/formats';
import userTypes from 'constants/userTypes';
import { format } from 'date-fns';
import { useDispatch } from 'react-redux';

import { setModal } from 'redux/actions/modalActions';

import EditModal from './EditModal';

function UserMyData({ user }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [displayAddress, setDisplayAddress] = useState('');
  const [displayBirthDate, setDisplayBirthDate] = useState('');

  useEffect(() => {
    const { line1, zip, city, country } = user?.address || {};
    const addressText = () =>
      `${line1 ? `${line1},` : ''} ${zip || ''} ${city || ''} ${
        country ? `(${country})` : ''
      }`;

    setDisplayAddress(addressText);
    setDisplayBirthDate(
      user?.birthdate ? format(new Date(user?.birthdate), dateFormat) : ''
    );
  }, [user]);

  return (
    <div className="card-inner card-inner-lg position-relative">
      <div className="nk-block-head">
        <div className="nk-block-head-content">
          <h4 className="nk-block-title">{t('PersonalInformation')}</h4>
        </div>
      </div>
      <div className="nk-block">
        <div
          className="nk-data data-list data-list-s2"
          onClick={() =>
            dispatch(
              setModal(<EditModal user={user} userType={userTypes.USER} />)
            )
          }
        >
          <div className="data-head">
            <h6 className="overline-title">{t('YourData')}:</h6>
          </div>
          <div
            className="data-item"
            data-toggle="modal"
            data-target="#profile-edit"
          >
            <div className="data-col">
              <span className="data-label">{t('FullName')}</span>
              <span className="data-value">{user.name || '-'}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <em className="icon ni ni-forward-ios" />
              </span>
            </div>
          </div>
          <div
            className="data-item"
            data-toggle="modal"
            data-target="#profile-edit"
          >
            <div className="data-col">
              <span className="data-label">{t('Email')}</span>
              <span className="data-value">{user.email}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <em className="icon ni ni-forward-ios" />
              </span>
            </div>
          </div>
          <div
            className="data-item"
            data-toggle="modal"
            data-target="#profile-edit"
            data-tab-target="#address"
          >
            <div className="data-col">
              <span className="data-label">{t('Address')}</span>
              <span className="data-value">{displayAddress}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <em className="icon ni ni-forward-ios" />
              </span>
            </div>
          </div>
          <div
            className="data-item"
            data-toggle="modal"
            data-target="#profile-edit"
          >
            <div className="data-col">
              <span className="data-label">{t('Nationality')}</span>
              <span className="data-value">{user.nationality || '-'}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <em className="icon ni ni-forward-ios" />
              </span>
            </div>
          </div>
          <div
            className="data-item"
            data-toggle="modal"
            data-target="#profile-edit"
            data-tab-target="#profile-edit"
          >
            <div className="data-col">
              <span className="data-label">{t('Image')}</span>
              <div className="user-avatar md bg-primary">
                <img src={user.image} alt="profile" />
              </div>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <em className="icon ni ni-forward-ios" />
              </span>
            </div>
          </div>
        </div>
        <div
          className="nk-data data-list data-list-s2"
          onClick={() =>
            dispatch(
              setModal(<EditModal user={user} userType={userTypes.USER} />)
            )
          }
        >
          <div className="data-head">
            <h6 className="overline-title">
              {t('OtherInformationNotVisible')}
            </h6>
          </div>
          <div
            className="data-item"
            data-toggle="modal"
            data-target="#profile-edit"
          >
            <div className="data-col">
              <span className="data-label">{t('Telephone')}</span>
              <span className="data-value">{user.phone || '-'}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <em className="icon ni ni-forward-ios" />
              </span>
            </div>
          </div>
          <div
            className="data-item"
            data-toggle="modal"
            data-target="#profile-edit"
          >
            <div className="data-col">
              <span className="data-label">{t('DateOfBirth')}</span>
              <span className="data-value">{displayBirthDate || '-'}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <em className="icon ni ni-forward-ios" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserMyData;
