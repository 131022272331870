import { updateUser } from 'redux/actions/userActions';
import store from 'redux/store';
import { editUser } from 'utils/modals';

export default [
  {
    icon: 'ni-edit',
    text: 'Editar',
    action: editUser,
  },
  {
    icon: 'ni-trash',
    text: 'Eliminar',
    action: ({ userId }, adminId) =>
      store.dispatch(updateUser(userId, { admin: { remove: adminId } })),
  },
];
