import React from 'react';
import store from 'redux/store';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { format } from 'date-fns';

import BeneficiaryCertificate from 'components/BeneficiaryCertificate';
import { getUsers } from 'redux/actions/userActions';
import { addAlert } from 'redux/actions/alertActions';
import alertTypes from 'constants/alertTypes';
import { DATE_FORMAT } from 'constants/defaultConstants';
import { dayMonthYear } from './formatDate';

export default async function createCertificate(params) {
  const { societyData, beneficiaryData, planData } = params?.certificateData;
  let admin;

  if (societyData?.administrator) {
    try {
      [admin] = await store.dispatch(
        getUsers({
          usersList: [societyData?.administrator],
        })
      );
    } catch (error) {
      console.log(error);
    }
  }

  const certificateData = {
    societyName: societyData?.name,
    creationDate: format(new Date(beneficiaryData?.planStartDate), DATE_FORMAT),
    beneficiaryFullName: beneficiaryData?.name,
    beneficiaryShares: beneficiaryData?.sharesCount?.future,
    societyCIF: societyData?.cif,
    planAprobationDate:
      planData?.startDate && dayMonthYear(planData?.startDate),
    sharePrice: planData?.sharePrice,
    totalValueShares: (
      beneficiaryData?.sharesCount?.future * planData?.sharePrice
    ).toFixed(2),
    downloadDate: dayMonthYear(new Date()),
    societyAdmin: admin?.name || '',
  };

  store.dispatch(
    addAlert({
      type: alertTypes.SUCCESS,
      title: '<h4 class="nk-modal-title">¡Certificado listo!</h4>',
      html: (
        <>
          <p>Puede proceder a descargarlo a través del siguiente enlace:</p>
          <PDFDownloadLink
            document={
              <BeneficiaryCertificate certificateData={certificateData} />
            }
            fileName="Certificado de Participación en Plan de Incentivos.pdf"
          >
            {({ loading }) =>
              loading
                ? 'Generando Certificado...'
                : 'Certificado de Participación en Plan de Incentivos.pdf'
            }
          </PDFDownloadLink>
        </>
      ),
    })
  );
}
