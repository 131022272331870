/* eslint-disable no-case-declarations */
import actionTypes from 'redux/actions/actionTypes';
import initialState from '../initialState';

export default function reducer(plan = initialState.plan, action) {
  switch (action.type) {
    case actionTypes.CREATE_STOCK_PLAN:
      return [...plan, action.plan];

    case actionTypes.GET_STOCK_PLAN:
      return [action.plan];

    case actionTypes.GET_STOCK_PLANS:
      return action.data;

    case actionTypes.UPDATE_STOCK_PLAN:
      return [
        ...plan.filter((plan) => plan['_id'] !== action.plan['_id']),
        action.plan,
      ];

    case actionTypes.DELETE_STOCK_PLAN:
      return [
        ...plan.filter(
          (currentPlan) => currentPlan['_id'] !== action.plan['_id']
        ),
      ];

    default:
      return plan;
  }
}
