/* eslint-disable react/jsx-props-no-spreading */
import draftTypes from 'constants/draftTypes';
import {
  currencyFormatDecimals,
  numberFormat,
  numberFormatDecimals,
} from 'constants/formats';
import i18n from 'i18n/config';
import proportionalityTypes from 'pages/DraftCapitalIncrease/proportionalityTypes';
import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import { Tooltip } from 'react-tooltip';
import { formatCurrencyDecimals, formatNumberDecimals } from 'utils/formats';
import menuOptions from 'components/DraftConvertibleNoteRow/menuOptions';
import MenuDots from 'components/MenuDots';

function DraftCapitalIncreaseRow({
  print = false,
  draft,
  partner,
  addPartnerToDraft,
  updatePartnerToDraft,
  valuation,
  premium,
  setTouched,
  removePartner,
  isConverted = false,
}) {
  const {
    name,
    email,
    contribution,
    realContribution,
    proportionality,
    proportionalityPercent,
    NDPercent,
    NDTotal,
    FDTotal,
    FDPotential,
    shares,
    discountPercent,
    generatedKey,
    isHidden,
  } = partner;

  const [firstName, lastName] = name?.split(' ') || [];
  const [currentMenuOptions, setCurrentMenuOptions] = useState(
    menuOptions(i18n)
  );
  const userInitials = lastName
    ? `${firstName[0]}${lastName[0]}` // get 2 first letters of the firstname
    : firstName?.substring(0, 2);

  return (
    <>
      <tr className="nk-tb-item cursor-pointer">
        <td className="nk-tb-col">
          <div className="user-card">
            {!print && (
              <div className="user-avatar xs bg-dim-primary d-none d-sm-flex">
                <span style={{ lineHeight: '26px' }}>
                  {userInitials.toUpperCase()}
                </span>
              </div>
            )}
            <div className="user-info">
              <span className="tb-lead">{name}</span>
              {isHidden && (
                <span className="badge rounded-pill bg-outline-primary">
                  {i18n.t('Transferred')}
                </span>
              )}
            </div>
          </div>
        </td>
        {print && (
          <td className="nk-tb-col">
            <span>{email}</span>
          </td>
        )}
        <td className="nk-tb-col tb-col-md text-right">
          <span className="tb-amount d-flex flex-column">
            {!print ? (
              <>
                <NumberFormat
                  value={contribution}
                  displayType="text"
                  {...currencyFormatDecimals}
                />
                <span
                  id={generatedKey}
                  className="text-muted"
                  style={{
                    fontSize: '10px',
                  }}
                >
                  {formatCurrencyDecimals(realContribution)}
                </span>
                <Tooltip
                  anchorId={generatedKey}
                  place="bottom"
                  style={{
                    zIndex: 9999, // Adjust the value as needed
                    textAlign: 'left',
                  }}
                  html={`<span style="color: #ffffff"><b>${formatCurrencyDecimals(
                    contribution
                  )}</b> - es el importe ajustado para que el número de participaciones sea un número entero sin decimales.<br/>
              <b>${formatCurrencyDecimals(
                realContribution
              )}</b> - es el importe indicado como aportación del inversor sin ajustes.
              </span>`}
                />
              </>
            ) : (
              `${contribution}€`
            )}
          </span>
        </td>
        <td className="nk-tb-col tb-col-md text-right">
          <span className="tb-amount">
            {!print ? (
              <NumberFormat
                value={discountPercent || 0}
                displayType="text"
                suffix="%"
                {...numberFormatDecimals}
              />
            ) : (
              `${discountPercent}%`
            )}
          </span>
        </td>
        <td className="nk-tb-col tb-col-md text-right">
          <span className="tb-amount">
            {NDPercent > 0 ? '+' : ''}
            {!print ? (
              <NumberFormat
                value={NDPercent}
                displayType="text"
                suffix="%"
                {...numberFormatDecimals}
              />
            ) : (
              `${NDPercent}%`
            )}
          </span>
        </td>
        <td className="nk-tb-col tb-col-xl text-right">
          <span
            className="tb-amount"
            id={`${proportionalityTypes.CUSTOM_ND}-${generatedKey}`}
          >
            {!print ? (
              <NumberFormat
                value={NDTotal}
                displayType="text"
                suffix="%"
                {...numberFormatDecimals}
              />
            ) : (
              `${NDTotal}%`
            )}
          </span>
          {!print && proportionality === proportionalityTypes.CUSTOM_ND && (
            <Tooltip
              anchorId={`${proportionalityTypes.CUSTOM_ND}-${generatedKey}`}
              place="bottom"
              style={{
                zIndex: 9999, // Adjust the value as needed
                textAlign: 'left',
              }}
              html={`<span style="color: #ffffff"><b>${formatNumberDecimals(
                NDTotal
              )}%</b> - es el porcentaje recalculado basado en las condiciones de proporcionalidad de todos los inversores.<br/>
              <b>${formatNumberDecimals(
                proportionalityPercent
              )}%</b> - es el porcentaje indicado a conservar sin ajustes.
              </span>`}
            />
          )}
        </td>
        <td className="nk-tb-col tb-col-xl text-right">
          <span
            className="tb-amount"
            id={`${proportionalityTypes.CUSTOM_FD}-${generatedKey}`}
          >
            {!print ? (
              <NumberFormat
                value={FDTotal}
                displayType="text"
                suffix="%"
                {...numberFormatDecimals}
              />
            ) : (
              `${FDTotal}%`
            )}
          </span>
          {!print && proportionality === proportionalityTypes.CUSTOM_FD && (
            <Tooltip
              anchorId={`${proportionalityTypes.CUSTOM_FD}-${generatedKey}`}
              place="bottom"
              style={{
                zIndex: 9999, // Adjust the value as needed
                textAlign: 'left',
              }}
              html={`<span style="color: #ffffff"><b>${formatNumberDecimals(
                FDTotal
              )}%</b> - es el porcentaje recalculado basado en las condiciones de proporcionalidad de todos los inversores.<br/>
              <b>${formatNumberDecimals(
                proportionalityPercent
              )}%</b> - es el porcentaje indicado a conservar sin ajustes.
              </span>`}
            />
          )}
        </td>
        <td className="nk-tb-col tb-col-xl text-right">
          <span
            className="tb-amount"
            id={`${proportionalityTypes.CUSTOM_POTENTIAL}-${generatedKey}`}
          >
            {!print ? (
              <NumberFormat
                value={FDPotential}
                displayType="text"
                suffix="%"
                {...numberFormatDecimals}
              />
            ) : (
              `${FDPotential}%`
            )}
          </span>
          {!print &&
            proportionality === proportionalityTypes.CUSTOM_POTENTIAL && (
              <Tooltip
                anchorId={`${proportionalityTypes.CUSTOM_POTENTIAL}-${generatedKey}`}
                place="bottom"
                style={{
                  zIndex: 9999, // Adjust the value as needed
                  textAlign: 'left',
                }}
                html={`<span style="color: #ffffff"><b>${formatNumberDecimals(
                  FDPotential
                )}%</b> - es el porcentaje recalculado basado en las condiciones de proporcionalidad de todos los inversores.<br/>
              <b>${formatNumberDecimals(
                proportionalityPercent
              )}%</b> - es el porcentaje indicado a conservar sin ajustes.
              </span>`}
              />
            )}
        </td>
        <td className="nk-tb-col tb-col-xl text-right">
          <span className="tb-amount">
            {!print ? (
              <>
                <NumberFormat
                  value={shares.new}
                  displayType="text"
                  {...numberFormat}
                />
                <span className="currency" />
              </>
            ) : (
              shares.new
            )}
          </span>
        </td>
        <td className="nk-tb-col tb-col-md text-right">
          <span className="tb-amount">
            {!print ? (
              <NumberFormat
                value={shares.total}
                displayType="text"
                {...numberFormat}
              />
            ) : (
              shares.total
            )}
          </span>
        </td>
        {!print && !isConverted && (
          <td className="nk-tb-col nk-tb-col-tools">
            <div className="nk-file-actions">
              <div className="tb-lead">
                <MenuDots
                  menuOptions={currentMenuOptions}
                  params={{
                    addPartnerToDraft,
                    updatePartnerToDraft,
                    removePartnerFromDraft: removePartner,
                    valuation,
                    premium,
                    action: 'EDIT',
                    partnerData: partner,
                    currentDraft: draft,
                    draftType: draftTypes.DRAFT_CAPITAL_INCREASE,
                    setTouched,
                  }}
                  direction="left"
                />
              </div>
            </div>
          </td>
        )}
      </tr>
    </>
  );
}

export default DraftCapitalIncreaseRow;
