/* eslint-disable react/react-in-jsx-scope */
import sizes from 'constants/sizes';
import { tenderOfferSharesTypes } from 'utils/tenderOfferSharesTypes';

import AddTenderOffer from 'components/Modals/AddTenderOffer';
import AddTenderOfferShares from 'components/Modals/AddTenderOfferShares';

function menuOptions(i18n) {
  return [
    {
      icon: 'ni-edit',
      text: i18n.t('Units'),
      modal: <AddTenderOffer size={sizes.LG} />,
    },
    {
      icon: 'ni-edit',
      text: i18n.t('Shares'),
      modal: (
        <AddTenderOfferShares
          size={sizes.LG}
          type={tenderOfferSharesTypes.BUY}
        />
      ),
    },
  ];
}
export default menuOptions;
